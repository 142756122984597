import { PayloadAction } from '@reduxjs/toolkit';
import { ChatRoom } from 'amazon-ivs-chat-messaging';
import { camelizeKeys } from 'humps';
import { call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import { selectUserLogin } from '../../auth/state/authSlice';
import { ApiReturnType } from '../../shared/ApiReturnType';
import streamChatApi from '../api/streamChatApi';
import { ChatMessageResponse } from '../types';
import createChatChannel from './createChatChannel/createChatChannel';
import ivsChatUtils from './ivsChatUtils';
import {
  getChatMessagesLive,
  initializeChat,
  setFollowerUsername,
  setJoinerUsername,
  setMessages,
  StreamChatMessage,
} from './streamChatSlice';

export const mapChatMessageResponse = (
  chatMessageResponse: ChatMessageResponse
): StreamChatMessage => ({
  message: chatMessageResponse.content,
  receivedTime: chatMessageResponse.receivedTime,
  count: chatMessageResponse.attributes?.count ? Number(chatMessageResponse.attributes.count) : 1,
  username: chatMessageResponse.attributes?.senderUsername,
  type: chatMessageResponse.attributes?.type,
  isStreamerFollower: chatMessageResponse.attributes?.isStreamerFollower === 'true',
});

export function* getChatMessagesLiveHandler(action: PayloadAction<string>) {
  try {
    const { messages }: ApiReturnType<typeof streamChatApi.getChatMessagesLive> = yield call(
      streamChatApi.getChatMessagesLive,
      action.payload
    );
    const liveMessages = [...(messages ?? [])]
      .reverse()
      .map(chatMessageResponse => mapChatMessageResponse(chatMessageResponse));
    yield put(setMessages(liveMessages));
  } catch (unknownError: unknown) {
    yield call(console.error, unknownError);
  }
}
function* setupChatEventListenersGenerator(chatRoom: ChatRoom): Generator {
  const eventChannel = createChatChannel(chatRoom, 'event');

  while (true) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chatEvent = (yield take(eventChannel)) as any;

    if (chatEvent.eventName === 'JOIN') {
      const username = !!chatEvent.attributes?.['sender_username']
        ? chatEvent.attributes['sender_username']
        : 'Gast';
      yield put(setJoinerUsername(username));
    }

    if (chatEvent.eventName === 'FOLLOW') {
      const username = !!chatEvent.attributes?.['sender_username']
        ? chatEvent.attributes['sender_username']
        : 'Gast';
      yield put(setFollowerUsername(username));
    }
  }
}

function* setupChatMessagesListenersGenerator(chatRoom: ChatRoom): Generator {
  const chatChannel = createChatChannel(chatRoom, 'message');

  while (true) {
    const payload = yield take(chatChannel);

    const chatMessage = mapChatMessageResponse(
      // eslint-disable-next-line
      camelizeKeys(payload as any) as unknown as ChatMessageResponse
    );
    yield put(setMessages([chatMessage]));
  }
}

export function* handleChatInitialization(action: PayloadAction<string>) {
  try {
    const showId = action.payload;
    const { roomArn }: ApiReturnType<typeof streamChatApi.getChatRoom> = yield call(
      streamChatApi.getChatRoom,
      showId
    );
    const userLogin: ReturnType<typeof selectUserLogin> = yield select(selectUserLogin);
    if (userLogin) {
      const userId = userLogin.id;
      const chatRoom = ivsChatUtils.create(roomArn, userId);
      ivsChatUtils.connect(chatRoom);
      yield spawn(setupChatEventListenersGenerator, chatRoom);
      yield spawn(setupChatMessagesListenersGenerator, chatRoom);
    }
  } catch (unknownError: unknown) {
    yield call(console.error, unknownError);
  }
}

export function* watcherStreamChatSagas() {
  yield takeLatest(getChatMessagesLive.type, getChatMessagesLiveHandler);
  yield takeLatest(initializeChat.type, handleChatInitialization);
}
