import { Pageable } from '../creators/components/DataGridTable/ServerSideDataGrid';
import { QueryParams } from './types';

export const toQueryString = (queryParams: QueryParams) =>
  new URLSearchParams({
    sort_field: queryParams.sortField,
    sort_order: queryParams.sortOrder.toUpperCase(),
    page_size: queryParams.pageSize.toString(),
    page_number: queryParams.pageNumber.toString(),
    from: queryParams.from,
    to: queryParams.to,
    ...(queryParams.searchTerm && { search_term: queryParams.searchTerm }),
    ...(queryParams.audience && {
      audience: queryParams.audience.map(e => e.toString()).join(','),
    }),
  }).toString();

export const mapQueryParamsToPageable = (queryData: QueryParams): Pageable => ({
  page: queryData.pageNumber,
  pageSize: queryData.pageSize,
  sortField: queryData.sortField,
  sortOrder: queryData.sortOrder,
  searchTerm: queryData.searchTerm,
});
