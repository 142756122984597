import { Text } from '@hse24/shared-components';
import { Divider, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import TextField from '../../../../components/TextField/TextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import styles from '../ContactDataForm/ContactDataForm.module.scss';
import { profilePersonalDataKeys } from '../ProfilePersonalData';

const SocialMediaForm = () => {
  const socialMediaAccounts = useSelector(selectLoggedInCreator)?.socialMediaAccounts;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text.B1 className={styles.heading}>Social Media</Text.B1>
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fieldName={profilePersonalDataKeys.instagram}
          label="Instagram"
          value={socialMediaAccounts?.instagram}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fieldName={profilePersonalDataKeys.tiktok}
          label="TikTok"
          value={socialMediaAccounts?.tiktok}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fieldName={profilePersonalDataKeys.youtube}
          label="Youtube"
          value={socialMediaAccounts?.youtube}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fieldName={profilePersonalDataKeys.facebook}
          label="Facebook"
          value={socialMediaAccounts?.facebook}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fieldName={profilePersonalDataKeys.snapchat}
          label="Snapchat"
          value={socialMediaAccounts?.snapchat}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fieldName={profilePersonalDataKeys.pinterest}
          label="Pinterest"
          value={socialMediaAccounts?.pinterest}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={styles.divider} />
      </Grid>
    </Grid>
  );
};

export default SocialMediaForm;
