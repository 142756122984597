import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import theme from '../../theme/theme.module.scss';
import styles from './InfoTable.module.scss';

export interface InfoTableProps {
  title?: string;
  ariaLabel?: string;
  columns?: (string | number)[];
  rows?: (string | number)[][];
  selectedRow?: number;
  description?: string;
}

const InfoTable = ({
  title,
  ariaLabel,
  columns,
  rows,
  selectedRow,
  description,
}: InfoTableProps) => {
  return (
    <Grid item className={styles.table} p={theme.space24}>
      {title && (
        <Typography
          fontWeight={theme.fontWeight7}
          fontSize={theme.fontSize14}
          mb={theme.space16}
          maxWidth={657}
          data-testid={'info-table-title'}
        >
          {title}
        </Typography>
      )}
      <TableContainer component={Paper}>
        <Table className={styles.table_container} aria-label={ariaLabel}>
          <TableHead>
            {!!columns?.length && (
              <TableRow data-testid={'info-table-columns'}>
                {columns.map((column, index) => (
                  <TableCell key={index}>{column}</TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody data-testid={'info-table-rows'}>
            {!!rows?.length &&
              rows.map((row, index) => {
                const isRowSelected = index + 1 === selectedRow;
                return (
                  <TableRow key={index} selected={isRowSelected}>
                    {row.map((record, index) => (
                      <TableCell
                        key={index}
                        sx={{ fontWeight: isRowSelected ? theme.fontWeight7 : theme.fontWeight4 }}
                      >
                        {record}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {description && (
        <Typography
          fontSize={theme.fontSize11}
          mt={theme.space16}
          maxWidth={657}
          data-testid={'info-table-description'}
        >
          {description}
        </Typography>
      )}
    </Grid>
  );
};

export default InfoTable;
