import { Audience } from '../../../common/types';
import { AdminNewCreatorRequest, NewCreatorRequest } from '../../api/requestResponse';
import { AdminAddCreatorDataUi } from '../../components/AddCreatorsForm/AdminAddCreatorForm/AdminAddCreatorForm';
import { AgencyAddCreatorDataUi } from '../../components/AddCreatorsForm/AgencyAddCreatorForm/AgencyAddCreatorForm';

export const mapAgencyAddCreatorDataUiToNewCreatorRequest = (
  addCreatorDataUi: AgencyAddCreatorDataUi
): NewCreatorRequest => {
  if (addCreatorDataUi.socialMediaPlatform.trim() === '') {
    throw 'SocialMediaPlatform is mandatory and cannot be null!';
  }
  return {
    email: addCreatorDataUi.email,
    name: addCreatorDataUi.name,
    socialMediaAccounts: {
      [addCreatorDataUi.socialMediaPlatform]: addCreatorDataUi.socialMediaUsername,
    },
  };
};

export const mapAdminAddCreatorDataUiToAdminNewCreatorRequest = (
  addCreatorDataUi: AdminAddCreatorDataUi
): AdminNewCreatorRequest => {
  const requestWithMandatoryFields: AdminNewCreatorRequest = {
    email: addCreatorDataUi.email,
    name: addCreatorDataUi.name,
    role: addCreatorDataUi.creatorType,
    audience: addCreatorDataUi.audience.split(',') as Audience[],
    ...(addCreatorDataUi.agencyId && {
      agencyId: addCreatorDataUi.agencyId,
    }),
  };

  return addCreatorDataUi.socialMediaPlatform.trim() !== ''
    ? {
        ...requestWithMandatoryFields,
        socialMediaAccounts: {
          [addCreatorDataUi.socialMediaPlatform]: addCreatorDataUi.socialMediaUsername,
        },
      }
    : requestWithMandatoryFields;
};
