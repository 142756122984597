import { ArrowForward } from '@mui/icons-material';
import QuizIcon from '@mui/icons-material/Quiz';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import banner from '../../../assets/amaOnboarding/ama_banner.png';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import t from '../../../constants/translation';
import { showAmaOnboardingDialog } from '../../../notifications/state/notificationsSlice';
import styles from './AmaBanner.module.scss';

const AmaBanner = () => {
  const dispatch = useDispatch();
  const isMobile = isMobileBreakPoint();

  const onClick = () => {
    dispatch(showAmaOnboardingDialog());
  };
  return (
    <div className={styles.container} onClick={onClick}>
      {!isMobile ? (
        <img
          src={banner}
          alt="Ask me anything picture"
          className={styles.ama_picture}
          data-testid="ama-pic"
        />
      ) : (
        <Box className={styles.circle}>
          <QuizIcon />
        </Box>
      )}
      <Box>
        <Typography variant={'body1'} className={styles.headline}>
          {isMobile
            ? t.creators.ama['Ask me Anything']
            : t.creators.ama['Engage more with your audience.']}
        </Typography>
        <Box display="flex" justifyContent="center">
          {!isMobile && (
            <>
              <Typography variant={'body2'} className={styles.subline1}>
                {t.creators.ama['Ask me Anything']}
              </Typography>
              &nbsp;
            </>
          )}
          <Typography variant={'body2'} className={styles.subline2}>
            {t.creators.ama['is here! 🎉']}
          </Typography>
        </Box>
      </Box>
      {!isMobile && (
        <Box>
          <Button className={styles.button} disableElevation>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Box>{t.creators.ama['Learn more']}</Box>
              <Box>
                <ArrowForward />
              </Box>
            </Box>
          </Button>
        </Box>
      )}
    </div>
  );
};

export default AmaBanner;
