import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import t from '../../../../../src/constants/translation';
import CustomDialogContent from '../../../../components/CustomDialogContent/CustomDialogContent';
import { closeManagedDialog } from '../../../../notifications/state/notificationsSlice';
import { deleteCreatorAccount } from '../../../state/creatorsSlice';
import styles from './DeleteAccountModal.module.scss';

const DeleteAccountModal = () => {
  const dispatch = useDispatch();

  const closeModal = () => dispatch(closeManagedDialog());
  const deleteAccount = () => dispatch(deleteCreatorAccount());
  return (
    <CustomDialogContent>
      <div className={styles.modal_content}>
        <p>Möchtest du deinen Account wirklich löschen?</p>
        <p>Diese Aktion kann nicht rückgängig gemacht werden.</p>
      </div>
      <Box className={styles.buttons}>
        <Button variant="contained" onClick={closeModal} disableElevation>
          {t.common.Cancel}
        </Button>
        <Button variant="outlined" onClick={deleteAccount} disableElevation>
          Ja, lösche meinen Account.
        </Button>
      </Box>
    </CustomDialogContent>
  );
};

export default DeleteAccountModal;
