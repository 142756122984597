import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, IconButtonProps, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import { AcademySection } from '../../model/academy';
import VideoCard from '../VideoCard/VideoCard';
import styles from './SectionCrad.module.scss';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  section: AcademySection;
}

function SectionCard({ section }: Props) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <Paper elevation={0}>
      <Box
        p={2}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        onClick={handleExpandClick}
      >
        <Box>
          <Typography component={'h2'} fontWeight={'bold'}>
            {section.title}
          </Typography>
        </Box>
        <Box>
          <ExpandMore expand={expanded}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box className={styles.videos}>
          {section.videos.map(video => (
            <VideoCard key={video.id} video={video} />
          ))}
        </Box>
      </Collapse>
    </Paper>
  );
}

export default SectionCard;
