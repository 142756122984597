import { Box, Tooltip } from '@mui/material';
import t from '../../../constants/translation';
import styles from './FeedbackText.module.scss';
interface Props {
  text: string;
}
function FeedbackText({ text }: Props) {
  return text != undefined ? (
    <Tooltip title={text} placement={'bottom-start'}>
      <Box className={styles.feedback}>
        <Box className={styles.text}>{text}</Box>
        {text.length > 40 && <Box className={styles.showMore}>{t.common.More}</Box>}
      </Box>
    </Tooltip>
  ) : (
    <Box className={styles.text}>-</Box>
  );
}

export default FeedbackText;
