import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../auth/state/authSlice';
import CustomDialogContent from '../../../../components/CustomDialogContent/CustomDialogContent';
import styles from '../DeleteAccountModal/DeleteAccountModal.module.scss';

const DeleteAccountConfirmationModal = () => {
  const dispatch = useDispatch();

  const logoutUser = () => dispatch(logout());

  return (
    <CustomDialogContent>
      <div className={styles.modal_content}>
        <p>Wir haben deinen Antrag erhalten.</p>
        <p>Das Löschen deines Accounts kann bis zu 7 Tage dauern.</p>
        <p>Wir loggen dich jetzt aus.</p>
      </div>
      <Box>
        <Button fullWidth onClick={logoutUser} disableElevation>
          Ok
        </Button>
      </Box>
    </CustomDialogContent>
  );
};

export default DeleteAccountConfirmationModal;
