import { isFuture, isValid, parse } from 'date-fns';
import * as yup from 'yup';

const defaultDateFormat = 'dd.MM.yyyy HH:mm';

const adminCreatePostSchema = {
  caption: yup
    .string()
    .required('Bitte gib einen Titel ein')
    .min(3, 'Mindestens 3 Zeichen')
    .max(200, 'Maximal 200 Zeichen'),
  baseProductsNo: yup
    .array()
    .required('Keine Produkte ausgewählt')
    .test('invalid', 'Keine Produkte ausgewählt', value => !!value && value.length > 0),
  salesTopic: yup.string().optional(),
};

export const adminPlannedCreatePostSchema = () =>
  yup.object({
    ...adminCreatePostSchema,
    scheduledStartAt: yup
      .string()
      .required('Startzeit ist erforderlich')
      .test(
        'invalid',
        'Ungültiges Datum',
        value => !!value && isValid(parse(value, defaultDateFormat, new Date()))
      )
      .test('invalid', 'Startzeit muss in der Zukunft liegen', value => {
        return !!value && isFuture(parse(value, defaultDateFormat, new Date()));
      }),
  });

export const adminImmediateCreatePostSchema = () =>
  yup.object({
    ...adminCreatePostSchema,
    scheduledStartAt: yup.string().optional(),
  });
