import { Typography } from '@mui/material';
import { formatDay, formatHour, germanDay, germanMonth } from '../../../../../../utils/dates';
import styles from './DaySelectionPreview.module.scss';

interface DaySelectionPreviewProps {
  day: Date;
  shouldDisplayFullDate: boolean;
}
const DaySelectionPreview = ({ day, shouldDisplayFullDate }: DaySelectionPreviewProps) => {
  return (
    <Typography
      className={styles.day_selection_preview}
      data-testid="day-selection-preview"
    >{`${germanDay(day)}, ${formatDay(day)}. ${germanMonth(day)} ${
      shouldDisplayFullDate ? `um ${formatHour(day)}` : ''
    }`}</Typography>
  );
};

export default DaySelectionPreview;
