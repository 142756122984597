import { LoadingSpinner } from '@hse24/shared-components';
import styles from './Loading.module.scss';

const Loading = () => (
  <div className={styles.loading} data-testid="loading-spinner">
    <LoadingSpinner className="w-48 h-48" />
  </div>
);

export default Loading;
