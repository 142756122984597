import { createSelector, createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '../store';

export interface AppState {
  appStartedAt: string;
}

export const initialState: AppState = {
  appStartedAt: dayjs().toISOString(),
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});

const selectAppState = (state: RootState) => state[appSlice.name];

export const selectAppStartedAt = createSelector(selectAppState, state => state.appStartedAt);

export default appSlice.reducer;
