import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import cx from 'classnames';
import throttle from 'lodash.throttle';
import { CSSProperties, FC, SyntheticEvent, useEffect, useState } from 'react';
import styles from './BackToTop.module.scss';

export type TBackToTopProps = {
  // callback function which is fired when clicked
  onClick?: (e: SyntheticEvent) => void;

  // scroll position to scroll to on click
  scrollTo?: number | [number, number];

  // number off pixel
  offset?: number;

  // custom class which is applied to the component
  className?: string;

  // custom styles which are applied to the component
  style?: CSSProperties;
};

const BackToTop: FC<TBackToTopProps> = props => {
  const { onClick = () => null, scrollTo = [0, 0], offset = 300, className, style } = props;

  const [isVisible, setIsVisible] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const THROTTLE_WAIT = 200;

  const scrollToPosition = (e: SyntheticEvent) => {
    const xPos = (Array.isArray(scrollTo) ? scrollTo[0] : 0) || 0;
    const yPos = (Array.isArray(scrollTo) ? scrollTo[1] : 0) || 0;
    window && window.scrollTo({ left: xPos, top: yPos, behavior: 'smooth' });
    onClick(e);
  };

  useEffect(() => {
    const scrollListener = () => {
      const isBelowOffset = window && window.pageYOffset >= offset;
      setIsVisible(isBelowOffset);
    };
    setDidMount(true);
    window.addEventListener('scroll', throttle(scrollListener, THROTTLE_WAIT));
    return () => {
      window.removeEventListener('scroll', throttle(scrollListener, THROTTLE_WAIT));
    };
  }, [offset, scrollTo]);

  return (
    <div
      onClick={scrollToPosition}
      className={cx(className, styles.backToTop, {
        [styles.visible]: isVisible,
        [styles.hidden]: !isVisible,
        [styles.offscreen]: !didMount,
      })}
      style={style}
      data-sc={true}
      data-testid="back-to-top"
    >
      <ArrowUpwardIcon width="24px" />
    </div>
  );
};

BackToTop.displayName = 'BackToTop';

export default BackToTop;
