import { createValidationError, validateOptionalObject, validateOptionalString, validateString } from './validate.js';
export function serializeRequest(request) {
    validateString(request.action, 'request.action');
    validateString(request.requestId, 'request.requestId');
    switch (request.action) {
        case 'DELETE_MESSAGE': {
            validateString(request.id, 'request.id');
            validateOptionalString(request.reason, 'request.reason');
            break;
        }
        case 'DISCONNECT_USER': {
            validateString(request.userId, 'request.userId');
            validateOptionalString(request.reason, 'request.reason');
            break;
        }
        case 'SEND_MESSAGE': {
            validateString(request.content, 'request.content');
            validateOptionalObject(request.attributes, 'request.attributes');
            break;
        }
        default:
            // @ts-ignore
            throw createValidationError(`Cannot serialize request, invalid action ${request.action}`);
    }
    const mappedEntries = Object.entries(request).map(([key, value]) => {
        const mappedKey = key.charAt(0).toUpperCase() + key.substring(1);
        return [mappedKey, value];
    });
    return JSON.stringify(Object.fromEntries(mappedEntries));
}
