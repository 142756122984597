import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSearchProducts } from '../../../state/showsSlice';
import ShowProductSearchNoResults from '../ShowProductSearchNoResults/ShowProductSearchNoResults';
import ShowProductSuggestionItem from '../ShowProductSuggestionItem/ShowProductSuggestionItem';
import styles from './ShowProductSuggestions.module.scss';

interface ShowProductSuggestionsProps {
  onSuggestionsClose: () => void;
}

const ShowProductSuggestions = ({ onSuggestionsClose }: ShowProductSuggestionsProps) => {
  const products = useSelector(selectSearchProducts);
  const [shouldOpenSuggestions, setShouldOpenSuggestions] = useState(true);

  const onProductSelected = () => {
    setShouldOpenSuggestions(false);
    onSuggestionsClose();
  };

  return (
    <>
      {shouldOpenSuggestions && (
        <ul className={styles.suggestions}>
          {products.length > 0 ? (
            products.map(product => (
              <ShowProductSuggestionItem
                key={product.baseProductNo}
                product={product}
                onProductSelectedCallback={onProductSelected}
              />
            ))
          ) : (
            <ShowProductSearchNoResults />
          )}
        </ul>
      )}
    </>
  );
};

export default ShowProductSuggestions;
