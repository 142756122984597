import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Container, IconButton, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './MobileAppBar.module.scss';

interface Props {
  title?: string;
  action?: ReactNode;
  landing?: () => void;
}

function MobileAppBar({ title, action, landing }: Props) {
  return (
    <Box className={styles.appbar}>
      <Container>
        <Box className={styles.header}>
          <Box>
            {landing && (
              <IconButton onClick={landing}>
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </Box>
          <Box className={styles.title}>
            <Typography sx={{ textAlign: 'center' }} variant={'h6'} fontWeight={'bold'}>
              {title}
            </Typography>
          </Box>
          <Box>{action}</Box>
        </Box>
      </Container>
    </Box>
  );
}

export default MobileAppBar;
