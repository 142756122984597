import { Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  getAgenciesSummary,
  selectAgenciesSummary,
} from '../../../../agencies/state/agenciesSlice';
import { Audience } from '../../../../common/types';
import CustomDialogContent from '../../../../components/CustomDialogContent/CustomDialogContent';
import FormikRadioGroup from '../../../../components/FormikRadioGroup/FormikRadioGroup';
import FormikSelect from '../../../../components/FormikSelect/FormikSelect';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import t from '../../../../constants/translation';
import { CreatorRole } from '../../../model/creator';
import { SocialMediaPlatform } from '../../../model/socialMediaPlatform';
import { createApprovedCreator } from '../../../state/creatorsSlice';
import DialogActionButtons from '../DialogActionButtons/DialogActionButtons';
import OptionalSocialMediaAccountSelection from '../OptionalSocialMediaAccountSelection/OptionalSocialMediaAccountSelection';

const validationSchema = yup.object().shape(
  {
    name: yup
      .string()
      .required('Der Name darf nicht leer sein')
      .max(30, 'Das Maximum beträgt 30 Zeichen'),
    email: yup
      .string()
      .email('Bitte gebe eine valide E-Mail-Adresse an')
      .required('Die E-Mail-Adresse darf nicht leer sein')
      .max(350, 'Das Maximum beträgt 350 Zeichen'),
    socialMediaPlatform: yup
      .mixed<SocialMediaPlatform>()
      .oneOf(Object.values(SocialMediaPlatform))
      .when('socialMediaUsername', {
        is: (val: string) => Boolean(val),
        then: yup
          .mixed<SocialMediaPlatform>()
          .oneOf(Object.values(SocialMediaPlatform))
          .required('Bitte wähle eine Social Media Plattform aus'),
      }),
    socialMediaUsername: yup.string().when('socialMediaPlatform', {
      is: (val: SocialMediaPlatform | string) => Boolean(val),
      then: yup.string().required('Bitte gebe einen Social Media Account an'),
    }),
    creatorType: yup
      .mixed<CreatorRole>()
      .oneOf(Object.values(CreatorRole))
      .required('Bitte wähle eine Rolle aus.'),
  },
  [['socialMediaPlatform', 'socialMediaUsername']]
);

export type AdminAddCreatorDataUi = {
  name: string;
  email: string;
  socialMediaPlatform: SocialMediaPlatform;
  socialMediaUsername: string;
  creatorType: CreatorRole;
  audience: Audience;
  agencyId?: string;
};

type InitialAdminAddStreamerDataUi = Omit<
  AdminAddCreatorDataUi,
  'creatorType' | 'socialMediaPlatform'
> & {
  socialMediaPlatform: SocialMediaPlatform | '';
  creatorType: CreatorRole | '';
};

const formDataUi: InitialAdminAddStreamerDataUi = {
  name: '',
  email: '',
  socialMediaPlatform: '',
  socialMediaUsername: '',
  creatorType: '',
  audience: Audience.HELLO,
};

const AdminAddCreatorForm = () => {
  const dispatch = useDispatch();
  const onSubmit = (values: InitialAdminAddStreamerDataUi) => {
    // type cast guaranteed by formik validation
    dispatch(createApprovedCreator(values as AdminAddCreatorDataUi));
  };

  const agenciesSummary = useSelector(selectAgenciesSummary);

  const items = [
    {
      value: CreatorRole.STREAMER,
      label: 'Streamer',
    },
    {
      value: CreatorRole.INFLUENCER,
      label: 'Influencer',
    },
  ];

  useEffect(() => {
    dispatch(getAgenciesSummary());
  }, []);

  return (
    <Formik initialValues={formDataUi} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form>
        <CustomDialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} mb={2}>
              <FormikRadioGroup
                value={formDataUi.creatorType ? formDataUi.creatorType : '' || ''}
                fieldName="creatorType"
                items={items}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField fieldName="name" label="Name*" value={formDataUi.name} />
            </Grid>
            <Grid item xs={12}>
              <FormikTextField fieldName="email" label="E-Mail*" value={formDataUi.email} />
            </Grid>
            <Grid item xs={12}>
              <FormikSelect
                fieldName="audience"
                label={'Audience'}
                value={Audience.HELLO}
                menuItems={[
                  <MenuItem key={Audience.HELLO} value={Audience.HELLO}>
                    {Audience.HELLO}
                  </MenuItem>,
                  <MenuItem key={Audience.CLASSIC} value={Audience.CLASSIC}>
                    {Audience.CLASSIC}
                  </MenuItem>,
                  <MenuItem key={'CLASSIC,HELLO'} value={'CLASSIC,HELLO'}>
                    {'CLASSIC & HELLO'}
                  </MenuItem>,
                ]}
              />
            </Grid>
            <Grid mt={3} mb={3} item xs={12}>
              <FormikSelect
                fieldName="agencyId"
                label={t.agencies.Agency}
                value={''}
                menuItems={agenciesSummary.map(e => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              />
            </Grid>
            <Grid item xs={12}>
              <OptionalSocialMediaAccountSelection />
            </Grid>
          </Grid>
          <DialogActionButtons />
        </CustomDialogContent>
      </Form>
    </Formik>
  );
};

export default AdminAddCreatorForm;
