import { ArrowIcon } from '@hse24/shared-components';
import cx from 'classnames';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import {
  createPreSignedUrls,
  FileMetaData,
  selectSelectedSupportedFiles,
} from '../../../state/postsSlice';
import { filterSelectedFiles } from '../../../utils/MediaUtils';
import { PostMediaPreview } from '../PostMediaPreview/PostMediaPreview';
import styles from './PostMediaPreviews.module.scss';

const IMAGES_PER_PAGE = 3;

const PostMediaPreviews = () => {
  const selectedSupportedFiles = useSelector(selectSelectedSupportedFiles);
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleFileUploaded = (chosenFiles: FileList | null) => {
    if (chosenFiles) {
      const filteredFiles = filterSelectedFiles(chosenFiles, selectedSupportedFiles.length);
      dispatch(
        createPreSignedUrls({
          attachments: filteredFiles.attachments,
          files: filteredFiles.files.filter(file => file.isSupported),
          isUploading: true,
        })
      );
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - IMAGES_PER_PAGE);
    }
  };

  const handleNext = () => {
    if (currentIndex < selectedSupportedFiles.length + 1 - IMAGES_PER_PAGE) {
      setCurrentIndex(currentIndex + IMAGES_PER_PAGE);
    }
  };

  if (selectedSupportedFiles.length === 0) {
    return (
      <div className={styles.placeholder_cards}>
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className={styles.card} data-testid="file-placeholder" />
        ))}
      </div>
    );
  }

  const hideArrows = selectedSupportedFiles.length <= 3;

  return (
    <div className={styles.slider_container} data-testid="post-media-previews">
      <button
        onClick={handlePrevious}
        disabled={currentIndex === 0}
        className={cx(styles.arrow_button, { [styles.hidden]: hideArrows })}
        data-testid="arrow-up"
      >
        <ArrowIcon width={40} height={40} className={styles.arrow_up} />
      </button>

      <div className={cx(styles.image_container, { [styles.show_arrows]: !hideArrows })}>
        <div
          className={styles.images_wrapper}
          style={{ transform: `translateY(-${currentIndex * 174}px)` }} // 150px height + 24px gap
        >
          {selectedSupportedFiles
            .sort((a, b) => a.index - b.index)
            .map((file: FileMetaData) => {
              return <PostMediaPreview key={file.name} file={file} />;
            })}
          <div className={styles.add_button}>
            <div
              onClick={() => {
                inputRef.current?.click();
              }}
              className={styles.plus_icon}
            >
              <Icon icon={Icons.plusIcon} height={18} width={18} dataTestId="plus-icon" />
            </div>
            <input
              data-testid="add-files"
              ref={inputRef}
              className={styles.upload_input}
              type="file"
              onChange={event => {
                handleFileUploaded(event.target.files);
              }}
              multiple={true}
            />
          </div>
        </div>
      </div>

      <button
        onClick={handleNext}
        disabled={currentIndex >= selectedSupportedFiles.length + 1 - IMAGES_PER_PAGE}
        className={cx(styles.arrow_button, { [styles.hidden]: hideArrows })}
        data-testid="arrow-down"
      >
        <ArrowIcon width={40} height={40} />
      </button>
    </div>
  );
};

export default PostMediaPreviews;
