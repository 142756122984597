import { Dialog } from '@mui/material';
import React, { ReactNode, useEffect, useRef } from 'react';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import styles from './ProductsSelection.module.scss';

interface Props {
  isExpanded: boolean;
  setIsExpanded: (value: boolean) => void;
  setIsScrolling: (value: boolean) => void;
  children: ReactNode;
}
function ProductsSelectionDialog({ children, isExpanded, setIsExpanded, setIsScrolling }: Props) {
  const isMobile = isMobileBreakPoint();

  const paperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!paperRef.current) return;

    const scrollInfo = {
      prevScroll: paperRef.current.scrollTop,
      wasExpandedWhenScrollingStarted: isExpanded,
    };

    const handleScroll = () => {
      const paperElement = paperRef.current;
      if (!paperElement) return;
      if (paperElement.scrollTop === 0) {
        setIsScrolling(false);
      } else {
        setIsScrolling(true);
      }

      if (
        scrollInfo.wasExpandedWhenScrollingStarted &&
        Math.abs(paperElement.scrollTop - scrollInfo.prevScroll) > 300
      ) {
        setIsExpanded(false);
        scrollInfo.prevScroll = paperElement.scrollTop;
      }
    };

    const handleScrollStart = () => {
      scrollInfo.wasExpandedWhenScrollingStarted = isExpanded;
    };

    const paperElement = paperRef.current;
    paperElement.addEventListener('scroll', handleScrollStart, { once: true });
    paperElement.addEventListener('scroll', handleScroll);

    return () => {
      paperElement.removeEventListener('scroll', handleScrollStart);
      paperElement.removeEventListener('scroll', handleScroll);
    };
  }, [isExpanded, paperRef.current]);

  return (
    <Dialog
      PaperProps={
        !isMobile
          ? {
              className: styles.dialog_paper,
              ref: paperRef,
            }
          : {
              ref: paperRef,
            }
      }
      open={true}
      scroll={'paper'}
      fullScreen={isMobile}
      fullWidth
      maxWidth={'sm'}
    >
      {children}
    </Dialog>
  );
}

export default ProductsSelectionDialog;
