import { CloseOutlined } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeManagedDialog,
  ManagedDialog,
  selectManagedDialog,
} from '../../notifications/state/notificationsSlice';
import styles from './CustomDialogContent.module.scss';

interface Props {
  children: ReactNode[];
}

function CustomDialogContent({ children }: Props) {
  const dispatch = useDispatch();
  const managedDialog: ManagedDialog | undefined = useSelector(selectManagedDialog);

  const content = children.map((e, i) => (
    <Box key={i} className={styles.content}>
      {e}
    </Box>
  ));

  return (
    <Box className={styles.dialog_content}>
      <Box className={styles.dialog_header}>
        <IconButton onClick={() => dispatch(closeManagedDialog())}>
          <CloseOutlined />
        </IconButton>
      </Box>
      <Box className={styles.dialog_body}>
        <Typography variant={'h3'} component={'div'}>
          {managedDialog?.title}
        </Typography>
        {content}
      </Box>
    </Box>
  );
}

export default CustomDialogContent;
