import React from 'react';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import StreamerAcademy from '../../components/StreamerAcademy/StreamerAcademy';

function StreamerAcademyPage() {
  return (
    <PageContainer
      pageToRender={<StreamerAcademy />}
      backgroundColor={theme.whiteLilac}
      fullHeight
    />
  );
}

export default StreamerAcademyPage;
