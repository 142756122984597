import React from 'react';
import { useSelector } from 'react-redux';
import { activationSteps } from '../../authSteps';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { selectAuthActivationProgressStep } from '../../state/authSlice';

function WelcomePage() {
  const step = useSelector(selectAuthActivationProgressStep);
  return <AuthLayout stepInfo={activationSteps.get(step)} />;
}

export default WelcomePage;
