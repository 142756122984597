import dayjs from 'dayjs';
import { UpcomingShow } from '../../../shows/model/shows';

const sortUpcomingShows = (shows: UpcomingShow[]) => {
  const now = dayjs();

  // Filter shows that started less than an hour ago or haven't started yet
  let mainCandidates = shows.filter(show => {
    const startDate = dayjs(show.scheduledAt);
    return startDate.isAfter(now) || startDate.isAfter(now.subtract(1, 'hour'));
  });

  if (mainCandidates.length === 0) {
    mainCandidates = shows;
  }

  // Sort by start date
  mainCandidates = [...mainCandidates].sort((a, b) =>
    dayjs(a.scheduledAt).diff(dayjs(b.scheduledAt))
  );

  // Select the main show
  const mainShow = mainCandidates[0];

  // Filter out the main show from the rest
  const otherShows = shows.filter(show => show !== mainShow);

  return { mainShow, otherShows };
};

export default sortUpcomingShows;
