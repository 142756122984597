import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid, Paper } from '@mui/material';
import classNames from 'classnames';
import PriceInfo from '../../../../components/PriceInfo/PriceInfo';
import ImagePreview from '../../../../products/components/ImagePreview/ImagePreview';
import { ProductTileInfo } from '../../../../products/model/productTileInfo';
import styles from './HighlightProductItem.module.scss';

interface HighlightProductItemProps {
  product: ProductTileInfo;
  selectable?: boolean;
  isHighlighted?: boolean;
}

const HighlightProductItem = ({
  product,
  isHighlighted = false,
  selectable = false,
}: HighlightProductItemProps) => {
  return (
    <Paper
      className={classNames({
        [styles.product_container]: true,
        [styles.not_selectable]: !selectable,
      })}
      elevation={1}
    >
      {isHighlighted && <CheckCircleIcon className={styles.highlighted} />}
      <Grid container item xs={12} direction={'row'}>
        <Grid item xs={4}>
          <ImagePreview
            alt={product.name}
            size={320}
            imageUrl={product.imageUri}
            className={styles.product_image}
          />
        </Grid>
        <Grid
          container
          item
          xs={8}
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={styles.product_details}
        >
          <Grid item className={styles.product_brand}>
            {product.brandName}
          </Grid>
          <Grid item>
            <h3 className={styles.product_name}>{product.name}</h3>
          </Grid>
          <Grid item>
            {product.price && <PriceInfo price={product.price} className={styles.product_price} />}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default HighlightProductItem;
