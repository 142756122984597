import {
  Button,
  EButtonSize,
  EButtonType,
  IconChatBox,
  IconTrash,
  Text,
} from '@hse24/shared-components';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import Config from '../../../config/config';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import { ShowDetails } from '../../model/shows';
import {
  changeShowVisibility,
  deleteShowByAdmin,
  selectShowDetailsLoading,
} from '../../state/showsSlice';
import CreatorInfo from '../ShowDetails/CreatorInfo/CreatorInfo';
import DeletionConfirmationDialog from '../ShowDetails/DeletionConfirmationDialog/DeletionConfirmationDialog';
import ExternalDeviceInfo from '../ShowDetails/ExternalDeviceInfo/ExternalDeviceInfo';
import ShowInfo from '../ShowDetails/ShowInfo/ShowInfo';
import UnpublishedConfirmationDialog from '../ShowDetails/UnpublishedConfirmationDialog/UnpublishedConfirmationDialog';
import VoucherDetails from '../ShowDetails/VoucherDetails/VoucherDetails';
import styles from './NewShowDetails.module.scss';

const NewShowDetails = ({ showDetails }: { showDetails: ShowDetails }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(selectShowDetailsLoading);

  const isInApp = featureFlagValue(AvailableFlag.isInApp);

  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = useState(false);
  const [isDeletionDialogOpen, setIsDeletionDialogOpen] = useState(false);

  const showId = showDetails.id;

  const handlePublish = (publish: boolean) => {
    dispatch(changeShowVisibility({ showId: showId, isPublished: publish }));
    handleCloseUnpublishDialog();
  };

  const handleDelete = () => {
    dispatch(deleteShowByAdmin({ showId, redirect: true }));
    handleCloseDeleteDialog();
  };

  const handleCloseUnpublishDialog = () => setIsUnpublishDialogOpen(false);

  const handleCloseDeleteDialog = () => setIsDeletionDialogOpen(false);

  const handleOpenChat = () => {
    isInApp
      ? history.push(routePaths.creator.streamChat.replace(':streamId', showId))
      : window.open(
          Config.env.baseRoute.concat(routePaths.creator.streamChat.replace(':streamId', showId)),
          '_blank'
        );
  };

  const navigateToShowModeration = () => {
    const path = generatePath(routePaths.hseEmployee.showModeration, { showId: showDetails.id });
    history.push(path);
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <UnpublishedConfirmationDialog
        handleClose={handleCloseUnpublishDialog}
        openDialog={isUnpublishDialogOpen}
        handleUnpublish={() => handlePublish(false)}
      />
      <DeletionConfirmationDialog
        handleClose={handleCloseDeleteDialog}
        openDialog={isDeletionDialogOpen}
        handleDelete={handleDelete}
      />
      <div className={styles.show_details_container}>
        <Text.B4>Ausführliche Stream-Details</Text.B4>
        <div className={styles.show_details}>
          <div className={styles.row}>
            <ShowInfo showDetails={showDetails} />
            <VoucherDetails
              isVoucherFormDisabled={!!showDetails.endedAt}
              showId={showDetails.id}
              voucher={showDetails.vouchers?.length ? showDetails.vouchers[0] : undefined}
            />
          </div>
          <div className={styles.row}>
            <CreatorInfo creatorDetails={showDetails.creatorDetails} />
            <div className={styles.external_info}>
              {!showDetails.endedAt && (
                <>
                  <ExternalDeviceInfo showDetails={showDetails} />
                  <Button
                    className={styles.action_button}
                    size={EButtonSize.SMALL}
                    onClick={() => navigateToShowModeration()}
                  >
                    <Typography fontWeight={700}>Zur Moderationsseite</Typography>
                  </Button>
                </>
              )}
              <Button
                className={styles.action_button}
                size={EButtonSize.SMALL}
                onClick={handleOpenChat}
              >
                <Typography fontWeight={700}>Öffne externen Chat</Typography>
                <IconChatBox className={styles.aside_icon} width="20px" height="20px" />
              </Button>
              {showDetails.isPublished ? (
                <Button
                  className={styles.action_button}
                  size={EButtonSize.SMALL}
                  type={EButtonType.BLANK}
                  data-testid="unpublish-show-button"
                  onClick={() => setIsUnpublishDialogOpen(true)}
                >
                  Stream verbergen
                  <Icon className={styles.aside_icon} icon={Icons.eyesViewOffInvisibleIcon} />
                </Button>
              ) : (
                <Button
                  className={styles.action_button}
                  size={EButtonSize.SMALL}
                  type={EButtonType.BLANK}
                  data-testid="publish-show-button"
                  onClick={() => handlePublish(true)}
                >
                  Erneut veröffentlichen
                  <Icon className={styles.aside_icon} icon={Icons.eyesViewOnVisibleIcon} />
                </Button>
              )}
              {!showDetails.startedAt && (
                <Button
                  className={styles.action_button}
                  size={EButtonSize.SMALL}
                  type={EButtonType.BLANK}
                  data-testid="delete-show-button"
                  onClick={() => setIsDeletionDialogOpen(true)}
                >
                  {t.creators.show['Delete Stream']}
                  <IconTrash className={styles.aside_icon} width="20px" height="20px" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewShowDetails;
