import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { secondsIntoMinutes } from '../../../common/utils';
import VideoStatusBadge from '../../../components/VideoStatusBadge/VideoStatusBadge';
import routePaths from '../../../routes/routePaths';
import { SectionVideo } from '../../model/academy';
import styles from './VideoCard.module.scss';

interface Props {
  video: SectionVideo;
  column?: boolean;
  onVideoClick?: () => void;
}

function VideoCard({ video, column, onVideoClick }: Props) {
  const history = useHistory();

  const onClick = () => {
    onVideoClick && onVideoClick();
    history.push(routePaths.creator.academy.overview + `/${video.id}`);
  };
  return (
    <Box
      key={video.id}
      display={'flex'}
      flexDirection={column ? 'column' : 'row'}
      alignItems={column ? 'flex-start' : 'center'}
      gap={2}
      onClick={onClick}
      className={styles.video}
    >
      <Box
        className={classNames(styles.preview_image, {
          [styles.row]: !column,
          [styles.column]: column,
        })}
      >
        <img src={`${video.previewImageUrl}?imwidth=320`} alt={video.title} />
      </Box>
      <Box className={styles.video_details}>
        <Typography variant={'body1'}>{video.title}</Typography>
        <Typography variant={'caption'}>
          {secondsIntoMinutes(video.durationInSeconds)} min
        </Typography>
      </Box>
      {!column && <VideoStatusBadge status={video.status} />}
    </Box>
  );
}

export default VideoCard;
