import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { GridColDef, GridFeatureModeConstant, GridRowParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FloorSale } from '../../../common/types';
import t from '../../../constants/translation';
import CreatorsTableStatusCell from '../../../creators/components/CreatorsTable/StatusCell/CreatorsTableStatusCell';
import DataGridTable from '../../../creators/components/DataGridTable/DataGridTable';
import { CreatorOverview, displayName } from '../../../creators/model/creator';
import { navigateToCreatorDetailsPage, TableFilter } from '../../../creators/state/creatorsSlice';
import IconCard from '../../../shows/component/IconCard/IconCard';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { mapProgressToSortOrder } from '../../../utils/onboardingMapper';
import {
  selectAgencyStreamersOverviewTableFilter,
  setAgencyStreamersOverviewTableFilter,
} from '../../state/agenciesSlice';
import styles from './StreamersTable.module.scss';

interface Props {
  creators: CreatorOverview[];
  isLoading?: boolean;
}

const StreamersTable = ({ creators, isLoading = false }: Props) => {
  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', width: 300 },
    {
      field: 'role',
      headerName: 'Rolle',
      width: 150,
      valueFormatter: ({ value }) => displayName(value),
    },
    { field: 'email', headerName: 'Email', width: 300 },
    {
      field: 'streamedSeconds',
      width: 180,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: ({ value }) => formatTime(value),
      renderHeader: () => (
        <IconCard
          text={t.creators['Streaming time']}
          textStyle={{ fontWeight: 'bold' }}
          icon={<VideocamOutlinedIcon />}
        />
      ),
    },
    {
      field: 'floorSales',
      headerName: t.common.Revenue,
      width: 180,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.floorSales,
      valueFormatter: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      renderHeader: () => (
        <IconCard
          text="Umsatz"
          textStyle={{ fontWeight: 'bold' }}
          icon={<CreditCardOutlinedIcon />}
        />
      ),
      sortComparator: (v1: FloorSale, v2: FloorSale) => v1.amount - v2.amount,
    },
    {
      field: 'showsFloorSales',
      headerName: t.creators['Streams Revenue'],
      width: 160,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text={t.creators['Streams Revenue']}
          textStyle={{ fontWeight: 'bold' }}
          icon={<CreditCardOutlinedIcon />}
        />
      ),
      valueFormatter: params => germanMoneyFormat(params.value.amount, params.value.currency),
      sortable: false,
      hide: true,
    },
    {
      field: 'postsFloorSales',
      headerName: t.creators.post['Posts Revenue'],
      width: 140,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text={t.creators.post['Posts Revenue']}
          textStyle={{ fontWeight: 'bold' }}
          icon={<CreditCardOutlinedIcon />}
        />
      ),
      valueFormatter: params => germanMoneyFormat(params.value.amount, params.value.currency),
      sortable: false,
      hide: true,
    },
    { field: 'level', headerName: 'Level', width: 100, align: 'right', headerAlign: 'right' },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      valueGetter: params => params.row,
      renderCell: ({ value }) => <CreatorsTableStatusCell creatorOverview={value} />,
      sortComparator: (value1: CreatorOverview, value2: CreatorOverview) =>
        mapProgressToSortOrder(value1) - mapProgressToSortOrder(value2),
    },
    {
      field: 'icon',
      headerName: '',
      renderCell: () => (
        <div className={styles.nav_icon}>
          <ChevronRightIcon />
        </div>
      ),
      flex: 1,
      sortable: false,
    },
  ];

  const dispatch = useDispatch();

  const tableFilter = useSelector(selectAgencyStreamersOverviewTableFilter);

  const onRowClick = (rowParams: GridRowParams) => {
    dispatch(navigateToCreatorDetailsPage({ creatorId: rowParams.row.id }));
  };

  const onFilterStateChange = (state: TableFilter) => {
    const updatedObject = {
      pagination: state.pagination,
      sorting: state.sorting,
    };

    if (!_.isEqual(updatedObject, tableFilter)) {
      dispatch(setAgencyStreamersOverviewTableFilter(updatedObject));
    }
  };

  return (
    <DataGridTable
      mode={GridFeatureModeConstant.client}
      rows={creators}
      columns={columns}
      rowsPerPageOptions={[10, 20, 50, 100]}
      quickFilterPlaceholder={'Suchen...'}
      height={630}
      loading={creators.length == 0 && isLoading} // Show loading spinner only if there are no rows in the table
      onRowClick={onRowClick}
      initialState={tableFilter}
      onStateChange={onFilterStateChange}
    />
  );
};

export default StreamersTable;
