import React, { CSSProperties } from 'react';
import { SvgIcon } from '../../assets';

interface IconProps {
  icon: SvgIcon;
  width?: number;
  height?: number;
  dataTestId?: string;
  className?: string;
  style?: CSSProperties;
}

const Icon: React.FC<IconProps> = ({
  icon,
  width = 24,
  height = 24,
  dataTestId,
  className,
  style,
}) => {
  const Svg = icon;

  return (
    <Svg
      width={width}
      height={height}
      className={className}
      style={style}
      data-testid={dataTestId}
    />
  );
};

export default Icon;
