import { useState } from 'react';
import { DateFilterMode, DateFilterType, DateFilterTypes } from '../dateFilterTypes';
import FilterButton from '../FilterButton/FilterButton';
import styles from './DateFilterButtons.module.scss';

type FilterButton = {
  filterType: DateFilterType;
  label: string;
};

interface DateFilterButtonsProps {
  onClickCallback: (selectedFilter: DateFilterTypes) => void;
  dateFilterMode: DateFilterMode;
  selectedDateFilter: DateFilterType;
}

const DateFilterButtons = ({
  onClickCallback,
  dateFilterMode,
  selectedDateFilter,
}: DateFilterButtonsProps) => {
  const [selectedFilter, setSelectedFilter] = useState<DateFilterTypes>(selectedDateFilter);

  const onSelectedFilter = (selectedFilter: DateFilterTypes) => {
    setSelectedFilter(selectedFilter);
    onClickCallback(selectedFilter);
  };

  const standardFilterButtons: FilterButton[] = [
    {
      filterType: DateFilterTypes.CUSTOM,
      label: 'Benutzerdefiniert',
    },
    {
      filterType: DateFilterTypes.MONTH,
      label: 'Monat',
    },
    {
      filterType: DateFilterTypes.TODAY,
      label: 'Heute',
    },
  ];

  const filterButtonsInPast: FilterButton[] = [
    {
      filterType: DateFilterTypes.YESTERDAY,
      label: 'Gestern',
    },
    {
      filterType: DateFilterTypes.LAST_7_DAYS,
      label: 'Letzte 7 Tage',
    },
    {
      filterType: DateFilterTypes.LAST_14_DAYS,
      label: 'Letzte 14 Tage',
    },
    {
      filterType: DateFilterTypes.LAST_28_DAYS,
      label: 'Letzte 28 Tage',
    },
  ];

  const filterButtonsInFuture: FilterButton[] = [
    {
      filterType: DateFilterTypes.TOMORROW,
      label: 'Morgen',
    },
    {
      filterType: DateFilterTypes.NEXT_7_DAYS,
      label: 'Nächste 7 Tage',
    },
    {
      filterType: DateFilterTypes.NEXT_14_DAYS,
      label: 'Nächste 14 Tage',
    },
    {
      filterType: DateFilterTypes.NEXT_28_DAYS,
      label: 'Nächste 28 Tage',
    },
  ];

  const resolveButtonsSet = () =>
    dateFilterMode === DateFilterMode.FUTURE
      ? [...standardFilterButtons, ...filterButtonsInFuture]
      : [...standardFilterButtons, ...filterButtonsInPast];

  return (
    <div className={styles.buttons_container}>
      <div className={styles.buttons_slider}>
        {resolveButtonsSet().map(buttonDetails => (
          <FilterButton
            currentFilter={selectedFilter}
            filterSelectedCallback={onSelectedFilter}
            filterType={buttonDetails.filterType}
            label={buttonDetails.label}
            key={buttonDetails.filterType}
          />
        ))}
      </div>
    </div>
  );
};

export default DateFilterButtons;
