export enum NativeMessageType {
  STORAGE_EVENT = 'storageEvent',
  CLICK_EVENT = 'clickEvent',
  LOGIN_EVENT = 'loginEvent',
  LOGOUT_EVENT = 'logoutEvent',
  REDIRECTION = 'redirection',
}

export interface NativeMessageEvent<T> {
  readonly type: NativeMessageType;
  readonly body: T;
}

type LocalStorageBody = {
  key: string;
  value: string | null;
};

type ClickEventBody = {
  element: string;
  id?: string;
};

export enum Target {
  BLANK = '_blank',
  SELF = '_self',
}

interface RedirectionBody {
  link: string;
  target: Target;
}

export enum ClickEventElement {
  CHANGE_SHOW_IMAGES = 'changeShowImages',
  START_STREAMING = 'startStreaming',
  SHARE_SHOW = 'shareShow',
  OPEN_CONSENT = 'openConsent',
  DISPLAY_PDP = 'displayPdp',
}

export function buildLocalStorageMessageEvent(
  key: string,
  value: string | null
): NativeMessageEvent<LocalStorageBody> {
  return {
    type: NativeMessageType.STORAGE_EVENT,
    body: {
      key,
      value,
    },
  };
}

export function buildClickMessageEvent(
  element: ClickEventElement,
  id?: string
): NativeMessageEvent<ClickEventBody> {
  return {
    type: NativeMessageType.CLICK_EVENT,
    body: {
      element,
      id,
    },
  };
}

export function buildRedirectionEventBody(
  link: string,
  target = Target.BLANK
): NativeMessageEvent<RedirectionBody> {
  return {
    type: NativeMessageType.REDIRECTION,
    body: {
      link,
      target,
    },
  };
}
