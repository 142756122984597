import EditIcon from '@mui/icons-material/Edit';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import theme from '../../../../theme/theme.module.scss';
import { AmaUpdateRequest } from '../../api/amasRequestResponse';
import AmaManagementForm from '../../components/AmaManagementForm/AmaManagementForm';
import { fetchAmaByIdAction, selectAma, updateAmaByIdAction } from '../../state/amasSlice';

const AmaUpdatePage = () => {
  const dispatch = useAppDispatch();

  const { amaId: id } = useParams<{ amaId: string }>();
  if (!id) return null;

  const amaRequest = useAppSelector(selectAma)?.request;

  useEffect(() => {
    if (id) dispatch(fetchAmaByIdAction(id));
  }, [id]);

  if (!amaRequest) return null;

  const handleFormSubmit = (request: AmaUpdateRequest) => {
    dispatch(updateAmaByIdAction({ id, request }));
  };

  const AmaUpdate = () => (
    <AmaManagementForm
      id={id}
      title={'AMA ändern'}
      initialValues={{ request: amaRequest }}
      submitButton={{ title: 'Ändern', icon: <EditIcon /> }}
      handleFormSubmit={handleFormSubmit}
      isUpdate
    />
  );

  return <PageContainer fullHeight backgroundColor={theme.whiteLilac} pageToRender={AmaUpdate()} />;
};

export default AmaUpdatePage;
