import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { germanFormatWithoutDecimal } from '../../../../../utils/formatters/germanFormat/germanFormats';
import { fetchCreatorStatistics, selectCreatorStatistics } from '../../../../state/creatorsSlice';
import styles from './Statistics.module.scss';

interface StatisticsProps {
  creatorId: string;
}

const Statistics = ({ creatorId }: StatisticsProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCreatorStatistics({ creatorId: creatorId }));
  }, [creatorId]);

  const VerticalLine = () => <div className={styles.vertical_line}></div>;

  const creatorStatistics = useSelector(selectCreatorStatistics);
  if (creatorStatistics === null) return null;
  return (
    <div data-testid="stats-container" className={styles.container}>
      <div className={styles.statistic}>
        <span className={styles.count}>
          {germanFormatWithoutDecimal(creatorStatistics.followerCount)}
        </span>
        <span className={styles.label}>Folgen</span>
      </div>
      <VerticalLine />
      <div className={styles.statistic}>
        <span className={styles.count}>
          {germanFormatWithoutDecimal(creatorStatistics.showsStats.likesCount)}
        </span>
        <span className={styles.label}>Likes</span>
      </div>
      <VerticalLine />
      <div className={styles.statistic}>
        <span className={styles.count}>
          {germanFormatWithoutDecimal(creatorStatistics.showsStats.viewsCount)}
        </span>
        <span className={styles.label}>Views</span>
      </div>
    </div>
  );
};

export default Statistics;
