import { Col, Row } from '@hse24/shared-components';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import Config from '../../../config/config';
import { IPage } from '../../model/page';
import { ProductTileInfo } from '../../model/productTileInfo';
import { mapProductTileInfoToProductTileProduct } from '../../utils/productTileInfoMapper';
import { ProductTile } from '../ProductTile/ProductTile';
import styles from './SearchResults.module.scss';

interface SearchResultsProps {
  pages: IPage[];
}

const resolveSku = (product: ProductTileInfo) =>
  product.imageUri?.split('/')[1] || product.baseProductNo;

const resolveProductUrl = (product: ProductTileInfo) => {
  const sku = resolveSku(product);
  return sku === product.baseProductNo
    ? `${Config.env.pdpUrl}/product/${product.baseProductNo}`
    : `${Config.env.pdpUrl}/product/${product.baseProductNo}?v=${sku}&filter`;
};

const getSortedProducts = (pageProducts: ProductTileInfo[], sorting?: string) =>
  sorting?.includes('Price')
    ? [...pageProducts].sort((a, b) =>
        sorting.includes('desc') ? b.price.value - a.price.value : a.price.value - b.price.value
      )
    : pageProducts;

const SearchResults = ({ pages }: SearchResultsProps) => {
  const location = useLocation();
  const sorting = new URLSearchParams(location.search).get('sort') || undefined;

  const handleProductTileClick = (product: ProductTileInfo) => {
    const newWindow = window?.open(resolveProductUrl(product), '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Row className={styles.searchResultsList}>
      {pages &&
        pages.map(page => {
          return getSortedProducts(page.products, sorting).map((product, index) => {
            return (
              <Col
                s={6}
                ms={3}
                md={3}
                withPadding={false}
                className={cx(styles.searchResultsItem, 'relative')}
                key={index}
              >
                <ProductTile
                  product={mapProductTileInfoToProductTileProduct(product)}
                  isProdEnv={Config.env.isProdEnv}
                  onClick={() => handleProductTileClick(product)}
                />
              </Col>
            );
          });
        })}
    </Row>
  );
};

export default SearchResults;
