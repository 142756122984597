import { FC } from 'react';
import styles from './PagingBar.module.scss';

interface ProductsPagingBarProps {
  hitsPerPage: number;
  maxVisitedPage: number;
  minVisitedPage: number;
  totalHits: number;
}

const PagingBar: FC<ProductsPagingBarProps> = ({
  hitsPerPage,
  maxVisitedPage,
  minVisitedPage,
  totalHits,
}) => {
  const hitsBefore = (minVisitedPage - 1) * hitsPerPage;
  const productsLoaded = Math.min(
    (maxVisitedPage - minVisitedPage + 1) * hitsPerPage,
    totalHits - hitsBefore
  );

  return (
    <div className={styles.bar}>
      <div
        className={styles.loaded}
        style={{
          left: `${(hitsBefore / totalHits) * 100}%`,
          width: `${(productsLoaded / totalHits) * 100}%`,
        }}
      />
    </div>
  );
};

export default PagingBar;
