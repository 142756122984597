import { Divider } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { germanMonth } from '../../../utils/dates';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { Fee } from '../../model/Payments';
import { selectInvoiceDetails, selectInvoiceDetailsCreator } from '../../state/paymentsSlice';
import FeeCard from '../FeeCard/FeeCard';
import styles from './InvoiceDetails.module.scss';

function InvoiceDetails() {
  const creator = useSelector(selectInvoiceDetailsCreator);
  const invoice = useSelector(selectInvoiceDetails);

  if (!invoice) return <></>;

  const date = dayjs(new Date(invoice.generationDate)).format('DD.MM.YYYY');
  const periodStart = new Date(invoice.periodStart);
  const dateSubject = germanMonth(periodStart) + ' / ' + periodStart.getFullYear();
  const feeCurrency = invoice.currency;
  const fees = invoice.invoiceDetails.fees;
  const totals = invoice.invoiceDetails.totals;
  const tax = invoice.invoiceDetails.tax;

  return (
    <div>
      <div className={styles.text_content}>
        <div className={styles.text} data-testid="invoiceHSEAddress">
          <div>Home Shopping Europe GmbH</div>
          <div>Münchener Straße 101h</div>
          <div>85737 Ismaning</div>
        </div>
      </div>

      <div className={styles.text_row}>
        <div className={styles.text_content}>
          {creator && (
            <div className={styles.text} data-testid="invoiceRecipientAddress">
              <div>
                {creator?.firstName} {creator?.lastName}.
              </div>
              <div>
                {creator?.street} {creator?.streetNumber}
              </div>
              <div>
                {creator?.zipCode} {creator?.city}
              </div>
            </div>
          )}
        </div>
        <div className={styles.text_content}>
          <div className={classNames(styles.text, styles.text_right)}>
            <div data-testid="generationDate">{date}</div>
            <div>Gutschrifts-Nr.: {invoice.invoiceNumber}</div>
            <div>PO/KST: {invoice.invoiceDetails.costCenter}</div>
            <div>Lieferanten-Nr.: {invoice.invoiceDetails.creator?.vendorAccountId}</div>
          </div>
        </div>
      </div>

      <div className={styles.title}>
        <div className={styles.text_title}>Gutschrift Abrechnungszeitraum {dateSubject}</div>
      </div>

      {feeCurrency && (
        <>
          {fees?.map((e: Fee, i: number) => (
            <div key={i}>
              <FeeCard title={e.title} value={`${germanMoneyFormat(e.fee)}`} dataTestId="fees" />
              {i !== fees.length - 1 && <Divider className={styles.divider} />}
            </div>
          ))}

          {totals && (
            <FeeCard
              className={styles.net_total}
              title="Nettosumme"
              value={`${germanMoneyFormat(totals.net)}`}
              dataTestId="net"
            />
          )}

          {tax && (
            <FeeCard
              title={tax.title}
              value={tax.value !== undefined ? `${germanMoneyFormat(tax.value)}` : ''}
              dataTestId="tax"
            />
          )}

          <Divider className={styles.divider} />

          {totals && (
            <FeeCard
              className={styles.gross_total}
              title="Bruttobetrag"
              value={`${germanMoneyFormat(totals.gross)}`}
              dataTestId="gross"
            />
          )}
        </>
      )}

      {creator?.taxId && <FeeCard title="Steueridentifikationsnummer" value={creator.taxId} />}

      {creator?.taxNumber && !creator?.taxId && (
        <FeeCard title="Steuernummer" value={creator.taxNumber} dataTestId="taxNumber" />
      )}
      {creator?.vatId && (
        <FeeCard
          className={styles.vat_id}
          title="Umsatzsteuer-Identifikations-Nr. (USt-IdNr.):"
          value={creator.vatId}
          dataTestId="vatId"
        />
      )}
    </div>
  );
}

export default InvoiceDetails;
