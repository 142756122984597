import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import PreviewIcon from '@mui/icons-material/Preview';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapQueryParamsToPageable } from '../../../common/queryParams';
import t from '../../../constants/translation';
import ServerSideDataGrid, {
  Pageable,
} from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import { TableFilter } from '../../../creators/state/creatorsSlice';
import { PostStatus } from '../../model/post';
import { selectAdminPostsOverview, setAdminPostsOverview } from '../../state/postsSlice';
import PostImage from '../PostImage/PostImage';
import PostsMenuOptions from '../PostMenuAction/PostsMenuOptions';
import styles from './AdminPostsTable.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTypography = ({ children }: { children: ReactNode }) => (
  <Typography
    variant={'body2'}
    sx={{
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
    }}
  >
    {children}
  </Typography>
);

const columns: GridColDef[] = [
  {
    field: 'imageUrl',
    headerName: 'Post',
    width: 70,
    renderHeader: () => <Typography variant={'body1'}>Post</Typography>,
    renderCell: ({ value, row }) => (
      <PostImage
        value={value}
        caption={row.caption}
        isInVisible={row.status !== PostStatus.PUBLISHED}
      />
    ),
  },
  {
    field: 'createdAt',
    headerName: 'Caption',
    flex: 2,
    minWidth: 220,
    renderHeader: () => <Typography variant={'body1'}>{t.creators.post['Details']}</Typography>,
    renderCell: ({ row }) => (
      <>
        {row.status !== PostStatus.PUBLISHED && (
          <Chip
            avatar={<VisibilityOffIcon fontSize={'small'} />}
            label={
              row.status === PostStatus.UNPUBLISHED_CREATOR
                ? 'Verborgen von Streamer'
                : 'Verborgen von HSE'
            }
            variant="outlined"
            size={'small'}
          />
        )}
        <Box>
          <CustomTypography>{row.caption}</CustomTypography>
          <Typography variant={'overline'}>
            {row.createdAt ? dayjs(row.createdAt).format('DD.MM.YYYY') : ''}
          </Typography>
        </Box>
      </>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    hide: true,
  },
  {
    field: 'caption',
    headerName: 'caption',
    hide: true,
  },
  {
    field: 'viewsCount',
    headerName: t.creators['Views'],
    minWidth: 160,
    flex: 1,
    renderHeader: () => (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <PreviewIcon />
        <Typography variant={'body1'}>{t.creators['Views']}</Typography>
      </Box>
    ),
    renderCell: ({ value }) => <>{value}</>,
    align: 'center',
  },
  {
    field: 'followsCount',
    headerName: t.creators.post['followers'],
    minWidth: 190,
    flex: 1,
    renderHeader: () => (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <GroupAddOutlinedIcon />
        <Typography variant={'body1'}>{t.creators.post['followers']}</Typography>
      </Box>
    ),
    renderCell: ({ value }) => <>{`+ ${value}`}</>,
    align: 'center',
  },
  {
    field: 'likes',
    headerName: 'Likes',
    minWidth: 120,
    flex: 1,
    renderHeader: () => (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <FavoriteBorderOutlinedIcon />
        <Typography variant={'body1'}>Likes</Typography>
      </Box>
    ),
    renderCell: ({ value }) => <>{value}</>,
    align: 'center',
  },
  {
    field: 'commentsCount',
    headerName: 'Comments',
    minWidth: 120,
    flex: 1,
    renderHeader: () => (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <CommentOutlinedIcon />
        <Typography variant={'body1'}>Kommentare</Typography>
      </Box>
    ),
    renderCell: ({ value }) => <Box>{value}</Box>,
    align: 'center',
  },
  {
    field: 'shares',
    headerName: 'Shares',
    minWidth: 120,
    flex: 1,
    renderHeader: () => (
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <ShareOutlinedIcon />
        <Typography variant={'body1'}>Shares</Typography>
      </Box>
    ),
    renderCell: ({ value }) => <>{value}</>,
    align: 'center',
  },
  {
    field: 'post',
    headerName: '',
    width: 70,
    sortable: false,
    renderCell: ({ row }) => (
      <PostsMenuOptions
        post={row}
        isPublished={row.status === PostStatus.PUBLISHED}
        isAdmin={true}
      />
    ),
    align: 'center',
  },
];

const AdminPostsTable = () => {
  const dispatch = useDispatch();
  const adminPostsOverview = useSelector(selectAdminPostsOverview);

  const updatePageableCallback = (pageable: Pageable) => {
    const queryParams = {
      from: adminPostsOverview.queryParams.from,
      to: adminPostsOverview.queryParams.to,
      pageSize: pageable.pageSize,
      pageNumber: pageable.page,
      sortField: pageable.sortField,
      sortOrder: pageable.sortOrder,
      searchTerm: pageable.searchTerm,
      audience: adminPostsOverview.queryParams.audience,
    };
    if (!_.isEqual(queryParams, adminPostsOverview.queryParams))
      dispatch(setAdminPostsOverview({ queryParams }));
  };

  const onFilterStateChange = (state: TableFilter) => {
    const updatedObject = {
      pagination: state.pagination,
      sorting: state.sorting,
    };

    if (!_.isEqual(updatedObject, adminPostsOverview.tableFilter)) {
      dispatch(
        setAdminPostsOverview({
          tableFilter: {
            pagination: state.pagination,
            sorting: state.sorting,
          },
        })
      );
    }
  };

  return (
    <div className={styles.table_wrapper}>
      <ServerSideDataGrid
        columns={columns}
        rows={adminPostsOverview.posts}
        rowCount={adminPostsOverview.hits}
        loading={adminPostsOverview.loading}
        rowsPerPageOptions={[10, 50, 100, 1000]}
        height={630}
        quickFilterPlaceholder={'Suchen...'}
        enableColumnSelector
        isExportEnabled
        onStateChange={onFilterStateChange}
        updatePageableCallback={updatePageableCallback}
        initialPageable={mapQueryParamsToPageable(adminPostsOverview.queryParams)}
        getRowClassName={params =>
          `published-post-row--${params.row.status === PostStatus.PUBLISHED}`
        }
        sx={{
          border: '1px solid #dde1e7',
          borderRadius: '8px',
          '& .MuiDataGrid-cell': {
            alignItems: 'flex-start',
            padding: '16px',
            flexDirection: 'column',
            gap: '8px',
            whiteSpace: 'pre-wrap',
          },
          '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
            whiteSpace: 'pre-wrap',
          },
          '& .MuiDataGrid-row': {
            minHeight: 100,
            maxHeight: 200,
          },
          '& .published-post-row--false': {
            background: '#f5f5f5',
            '&:hover': {
              background: '#f5f5f5',
            },
          },
        }}
        dataGridProps={{
          rowHeight: 120,
          headerHeight: 80,
        }}
      />
    </div>
  );
};
export default AdminPostsTable;
