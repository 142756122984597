import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import t from '../../../constants/translation';
import { PostUploadStatus } from '../../model/post';
import { FileMetaData, setS3UploadStatus, uploadPostMediaToS3 } from '../../state/postsSlice';
import {
  getPostUploadFileType,
  getReadableFileSize,
  shortenFileName,
} from '../../utils/MediaUtils';
import styles from './UploadStatus.module.scss';
import { UploadStatusFileIcon } from './UploadStatusFileIcon/UploadStatusFileIcon';
import { UploadStatusIcon } from './UploadStatusIcon/UploadStatusIcon';
interface UploadStatusProps {
  fileMetaData: FileMetaData;
}
export const UploadStatus = ({ fileMetaData }: UploadStatusProps) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<PostUploadStatus>(PostUploadStatus.IN_PROGRESS);

  useEffect(() => {
    if (!fileMetaData.isSupported) {
      setStatus(PostUploadStatus.ERROR);
      return;
    } else if (fileMetaData.preSignedUrl && fileMetaData.preSignedUrl.length > 0) {
      fileMetaData && fileMetaData.uploadStatus
        ? setStatus(fileMetaData.uploadStatus)
        : setStatus(PostUploadStatus.IN_PROGRESS);
    } else {
      setStatus(PostUploadStatus.IN_PROGRESS);
    }
  }, [fileMetaData]);

  useEffect(() => {
    dispatch(
      setS3UploadStatus({
        preSignedUrl: fileMetaData.preSignedUrl,
        status: PostUploadStatus.IN_PROGRESS,
      })
    );
    dispatch(
      uploadPostMediaToS3({ fileUrl: fileMetaData.url, preSignedUrl: fileMetaData.preSignedUrl })
    );
  }, []);

  return (
    <div className={styles.container} data-testid="upload-status">
      <div className={styles.file_icon}>
        <UploadStatusFileIcon fileType={getPostUploadFileType(fileMetaData.type)} />
      </div>
      <div className={styles.file_description}>
        <p className={styles.file_name}>{`${t.creators.post.transferringTheFile} "${shortenFileName(
          fileMetaData.name
        )}"`}</p>
        {fileMetaData.isSupported ? (
          <p className={styles.file_size}>{getReadableFileSize(fileMetaData.size)}</p>
        ) : (
          <p className={styles.unsupported_file_format}>{t.creators.post.unsupportedFileFormat}</p>
        )}
      </div>
      <div className={styles.upload_status}>
        <UploadStatusIcon status={status} />
      </div>
    </div>
  );
};
