import { TextFieldProps } from '@mui/material/TextField/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'date-fns';
import { format } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '../../../../components/TextField/TextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import { profilePersonalDataKeys } from '../ProfilePersonalData';

const BirthdatePicker = () => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { values, setFieldValue, touched } = useFormikContext<any>();
  const profileData = useSelector(selectLoggedInCreator);
  const hasCompletedProfile = profileData?.onboarding.hasCompletedProfile;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const birthdate = profileData?.birthDate;
  const birthdateKey = profilePersonalDataKeys.birthdate;

  useEffect(() => {
    setFieldValue(birthdateKey, birthdate, false);
  }, [birthdate]);

  function doFormat(date: Date): string {
    try {
      return format(date, 'yyyy-MM-dd');
    } catch (e: unknown) {
      return 'Invalid Date';
    }
  }

  const dateToString = (date: Date): string => (date ? doFormat(date) : '');

  const onChange = (date: Date | null) => {
    if (date) {
      const dateString = dateToString(date);
      setFieldValue(birthdateKey, dateString);
    }
  };

  const min18YearsAgo = () => {
    const today = new Date();
    return new Date(today.setFullYear(today.getFullYear() - 18));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <DatePicker
        label="Geburtstag*"
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        onChange={onChange}
        onClose={() => {
          setIsOpen(false);
        }}
        open={!hasCompletedProfile && isOpen}
        openTo="year"
        views={['year', 'month', 'day']}
        maxDate={min18YearsAgo()}
        renderInput={(props: TextFieldProps) => (
          <div
            ref={props.inputRef}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <TextField
              fieldName={birthdateKey}
              label={props.label as string}
              value={birthdate}
              disabled={hasCompletedProfile}
            />
          </div>
        )}
        disableFuture
        onOpen={() => {
          if (!touched[birthdateKey] && !values[birthdateKey]) {
            // workaround to see latest years without scrolling when selecting the date for the first time
            setFieldValue(birthdateKey, min18YearsAgo());
          }
        }}
        disabled={hasCompletedProfile}
        value={values[birthdateKey] || null}
      />
    </LocalizationProvider>
  );
};

export default BirthdatePicker;
