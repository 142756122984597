import { SearchParams } from './searchParams';

export interface TSortItem {
  description?: string;
  name: string;
  order: string; // 'asc' | 'desc'
  linkTarget?: string;
  selected?: boolean;
  href?: string;
  searchParams?: SearchParams;
}

export const EAllowedSort: EAllowedSortName = {
  PRICE_ASC: { key: 'Price', order: 'asc' },
  PRICE_DESC: { key: 'Price', order: 'desc' },
  NEW_DESC: { key: 'NewInShop', order: 'desc' },
};

interface EAllowedSortName {
  [key: string]: EAllowedSortInstance;
}

interface EAllowedSortInstance {
  key: string;
  order: string;
}
