import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, IconButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import ModalTitle from '../../../../components/ModalTitle/ModalTitle';
import t from '../../../../constants/translation';
import { ProductTileInfo } from '../../../model/productTileInfo';
import {
  clearProductsSelectionResponse,
  fetchOrdersProductsSelectionSearch,
  fetchProductsSelectionSearch,
} from '../../../state/productsSlice';
import ProductSelectionDialogSearchInput from '../ProductSelectionDialogSearchInput/ProductSelectionDialogSearchInput';
import ProductSelectionItem from '../ProductSelectionItem/ProductSelectionItem';
import styles from './ProductSelectionDialogHeader.module.scss';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  selectedProducts: ProductTileInfo[];
  onClose: () => void;
  isExpanded: boolean;
  isScrolling: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
  onDone: () => void;
  onRemoveProduct: (product: ProductTileInfo) => void;
  query: string;
  setQuery: (value: string) => void;
}

function ProductSelectionDialogHeader({
  selectedProducts,
  onClose,
  isExpanded,
  setIsExpanded,
  isScrolling,
  onRemoveProduct,
  setQuery,
  query,
}: Props) {
  const dispatch = useDispatch();

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  const onQueryChangeHandler = (value: string) => {
    setQuery(value);
    if (!value.length) {
      dispatch(clearProductsSelectionResponse());
      return dispatch(fetchOrdersProductsSelectionSearch());
    }
    return dispatch(fetchProductsSelectionSearch({ query: value }));
  };

  return (
    <Box
      className={classNames(styles.dialog_header, {
        [styles.border]: isScrolling,
      })}
    >
      <ModalTitle title={t.common['add item']} handleModalClose={onClose}></ModalTitle>
      <ProductSelectionDialogSearchInput onChange={onQueryChangeHandler} value={query} />
      {selectedProducts.length > 0 && (
        <Box className={styles.selected_products}>
          <Box className={styles.selected_header} onClick={handleExpandClick}>
            <Box className={styles.inline_display}>
              <Typography className={styles.selected_products_string} variant={'body1'}>
                {t.common['Selected']}
              </Typography>
              <Typography
                className={styles.selected_products_number}
                fontWeight={400}
                fontSize={14}
              >
                {selectedProducts.length}
              </Typography>
            </Box>
            <Box color={'black'}>
              <ExpandMore expand={isExpanded}>
                <ExpandMoreIcon color={'inherit'} />
              </ExpandMore>
            </Box>
          </Box>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box className={styles.horizontal_list}>
              {selectedProducts.map(product => (
                <ProductSelectionItem
                  key={product.baseProductNo}
                  product={product}
                  onRemoveHandler={() => onRemoveProduct(product)}
                  direction={'column'}
                />
              ))}
            </Box>
          </Collapse>
        </Box>
      )}
    </Box>
  );
}

export default ProductSelectionDialogHeader;
