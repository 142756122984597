import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatRoom, ConnectionState, DisconnectReason } from 'amazon-ivs-chat-messaging';
import { RootState } from '../../app/store';

export interface StreamChatMessage {
  message: string;
  receivedTime: string;
  count: number;
  username?: string;
  type?: string;
  isStreamerFollower?: boolean;
}

export interface StreamChatState {
  chatRoom?: ChatRoom;
  state?: ConnectionState;
  disconnectReason?: DisconnectReason;
  messages: StreamChatMessage[];
  reactions: number;
  joinerUsername?: string;
  followerUsername?: string;
}

export const streamChatInitialState: StreamChatState = {
  chatRoom: undefined,
  state: undefined,
  disconnectReason: undefined,
  messages: [],
  reactions: 0,
  joinerUsername: undefined,
  followerUsername: undefined,
};

export const getStreamChat = createAction('streamChat/getStreamChat');
export const getChatMessagesLive = createAction<string>('ivsChat/getChatMessagesLive');
export const initializeChat = createAction<string>('ivsChat/initializeChat');

export const streamChatSlice = createSlice({
  name: 'streamChat',
  initialState: streamChatInitialState,
  reducers: {
    setMessages(state, action: PayloadAction<StreamChatMessage[]>) {
      state.messages = state.messages.concat(action.payload);
    },
    setJoinerUsername(state, action: PayloadAction<string | undefined>) {
      state.joinerUsername = action.payload;
    },
    setFollowerUsername(state, action: PayloadAction<string | undefined>) {
      state.followerUsername = action.payload;
    },
    clearChat(state) {
      state.chatRoom = undefined;
      state.state = undefined;
      state.disconnectReason = undefined;
      state.messages = [];
      state.reactions = 0;
      state.joinerUsername = undefined;
      state.followerUsername = undefined;
    },
  },
});

export const { setMessages, setJoinerUsername, clearChat, setFollowerUsername } =
  streamChatSlice.actions;

export default streamChatSlice.reducer;

export const streamChatSelector = (state: RootState) => state.streamChat;
export const streamChatSelectors = {
  chatRoom: createSelector(streamChatSelector, streamChat => streamChat.chatRoom),
  state: createSelector(streamChatSelector, streamChat => streamChat.state),
  disconnectReason: createSelector(streamChatSelector, streamChat => streamChat.disconnectReason),
  messages: createSelector(streamChatSelector, streamChat => streamChat.messages),
  reactions: createSelector(streamChatSelector, streamChat => streamChat.reactions),
  joinerUsername: createSelector(streamChatSelector, streamChat => streamChat.joinerUsername),
  followerUsername: createSelector(streamChatSelector, streamChat => streamChat.followerUsername),
};
