import { MediaType } from '../api/postsRequestResponse';
import { Media } from '../state/postsSlice';

export interface PostImageMetadata {
  fileExtension: string;
  imageUrl: string;
  mediaType?: MediaType;
}

export interface PostData {
  preview: PostImageMetadata;
  caption: string;
  baseProductsNo: string[];
  scheduleAt?: string;
}
export interface AdminPostData extends Omit<PostData, 'preview'> {
  medias: Media[];
  salesTheme?: string;
}

export interface StreamPostData {
  preview?: PostImageMetadata | null;
  caption?: string | null;
  baseProductsNo?: string[];
  scheduleAt?: string;
  salesTheme?: string;
  creatorId?: string;
}

export interface AdminStreamPostData extends Omit<StreamPostData, 'preview'> {
  medias: Media[];
}

export interface Post {
  id: string;
  caption: string;
  imageUrl: string;
  likes: number;
  shares: number;
  commentsCount: number;
  createdAt: string;
  status: PostStatus;
  scheduleAt?: string;
  salesTopic?: string;
}

export enum PostStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED_HSE_EMPLOYEE = 'UNPUBLISHED_HSE_EMPLOYEE',
  UNPUBLISHED_CREATOR = 'UNPUBLISHED_CREATOR',
}

export enum PostUploadStatus {
  IN_PROGRESS,
  SUCCESS,
  ERROR,
}
export enum PostUploadFileType {
  IMAGE,
  VIDEO,
  FILE,
}

export enum ScheduledWhen {
  IMMEDIATELY = 'SOFORT',
  PLANNED = 'GEPLANT',
}
