import { LoadingSpinner } from '@hse24/shared-components';
import styles from './SearchLoading.module.scss';

const SearchLoading = () => (
  <div className={styles.searchLoading} data-testid="loading-spinner">
    <LoadingSpinner className="w-48 h-48" />
  </div>
);

export default SearchLoading;
