import { Theme, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserIsAuthenticated } from '../../auth/state/authSlice';
import BottomNavbar from '../BottomNavbar/BottomNavbar';
import DesktopFooter from './DesktopFooter/DesktopFooter';

const Footer = ({ hideBottomHeader }: { hideBottomHeader: boolean }) => {
  const isAuthenticated = useSelector(selectUserIsAuthenticated);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return !hideBottomHeader && isMobile && isAuthenticated ? <BottomNavbar /> : <DesktopFooter />;
};

export default Footer;
