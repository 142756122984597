import { Role } from '../../auth/role';
import AmaCreatePage from '../../onDemandInteractions/amas/pages/AmaCreatePage/AmaCreatePage';
import AmaDashboardPage from '../../onDemandInteractions/amas/pages/AmaDashboardPage/AmaDashboardPage';
import AmaDisplayPage from '../../onDemandInteractions/amas/pages/AmaDisplayPage/AmaDisplayPage';
import AmaUpdatePage from '../../onDemandInteractions/amas/pages/AmaUpdatePage/AmaUpdatePage';
import { RouteDetails } from '../routeDetails';
import routePaths from '../routePaths';

export const amaRoutes: Record<string, RouteDetails> = {
  amaDashboard: {
    path: routePaths.creator.onDemandInteractions.ama.dashboard,
    component: AmaDashboardPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  amaCreate: {
    path: routePaths.creator.onDemandInteractions.ama.create,
    component: AmaCreatePage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  amaUpdate: {
    path: routePaths.creator.onDemandInteractions.ama.update,
    component: AmaUpdatePage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  amaDisplay: {
    path: routePaths.creator.onDemandInteractions.ama.display,
    component: AmaDisplayPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
};
