import QuizIcon from '@mui/icons-material/Quiz';
import { Box, DialogContentText } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import {
  hideAmaMessagesDialog,
  selectAmaMessageDialog,
} from '../../notifications/state/notificationsSlice';
import routePaths from '../../routes/routePaths';
import StyledDialog from '../StyledDialog/StyledDialog';
import styles from './AmaMessageDialog.module.scss';

function AmaMessageDialog() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen, showId, amaResponsesCount } = useSelector(selectAmaMessageDialog);

  const onClose = () => {
    dispatch(hideAmaMessagesDialog());
  };

  const onNavigate = () => history.push(generatePath(routePaths.creator.showAma, { showId }));

  return (
    <StyledDialog
      dialogProps={{
        open: isOpen,
      }}
      close={onClose}
      header={
        <Box display={'flex'} justifyContent={'center'}>
          <Box className={styles.icon}>
            <QuizIcon />
          </Box>
        </Box>
      }
      body={
        <DialogContentText textAlign={'center'} data-testid={'content'}>
          Du hast <b>{amaResponsesCount}</b> AMA Antworten in deinem letzten Stream erhalten. Du
          kannst sie dir jetzt direkt anschauen oder jederzeit später über deine Streamübersicht.
        </DialogContentText>
      }
      submit={{
        title: 'Jetzt ansehen',
        action: onNavigate,
      }}
      cancel={{
        title: 'Nicht jetzt',
        action: onClose,
      }}
    />
  );
}

export default AmaMessageDialog;
