import React from 'react';
import { useSelector } from 'react-redux';
import t from '../../../../../src/constants/translation';
import { isMobileBreakPoint } from '../../../../common/mediaQueries';
import StyledDialog from '../../../../components/StyledDialog/StyledDialog';
import { selectShowDetailsLoading } from '../../../state/showsSlice';

interface Props {
  openDialog: boolean;
  handleClose: () => void;
  handleUnpublish: () => void;
}
function UnpublishedConfirmationDialog({ openDialog, handleClose, handleUnpublish }: Props) {
  const isMobile = isMobileBreakPoint();
  const isShowDetailsLoading = useSelector(selectShowDetailsLoading);

  const submit = () => {
    handleClose();
    handleUnpublish();
  };

  return (
    <StyledDialog
      dialogProps={{ open: openDialog, maxWidth: isMobile ? 'md' : 'xs' }}
      close={handleClose}
      title={t.creators.show['Hide stream']}
      body={t.creators.show['Do you really want to hide the stream?']}
      cancel={{
        title: t.common.Cancel,
        action: handleClose,
      }}
      submit={{
        title: t.creators.show['Hide stream'],
        loading: isShowDetailsLoading,
        action: submit,
        buttonProps: {
          color: 'primary',
        },
      }}
      testId={'unpublish-confirmation-dialog'}
    />
  );
}

export default UnpublishedConfirmationDialog;
