export const generateNavigationItemID = (data: string[]): string => {
  return data
    .filter(item => item)
    .map(item => normalize(item))
    .join('|');
};

const normalize = (s: string): string => {
  return s
    .toLowerCase()
    .replace(/\u00df/g, 'ss') // replace all 'ß' with 'ss'
    .replace(/\u00e4/g, 'ae') // replace all 'ä' with 'ae'
    .replace(/\u00f6/g, 'oe') // replace all 'ö' with 'oe'
    .replace(/\u00fc/g, 'ue') // replace all 'ü' with 'ue'
    .replace(/\s/g, '_') // replace all whitespaces with '_'
    .replace(/[^a-z\d_]/g, '') // remove all characters that are not letters, digits or hyphens
    .replace(/_{2,}/g, '_'); // replace all sequences of two or more hyphens with a single hyphen
};
