import { isPast } from 'date-fns';
import { ShowDetails } from '../../../model/shows';
import ShowCard from '../../ShowCard/ShowCard';
import ShowCardContent from '../../ShowCard/ShowCardContent/ShowCardContent';
import ShowDetailsMenu from '../ShowDetailsMenu/ShowDetailsMenu';
import UpcomingShowLabel from '../UpcomingShowLabel/UpcomingShowLabel';
import styles from './ShowCardWithShowDetails.module.scss';

interface ShowCardWithShowDetailsProps {
  showDetails: ShowDetails;
}

const ShowCardWithShowDetails = ({ showDetails }: ShowCardWithShowDetailsProps) => {
  // An Upcoming Show has no endedAt set and should be in the future
  const isUpcomingShow =
    !Boolean(showDetails.endedAt) &&
    !!showDetails.scheduledAt &&
    !isPast(new Date(showDetails.scheduledAt));

  return (
    <ShowCard previewImageUrl={showDetails.previewImageUrl}>
      <ShowCardContent className={`${styles.content} ${!isUpcomingShow ? styles.download : ''}`}>
        {isUpcomingShow && !!showDetails.scheduledAt && (
          <UpcomingShowLabel scheduledAt={showDetails.scheduledAt} />
        )}
        <div className={styles.title}>{showDetails.title}</div>
        {!isUpcomingShow && <ShowDetailsMenu showDetails={showDetails} />}
      </ShowCardContent>
    </ShowCard>
  );
};

export default ShowCardWithShowDetails;
