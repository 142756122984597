import { LocalStorageKeys } from '../constants/localStorageKeys';
import { sharedStorageService } from '../storage/sharedStorageService';

export const productionEnvironmentValues: (
  | iOSAppBuildInfoEnvironments
  | AndroidAppBuildInfoEnvironments
)[] = ['PROD', 'production'];

type iOSAppBuildInfoEnvironments = 'debug' | 'staging' | 'production';
type AndroidAppBuildInfoEnvironments = 'DEV' | 'STAGING' | 'PROD';

export type AppBuildInfo = {
  version: string;
  buildNumber: string;
  environment: AndroidAppBuildInfoEnvironments | iOSAppBuildInfoEnvironments;
};

export function isAppBuildInfo(value: Record<string, string>): value is AppBuildInfo {
  return !!(value && value['version'] && value['buildNumber'] && value['environment']);
}

export function getAppBuildInfo(): AppBuildInfo | undefined {
  try {
    const appBuildInfo = sharedStorageService.getItem(LocalStorageKeys.AppBuildInfo);
    if (!appBuildInfo) {
      return;
    }

    const parsedObject = JSON.parse(appBuildInfo);
    if (isAppBuildInfo(parsedObject)) {
      return parsedObject;
    }
  } catch (error: unknown) {
    console.error('Error while trying to get app build info: ', error);
  }
}
