import axios, { AxiosError } from 'axios';

export const parseError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError;
    return {
      status: axiosError.response?.status,
      data: axiosError.response?.data,
      config: axiosError.response?.config,
    };
  }
  const err = error as Error;
  return {
    message: err.message,
  };
};
