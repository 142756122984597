import { IconTrash } from '@hse24/shared-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import StyledDialog from '../../../../components/StyledDialog/StyledDialog';
import StyledMenu from '../../../../components/StyledMenu/StyledMenu';
import t from '../../../../constants/translation';
import AdminCreateStreamerShopLinkDialog from '../../../components/AdminCreateStreamerShopLinkDialog/AdminCreateStreamerShopLinkDialog';
import { CreatorShopLink, CreatorShopLinkData } from '../../../model/creator';
import {
  deleteCreatorShopLink,
  editCreatorShopLink,
  selectCreatorShopLinksIsLoading,
} from '../../../state/creatorsSlice';
import styles from './CreatorShopLinkMenuOptions.module.scss';

interface Props {
  shopLink: CreatorShopLink;
}

const CreatorShopLinkMenuOptions = ({ shopLink }: Props) => {
  const dispatch = useDispatch();
  const { streamerId } = useParams<{ streamerId: string }>();
  const creatorShopLinksIsLoading = useSelector(selectCreatorShopLinksIsLoading);

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [dropDownAnchor, setDropDownAnchor] = useState<null | HTMLElement>(null);

  const handleOnClose = () => {
    setDropDownAnchor(null);
  };

  const handleDeleteDialogOnClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleEditDialogOnClose = () => {
    setEditDialogOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setDropDownAnchor(event.currentTarget);

  const handleDelete = () => {
    dispatch(deleteCreatorShopLink({ creatorId: streamerId, shopLinkId: shopLink.id }));
    setDeleteDialogOpen(false);
  };

  const openDeleteDialog = () => {
    setDropDownAnchor(null);
    setDeleteDialogOpen(true);
  };

  const openEditDialog = () => {
    setDropDownAnchor(null);
    setEditDialogOpen(true);
  };

  const handleSubmit = (shopLinkData: CreatorShopLinkData) => {
    dispatch(
      editCreatorShopLink({
        creatorId: streamerId,
        shopLinkId: shopLink.id,
        shopLinkData,
      })
    );
  };

  const MenuOptions = () => (
    <div
      className={styles.menu_item_wrapper}
      onClick={openEditDialog}
      data-testid={'edit-dialog-trigger-button'}
    >
      <div className={styles.menu_item}>
        <Icon icon={Icons.pencilIcon} />
        <span>{t.creators.show.Edit}</span>
      </div>
      <div
        className={styles.menu_item_delete}
        onClick={openDeleteDialog}
        data-testid={'confirmation-dialog-trigger-button'}
      >
        <IconTrash color={'#BE0A14'} width="24px" />
        <span>Freigabe löschen</span>
      </div>
    </div>
  );

  return (
    <>
      <IconButton onClick={handleClick} data-testid={'IconButton'}>
        <MoreVertIcon data-testid={'MoreVertIcon'} />
      </IconButton>
      <StyledMenu
        open={!!dropDownAnchor}
        anchorEl={dropDownAnchor}
        data-testid={'styled-menu'}
        onClose={handleOnClose}
      >
        <MenuOptions />
      </StyledMenu>

      <StyledDialog
        close={handleDeleteDialogOnClose}
        dialogProps={{
          open: isDeleteDialogOpen,
          maxWidth: 'md',
        }}
        title={t.common['Confirm deletion']}
        body="Möchtest du diese Freigabe wirklich löschen? Dieser  Eintrag wird unwiderruflich gelöscht."
        cancel={{
          title: t.common.Cancel,
          action: handleDeleteDialogOnClose,
        }}
        submit={{
          title: 'Freigabe löschen',
          loading: creatorShopLinksIsLoading,
          action: handleDelete,
        }}
        testId="styled-dialog"
      />
      {isEditDialogOpen && (
        <AdminCreateStreamerShopLinkDialog
          isOpen={isEditDialogOpen}
          shopLinkToEdit={shopLink}
          handleSubmit={handleSubmit}
          handleClose={handleEditDialogOnClose}
        />
      )}
    </>
  );
};

export default CreatorShopLinkMenuOptions;
