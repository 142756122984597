import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/styles';

const ToggleSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 48,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#4CAF50',
        opacity: 1,
        border: 0,
        padding: '2px 2px 2px 22px',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
        backgroundColor: '#BDBDBD',
        padding: '2px 22px 2px 2px',
      },
    },

    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#fff',
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#BDBDBD',
    opacity: 1,
  },
}));

export default ToggleSwitch;
