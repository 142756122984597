import MoreVertIcon from '@mui/icons-material/MoreVert';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addPathQuery } from '../../../common/utils';
import routePaths from '../../../routes/routePaths';
import { PastShow } from '../../model/shows';
import styles from './PastShowsTableMenu.module.scss';

interface Props {
  show: PastShow;
}

const PastShowsTableMenu = ({ show }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleAma = () => {
    history.push(addPathQuery(routePaths.creator.analytics, { tab: 'Streams', showId: show.id }));
  };

  return (
    <>
      <IconButton
        id="past-shows-table-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={openMenu}
        data-testid={'past-shows-table-menu'}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="past-shows-table-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        MenuListProps={{ 'aria-labelledby': 'past-shows-table-menu-button' }}
      >
        <MenuItem data-testid={'display-ama-button'} onClick={handleAma} disableRipple>
          <QuestionAnswerIcon className={styles.item_icon} fontSize={'small'} />
          AMA
        </MenuItem>
      </Menu>
    </>
  );
};

export default PastShowsTableMenu;
