export function parseToken(token) {
    const tokenObject = parseObject(token);
    return {
        token: parseString(tokenObject === null || tokenObject === void 0 ? void 0 : tokenObject.token, 'token'),
        sessionExpirationTime: parseDateObjectOrDateString(tokenObject === null || tokenObject === void 0 ? void 0 : tokenObject.sessionExpirationTime, 'sessionExpirationTime'),
        tokenExpirationTime: parseDateObjectOrDateString(tokenObject === null || tokenObject === void 0 ? void 0 : tokenObject.tokenExpirationTime, 'tokenExpirationTime'),
    };
}
export function parseChatMessage(entity) {
    const sender = parseObject(entity.Sender, 'Sender');
    return {
        id: parseString(entity.Id, 'Id'),
        sender: {
            userId: parseString(sender.UserId, 'Sender.UserId'),
            attributes: parseChatAttributes(sender.Attributes, 'Sender.Attributes'),
        },
        content: parseString(entity.Content, 'Content'),
        sendTime: parseDateString(entity.SendTime, 'SendTime'),
        requestId: parseOptionalString(entity.RequestId, 'RequestId'),
        attributes: parseChatAttributes(entity.Attributes, 'Attributes'),
    };
}
export function parseChatAttributes(value, field) {
    if (value == null) {
        return undefined;
    }
    const objectValue = parseObject(value, field);
    Object.entries(objectValue).forEach(([key, value]) => {
        if (typeof value !== 'string') {
            throw createParsingError(`${field}: expected object with string keys & values but got '${key}' of type ${typeof value}: ${value}`);
        }
    });
    return objectValue;
}
export function parseChatEvent(entity) {
    return {
        id: parseString(entity.Id, 'Id'),
        eventName: parseString(entity.EventName, 'EventName'),
        sendTime: parseDateString(entity.SendTime, 'SendTime'),
        requestId: parseOptionalString(entity.RequestId, 'RequestId'),
        attributes: parseChatAttributes(entity.Attributes, 'Attributes'),
    };
}
export function parseDeleteMessageEvent(event) {
    var _a, _b;
    return {
        id: event.id,
        messageId: parseString((_a = event.attributes) === null || _a === void 0 ? void 0 : _a.MessageID, 'event.attributes.MessageID'),
        reason: parseOptionalString((_b = event.attributes) === null || _b === void 0 ? void 0 : _b.Reason, 'event.attributes.Reason'),
        sendTime: event.sendTime,
        requestId: event.requestId,
        attributes: event.attributes,
    };
}
export function parseDisconnectUserEvent(event) {
    var _a, _b;
    return {
        id: event.id,
        userId: parseString((_a = event.attributes) === null || _a === void 0 ? void 0 : _a.UserId, 'event.attributes.UserId'),
        reason: parseOptionalString((_b = event.attributes) === null || _b === void 0 ? void 0 : _b.Reason, 'event.attributes.Reason'),
        sendTime: event.sendTime,
        requestId: event.requestId,
        attributes: event.attributes,
    };
}
export function parseChatError(entity) {
    return {
        id: parseString(entity.Id, 'Id'),
        errorCode: parseNumber(entity.ErrorCode, 'ErrorCode'),
        errorMessage: parseString(entity.ErrorMessage, 'ErrorMessage'),
        requestId: parseOptionalString(entity.RequestId, 'RequestId'),
    };
}
export function parseString(value, field) {
    if (typeof value !== 'string') {
        throw createParsingError(`${field}: expected string but got ${typeof value}: ${value}`);
    }
    return value;
}
export function parseOptionalString(value, field) {
    if (value == null) {
        return undefined;
    }
    if (typeof value !== 'string') {
        throw createParsingError(`${field}: expected string or null/undefined but got ${typeof value}: ${value}`);
    }
    return value;
}
export function parseNumber(value, field) {
    if (typeof value !== 'number') {
        throw createParsingError(`${field}: expected number but got ${typeof value}: ${value}`);
    }
    return value;
}
export function parseDateString(value, field) {
    if (typeof value !== 'string') {
        throw createParsingError(`${field}: expected date string but got ${typeof value}: ${value}`);
    }
    const dateValue = new Date(value);
    if (isNaN(dateValue.getTime())) {
        throw createParsingError(`${field}: expected date string but got ${typeof value}: ${value}`);
    }
    return dateValue;
}
export function parseDateObjectOrDateString(value, field) {
    if (value == null) {
        return undefined;
    }
    const dateObject = typeof value === 'string' ? new Date(value) : value;
    if (!(dateObject instanceof Date) || isNaN(dateObject === null || dateObject === void 0 ? void 0 : dateObject.getTime())) {
        throw createParsingError(`${field}: expected Date object or ISO 8601 string but got ${typeof value}: ${value}`);
    }
    return dateObject;
}
export function parseObject(value, field) {
    if (value == null || typeof value !== 'object') {
        throw createParsingError(`${field ? `${field}: ` : ''}expected object but got ${typeof value}: ${value}`);
    }
    if (Array.isArray(value)) {
        throw createParsingError(`${field ? `${field}: ` : ''}expected object but got array: ${value}`);
    }
    return value;
}
function createParsingError(message) {
    const error = new Error(message);
    error.name = 'ParsingError';
    return error;
}
