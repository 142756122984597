import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  AcademyProgress,
  AcademyProgressResponse,
  AcademySection,
  AcademyVideo,
  AcademyVideoAction,
  AcademyVideoStatus,
} from '../model/academy';

export interface AcademyState {
  sections: {
    loading: boolean;
    data: AcademySection[];
  };
  video: {
    loading: boolean;
    data?: AcademyVideo;
  };
  progress: AcademyProgress;
}

export const initialState: AcademyState = {
  sections: {
    loading: false,
    data: [],
  },
  video: {
    loading: false,
  },
  progress: {
    currentProgressPercentage: 0,
    totalVideosCount: 0,
    watchedVideosCount: 0,
  },
};

export const fetchAcademySections = createAction('/academy/fetchAcademySections');

export const fetchAcademyProgress = createAction('/academy/fetchAcademyProgress');
export const fetchAcademyVideo = createAction<string>('/academy/fetchAcademyVideo');

export const saveAcademyVideoStatus = createAction<AcademyVideoAction>(
  '/academy/saveAcademyVideoStatus'
);

export const academySlice = createSlice({
  name: 'academy',
  initialState,
  reducers: {
    setAcademySections: (state, action: PayloadAction<AcademySection[]>) => {
      state.sections.data = action.payload;
    },
    setAcademySectionsLoading: (state, action: PayloadAction<boolean>) => {
      state.sections.loading = action.payload;
    },
    setAcademyVideo: (state, action: PayloadAction<AcademyVideo>) => {
      state.video.data = action.payload;
    },
    setAcademyVideoLoading: (state, action: PayloadAction<boolean>) => {
      state.video.loading = action.payload;
    },
    setAcademyProgress: (state, action: PayloadAction<AcademyProgressResponse>) => {
      state.progress = action.payload;
    },
    updateAcademyVideoStatus: (state, action: PayloadAction<AcademyVideoStatus>) => {
      if (state.video.data) state.video.data.status = action.payload;
    },
  },
});

export const {
  setAcademySections,
  setAcademySectionsLoading,
  setAcademyVideo,
  setAcademyVideoLoading,
  setAcademyProgress,
  updateAcademyVideoStatus,
} = academySlice.actions;

export default academySlice.reducer;

export const selectAcademySections = (state: RootState) => state.academy.sections;
export const selectAcademyVideo = (state: RootState) => state.academy.video;

export const selectAcademyProgress = (state: RootState) => state.academy.progress;
