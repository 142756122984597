import { addMonths } from 'date-fns';
import { useState } from 'react';
import { DateFilterMode } from '../DateFilter/dateFilterTypes';
import DatePicker from '../DatePicker/DatePicker';
import styles from './DateRangePicker.module.scss';

interface DateRangePickerProps {
  dateFrom: Date | null;
  dateTo: Date | null;
  onChangeDateFromCallback: (date: Date | null) => void;
  onChangeDateToCallback: (date: Date | null) => void;
  isOpenInitially?: boolean;
  isOpenToInitially?: boolean;
  disabled?: boolean;
  dateFilterMode: DateFilterMode;
}

const DateRangePicker = ({
  dateFrom,
  dateTo,
  onChangeDateFromCallback,
  onChangeDateToCallback,
  isOpenInitially = false,
  disabled = false,
  dateFilterMode,
}: DateRangePickerProps) => {
  const onChangeDateFrom = (date: Date | null) => onChangeDateFromCallback(date);
  const onChangeDateTo = (date: Date | null) => onChangeDateToCallback(date);

  const dateFromMinDate = dateFilterMode === DateFilterMode.PAST ? undefined : new Date();

  const dateFromMaxDate = dateFilterMode === DateFilterMode.PAST ? dateTo || undefined : undefined;

  const dateToMaxDate =
    dateFilterMode === DateFilterMode.PAST ? new Date() : addMonths(new Date(), 6);

  const [isToDateOpenInitially, setIsToDateOpenInitially] = useState(false);
  const onChangeDateFromInternal = (date: Date | null) => {
    if (date != null) {
      setIsToDateOpenInitially(true);
    }
    onChangeDateFrom(date);
  };

  return (
    <div className={styles.container}>
      <DatePicker
        value={dateFrom}
        onChangeDateCallback={onChangeDateFromInternal}
        isOpenInitially={isOpenInitially}
        minDate={dateFromMinDate}
        maxDate={dateFromMaxDate}
        disabled={disabled}
        isLarge={false}
      />
      <span>bis</span>
      <DatePicker
        value={dateTo}
        onChangeDateCallback={onChangeDateTo}
        isOpenInitially={isToDateOpenInitially}
        minDate={dateFrom || undefined}
        maxDate={dateToMaxDate}
        disabled={disabled}
        isLarge={false}
      />
    </div>
  );
};

export default DateRangePicker;
