import { ChevronLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import {
  DateRange,
  DateRangeWithSelectedFilter,
} from '../../../components/DateFilter/dateFilterTypes';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import OverAllPerformance from '../../components/OverAllPerformance/OverAllPerformance';
import StreamersTable from '../../components/StreamersTable/StreamersTable';
import {
  getAgencyDetailsById,
  selectAgencyCommission,
  selectAgencyDetailsLoading,
  selectAgencyGeneratedSales,
  selectAgencyStreamedSeconds,
  selectAgencyStreamers,
} from '../../state/agenciesSlice';
import styles from './AgencyDetailsPage.module.scss';

const AgencyDetailsPage = () => {
  const dispatch = useDispatch();
  const { agencyId } = useParams<{ agencyId: string }>();
  const [dateRange, setDateRange] = useState<DateRange>();
  const streamers = useSelector(selectAgencyStreamers) ?? [];
  const streamedSeconds = useSelector(selectAgencyStreamedSeconds);
  const agencyCommission = useSelector(selectAgencyCommission);
  const agencyGeneratedSales = useSelector(selectAgencyGeneratedSales);
  const isLoading = useSelector(selectAgencyDetailsLoading);
  const history = useHistory();

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    const dateRange: DateRange = {
      startDate: dateRangeWithSelectedFilter.startDate,
      endDate: dateRangeWithSelectedFilter.endDate,
    };
    setDateRange(dateRange);
    dispatch(getAgencyDetailsById({ agencyId, dateRange: dateRange }));
  };

  useEffect(() => {
    if (dateRange) {
      dispatch(getAgencyDetailsById({ agencyId, dateRange }));
    }
  }, []);

  const AgencyDetails = () => (
    <Box className={styles.container}>
      <div className={styles.navigation}>
        <div className={styles.link}>
          <Button color="secondary" onClick={() => history.goBack()}>
            <ChevronLeft />
            Zurück
          </Button>
        </div>
        <h1>{streamers[0]?.agencyName ? streamers[0].agencyName : ''} </h1>
      </div>
      <div className={styles.month_picker_container}>
        <DateFilterInPast onDateFiltersChange={onDateFiltersChange} />
      </div>
      <>
        <OverAllPerformance
          streamedSeconds={streamedSeconds}
          agencyCommission={agencyCommission}
          agencyGeneratedSales={agencyGeneratedSales}
        />
        <Box className={styles.table_header}>
          <h1>Streamer</h1>
        </Box>
        <StreamersTable creators={streamers} isLoading={isLoading} />
      </>
    </Box>
  );

  return <PageContainer pageToRender={AgencyDetails()} backgroundColor={theme.whiteLilac} />;
};

export default AgencyDetailsPage;
