import StreamerAcademyPage from '../../academy/pages/StreamerAcademyPage/StreamerAcademyPage';
import StreamerAcademyVideoPage from '../../academy/pages/StreamerAcademyVideoPage/StreamerAcademyVideoPage';
import AnalyticsPage from '../../analytics/pages/AnalyticsPage';
import { Role } from '../../auth/role';
import CreatorClassicDashboardPage from '../../creators/pages/CreatorClassicDashboardPage/CreatorClassicDashboardPage';
import CreatorDashboardPage from '../../creators/pages/CreatorDashboardPage/CreatorDashboardPage';
import CreatorEditProfilePage from '../../creators/pages/CreatorEditProfilePage/CreatorEditProfilePage';
import CreatorStreamSettingsPage from '../../creators/pages/CreatorStreamSettingsPage/CreatorStreamSettingsPage';
import ProfileDashboardPage from '../../creators/pages/ProfileDashboardPage/ProfileDashboardPage';
import InvoicePreviewPage from '../../payments/pages/InvoiceDetailPage/InvoicePreviewPage';
import PaymentsPage from '../../payments/pages/PaymentsPage/PaymentsPage';
import CreatePostPage from '../../posts/pages/CreatePostPage/CreatePostPage';
import UpdatePostPage from '../../posts/pages/UpdatePostPage/UpdatePostPage';
import ProductsPage from '../../products/pages/ProductsPage';
import CreatorUpcomingShowsPage from '../../shows/pages/CreatorUpcomingShowsPage/CreatorUpcomingShowsPage';
import CreateShowPage from '../../shows/pages/ScheduleShowPage/CreateShowPage';
import { UpdateShowPage } from '../../shows/pages/UpdateShowPage/UpdateShowPage';
import StreamChatPage from '../../streamChat/pages/StreamChatPage/StreamChatPage';
import { RouteDetails } from '../routeDetails';
import routePaths from '../routePaths';
import { amaRoutes } from './amaRoutes';

export const creatorRoutes: Record<string, RouteDetails> = {
  creatorDashboard: {
    path: routePaths.creator.dashboard,
    component: CreatorDashboardPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  creatorClassicDashboard: {
    path: routePaths.creator.classicDashboard,
    component: CreatorClassicDashboardPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  upcomingShows: {
    path: routePaths.creator.upcomingShows,
    component: CreatorUpcomingShowsPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  addCreatorShow: {
    path: routePaths.creator.addShow,
    component: CreateShowPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },

  addCreatorPost: {
    path: routePaths.creator.addPost,
    component: CreatePostPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  payments: {
    path: routePaths.creator.payments,
    component: PaymentsPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  analytics: {
    path: routePaths.creator.analytics,
    component: AnalyticsPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  academy: {
    path: routePaths.creator.academy.overview,
    component: StreamerAcademyPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  academyVideo: {
    path: routePaths.creator.academy.video,
    component: StreamerAcademyVideoPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  products: {
    path: routePaths.creator.products,
    component: ProductsPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  creatorProfile: {
    path: routePaths.creator.editProfile,
    component: CreatorEditProfilePage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  streamSettings: {
    path: routePaths.creator.streamSettings,
    component: CreatorStreamSettingsPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  creatorProfileDashboard: {
    path: routePaths.creator.profileDashboard,
    component: ProfileDashboardPage,
    exact: true,
    protected: false,
    hideDesktopFooter: true,
  },
  invoiceDetails: {
    path: routePaths.creator.invoiceDetails,
    component: InvoicePreviewPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER],
    hideDesktopFooter: true,
  },
  updateShow: {
    path: routePaths.creator.updateShow,
    component: UpdateShowPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  updatePost: {
    path: routePaths.creator.updatePost,
    component: UpdatePostPage,
    exact: true,
    protected: true,
    roles: [Role.STREAMER_HELLO, Role.INFLUENCER, Role.STREAMER_CLASSIC],
    hideDesktopFooter: true,
  },
  streamChat: {
    path: routePaths.creator.streamChat,
    component: StreamChatPage,
    exact: true,
    protected: true,
    hideHeader: true,
    hideDesktopFooter: true,
    roles: [
      Role.STREAMER_HELLO,
      Role.INFLUENCER,
      Role.STREAMER_CLASSIC,
      Role.HSE_EMPLOYEE,
      Role.AGENCY,
    ],
  },
  ...amaRoutes,
};
