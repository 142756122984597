import { Info } from '@mui/icons-material';
import { Avatar, Box, ClickAwayListener, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { StatTile } from '../StreamerOverallPerformance';
import styles from '../StreamerOverallPerformance.module.scss';

interface Props {
  tile: StatTile;
}

const OverallPerformanceTile = ({ tile: { title, value, icon, tooltip } }: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <Box className={styles.stat_card} display={'flex'} flexDirection={'column'} gap={3}>
      <Box display={'flex'} alignItems={'center'} gap={1}>
        <Avatar className={styles.stat_icon} variant="rounded">
          {icon}
        </Avatar>
        <Typography variant={'h5'} fontWeight={'bold'}>
          {value ?? '-'}
        </Typography>
      </Box>
      <Typography variant={'body1'}>
        <Box
          display="flex"
          flexWrap="nowrap"
          alignItems="flex-start"
          alignContent="flex-start"
          justifyContent="flex-start"
          gap="4px"
        >
          {title}
          {tooltip && (
            <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setTooltipOpen(false)}
                open={tooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
              >
                <IconButton
                  onClick={() => setTooltipOpen(true)}
                  aria-label="close-tooltip"
                  size="large"
                  sx={{
                    padding: 0,
                    marginY: 'auto',
                  }}
                >
                  <Info fontSize={'small'} data-testid="info-icon" />
                </IconButton>
              </Tooltip>
            </ClickAwayListener>
          )}
        </Box>
      </Typography>
    </Box>
  );
};

export default OverallPerformanceTile;
