import { Radio } from '@hse24/shared-components';
import { useState } from 'react';
import { Aspect } from '../../../utils/images/cropImageFns';
import styles from './RatioSelector.module.scss';

const options: Record<Aspect, string> = {
  [Aspect.RATIO_1_1]: '1:1',
  [Aspect.RATIO_4_5]: '4:5',
  [Aspect.RATIO_3_4]: '3:4',
  [Aspect.RATIO_9_16]: '9:16',
};

interface RatioSelectorProps {
  defaultValue?: Aspect;
  value?: Aspect;
  onChange?: (value: Aspect) => void;
}

const RatioSelector = ({ defaultValue, onChange }: RatioSelectorProps) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  return (
    <div className={styles.container} data-testid="ratio-selector">
      {Object.keys(options).map(key => {
        const ratio = key as unknown as Aspect;

        return (
          <Radio
            key={key}
            selected={selectedOption == ratio}
            onSelect={() => {
              setSelectedOption(ratio);
              onChange?.(ratio);
            }}
            className={styles.ration_option}
          >
            {options[ratio]}
          </Radio>
        );
      })}
    </div>
  );
};

export default RatioSelector;
