import { DateRange } from '../../components/DateFilter/dateFilterTypes';
import { Salutation } from '../../creators/components/Profile/ProfilePersonalData';
import { SocialMediaAccountsWithUsername } from '../../creators/model/creator';

export enum Currency {
  EUR = 'EUR',
}

export type PaymentsMonthRange = DateRange;

export interface PaymentDetailsParams {
  dateRange: PaymentsMonthRange;
  creatorId: string;
}
export interface FloorSales {
  amount: number;
  currency: Currency;
}

export interface PaymentsDetails {
  streamedSeconds: number;
  dailyStreamedSeconds: [
    {
      day: string;
      streamedSeconds: number;
    }
  ];
  generatedSales: {
    floorSales: FloorSales;
  };
  dailyGeneratedSales: DailyGeneratedSales[];
}

export interface InvoiceOverview {
  generationDate: string;
  invoiceId: string;
  periodStart: string;
  gross: number;
  currency: Currency;
  invoiceNumber: string;
}
export interface CreatorInvoices {
  invoices: InvoiceOverview[];
}

export interface InvoiceDetails extends InvoiceOverview {
  invoiceDetails: PaymentPreview;
}

export enum FeeType {
  CONTENT_CREATION = 'CONTENT_CREATION',
  SALES_PROVISION = 'SALES_PROVISION',
  SAMPLE_ORDERS = 'SAMPLE_ORDERS',
}

export interface PaymentPreviewCreator {
  salutation?: Salutation;
  firstName?: string;
  lastName?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  phoneNumber?: string;
  birthDate?: string;
  iban?: string;
  bic?: string;
  vatId?: string;
  isLiableToVat?: boolean;
  taxNumber?: string;
  taxId?: string;
  vendorAccountId?: string;
  profileImageUrl?: string;

  socialMediaAccounts?: SocialMediaAccountsWithUsername;
}

export interface PaymentPreview {
  costCenter?: number;
  creator?: PaymentPreviewCreator;
  fees?: Fee[];
  tax?: {
    title: string;
    value?: number;
  };
  totals?: {
    net: number;
    gross: number;
  };
  currency?: Currency;
}

export interface Fee {
  type: FeeType;
  title: string;
  fee: number;
}
export interface DailyGeneratedSales {
  day: string;
  generatedSales: {
    amount: number;
    currency: Currency;
  };
}

export interface DailyStreamedSeconds {
  day: string;
  streamedSeconds: number;
}
