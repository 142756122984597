import { all, fork } from 'redux-saga/effects';
import { watcherAcademySagas } from '../academy/state/academyHandler';
import { watcherAgenciesSagas } from '../agencies/state/agenciesHandler';
import { watcherAuthSagas } from '../auth/state/authHandlers';
import { watcherBasketSagas } from '../basket/state/basketHandler';
import { watcherCreatorsSagas } from '../creators/state/creatorsHandler';
import { watcherLoggingLambdaSagas } from '../integration/logginglambda/loggingHandler';
import { watcherMiddlewareSagas } from '../middlewares/middlewareHandlers';
import { watcherNotificationsSagas } from '../notifications/state/notificationsHandler';
import { watcherAmasSagas } from '../onDemandInteractions/amas/state/amasHandler';
import { watcherPaymentsSagas } from '../payments/state/paymentsHandler';
import { watcherPostsSagas } from '../posts/state/postsHandler';
import { watcherFactFinderSagas } from '../products/state/productsHandlers';
import { watcherShowsSagas } from '../shows/state/showsHandler';
import { watcherStreamChatSagas } from '../streamChat/state/streamChatHandlers';
import { watcherSurveysSagas } from '../surveys/state/surveysHandler';

export function* rootSaga() {
  yield all([
    fork(watcherAgenciesSagas),
    fork(watcherAuthSagas),
    fork(watcherCreatorsSagas),
    fork(watcherFactFinderSagas),
    fork(watcherLoggingLambdaSagas),
    fork(watcherMiddlewareSagas),
    fork(watcherNotificationsSagas),
    fork(watcherPaymentsSagas),
    fork(watcherShowsSagas),
    fork(watcherAmasSagas),
    fork(watcherPostsSagas),
    fork(watcherAcademySagas),
    fork(watcherSurveysSagas),
    fork(watcherStreamChatSagas),
    fork(watcherBasketSagas),
  ]);
}
