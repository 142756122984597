import styles from './UpcomingShowCard.module.scss';

const TimeLine = () => {
  return (
    <div className={styles.timeline_container}>
      <div className={styles.circle_icon} />
      <div className={styles.vertical_line} />
    </div>
  );
};

export default TimeLine;
