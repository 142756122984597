import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Card, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import styles from './Details.module.scss';

interface DetailsProps {
  overview: JSX.Element;
  performance: JSX.Element;
}

/**
 * The details view containing three sections with content and a backlink.
 *
 * @param The overview section displayed on the left
 * @param The performance section displayed on the top right
 */
const Details = ({ overview, performance }: DetailsProps) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const areaSpacing = 3;
  const history = useHistory();

  return (
    <Box mb={2}>
      <div className={styles.box}>
        <Button color="secondary" onClick={() => history.goBack()}>
          <ChevronLeft />
          Zurück
        </Button>
      </div>
      <Grid container direction={isSmall ? 'column' : 'row'} spacing={areaSpacing}>
        <Grid item md={3} sm={12} className={styles.overview}>
          <Card className={cx(styles.card, styles.white_card)}>{overview}</Card>
        </Grid>

        <Grid item md={9} sm={12} className={styles.overview}>
          <Card className={styles.card}>{performance}</Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Details;
