import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../assets';
import t from '../../constants/translation';
import {
  fetchCreatorsList,
  selectCreatorsIsLoading,
  selectCreatorsList,
} from '../../creators/state/creatorsSlice';
import Dropdown, { SelectOption } from '../Dropdown/Dropdown';
import Icon from '../Icon/Icon';
import CreatorsSearchOption from './CreatorSearchOption/CreatorsSearchOption';
import classes from './CreatorsSearchDropdown.module.scss';

interface CreatorsSearchDropdownProps {
  creatorId?: string;
  setCreatorId: (value: string) => void;
}

const CreatorsSearchDropdown = ({ creatorId, setCreatorId }: CreatorsSearchDropdownProps) => {
  const dispatch = useDispatch();
  const creatorList = useSelector(selectCreatorsList);
  const isLoading = useSelector(selectCreatorsIsLoading);
  const [options, setOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    dispatch(fetchCreatorsList());
  }, []);

  useEffect(() => {
    setOptions(
      creatorList.map(creatorLightResponse => {
        return {
          value: creatorLightResponse.id,
          key: creatorLightResponse.id,
          searchValue: creatorLightResponse.name,
          label: <CreatorsSearchOption creator={creatorLightResponse} />,
        };
      })
    );
  }, [creatorList]);

  return (
    <Dropdown
      defaultOption={creatorId}
      isLoadingOptions={isLoading}
      options={options}
      onSelect={selectedOption => setCreatorId(selectedOption)}
      styles={{ dropdownOptions: classes.options }}
      defaultLabel={
        <div className={classes.default_label}>
          <Icon dataTestId="person-icon" height={40} width={40} icon={Icons.personIcon} />
          <div>
            <p>{t.creators.show.selectCreator}</p>
          </div>
        </div>
      }
    />
  );
};

export default CreatorsSearchDropdown;
