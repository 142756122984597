import { useEffect, useRef } from 'react';

/**
 * useIsMountedRef is needed to avoid the warning "Warning: Can't perform a React state update on an unmounted component."
 * See https://www.debuggr.io/react-update-unmounted-component/ for explanation.
 */
export function useIsMountedRef() {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}
