import { Box, Breakpoint, Skeleton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import styles from './UpcomingShowsLoading.module.scss';

interface Props {
  size?: Breakpoint;
}

function UpcomingShowsLoading(props: Props) {
  const lg = props.size === 'lg';
  return (
    <Box
      data-testid={'skeleton-body'}
      className={classNames(styles.skeleton_body, {
        [styles.lg]: lg,
      })}
    >
      <Box className={styles.skeleton_content}>
        <Box width={'100%'}>
          <Skeleton
            data-testid={'skeleton'}
            variant="rectangular"
            width={'50%'}
            height={20}
            sx={{ borderRadius: '4px' }}
          />
        </Box>
        <Box width={'100%'}>
          <Skeleton
            data-testid={'skeleton'}
            variant="rectangular"
            width={'80%'}
            height={15}
            sx={{ borderRadius: '4px' }}
          />
        </Box>
        <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Skeleton
            data-testid={'skeleton'}
            variant="rectangular"
            width={'60%'}
            height={30}
            sx={{ borderRadius: '4px' }}
          />
          <Skeleton
            data-testid={'skeleton'}
            variant="rectangular"
            width={8}
            height={20}
            sx={{ borderRadius: '4px' }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default UpcomingShowsLoading;
