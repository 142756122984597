import { Target } from 'redux-beacon';
import { FirebaseEvent } from './types';

const mapFirebaseEventToGTM = (event: FirebaseEvent) => {
  if (!event) return;
  const { name, params } = event;
  return { event: name, ...params };
};

const trackGTMEvent = (event: FirebaseEvent) => {
  if (!window || !event || !window.dataLayer) return;
  window.dataLayer.push(mapFirebaseEventToGTM(event));
};

export const firebaseTrackingMiddleware = (): Target => events => {
  events.forEach(event => {
    if (typeof event === 'object') {
      const { name, params, userProperties, userId } = event;
      if (name) {
        // Send event to firebase
        window.nativeApp.firebase.logEvent(name, params);

        // Send event user properties
        if (userProperties) {
          Object.entries<string>(userProperties).forEach(([key, value]) => {
            window.nativeApp.firebase.setUserProperty(key, value);
          });
        }

        if (userId) {
          window.nativeApp.firebase.setUserId(userId);
        }

        // Send event to dataLayer
        trackGTMEvent(event);
      }
    }
  });
};
