import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BaseSyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routePaths from '../../../routes/routePaths';

export enum ErrorPageDialogType {
  UNAUTHORIZED = 'UNAUTHORIZED',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
}

interface Props {
  entityLabel?: string;
  errorDialogType: ErrorPageDialogType;
}

const ErrorPageDialog = ({ errorDialogType, entityLabel }: Props) => {
  const [open, setOpen] = useState(true);
  const history = useHistory();

  const onButtonClick = () => {
    setOpen(false);
    history.push(routePaths.landing);
  };

  const onClose = (event: BaseSyntheticEvent) => {
    event.preventDefault();
  };

  const title: string = {
    [ErrorPageDialogType.UNAUTHORIZED]: 'Nicht autorisiert',
    [ErrorPageDialogType.PAGE_NOT_FOUND]: 'Nicht gefunden',
    [ErrorPageDialogType.ENTITY_NOT_FOUND]: 'Nicht gefunden',
  }[errorDialogType];
  const details = {
    [ErrorPageDialogType.UNAUTHORIZED]:
      'Für diese Funktion musst Du Dich mit einem anderen Login anmelden.',
    [ErrorPageDialogType.PAGE_NOT_FOUND]: 'Die gewünschte Seite wurde nicht gefunden.',
    [ErrorPageDialogType.ENTITY_NOT_FOUND]: `${entityLabel} wurde nicht gefunden.`,
  }[errorDialogType];

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="responsive-dialog-title" maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{details}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onButtonClick} color="primary">
          Zur Übersicht
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorPageDialog;
