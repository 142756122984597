import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import StreamerPersonalDataComponent from '../../components/Profile/StreamerPersonalDataComponent';
const CreatorEditProfilePage = () => {
  return (
    <PageContainer
      pageToRender={<StreamerPersonalDataComponent />}
      backgroundColor={theme.whiteLilac}
    />
  );
};

export default CreatorEditProfilePage;
