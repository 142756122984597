import { useEffect, useState } from 'react';

const isArraysDifferent = <U>(arr1: U[], arr2: U[]): boolean => {
  return arr1.length !== arr2.length || !arr1.every(item => arr2.includes(item));
};

const useFormChanged = <T>(data: T, dataToUpdate: T | null): boolean => {
  const [formChanged, setFormChanged] = useState(false);

  useEffect(() => {
    const isFieldChanged = (field: keyof T): boolean => {
      if (Array.isArray(data[field])) {
        const arr1 = data[field] as unknown as string[];
        const arr2 = (dataToUpdate?.[field] || []) as unknown as string[];
        return isArraysDifferent<string>(arr1, arr2);
      } else {
        return data[field] !== dataToUpdate?.[field];
      }
    };

    if (!dataToUpdate) {
      const anyFieldChanged = Object.values(data).some(value =>
        Array.isArray(value) ? value.length > 0 : !!value
      );

      setFormChanged(anyFieldChanged);
      return;
    }

    const fields = Object.keys(data) as (keyof T)[];
    const fieldsChanged = fields.some(field => isFieldChanged(field));
    setFormChanged(fieldsChanged);
  }, [data, dataToUpdate]);

  return formChanged;
};

export default useFormChanged;
