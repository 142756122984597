import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import Config from '../../config/config';
import {
  CreatorInvoices,
  InvoiceDetails,
  PaymentsDetails,
  PaymentsMonthRange,
} from '../model/Payments';

const defaultBaseUrl = `${Config.env.socialLifeCommerceServiceBaseUrl}`;

const paymentsClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/creators`);
const invoicesClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/invoices`);

const getPaymentsDetails = async (
  dateRange: PaymentsMonthRange,
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<PaymentsDetails> => {
  const response = await RefreshTokenUtil.wrap(() =>
    paymentsClient(baseUrl).get<PaymentsDetails>(`/${creatorId}/payment-details`, {
      params: {
        start_date: dateRange.startDate,
        end_date: dateRange.endDate,
      },
    })
  );

  return response.data as PaymentsDetails;
};

const getCreatorInvoices = async (
  streamerId: string,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorInvoices> => {
  const response = await RefreshTokenUtil.wrap(() =>
    invoicesClient(baseUrl).get<CreatorInvoices>('', {
      params: {
        creator_id: streamerId,
      },
    })
  );

  return response.data as CreatorInvoices;
};

export const getInvoiceDetails = async (
  invoiceId: string,
  baseUrl: string = defaultBaseUrl
): Promise<InvoiceDetails> => {
  const response = await RefreshTokenUtil.wrap(() =>
    invoicesClient(baseUrl).get<InvoiceDetails>(`/${invoiceId}/overview`)
  );

  return response.data as InvoiceDetails;
};

const paymentsApi = {
  paymentsClient,
  invoicesClient,
  getPaymentsDetails,
  getCreatorInvoices,
  getInvoiceDetails,
};

export default paymentsApi;
