import { Category } from '../../model/category';
import { hasChildCategories, hasSelectedChild } from '../../utils/categoriesMapper';

export const getLastActiveParentCategory = (categories: Category[]): Category | null => {
  if (categories && categories.length !== 0) {
    const category = categories.filter(category => category.selected);
    if (category[0]) {
      if (
        category[0].categories &&
        category[0].categories.length > 0 &&
        hasChildCategories(category[0].categories)
      ) {
        const hasSelected = hasSelectedChild(category[0].categories);
        return hasSelected ? getLastActiveParentCategory(category[0].categories) : category[0];
      }
      return category[0];
    }
    return null;
  }
  return null;
};
