import { GridColDef, GridFeatureModeConstant } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGridTable from '../../../components/DataGridTable/DataGridTable';
import { ShopLinkType } from '../../../model/creator';
import {
  fetchCreatorShopLinksList,
  selectCreatorShopLinksIsLoading,
  selectCreatorShopLinksOverview,
} from '../../../state/creatorsSlice';
import CreatorShopLinkMenuOptions from '../CreatorShopLinkMenuOptions/CreatorShopLinkMenuOptions';
import styles from './CreatorShopLinksTable.module.scss';

const columns: GridColDef[] = [
  {
    field: 'type',
    headerName: 'Typ',
    width: 150,
    renderCell: value =>
      value.value == ShopLinkType.BRAND_OR_CATEGORY ? 'Marke/Kategorie' : 'Campaign',
  },
  {
    field: 'label',
    headerName: 'Angezeigter Name',
    width: 370,
  },
  {
    field: 'url',
    headerName: 'Link/Campaign ID',
    width: 370,
  },
  {
    field: 'imageUrl',
    headerName: 'Bild',
    width: 140,
    renderCell: value => (
      <img
        className={styles.image}
        src={value.value}
        alt="Bild"
        data-testid="shop-link-image"
      ></img>
    ),
  },
  {
    field: 'icon',
    headerName: '',
    width: 70,
    renderCell: ({ row }) => <CreatorShopLinkMenuOptions shopLink={row} />,
  },
];

const CreatorShopLinksTable = ({ creatorId }: { creatorId: string }) => {
  const shopLinks = useSelector(selectCreatorShopLinksOverview);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectCreatorShopLinksIsLoading);
  useEffect(() => {
    dispatch(fetchCreatorShopLinksList({ creatorId: creatorId }));
  }, [dispatch]);
  return (
    <DataGridTable
      mode={GridFeatureModeConstant.client}
      columns={columns}
      rows={shopLinks}
      height={630}
      rowsPerPageOptions={[10, 20, 50]}
      loading={isLoading}
    />
  );
};
export default CreatorShopLinksTable;
