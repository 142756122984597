export interface SectionVideo {
  id: string;
  title: string;
  previewImageUrl: string;
  durationInSeconds: number;
  status: AcademyVideoStatus;
}

interface Section {
  id: string;
  title: string;
}

export interface AcademySection extends Section {
  videos: SectionVideo[];
}

export interface AcademyVideo {
  id: string;
  title: string;
  previewImageUrl: string;
  durationInSeconds: number;
  url: string;
  section: Section;
  nextEpisodes: SectionVideo[];
  status: AcademyVideoStatus;
}

export interface AcademySectionResponse {
  sections: AcademySection[];
}

export interface AcademyProgress {
  currentProgressPercentage: number;
  watchedVideosCount: number;
  totalVideosCount: number;
}

export enum AcademyVideoStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
}

export interface AcademyVideoStatusResponse {
  status: AcademyVideoStatus;
}

export interface AcademyVideoAction {
  finished: boolean;
  id: string;
}

export type AcademyProgressResponse = AcademyProgress;

export type AcademyVideoResponse = AcademyVideo;
