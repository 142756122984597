import { Text } from '@hse24/shared-components';
import { Box } from '@mui/material';
import Icons from '../../../../assets';
import ResponsiveDetailsRow from '../../../../components/DetailsRow/ResponsiveDetailsRow';
import SecureDetailsRow from '../../../../components/DetailsRow/SecureDetailsRow/SecureDetailsRow';
import Icon from '../../../../components/Icon/Icon';
import { ShowDetails } from '../../../model/shows';
import styles from './ExternalDeviceInfo.module.scss';

interface ExternalDeviceInfoProps {
  showDetails: ShowDetails;
}

const ExternalDeviceInfo = ({ showDetails }: ExternalDeviceInfoProps) => {
  return (
    <div data-testid="external-device-info">
      <Box className={styles.info_header}>
        <Icon icon={Icons.settingsIcon} dataTestId="settings-icon" />
        <Text.B1>Externe Geräteeinstellungen</Text.B1>
      </Box>
      <ResponsiveDetailsRow
        label="Stream URL"
        value={showDetails.streamingUrl ?? '-'}
        withCopyIcon
      />
      {showDetails.streamingKey ? (
        <SecureDetailsRow label="Stream Key" value={showDetails.streamingKey} />
      ) : (
        <ResponsiveDetailsRow label="Stream Key" value={'-'} />
      )}
    </div>
  );
};

export default ExternalDeviceInfo;
