import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

interface BorderLinearProgressProps extends LinearProgressProps {
  bgColor?: string;
  barColor?: string;
}

const BorderLinearProgress = styled(LinearProgress)<BorderLinearProgressProps>(
  ({ bgColor = 'rgba(255, 255, 255, 1)', barColor = 'rgba(67, 160, 71, 1)' }) => ({
    height: 8,
    width: '100%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: bgColor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barColor,
    },
  })
);

export default BorderLinearProgress;
