import heic2any from 'heic2any';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { humanReadableSupportedImageTypes, supportedImageTypes } from '../../constants/fileTypes';
import { trackProfileImageValidation } from '../../middlewares/reduxBeaconMiddleware/actions';
import { ProfileImageValidation } from '../../middlewares/reduxBeaconMiddleware/types';
import {
  hideProgressDialog,
  showErrorDialog,
  showProgressDialog,
} from '../../notifications/state/notificationsSlice';
import { getImageDimensions, readFileAsDataURL } from '../../utils/images/readFileAsDataURL';

const useValidateAndCropImage = () => {
  const dispatch = useDispatch();

  return async (
    event: ChangeEvent<HTMLInputElement>,
    isProfileImage?: boolean,
    isVideoAllowed?: boolean
  ): Promise<string | null> => {
    try {
      if (!event.target.files) {
        return null;
      }

      dispatch(showProgressDialog('Verarbeite Bilddatei...'));
      const file = event.target.files[0];

      if (isVideoAllowed && file.type.startsWith('video')) {
        dispatch(hideProgressDialog());
        const croppedVideo = await readFileAsDataURL(file);
        return croppedVideo;
      }

      if (!supportedImageTypes.includes(file.type)) {
        dispatch(hideProgressDialog());
        dispatch(
          showErrorDialog(
            `Dieser Dateityp wird nicht unterstützt. Mögliche Formate: ${humanReadableSupportedImageTypes}`
          )
        );
        dispatch(trackProfileImageValidation(ProfileImageValidation.WRONG_FORMAT));
        return null;
      }

      if (file.size > 10 * 1024 * 1024) {
        // 10MB
        dispatch(hideProgressDialog());
        dispatch(showErrorDialog('Die Bilddatei ist zu groß. Es sind maximal 10 MB erlaubt.'));
        dispatch(trackProfileImageValidation(ProfileImageValidation.LARGE_SIZE));
        return null;
      }

      if (isProfileImage) {
        const dimensions = await getImageDimensions(file);

        if (!dimensions) {
          dispatch(showErrorDialog('Bitte versuche es nochmal'));
          return null;
        }

        if (dimensions.width < 1080 || dimensions.height < 1080) {
          dispatch(hideProgressDialog());
          dispatch(
            showErrorDialog('Bitte laden Sie ein Bild mit einer Mindestgröße von 1080 hoch.')
          );
          dispatch(trackProfileImageValidation(ProfileImageValidation.SMALL_DIMENSIONS));
          return null;
        }
      }

      let croppedImage;
      if (file.type.includes('heic') || file.type.includes('heif')) {
        const convertedImageFile = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 1,
        });
        croppedImage = await readFileAsDataURL(convertedImageFile as Blob);
      } else {
        croppedImage = await readFileAsDataURL(file);
      }

      dispatch(hideProgressDialog());
      return croppedImage;
    } catch (error) {
      dispatch(showErrorDialog('Bitte versuche es nochmal'));
      return null;
    }
  };
};

export default useValidateAndCropImage;
