import { EButtonSize, EButtonType } from '@hse24/shared-components';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from 'react-router';
import Config from '../../../config/config';
import { Paging } from '../../model/paging';
import AnchorButton from '../AnchorButton/AnchorButton';
import styles from '../LoadMoreArticles/LoadMoreArticles.module.scss';

interface LoadPreviousArticlesProps {
  paging: Paging;
  loading: boolean;
}

const LoadPreviousArticles = ({ paging, loading }: LoadPreviousArticlesProps) => {
  const history = useHistory();
  const loadPrevious = (linkTarget: string) => history.push(linkTarget);

  return (
    <>
      {paging.prevLink && (
        <div className="flex justify-center mb-24">
          <AnchorButton
            size={EButtonSize.MEDIUM}
            type={EButtonType.BLANK}
            inline={true}
            icon={{
              left: <div className="aside" />,
              right: loading ? (
                <RefreshIcon className="spinning" data-testid="loading-icon" />
              ) : (
                <ArrowUpwardIcon width="1.5rem" data-testid="arrow-up-icon" />
              ),
            }}
            onClick={() => loadPrevious(paging.prevLink || '')}
            url={Config.env.baseUrl.concat(paging.prevLink)}
            className={styles.buttonMore}
          >
            Vorherige Artikel laden
          </AnchorButton>
        </div>
      )}
    </>
  );
};

export default LoadPreviousArticles;
