import { camelizeKeys, decamelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import { toQueryString } from '../../common/queryParams';
import { QueryParams } from '../../common/types';
import Config from '../../config/config';
import { Pageable } from '../../creators/components/DataGridTable/ServerSideDataGrid';
import { PostsStatsData } from '../state/postsSlice';
import {
  AdminDeleteS3ObjectsRequest,
  AdminPostCreationRequest,
  AdminPostPreSignedUrlsRequest,
  AdminPostUpdateRequest,
  PostCreationRequest,
  PostCreationResponse,
  PostPreSignedUrlRequest,
  PostPreSignedUrlResponse,
  PostPreSignedUrlsResponse,
  PostStatusRequestResponse,
  PostUpdateRequest,
  PostUpdateResponse,
  StreamerPostResponse,
  StreamerPostsResponse,
} from './postsRequestResponse';

const defaultBaseUrl = Config.env.socialLifeCommerceServiceBaseUrl;

const postClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/posts`);
const adminPostClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/admin/posts`);

const createNewPost = async (
  postRequest: PostCreationRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PostCreationResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).post<PostCreationResponse>('', postRequest)
  );
  return response.data;
};
const createNewPostByAdmin = async (
  adminPostCreationRequest: AdminPostCreationRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PostCreationResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminPostClient(baseUrl).post<PostCreationResponse>('', adminPostCreationRequest)
  );
  return response.data;
};

const generatePreSignedUrlForPost = async (
  request: PostPreSignedUrlRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PostPreSignedUrlResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).post<PostPreSignedUrlResponse>(`create-pre-signed-url`, request)
  );
  return response.data;
};

const fetchStreamerPosts = async (
  creatorId: string,
  params: Pageable,
  baseUrl: string = defaultBaseUrl
): Promise<StreamerPostsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).get<StreamerPostsResponse>(`/creator/${creatorId}/overview`, {
      params: decamelizeKeys(params),
    })
  );
  return camelizeKeys(response.data) as StreamerPostsResponse;
};

const fetchStreamerPostsStats = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<PostsStatsData> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).get<PostsStatsData>(`/creator/${creatorId}/posts-stats`)
  );
  return camelizeKeys(response.data) as PostsStatsData;
};

const unpublishPost = async (
  postId: string,
  baseUrl: string = defaultBaseUrl
): Promise<PostStatusRequestResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).put<PostStatusRequestResponse>(`/un-publish/${postId}`)
  );
  return camelizeKeys(response.data) as PostStatusRequestResponse;
};

const republishPost = async (
  postId: string,
  baseUrl: string = defaultBaseUrl
): Promise<PostStatusRequestResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).put<PostStatusRequestResponse>(`/publish/${postId}`)
  );
  return camelizeKeys(response.data) as PostStatusRequestResponse;
};

const fetchAdminPostsOverview = async (
  queryParams: QueryParams,
  baseUrl: string = defaultBaseUrl
): Promise<StreamerPostsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).get<StreamerPostsResponse>(`/overview?${toQueryString(queryParams)}`)
  );
  return response.data;
};

const getStreamerPostById = async (
  postId: string,
  baseUrl: string = defaultBaseUrl
): Promise<StreamerPostResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).get<StreamerPostResponse>(`/${postId}?disable_cache=true`)
  );
  return camelizeKeys(response.data) as StreamerPostResponse;
};

const updatePost = async (
  postId: string,
  postToUpdate: PostUpdateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PostUpdateResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    postClient(baseUrl).put<PostUpdateResponse>(`/${postId}`, postToUpdate)
  );
  return response.data;
};
const updatePostByAdmin = async (
  postId: string,
  postToUpdate: AdminPostUpdateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PostUpdateResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminPostClient(baseUrl).put<PostUpdateResponse>(`/${postId}`, postToUpdate)
  );
  return response.data;
};

const createPreSignedUrls = async (
  postPreSignedUrlsRequest: AdminPostPreSignedUrlsRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PostPreSignedUrlsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminPostClient(baseUrl).post<PostPreSignedUrlsResponse>(
      `/create-pre-signed-urls`,
      postPreSignedUrlsRequest
    )
  );
  return response.data;
};

const deleteS3Objects = async (
  deleteS3ObjectsRequest: AdminDeleteS3ObjectsRequest,
  baseUrl: string = defaultBaseUrl
): Promise<void> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminPostClient(baseUrl).post(`/delete-s3-objects-by-keys`, deleteS3ObjectsRequest)
  );
  return response.data;
};

const postsApi = {
  createNewPost,
  createNewPostByAdmin,
  generatePreSignedUrlForPost,
  postClient,
  fetchStreamerPosts,
  fetchStreamerPostsStats,
  unpublishPost,
  republishPost,
  fetchAdminPostsOverview,
  getStreamerPostById,
  updatePost,
  updatePostByAdmin,
  createPreSignedUrls,
  deleteS3Objects,
};

export default postsApi;
