import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { put, select, takeLatest } from 'redux-saga/effects';
import { selectCreatorMarketingConsent } from '../../creators/state/creatorsSlice';
import { isAxiosError } from '../../shared/axiosClient';
import { redirectToLoginConfirmationDialogDetails } from '../components/ConfirmationDialog/ConfirmationDialog';
import {
  closeProgressDialog,
  collapseOnboarding,
  collapseOnboardingDialog,
  errorOccurredAction,
  hideOnboardingIndicator,
  hideOnboardingIndicatorDialog,
  hideProgressDialog,
  openConfirmationDialog,
  openErrorDialog,
  openProgressDialog,
  showErrorDialog,
  showMarketingConsentDialog,
  showMarketingConsentIfNeeded,
  showProgressDialog,
} from './notificationsSlice';

const isAuthError = (axiosError: AxiosError) => {
  return axiosError.response?.status === 401;
};

export function* errorOccurredHandler(action: PayloadAction<{ error: Error }>) {
  const { error } = action.payload;

  if (isAxiosError(error)) {
    if (isAuthError(error)) {
      yield put(openConfirmationDialog(redirectToLoginConfirmationDialogDetails));
    } else {
      yield put(
        openErrorDialog({
          message: getErrorMessageOrDefault(error, undefined),
          errorCode: error.response?.status,
        })
      );
    }
  } else {
    yield put(openErrorDialog({ message: error.message }));
  }
}

export function getErrorMessageOrDefault(error: AxiosError, defaultMessage?: string) {
  const responseData = error.response?.data;
  if (responseData?.detail != null) {
    return error.response?.data?.detail;
  }
  if (responseData?.message != null) {
    return error.response?.data?.message;
  }
  return defaultMessage;
}

export function* collapseOnboardingHandler(action: PayloadAction<boolean>) {
  yield put(collapseOnboardingDialog(action.payload));
}

export function* hideOnboardingIndicatorHandler(action: PayloadAction<boolean>) {
  yield put(hideOnboardingIndicatorDialog(action.payload));
}

export function* showErrorDialogHandler(action: PayloadAction<string>) {
  yield put(openErrorDialog({ message: action.payload }));
}
export function* showProgressDialogHandler(action: PayloadAction<string>) {
  yield put(openProgressDialog(action.payload));
}

export function* hideProgressDialogHandler() {
  yield put(closeProgressDialog());
}

export function* showMarketingConsentIfNeededHandler() {
  const FOUR_WEEKS = 4 * 7 * 24 * 60 * 60 * 1000;
  const marketingConsent: ReturnType<typeof selectCreatorMarketingConsent> = yield select(
    selectCreatorMarketingConsent
  );
  if (marketingConsent && !marketingConsent.hasAccepted) {
    if (!marketingConsent.lastDialogDisplayTime) {
      yield put(showMarketingConsentDialog());
    } else {
      const lastDialogDisplayTime = new Date(marketingConsent.lastDialogDisplayTime);
      const fourWeeksAgo = new Date(new Date().getTime() - FOUR_WEEKS);
      const isFourWeeksOld = lastDialogDisplayTime <= fourWeeksAgo;
      if (isFourWeeksOld) {
        yield put(showMarketingConsentDialog());
      }
    }
  }
}

export function* watcherNotificationsSagas() {
  yield takeLatest(errorOccurredAction.type, errorOccurredHandler);
  yield takeLatest(collapseOnboarding.type, collapseOnboardingHandler);
  yield takeLatest(hideOnboardingIndicator.type, hideOnboardingIndicatorHandler);
  yield takeLatest(showErrorDialog.type, showErrorDialogHandler);
  yield takeLatest(showProgressDialog.type, showProgressDialogHandler);
  yield takeLatest(hideProgressDialog.type, hideProgressDialogHandler);
  yield takeLatest(showMarketingConsentIfNeeded.type, showMarketingConsentIfNeededHandler);
}
