import {
  ArrowIcon,
  Button,
  EButtonSize as ETriggerSize,
  EButtonText,
  EButtonType as ETriggerType,
  TSelectTriggerProps,
} from '@hse24/shared-components';
import { FC } from 'react';
import { SortingSelectProps } from '../SortingSelect';
import styles from '../SortingSelect.module.scss';

const SortingSelectTrigger: FC<TSelectTriggerProps & SortingSelectProps> = props => {
  const { onClick, isOpen, label, size, type } = props;
  return (
    <Button
      onClick={onClick}
      type={type || ETriggerType.NONE}
      size={size || ETriggerSize.TINY}
      textAlign={EButtonText.LEFT}
      className={styles.rounded}
      icon={{
        right: (
          <ArrowIcon
            color={styles.primary}
            style={{
              width: '1rem',
              height: '1rem',
              transform: `scale(${isOpen ? -1 : 1})`,
              transition: 'all 0.2s',
            }}
          />
        ),
      }}
    >
      {label}
    </Button>
  );
};

export default SortingSelectTrigger;
