import { AxiosResponse } from 'axios';
import { decamelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import { toQueryString } from '../../common/queryParams';
import { PreSignedUrlResponse, QueryParams } from '../../common/types';
import Config from '../../config/config';
import { ShowImages } from '../../shows/model/shows';
import {
  CreatorImageType,
  CreatorShopLink,
  CreatorShopLinkRequest,
  CreatorShopLinkUpdateRequest,
  CreatorStatistics,
  CreatorStatus,
} from '../model/creator';
import {
  AdminNewCreatorRequest,
  ApproveCreatorRequest,
  ApproveCreatorResponse,
  CreatorDetailsOverviewResponse,
  CreatorLightResponse,
  CreatorPerformanceOverviewResponse,
  CreatorPreSignedUrlRequest,
  CreatorPreSignedUrlResponse,
  CreatorsOverviewResponse,
  LoggedInCreatorResponse,
  NewCreatorRequest,
  NewCreatorResponse,
  SetShowImagesRequest,
  ShopLinkPreSignedUrlRequest,
  UpdateCreatorRequest,
} from './requestResponse';

const defaultBaseUrl = Config.env.socialLifeCommerceServiceBaseUrl;

const creatorClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/creators`);

const getCreatorDetailsOverview = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorDetailsOverviewResponse> =>
  (
    await RefreshTokenUtil.wrap(() =>
      creatorClient(baseUrl).get<CreatorDetailsOverviewResponse>(creatorId)
    )
  ).data;

const getCreatorShopLinks = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorShopLink[]> =>
  (
    await RefreshTokenUtil.wrap(() =>
      creatorClient(baseUrl).get<CreatorShopLink[]>(`${creatorId}/shop-links`)
    )
  ).data;

const saveCreatorShopLink = async (
  creatorId: string,
  shopLink: CreatorShopLinkRequest,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> =>
  await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post(`${creatorId}/shop-links`, decamelizeKeys(shopLink))
  );

const deleteCreatorShopLink = async (
  creatorId: string,
  shopLinkId: string,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> =>
  await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).delete(`/${creatorId}/shop-link/${shopLinkId}`)
  );

const editCreatorShopLink = async (
  creatorId: string,
  shopLinkId: string,
  shopLink: CreatorShopLinkUpdateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorShopLink> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).put(`${creatorId}/shop-link/${shopLinkId}`, decamelizeKeys(shopLink))
  );
  return response.data;
};

const generateShopLinkPreSignedUrl = async (
  creatorId: string,
  request: ShopLinkPreSignedUrlRequest,
  baseUrl: string = defaultBaseUrl
): Promise<PreSignedUrlResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post(`${creatorId}/shop-links/pre-signed-url`, decamelizeKeys(request))
  );
  return response.data;
};

const getLoggedInCreator = async (
  baseUrl: string = defaultBaseUrl
): Promise<LoggedInCreatorResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).get<LoggedInCreatorResponse>('profile')
  );
  return response.data;
};

const getCreatorStatistics = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorStatistics> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).get<CreatorStatistics>(`${creatorId}/profile`)
  );
  return response.data;
};

const generatePreSignedUrl = async (
  streamerId: string,
  fileExtension: string,
  imageType: CreatorImageType,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorPreSignedUrlResponse> => {
  const request: CreatorPreSignedUrlRequest = { fileExtension, imageType };
  const response = await creatorClient(baseUrl).post<CreatorPreSignedUrlResponse>(
    `images/pre-signed-url`,
    request
  );
  return response.data;
};

const saveCreator = async (
  creatorToSave: NewCreatorRequest,
  baseUrl: string = defaultBaseUrl
): Promise<NewCreatorResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<NewCreatorResponse>('', creatorToSave)
  );
  return response.data;
};

const createApprovedCreator = async (
  creatorToSave: AdminNewCreatorRequest,
  baseUrl: string = defaultBaseUrl
): Promise<NewCreatorResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<NewCreatorResponse>('/approved', creatorToSave)
  );
  return response.data;
};

const updateCreator = async (
  creatorToUpdate: UpdateCreatorRequest,
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<LoggedInCreatorResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).put<LoggedInCreatorResponse>(`/${creatorId}`, creatorToUpdate)
  );
  return response.data;
};

const approveCreator = async (
  creatorId: string,
  request: ApproveCreatorRequest,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorStatus> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<ApproveCreatorResponse>(`/${creatorId}/approval`, request)
  );
  return response.data.status;
};

const getStreamerPerformanceOverview = async (
  id: string,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorPerformanceOverviewResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).get<CreatorPerformanceOverviewResponse>(`/${id}/performance-overview`)
  );
  return response.data;
};

const acceptTermsAndConditions = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<LoggedInCreatorResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<LoggedInCreatorResponse>(
      `/${creatorId}/accept-terms-and-conditions`
    )
  );
  return response.data;
};

const shopSetupCompleted = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<AxiosResponse>(
      `/${creatorId}/onboarding-flags/shop-setup-completed`
    )
  );
  return response.data as AxiosResponse;
};

const samplesOrdered = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<AxiosResponse>(`/${creatorId}/onboarding-flags/samples-ordered`)
  );
  return response.data as AxiosResponse;
};

const samplesReceived = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<AxiosResponse>(`/${creatorId}/onboarding-flags/samples-received`)
  );
  return response.data as AxiosResponse;
};

const onboardingCompleted = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<AxiosResponse>(
      `/${creatorId}/onboarding-flags/onboarding-completed`
    )
  );
  return response.data as AxiosResponse;
};

const amaOnboardingCompleted = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<AxiosResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<AxiosResponse>(
      `/${creatorId}/onboarding-flags/ama-onboarding-completed`
    )
  );
  return response.data as AxiosResponse;
};

const updateCreatorShowImages = async (
  showImagesRequest: SetShowImagesRequest,
  baseUrl: string = defaultBaseUrl
): Promise<ShowImages | null> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<AxiosResponse>('show-images', showImagesRequest)
  );
  return response.data as ShowImages;
};

const resendWelcomeEmail = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<string> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<string>(`/${creatorId}/resend-welcome-email`)
  );
  return response.data;
};

const deleteAccount = async (
  creatorId: string,
  baseUrl: string = defaultBaseUrl
): Promise<string> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).delete<string>(`/${creatorId}`)
  );
  return response.data;
};

const queryCreatorsOverview = async (
  queryParams: QueryParams,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorsOverviewResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).get<CreatorsOverviewResponse>(`/overview?${toQueryString(queryParams)}`)
  );
  return response.data;
};

const acceptMarketingConsent = async (
  creatorId: string,
  hasAcceptedMarketingConsent: boolean,
  baseUrl: string = defaultBaseUrl
): Promise<LoggedInCreatorResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).post<LoggedInCreatorResponse>(`/${creatorId}/accept-marketing-consent`, {
      hasAcceptedMarketingConsent,
    })
  );
  return response.data;
};

const getCreators = async (baseUrl: string = defaultBaseUrl): Promise<CreatorLightResponse[]> => {
  const response = await RefreshTokenUtil.wrap(() =>
    creatorClient(baseUrl).get<CreatorLightResponse[]>('/list')
  );
  return response.data;
};

const creatorsApi = {
  getCreatorDetailsOverview,
  getLoggedInCreator,
  generateProfilePicturePreSignedUrl: generatePreSignedUrl,
  saveCreator,
  createApprovedCreator,
  updateCreator,
  creatorClient,
  approveCreator,
  getStreamerPerformanceOverview,
  acceptTermsAndConditions,
  shopSetupCompleted,
  samplesOrdered,
  samplesReceived,
  onboardingCompleted,
  amaOnboardingCompleted,
  updateCreatorShowImages,
  resendWelcomeEmail,
  deleteAccount,
  queryCreatorsOverview,
  acceptMarketingConsent,
  getCreatorStatistics,
  getCreators,
  getCreatorShopLinks,
  saveCreatorShopLink,
  deleteCreatorShopLink,
  editCreatorShopLink,
  generateShopLinkPreSignedUrl,
};

export default creatorsApi;
