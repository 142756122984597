import { Button } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import t from '../../../constants/translation';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import routePaths from '../../../routes/routePaths';
import theme from '../../../theme/theme.module.scss';
import AgenciesTable from '../../components/AgenciesList/AgenciesTable';
import { selectAgenciesDateRange, setAgenciesOverviewDateRange } from '../../state/agenciesSlice';
import styles from './AgenciesOverviewPage.module.scss';

const AgenciesOverviewPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const agenciesDateRange = useSelector(selectAgenciesDateRange);
  const handleOnClick = () => history.push(routePaths.hseEmployee.addAgency);
  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    dispatch(setAgenciesOverviewDateRange(dateRangeWithSelectedFilter));
  };

  const AgenciesOverview = () => (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.button_right}>
          <Button onClick={handleOnClick}>
            <Typography fontWeight={700}>{t.agencies['Create agency']}</Typography>
          </Button>
        </div>
        <Box mb={3}>
          <DateFilterInPast
            onDateFiltersChange={onDateFiltersChange}
            selectedDateFrom={agenciesDateRange?.startDate}
            selectedDateTo={agenciesDateRange?.endDate}
            selectedFilter={agenciesDateRange?.dateFilter}
          />
        </Box>
        <div className={styles.agencies_overview}>
          <AgenciesTable />
        </div>
      </div>
    </div>
  );

  return <PageContainer pageToRender={AgenciesOverview()} backgroundColor={theme.whiteLilac} />;
};

export default AgenciesOverviewPage;
