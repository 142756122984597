import { createAction } from '@reduxjs/toolkit';
import { BeaconActions, MarketingConsentResults, ProfileImageValidation } from './types';

export const trackPageImpression = createAction(BeaconActions.TRACK_PAGE_IMPRESSION_EVENT);
export const trackLoginSuccess = createAction(BeaconActions.TRACK_LOGIN_SUCCESS_EVENT);
export const trackInvoiceDownload = createAction(BeaconActions.TRACK_INVOICE_DOWNLOAD_EVENT);
export const trackMarketingConsent = createAction<MarketingConsentResults>(
  BeaconActions.TRACK_MARKETING_CONSENT_EVENT
);
export const trackStreamerAcademy = createAction(BeaconActions.TRACK_STREAMER_ACADEMY_EVENT);

export const trackStreamEnded = createAction<Record<string, string | boolean>>(
  BeaconActions.TRACK_STREAM_ENDED_EVENT
);

export const trackProfileImageValidation = createAction<ProfileImageValidation>(
  BeaconActions.TRACK_PROFILE_IMAGE_VALIDATION_EVENT
);
