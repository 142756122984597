import t from '../../constants/translation';
import { InfoTableProps } from '../InfoTable/InfoTable';

export enum StreamerCommissionType {
  CONTENT_HOURS = 'CONTENT_HOURS',
  CONTENT_MONTHS = 'CONTENT_MONTHS',
  STREAMER_SALES_PROVISION = 'STREAMER_SALES_PROVISION',
  AGENCY_SALES_PROVISION = 'AGENCY_SALES_PROVISION',
  VIEWS = 'VIEWS',
  LIKES = 'LIKES',
  COMMENTS = 'COMMENTS',
  ORDERS = 'ORDERS',
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  CREATOR_INSTAGRAM = 'CREATOR_INSTAGRAM',
  CREATOR_TIKTOK = 'CREATOR_TIKTOK',
}

export const streamerCommissionTables = new Map<StreamerCommissionType, InfoTableProps>([
  [
    StreamerCommissionType.CONTENT_HOURS,
    {
      title:
        t.creators.commissionTables.contentHours['Expense flat rate in your first three months'],
      ariaLabel: 'Content creation hours',
      columns: [t.creators.Live, t.creators.commissionTables.contentHours['Flat rate of expenses']],
      rows: [
        [t.creators.commissionTables.contentHours['less than 300'], '0€'],
        [t.creators.commissionTables.contentHours['between 300 and 600'], '50€'],
        [t.creators.commissionTables.contentHours['from 600'], '100€'],
      ],
      description:
        t.creators.commissionTables.contentHours[
          'If the sales commission exceeds the administration fee, the administration fee is forfeited. Only the higher amount of the two values will be credited.'
        ],
    },
  ],
  [
    StreamerCommissionType.CONTENT_MONTHS,
    {
      title: t.creators.commissionTables.contentMonths['Credit for show products'],
      ariaLabel: 'Content creation months',
      columns: [t.creators.Month, `${t.creators.Credit} / ${t.creators.Month}`],
      rows: [
        [
          `${t.creators.Month} 1`,
          t.creators.commissionTables.contentMonths['Up to 50€ for show products'],
        ],
        [
          `${t.creators.Month} 2`,
          t.creators.commissionTables.contentMonths['Up to 50€ for show products'],
        ],
        [
          `${t.creators.Month} 3`,
          t.creators.commissionTables.contentMonths['Up to 50€ for show products'],
        ],
      ],
      description:
        t.creators.commissionTables.contentMonths[
          'Months 2 and 3 show product credit will only be given if you streamed at least 600 minutes in the previous month.'
        ],
    },
  ],
  [
    StreamerCommissionType.STREAMER_SALES_PROVISION,
    {
      title: t.creators['Sales commission'],
      ariaLabel: 'Sales provision',
      columns: ['Level', t.creators['Net sales'], t.creators['Commission streamer']],
      rows: [
        [1, '1€ - 99,99€', '5%'],
        [2, '100€ - 499,99€', '5,41%'],
        [3, '500€ - 999,99€', '5,82%'],
        [4, '1.000€ - 2.499,99€', '6,23%'],
        [5, '2.500€ - 4.999,99€', '6,64%'],
        [6, '5.000€ - 9.999,99€', '7,05%'],
        [7, '10.000€ - 24.999,99€', '7,46%'],
        [8, '25.000€ - 49.999,99€', '7,87%'],
        [9, '50.000€ - 99.999,99€', '8,28%'],
        [10, '100.000€ - 249.999,99€', '8,69%'],
        [11, '250.000€ - 499.999,99€', '9,10%'],
        [12, '500.000€ - 999.999,99€', '9,52%'],
        [13, '1.000.000€ +', '10%'],
      ],
      description:
        t.creators.commissionTables[
          'Only the key figure listed here in HELLO for Streamers is decisive for the turnover.'
        ],
    },
  ],
  [
    StreamerCommissionType.AGENCY_SALES_PROVISION,
    {
      title: t.creators['Sales commission'],
      ariaLabel: 'Sales provision',
      columns: [
        'Level',
        t.common.Revenue,
        t.creators['Commission streamer'],
        t.agencies['Agency commission'],
      ],
      rows: [
        [1, '1€ - 99,99€', '5%', '0%'],
        [2, '100€ - 499,99€', '5,41%', '0%'],
        [3, '500€ - 999,99€', '5,82%', '2%'],
        [4, '1.000€ - 2.499,99€', '6,23%', '2%'],
        [5, '2.500€ - 4.999,99€', '6,64%', '2%'],
        [6, '5.000€ - 9.999,99€', '7,05%', '2%'],
        [7, '10.000€ - 24.999,99€', '7,46%', '2%'],
        [8, '25.000€ - 49.999,99€', '7,87%', '2%'],
        [9, '50.000€ - 99.999,99€', '8,28%', '2%'],
        [10, '100.000€ - 249.999,99€', '8,69%', '2%'],
        [11, '250.000€ - 499.999,99€', '9,10%', '2%'],
        [12, '500.000€ - 999.999,99€', '9,52%', '2%'],
        [13, '1.000.000€ +', '10%', '2%'],
      ],
      description:
        t.creators.commissionTables[
          'Only the key figure listed here in HELLO for Streamers is decisive for the turnover.'
        ],
    },
  ],
  [
    StreamerCommissionType.VIEWS,
    {
      ariaLabel: 'Views',
      columns: [
        `${t.creators.Views} (Live) / ${t.creators.Month}`,
        `${t.creators.Bonus} / ${t.creators.Month}`,
      ],
      rows: [
        ['100 - 130', '10€'],
        ['131 - 150', '20€'],
        ['151 - 170', '30€'],
        ['161 - 200', '50€'],
        ['201 - 300', '150€'],
        ['301+', '250€'],
      ],
    },
  ],
  [
    StreamerCommissionType.LIKES,
    {
      ariaLabel: 'Likes',
      columns: [
        `${t.creators.Likes} / ${t.creators.Month}`,
        `${t.creators.Bonus} / ${t.creators.Month}`,
      ],
      rows: [
        ['5.000 - 10.000', '10€'],
        ['10.001 - 15.000', '20€'],
        ['15.001 - 20.000', '30€'],
        ['20.001 - 25.000', '40€'],
        ['25.001 - 50.000', '50€'],
        ['50.001+', '75€'],
      ],
    },
  ],
  [
    StreamerCommissionType.COMMENTS,
    {
      ariaLabel: 'Comments',
      columns: [
        `${t.creators.Comments} / ${t.creators.Month}`,
        `${t.creators.Bonus} / ${t.creators.Month}`,
      ],
      rows: [
        ['50 - 100', '10€'],
        ['101 - 150', '20€'],
        ['151 - 200', '40€'],
        ['201 - 250', '75€'],
        ['250 - 600', '100€'],
        ['601+', '200€'],
      ],
    },
  ],
  [
    StreamerCommissionType.ORDERS,
    {
      ariaLabel: 'Orders',
      columns: [
        `${t.creators.Orders} / ${t.creators.Month}`,
        `${t.creators.Bonus} / ${t.creators.Month}`,
      ],
      rows: [
        ['5', '10€'],
        ['6', '20€'],
        ['7', '30€'],
        ['8 - 9', '50€'],
        ['10 - 50', '100€'],
        ['51+', '300€'],
      ],
    },
  ],
  [
    StreamerCommissionType.INSTAGRAM,
    {
      title: 'Instagram',
      ariaLabel: 'Instagram',
      columns: ['Level', 'Follower', 'Instagram-Story', '1 Post', 'Managementgebühr'],
      rows: [
        [1, '10k - 29k', 1, '150€', '40€'],
        [2, '30k - 99k', 1, '300€', '75€'],
        [3, '100k - 299k', 1, '450€', '100€'],
        [4, '300k - 599k', 1, '600€', '150€'],
        [5, '600k +', 1, '800€', '200€'],
      ],
      description:
        t.agencies.commissionTables[
          'Only for selected streamers. Please contact your service partner for more information.'
        ],
    },
  ],
  [
    StreamerCommissionType.CREATOR_INSTAGRAM,
    {
      title: 'Instagram',
      ariaLabel: 'Instagram',
      columns: ['Level', 'Follower', 'Instagram-Story', '1 Post'],
      rows: [
        [1, '10k - 29k', 1, '150€'],
        [2, '30k - 99k', 1, '300€'],
        [3, '100k - 299k', 1, '450€'],
        [4, '300k - 599k', 1, '600€'],
        [5, '600k +', 1, '800€'],
      ],
      description:
        t.agencies.commissionTables[
          'Only for selected streamers. Please contact your service partner for more information.'
        ],
    },
  ],
  [
    StreamerCommissionType.TIKTOK,
    {
      title: 'TikTok',
      ariaLabel: 'TikTok',
      columns: [
        'Level',
        'Follower',
        '2 Posts',
        `2 Posts ${t.creators['in total']}`,
        t.creators['Management fee'],
      ],
      rows: [
        [1, '30k - 99k', '1 TikTok-Video + 1 Instagram-Story', '150€', '50€'],
        [2, '100k - 299k', '1 TikTok-Video + 1 Instagram-Story', '250€', '100€'],
        [3, '300k - 599k', '1 TikTok-Video + 1 Instagram-Story', '400€', '150€'],
        [4, '600k +', '1 TikTok-Video + 1 Instagram-Story', '600€', '200€'],
      ],
      description:
        t.agencies.commissionTables[
          'Only for selected streamers. Please contact your service partner for more information.'
        ],
    },
  ],
  [
    StreamerCommissionType.CREATOR_TIKTOK,
    {
      title: 'TikTok',
      ariaLabel: 'TikTok',
      columns: ['Level', 'Follower', '2 Posts', '2 Posts insgesamt'],
      rows: [
        [1, '30k - 99k', '1 TikTok-Video + 1 Instagram-Story', '150€'],
        [2, '100k - 299k', '1 TikTok-Video + 1 Instagram-Story', '250€'],
        [3, '300k - 599k', '1 TikTok-Video + 1 Instagram-Story', '400€'],
        [4, '600k +', '1 TikTok-Video + 1 Instagram-Story', '600€'],
      ],
      description:
        t.agencies.commissionTables[
          'Only for selected streamers. Please contact your service partner for more information.'
        ],
    },
  ],
]);
