const regionRegex = /^[a-zA-Z0-9-._~]+$/;
export function makeWebSocketUrl(regionOrUrl) {
    // Support for explicit WebSocket URL
    if (regionOrUrl.startsWith('wss://') || regionOrUrl.startsWith('ws://')) {
        return regionOrUrl;
    }
    if (!regionOrUrl.match(regionRegex)) {
        throw new Error('Region contains invalid characters');
    }
    return `wss://edge.ivschat.${regionOrUrl}.amazonaws.com`;
}
