import { CountryCode } from '@hse24/shared-components';
import { Salutation } from '../creators/components/Profile/ProfilePersonalData';
import { Role } from './role';

export interface ConfirmEmail {
  email: string;
  executeRecaptcha?: (action?: string) => Promise<string>;
}

export interface LoginUserAction {
  credentials: LoginUserCredentials;
  executeRecaptcha?: (action?: string | undefined) => Promise<string>;
}

export enum RedirectAfterLogin {
  PORTAL = 'PORTAL',
  ADDRESS_REGISTER = 'ADDRESS_REGISTER',
}

export enum ForgetPasswordOrigin {
  LOGIN = 'LOGIN',
  ONBOARDING = 'ONBOARDING',
}

export interface LoginUserCredentials {
  email: string;
  password: string;
  stayLoggedIn: boolean;
}

export interface ActivateUserRequest {
  activationTokenId: string;
}

export interface ActivateUserResponse {
  userId: string;
  email: string;
  isExistingAccount: boolean;
  role: Role;
}

export enum CheckUsernameErrorsEnum {
  EMAIL_NOT_EXISTS = '/dpl/cs/errors/email_not_exist',
}

export interface LoginUserResponse {
  customerId: string;
  crossDeviceTracking: string;
  customerNumber: string;
  email?: string;
}

export interface LoggedInCustomerPersonalDetailsResponse {
  customerNumber?: string;
  firstName: string;
  lastName: string;
  username?: string;
  salutation: Salutation;
  careOf?: string;
  birthdate?: string;
  email?: string;
  phoneNumber?: string;
  street?: string;
  streetNumber?: string;
  addressAddition?: string;
  zipCode?: string;
  city?: string;
  countryCode?: CountryCode;
}

export enum ReCaptchaAction {
  USERNAME = 'streamer_portal_username',
  LOGIN = 'streamer_portal_login',
}

export interface StreamerRegistrationRequest {
  activationTokenId: string;
  password: string;
}

export interface StreamerRegistrationResponse {
  customerId: string;
}

export interface SetPasswordRequest {
  token: string;
  password: string;
}
