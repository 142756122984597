import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';

interface Props extends ButtonProps {
  children: React.ReactNode;
}
const ButtonStyles = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  borderRadius: '0px',
  lineHeight: 1.5,
  height: 40,
  backgroundColor: '#FF3C28',
  borderColor: '#FF3C28',
  '&:hover': {
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
  },
});
function SubmitButton({ children, ...props }: Props) {
  return (
    <ButtonStyles {...props} variant={props.variant ?? 'contained'}>
      {children}
    </ButtonStyles>
  );
}

export default SubmitButton;
