import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { hideCreateContentDialog } from '../../../notifications/state/notificationsSlice';
import routePaths from '../../../routes/routePaths';
import styles from './CreateContentCards.module.scss';

function CreateContentCards() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const navigateTo = (path: string) => {
    dispatch(hideCreateContentDialog());
    if (location.pathname != path) history.push(path);
  };

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.item} onClick={() => navigateTo(routePaths.creator.addShow)}>
        <Box className={styles.card}>
          <LiveTvOutlinedIcon fontSize={'inherit'} />
        </Box>
        <Typography variant={'body1'} textAlign={'center'}>
          Stream planen
        </Typography>
      </Box>
      <Box className={styles.item} onClick={() => navigateTo(routePaths.creator.addPost)}>
        <Box className={styles.card}>
          <WysiwygOutlinedIcon fontSize={'inherit'} />
        </Box>
        <Typography variant={'body1'} textAlign={'center'}>
          Post erstellen
        </Typography>
      </Box>
    </Box>
  );
}

export default CreateContentCards;
