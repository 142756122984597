import { Box, Link, Typography } from '@mui/material';
import { isBrowser, isIOS } from 'react-device-detect';
import { ReactComponent as AppStoreLogo } from '../../../assets/app-store-logo.svg';
import { ReactComponent as GooglePlayLogo } from '../../../assets/google-play-logo.svg';
import { ReactComponent as HseAppLogo } from '../../../assets/hse-app-logo.svg';
import t from '../../../constants/translation';
import styles from './HseAppLinks.module.scss';

const HseGooglePlayLink = () => (
  <Link
    target="_blank"
    rel="opener"
    href="https://play.google.com/store/apps/details?id=de.hse24&utm_source=webshop&utm_medium=footer&utm_campaign=AppStoreButton"
  >
    <GooglePlayLogo data-testid="google-play-logo" />
  </Link>
);
const HseAppStoreLink = () => (
  <Link
    target="_blank"
    rel="opener"
    href="https://apps.apple.com/de/app/hse-here-shopping-entertains/id1016812570?utm_campaign=AppStoreButton&utm_medium=footer&utm_source=webshop"
  >
    <AppStoreLogo data-testid="app-store-logo" />
  </Link>
);

const HseWebStoreLink = () => (
  <Link target="_blank" rel="opener" href="https://www.hse.de/dpl/c/app">
    <HseAppLogo data-testid="hse-app-logo" />
  </Link>
);

export default function HseAppLinks() {
  return (
    <footer data-testid="hse-apps">
      <Box className={styles.shop_apps_hint}>
        <Typography variant="body1">{t.auth['shop app hint']}</Typography>
      </Box>
      <Box className={styles.apps}>
        <HseWebStoreLink />
        {isBrowser ? (
          <>
            <HseAppStoreLink />
            <HseGooglePlayLink />
          </>
        ) : isIOS ? (
          <HseAppStoreLink />
        ) : (
          <HseGooglePlayLink />
        )}
      </Box>
    </footer>
  );
}
