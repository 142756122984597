import { useSelector } from 'react-redux';
import DeleteExpiredShowReminder from '../../../shows/component/DeleteExpiredShowsReminder/DeleteExpiredShowReminder';
import { selectCreatorUpcomingShows } from '../../../shows/state/showsSlice';
import useExpiredStreamsManager from '../../../shows/utils/useExpiredStreamsManager';

function DeleteExpiredShowsReminder() {
  const upcomingShows = useSelector(selectCreatorUpcomingShows);
  const { expiredStreamToShow, expiredStreams, handleDismissExpiredStream } =
    useExpiredStreamsManager(upcomingShows);

  return (
    <>
      {expiredStreams.map(expiredStream => (
        <DeleteExpiredShowReminder
          key={expiredStream.id}
          isOpen={expiredStreamToShow?.id === expiredStream.id}
          show={expiredStream}
          handleDismissExpiredStream={handleDismissExpiredStream}
        />
      ))}
    </>
  );
}

export default DeleteExpiredShowsReminder;
