import { Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import styles from './StreamerAcademyVideo.module.scss';

function StreamerAcademyVideoSkeleton() {
  return (
    <>
      <Box mt={'3'} display={'flex'} flexDirection={'column'} gap={2} data-testid={'skeleton'}>
        <Box className={styles.player}>
          <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={'100%'} />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <Skeleton variant="rectangular" width={100} height={32} />
        </Box>
        <Box>
          <Paper elevation={0}>
            <Box p={1} display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Skeleton variant="rectangular" animation={false} width={200} height={32} />
                <Skeleton variant="rectangular" animation={false} width={100} height={24} />
              </Box>
              <Grid container spacing={1} xs={12}>
                <Grid item xs={6} className={styles.episode}>
                  <Box className={styles.preview_skeleton} mt={1} mb={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                  </Box>
                  <Box>
                    <Typography variant={'body1'} gutterBottom>
                      <Skeleton
                        variant="rectangular"
                        animation={false}
                        width={'100%'}
                        height={32}
                      />
                    </Typography>
                    <Typography variant={'caption'}>
                      <Skeleton variant="rectangular" animation={false} width={'50%'} height={24} />
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} className={styles.episode}>
                  <Box className={styles.preview_skeleton} mt={1} mb={1}>
                    <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
                  </Box>
                  <Box>
                    <Typography variant={'body1'} gutterBottom>
                      <Skeleton
                        variant="rectangular"
                        animation={false}
                        width={'100%'}
                        height={32}
                      />
                    </Typography>
                    <Typography variant={'caption'}>
                      <Skeleton variant="rectangular" animation={false} width={'50%'} height={24} />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
}

export default StreamerAcademyVideoSkeleton;
