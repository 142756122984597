import { CheckCircleOutline } from '@mui/icons-material';
import { Typography } from '@mui/material';

interface Props {
  voucherCode?: string;
}
const VoucherBadge = ({ voucherCode }: Props) => {
  return voucherCode != undefined ? (
    <CheckCircleOutline data-testid="check-circle-outline" color="success" />
  ) : (
    <Typography align="center">-</Typography>
  );
};
export default VoucherBadge;
