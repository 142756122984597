import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import { clearProductsSelection } from '../../../../products/state/productsSlice';
import theme from '../../../../theme/theme.module.scss';
import { ShowRequest } from '../../../api/showsRequestResponse';
import AdminAddShowForm from '../../../component/AdminAddShowForm/AdminAddShowForm';
import Loading from '../../../component/Loading/Loading';
import {
  adminCreateNewShow,
  fetchShow,
  fetchShowDetails,
  selectShowIsBeingLoaded,
  selectShowState,
} from '../../../state/showsSlice';
import styles from './DuplicateShowPage.module.scss';

export const DuplicateShowPage = () => {
  const { showId } = useParams<{ showId: string }>();
  const dispatch = useDispatch();
  const show = useSelector(selectShowState);
  const loading = useSelector(selectShowIsBeingLoaded);

  const onCreateShow = (values: ShowRequest) =>
    dispatch(adminCreateNewShow({ showRequest: values, redirect: true }));

  useEffect(() => {
    dispatch(clearProductsSelection());
    dispatch(fetchShow(showId));
    dispatch(fetchShowDetails(showId));
  }, [showId]);

  if (loading) {
    return <Loading />;
  }

  if (!show) {
    return (
      <ErrorPageDialog
        errorDialogType={ErrorPageDialogType.ENTITY_NOT_FOUND}
        entityLabel="Stream"
      />
    );
  }

  return (
    <PageContainer
      pageToRender={
        <div className={styles.container}>
          <AdminAddShowForm
            key={show.id}
            isCreate={true}
            showToDuplicate={show}
            submitCallback={onCreateShow}
          />
        </div>
      }
      backgroundColor={theme.whiteLilac}
    />
  );
};

export default DuplicateShowPage;
