import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import { TransitionProps } from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';
import cx from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { forwardRef, ReactElement, Ref, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import useCountdown from '../../../utils/useCountDown';
import { EUROPE_BERLIN_TIMEZONE } from '../../constants';
import { UpcomingShow } from '../../model/shows';
import { deleteExpiredShow } from '../../state/showsSlice';
import styles from './DeleteExpiredShowReminder.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomFade = styled(Fade)({
  // Customize the styling if needed
  '&.MuiFade-entering, &.MuiFade-exiting, &.MuiFade-exited': {
    opacity: 0,
  },
  '&.MuiFade-entered': {
    opacity: 1,
    transition: 'opacity 300ms ease-out',
  },
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: Ref<unknown>
) {
  return <CustomFade {...props} ref={ref} timeout={300} />;
});

interface DeleteShowReminderDialogProps {
  isOpen: boolean;
  show: UpcomingShow;
  handleDismissExpiredStream: (expiredStreamId: string) => void;
}

const DeleteExpiredShowReminder = ({
  isOpen,
  show,
  handleDismissExpiredStream,
}: DeleteShowReminderDialogProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = isMobileBreakPoint();
  const paperRef = useRef<HTMLDivElement | null>(null);
  const [step, setStep] = useState(1);
  const formattedTime = useCountdown(show.scheduledAt);

  const translation = t.creators.show;

  const nextStep = () => setStep(prevStep => prevStep + 1);

  const handleClose = () => {
    handleDismissExpiredStream(show.id);
  };

  const handleEdit = () => {
    history.push(routePaths.creator.updateShow.replace(':showId', show.id));
    handleClose();
  };

  const handleDelete = () => {
    if (step === 1) {
      nextStep();
    } else {
      dispatch(deleteExpiredShow(show.id));
      handleClose();
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <Typography variant="body1">{translation['missed planned stream']}</Typography>
            <Typography variant="body1" fontWeight="bold">
              {`Do : ${dayjs(show.scheduledAt)
                .tz(EUROPE_BERLIN_TIMEZONE)
                .format('DD. MMM, HH:mm')}`}
            </Typography>
            <Typography className={styles.show_info} variant="body1" fontWeight="bold">
              {show.title}.
            </Typography>
            <Typography variant="body1">
              {translation['missed planned stream description']}
            </Typography>
          </>
        );
      case 2:
        return <Typography variant="body1">{translation['delete stream confirmation']}</Typography>;
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      PaperProps={{
        ref: paperRef,
        className: cx(styles.container, { [styles.dialog_paper]: !isMobile }),
      }}
      scroll="paper"
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
    >
      <IconButton className={styles.close_button} aria-label="close" onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent className={styles.content}>
        <Typography className={styles.title} variant="h6" fontWeight="bold">
          {translation['Expired stream']}
        </Typography>
        {renderStep()}
        <Typography className={styles.countdown_note} variant="body2">
          {`${translation['delete countdown']} `}
          <strong>{formattedTime}</strong> {translation.Hours}.
        </Typography>
      </DialogContent>
      <div className={styles.actions}>
        <Button className={styles.delete} variant="contained" size="medium" onClick={handleDelete}>
          {translation.Delete}
        </Button>
        <Button className={styles.edit} variant="outlined" size="medium" onClick={handleEdit}>
          {translation.Edit}
        </Button>
      </div>
    </Dialog>
  );
};

export default DeleteExpiredShowReminder;
