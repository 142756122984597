import { IconCheck } from '@hse24/shared-components';
import React, { useState } from 'react';
import t from '../../../../constants/translation';
import { ShowStatus } from '../../../model/shows';
import ShowStatusChangeModal from './ShowStatusChangeModal/ShowStatusChangeModal';
import styles from './ShowStatusDropDown.module.scss';

interface Props {
  showId: string;
  showStatus: ShowStatus;
  isShowReadyToBePublished: boolean;
}

interface StatusConfig {
  label: string;
  icon?: React.ReactNode;
  className?: string;
}

const statusConfigs: Partial<Record<ShowStatus, StatusConfig>> = {
  [ShowStatus.CREATED]: {
    label: t.creators.show.status.Published,
    icon: <IconCheck className={styles.checkmark_icon} />,
  },
  [ShowStatus.DRAFT]: {
    label: t.creators.show.status.Draft,
    className: styles.draft,
  },
  [ShowStatus.UNPUBLISHED]: {
    label: t.creators.show.status.Unpublished,
    className: styles.unpublished,
  },
};

const defaultConfig: StatusConfig = {
  label: 'UNKNOWN',
  className: styles.unknown,
};

const ShowStatusDropDown = ({ showId, showStatus, isShowReadyToBePublished }: Props) => {
  const config = statusConfigs[showStatus] ?? defaultConfig;
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const onStatusClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <div className={styles.container} onClick={onStatusClicked}>
        {config.icon}
        <span className={config.className}>{config.label}</span>
      </div>
      <ShowStatusChangeModal
        showId={showId}
        showStatus={showStatus}
        anchorEl={anchor}
        isOpen={!!anchor}
        handleClose={handleClose}
        testId="show-status-change-modal"
        isShowReadyToBePublished={isShowReadyToBePublished}
      />
    </>
  );
};

export default ShowStatusDropDown;
