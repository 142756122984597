import { IconWithText as IconWithTextComp } from '@hse24/shared-components';
import { ComponentProps } from 'react';
import styles from './IconWithText.module.scss';

type IconWithTextProps = ComponentProps<typeof IconWithTextComp>;

const IconWithText = (props: IconWithTextProps) => (
  <IconWithTextComp textClassName={styles.text} {...props}>
    {props.children}
  </IconWithTextComp>
);

export default IconWithText;
