import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { errorOccurred } from '../../../notifications/state/notificationsSlice';
import routePaths from '../../../routes/routePaths';
import amasApi from '../api/amasApi';
import { AmaCreateRequest, AmaUpdateRequest } from '../api/amasRequestResponse';
import { Ama } from '../model/ama';
import {
  createAmaAction,
  deleteAmaAction,
  fetchAmaByIdAction,
  fetchAmasByShowIdAction,
  setAma,
  setAmaLoading,
  setAmas,
  updateAmaByIdAction,
} from './amasSlice';

export function* createAmaHandler(action: PayloadAction<AmaCreateRequest>) {
  yield put(setAmaLoading(true));
  try {
    const ama: Ama = yield call(amasApi.create, action.payload);
    yield put(setAma(ama));
    if (ama) {
      yield put(
        push(
          routePaths.creator.onDemandInteractions.ama.display
            .replace(':showId', action.payload.showId)
            .replace(':amaId', ama.id)
        )
      );
    }
  } catch (error) {
    yield put(errorOccurred(error as Error));
  } finally {
    yield put(setAmaLoading(false));
  }
}

export function* updateAmaByIdHandler(
  action: PayloadAction<{ id: string; request: AmaUpdateRequest }>
) {
  yield put(setAmaLoading(true));
  try {
    const ama: Ama = yield call(amasApi.updateById, action.payload.id, action.payload.request);
    yield put(setAma(ama));
  } catch (error) {
    yield put(errorOccurred(error as Error));
  } finally {
    yield put(setAmaLoading(false));
  }
}

export function* fetchAmaByIdHandler(action: PayloadAction<string>) {
  yield put(setAmaLoading(true));
  try {
    const ama: Ama = yield call(amasApi.fetchById, action.payload);
    yield put(setAma(ama));
  } catch (error) {
    yield put(errorOccurred(error as Error));
  } finally {
    yield put(setAmaLoading(false));
  }
}

export function* deleteAmaHandler(action: PayloadAction<string>) {
  yield put(setAmaLoading(true));
  try {
    yield call(amasApi.deleteById, action.payload);
    yield put(push(routePaths.creator.shows));
  } catch (error) {
    yield put(errorOccurred(error as Error));
  } finally {
    yield put(setAmaLoading(false));
  }
}

export function* fetchAmasByShowIdHandler(action: PayloadAction<string>) {
  yield put(setAmaLoading(true));
  try {
    const amas: Ama[] = yield call(amasApi.fetchByShowId, action.payload);
    yield put(setAmas(amas));
  } catch (error) {
    yield put(errorOccurred(error as Error));
  } finally {
    yield put(setAmaLoading(false));
  }
}

export function* watcherAmasSagas() {
  yield takeLatest(createAmaAction.type, createAmaHandler);
  yield takeLatest(updateAmaByIdAction.type, updateAmaByIdHandler);
  yield takeLatest(fetchAmaByIdAction.type, fetchAmaByIdHandler);
  yield takeLatest(deleteAmaAction.type, deleteAmaHandler);
  yield takeLatest(fetchAmasByShowIdAction.type, fetchAmasByShowIdHandler);
}
