import { Button } from '@hse24/shared-components';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import t from '../../../constants/translation';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import { saveNewAgency } from '../../state/agenciesSlice';
const validationSchema = yup.object({
  name: yup
    .string()
    .min(3, t.validation['Enter at least 3 characters!'])
    .max(200, t.validation['The maximum is 200 characters']),
  email: yup.string().email(t.validation['Please enter a valid email address!']),
});

const AddAgencyFormPage = () => {
  const dispatch = useDispatch();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (values) {
        dispatch(saveNewAgency(values));
      }
    },
  });

  const isBtnDisabled = () => {
    const emailInvalid = !formik.values.email || Boolean(formik.errors.email);
    const nameInvalid = !formik.values.name || Boolean(formik.errors.name);
    return emailInvalid || nameInvalid;
  };

  const AddAgencyForm = () => (
    <>
      <Box mb={2}>
        <Typography variant="h5">{t.agencies['Create agency']}</Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container direction={'column'} spacing={4}>
          <Grid item>
            <TextField
              fullWidth
              multiline
              id="name"
              type="text"
              label="Name"
              variant="outlined"
              error={Boolean(formik.errors.name)}
              onChange={formik.handleChange}
              helperText={formik.errors.name}
              value={formik.values.name}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              multiline
              id="email"
              type="text"
              label="E-Mail"
              variant="outlined"
              error={Boolean(formik.errors.email)}
              onChange={formik.handleChange}
              helperText={formik.errors.email}
              value={formik.values.email}
            />
          </Grid>
          <Grid item>
            <Button disabled={isBtnDisabled()}>
              <Typography fontWeight={700}>{t.common.Save}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );

  return <PageContainer pageToRender={AddAgencyForm()} />;
};

export default AddAgencyFormPage;
