import { Currency } from '../payments/model/Payments';

export const getCurrency = (e: Currency) => {
  switch (e) {
    case Currency.EUR:
      return '€';

    default:
      return '€';
  }
};
