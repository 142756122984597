import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { RootState } from '../../app/store';
import {
  axiosErrorToSerializableAxiosError,
  isAxiosError,
  SerializableAxiosError,
} from '../../shared/axiosClient';

export interface ConfirmationDialogDetails {
  toggle: boolean;
  title?: string;
  contentText?: string;
  actionToDispatchOnClose?: PayloadAction;
  actionToTrigger?: () => void;
  submit?: string;
}

export interface SnackbarDetails {
  toggle: boolean;
  text?: string;
}

export interface ProgressDialogDetails {
  toggle: boolean;
  message: string;
}

export interface ErrorDialogDetails {
  toggle: boolean;
  errorCode: number | undefined;
  errorMessage: string | undefined;
}

export interface AmaMessageDialog {
  isOpen: boolean;
  showId?: string;
  amaResponsesCount?: number;
}

export interface SuccessDialogDetails {
  toggle: boolean;
  title?: string;
  text?: string;
}

export interface NotificationsState {
  confirmationDialogDetails: ConfirmationDialogDetails | undefined;
  snackbarDetails: SnackbarDetails | undefined;
  errorDialogDetails: ErrorDialogDetails | undefined;
  progressDialogDetails: ProgressDialogDetails | undefined;
  isOnboardingDialogCollapsed: boolean;
  isOnboardingIndicatorDialogHidden: boolean;
  managedDialog: ManagedDialog | undefined;
  showMarketingConsentDialog: boolean;
  showCreateContentDialog: boolean;
  showAmaMessagesDialog: AmaMessageDialog;
  amaOnboardingDialog: boolean;
  successDialogDetails?: SuccessDialogDetails;
}

export const initialState: NotificationsState = {
  confirmationDialogDetails: undefined,
  snackbarDetails: undefined,
  errorDialogDetails: undefined,
  progressDialogDetails: undefined,
  isOnboardingDialogCollapsed: false,
  isOnboardingIndicatorDialogHidden: true,
  managedDialog: undefined,
  showMarketingConsentDialog: false,
  showCreateContentDialog: false,
  showAmaMessagesDialog: {
    isOpen: false,
  },
  amaOnboardingDialog: false,
  successDialogDetails: undefined,
};

export interface ManagedDialog {
  isOpen: boolean;
  title: string | ReactNode;
  component: string;
}

const toSerializableError = (error: Error): SerializableAxiosError | Error => {
  if (isAxiosError(error)) return axiosErrorToSerializableAxiosError(error);
  return error;
};

export const errorOccurred = (error: Error) =>
  errorOccurredAction({ error: toSerializableError(error) });

export const errorOccurredAction = createAction<{ error: SerializableAxiosError | Error }>(
  '/notifications/errorOccurred'
);

export const collapseOnboarding = createAction<boolean>('/notifications/collapseOnboarding');

export const hideOnboardingIndicator = createAction<boolean>(
  '/notifications/hideOnboardingIndicator'
);

export const showProgressDialog = createAction<string>('/notifications/showProgressDialog');
export const showErrorDialog = createAction<string>('/notifications/showErrorDialog');
export const hideProgressDialog = createAction('/notifications/hideProgressDialog');
export const showMarketingConsentIfNeeded = createAction(
  '/notifications/showMarketingConsentIfNeeded'
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    openConfirmationDialog: (
      state,
      action: PayloadAction<{
        title: string;
        contentText: string;
        actionToDispatchOnClose?: PayloadAction;
        actionToTrigger?: () => void;
        submit?: string;
      }>
    ) => {
      state.confirmationDialogDetails = {
        toggle: true,
        title: action.payload.title,
        contentText: action.payload.contentText,
        actionToDispatchOnClose: action.payload.actionToDispatchOnClose,
        actionToTrigger: action.payload.actionToTrigger,
        submit: action.payload.submit,
      };
    },
    closeConfirmationDialog: state => {
      state.confirmationDialogDetails = {
        toggle: false,
        title: undefined,
        contentText: undefined,
        actionToDispatchOnClose: undefined,
      };
    },
    showSnackbar: (state, action: PayloadAction<Omit<SnackbarDetails, 'toggle'>>) => {
      state.snackbarDetails = {
        ...action.payload,
        toggle: true,
      };
    },
    closeSnackbar: state => {
      state.snackbarDetails = { toggle: false, text: undefined };
    },
    openErrorDialog: (
      state,
      action: PayloadAction<{
        message?: string;
        errorCode?: number;
      }>
    ) => {
      state.errorDialogDetails = {
        toggle: true,
        errorCode: action.payload.errorCode,
        errorMessage: action.payload.message,
      };
    },
    closeErrorDialog: state => {
      state.errorDialogDetails = {
        toggle: false,
        errorMessage: undefined,
        errorCode: undefined,
      };
    },
    openProgressDialog: (state: NotificationsState, action: PayloadAction<string>) => {
      state.progressDialogDetails = { toggle: true, message: action.payload };
    },
    closeProgressDialog: (state: NotificationsState) => {
      state.progressDialogDetails = { toggle: false, message: '' };
    },
    collapseOnboardingDialog: (state: NotificationsState, action: PayloadAction<boolean>) => {
      state.isOnboardingDialogCollapsed = action.payload;
    },
    hideOnboardingIndicatorDialog: (state: NotificationsState, action: PayloadAction<boolean>) => {
      state.isOnboardingIndicatorDialogHidden = action.payload;
    },
    openManagedDialog: (
      state,
      action: PayloadAction<{
        title: string | ReactNode;
        component: string;
      }>
    ) => {
      state.managedDialog = {
        isOpen: true,
        title: action.payload.title,
        component: action.payload.component,
      };
    },
    closeManagedDialog: state => (state.managedDialog = undefined),
    showMarketingConsentDialog: state => {
      state.showMarketingConsentDialog = true;
    },
    closeConsentDialog: state => {
      state.showMarketingConsentDialog = false;
    },
    showCreateContentDialog: state => {
      state.showCreateContentDialog = true;
    },
    hideCreateContentDialog: state => {
      state.showCreateContentDialog = false;
    },
    showAmaMessagesDialog: (state, action: PayloadAction<AmaMessageDialog>) => {
      state.showAmaMessagesDialog = action.payload;
    },
    hideAmaMessagesDialog: state => {
      state.showAmaMessagesDialog = {
        isOpen: false,
      };
    },
    showAmaOnboardingDialog: state => {
      state.amaOnboardingDialog = true;
    },
    hideAmaOnboardingDialog: state => {
      state.amaOnboardingDialog = false;
    },
    openSuccessDialog: (state, action: PayloadAction<{ title: string; text: string }>) => {
      state.successDialogDetails = {
        toggle: true,
        title: action.payload.title,
        text: action.payload.text,
      };
    },
    closeSuccessDialog: state => {
      state.successDialogDetails = {
        toggle: false,
        title: undefined,
        text: undefined,
      };
    },
  },
});

export const {
  openConfirmationDialog,
  closeConfirmationDialog,
  showSnackbar,
  closeSnackbar,
  openErrorDialog,
  closeErrorDialog,
  openProgressDialog,
  closeProgressDialog,
  openManagedDialog,
  closeManagedDialog,
  collapseOnboardingDialog,
  hideOnboardingIndicatorDialog,
  showMarketingConsentDialog,
  closeConsentDialog,
  showCreateContentDialog,
  hideCreateContentDialog,
  showAmaMessagesDialog,
  hideAmaMessagesDialog,
  showAmaOnboardingDialog,
  hideAmaOnboardingDialog,
  openSuccessDialog,
  closeSuccessDialog,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const selectConfirmationDialogDetails = (state: RootState) =>
  state.notifications.confirmationDialogDetails;

export const selectSnackbarDetails = (state: RootState) => state.notifications.snackbarDetails;

export const selectErrorDialogDetails = (state: RootState) =>
  state.notifications.errorDialogDetails;

export const selectProgressDialogDetails = (state: RootState) =>
  state.notifications.progressDialogDetails;

export const selectOnboardingDialogCollapsed = (state: RootState) =>
  state.notifications.isOnboardingDialogCollapsed;

export const selectOnboardingIndicatorDialogStatus = (state: RootState) =>
  state.notifications.isOnboardingIndicatorDialogHidden;

export const selectManagedDialog = (state: RootState) => state.notifications.managedDialog;

export const selectConsentDialogOpen = (state: RootState) =>
  state.notifications.showMarketingConsentDialog;

export const selectCreateContentDialog = (state: RootState) =>
  state.notifications.showCreateContentDialog;

export const selectAmaMessageDialog = (state: RootState) =>
  state.notifications.showAmaMessagesDialog;

export const selectAmaOnboardingDialog = (state: RootState) =>
  state.notifications.amaOnboardingDialog;

export const selectSucessDialogDetails = (state: RootState) =>
  state.notifications.successDialogDetails;
