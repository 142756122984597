import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { generatePath } from 'react-router-dom';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { addToAgencyStreamers } from '../../agencies/state/agenciesSlice';
import { Role } from '../../auth/role';
import { selectUserRoles } from '../../auth/state/authSlice';
import { PreSignedUrlResponse, QueryParams } from '../../common/types';
import { LogLevel } from '../../integration/logginglambda/LoggingLambdaApi';
import { logToLambda } from '../../integration/logginglambda/loggingSlice';
import paymentServiceApi from '../../integration/paymentservice/PaymentServiceApi';
import {
  closeConsentDialog,
  closeManagedDialog,
  closeProgressDialog,
  errorOccurred,
  hideOnboardingIndicator,
  openManagedDialog,
  openProgressDialog,
  showMarketingConsentDialog,
  showSnackbar,
} from '../../notifications/state/notificationsSlice';
import routePaths from '../../routes/routePaths';
import { ApiReturnType } from '../../shared/ApiReturnType';
import { put as sendPutRequest } from '../../shared/axiosClient';
import { GeneratePreSignedShowUrlResponse } from '../../shows/api/showsRequestResponse';
import { ShowImageExtension, ShowImages, ShowImagesData } from '../../shows/model/shows';
import getBlobFromUrl from '../../utils/images/getBlobFromUrl';
import creatorsApi from '../api/creatorsApi';
import {
  AdminNewCreatorRequest,
  CreatorPreSignedUrlResponse,
  CreatorProfilePictureRequest,
  CreatorsOverviewResponse,
  NewCreatorRequest,
  SetShowImageRequest,
  ShopLinkPreSignedUrlRequest,
  UpdateCreatorRequest,
} from '../api/requestResponse';
import validateAddressApi, { AddressValidateRequest } from '../api/validateAddressApi';
import { AdminAddCreatorDataUi } from '../components/AddCreatorsForm/AdminAddCreatorForm/AdminAddCreatorForm';
import { AgencyAddCreatorDataUi } from '../components/AddCreatorsForm/AgencyAddCreatorForm/AgencyAddCreatorForm';
import DeleteAccountConfirmationModal from '../components/DeleteAccount/DeleteAccountConfirmationModal/DeleteAccountConfirmationModal';
import ProfilePersonalData from '../components/Profile/ProfilePersonalData';
import { Address, AddressRecommendation } from '../model/addressRecommendation';
import {
  CreatorImageType,
  CreatorRole,
  CreatorShopLinkData,
  LoggedInCreator,
} from '../model/creator';
import {
  mapAdminAddCreatorDataUiToAdminNewCreatorRequest,
  mapAgencyAddCreatorDataUiToNewCreatorRequest,
} from '../utils/mappers/creatorMapper';
import {
  acceptMarketingConsent,
  acceptTermsAndConditions,
  amaOnboardingCompleted,
  approveCreator,
  clearAddressRecommendation,
  clearCreatorShopLinks,
  clearSelectedCreator,
  createApprovedCreator,
  deleteCreatorAccount,
  deleteCreatorShopLink,
  editCreatorShopLink,
  fetchCreator,
  fetchCreatorShopLinksList,
  fetchCreatorsList,
  fetchCreatorStatistics,
  fetchLoggedInCreator,
  getCreatorPerformanceOverview,
  getCreatorsOverview,
  hideAddressRecommendation,
  navigateToCreatorDetailsPage,
  onboardingCompleted,
  profileCompleted,
  resendWelcomeEmail,
  samplesOrdered,
  samplesReceived,
  saveCreatorShopLink,
  saveNewCreator,
  selectLoggedInCreator,
  setAcceptTermsAndConditionsInProgress,
  setAddressRecommendation,
  setCreatorPerformanceOverview,
  setCreatorShopLinks,
  setCreatorShopLinksIsLoading,
  setCreatorsIsLoading,
  setCreatorsList,
  setCreatorsOverview,
  setCreatorStatistics,
  setLoggedInCreator,
  setLoggedInCreatorBic,
  setLoggedInCreatorShowImages,
  setProfilePersonalData,
  setSelectedCreator,
  shopSetupCompleted,
  updateCreatorDetails,
  updateCreatorShowImages,
  validateAddress,
  validateCreatorIban,
} from './creatorsSlice';

export function* createCreatorHandler(action: PayloadAction<AgencyAddCreatorDataUi>) {
  try {
    yield put(openProgressDialog('Lege Streamer an...'));
    const newCreatorRequest: NewCreatorRequest = mapAgencyAddCreatorDataUiToNewCreatorRequest(
      action.payload
    );
    const newStreamer: ApiReturnType<typeof creatorsApi.saveCreator> = yield call(
      creatorsApi.saveCreator,
      newCreatorRequest
    );
    yield put(addToAgencyStreamers(newStreamer));
    yield put(showSnackbar({ text: 'Die Anlage des Streamers wurde initiiert.' }));
    yield put(closeProgressDialog());
    yield put(closeManagedDialog());
    yield put(push(routePaths.agency.creatorsOverview));
  } catch (unknownError: unknown) {
    yield put(closeProgressDialog());
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* createApprovedCreatorHandler(action: PayloadAction<AdminAddCreatorDataUi>) {
  try {
    yield put(openProgressDialog('Lege Streamer an...'));
    const newCreatorRequest: AdminNewCreatorRequest =
      mapAdminAddCreatorDataUiToAdminNewCreatorRequest(action.payload);
    yield call(creatorsApi.createApprovedCreator, newCreatorRequest);
    yield put(showSnackbar({ text: 'Die Anlage des Streamers wurde initiiert.' }));
    yield put(closeProgressDialog());
    yield put(closeManagedDialog());
    yield put(push(routePaths.hseEmployee.creatorsOverview));
  } catch (unknownError: unknown) {
    yield put(closeProgressDialog());
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* updateCreatorHandler(action: PayloadAction<ProfilePersonalData>) {
  try {
    yield put(openProgressDialog('Aktualisiere Daten...'));
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    const creatorId = creator?.id;
    if (creatorId) {
      const request = createRequestFrom(action.payload);
      if (action.payload.profileImage.imageUrl != creator.profileImageUrl) {
        yield call(uploadProfilePicture, creatorId, action.payload.profileImage);
      }
      const updatedCreator: ApiReturnType<typeof creatorsApi.updateCreator> = yield call(
        creatorsApi.updateCreator,
        request,
        creatorId
      );
      if (isProfileCompleted(creator, updatedCreator)) {
        yield put(profileCompleted());
        // After updating a profile in the onboarding process we need to fetch creator data to update the onboarding flags.
        yield put(fetchLoggedInCreator());
      }
      yield put(setLoggedInCreator(updatedCreator));
      yield put(clearAddressRecommendation());
      yield put(closeProgressDialog());
      yield put(showSnackbar({ text: 'Deine Änderungen wurden erfolgreich gespeichert!' }));
    }
  } catch (unknownError: unknown) {
    yield put(closeProgressDialog());
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* uploadShowImage(
  creatorId: string,
  imageUrl: string,
  imageType: CreatorImageType,
  fileExtension: ShowImageExtension
) {
  try {
    const preSignedUrlResponse: GeneratePreSignedShowUrlResponse = yield call(
      creatorsApi.generateProfilePicturePreSignedUrl,
      creatorId,
      fileExtension,
      imageType
    );
    const previewImageData: Blob = yield call(getBlobFromUrl, imageUrl);
    yield call(sendPutRequest, preSignedUrlResponse.preSignedUrl, previewImageData);

    return preSignedUrlResponse;
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* updateCreatorShowImagesHandler(
  action: PayloadAction<ShowImagesData>
): Generator<unknown, void, LoggedInCreator & ShowImages & GeneratePreSignedShowUrlResponse[]> {
  try {
    yield put(openProgressDialog('Aktualisiere Daten...'));

    const {
      payload: { preview, curtainAfter, curtainBefore },
    } = action;

    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    const creatorId = creator?.id;

    if (!creatorId) return;

    const showImages: SetShowImageRequest[] = [];

    const showImagesData = [preview, curtainAfter, curtainBefore];
    const [previewResult, curtainAfterResult, curtainBeforeResult] = yield all(
      showImagesData.map(
        showImage =>
          showImage.changed &&
          showImage.imageUrl &&
          call(
            uploadShowImage,
            creatorId,
            showImage.imageUrl,
            showImage.imageType,
            showImage.fileExtension
          )
      )
    );

    if (previewResult) {
      showImages.push({ imageKey: previewResult.key, imageType: CreatorImageType.PREVIEW });
    }

    if (curtainAfterResult) {
      showImages.push({
        imageKey: curtainAfterResult.key,
        imageType: CreatorImageType.CURTAIN_AFTER,
      });
    }

    if (curtainBeforeResult) {
      showImages.push({
        imageKey: curtainBeforeResult.key,
        imageType: CreatorImageType.CURTAIN_BEFORE,
      });
    }

    if (showImages.length === 0) return;

    const updatedShowImages: ShowImages | null = yield call(creatorsApi.updateCreatorShowImages, {
      showImages,
    });

    if (updatedShowImages) {
      yield put(setLoggedInCreatorShowImages(updatedShowImages));
    }

    yield put(showSnackbar({ text: 'Änderungen erfolgreich gespeichert' }));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(closeProgressDialog());
  }
}

export function* uploadProfilePicture(
  creatorId: string,
  profilePicture: CreatorProfilePictureRequest
) {
  const preSignedUrl: CreatorPreSignedUrlResponse = yield call(
    creatorsApi.generateProfilePicturePreSignedUrl,
    creatorId,
    profilePicture.fileExtension,
    CreatorImageType.PROFILE
  );
  const profileImageData: Blob = yield call(getBlobFromUrl, profilePicture.imageUrl);
  yield call(sendPutRequest, preSignedUrl.preSignedUrl, profileImageData);
}

function createRequestFrom(values: ProfilePersonalData): UpdateCreatorRequest {
  return {
    name: values.name || '',
    description: values.description || '',
    salutation: values.salutation,
    firstName: values.firstName,
    lastName: values.lastName,
    street: values.street,
    streetNumber: values.streetNumber,
    zipCode: values.zipCode,
    city: values.city,
    phoneNumber: values.phoneNumber || '',
    birthdate: values.birthdate,
    ...(values.isLiableToVat !== undefined &&
      values.iban && {
        businessData: {
          iban: values.iban,
          vatId: values.vatId,
          isLiableToVat: values.isLiableToVat,
          taxId: values.taxId,
        },
      }),
    socialMediaAccounts: {
      facebook: values.facebook,
      instagram: values.instagram,
      youtube: values.youtube,
      pinterest: values.pinterest,
      snapchat: values.snapchat,
      tiktok: values.tiktok,
    },
  };
}

export function* fetchLoggedInCreatorPropagateError() {
  const creator: ApiReturnType<typeof creatorsApi.getLoggedInCreator> = yield call(
    creatorsApi.getLoggedInCreator
  );
  yield put(setLoggedInCreator(creator));
}

export function* fetchLoggedInCreatorHandler() {
  try {
    yield call(fetchLoggedInCreatorPropagateError);
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* fetchCreatorStatisticsHandler(action: PayloadAction<{ creatorId: string }>) {
  if (action.payload.creatorId === '') {
    yield put(
      logToLambda({
        level: LogLevel.WARN,
        message: 'error occurred when trying to fetch creator statistics',
        error: 'creatorId is empty',
      })
    );
  } else {
    try {
      const creatorStatistics: ApiReturnType<typeof creatorsApi.getCreatorStatistics> = yield call(
        creatorsApi.getCreatorStatistics,
        action.payload.creatorId
      );
      yield put(setCreatorStatistics(creatorStatistics));
    } catch (unknownError: unknown) {
      yield put(errorOccurred(unknownError as Error));
    }
  }
}

export function* fetchCreatorHandler(action: PayloadAction<{ creatorId: string }>) {
  try {
    yield put(clearSelectedCreator());
    const creator: ApiReturnType<typeof creatorsApi.getCreatorDetailsOverview> = yield call(
      creatorsApi.getCreatorDetailsOverview,
      action.payload.creatorId
    );
    yield put(setSelectedCreator(creator));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* fetchCreatorShopLinksHandler(action: PayloadAction<{ creatorId: string }>) {
  try {
    yield put(clearCreatorShopLinks());
    yield put(setCreatorShopLinksIsLoading(true));
    const shopLinks: ApiReturnType<typeof creatorsApi.getCreatorShopLinks> = yield call(
      creatorsApi.getCreatorShopLinks,
      action.payload.creatorId
    );
    yield put(setCreatorShopLinks(shopLinks));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatorShopLinksIsLoading(false));
  }
}

export function* uploadShopLinkImage(creatorId: string, imageUrl: string, fileExtension: string) {
  try {
    const request: ShopLinkPreSignedUrlRequest = { fileExtension };

    const preSignedUrlResponse: PreSignedUrlResponse = yield call(
      creatorsApi.generateShopLinkPreSignedUrl,
      creatorId,
      request
    );

    const shopLinkImageBlob: Blob = yield call(getBlobFromUrl, imageUrl);

    yield call(sendPutRequest, preSignedUrlResponse.preSignedUrl, shopLinkImageBlob);
    return preSignedUrlResponse;
  } catch (err) {
    console.error(err);
    yield put(errorOccurred(err as Error));
  }
}

export function* saveCreatorShopLinkHandler(
  action: PayloadAction<{
    creatorId: string;
    shopLinkData: CreatorShopLinkData;
  }>
) {
  try {
    const payload = action.payload;
    yield put(setCreatorShopLinksIsLoading(true));

    const uploadImageResponse: PreSignedUrlResponse = yield call(
      uploadShopLinkImage,
      payload.creatorId,
      payload.shopLinkData.preview.imageUrl,
      payload.shopLinkData.preview.fileExtension
    );

    yield call(creatorsApi.saveCreatorShopLink, payload.creatorId, {
      ...payload.shopLinkData,
      s3Key: uploadImageResponse.key,
    });

    const shopLinks: ApiReturnType<typeof creatorsApi.getCreatorShopLinks> = yield call(
      creatorsApi.getCreatorShopLinks,
      action.payload.creatorId
    );

    yield put(setCreatorShopLinks(shopLinks));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatorShopLinksIsLoading(false));
  }
}

export function* editCreatorShopLinkHandler(
  action: PayloadAction<{
    creatorId: string;
    shopLinkId: string;
    shopLinkData: CreatorShopLinkData;
  }>
) {
  try {
    yield put(setCreatorShopLinksIsLoading(true));

    const payload = action.payload;
    const preview = payload.shopLinkData.preview;
    let s3Key: string | undefined;

    switch (preview.changed) {
      case true:
        const uploadImageResponse: PreSignedUrlResponse = yield call(
          uploadShopLinkImage,
          payload.creatorId,
          preview.imageUrl,
          preview.fileExtension
        );
        s3Key = uploadImageResponse.key;
        break;
      default:
        s3Key = undefined;
        break;
    }

    yield call(creatorsApi.editCreatorShopLink, payload.creatorId, payload.shopLinkId, {
      type: payload.shopLinkData.type,
      url: payload.shopLinkData.url,
      label: payload.shopLinkData.label,
      s3Key,
    });

    const shopLinks: ApiReturnType<typeof creatorsApi.getCreatorShopLinks> = yield call(
      creatorsApi.getCreatorShopLinks,
      action.payload.creatorId
    );

    yield put(setCreatorShopLinks(shopLinks));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatorShopLinksIsLoading(false));
  }
}

export function* deleteCreatorShopLinkHandler(
  action: PayloadAction<{
    creatorId: string;
    shopLinkId: string;
  }>
) {
  try {
    const payload = action.payload;
    yield put(setCreatorShopLinksIsLoading(true));

    yield call(creatorsApi.deleteCreatorShopLink, payload.creatorId, payload.shopLinkId);

    const shopLinks: ApiReturnType<typeof creatorsApi.getCreatorShopLinks> = yield call(
      creatorsApi.getCreatorShopLinks,
      action.payload.creatorId
    );

    yield put(setCreatorShopLinks(shopLinks));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatorShopLinksIsLoading(false));
  }
}

export function* approveCreatorHandler(
  action: PayloadAction<{ creatorId: string; role: CreatorRole }>
) {
  try {
    yield put(openProgressDialog('Gebe Streamer frei...'));
    yield call(creatorsApi.approveCreator, action.payload.creatorId, {
      role: action.payload.role,
    });
    yield put(showSnackbar({ text: 'Der Freigabeprozess wurde erfolgreich initiiert' }));
    yield put(closeProgressDialog());
  } catch (unknownError: unknown) {
    yield put(closeProgressDialog());
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* fetchCreatorPerformanceOverviewHandler() {
  try {
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    if (creator) {
      const creatorPerformanceOverview: ApiReturnType<
        typeof creatorsApi.getStreamerPerformanceOverview
      > = yield call(creatorsApi.getStreamerPerformanceOverview, creator.id);
      yield put(setCreatorPerformanceOverview(creatorPerformanceOverview));
    } else {
      yield call(fetchLoggedInCreatorHandler);
    }
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* navigateToCreatorDetailsPageHandler(action: PayloadAction<{ creatorId: string }>) {
  const userRoles: Role[] = yield select(selectUserRoles);
  const route = userRoles.includes(Role.AGENCY)
    ? routePaths.agency.creatorDetails
    : routePaths.hseEmployee.creatorDetails;
  const path = generatePath(route, { streamerId: action.payload.creatorId });
  yield put(push(path));
}

export function* validateCreatorIbanHandler(action: PayloadAction<{ iban: string }>) {
  try {
    const bic: ApiReturnType<typeof paymentServiceApi.validateIban> = yield call(
      paymentServiceApi.validateIban,
      action.payload.iban
    );
    yield put(setLoggedInCreatorBic(bic));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* validateAddressHandler(action: PayloadAction<ProfilePersonalData>) {
  try {
    const personalData = action.payload;
    const addressRecommendationResponse: ApiReturnType<typeof validateAddressApi.validateAddress> =
      yield call(validateAddressApi.validateAddress, createValidateAddressRequest(personalData));
    const addressRecommendation: AddressRecommendation = addressRecommendationResponse[0];

    if (areAddressesEqual(addressRecommendation, personalData)) {
      yield put(updateCreatorDetails(personalData));
    } else {
      yield put(setAddressRecommendation(addressRecommendation));
      yield put(setProfilePersonalData(personalData));
    }
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* sampleOrderedHandler() {
  try {
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield call(creatorsApi.samplesOrdered, loggedInCreator.id);
    yield put(
      setLoggedInCreator({
        ...loggedInCreator,
        onboarding: { ...loggedInCreator.onboarding, isSamplesOrdered: true },
      })
    );
    yield put(push(routePaths.creator.shows));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* sampleReceivedHandler() {
  try {
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield call(creatorsApi.samplesReceived, loggedInCreator.id);
    yield put(
      setLoggedInCreator({
        ...loggedInCreator,
        onboarding: { ...loggedInCreator.onboarding, isSamplesReceived: true },
      })
    );
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* onboardingCompletedHandler() {
  try {
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield call(creatorsApi.onboardingCompleted, loggedInCreator.id);
    yield put(
      setLoggedInCreator({
        ...loggedInCreator,
        onboarding: {
          ...loggedInCreator.onboarding,
          isOnboardingCompleted: true,
        },
      })
    );
    yield put(hideOnboardingIndicator(false));
    yield put(push(routePaths.creator.dashboard));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* amaOnboardingCompletedHandler() {
  try {
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield call(creatorsApi.amaOnboardingCompleted, loggedInCreator.id);
    yield put(
      setLoggedInCreator({
        ...loggedInCreator,
        isAmaOnboardingCompleted: true,
      })
    );
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* acceptTermsAndConditionsHandler() {
  try {
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield put(setAcceptTermsAndConditionsInProgress(true));
    const updatedLoggedInCreator: LoggedInCreator = yield call(
      creatorsApi.acceptTermsAndConditions,
      loggedInCreator.id
    );
    yield put(setLoggedInCreator(updatedLoggedInCreator));
    yield put(shopSetupCompleted());
    yield put(showMarketingConsentDialog());
    yield put(push(routePaths.creator.editProfile));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setAcceptTermsAndConditionsInProgress(false));
  }
}

export function* hideAddressRecommendationHandler() {
  yield put(clearAddressRecommendation());
}

export function* shopSetupCompletedHandler() {
  try {
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield call(creatorsApi.shopSetupCompleted, loggedInCreator.id);
    yield put(
      setLoggedInCreator({
        ...loggedInCreator,
        onboarding: { ...loggedInCreator.onboarding, isShopSetupComplete: true },
      })
    );
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* resendWelcomeEmailHandler(action: PayloadAction<string>) {
  try {
    yield put(openProgressDialog('Sende Einladung...'));
    yield call(creatorsApi.resendWelcomeEmail, action.payload);
    yield put(closeProgressDialog());
    yield put(showSnackbar({ text: 'Die Einladung wurde erneut gesendet' }));
  } catch (unknownError: unknown) {
    yield put(closeProgressDialog());
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* deleteCreatorAccountHandler() {
  try {
    const creator: LoggedInCreator = yield select(selectLoggedInCreator);
    yield call(creatorsApi.deleteAccount, creator.id);
    yield put(
      openManagedDialog({
        title: 'Schade, dass du uns verlässt',
        component: DeleteAccountConfirmationModal.name,
      })
    );
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* getCreatorsOverviewHandler(action: PayloadAction<QueryParams>) {
  try {
    yield put(setCreatorsOverview({ loading: true }));
    const creatorsOverview: CreatorsOverviewResponse = yield call(
      creatorsApi.queryCreatorsOverview,
      action.payload
    );
    yield put(setCreatorsOverview({ ...creatorsOverview, queryParams: action.payload }));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatorsOverview({ loading: false }));
  }
}

export function* acceptMarketingConsentHandler(action: PayloadAction<boolean>) {
  try {
    yield put(closeConsentDialog());
    const loggedInCreator: LoggedInCreator = yield select(selectLoggedInCreator)!;
    yield call(creatorsApi.acceptMarketingConsent, loggedInCreator.id, action.payload);
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* fetchCreatorsListHandler() {
  try {
    yield put(setCreatorsIsLoading(true));
    const creators: ApiReturnType<typeof creatorsApi.getCreators> = yield call(
      creatorsApi.getCreators
    );
    yield put(setCreatorsList(creators));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatorsIsLoading(false));
  }
}

function createValidateAddressRequest(personalData: ProfilePersonalData): AddressValidateRequest {
  return {
    salutation: personalData.salutation!,
    firstName: personalData.firstName!,
    lastName: personalData.lastName!,
    street: personalData.street!,
    streetNumber: personalData.streetNumber!,
    zipCode: personalData.zipCode!,
    city: personalData.city!,
  };
}

function areAddressesEqual(
  addressRecommendation: AddressRecommendation,
  personalData: ProfilePersonalData
) {
  const addressKeys: (keyof Address)[] = ['street', 'streetNumber', 'zipCode', 'city'];

  const recommendationAddress: Address = _.pick(addressRecommendation, addressKeys);
  const personalDataAddress: Address = _.pick(personalData, addressKeys);

  return _.isEqual(recommendationAddress, personalDataAddress);
}

function isProfileCompleted(streamer: LoggedInCreator, updatedStreamer: LoggedInCreator) {
  return (
    streamer.onboarding?.hasCompletedProfile !== updatedStreamer.onboarding.hasCompletedProfile
  );
}

export function* watcherCreatorsSagas() {
  yield takeLatest(saveNewCreator.type, createCreatorHandler);
  yield takeLatest(createApprovedCreator.type, createApprovedCreatorHandler);
  yield takeLatest(updateCreatorDetails.type, updateCreatorHandler);
  yield takeLatest(updateCreatorShowImages.type, updateCreatorShowImagesHandler);
  yield takeLatest(fetchLoggedInCreator.type, fetchLoggedInCreatorHandler);
  yield takeLatest(fetchCreator.type, fetchCreatorHandler);
  yield takeLatest(fetchCreatorShopLinksList.type, fetchCreatorShopLinksHandler);
  yield takeLatest(saveCreatorShopLink.type, saveCreatorShopLinkHandler);
  yield takeLatest(deleteCreatorShopLink.type, deleteCreatorShopLinkHandler);
  yield takeLatest(editCreatorShopLink.type, editCreatorShopLinkHandler);
  yield takeLatest(approveCreator.type, approveCreatorHandler);
  yield takeLatest(getCreatorPerformanceOverview.type, fetchCreatorPerformanceOverviewHandler);
  yield takeLatest(navigateToCreatorDetailsPage.type, navigateToCreatorDetailsPageHandler);
  yield takeLatest(validateCreatorIban.type, validateCreatorIbanHandler);
  yield takeLatest(validateAddress.type, validateAddressHandler);
  yield takeLatest(hideAddressRecommendation.type, hideAddressRecommendationHandler);
  yield takeLatest(acceptTermsAndConditions.type, acceptTermsAndConditionsHandler);
  yield takeLatest(shopSetupCompleted.type, shopSetupCompletedHandler);
  yield takeLatest(samplesOrdered.type, sampleOrderedHandler);
  yield takeLatest(samplesReceived.type, sampleReceivedHandler);
  yield takeLatest(onboardingCompleted.type, onboardingCompletedHandler);
  yield takeLatest(amaOnboardingCompleted.type, amaOnboardingCompletedHandler);
  yield takeLatest(resendWelcomeEmail.type, resendWelcomeEmailHandler);
  yield takeLatest(deleteCreatorAccount.type, deleteCreatorAccountHandler);
  yield takeLatest(getCreatorsOverview.type, getCreatorsOverviewHandler);
  yield takeLatest(acceptMarketingConsent.type, acceptMarketingConsentHandler);
  yield takeLatest(fetchCreatorStatistics.type, fetchCreatorStatisticsHandler);
  yield takeLatest(fetchCreatorsList.type, fetchCreatorsListHandler);
}
