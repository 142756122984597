import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../common/mediaQueries';
import { addPathQuery } from '../../common/utils';
import StyledTab from '../../components/StyledTab/StyledTab';
import StyledTabs from '../../components/StyledTab/StyledTabs';
import TabPanel from '../../components/TabPanel/TabPanel';
import PageContainer from '../../layout/PageContainer/PageContainer';
import Payments from '../../payments/components/Payments/Payments';
import CreatorPostsOverview from '../../posts/components/CreatorPostsOverview/CreatorPostsOverview';
import routePaths from '../../routes/routePaths';
import theme from '../../theme/theme.module.scss';
import AmaMessages from '../components/AmaMessages/AmaMessages';
import PastStreamsOverview from '../components/PastStreamsOverview/PastStreamsOverview';

function AnalyticsPage() {
  const [value, setValue] = useState(0);
  const [showAma, setShowAma] = useState(false);

  const history = useHistory();
  const search = new URLSearchParams(history.location.search);

  const tabs = ['Streams', 'Posts', 'Rechnung'];
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    history.push(addPathQuery(routePaths.creator.analytics, { tab: tabs[newValue] }));
  };

  useEffect(() => {
    const tab = search.get('tab');
    const showId = search.get('showId');
    if (tab) {
      setValue(tabs.includes(tab) ? tabs.indexOf(tab) : 0);

      if (tab === tabs[0]) {
        if (showId) {
          setShowAma(true);
        } else {
          setShowAma(false);
        }
      }
    }
  }, [search]);

  const isMobile = isMobileBreakPoint();

  const renderTabs = () => (
    <StyledTabs variant={isMobile ? 'fullWidth' : 'standard'} value={value} onChange={handleChange}>
      {tabs.map(label => (
        <StyledTab key={label} label={label} />
      ))}
    </StyledTabs>
  );

  return (
    <>
      {isMobile && !showAma && renderTabs()}
      <PageContainer
        backgroundColor={theme.whiteLilac}
        pageToRender={
          <>
            {!isMobile && renderTabs()}
            <Box sx={{ bgcolor: isMobile ? '#fff' : '#f5f7fb' }}>
              <TabPanel value={value} index={0}>
                {!showAma ? <PastStreamsOverview /> : <AmaMessages />}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <CreatorPostsOverview />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Payments />
              </TabPanel>
            </Box>
          </>
        }
        fullHeight
      />
    </>
  );
}

export default AnalyticsPage;
