import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  DataGrid,
  deDE,
  GridColDef,
  GridFilterModel,
  GridRowIdGetter,
  GridRowParams,
  GridSortItem,
  GridToolbar,
} from '@mui/x-data-grid';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridFeatureMode } from '@mui/x-data-grid/models/gridFeatureMode';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import theme from '../../../theme/theme.module.scss';
import { TableFilter } from '../../state/creatorsSlice';

export interface DataGridTableProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  loading: boolean;
  onRowClick?: (rowParams: GridRowParams) => void;
  getRowId?: GridRowIdGetter;
  initialPageSize?: number;
  initialSorting?: GridSortItem[];
  rowsPerPageOptions?: number[];
  height?: number;
  autoHeight?: boolean;
  quickFilterPlaceholder?: string;
  mode: GridFeatureMode;
  onSortModelChange?: (model: GridSortModel) => void;
  onQuickFilterChange?: (mode: GridFilterModel) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  rowCount?: number;
  enableColumnSelector?: boolean;
  isExportEnabled?: boolean;
  sx?: SxProps<Theme>;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  initialState?: TableFilter;
  onStateChange?: (state: TableFilter) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  dataGridProps?: {
    rowHeight?: number;
    headerHeight?: number;
  };
}

const DataGridTable = ({
  rows,
  columns,
  loading,
  onRowClick = undefined,
  getRowId = undefined,
  initialPageSize = 5,
  initialSorting = undefined,
  rowsPerPageOptions = [5, 10, 20],
  height = 370,
  autoHeight = undefined,
  quickFilterPlaceholder = undefined,
  mode,
  onSortModelChange = undefined,
  onQuickFilterChange = undefined,
  onPageSizeChange = undefined,
  onPageChange = undefined,
  rowCount = undefined,
  enableColumnSelector = false,
  isExportEnabled = false,
  getRowClassName = undefined,
  sx = {},
  initialState,
  onStateChange,
  columnVisibilityModel,
  dataGridProps,
}: DataGridTableProps) => {
  // If less items than 'initialPageSize' are shown, we apply the 'autoHeight' property and render a smaller table
  const lessRowsThanPageSize = rows.length <= initialPageSize;
  const dynamicHeight = lessRowsThanPageSize ? undefined : height;

  return (
    <div style={{ height: dynamicHeight, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        onRowClick={onRowClick}
        isRowSelectable={() => false}
        getRowId={getRowId}
        initialState={
          initialState || {
            pagination: {
              pageSize: initialPageSize,
            },
            sorting: {
              sortModel: initialSorting,
            },
          }
        }
        rowsPerPageOptions={rowsPerPageOptions}
        sortingOrder={['asc', 'desc']}
        disableColumnMenu={true}
        autoHeight={autoHeight !== undefined ? autoHeight : lessRowsThanPageSize}
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        /* Toolbar with quick filter */
        componentsProps={{
          toolbar: {
            showQuickFilter:
              onQuickFilterChange !== undefined || quickFilterPlaceholder !== undefined,
            quickFilterProps: {
              debounceMs: 500,
              placeholder: quickFilterPlaceholder ?? 'Suche...',
            },
            printOptions: { disableToolbarButton: true }, // disabling print + csv will hide the export button completely
            csvOptions: isExportEnabled
              ? { utf8WithBom: true, delimiter: ';' }
              : { disableToolbarButton: true },
          },
        }}
        disableColumnFilter
        disableColumnSelector={!enableColumnSelector}
        disableDensitySelector
        /* Change handler for server-side pagination etc.*/
        sortingMode={mode}
        filterMode={mode}
        paginationMode={mode}
        rowCount={rowCount}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onQuickFilterChange}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        components={
          onQuickFilterChange || quickFilterPlaceholder ? { Toolbar: GridToolbar } : undefined
        }
        /* Styles */
        sx={{
          color: '#252525',
          background: 'white',
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '& .MuiTablePagination-displayedRows': {
            marginTop: 0,
          },
          '& .MuiDataGrid-row:hover': {
            background: theme.paleOrange,
          },
          '& .MuiDataGrid-cell': {
            padding: '0 16px',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:hover': {
            cursor: onRowClick ? 'pointer' : 'inherit',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader': {
            padding: '0 16px',
          },
          '& .MuiDataGrid-columnSeparator': {
            color: 'transparent',
          },
          '& .MuiDataGrid-footerContainer': {
            borderTopWidth: 2,
          },
          border: 'unset',
          ...sx,
        }}
        getRowClassName={getRowClassName}
        onStateChange={state =>
          onStateChange &&
          onStateChange({
            pagination: state.pagination,
            sorting: state.sorting,
          })
        }
        columnVisibilityModel={columnVisibilityModel}
        {...dataGridProps}
      />
    </div>
  );
};

export default DataGridTable;
