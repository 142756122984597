import PageContainer from '../../layout/PageContainer/PageContainer';
import Result from '../components/Result/Result';

const ProductsPage = () => (
  <>
    <PageContainer pageToRender={<Result />} />
  </>
);

export default ProductsPage;
