import { FutureDateFilters } from './DateFilterInFuture/DateFilterInFuture';
import { PastDateFilters } from './DateFilterInPast/DateFilterInPast';

export interface DateRange {
  startDate: string;
  endDate: string;
}

export interface DateRangeWithSelectedFilter extends DateRange {
  dateFilter: DateFilterType;
}

export enum DateFilterTypes {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  TOMORROW = 'tomorrow',
  MONTH = 'month',
  CUSTOM = 'custom',
  LAST_7_DAYS = 'last7Days',
  LAST_14_DAYS = 'last14Days',
  LAST_28_DAYS = 'last28Days',
  NEXT_7_DAYS = 'next7Days',
  NEXT_14_DAYS = 'next14Days',
  NEXT_28_DAYS = 'next28Days',
}

export enum DateFilterMode {
  PAST = 'PAST',
  FUTURE = 'FUTURE',
}

export type DateFilterType = PastDateFilters | FutureDateFilters;
