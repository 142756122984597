import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const isAxiosError = (data: Error): data is AxiosError =>
  (data as { isAxiosError?: boolean }).isAxiosError === true;

/**
 * An AxiosError is not serializable. As such it should not be part of an action payload, and not be stored in the redux
 * store, see https://redux.js.org/style-guide/style-guide#do-not-put-non-serializable-values-in-state-or-actions
 * This interface contains a serializable subset of the AxiosError properties and sub-properties.
 */
export interface SerializableAxiosError extends Error {
  code?: string;
  response?: Pick<AxiosResponse, 'data' | 'status'>;
  isAxiosError: true;
}

export const axiosErrorToSerializableAxiosError = (
  axiosError: AxiosError
): SerializableAxiosError => ({
  name: axiosError.name,
  message: axiosError.message,
  stack: axiosError.stack,
  code: axiosError.code,
  response: axiosError.response && {
    data: axiosError.response.data,
    status: axiosError.response.status,
  },
  isAxiosError: true,
});

const axiosClient: AxiosInstance = axios.create();

export const get = async <T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> =>
  await axiosClient.get<T>(url, config);

export const put = async <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<AxiosResponse<T>> => await axiosClient.put<T>(url, data, config);

export default axiosClient;
