import { Variant } from '../model/hit';

export const determineIfProductHasStartingPrice = (
  variant: Variant,
  values: Variant[]
): boolean => {
  return values.some(v => {
    return variant.price !== v.price;
  });
};

export const getLowestPriceVariant = (values: Variant[]): Variant => {
  return values.reduce((a, b) => {
    return a.price < b.price ? a : b;
  });
};
