import ErrorIcon from '@mui/icons-material/Error';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Link, Menu, MenuItem, Tooltip } from '@mui/material';
import { MouseEvent, useMemo, useState } from 'react';
import { ShowDetails } from '../../../model/shows';
import styles from './ShowDetailsMenu.module.scss';

type ShowDetailsMenuProps = {
  showDetails: ShowDetails;
};

const ShowDetailsMenu = ({ showDetails }: ShowDetailsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);

  const downloadShowMenuItem = useMemo(
    () =>
      showDetails.videoDownload ? (
        <MenuItem
          download={true}
          component={Link}
          href={showDetails.videoDownload.url}
          data-testid="show-details-menu-download-item"
          target={navigator.userAgent.indexOf('Firefox') > 0 ? '_blank' : undefined}
        >
          <span className={styles.menuIcon}>
            <GetAppOutlinedIcon />
          </span>
          Download
        </MenuItem>
      ) : (
        <Tooltip
          placement="right"
          title="Es kann nach dem Ende des Streams ein paar Minuten dauern, bis der Download der Show möglich ist."
        >
          <span>
            <MenuItem
              disabled={true}
              data-testid="show-details-menu-download-item"
              sx={{ '&.Mui-disabled': { backgroundColor: 'rgb(242, 242, 242)' } }}
            >
              <span className={styles.menuIcon}>
                <GetAppOutlinedIcon />
              </span>
              <span>
                Download wird vorbereitet.&nbsp;&nbsp;
                <ErrorIcon />
              </span>
            </MenuItem>
          </span>
        </Tooltip>
      ),
    [showDetails.videoDownload]
  );

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        id="show-details-card-menu-button"
        data-testid="show-details-card-menu-button"
        aria-label="download"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-controls={open ? 'show-details-card-menu' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="show-details-card-menu"
        MenuListProps={{ 'aria-labelledby': 'show-details-card-menu-button' }}
      >
        {downloadShowMenuItem}
      </Menu>
    </>
  );
};

export default ShowDetailsMenu;
