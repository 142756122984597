import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import streamersSymbol from '../../../assets/streamersSymbol.svg';
import styles from './StreamersSymbol.module.scss';

const StreamersSymbol = () => (
  <Link className={styles.symbol_wrapper} underline="hover" component={RouterLink} to={'/'}>
    <img
      className={styles.symbol}
      alt=" Streamer's Symbol"
      src={streamersSymbol}
      data-testid="streamers_symbol"
    />
  </Link>
);

export default StreamersSymbol;
