import { Button, ButtonProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import styles from './SubmitBtn.module.scss';
/**
 * This component is based on a FormikContext in the current path.
 * It renders a submit button, that will be disabled if there are
 * errors in the FormikContext.
 *
 * @param props The button label can be set via props
 */
const SubmitBtn = (props: ButtonProps) => {
  const { errors, touched, isValid } = useFormikContext();

  const [isDisabled, setDisabled] = useState(true);

  useEffect(() => {
    const touchedCount = Object.keys(touched).length;
    setDisabled(props.disabled || touchedCount === 0 || !isValid);
  }, [errors, touched]);

  return (
    <Button
      className={styles.primary_button}
      variant="contained"
      color="primary"
      type="submit"
      disabled={isDisabled}
      data-testid="submit-button"
      disableElevation
      {...props}
    />
  );
};

export default SubmitBtn;
