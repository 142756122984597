import { Button, EButtonSize, EButtonType, EInputTypes } from '@hse24/shared-components';
import { Box, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import FormHeader from '../../../components/FormHeader/FormHeader';
import RouteLeavingGuard from '../../../components/RouteLeavingGuard/RouteLeavingGuard';
import t from '../../../constants/translation';
import { selectProductSelection } from '../../../products/state/productsSlice';
import routePaths from '../../../routes/routePaths';
import useFormChanged from '../../../shows/utils/useFormChanged';
import { toBerlinTimeZone } from '../../../utils/dates';
import { StreamerPostResponse } from '../../api/postsRequestResponse';
import { AdminStreamPostData, ScheduledWhen } from '../../model/post';
import {
  cancelPostMediaUploadToS3,
  FileMetaData,
  selectSupportedSelectedFiles,
} from '../../state/postsSlice';
import { getFileExtension, getMediaType, getSupportedFileExtension } from '../../utils/MediaUtils';
import {
  adminImmediateCreatePostSchema,
  adminPlannedCreatePostSchema,
} from '../../utils/PostSchema/PostSchema';
import { UploadArea } from '../UploadArea/UploadArea';
import styles from './AdminCreateEditPostForm.module.scss';
import DataEntryForm from './DataEntryForm/DataEntryForm';
import PostMediaPreviews from './PostMediaPreviews/PostMediaPreviews';

export interface AdminPostData {
  caption?: string | null;
  baseProductsNo?: string[];
  scheduledStartAt?: string;
  salesTheme?: string;
  creatorId?: string;
  scheduleAt?: Date;
  selectedFiles: string[];
}

interface AdminCreateEditPostFormProps {
  isCreate?: boolean;
  isOwnPost?: boolean;
  postToUpdate?: StreamerPostResponse;
  submitCallback: (values: AdminStreamPostData) => void;
}

const AdminCreateEditPostForm: FC<AdminCreateEditPostFormProps> = ({
  isCreate = false,
  submitCallback,
  isOwnPost,
  postToUpdate,
}: AdminCreateEditPostFormProps) => {
  const history = useHistory();
  const selectedFiles: FileMetaData[] = useSelector(selectSupportedSelectedFiles);
  const [formValues, setFormValues] = useState<AdminPostData>({
    caption: postToUpdate?.caption || '',
    creatorId: postToUpdate?.creator?.id || '',
    salesTheme: postToUpdate?.salesTheme || '',
    scheduleAt: postToUpdate?.scheduleAt ? dayjs(postToUpdate.scheduleAt).toDate() : undefined,
    selectedFiles: selectedFiles.map(f => f.key),
  });
  const initialSelectedFiles = useMemo(() => selectedFiles.map(f => f.key), []);

  const { selectedProducts } = useSelector(selectProductSelection);

  const [nowOrLater, setNowOrLater] = useState(
    formValues.scheduleAt ? ScheduledWhen.PLANNED : ScheduledWhen.IMMEDIATELY
  );
  const isAdmin: boolean = useSelector(selectUserIsHSEEmployee);
  const dispatch = useDispatch();

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const getScheduleAt = () => formValues.scheduleAt?.toISOString() || '';

  const hasFormChanged = useFormChanged<AdminPostData>(
    {
      caption: formValues.caption,
      baseProductsNo: selectedProducts.map(p => p.baseProductNo),
      scheduledStartAt: getScheduleAt(),
      creatorId: formValues.creatorId,
      salesTheme: formValues.salesTheme,
      selectedFiles: selectedFiles.map(f => f.key),
    },
    postToUpdate
      ? {
          caption: postToUpdate.caption,
          baseProductsNo: postToUpdate.products?.map(p => p.baseProductNo) || [],
          scheduledStartAt: dayjs(postToUpdate.scheduleAt).toDate().toISOString(),
          creatorId: postToUpdate?.creator?.id || '',
          salesTheme: postToUpdate.salesTheme || '',
          selectedFiles: initialSelectedFiles,
        }
      : null
  );

  const displayButton = !(!isOwnPost && !isCreate) || isAdmin;

  const handleCancel = () => {
    history.push(routePaths.hseEmployee.postsOverview);
  };

  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const initialValues: AdminStreamPostData & { productSearch: string } = {
    scheduleAt: postToUpdate?.scheduleAt ?? '',
    caption: postToUpdate?.caption ?? '',
    baseProductsNo: [], // Will be taken from the selector in the child component
    productSearch: '',
    salesTheme: postToUpdate?.salesTheme ?? undefined,
    medias: [],
  };

  const mapFormValuesToSchedulePostData = (
    values: AdminStreamPostData & { productSearch: string }
  ): AdminStreamPostData => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { productSearch, ...rest } = values;
    rest.scheduleAt = formValues.scheduleAt
      ? toBerlinTimeZone(formValues.scheduleAt).toISOString()
      : toBerlinTimeZone(dayjs().toDate()).toISOString();
    rest.creatorId = formValues.creatorId;
    rest.salesTheme = formValues.salesTheme;
    if (selectedFiles.length === 0) {
      console.log('No files attached to the post');
    }
    rest.medias = selectedFiles.map(selectedFile => {
      return {
        mediaType: getMediaType(selectedFile.key),
        extension: getSupportedFileExtension(getFileExtension(selectedFile.key)),
        url: selectedFile.key,
      };
    });
    return rest;
  };

  const setFormValue = (field: string, value?: string | Date) => {
    setFormValues({
      ...formValues,
      [field]: value,
    });
  };

  return (
    <>
      <FormHeader title="Posts" />
      <Formik
        initialValues={initialValues}
        validationSchema={
          nowOrLater === ScheduledWhen.PLANNED
            ? adminPlannedCreatePostSchema
            : adminImmediateCreatePostSchema
        }
        onSubmit={values => {
          setIsFormSubmitted(true);
          submitCallback(mapFormValuesToSchedulePostData(values));
        }}
        initialTouched={{
          scheduleAt: false,
          caption: false,
          baseProductsNo: false,
          salesTheme: false,
        }}
        initialErrors={{ baseProductsNo: '' }}
      >
        {({ setFieldTouched, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <div className={styles.wrapper} data-testid="classic-post-form">
                <Grid className={styles.container} container gap={3}>
                  <Grid container item xs={12} md={6} gap={3}>
                    <DataEntryForm
                      formValues={formValues}
                      setFormValue={setFormValue}
                      isSubmitClicked={isSubmitClicked}
                      errors={errors}
                      touched={touched}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      nowOrLater={nowOrLater}
                      setNowOrLater={setNowOrLater}
                    />
                    {displayButton && (
                      <Grid container item xs={12} className={styles.desktop_actions}>
                        <Button
                          onClick={handleCancel}
                          type={EButtonType.BLANK}
                          inputType={EInputTypes.button}
                        >
                          <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                        </Button>
                        <Button
                          type={EButtonType.PRIMARY}
                          inputType={EInputTypes.submit}
                          onClick={() => {
                            setIsSubmitClicked(true);
                          }}
                          data-testid="submit-button"
                        >
                          {t.creators.post[isCreate ? 'Publish' : 'Edit']}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                  <Grid className={styles.media_area} container item xs={12} md={6}>
                    <Grid item xs={12}>
                      <Box className={styles.image_selection}>
                        <UploadArea isUpdate={!isCreate} />
                      </Box>
                    </Grid>
                    <Grid className={styles.media_slider} item xs={12}>
                      <PostMediaPreviews />
                    </Grid>
                  </Grid>
                  {displayButton && (
                    <Grid container item className={styles.actions} gap={2}>
                      <Button
                        type={EButtonType.BLANK}
                        onClick={handleCancel}
                        size={EButtonSize.MEDIUM}
                        inputType={EInputTypes.button}
                      >
                        <Typography fontWeight={700}> {t.common.Cancel}</Typography>
                      </Button>
                      <Button
                        type={EButtonType.PRIMARY}
                        inputType={EInputTypes.submit}
                        size={EButtonSize.MEDIUM}
                        onClick={() => {
                          setIsSubmitClicked(true);
                        }}
                        data-testid="submit-button"
                      >
                        {t.creators.post[isCreate ? 'Publish' : 'Edit']}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Form>
          );
        }}
      </Formik>
      <RouteLeavingGuard
        blockNavigation={hasFormChanged}
        navigationPermitted={isFormSubmitted}
        onAbort={() => {
          if (isCreate) dispatch(cancelPostMediaUploadToS3());
        }}
      />
    </>
  );
};

export default AdminCreateEditPostForm;
