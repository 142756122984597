import { useSelector } from 'react-redux';
import { selectSelectedProducts } from '../../../state/showsSlice';
import ShowProductSearchResultItem from '../ShowProductSearchResultItem/ShowProductSearchResultItem';
import styles from './ShowProductSearchResults.module.scss';

const ShowProductSearchResults = () => {
  const selectedProducts = useSelector(selectSelectedProducts);

  return (
    <div className={styles.display_selected_products}>
      {selectedProducts.map(product => (
        <div key={product.baseProductNo}>
          <ShowProductSearchResultItem key={product.baseProductNo} product={product} />
        </div>
      ))}
    </div>
  );
};

export default ShowProductSearchResults;
