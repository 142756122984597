import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileBreakPoint } from '../../common/mediaQueries';
import {
  hideAmaOnboardingDialog,
  selectAmaOnboardingDialog,
} from '../../notifications/state/notificationsSlice';
import theme from '../../theme/theme.module.scss';
import step1 from './../../assets/amaOnboarding/step_1.png';
import step2 from './../../assets/amaOnboarding/step_2.png';
import step3 from './../../assets/amaOnboarding/step_3.png';
import step4 from './../../assets/amaOnboarding/step_4.png';
import step5 from './../../assets/amaOnboarding/step_5.png';
import styles from './AmaOnboardingDialog.module.scss';

interface Step {
  id: number;
  image: string;
  text: string;
}

export const onboardingSteps: Step[] = [
  {
    id: 1,
    image: step1,
    text: `Wir haben gute Neuigkeiten! Um noch besser mit deinen Zuschauern zu interagieren kannst du ab jetzt direkt in deinem Livestream das neue AMA-Feature (Ask me anything) einbinden.`,
  },
  {
    id: 2,
    image: step2,
    text: `Klicke einfach während der Show auf das AMA-icon (Fragezeichen) um es für deine Zuschauer anzuzeigen.`,
  },
  {
    id: 3,
    image: step3,
    text: `So wie Du das Overlay siehst, werden es auch deine Zuschauer sehen.`,
  },
  {
    id: 4,
    image: step4,
    text: `Du möchtest es deaktivieren? Dann klicke einfach nochmal auf das Fragezeichen.`,
  },
  {
    id: 5,
    image: step5,
    text: `💡 Tipp:\n Motiviere deine Zuschauer, dir Fragen zu stellen, die sie vielleicht nicht im Chat stellen wollen oder die auf keinen Fall untergehen sollen. Sobald dein Stream vorbei ist, siehst du sämtliche Nachrichten in deiner Streamübersicht und kannst sie im nächsten Schritt direkt beantworten.`,
  },
];

function AmaOnboardingDialog() {
  const dispatch = useDispatch();
  const showDialog = useSelector(selectAmaOnboardingDialog);
  const isMobile = isMobileBreakPoint();

  const [currentStep, setCurrentStep] = useState(onboardingSteps[0]);

  const closeHandler = () => {
    return dispatch(hideAmaOnboardingDialog());
  };

  const stepDots = () => {
    return onboardingSteps.map(step => (
      <Box
        key={step.id}
        className={classNames(styles.step_dot, {
          [styles.selected]: step.id === currentStep.id,
        })}
      ></Box>
    ));
  };

  const onNextHandler = () => {
    if (currentStep.id === 5) {
      return dispatch(hideAmaOnboardingDialog());
    }
    return setCurrentStep(onboardingSteps[currentStep.id]);
  };

  const onPreviousHandler = () => {
    if (currentStep.id !== 1) {
      return setCurrentStep(onboardingSteps[currentStep.id - 2]);
    }
  };

  const currentId = currentStep.id;

  return (
    <Dialog
      open={showDialog}
      maxWidth={'lg'}
      fullScreen={isMobile}
      fullWidth={isMobile}
      scroll={'paper'}
      PaperProps={{
        className: classNames(styles.dialog_paper, {
          [styles.desktop]: !isMobile,
        }),
      }}
    >
      <Box display={'flex'} justifyContent={'flex-end'} p={2}>
        <IconButton onClick={closeHandler}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <DialogContent className={styles.dialog_body}>
        <Box className={styles.content}>
          <Box
            className={classNames(styles.image, {
              [styles.small]: currentId === 5,
            })}
          >
            <img src={currentStep.image} alt={`onboarding step ${currentId}`} />
          </Box>
          <Box>
            <Typography variant={'body1'} textAlign={'center'}>
              {currentStep.text}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={styles.dialog_actions}>
        <Box className={styles.button}>
          <Button
            variant="text"
            disableElevation
            disabled={currentId === 1}
            sx={{
              borderColor: currentId === 1 ? 'rgba(0, 0, 0, 0.26) !important' : theme.primary,
            }}
            onClick={onPreviousHandler}
          >
            Vorherige
          </Button>
        </Box>
        <Box className={styles.step_indicator}>{stepDots()}</Box>
        <Box className={styles.button}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={onNextHandler}
            endIcon={currentId === 5 ? <></> : <ArrowForwardRoundedIcon />}
          >
            {currentId === 5 ? 'Verstanden' : 'Weiter'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default AmaOnboardingDialog;
