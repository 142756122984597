import {
  EButtonSize as ETriggerSize,
  EButtonType as ETriggerType,
  Select,
  TSelectProps,
} from '@hse24/shared-components';
import { TSortItem } from '../../model/sortItem';
import { mapSortingLabel } from '../../utils/sortItemsUtils';
import SortingSelectTrigger from './SortingSelectTrigger/SortingSelectTrigger';

export type SortingSelectProps = {
  type?: ETriggerType;
  size?: ETriggerSize;
  defaultLabel?: string;
};

export const valueSelector = (_: TSortItem, index: number) => String(index);

export const labelSelector = (item: TSortItem) => mapSortingLabel(item);

export const selectedSelector = (item: TSortItem) => Boolean(item.selected);

export const getCurrentSortingLabel = (sortItems: TSortItem[] = [], text = ' ') => {
  const currentItem = sortItems.find((item: TSortItem) => item.selected);
  return currentItem ? mapSortingLabel(currentItem) : text;
};

const SortingSelect = (props: TSelectProps & SortingSelectProps) => {
  const { type, size, defaultLabel, ...rest } = props;
  return (
    <Select
      {...rest}
      label={getCurrentSortingLabel(props.options as TSortItem[], defaultLabel)}
      defaultLabel={defaultLabel}
      valueSelector={valueSelector}
      labelSelector={labelSelector}
      selectedSelector={selectedSelector}
      selectTrigger={triggerProps => (
        <SortingSelectTrigger {...triggerProps} type={type} size={size} />
      )}
    />
  );
};
SortingSelect.displayName = 'SortingSelect';

export default SortingSelect;
