import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { UpdateCreatorRequest } from '../../creators/api/requestResponse';
import { AddressRecommendation } from '../../creators/model/addressRecommendation';
import { Role } from '../role';
import {
  ConfirmEmail,
  ForgetPasswordOrigin,
  LoggedInCustomerPersonalDetailsResponse,
  LoginUserAction,
  SetPasswordRequest,
  StreamerRegistrationRequest,
} from '../types';

export interface UserLogin {
  role: Role;
  profileImageUrl?: string;
  id: string; // id of creator, agency, employee,
  displayName: string;
  firstName?: string;
  lastName?: string;
}

export enum AuthStep {
  LOGIN_PASSWORD_STEP = 'LOGIN_PASSWORD_STEP',
  FORGET_PASSWORD_STEP = 'FORGET_PASSWORD_STEP',
  RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL',
  SET_PASSWORD_STEP = 'SET_PASSWORD_STEP',
}

export enum ActivationStep {
  WELCOME_STEP = 'WELCOME_STEP',
  EXISTING_ACCOUNT_STEP = 'EXISTING_ACCOUNT_STEP',
  NEW_ACCOUNT_STEP = 'NEW_ACCOUNT_STEP',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  ADDRESS_STEP = 'ADDRESS_STEP',
}

export enum EmailExistenceStatus {
  EMAIL_EXIST = 'EMAIL_EXIST',
  EMAIL_NOT_EXISTS = 'EMAIL_NOT_EXISTS',
}

export interface EmailExistenceVerification {
  loading: boolean;
  status?: EmailExistenceStatus;
}

export interface AuthProcess {
  step: AuthStep;
  loading: boolean;
  error?: string;
  email?: string;
  emailExistenceVerification?: EmailExistenceVerification;
}

export interface ActivationData {
  id: string;
  email: string;
  isExistingAccount: boolean;
  role: Role;
}

export interface ErrorMessage {
  message: string;
  detail?: string;
}

export interface ActivationProcess {
  step: ActivationStep;
  data?: ActivationData;
  error?: ErrorMessage;
  addressRecommendation?: AddressRecommendation;
}

export interface AuthState {
  userLogin?: UserLogin | undefined;
  loading?: boolean;
  authProcess: AuthProcess;
  activationProcess: ActivationProcess;
  loggedInCustomerPersonalDetail?: LoggedInCustomerPersonalDetailsResponse;
}

export const redirectToLogin = createAction('auth/redirectToLogin');
export const logout = createAction('auth/logout');
export const userLoginState = createAction('auth/userLoginState');
export const confirmEmailExistence = createAction<ConfirmEmail>('auth/confirmEmailExistence');
export const loginUserAction = createAction<LoginUserAction>('auth/loginUser');
export const forgetPasswordAction = createAction<ForgetPasswordOrigin>('auth/forgetPassword');
export const resetPasswordAction = createAction<string>('auth/resetPassword');
export const activateUserAction = createAction<string>('auth/activateUser');
export const activateUserRedirectAction = createAction('auth/activateUserRedirect');
export const fetchPersonalDetailsAction = createAction('auth/fetchPersonnelDetailsAction');
export const checkPersonalDetailsAction = createAction<UpdateCreatorRequest>(
  'auth/checkPersonalDetailsAction'
);
export const updatePersonalDetailsAction = createAction<UpdateCreatorRequest>(
  'auth/updatePersonalDetailsAction'
);
export const createUserAccountAction = createAction<StreamerRegistrationRequest>(
  'auth/createUserAccountAction'
);
export const setPasswordAction = createAction<SetPasswordRequest>('auth/setPasswordAction');

export const redirectToAddressStepAction = createAction('auth/redirectToAddressStepAction');

export const initialState: AuthState = {
  userLogin: undefined,
  loading: true,
  authProcess: {
    step: AuthStep.LOGIN_PASSWORD_STEP,
    loading: false,
    error: undefined,
  },
  activationProcess: {
    step: ActivationStep.WELCOME_STEP,
    error: undefined,
  },
  loggedInCustomerPersonalDetail: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => ({ ...initialState, loading: false }),
    setUserLogin: (state, action: PayloadAction<UserLogin>) => {
      state.userLogin = action.payload;
      state.loading = false;
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAuthProcessLoading: (state, action: PayloadAction<boolean>) => {
      state.authProcess.loading = action.payload;
    },
    setAuthProcessError: (state, action: PayloadAction<string | undefined>) => {
      state.authProcess.error = action.payload;
    },
    setAuthProcessStep: (state, action: PayloadAction<AuthStep>) => {
      state.authProcess.step = action.payload;
    },
    setAuthProcessEmail: (state, action: PayloadAction<string | undefined>) => {
      state.authProcess.email = action.payload;
    },
    setAuthActivationProcessStep: (state, action: PayloadAction<ActivationStep>) => {
      state.activationProcess.step = action.payload;
    },
    setAuthActivationProcessData: (state, action: PayloadAction<ActivationData | undefined>) => {
      state.activationProcess.data = action.payload;
    },
    setActivationProcessError: (state, action: PayloadAction<ErrorMessage | undefined>) => {
      state.activationProcess.error = action.payload;
    },
    setActivationProcessAddressRecommendation: (
      state,
      action: PayloadAction<AddressRecommendation | undefined>
    ) => {
      state.activationProcess.addressRecommendation = action.payload;
    },
    setLoggedInCustomerPersonalDetail: (
      state,
      action: PayloadAction<LoggedInCustomerPersonalDetailsResponse>
    ) => {
      state.loggedInCustomerPersonalDetail = action.payload;
    },
    setVerifyEmailExistence: (state, action: PayloadAction<EmailExistenceVerification>) => {
      state.authProcess.emailExistenceVerification = action.payload;
    },
  },
});

export const {
  resetAuthState,
  setUserLogin,
  setAuthLoading,
  setAuthProcessLoading,
  setAuthProcessError,
  setAuthProcessStep,
  setAuthProcessEmail,
  setAuthActivationProcessStep,
  setAuthActivationProcessData,
  setActivationProcessError,
  setLoggedInCustomerPersonalDetail,
  setActivationProcessAddressRecommendation,
  setVerifyEmailExistence,
} = authSlice.actions;

export default authSlice.reducer;

export const selectUserRoles = (state: RootState) =>
  state.auth.userLogin?.role && [state.auth.userLogin.role];

export const selectUserIsAuthenticated = (state: RootState) => !!state.auth.userLogin;

export const selectAuthLoading = (state: RootState) => state.auth.loading;

export const selectEmailExistenceVerification = (state: RootState) =>
  state.auth.authProcess.emailExistenceVerification;

export const selectUserIsHelloStreamer = (state: RootState) => hasRole(Role.STREAMER_HELLO, state);
export const selectUserIsClassicStreamer = (state: RootState) =>
  hasRole(Role.STREAMER_CLASSIC, state);

export const selectUserIsCreator = (state: RootState) =>
  hasRole(Role.STREAMER_HELLO, state) ||
  hasRole(Role.INFLUENCER, state) ||
  hasRole(Role.STREAMER_CLASSIC, state);

export const selectUserIsInfluencer = (state: RootState) => hasRole(Role.INFLUENCER, state);

export const selectUserIsAgency = (state: RootState) => hasRole(Role.AGENCY, state);

export const selectUserIsHSEEmployee = (state: RootState) => hasRole(Role.HSE_EMPLOYEE, state);

export const selectIsUserLogin = (state: RootState) => !!state.auth.userLogin;

export const selectUserLogin = (state: RootState) => state.auth.userLogin;

export const selectAuthProgress = (state: RootState) => state.auth.authProcess;

export const selectAuthActivationProgressStep = (state: RootState) =>
  state.auth.activationProcess.step;

export const selectAuthActivationProgressData = (state: RootState) =>
  state.auth.activationProcess.data;

export const selectActivationProcessError = (state: RootState) =>
  state.auth.activationProcess.error;

export const selectActivationProcessAddressRecommendation = (state: RootState) =>
  state.auth.activationProcess.addressRecommendation;

export const selectLoggedInCustomerPersonalDetail = (state: RootState) =>
  state.auth.loggedInCustomerPersonalDetail;

const hasRole = (role: Role, state: RootState) => state.auth.userLogin?.role === role;
