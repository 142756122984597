import { camelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import Config from '../../config/config';
import {
  AcademyProgressResponse,
  AcademySectionResponse,
  AcademyVideoResponse,
  AcademyVideoStatusResponse,
} from '../model/academy';

const defaultBaseUrl = Config.env.socialLifeCommerceServiceBaseUrl;

const academyClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/academy`);

const fetchAcademySections = async (
  baseUrl: string = defaultBaseUrl
): Promise<AcademySectionResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    academyClient(baseUrl).get<AcademySectionResponse>(`/sections`)
  );
  return camelizeKeys(response.data) as AcademySectionResponse;
};

const fetchAcademyProgress = async (
  baseUrl: string = defaultBaseUrl
): Promise<AcademyProgressResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    academyClient(baseUrl).get<AcademyProgressResponse>(`/progress`)
  );
  return camelizeKeys(response.data) as AcademyProgressResponse;
};

const fetchAcademyVideo = async (
  id: string,
  baseUrl: string = defaultBaseUrl
): Promise<AcademyVideoResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    academyClient(baseUrl).get<AcademyVideoResponse>(`/videos/${id}`)
  );
  return camelizeKeys(response.data) as AcademyVideoResponse;
};

const saveAcademyVideoStatus = async (
  id: string,
  finished: boolean,
  baseUrl: string = defaultBaseUrl
): Promise<AcademyVideoStatusResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    academyClient(baseUrl).post<AcademyVideoStatusResponse>(`/videos/${id}`, {
      finished,
    })
  );
  return response.data;
};

const academyApi = {
  fetchAcademySections,
  academyClient,
  fetchAcademyVideo,
  fetchAcademyProgress,
  saveAcademyVideoStatus,
};

export default academyApi;
