import { Button, EButtonSize, EButtonType, LoadingSpinner } from '@hse24/shared-components';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogProps,
  IconButton,
  Typography,
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactElement, ReactNode } from 'react';
import { isMobileBreakPoint } from '../../common/mediaQueries';
import styles from './StyledDialog.module.scss';

const SlideUpTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} />;
});

interface Action {
  title: string;
  action: () => void;
  buttonProps?: ButtonProps;
  loading?: boolean;
}

interface Props {
  dialogProps: DialogProps;
  title?: string;
  header?: ReactNode;
  close?: () => void;
  body: ReactNode;
  submit?: Action;
  cancel?: Action;
  testId?: string;
  fullWidth?: boolean;
}

function StyledDialog({
  title,
  close,
  body,
  submit,
  cancel,
  dialogProps,
  header,
  testId,
  fullWidth,
}: Props) {
  const isMobile = isMobileBreakPoint();
  return (
    <Dialog
      TransitionComponent={SlideUpTransition}
      PaperProps={{ className: isMobile ? styles.dialog_paper : styles.dialog_border }}
      maxWidth={isMobile ? 'md' : 'xs'}
      fullWidth={fullWidth}
      onClose={close}
      {...dialogProps}
      data-testid={testId}
    >
      {close && (
        <IconButton className={styles.close_button} aria-label="close" onClick={close}>
          <CloseIcon />
        </IconButton>
      )}
      {header && (
        <Box
          display="grid"
          gridTemplateColumns={'1fr 2fr 1fr'}
          alignItems="center"
          justifyContent="space-between"
          pt={2}
        >
          <Box />
          {header}
        </Box>
      )}
      {title && (
        <Box
          display="flex"
          justifyContent={title || header ? 'space-between' : 'flex-end'}
          alignItems="self-start"
          className={styles.header}
        >
          <Box>
            {
              <Typography variant="h6" fontWeight="bold">
                {title}
              </Typography>
            }
          </Box>
        </Box>
      )}
      <DialogContent className={styles.content}>{body}</DialogContent>
      <DialogActions className={styles.actions}>
        {submit && (
          <Button
            className={styles.button}
            onClick={submit.action}
            disableElevation
            {...submit.buttonProps}
            size={EButtonSize.MEDIUM}
            type={EButtonType.PRIMARY}
          >
            {submit.loading ? (
              <LoadingSpinner data-testid="loading-spinner" className={styles.loading} />
            ) : (
              <span data-testid={`${testId}-submit`}> {submit.title}</span>
            )}
          </Button>
        )}
        {cancel && (
          <Button
            className={styles.button}
            onClick={cancel.action}
            disableElevation
            {...cancel.buttonProps}
            type={EButtonType.BLANK}
            size={EButtonSize.MEDIUM}
          >
            <span data-testid={`${testId}-cancel`}>{cancel.title}</span>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default StyledDialog;
