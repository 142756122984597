import { FormikErrors } from 'formik/dist/types';
import * as yup from 'yup';
import { StringSchema } from 'yup';
import t from '../../constants/translation';
import { ShopLinkType } from '../model/creator';

type FormikErrorToLabel<T> = {
  [Property in keyof FormikErrors<T>]: string;
};

// Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
/* eslint-disable @typescript-eslint/no-explicit-any */
function filterOutDuplicatedErrorValues(errors: FormikErrors<any>) {
  const errorValues = Object.values(errors);
  return errorValues.filter((error, index) => errorValues.indexOf(error) === index);
}

const mapErrorValueToString = (
  errorValue: FormikErrors<any>[] | string | string[] | FormikErrors<any> | undefined
): string => {
  if (!errorValue) {
    return '';
  } else if (Array.isArray(errorValue)) {
    return errorValue
      .map(err => (typeof err === 'object' ? filterOutDuplicatedErrorValues(err).join('; ') : err))
      .join('; ');
  } else if (typeof errorValue === 'object') {
    return filterOutDuplicatedErrorValues(errorValue).join('; ');
  } else {
    return errorValue;
  }
};

export function formatFormikErrors(
  errors: FormikErrors<any>,
  fieldNameToLabel: FormikErrorToLabel<any>
): string {
  return (
    Object.entries(errors)
      // Because of `FormikErrors<any>` (Formik is currently not supporting typed interfaces) we cannot make sure
      // if keys contained in the FormikErrors are contained in the FormikErrorToLabel...
      .filter(
        ([errorKey, errorValue]) => Boolean(fieldNameToLabel[errorKey]) && Boolean(errorValue)
      )
      .map(
        ([errorKey, errorValue]) =>
          `${fieldNameToLabel[errorKey]}: ${mapErrorValueToString(errorValue)}`
      )
      .join('\n')
  );
}

export const getUrlValidationSchemaFromShopLinkType = (
  shopLinkType: ShopLinkType
): StringSchema => {
  switch (shopLinkType) {
    case ShopLinkType.CAMPAIGN:
      return yup.string().required(t.validation['This field is mandatory']);
    case ShopLinkType.BRAND_OR_CATEGORY:
      return yup
        .string()
        .required(t.validation['This field is mandatory'])
        .matches(
          /^\/dpl\/m\/(category|brand)\/.+/,
          t.validation['Please check the formatting of the URL']
        );
  }
};

/* eslint-enable @typescript-eslint/no-explicit-any */
