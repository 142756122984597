import React from 'react';
import { authSteps } from '../../authSteps';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { AuthStep } from '../../state/authSlice';

const SetPasswordPage = () => {
  return <AuthLayout stepInfo={authSteps.get(AuthStep.SET_PASSWORD_STEP)} />;
};

export default SetPasswordPage;
