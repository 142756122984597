import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserIsHelloStreamer } from '../../../auth/state/authSlice';
import { AvailableFlag, Flag } from '../../../config/FeatureFlags';
import DeleteExpiredShowsReminder from '../../../shows/component/DeleteExpiredShowsReminder/DeleteExpiredShowsReminder';
import AcademyBanner from '../AcademyBanner/AcademyBanner';
import AmaBanner from '../AmaBanner/AmaBanner';
import StartingSoonSlider from '../StartingSoonSlider/StartingSoonSlider';
import StreamerLevel from '../StreamerLevel/StreamerLevel';
import DeletedExpiredShowsContainer from './DeletedExpiredShowsContainer/DeletedExpiredShowsContainer';
import GoodbyeMessageForHelloStreamerAlert from './GoodbyeMessageForHelloStreamerAlert/GoodbyeMessageForHelloStreamerAlert';

function DashboardContainer() {
  const isHelloStreamer = useSelector(selectUserIsHelloStreamer);
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <AmaBanner />
        </Grid>
        <Grid item xs={6}>
          <AcademyBanner />
        </Grid>
      </Grid>
      <StartingSoonSlider />
      <StreamerLevel />
      <Flag flag={AvailableFlag.enableStreamDeleting}>
        <DeletedExpiredShowsContainer />
        <DeleteExpiredShowsReminder />
      </Flag>
      <Flag flag={AvailableFlag.goodbyeMessageForHelloStreamer}>
        {isHelloStreamer ? <GoodbyeMessageForHelloStreamerAlert /> : null}
      </Flag>
    </Box>
  );
}

export default DashboardContainer;
