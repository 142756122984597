import { Breakpoint } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import styles from './ShowCard.module.scss';

interface ShowCardProps {
  children: ReactNode;
  previewImageUrl: string;
  size?: Breakpoint;
  badge?: ReactElement | null;
}

const ShowCard = ({ children, previewImageUrl, size, badge }: ShowCardProps) => {
  const lg = size === 'lg';
  return (
    <div
      className={classNames(styles.container, {
        [styles.lg]: lg,
      })}
    >
      {!!badge && <div className={classNames(styles.badge, styles.text)}>{badge}</div>}
      <div
        style={{ backgroundImage: `url(${previewImageUrl})` }}
        className={styles.poster}
        data-testid="poster"
      />
      <div className={styles.poster_overlay} />
      {children}
    </div>
  );
};

export default ShowCard;
