import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../hooks/useQuery';
import routePaths from '../../../../routes/routePaths';
import { setPasswordAction } from '../../../state/authSlice';
import AuthForm, { AuthFormValues } from '../../AuthForm/AuthForm';

function SetPasswordStep() {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();

  const token = query.get('token');
  if (!token) history.push(routePaths.landing);

  const handleFormSubmit = (values?: AuthFormValues) => {
    const password = values?.password;
    if (token && password)
      dispatch(
        setPasswordAction({
          token,
          password,
        })
      );
  };

  return (
    <AuthForm
      header={
        <>
          <Typography variant={'h3'}>Passwort erstellen</Typography>
          <Typography variant={'body1'}>
            Noch ein Schritt und dein Konto steht dir zur Verfügung
          </Typography>
        </>
      }
      submitButton={{ title: 'Passwort erstellen' }}
      submitCallback={handleFormSubmit}
      password=""
      passwordLabel="Passwort erstellen"
      passwordStrengthBar
    />
  );
}

export default SetPasswordStep;
