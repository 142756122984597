import { Button, EButtonType, LoadingSpinner } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../constants/translation';
import { openConfirmationDialog } from '../../../notifications/state/notificationsSlice';
import { ProductTileInfo } from '../../model/productTileInfo';
import {
  addToProductsSelectionSelectedProducts,
  clearProductsSelectionResponse,
  fetchOrdersProductsSelectionSearch,
  selectProductSelection,
} from '../../state/productsSlice';
import { mapProductSwatchToProductTitleInfo } from '../../utils/productTileInfoMapper';
import ProductSelectionDialogHeader from './ProductSelectionDialogHeader/ProductSelectionDialogHeader';
import styles from './ProductsSelection.module.scss';
import ProductsSelectionDialog from './ProductsSelectionDialog';
import ProductWithSelector from './ProductWithSelector';

interface Props {
  handleClose: () => void;
  isAdmin?: boolean;
}

function ProductsSelection({ handleClose, isAdmin }: Props) {
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');
  const [selectedProducts, setSelectedProducts] = useState<ProductTileInfo[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const {
    productsResponse,
    loading,
    totalOrders,
    selectedProducts: prevSelectedProducts,
  } = useSelector(selectProductSelection);

  useEffect(() => {
    !isAdmin && dispatch(fetchOrdersProductsSelectionSearch());
  }, []);

  const handleDiscard = () => {
    setQuery('');
    if (selectedProducts.length) {
      dispatch(
        openConfirmationDialog({
          title: 'Möchtest du deine aktuelle Auswahl wirklich verwerfen?',
          contentText: 'Deine ausgewählten Produkte werden nicht gespeichert.',
          submit: 'Verwerfen',
          actionToTrigger: handleClose,
        })
      );
    } else {
      handleClose();
    }
  };

  const handleOnDone = () => {
    setQuery('');

    const productsToAdd = selectedProducts.filter(
      selectedProduct =>
        !prevSelectedProducts.some(
          prevSelectedProduct => prevSelectedProduct.baseProductNo === selectedProduct.baseProductNo
        )
    );

    dispatch(addToProductsSelectionSelectedProducts(productsToAdd));
    dispatch(clearProductsSelectionResponse());
    handleClose();
  };

  const handleRemoveProduct = (product: ProductTileInfo) => {
    const newListedProducts = selectedProducts.filter(
      sProduct => !sProduct.baseProductNo.startsWith(product.baseProductNo)
    );
    setSelectedProducts(newListedProducts);
  };

  const handleAddProduct = (product: ProductTileInfo) => {
    if (product.productSwatch?.length === 1) {
      const onlyVariant = product.productSwatch[0];
      const selectedVariant = mapProductSwatchToProductTitleInfo(onlyVariant, product);
      setSelectedProducts([selectedVariant].concat(selectedProducts));
    } else {
      setSelectedProducts([product].concat(selectedProducts));
    }
  };

  return (
    <ProductsSelectionDialog
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
      setIsScrolling={setIsScrolling}
    >
      <ProductSelectionDialogHeader
        onClose={handleDiscard}
        onDone={handleOnDone}
        onRemoveProduct={handleRemoveProduct}
        selectedProducts={selectedProducts}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isScrolling={isScrolling}
        query={query}
        setQuery={setQuery}
      />
      <Box className={styles.content}>
        <Box className={styles.products}>
          {!loading ? (
            <Box>
              {query ? (
                <Typography variant={'body1'}>
                  {productsResponse.length} {t.common['Products found']}
                </Typography>
              ) : (
                <Typography variant={'body1'}>
                  {t.common['Your latest orders']} ({totalOrders})
                </Typography>
              )}
              {productsResponse.map(product => (
                <ProductWithSelector
                  key={product.baseProductNo}
                  product={product}
                  selectedProducts={selectedProducts}
                  handleSelect={handleAddProduct}
                  handleRemove={handleRemoveProduct}
                />
              ))}
            </Box>
          ) : (
            <Box
              data-testid={'loading'}
              p={5}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <LoadingSpinner />
            </Box>
          )}
        </Box>
        <div className={styles.actions}>
          <Button onClick={handleDiscard} type={EButtonType.BLANK} className={styles.cancel}>
            <Typography fontWeight={700}> {t.common.Cancel}</Typography>
          </Button>
          <Button onClick={handleOnDone} type={EButtonType.PRIMARY} className={styles.confirm}>
            <Typography fontWeight={700}> {t.common['Add selected products']}</Typography>
          </Button>
        </div>
      </Box>
    </ProductsSelectionDialog>
  );
}

export default ProductsSelection;
