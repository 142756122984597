export enum LocalStorageKeys {
  PathBeforeRedirect = 'PathBeforeRedirect',
  IdToken = 'IdToken',
  RefreshToken = 'RefreshToken',
  ExpirationTime = 'ExpirationTime',
  ExperimentalTrackingFeatureEnabled = 'experimentalTrackingFeatureEnabled',
  InApp = 'inApp',
  AppBuildInfo = 'appBuildInfo',
  AmasEnabled = 'amasEnabled',
  streamEnded = 'streamEnded',
  UcUserInteraction = 'uc_user_interaction',
  dismissedExpiredStreams = 'dismissedExpiredStreams',
  displayHeaderField = 'displayHeaderField',
  upcomingShowsOverviewNewDesign = 'upcomingShowsOverviewNewDesign',
}
