import {
  AndroidOutlined,
  Apple,
  PhoneIphoneOutlined,
  TabletAndroidOutlined,
} from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import { capitalize } from 'lodash';
import React from 'react';
import t from '../../../constants/translation';
import { DeviceType, Surveys } from '../../types/types';
import style from './PlatformBadge.module.scss';
const getDeviceIconsFromDeviceType = (deviceType: DeviceType) => {
  switch (deviceType) {
    case DeviceType.Android_phone:
      return (
        <Box>
          <Tooltip title={t.admins.survey.Android}>
            <AndroidOutlined className={style.icon} data-testid="android"></AndroidOutlined>
          </Tooltip>
          <Tooltip title={t.admins.survey.Phone}>
            <PhoneIphoneOutlined className={style.icon} data-testid="phone"></PhoneIphoneOutlined>
          </Tooltip>
        </Box>
      );
    case DeviceType.iOS_phone:
      return (
        <Box>
          <Tooltip title={t.admins.survey.IOS}>
            <Apple className={style.icon} data-testid="ios"></Apple>
          </Tooltip>
          <Tooltip title={t.admins.survey.Phone}>
            <PhoneIphoneOutlined className={style.icon} data-testid="phone"></PhoneIphoneOutlined>
          </Tooltip>
        </Box>
      );
    case DeviceType.Android_tablet:
      return (
        <Box>
          <Tooltip title={t.admins.survey.Android}>
            <AndroidOutlined className={style.icon} data-testid="android"></AndroidOutlined>
          </Tooltip>
          <Tooltip title={t.admins.survey.Tablet}>
            <TabletAndroidOutlined
              className={style.icon}
              data-testid="tablet"
            ></TabletAndroidOutlined>
          </Tooltip>
        </Box>
      );
    case DeviceType.iOS_tablet:
      return (
        <Box>
          <Tooltip title={t.admins.survey.IOS}>
            <Apple className={style.icon} data-testid="ios"></Apple>
          </Tooltip>
          <Tooltip title={t.admins.survey.Tablet}>
            <TabletAndroidOutlined
              className={style.icon}
              data-testid="tablet"
            ></TabletAndroidOutlined>
          </Tooltip>
        </Box>
      );
  }
};
interface Props {
  survey: Surveys;
}
const PlatformBadge = ({ survey }: Props) => {
  return survey.platform == undefined ? (
    <Typography> - </Typography>
  ) : survey.deviceType != undefined ? (
    getDeviceIconsFromDeviceType(survey.deviceType)
  ) : (
    <Typography>{capitalize(survey.platform)} </Typography>
  );
};
export default PlatformBadge;
