import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Alert, AlertTitle, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { useLocation } from 'react-router-dom';
import t from '../../../../constants/translation';
import { ArchivedShow } from '../../../../shows/api/showsRequestResponse';
import { EUROPE_BERLIN_TIMEZONE } from '../../../../shows/constants';
import { hiddenFooterPath } from '../../../../utils/routeConfiguration';
import style from './DeletedExpiredShowAlert.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DeletedExpiredShowHintProps {
  archivedShow: ArchivedShow;
  onClose: VoidFunction;
}

const HIDE_NAVBAR_URL_PARAM = 'hideNavbar';

const DeletedExpiredShowAlert = ({ archivedShow, onClose }: DeletedExpiredShowHintProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isNavbarHidden = queryParams.has(HIDE_NAVBAR_URL_PARAM);
  const isFooterHidden = hiddenFooterPath(location.pathname);
  return (
    <Box
      className={classNames({
        [style.alert_wrapper]: true,
        [style.extra_margin]: !isNavbarHidden && !isFooterHidden && isMobile,
      })}
    >
      <Alert
        severity="info"
        onClose={onClose}
        className={classNames({
          [style.alert]: true,
        })}
        icon={<InfoOutlinedIcon className={style.icon} />}
      >
        <AlertTitle className={style.title}>
          {t.creators.notifications['We have deleted your missed stream after 48 hours:']}
        </AlertTitle>
        <Typography className={style.content}>
          {'DO : ' +
            dayjs(archivedShow.scheduledStartAt)
              .tz(EUROPE_BERLIN_TIMEZONE)
              .format('DD. MMM, HH:mm')}
        </Typography>
        <Typography className={style.content}>{archivedShow.title}</Typography>
      </Alert>
    </Box>
  );
};

export default DeletedExpiredShowAlert;
