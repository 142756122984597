import { Alert, AlertTitle, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { hiddenFooterPath } from '../../../../utils/routeConfiguration';
import style from './GoodbyeMessageForHelloStreamerAlert.module.scss';

const HIDE_NAVBAR_URL_PARAM = 'hideNavbar';

const GoodbyeMessageForHelloStreamerAlert = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isNavbarHidden = queryParams.has(HIDE_NAVBAR_URL_PARAM);
  const isFooterHidden = hiddenFooterPath(location.pathname);

  return (
    <Box
      className={classNames({
        [style.alert_wrapper]: true,
        [style.extra_margin]: !isNavbarHidden && !isFooterHidden && isMobile,
        [style.desktop]: !isMobile,
      })}
    >
      <Alert
        className={classNames({
          [style.alert]: true,
        })}
        icon={false}
      >
        <AlertTitle className={style.title}>Goodbye 👋</AlertTitle>
        <Typography className={style.content}>
          Am 20.03. sagen wir Goodbye zu HELLO. Wir danken dir für die schöne Zeit 💜 Bis Montag
          kannst du noch einen Stream planen. Bitte checke deine E-Mails für weitere Informationen.
        </Typography>
      </Alert>
    </Box>
  );
};

export default GoodbyeMessageForHelloStreamerAlert;
