import { Grid, InputAdornment, MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import FormikSelect from '../../../../components/FormikSelect/FormikSelect';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import { SocialMediaPlatform } from '../../../model/socialMediaPlatform';

type SocialMediaAccountSelectionProps = {
  fieldsRequired?: boolean;
};

const resolveAdornment = (socialMediaPlatform: SocialMediaPlatform | string) => {
  switch (socialMediaPlatform) {
    case SocialMediaPlatform.YOUTUBE:
      return 'youtube.com/';
    default:
      return '@';
  }
};

const SocialMediaAccountSelection = ({
  fieldsRequired = false,
}: SocialMediaAccountSelectionProps) => {
  const [adornment, setAdornment] = useState('@');

  const startAdornments = {
    InputProps: {
      startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
    },
  };

  const onChangeCallback = (e: SelectChangeEvent) => {
    setAdornment(resolveAdornment(e.target.value));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <FormikSelect
          fieldName="socialMediaPlatform"
          label={fieldsRequired ? 'Plattform*' : 'Plattform'}
          value=""
          onChange={onChangeCallback}
          menuItems={[
            <MenuItem key={SocialMediaPlatform.FACEBOOK} value={SocialMediaPlatform.FACEBOOK}>
              {'Facebook'}
            </MenuItem>,
            <MenuItem key={SocialMediaPlatform.INSTAGRAM} value={SocialMediaPlatform.INSTAGRAM}>
              {'Instagram'}
            </MenuItem>,
            <MenuItem key={SocialMediaPlatform.PINTEREST} value={SocialMediaPlatform.PINTEREST}>
              {'Pinterest'}
            </MenuItem>,
            <MenuItem key={SocialMediaPlatform.SNAPCHAT} value={SocialMediaPlatform.SNAPCHAT}>
              {'Snapchat'}
            </MenuItem>,
            <MenuItem key={SocialMediaPlatform.TIKTOK} value={SocialMediaPlatform.TIKTOK}>
              {'TikTok'}
            </MenuItem>,
            <MenuItem key={SocialMediaPlatform.YOUTUBE} value={SocialMediaPlatform.YOUTUBE}>
              {'Youtube'}
            </MenuItem>,
          ]}
        />
      </Grid>
      <Grid item xs={7}>
        <FormikTextField
          fieldName="socialMediaUsername"
          label={fieldsRequired ? 'Username*' : 'Username'}
          value=""
          muiProps={startAdornments}
        />
      </Grid>
    </Grid>
  );
};

export default SocialMediaAccountSelection;
