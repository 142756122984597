import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { AmaCreateRequest, AmaUpdateRequest } from '../api/amasRequestResponse';
import { Ama } from '../model/ama';

export interface AmasState {
  loading: boolean;
  amas?: Ama[];
  ama?: Ama;
}

export const amasInitialState: AmasState = {
  loading: false,
  amas: undefined,
  ama: undefined,
};

export const amasSlice = createSlice({
  name: 'amas',
  initialState: amasInitialState,
  reducers: {
    setAmaLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAmas: (state, action: PayloadAction<Ama[] | undefined>) => {
      state.amas = action.payload;
    },
    setAma: (state, action: PayloadAction<Ama>) => {
      state.ama = action.payload;
    },
  },
});

export const { setAmaLoading, setAmas, setAma } = amasSlice.actions;

export const createAmaAction = createAction<AmaCreateRequest>('amas/createAmaAction');
export const updateAmaByIdAction = createAction<{ id: string; request: AmaUpdateRequest }>(
  'amas/updateAmaByIdAction'
);
export const fetchAmaByIdAction = createAction<string>('amas/fetchAmaByIdAction');
export const deleteAmaAction = createAction<string>('amas/deleteAmaAction');
export const fetchAmasByShowIdAction = createAction<string>('amas/fetchAmasByShowIdAction');

export const selectAmaLoading = (state: RootState) => state.amas.loading;
export const selectAmas = (state: RootState) => state.amas.amas;
export const selectAma = (state: RootState) => state.amas.ama;

export default amasSlice.reducer;
