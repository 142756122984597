import { ArrowForward } from '@mui/icons-material';
import StarOutlinedIcon from '@mui/icons-material/StarOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import t from '../../../constants/translation';
import { trackStreamerAcademy } from '../../../middlewares/reduxBeaconMiddleware/actions';
import routePaths from '../../../routes/routePaths';
import styles from './AcademyBanner.module.scss';

function AcademyBanner() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = isMobileBreakPoint();

  function goToAcademy() {
    dispatch(trackStreamerAcademy());
    history.push(routePaths.creator.academy.overview);
  }

  return (
    <div className={styles.container} onClick={goToAcademy}>
      <Box className={styles.circle}>
        <StarOutlinedIcon />
      </Box>
      <Box>
        <Typography variant={'h1'} className={styles.headline}>
          {t.creators['Streamer Academy']}
        </Typography>
        <Typography className={styles.subline}>
          {!isMobile && (
            <>
              <span>{t.creators['Learn the best streamer techniques and tricks.']}</span>
              <br />
              <span>{t.creators['Shopping Streams 🚀']}</span>
              &nbsp;
            </>
          )}
          <span style={{ fontWeight: 'normal' }}>{t.creators['Become a pro!']}</span>
        </Typography>
      </Box>
      {!isMobile && (
        <Box>
          <Button className={styles.button} disableElevation>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              <Box>{t.creators['Watch now']}</Box>
              <Box>
                <ArrowForward />
              </Box>
            </Box>
          </Button>
        </Box>
      )}
    </div>
  );
}

export default AcademyBanner;
