import { type ChatRoom, type ChatRoomListenerMap } from 'amazon-ivs-chat-messaging';
import { eventChannel } from 'redux-saga';

// eslint-disable-next-line
const mapPayload = (payload: any) =>
  typeof payload === 'undefined' ? {} : typeof payload === 'object' ? { ...payload } : payload;

const createChatChannel = <EventName extends keyof ChatRoomListenerMap>(
  chatRoom: ChatRoom,
  chatEvent: EventName,
  chatRoomKey?: keyof ChatRoom
) => {
  return eventChannel(emit => {
    // eslint-disable-next-line
    const listener: ChatRoomListenerMap[EventName] = (payload?: any) =>
      chatRoom !== undefined && chatRoomKey !== undefined
        ? emit(mapPayload(chatRoom[chatRoomKey]))
        : emit(mapPayload(payload));

    chatRoom.addListener(chatEvent, listener);
    return () => {
      chatRoom.removeListener(chatEvent, listener);
    };
  });
};

export default createChatChannel;
