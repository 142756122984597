import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../constants/translation';
import { germanFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import {
  fetchStreamerPostsStats,
  PostsStatsData,
  selectStreamerPostsStats,
} from '../../state/postsSlice';
import OverallPerformanceTile from './OverallPerformanceTile/OverallPerformanceTile';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gridAutoRows: '1fr',
    gridGap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(2, calc(50% - ${theme.spacing(1)}))`,
    },
  },
}));

export interface StatTile {
  title: string;
  value: string | number | undefined;
  icon: ReactNode;
  tooltip?: string;
}

const StreamerOverallPerformance = () => {
  const dispatch = useDispatch();
  const streamerPostsStats = useSelector(selectStreamerPostsStats);

  useEffect(() => {
    dispatch(fetchStreamerPostsStats());
  }, [dispatch]);

  const data = streamerPostsStats.data as PostsStatsData | undefined;
  const stats: StatTile[] = [
    {
      title: t.creators.post['Gained followers'],
      value: `+${data?.totalFollowers}`,
      icon: <GroupAddOutlinedIcon />,
    },
    {
      title: t.creators.post['Overall Revenue'],
      value: data?.overallRevenue != undefined ? germanFormat(data?.overallRevenue) : undefined,
      icon: <EuroOutlinedIcon />,
      tooltip: `Auf deinen durch Posts erzielten<br/>Nettogesamtumsatz erhältst du eine<br/>Provision in Höhe von 2%.`,
    },
    {
      title: t.creators.post['All likes'],
      value: data?.totalLikes,
      icon: <FavoriteBorderOutlinedIcon />,
    },
    {
      title: t.creators.post['All Comments'],
      value: data?.totalComments,
      icon: <CommentOutlinedIcon />,
    },
  ];

  const classes = useStyles();

  return (
    <Box className={classes.gridContainer}>
      {stats.map(tile => (
        <OverallPerformanceTile key={tile.title} tile={tile} />
      ))}
    </Box>
  );
};

export default StreamerOverallPerformance;
