import { ArrowIconLeft, Text } from '@hse24/shared-components';
import { Box, Toolbar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import styles from './FormHeader.module.scss';

interface FormHeaderProps {
  title: string;
}

function FormHeader({ title }: FormHeaderProps) {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <>
      <Box className={styles.app_bar}>
        <Toolbar className={styles.tool_bar}>
          <Box className={styles.header}>
            <Text.Body className={styles.text}>{title}</Text.Body>
          </Box>
        </Toolbar>
      </Box>
      <Box className={styles.exit} onClick={handleGoBack} data-testid="back-button">
        <ArrowIconLeft width="16px" height="16px" />
        <Text.Body className={styles.text}>Zurück zur Übersicht</Text.Body>
      </Box>
    </>
  );
}

export default FormHeader;
