import { ArrowBackIos } from '@mui/icons-material';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  IconButtonProps,
  Paper,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import { secondsIntoMinutes } from '../../../common/utils';
import MobileAppBar from '../../../components/MobileAppBar/MobileAppBar';
import VideoStatusBadge from '../../../components/VideoStatusBadge/VideoStatusBadge';
import routePaths from '../../../routes/routePaths';
import { SectionVideo } from '../../model/academy';
import { fetchAcademyVideo, selectAcademyVideo } from '../../state/academySlice';
import AcademyVideoPlayer from '../AcademyVideoPlayer/AcademyVideoPlayer';
import EpisodeCard from '../EpisodeCard/EpisodeCard';
import VideoCard from '../VideoCard/VideoCard';
import styles from './StreamerAcademyVideo.module.scss';
import StreamerAcademyVideoSkeleton from './StreamerAcademyVideoSkeleton';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function StreamerAcademyVideo() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isMobile = isMobileBreakPoint();

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [seeMoreExpended, setSeeMoreExpended] = useState(false);

  const { loading, data } = useSelector(selectAcademyVideo);
  const { videoId } = useParams<{ videoId: string }>();

  const goBack = () => {
    history.push(routePaths.creator.academy.overview);
  };

  const goToNextEpisode = () => {
    const nextVideoId = data?.nextEpisodes[0].id;
    history.push(routePaths.creator.academy.overview + `/${nextVideoId}`);
  };

  useEffect(() => {
    dispatch(fetchAcademyVideo(videoId));
  }, [videoId]);

  const onClose = () => {
    setDialogOpen(false);
    setSeeMoreExpended(false);
  };

  const onOpen = () => {
    setDialogOpen(true);
    setSeeMoreExpended(true);
  };

  const hasNextEpisodes = !!data?.nextEpisodes.length;
  const nextEpisodesDisplay = data
    ? seeMoreExpended
      ? data.nextEpisodes
      : data.nextEpisodes.slice(0, 2)
    : [];

  const renderDialog = () => (
    <Dialog fullScreen open={dialogOpen && isMobile} onClose={onClose}>
      <Box display={'flex'} className={styles.header}>
        <Box>
          <IconButton onClick={onClose}>
            <ArrowBackIos />
          </IconButton>
        </Box>
        <Box>
          <Typography variant={'h6'} fontWeight={'bold'}>
            Weitere Folgen
          </Typography>
        </Box>
        <Box />
      </Box>
      <DialogContent data-testid={'episodes'} className={styles.dialog_content}>
        <Box display={'flex'} flexDirection={'column'} gap={1}>
          {data?.nextEpisodes.map(video => (
            <Box key={video.id} className={styles.paper} mb={1} p={1}>
              <VideoCard video={video} onVideoClick={onClose} />
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );

  return (
    <>
      {renderDialog()}
      {isMobile && <MobileAppBar title={data?.title} landing={goBack} />}
      <Box mt={'3'} display={'flex'} flexDirection={'column'} gap={2}>
        {loading ? (
          <>
            <StreamerAcademyVideoSkeleton />
          </>
        ) : data ? (
          <>
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <AcademyVideoPlayer
                url={data.url}
                previewImageUrl={data.previewImageUrl}
                id={videoId}
              />
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box>
                  <Typography variant={'body1'}>{data.title}</Typography>
                  <Typography variant={'caption'}>
                    {secondsIntoMinutes(data.durationInSeconds)} min watch
                  </Typography>
                </Box>
                <Box>
                  <VideoStatusBadge status={data.status} />
                </Box>
              </Box>
            </Box>
            {hasNextEpisodes && (
              <Box display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={goToNextEpisode} disableElevation variant={'contained'}>
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    <Typography variant={'body1'}>Nächste Folge</Typography>
                    <ArrowForwardRoundedIcon />
                  </Box>
                </Button>
              </Box>
            )}
            {hasNextEpisodes && (
              <Box>
                <Paper elevation={0}>
                  <Box p={2} display={'flex'} flexDirection={'column'}>
                    <Box
                      mb={2}
                      display={'flex'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                    >
                      <Typography component={'h3'} fontWeight={'bold'}>
                        Weitere Folgen
                      </Typography>
                      {data.nextEpisodes.length > 2 && isMobile && (
                        <Button onClick={onOpen} disableElevation>
                          Alle anzeigen
                        </Button>
                      )}
                    </Box>
                    <Grid container spacing={{ xs: 1, lg: 2 }}>
                      {nextEpisodesDisplay?.map((video: SectionVideo) => (
                        <EpisodeCard key={video.id} video={video} />
                      ))}
                    </Grid>
                  </Box>
                  {data.nextEpisodes.length > 2 && !isMobile && (
                    <Box
                      onClick={() => {
                        if (seeMoreExpended) {
                          onClose();
                        } else {
                          onOpen();
                        }
                      }}
                      className={styles.expend_more}
                    >
                      <Typography variant={'body1'}>
                        Alle anzeigen
                        <ExpandMore expand={seeMoreExpended}>
                          <ArrowDownwardRoundedIcon color={'inherit'} />
                        </ExpandMore>
                      </Typography>
                    </Box>
                  )}
                </Paper>
              </Box>
            )}
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

export default StreamerAcademyVideo;
