import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SubmitButton from '../../../../components/Button/SubmitButton';
import ClientSideDataGrid from '../../../../creators/components/DataGridTable/ClientSideDataGrid';
import routePaths from '../../../../routes/routePaths';
import { fetchAmaByIdAction, selectAma, selectAmaLoading } from '../../state/amasSlice';
import DeleteAmaButton from '../DeleteAmaButton/DeleteAmaButton';
import styles from './AmaDisplay.module.scss';

interface AmaResponsesProps {
  id: string;
  showId: string;
}

const AmaDisplay = ({ id, showId }: AmaResponsesProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ama = useSelector(selectAma);
  const loading: boolean = useSelector(selectAmaLoading);

  useEffect(() => {
    dispatch(fetchAmaByIdAction(id));
  }, []);

  const handleUpdateClick = () => {
    history.push(
      routePaths.creator.onDemandInteractions.ama.update
        .replace(':showId', showId)
        .replace(':amaId', id)
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: 'User',
    },
    {
      field: 'response',
      headerName: 'Response',
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <div>
      <div className={styles.wrapper}>
        <h1 className={styles.header}>
          <IconButton color={'secondary'} onClick={() => history.push(routePaths.creator.shows)}>
            <ChevronLeft />
          </IconButton>
          {ama?.request}
        </h1>
        <div className={styles.buttons_wrapper}>
          <SubmitButton
            variant="contained"
            color="primary"
            data-testid="update-button"
            onClick={handleUpdateClick}
          >
            Update
          </SubmitButton>
          <DeleteAmaButton id={id} />
        </div>
      </div>
      <ClientSideDataGrid
        loading={loading}
        columns={columns}
        rows={ama?.responses ?? []}
        quickFilterPlaceholder={'Suchen...'}
      />
    </div>
  );
};

export default AmaDisplay;
