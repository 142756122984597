import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../../constants/translation';
import { useExecuteReCaptcha } from '../../../../hooks/useExecuteReCaptcha';
import {
  forgetPasswordAction,
  loginUserAction,
  selectAuthProgress,
} from '../../../state/authSlice';
import { ForgetPasswordOrigin } from '../../../types';
import AuthForm, { AuthFormValues } from '../../AuthForm/AuthForm';

function LoginPasswordStep() {
  const dispatch = useDispatch();
  const { email } = useSelector(selectAuthProgress);

  const executeRecaptcha = useExecuteReCaptcha();

  const handleSubmit = (values?: AuthFormValues) => {
    if (values && values.email && values.password) {
      const { email, password, stayLoggedIn } = values;
      return dispatch(
        loginUserAction({
          credentials: { email, password, stayLoggedIn: !!stayLoggedIn },
          executeRecaptcha,
        })
      );
    }
  };

  const handleResetPassword = () => dispatch(forgetPasswordAction(ForgetPasswordOrigin.LOGIN));

  return (
    <AuthForm
      header={
        <>
          <Typography variant={'h3'}>{t.creators['Welcome 👋']}</Typography>
          <Typography variant={'body1'}>
            {t.validation['Easy, just enter your login data']}
          </Typography>
        </>
      }
      submitButton={{ title: 'Einloggen' }}
      submitCallback={handleSubmit}
      resetPasswordCallback={handleResetPassword}
      email={{ value: email ?? '' }}
      password={''}
      stayLoggedIn={false}
    />
  );
}

export default LoginPasswordStep;
