import CloseIcon from '@mui/icons-material/Close';
import { Box, DialogContent, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { hideCreateContentDialog } from '../../../notifications/state/notificationsSlice';
import CreateContentCards from '../CreateContentCards/CreateContentCards';
import CreateContentUpcomingShows from '../CreateContentUpcomingShows/CreateContentUpcomingShows';
import styles from './MobileCreateContent.module.scss';

const SlideUpTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props.children}
    </Slide>
  );
});

function MobileCreateContent() {
  const dispatch = useDispatch();
  const handleOnClose = () => {
    dispatch(hideCreateContentDialog());
  };

  return (
    <Dialog
      open={true}
      TransitionComponent={SlideUpTransition}
      onClose={handleOnClose}
      maxWidth={'md'}
      PaperProps={{ className: styles.dialog_paper }}
      keepMounted
      fullWidth
      data-testid="create-content-mobile"
    >
      <Box display={'flex'} justifyContent={'flex-end'} m={2.5}>
        <IconButton onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent data-testid="create-content-mobile-dialog">
        <CreateContentUpcomingShows />
        <CreateContentCards />
      </DialogContent>
    </Dialog>
  );
}

export default MobileCreateContent;
