import { Button } from '@hse24/shared-components';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AudienceBadge from '../../../../components/AudienceBadge/AudienceBadge';
import ResponsiveDetailsRow, {
  DetailsRowProps,
} from '../../../../components/DetailsRow/ResponsiveDetailsRow';
import t from '../../../../constants/translation';
import { CreatorDetailsOverview, SocialMediaAccountsWithUsername } from '../../../model/creator';
import {
  fetchCreator,
  resendWelcomeEmail,
  selectSelectedCreator,
} from '../../../state/creatorsSlice';
import styles from './CreatorDetailsProfile.module.scss';

const CreatorDetailsProfile = () => {
  const { streamerId } = useParams<{ streamerId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCreator({ creatorId: streamerId }));
  }, []);

  const creator = useSelector(selectSelectedCreator);
  if (!creator)
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );

  const detailsRows: DetailsRowProps[] = generateRowDataFrom(creator);
  const streamerHasAcceptedTermsAndConditions = creator.onboarding?.hasAcceptedTermsAndConditions;

  return (
    <>
      <div className={styles.caption}>{creator.name}</div>
      <Grid container>
        {detailsRows.map((rowData, index) => (
          <ResponsiveDetailsRow
            key={rowData.label}
            withCopyIcon
            last={index === detailsRows.length - 1}
            {...rowData}
          />
        ))}
      </Grid>
      {!streamerHasAcceptedTermsAndConditions && (
        <Button
          className={styles.resendWelcomeMailBtn}
          onClick={() => dispatch(resendWelcomeEmail(creator.id))}
        >
          <Typography fontWeight={700}> Einladung erneut senden</Typography>
        </Button>
      )}
    </>
  );
};

function generateRowDataFrom(creator: CreatorDetailsOverview): DetailsRowProps[] {
  const rows: DetailsRowProps[] = [
    { label: 'E-Mail', value: creator.email, marketingConsent: creator.marketingConsent },
    {
      label: 'Audience',
      component: <AudienceBadge audience={creator.audience} />,
    },
  ];
  if (creator.agencyName) {
    rows.push({ label: t.agencies.Agency, value: creator.agencyName });
  }
  addSocialMediaAccounts(rows, creator.socialMediaAccounts);
  return rows;
}

function addSocialMediaAccounts(
  detailsRows: DetailsRowProps[],
  socialMediaAccounts?: SocialMediaAccountsWithUsername
) {
  addSocialMediaAccount(detailsRows, socialMediaAccounts?.instagram, 'Instagram');
  addSocialMediaAccount(detailsRows, socialMediaAccounts?.tiktok, 'TikTok');
  addSocialMediaAccount(detailsRows, socialMediaAccounts?.youtube, 'YouTube');
  addSocialMediaAccount(detailsRows, socialMediaAccounts?.facebook, 'Facebook');
  addSocialMediaAccount(detailsRows, socialMediaAccounts?.snapchat, 'Snapchat');
  addSocialMediaAccount(detailsRows, socialMediaAccounts?.pinterest, 'Pinterest');
}

function addSocialMediaAccount(
  detailsRows: DetailsRowProps[],
  socialMediaAccount: string | undefined,
  label: string
) {
  if (socialMediaAccount) {
    detailsRows.push({ label: label, value: socialMediaAccount });
  }
}

export default CreatorDetailsProfile;
