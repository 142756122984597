import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, IconButtonProps, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useState } from 'react';
import { Ama } from '../../../onDemandInteractions/amas/model/ama';
import styles from './AmaMessages.module.scss';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  ama: Ama;
}

function AmaMessageContent({ ama }: Props) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => setExpanded(!expanded);
  return (
    <Paper elevation={0}>
      <Box
        p={2}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        onClick={handleExpandClick}
        className={styles.header}
      >
        <Box>
          <Typography component={'h2'} fontWeight={'bold'} gutterBottom>
            {ama.request}
          </Typography>
          <Typography>Antworten ({ama.responses.length})</Typography>
        </Box>
        <Box>
          <ExpandMore expand={expanded}>
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box className={styles.ama_responses}>
          {ama.responses.map(response => (
            <Box key={response.id} className={styles.ama_response}>
              <Box>
                <Typography variant={'body1'} fontWeight={'bold'}>
                  {response.username}
                </Typography>
              </Box>
              <Box>
                <Typography variant={'body2'}>{response.response}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Paper>
  );
}

export default AmaMessageContent;
