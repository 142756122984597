import { Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import CustomDialogContent from '../../../../components/CustomDialogContent/CustomDialogContent';
import FormikTextField from '../../../../components/FormikTextField/FormikTextField';
import { closeManagedDialog } from '../../../../notifications/state/notificationsSlice';
import { SocialMediaPlatform } from '../../../model/socialMediaPlatform';
import { saveNewCreator } from '../../../state/creatorsSlice';
import DialogActionButtons from '../DialogActionButtons/DialogActionButtons';
import SocialMediaAccountSelection from '../SocialMediaAccountSelection/SocialMediaAccountSelection';

const validationSchema = yup.object({
  name: yup
    .string()
    .required('Der Name darf nicht leer sein')
    .max(30, 'Das Maximum beträgt 30 Zeichen'),
  email: yup
    .string()
    .email('Bitte gebe eine valide E-Mail-Adresse an')
    .required('Die E-Mail-Adresse darf nicht leer sein')
    .max(350, 'Das Maximum beträgt 350 Zeichen'),
  socialMediaPlatform: yup
    .mixed<SocialMediaPlatform>()
    .oneOf(Object.values(SocialMediaPlatform))
    .required('Bitte wähle eine Social Media Plattform aus'),
  socialMediaUsername: yup.string().required('Bitte gebe einen Social Media Account an'),
});

export type AgencyAddCreatorDataUi = {
  name: string;
  email: string;
  socialMediaPlatform: SocialMediaPlatform | string;
  socialMediaUsername: string;
};

const formDataUi: AgencyAddCreatorDataUi = {
  name: '',
  email: '',
  socialMediaUsername: '',
  socialMediaPlatform: '',
};

const AgencyAddCreatorForm = () => {
  const dispatch = useDispatch();

  const onSubmit = (values: AgencyAddCreatorDataUi) => {
    dispatch(saveNewCreator(values));
    dispatch(closeManagedDialog()); // TODO add to slice?
  };

  return (
    <div data-testid="agency-add-streamer-form">
      <Formik initialValues={formDataUi} validationSchema={validationSchema} onSubmit={onSubmit}>
        <Form>
          <CustomDialogContent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormikTextField fieldName="name" label="Name*" value={formDataUi.name} />
              </Grid>
              <Grid item xs={12}>
                <FormikTextField fieldName="email" label="E-Mail*" value={formDataUi.email} />
              </Grid>
              <Grid item xs={12}>
                <SocialMediaAccountSelection fieldsRequired />
              </Grid>
            </Grid>
            <DialogActionButtons />
          </CustomDialogContent>
        </Form>
      </Formik>
    </div>
  );
};

export default AgencyAddCreatorForm;
