import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectLoggedInCreator } from '../../../creators/state/creatorsSlice';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import { clearProductsSelection } from '../../../products/state/productsSlice';
import theme from '../../../theme/theme.module.scss';
import Loading from '../../component/Loading/Loading';
import ScheduleShowForm from '../../component/ScheduleShowForm/ScheduleShowForm';
import { ScheduleShowData } from '../../model/shows';
import {
  clearShow,
  fetchShow,
  selectShowIsBeingLoaded,
  selectShowState,
  updateShow,
} from '../../state/showsSlice';
import styles from './UpdateShowPage.module.scss';

export const UpdateShowPage = () => {
  const { showId } = useParams<{ showId: string }>();
  const show = useSelector(selectShowState);
  const loading = useSelector(selectShowIsBeingLoaded);
  const loggedInStreamer = useSelector(selectLoggedInCreator);

  const dispatch = useDispatch();

  const submitClicked = useCallback(
    (values: ScheduleShowData) => {
      dispatch(updateShow({ showId, request: values }));
    },
    [showId]
  );

  useEffect(() => {
    dispatch(clearProductsSelection());
    dispatch(fetchShow(showId));
  }, [showId]);

  useEffect(() => {
    dispatch(fetchShow(showId));
    return () => {
      dispatch(clearProductsSelection());
      dispatch(clearShow());
    };
  }, [showId]);

  if (loading) {
    return <Loading />;
  }

  if (!show) {
    return (
      <ErrorPageDialog
        errorDialogType={ErrorPageDialogType.ENTITY_NOT_FOUND}
        entityLabel={'Stream'}
      />
    );
  }

  return (
    <PageContainer
      pageToRender={
        <div className={styles.container}>
          <ScheduleShowForm
            key={show.id}
            isCreate={false}
            showToUpdate={show}
            isOwnShow={show.creator?.id === loggedInStreamer?.id}
            submitCallback={submitClicked}
          />
        </div>
      }
      backgroundColor={theme.whiteLilac}
    />
  );
};
