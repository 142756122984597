import RefreshTokenUtil from '../../../agencies/api/RefreshTokenUtil';
import authClient from '../../../auth/authClient';
import Config from '../../../config/config';
import { Ama } from '../model/ama';
import { AmaCreateRequest, AmasResponse, AmaUpdateRequest } from './amasRequestResponse';

const defaultBaseUrl = Config.env.socialLifeCommerceServiceBaseUrl;

const basePath = '/on-demand-interactions/amas';
const amasClient = (baseUrl: string) => authClient.createClient(baseUrl + basePath);

const create = async (
  request: AmaCreateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<Ama> => {
  const response = await RefreshTokenUtil.wrap(() => amasClient(baseUrl).post<Ama>('', request));
  return response.data;
};

const updateById = async (
  id: string,
  request: AmaUpdateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<Ama> => {
  const response = await RefreshTokenUtil.wrap(() =>
    amasClient(baseUrl).post<Ama>(`/${id}`, request)
  );
  return response.data;
};

const fetchById = async (id: string, baseUrl: string = defaultBaseUrl): Promise<Ama> => {
  const response = await RefreshTokenUtil.wrap(() => amasClient(baseUrl).get<Ama>(`/${id}`));
  return response.data;
};

const deleteById = async (id: string, baseUrl: string = defaultBaseUrl): Promise<void> => {
  await RefreshTokenUtil.wrap(() => amasClient(baseUrl).delete<void>(`/${id}`));
};

const fetchByShowId = async (showId: string, baseUrl: string = defaultBaseUrl): Promise<Ama[]> => {
  const response = await RefreshTokenUtil.wrap(() =>
    amasClient(baseUrl).get<AmasResponse>(`/show/${showId}`)
  );
  return response.data.amas;
};

const getAmaResponsesCountForShow = async (
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<number> => {
  const response = await RefreshTokenUtil.wrap(() =>
    amasClient(baseUrl).get(`/show/${showId}/count`)
  );
  return response.data.amaResponsesCount as number;
};

const amasApi = {
  basePath,
  amasClient,
  create,
  updateById,
  fetchById,
  deleteById,
  fetchByShowId,
  getAmaResponsesCountForShow,
};

export default amasApi;
