import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { LoggedInCustomerPersonalDetailsResponse } from '../auth/types';
import { UpdateCreatorRequest } from '../creators/api/requestResponse';

dayjs.extend(customParseFormat);

export const getCustomerPersonalDetails = (
  values: UpdateCreatorRequest,
  isShopAccount: boolean,
  customerDetails?: LoggedInCustomerPersonalDetailsResponse
): UpdateCreatorRequest => ({
  salutation: isShopAccount && customerDetails ? customerDetails.salutation : values.salutation,
  firstName: isShopAccount && customerDetails ? customerDetails.firstName : values.firstName,
  lastName: isShopAccount && customerDetails ? customerDetails.lastName : values.lastName,
  addressAddition: values.addressAddition,
  streetNumber: values.streetNumber,
  street: values.street,
  zipCode: values.zipCode,
  city: values.city,
  birthdate:
    isShopAccount && customerDetails?.birthdate
      ? customerDetails.birthdate
      : dayjs(values.birthdate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
  externalCustomerId: customerDetails?.customerNumber,
});
