import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  GridColDef,
  GridFeatureModeConstant,
  GridFilterModel,
  GridRowIdGetter,
  GridRowParams,
} from '@mui/x-data-grid';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import { TableFilter } from '../../state/creatorsSlice';
import DataGridTable from './DataGridTable';

export type Pageable = {
  page: number;
  pageSize: number;
  sortField: string;
  sortOrder: 'asc' | 'desc';
  searchTerm?: string;
};

interface ServerSideDataGridProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  loading: boolean;
  height?: number;
  initialPageSize?: number;
  rowsPerPageOptions?: number[];
  disableColumnMenu?: boolean;
  onRowClick?: (rowParams: GridRowParams) => void;
  getRowId?: GridRowIdGetter;
  quickFilterPlaceholder?: string;
  autoHeight?: boolean;
  updatePageableCallback: (pageable: Pageable) => void;
  initialPageable: Pageable;
  rowCount: number;
  enableColumnSelector?: boolean;
  isExportEnabled?: boolean;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  sx?: SxProps<Theme>;
  initialState?: TableFilter;
  onStateChange?: (state: TableFilter) => void;
  dataGridProps?: {
    rowHeight?: number;
    headerHeight?: number;
  };
}

const ServerSideDataGrid = (props: ServerSideDataGridProps) => {
  const onSortModelChange = (model: GridSortModel) => {
    console.log(model);
    props.updatePageableCallback({
      ...props.initialPageable,
      sortField: model[0].field,
      sortOrder: model[0].sort ? model[0].sort : 'asc',
    });
  };

  const onQuickFilterChange = (model: GridFilterModel) => {
    if (props.quickFilterPlaceholder)
      props.updatePageableCallback({
        ...props.initialPageable,
        searchTerm: model.quickFilterValues?.join(' '),
      });
  };

  const onPageSizeChange = (pageSize: number) => {
    props.updatePageableCallback({
      ...props.initialPageable,
      pageSize,
    });
  };

  const onPageChange = (page: number) => {
    props.updatePageableCallback({
      ...props.initialPageable,
      page: page,
    });
  };

  const modifiedChildProps = {
    ...props,
    onSortModelChange,
    ...(props.quickFilterPlaceholder ? { onQuickFilterChange } : {}),
    onPageChange,
    onPageSizeChange,
    initialPageSize: props.initialPageable.pageSize,
    initialSorting: [
      { field: props.initialPageable.sortField, sort: props.initialPageable.sortOrder },
    ],
  };

  return (
    <DataGridTable
      {...modifiedChildProps}
      mode={GridFeatureModeConstant.server}
      getRowClassName={props.getRowClassName}
      sx={props.sx}
      dataGridProps={props.dataGridProps}
    />
  );
};

export default ServerSideDataGrid;
