import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addPathQuery } from '../../../common/utils';
import MobileAppBar from '../../../components/MobileAppBar/MobileAppBar';
import Config from '../../../config/config';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import { trackInvoiceDownload } from '../../../middlewares/reduxBeaconMiddleware/actions';
import routePaths from '../../../routes/routePaths';
import theme from '../../../theme/theme.module.scss';
import InvoiceDetails from '../../components/InvoiceDetails/InvoiceDetails';
import InvoiceStreamerDetails from '../../components/InvoiceStreamerDetails/InvoiceStreamerDetails';
import { getInvoiceDetails, selectInvoiceDetails } from '../../state/paymentsSlice';
import styles from './InvoicePreviewPage.module.scss';

function InvoicePreviewPage() {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const invoice = useSelector(selectInvoiceDetails);

  useEffect(() => {
    dispatch(getInvoiceDetails(invoiceId));
  }, []);

  const handlePdfDownload = () => {
    if (invoice) {
      dispatch(trackInvoiceDownload());
      const pdfUrl = `${Config.env.socialLifeCommerceServiceBaseUrl}/invoices/${invoice.invoiceId}/${invoice.invoiceNumber}.pdf`;
      window.open(pdfUrl, '_blank');
    }
  };

  const goBack = () => {
    history.push(
      addPathQuery(routePaths.creator.analytics, {
        tab: 'Rechnung',
      })
    );
  };

  const InvoicePreview = () => (
    <div className={styles.main}>
      <MobileAppBar landing={goBack} title={'Rechnung'} />
      <div className={styles.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            {invoice && (
              <button
                data-testid={'top-invoice-download-btn'}
                onClick={handlePdfDownload}
                className={classNames(styles.export_pdf, styles.export_pdf_mobile)}
              >
                <SystemUpdateAltIcon /> Vorschau herunterladen (pdf)
              </button>
            )}
            <div className={styles.card}>{invoice && <InvoiceDetails />}</div>
            {invoice && (
              <button
                data-testid={'bottom-invoice-download-btn'}
                onClick={handlePdfDownload}
                className={styles.export_pdf}
              >
                <SystemUpdateAltIcon /> Rechnung herunterladen (pdf)
              </button>
            )}
          </Grid>
          <Grid className={styles.streamer_details} item xs={12} md={4}>
            <div className={styles.card}>{invoice && <InvoiceStreamerDetails />}</div>
          </Grid>
        </Grid>
      </div>
    </div>
  );

  return <PageContainer pageToRender={InvoicePreview()} backgroundColor={theme.whiteLilac} />;
}

export default InvoicePreviewPage;
