import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FacetElement } from '../../../model/facet';
import { Filter } from '../../../model/filter';
import { SearchParams } from '../../../model/searchParams';
import { selectProductListProps, toggleFilterModal } from '../../../state/productsSlice';
import mapSelectedOptionsToFilterValues from '../../../utils/mapSelectedOptionsToFilterValues';
import { buildLinkTarget } from '../../../utils/targetLinkUtils';
import Checkbox from '../../Checkbox/Checkbox';
import styles from './FilterMultiSelect.module.scss';

interface NewMultiselectProps {
  options: FacetElement[];
  facetName: string;
}

const assignFilterValues = (searchParams: SearchParams | undefined, filter: Filter) => {
  if (searchParams) {
    const otherFilters = searchParams.filters?.filter(fs => fs.name !== filter.name);
    if (otherFilters) {
      const filters = otherFilters.concat(filter);
      return { ...searchParams, filters };
    }

    const filters = searchParams.filters ? searchParams.filters.concat(filter) : [filter];
    return { ...searchParams, filters };
  }

  return searchParams;
};

const FilterMultiSelect = ({ options, facetName }: NewMultiselectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Array<FacetElement>>([]);
  const { searchParams } = useSelector(selectProductListProps);

  const dispatch = useDispatch();
  const history = useHistory();

  const isSelected = (option: FacetElement) => selectedOptions.includes(option);

  useEffect(() => {
    const selectedOptions = options.filter(
      option => option.selected && option.associatedFieldName === facetName
    );
    setSelectedOptions(selectedOptions);
  }, [options]);

  const toggle = (facet: FacetElement) =>
    selectedOptions.map(so => so.text).includes(facet.text)
      ? setSelectedOptions(selectedOptions.filter(option => option.text !== facet.text))
      : setSelectedOptions(selectedOptions.concat(facet));

  const triggerSearch = () => {
    const values = mapSelectedOptionsToFilterValues(selectedOptions);
    const filter: Filter = { name: facetName, values, substring: false };
    const copiedSearchParams = assignFilterValues(searchParams, filter);

    delete copiedSearchParams?.page;

    history.push(buildLinkTarget(copiedSearchParams));
    dispatch(toggleFilterModal());
  };

  return (
    <div>
      <ul className={styles.multiSelectOptions}>
        {options
          .filter(option => option.text)
          .map(option => {
            return (
              <li className={styles.multiSelectItem} key={option.text}>
                <Checkbox
                  onChange={() => toggle(option)}
                  label={option.displayName}
                  checked={isSelected(option)}
                />
              </li>
            );
          })}
      </ul>
      <div className={styles.multiSelectFooter}>
        <a className={styles.linkBtn} onClick={triggerSearch}>
          Anwenden
        </a>
      </div>
    </div>
  );
};

export default FilterMultiSelect;
