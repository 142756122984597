import { lazy } from 'react';
import AuthPage from '../auth/pages/AuthPage/AuthPage';
import SetPasswordPage from '../auth/pages/SetPasswordPage/SetPasswordPage';
import WelcomePage from '../auth/pages/WelcomePage/WelcomePage';
import { Role } from '../auth/role';
import { featureFlagValue } from '../config/featureFlagConfig';
import { AvailableFlag } from '../config/FeatureFlags';
import ImprintPage from '../pages/ImprintPage/ImprintPage';
import LandingPage from '../pages/LandingPage/LandingPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import { agencyRoutes } from './agencyRoutes';
import { creatorRoutes } from './creatorRoutes/creatorRoutes';
import { hseEmployeeRoutes } from './hseEmployeeRoutes';
import { RouteDetails } from './routeDetails';
import routePaths from './routePaths';

const isInApp = featureFlagValue(AvailableFlag.isInApp);
export const routes: Record<string, RouteDetails> = {
  landing: {
    path: routePaths.landing,
    component: LandingPage,
    exact: true,
    protected: true,
    roles: [
      Role.AGENCY,
      Role.STREAMER_HELLO,
      Role.STREAMER_CLASSIC,
      Role.HSE_EMPLOYEE,
      Role.INFLUENCER,
    ],
    hideDesktopFooter: true,
  },
  privacyPolicy: {
    path: routePaths.privacyPolicy,
    component: PrivacyPolicyPage,
    exact: true,
    protected: false,
    hideHeader: isInApp,
    hideDesktopFooter: true,
  },
  termsAndConditions: {
    path: routePaths.termsConditions,
    component: lazy(() => import('../pages/TermsAndConditionsPage/TermsAndConditionsPage')),
    exact: true,
    protected: false,
    hideHeader: isInApp,
    hideDesktopFooter: true,
  },
  imprint: {
    path: routePaths.imprint,
    component: ImprintPage,
    exact: true,
    protected: false,
    hideHeader: isInApp,
    hideDesktopFooter: true,
  },
  auth: {
    path: routePaths.auth,
    component: AuthPage,
    exact: true,
    protected: false,
    hideHeader: true,
    hideDesktopFooter: true,
    minimalHorizontalFooter: true,
  },
  welcome: {
    path: routePaths.welcome,
    component: WelcomePage,
    exact: true,
    protected: false,
    hideHeader: true,
    hideDesktopFooter: true,
    minimalHorizontalFooter: true,
  },
  setPassword: {
    path: routePaths.setPassword,
    component: SetPasswordPage,
    exact: true,
    protected: false,
    hideHeader: true,
    minimalHorizontalFooter: true,
    hideDesktopFooter: true,
  },
  ...hseEmployeeRoutes,
  ...agencyRoutes,
  ...creatorRoutes,
};
