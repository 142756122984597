import { MultipleStopOutlined } from '@mui/icons-material';
import { Box, Grid, ListItem, Paper, Stack, Typography } from '@mui/material';
import List from '@mui/material/List';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProductTileInfo } from '../../../products/model/productTileInfo';
import {
  addHighlightedProduct,
  highLightProducts,
  selectHighlightedProducts,
  selectShowProductDetails,
} from '../../state/showsSlice';
import HighlightProductItem from './HighlightProductItem/HighlightProductItem';
import styles from './HighlightProductsList.module.scss';

export interface HighlightProductProps {
  showId: string;
}

function PlaceHolder() {
  return (
    <ListItem data-testid={'highlighted-products-placeholder'}>
      <Paper elevation={0} className={styles.placeholder_highlighted} />
    </ListItem>
  );
}

const HighlightProducts = ({ showId }: HighlightProductProps) => {
  const dispatch = useDispatch();
  const showProductDetails = useSelector(selectShowProductDetails);
  const highlightedProducts = useSelector(selectHighlightedProducts);

  const setHighlightedProducts = (products: ProductTileInfo[]) =>
    dispatch(addHighlightedProduct(products));
  const notHighlightedProducts = showProductDetails.filter(
    product => !highlightedProducts.map(it => it.baseProductNo).includes(product.baseProductNo)
  );

  useEffect(() => {
    dispatch(
      highLightProducts({
        showId,
        baseProductNos: highlightedProducts.map(p => p.baseProductNo),
      })
    );
    const interval = setInterval(() => {
      dispatch(
        highLightProducts({
          showId,
          baseProductNos: highlightedProducts.map(p => p.baseProductNo),
        })
      );
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [highlightedProducts]);

  const MAX_NUMBER_HIGHLIGHTS = 3;
  const highlightProduct = (product: ProductTileInfo) => {
    if (highlightedProducts.length < MAX_NUMBER_HIGHLIGHTS)
      setHighlightedProducts([...highlightedProducts, product]);
  };
  const unHighlightProduct = (product: ProductTileInfo) => {
    setHighlightedProducts(highlightedProducts.filter(p => p !== product));
  };

  const mapNotHighlightedComponent = (product: ProductTileInfo) => (
    <ListItem
      key={product.baseProductNo}
      data-testid={'not-highlighted-product'}
      onClick={() => highlightProduct(product)}
    >
      <HighlightProductItem
        key={product.baseProductNo}
        product={product}
        selectable={highlightedProducts.length < MAX_NUMBER_HIGHLIGHTS}
      />
    </ListItem>
  );

  const mapHighlightedComponent = (product: ProductTileInfo) => (
    <ListItem
      key={product.baseProductNo}
      data-testid={'highlighted-product'}
      onClick={() => unHighlightProduct(product)}
    >
      <HighlightProductItem
        key={product.baseProductNo}
        product={product}
        isHighlighted
        selectable
      />
    </ListItem>
  );

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={1}>
        <Typography variant="h6" gutterBottom component="h6">
          Produkte
        </Typography>
        <Typography variant="subtitle1">
          Wähle bis zu {MAX_NUMBER_HIGHLIGHTS} Produkte aus um sie hervorzuheben:
        </Typography>
      </Stack>
      <Grid container direction={'row'} spacing={1} columns={11}>
        <Grid item xs={5}>
          <List className={styles.products_list} data-testid={'not-highlighted-products'}>
            {notHighlightedProducts.map(product => mapNotHighlightedComponent(product))}
            {notHighlightedProducts.length == 0 && <PlaceHolder />}
          </List>
        </Grid>
        <Grid item xs={1}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={styles.left_right_indicator}
          >
            <MultipleStopOutlined />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <List className={styles.hightlighed_list} data-testid={'highlighted-products'}>
            {highlightedProducts.map(product => mapHighlightedComponent(product))}
            {highlightedProducts.length < MAX_NUMBER_HIGHLIGHTS &&
              Array(
                Math.min(showProductDetails.length, MAX_NUMBER_HIGHLIGHTS) -
                  highlightedProducts.length
              ) // up to MAX_NUMBER_HIGHLIGHTS placeholders, but at max number of product in show
                .fill(0)
                .map((_, idx) => <PlaceHolder key={idx} />)}
          </List>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default HighlightProducts;
