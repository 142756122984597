/** Error codes sent by IVS Chat Messaging API. */
export const chatErrorCodes = {
    /** The request is malformed or invalid. */
    badRequest: 400,
    /** The connection has expired. */
    unauthorized: 401,
    /** The connection’s capabilities do not permit this action. */
    forbidden: 403,
    /** The room cannot be found (does not exist). */
    notFound: 404,
    /** The request was rejected during message review. */
    notAcceptable: 406,
    /** The request contains fields that are too large. */
    payloadTooLarge: 413,
    /** The application has exceeded rate limits. */
    tooManyRequests: 429,
    /** This is returned for a general error. */
    internalServerError: 500,
};
