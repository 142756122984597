import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`;

export const Bar = styled(Box)`
  height: 4px;
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 100px;
`;

type FilledBarProps = {
  color: string;
  percentage: number;
};

export const FilledBar = styled(Box)<FilledBarProps>`
  height: 100%;
  width: ${props => `${props.percentage}%`};
  background-color: ${props => props.color};
  border-radius: 100px;
  transition: width 300ms ease-out;
`;
