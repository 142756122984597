import { Skeleton } from '@mui/material';
import React from 'react';
import styles from './AdminPlannedShowTileSkeleton.module.scss';

const AdminPlannedShowTileSkeleton = () => {
  return (
    <div className={styles.container} data-testid="admin-planned-show-tile-skeleton">
      <div className={styles.images_container}>
        <div>
          <Skeleton
            variant="rectangular"
            width="160px"
            height="200px"
            sx={{
              borderTopLeftRadius: '8px',
              borderBottomLeftRadius: '8px',
            }}
          />
        </div>
        <div className={styles.streamer_info}>
          <Skeleton variant="circular" height="32px" width="32px" />
          <Skeleton variant="rectangular" height="16px" width="90px" />
        </div>
      </div>
      <div className={styles.show_details}>
        <div className={styles.date_and_action_buttons}>
          <div className={styles.date}>
            <Skeleton variant="rectangular" width="200px" height="26px" />
          </div>
          <div className={styles.show_icon_buttons}>
            {[1, 2, 3, 4].map(i => (
              <Skeleton
                key={i}
                variant="circular"
                style={{ width: '38px', height: '38px', padding: '8px' }}
              />
            ))}
            <Skeleton variant="rectangular" style={{ width: '142px', height: '40px' }} />
          </div>
        </div>
        <Skeleton variant="text" width="150px" />
        <Skeleton variant="text" width="410px" height="24px" />
        <div className={styles.products_wrapper}>
          {[1, 2, 3].map(i => (
            <Skeleton key={i} variant="rectangular" width="64px" height="80px" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPlannedShowTileSkeleton;
