import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest } from 'redux-saga/effects';
import { log } from './LoggingLambdaApi';
import { logToLambda } from './loggingSlice';

export function* logToLambdaHandler(action: PayloadAction<Record<string, unknown>>) {
  try {
    yield call(log, action.payload);
  } catch (unknownError: unknown) {
    console.log(`error when trying to log to lambda: `, unknownError, action.payload);
  }
}

export function* watcherLoggingLambdaSagas() {
  yield takeLatest(logToLambda.type, logToLambdaHandler);
}
