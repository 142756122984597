import { VisibilityOffOutlined } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import { StreamedBy } from '../../model/shows';
import style from './StreamedByWithStreamNameCell.module.scss';
interface Props {
  title: string;
  isPublished: boolean;
  streamedBy: StreamedBy;
}
const StreamedByWithStreamNameCell = ({ title, isPublished, streamedBy }: Props) => {
  return (
    <>
      {streamedBy === StreamedBy.HSE ? (
        <Tooltip title={t.common['Hse Stream']} placement={'bottom-start'}>
          <Box>
            <Icon
              dataTestId="video-camera-with-circle"
              icon={Icons.videoCameraWithCircle}
              className={style.space}
            />
          </Box>
        </Tooltip>
      ) : (
        <Tooltip title={t.common['Creator Stream']} placement={'bottom-start'}>
          <Box>
            <Icon
              dataTestId="star-with-circle"
              icon={Icons.starWithCircle}
              className={style.space}
            />
          </Box>
        </Tooltip>
      )}
      {!isPublished && <VisibilityOffOutlined className={style.space} />}
      {title}
    </>
  );
};
export default StreamedByWithStreamNameCell;
