import { isMobileBreakPoint } from '../../common/mediaQueries';
import { DesktopNavbar } from './DesktopNavbar/DesktopNavbar';

const Navbar = () => {
  const isMobile = isMobileBreakPoint();

  return !isMobile ? <DesktopNavbar /> : null;
};

export default Navbar;
