import { LocationChangeAction, LOCATION_CHANGE } from 'connected-react-router';
import dayjs from 'dayjs';
import * as H from 'history';
import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { selectAppStartedAt } from '../app/state/appSlice';
import { selectCurrentLocation } from '../app/store';
import routePaths from '../routes/routePaths';
import { trackPageImpression } from './reduxBeaconMiddleware/actions';

export function* usercentricsHandler() {
  const currentLocation: H.Location = yield select(selectCurrentLocation);
  if (window.UC_UI && window.UC_UI.isInitialized()) {
    if (currentLocation?.pathname == routePaths.privacyPolicy) window.UC_UI.closeCMP();
  }
}

export function* forceUpdateApplicationHandler() {
  const appStartedAt: string = yield select(selectAppStartedAt);
  const minutesSinceAppStart = dayjs().diff(dayjs(appStartedAt), 'minutes');
  if (minutesSinceAppStart > 60) {
    console.log(`Got minutes since app start: ${minutesSinceAppStart}. Reloading page.`);
    window.location.reload();
  }
}

export function* trackPageImpressionHandler(action: LocationChangeAction) {
  // first pageImpression event is delayed to give usercentrics more time to put consent_status in dataLayer first
  if (action.payload.isFirstRendering) yield delay(500);
  yield put(trackPageImpression());
}

export function* watcherMiddlewareSagas() {
  yield takeLatest(LOCATION_CHANGE, usercentricsHandler);
  yield takeLatest(LOCATION_CHANGE, trackPageImpressionHandler);
  yield takeLatest(LOCATION_CHANGE, forceUpdateApplicationHandler);
}
