import { Grid } from '@mui/material';
import badgeAppStore from '../../assets/badge_app_store.png';
import badgeGooglePlay from '../../assets/badge_google_play.png';
import { URL_APP_STORE, URL_GOOGLE_PLAY } from '../../constants/Onboarding';

const AppBadge = () => (
  <Grid container flexDirection={'row'}>
    <a href={URL_APP_STORE} target={'_blank'} rel={'noreferrer'} style={{ marginRight: '16px' }}>
      <img data-testid={'badge-app-store'} src={badgeAppStore} alt={'Badge App Store'} />
    </a>
    <a href={URL_GOOGLE_PLAY} target={'_blank'} rel={'noreferrer'}>
      <img data-testid={'badge-google-play'} src={badgeGooglePlay} alt={'Badge Google Play'} />
    </a>
  </Grid>
);

export default AppBadge;
