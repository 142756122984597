import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileBreakPoint } from '../../../../common/mediaQueries';
import {
  removeFromCreatorArchivedShow,
  selectCreatorArchivedShows,
} from '../../../../shows/state/showsSlice';
import DeletedExpiredShowAlert, {
  DeletedExpiredShowHintProps,
} from '../DeletedExpiredShowAlert/DeletedExpiredShowAlert';
import DeletedExpiredShowDialog from '../DeletedExpiredShowDialog/DeletedExpiredShowDialog';

const DeletedExpiredShowsContainer = () => {
  const dispatch = useDispatch();
  const archivedShows = useSelector(selectCreatorArchivedShows);
  const isMobile = isMobileBreakPoint();

  const removeArchivedShow = (id: string) => {
    dispatch(removeFromCreatorArchivedShow(id));
  };

  if (!archivedShows.length) return null;
  const show = archivedShows[0];

  const ComponentToRender: (props: DeletedExpiredShowHintProps) => JSX.Element = isMobile
    ? DeletedExpiredShowAlert
    : DeletedExpiredShowDialog;

  return <ComponentToRender archivedShow={show} onClose={() => removeArchivedShow(show.id)} />;
};

export default DeletedExpiredShowsContainer;
