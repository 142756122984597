import { LoadingSpinner } from '@hse24/shared-components';
import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../../constants/translation';
import { useExecuteReCaptcha } from '../../../../hooks/useExecuteReCaptcha';
import {
  forgetPasswordAction,
  loginUserAction,
  selectAuthActivationProgressData,
  selectAuthLoading,
} from '../../../state/authSlice';
import { ForgetPasswordOrigin } from '../../../types';
import AuthForm, { AuthFormValues } from '../../AuthForm/AuthForm';

function ExistingAccountStep() {
  const dispatch = useDispatch();
  const executeRecaptcha = useExecuteReCaptcha();

  const loading = useSelector(selectAuthLoading);
  const email = useSelector(selectAuthActivationProgressData)?.email;

  const handleSubmit = (values?: AuthFormValues) => {
    if (email && values && values.password) {
      const { password, stayLoggedIn } = values;
      dispatch(
        loginUserAction({
          credentials: { email, password, stayLoggedIn: !!stayLoggedIn },
          executeRecaptcha,
        })
      );
    }
  };

  const handleResetPassword = () => {
    dispatch(forgetPasswordAction(ForgetPasswordOrigin.ONBOARDING));
  };

  return loading ? (
    <div data-testid={'loading-spinner'}>
      <LoadingSpinner />
    </div>
  ) : email ? (
    <AuthForm
      header={
        <>
          <Typography variant={'h3'}>{t.creators['Welcome 👋']}</Typography>
          <Typography variant={'body1'}>
            {t.validation['Easy, just enter your login data']}
          </Typography>
        </>
      }
      submitButton={{ title: 'Einloggen' }}
      submitCallback={handleSubmit}
      resetPasswordCallback={handleResetPassword}
      email={{ value: email ?? '', disabled: true }}
      password={''}
      stayLoggedIn={false}
    />
  ) : (
    <Typography variant={'h3'}>Etwas ist schief gelaufen</Typography>
  );
}

export default ExistingAccountStep;
