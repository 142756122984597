import React from 'react';
import { useSelector } from 'react-redux';
import { isMobileBreakPoint } from '../../../../common/mediaQueries';
import StyledDialog from '../../../../components/StyledDialog/StyledDialog';
import t from '../../../../constants/translation';
import { selectShowDetailsLoading } from '../../../state/showsSlice';

interface Props {
  openDialog: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

function DeletionConfirmationDialog({ openDialog, handleClose, handleDelete }: Props) {
  const isShowDetailsLoading = useSelector(selectShowDetailsLoading);
  const isMobile = isMobileBreakPoint();

  const submit = () => {
    handleClose();
    handleDelete();
  };

  return (
    <StyledDialog
      close={handleClose}
      dialogProps={{
        open: openDialog,
        maxWidth: isMobile ? 'md' : 'xs',
      }}
      title={t.common['Confirm deletion']}
      body={t.admins.upcomingShowsOverview['Do you really want to delete this stream?']}
      cancel={{
        title: t.common.Cancel,
        action: handleClose,
      }}
      submit={{
        title: t.common['Permanently delete'],
        loading: isShowDetailsLoading,
        action: submit,
        buttonProps: {
          color: 'primary',
        },
      }}
      testId={'deletion-confirmation-dialog'}
    />
  );
}

export default DeletionConfirmationDialog;
