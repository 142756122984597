export const generateQueryForFactFinder = (queryString: string) => {
  if (!queryString) {
    // Setup default query
    return '*&filter=Status:SELLABLE&filter=ProductSocialLiveChannel:true&sort=Price:asc&hitsPerPage=48';
  }

  const urlSearchParams = new URLSearchParams();
  const allowedQueryKeys = ['filter', 'sort', 'page', 'CampaignId', 'hitsPerPage'];

  let q = '*';
  const pairs: string[] = (queryString[0] === '?' ? queryString.substring(1) : queryString).split(
    '&'
  );

  // Add permanent query pairs
  pairs.push('filter=Status:SELLABLE');
  pairs.push('filter=ProductSocialLiveChannel:true');
  pairs.push('hitsPerPage=48');

  // Remove duplicated query pairs
  const queryPairs = Array.from(new Set(pairs));

  for (let i = 0; i < queryPairs.length; i++) {
    const pair = queryPairs[i].split('=');
    const pairKey: string = decodeURIComponent(pair[0]);
    const pairValue: string = decodeURIComponent(pair[1] || '');

    // Only if key of this pair is valid query key
    if (allowedQueryKeys.includes(pairKey)) {
      urlSearchParams.append(pairKey, pairValue);
    } else if (pairKey == 'q') {
      q = replaceEmptyQueryByWildcard(pairValue);
    }
  }

  return urlSearchParams.toString().length === 0
    ? encodeURIComponent(q)
    : `${encodeURIComponent(q)}&${urlSearchParams}`;
};

const replaceEmptyQueryByWildcard = (value: string) => (value == '' ? '*' : value);
