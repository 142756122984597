import { SearchParams } from '../model/searchParams';

export const buildLinkTarget = (searchParams: SearchParams | undefined): string => {
  const parameters = searchParams
    ? [
        `q=${searchParams.query}`,
        buildFilterParameters(searchParams),
        getSort(searchParams),
        getPage(searchParams),
      ].filter(item => item !== null)
    : [];

  return parameters.length ? `/creator/products?${parameters.join('&')}` : '/streamer/products';
};

export const buildResetLinkTarget = (searchParams: SearchParams): string => {
  const query = `q=${searchParams.query || '*'}`;

  const parameters = [query, getSort(searchParams)].filter(item => item !== null);

  return parameters.length ? `/creator/products?${parameters.join('&')}` : '/creator/products';
};

const buildFilterParameters = (searchParams: SearchParams) => {
  const { filters } = searchParams;
  if (!filters) {
    return null;
  }

  const filterQueries = filters.map(filter =>
    filter.values.map(value => `filter=${filter.name}:${encodeURIComponent(value.value)}`).join('&')
  );
  return filterQueries.length ? filterQueries.join('&') : null;
};

const getSort = (searchParams: SearchParams) => {
  const { sortItems } = searchParams;

  return sortItems && sortItems.length ? `sort=${sortItems[0].name}:${sortItems[0].order}` : null;
};

const getPage = (searchParams: SearchParams) =>
  searchParams.page ? `page=${searchParams.page}` : null;
