import { Button, EButtonType } from '@hse24/shared-components';
import { Dialog, Typography } from '@mui/material';
import t from '../../../../src/constants/translation';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import ModalTitle from '../../../components/ModalTitle/ModalTitle';
import { Instruction } from './Instruction/Instruction';
import styles from './UploadInstructions.module.scss';

interface UploadDetailsPopupProps {
  isUploadDetailsPopupOpen: boolean;
  handleClose: (isOpen: boolean) => void;
}
const UploadInstructions = ({ isUploadDetailsPopupOpen, handleClose }: UploadDetailsPopupProps) => {
  const closeHandler = () => {
    handleClose(false);
  };

  return (
    <Dialog
      PaperProps={{
        className: styles.modal,
      }}
      fullWidth
      fullScreen={false}
      open={isUploadDetailsPopupOpen}
      maxWidth="sm"
      data-testid="uploadInstructionPopup"
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <ModalTitle title={t.creators.post.instructionsPopUp.title} handleModalClose={closeHandler} />
      <div className={styles.container}>
        <Instruction
          dataTestId="fileSizeInstruction"
          icon={<Icon icon={Icons.fileSize} />}
          iconText={t.creators.post.instructionsPopUp.instructions[0].iconTitle}
          instructionDescription={
            t.creators.post.instructionsPopUp.instructions[0].instructionDescription
          }
        />
        <Instruction
          dataTestId="fileFormatInstruction"
          icon={<Icon icon={Icons.file} />}
          iconText={t.creators.post.instructionsPopUp.instructions[1].iconTitle}
          instructionDescription={
            t.creators.post.instructionsPopUp.instructions[1].instructionDescription
          }
        />
        <Instruction
          dataTestId="videoInstruction"
          icon={<Icon icon={Icons.play} />}
          iconText={t.creators.post.instructionsPopUp.instructions[2].iconTitle}
          instructionDescription={
            t.creators.post.instructionsPopUp.instructions[2].instructionDescription
          }
          importantNote={t.creators.post.instructionsPopUp.instructions[2].importantNote}
        />
        <Instruction
          dataTestId="pictureInstruction"
          icon={<Icon icon={Icons.photo} />}
          iconText={t.creators.post.instructionsPopUp.instructions[3].iconTitle}
          instructionDescription={
            t.creators.post.instructionsPopUp.instructions[3].instructionDescription
          }
        />
        <Instruction
          dataTestId="cropInstruction"
          icon={<Icon icon={Icons.crop} />}
          iconText={t.creators.post.instructionsPopUp.instructions[4].iconTitle}
          instructionDescription={
            t.creators.post.instructionsPopUp.instructions[4].instructionDescription
          }
        />
      </div>
      <div className={styles.actions}>
        <Button type={EButtonType.PRIMARY} onClick={closeHandler} className={styles.close}>
          <Typography fontWeight={700}> {t.creators.post.instructionsPopUp.close}</Typography>
        </Button>
      </div>
    </Dialog>
  );
};

export default UploadInstructions;
