import CreateIcon from '@mui/icons-material/Create';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import theme from '../../../../theme/theme.module.scss';
import { AmaUpdateRequest } from '../../api/amasRequestResponse';
import AmaManagementForm from '../../components/AmaManagementForm/AmaManagementForm';
import { createAmaAction } from '../../state/amasSlice';

const AmaCreatePage = () => {
  const dispatch = useAppDispatch();

  const { showId } = useParams<{ showId: string }>();
  if (!showId) return null;

  const handleFormSubmit = (request: AmaUpdateRequest) => {
    dispatch(createAmaAction({ showId, ...request }));
  };

  const AmaCreate = () => (
    <AmaManagementForm
      title={'AMA erstellen'}
      initialValues={{ request: '' }}
      submitButton={{ title: 'Erstellen', icon: <CreateIcon /> }}
      handleFormSubmit={handleFormSubmit}
      isUpdate={false}
    />
  );

  return <PageContainer fullHeight backgroundColor={theme.whiteLilac} pageToRender={AmaCreate()} />;
};

export default AmaCreatePage;
