import InfoIcon from '@mui/icons-material/Info';
import DataCard from '../../../components/DataCard/DataCard';
import StreamerCommissionInfoTooltip from '../../../components/StreamerCommissionInfoTooltip/StreamerCommissionInfoTooltip';
import t from '../../../constants/translation';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import { getCurrency } from '../../../utils/getCurrency';
import { FloorSales } from '../../model/agency';
import styles from './OverAllPerformance.module.scss';

interface Props {
  streamedSeconds?: number;
  agencyGeneratedSales?: FloorSales;
  agencyCommission?: FloorSales;
}

const OverAllPerformance = ({ streamedSeconds, agencyGeneratedSales, agencyCommission }: Props) => {
  return (
    <>
      <div className={styles.cards_container}>
        <DataCard
          className={styles.card}
          label={t.creators['Streaming time']}
          value={streamedSeconds !== undefined ? formatTime(streamedSeconds) : 'N/A'}
        />
        <DataCard
          className={styles.card}
          label={t.common.Revenue}
          toolTip={
            <div className={styles.toolTip_container}>
              <span>von Streamern ab Level 3</span>
              <StreamerCommissionInfoTooltip>
                <InfoIcon />
              </StreamerCommissionInfoTooltip>
            </div>
          }
          value={
            agencyGeneratedSales !== undefined
              ? `${agencyGeneratedSales.amount} ${getCurrency(agencyGeneratedSales.currency)}`
              : 'N/A'
          }
        />
        <DataCard
          className={styles.card}
          label={t.agencies['Agency commission']}
          value={
            agencyCommission !== undefined
              ? `${agencyCommission.amount} ${getCurrency(agencyCommission.currency)}`
              : 'N/A'
          }
        />
      </div>
    </>
  );
};

export default OverAllPerformance;
