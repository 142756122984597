import dayjs from 'dayjs';
import * as yup from 'yup';
import { CreatorImageType } from '../../../model/creator';
import {
  profilePersonalDataKeys,
  Salutation,
  StreamerPersonalDataUi,
} from '../ProfilePersonalData';

const vatIdRegex = /^(DE)[0-9]{9}$/i;
const invalidVatIdMessage = 'Ungültige USt.-IdNr.';

const newLineRegex = /\r\n|\r|\n/;
const taxIdRegex = /^[0-9]{11}$/;
const invalidTaxIdMessage = 'Ungültige Steueridentifikationsnummer';

const streamerSchemaValidation: yup.SchemaOf<StreamerPersonalDataUi> = yup.object({
  profileImage: yup.object().shape({
    imageType: yup
      .mixed<CreatorImageType>()
      .oneOf(Object.values(CreatorImageType))
      .required('Pflichtfeld'),
    fileExtension: yup.string().required('Pflichtfeld'),
    imageUrl: yup.string().required('Pflichtfeld'),
  }),
  name: yup.string().nullable().required('Pflichtfeld').max(30, 'Maximal 30 Zeichen'),
  description: yup
    .string()
    .nullable()
    .required('Pflichtfeld')
    .max(70, 'Maximal 70 Zeichen')
    .test(
      'invalid',
      'Maximal 3 Zeilen',
      value => !!value && value.split(newLineRegex)?.length <= 3
    ),
  salutation: yup.mixed<Salutation>().oneOf(Object.values(Salutation)).required('Pflichtfeld'),
  firstName: yup.string().nullable().required('Pflichtfeld').max(50, 'Maximal 50 Zeichen'),
  lastName: yup.string().nullable().required('Pflichtfeld').max(50, 'Maximal 50 Zeichen'),
  street: yup.string().nullable().required('Pflichtfeld').max(50, 'Maximal 50 Zeichen'),
  streetNumber: yup.string().nullable().required('Pflichtfeld').max(30, 'Maximal 30 Zeichen'),
  zipCode: yup.string().nullable().required('Pflichtfeld').max(30, 'Maximal 30 Zeichen'),
  city: yup.string().nullable().required('Pflichtfeld').max(50, 'Maximal 50 Zeichen'),
  phoneNumber: yup.string().nullable().max(30, 'Maximal 30 Zeichen'),
  email: yup.string().nullable().required('Pflichtfeld'),
  birthdate: yup
    .string()
    .nullable()
    .required('Pflichtfeld')
    .test('invalid', 'Ungültiges Datum', value => dayjs(value!).isValid())
    .test('invalid', 'Ungültiges Datum', value => new Date(value!).getFullYear() >= 1900)
    .test('Underage', 'Mindestens 18 Jahre', value => dayjs().diff(dayjs(value!), 'years') >= 18),
  accountHolderName: yup.string().required('Pflichtfeld'),
  iban: yup
    .string()
    .required('Pflichtfeld')
    .when([profilePersonalDataKeys.bic], {
      // Validation is based on external payment service. If IBAN is not valid, BIC can not be determined
      // (see ValidatedIbanTextField component)
      is: (bic: string) => !bic,
      then: yup.string().test('invalidBic', 'Ungültige IBAN', () => false),
    }),
  bic: yup.string(),
  vatId: yup.string().when([profilePersonalDataKeys.isLiableToVat], {
    is: true,
    then: yup.string().nullable().required('Pflichtfeld').matches(vatIdRegex, invalidVatIdMessage),
    otherwise: yup.string().nullable().optional().matches(vatIdRegex, invalidVatIdMessage),
  }),
  isLiableToVat: yup.boolean().required('Pflichtfeld'),
  taxId: yup.string().required('Pflichtfeld').matches(taxIdRegex, invalidTaxIdMessage),
  instagram: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  tiktok: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  youtube: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  facebook: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  snapchat: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
  pinterest: yup.string().optional().max(50, 'Maximal 50 Zeichen'),
});
export default streamerSchemaValidation;
