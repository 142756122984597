import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useState } from 'react';
import t from '../../../constants/translation';
import { SurveyRequestType } from '../../types/types';

interface Props {
  initialValue: SurveyRequestType;
  onItemTypeFilterChange: (itemType: SurveyRequestType) => void;
}

const SurveyItemTypeFilter = ({ initialValue, onItemTypeFilterChange }: Props) => {
  const [value, setValue] = useState<SurveyRequestType>(initialValue);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const itemType = value as SurveyRequestType;
    onItemTypeFilterChange(itemType);
    setValue(itemType);
  };

  return (
    <Box display={'flex'} gap={1} flexDirection="column">
      <RadioGroup value={value} onChange={changeHandler}>
        <FormControlLabel
          control={<Radio />}
          label={t.common['All']}
          value={SurveyRequestType.ALL}
        />
        <FormControlLabel
          control={<Radio />}
          label={t.admins.survey.Livestream}
          value={SurveyRequestType.LIVE_SHOW}
        />
        <FormControlLabel
          control={<Radio />}
          label={t.admins.survey.Recording}
          value={SurveyRequestType.RECORDED_SHOW}
        />
        <FormControlLabel
          control={<Radio />}
          label={t.admins.survey.Post}
          value={SurveyRequestType.POST}
        />
      </RadioGroup>
    </Box>
  );
};

export default SurveyItemTypeFilter;
