import { IconExchange, IconLabel, IconMyProfile, IconOrder, Text } from '@hse24/shared-components';
import { Skeleton } from '@mui/material';
import { useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Icons from '../../../../assets';
import { logout } from '../../../../auth/state/authSlice';
import {
  fetchBasketCount,
  fetchBudgetAmount,
  selectBasketIsLoading,
  selectBasketItemsCount,
  selectBudgetAmount,
  selectBudgetAmountIsLoading,
} from '../../../../basket/state/basketSlice';
import { setCookie } from '../../../../common/utils';
import Icon from '../../../../components/Icon/Icon';
import IconWithText from '../../../../components/IconWithText/IconWithText';
import Config from '../../../../config/config';
import t from '../../../../constants/translation';
import routePaths from '../../../../routes/routePaths';
import { sendMessageToNativeApp } from '../../../../storage/dataExchangerService';
import { buildRedirectionEventBody } from '../../../../storage/nativeMessages';
import { germanMoneyFormat } from '../../../../utils/formatters/germanFormat/germanFormats';
import { ShopLinkType } from '../../../model/creator';
import {
  fetchCreatorShopLinksList,
  selectCreatorShopLinksIsLoading,
  selectCreatorShopLinksOverview,
  selectLoggedInCreator,
} from '../../../state/creatorsSlice';
import useBudgetLabel from '../../../utils/useBudgetLabel';
import Profile from './Profile';
import styles from './ProfileDashboard.module.scss';
import Statistics from './Statistics/Statistics';

const ProfileDashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoadingBasket = useSelector(selectBasketIsLoading);
  const basketItemsCount = useSelector(selectBasketItemsCount);
  const profileData = useSelector(selectLoggedInCreator);
  const shopLinks = useSelector(selectCreatorShopLinksOverview);
  const isLoadingShopLinks = useSelector(selectCreatorShopLinksIsLoading);

  const isBudgetLoading = useSelector(selectBudgetAmountIsLoading);
  const budgetAmount = useSelector(selectBudgetAmount);

  const budgetLabelStyle = useBudgetLabel(budgetAmount);

  useEffect(() => {
    dispatch(fetchBasketCount());
    dispatch(fetchBudgetAmount());
  }, [profileData]);

  useEffect(() => {
    if (profileData) {
      dispatch(fetchCreatorShopLinksList({ creatorId: profileData.id }));
    }
  }, [profileData]);

  const redirectHandler = (link: string) => {
    const absoluteLink = Config.env.baseUrl.concat(link);
    if (isDesktop) {
      window.open(absoluteLink, '_blank');
    } else {
      setCookie('hideHeaderAndFooter', 'true');
      sendMessageToNativeApp(buildRedirectionEventBody(absoluteLink));
    }
  };

  function redirectHandlerForShopLink(type: ShopLinkType, url: string) {
    let absoluteLink: string;
    switch (type) {
      case ShopLinkType.CAMPAIGN:
        absoluteLink = Config.env.baseUrl.concat('/ccv/campaign/').concat(url);
        break;
      case ShopLinkType.BRAND_OR_CATEGORY:
        absoluteLink = Config.env.baseUrl.concat('/ccv/').concat(url.substring('/dpl/m/'.length));
        break;
    }
    if (isDesktop) {
      window.open(absoluteLink, '_blank');
    } else {
      setCookie('hideHeaderAndFooter', 'true');
      sendMessageToNativeApp(buildRedirectionEventBody(absoluteLink));
    }
  }

  const handleEdit = () => history.push(routePaths.creator.editProfile);
  const handleStreamSettings = () => history.push(routePaths.creator.streamSettings);

  return (
    <div className={styles.profile_dashboard}>
      <Text.J3 className={styles.profile_title}>Account</Text.J3>
      <div className={styles.top_container}>
        <Profile
          profilePic={profileData?.profileImageUrl || ''}
          fullName={profileData?.name || ''}
          onEdit={handleEdit}
        />
        {!!profileData?.id && <Statistics creatorId={profileData?.id} />}
      </div>
      <section>
        <Text.B2>{t.creators['Your products']}</Text.B2>
        <div className={styles.links}>
          <div
            className={styles.link}
            onClick={() => {
              redirectHandler('/av/creators-hub/orders');
            }}
          >
            <IconWithText icon={<IconOrder />}>
              <Text.Body>{t.creators.Orders}</Text.Body>
            </IconWithText>
          </div>
          <div
            className={styles.link}
            onClick={() => {
              redirectHandler('/av/creators-hub/open-invoices');
            }}
          >
            <IconWithText icon={<IconExchange />}>
              <Text.Body>{t.creators.openInvoices}</Text.Body>
            </IconWithText>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.budget_section}>
          <Text.B2>{t.creators['Find products']}</Text.B2>
          <div className={styles.badge}>
            {isBudgetLoading ? (
              <Skeleton className={styles.skeleton} data-testid="skeleton" />
            ) : budgetAmount ? (
              <Text.Body
                className={styles.text}
                style={budgetLabelStyle}
                data-testid="budget-amount"
              >
                {germanMoneyFormat(budgetAmount.amount, budgetAmount.currency)} Budget
              </Text.Body>
            ) : null}
          </div>
        </div>
        <div className={styles.links}>
          {!isLoadingShopLinks && !shopLinks.length && (
            <div
              className={styles.link}
              onClick={() => {
                redirectHandler('/ccv/search');
              }}
            >
              <IconWithText icon={<IconLabel />}>
                <Text.Body>{t.creators.allProducts}</Text.Body>
              </IconWithText>
            </div>
          )}
          {!isLoadingShopLinks &&
            shopLinks.length > 0 &&
            shopLinks.map(shopLink => (
              <div
                key={shopLink.id}
                className={styles.image_link}
                onClick={() => {
                  redirectHandlerForShopLink(shopLink.type, shopLink.url);
                }}
                data-testid="shop-link"
              >
                <img src={`${shopLink.imageUrl}?imwidth=416`} alt="shop link image" />
                <div className={styles.overlay} />
                <div className={styles.label}>{shopLink.label}</div>
              </div>
            ))}
          <div
            className={styles.link}
            onClick={() => {
              redirectHandler('/cv/creators-hub');
            }}
          >
            <IconWithText icon={<Icon icon={Icons.basketIcon} />}>
              <Text.Body>{t.creators.basket}</Text.Body>
            </IconWithText>
            <Text.BodyMicro className={styles.badge} data-testid="basket-badge">
              {!isLoadingBasket ? basketItemsCount : null}
            </Text.BodyMicro>
          </div>
        </div>
      </section>
      <section>
        <div className={styles.budget_section}>
          <Text.B2>{t.creators['Personal data']}</Text.B2>
        </div>
        <div className={styles.links}>
          <div
            className={styles.link}
            onClick={() => {
              handleEdit();
            }}
          >
            <IconWithText icon={<IconMyProfile />}>
              <Text.Body>{t.creators['Change profile']}</Text.Body>
            </IconWithText>
          </div>
          <div
            className={styles.link}
            onClick={() => {
              handleStreamSettings();
            }}
          >
            <IconWithText icon={<Icon icon={Icons.settingsIcon} />}>
              <Text.Body>{t.creators.Settings}</Text.Body>
            </IconWithText>
          </div>
        </div>
      </section>
      <section>
        <a
          className={styles.linkBtn}
          href={`${Config.env.streamersPortalUrl}/privacy-policy`}
          rel="noreferrer"
          target="_blank"
        >
          <IconWithText icon={<Icon icon={Icons.shieldIcon} />}>
            <Text.BodySmall>{t.common['Privacy Settings']}</Text.BodySmall>
          </IconWithText>
        </a>
        <a className={styles.logout} target="_blank" onClick={() => dispatch(logout())}>
          <IconWithText icon={<Icon icon={Icons.logoutIcon} />}>
            <Text.BodySmall>{t.common.logOut}</Text.BodySmall>
          </IconWithText>
        </a>
      </section>
    </div>
  );
};

export default ProfileDashboard;
