import { Alert } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import { formatFormikErrors } from '../../utils/formikUtils';
import { profilePersonalDataLabels } from '../Profile/ProfilePersonalData';

const useStyles = makeStyles(() =>
  createStyles({
    errors: {
      whiteSpace: 'pre-line',
    },
    errorsHeader: {
      marginBottom: 16,
    },
  })
);

const FormikPersonalDataErrorsAlert = () => {
  const { errors, isValid, touched } =
    // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormikContext<any>();
  const classes = useStyles();
  const isFormInvalidAndTouched = !isValid && Object.keys(touched).length !== 0;

  return (
    <>
      {isFormInvalidAndTouched && (
        <Alert severity="error" className={classes.errors} data-testid="formik-errors">
          <div className={classes.errorsHeader}>
            <strong>Manche Felder erfordern deine Aufmerksamkeit:</strong>
          </div>
          {formatFormikErrors(errors, profilePersonalDataLabels)}
        </Alert>
      )}
    </>
  );
};

export default FormikPersonalDataErrorsAlert;
