import { LoadingSpinner } from '@hse24/shared-components';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import SubmitButton from '../../../../components/Button/SubmitButton';
import { UpdateCreatorRequest } from '../../../../creators/api/requestResponse';
import { Salutation, translate } from '../../../../creators/components/Profile/ProfilePersonalData';
import { getCustomerPersonalDetails } from '../../../../utils/PersonalDetailsMapper';
import {
  checkPersonalDetailsAction,
  fetchPersonalDetailsAction,
  selectActivationProcessAddressRecommendation,
  selectActivationProcessError,
  selectAuthLoading,
  selectLoggedInCustomerPersonalDetail,
  updatePersonalDetailsAction,
} from '../../../state/authSlice';

dayjs.extend(customParseFormat);

function AddressStep() {
  const dispatch = useDispatch();

  const [originalDetails, setOriginalDetails] = useState<UpdateCreatorRequest | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const authLoading = useSelector(selectAuthLoading);
  const customerDetails = useSelector(selectLoggedInCustomerPersonalDetail);
  const addressRecommendation = useSelector(selectActivationProcessAddressRecommendation);
  const error = useSelector(selectActivationProcessError);

  const isShopAccount = customerDetails?.customerNumber !== undefined;

  useEffect(() => {
    setIsLoading(!!authLoading);
  }, [authLoading]);

  useEffect(() => {
    setDialogOpen(!!addressRecommendation);
  }, [addressRecommendation]);

  useEffect(() => {
    dispatch(fetchPersonalDetailsAction());
  }, []);

  const onSubmit = (values: UpdateCreatorRequest) => {
    const data = getCustomerPersonalDetails(values, isShopAccount, customerDetails);
    setOriginalDetails(data);
    dispatch(checkPersonalDetailsAction(data));
  };

  const onHandleUpdateWithRecommendation = () => {
    if (addressRecommendation && originalDetails)
      dispatch(
        updatePersonalDetailsAction({
          ...originalDetails,
          street: addressRecommendation.street,
          streetNumber: addressRecommendation.streetNumber,
          zipCode: addressRecommendation.zipCode,
          city: addressRecommendation.city,
        })
      );
  };

  const onHandleUpdateWithOriginal = () => {
    if (originalDetails) dispatch(updatePersonalDetailsAction(originalDetails));
  };

  const initialValues: UpdateCreatorRequest = {
    salutation: customerDetails?.salutation ?? Salutation.MR,
    firstName: customerDetails?.firstName ?? '',
    lastName: customerDetails?.lastName ?? '',
    addressAddition: customerDetails?.addressAddition ?? '',
    streetNumber: customerDetails?.streetNumber ?? '',
    street: customerDetails?.street ?? '',
    zipCode: customerDetails?.zipCode ?? '',
    city: customerDetails?.city ?? '',
    birthdate: customerDetails?.birthdate
      ? dayjs(customerDetails?.birthdate).format('DD.MM.YYYY')
      : '',
  };

  const validationSchema = yup.object({
    salutation: isShopAccount ? yup.string() : yup.string().required('Pflichtfeld'),
    firstName: isShopAccount ? yup.string() : yup.string().required('Pflichtfeld'),
    lastName: isShopAccount ? yup.string() : yup.string().required('Pflichtfeld'),
    addressAddition: yup.string(),
    streetNumber: yup.string().required('Pflichtfeld'),
    street: yup.string().required('Pflichtfeld'),
    zipCode: yup.number().required('Pflichtfeld'),
    city: yup.string().required('Pflichtfeld'),
    birthdate: isShopAccount
      ? yup.string()
      : yup
          .string()
          .test('is-date', 'ungültiges Datumsformat', value => dayjs(value, 'DD.MM.YYYY').isValid())
          .test('is-18', 'Sie müssen 18 Jahre alt sein', value =>
            value ? dayjs().subtract(18, 'year').isAfter(dayjs(value, 'DD.MM.YYYY')) : false
          )
          .required('Pflichtfeld'),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => onSubmit(values)}
        enableReinitialize={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit} style={{ width: '310px' }}>
            <Grid container direction={'column'}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    select
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'salutation'}
                    label="Anrede"
                    value={values.salutation}
                    disabled={isShopAccount}
                    error={Boolean(errors.salutation && touched.salutation && errors.salutation)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.salutation && touched.salutation && String(errors.salutation)
                    }
                  >
                    {Object.values(Salutation).map(value => (
                      <MenuItem key={value} value={value}>
                        {translate(value)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'firstName'}
                    label="Vorname*"
                    value={values.firstName}
                    disabled={isShopAccount}
                    error={Boolean(errors.firstName && touched.firstName && errors.firstName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.firstName && touched.firstName && String(errors.firstName)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'lastName'}
                    label="Nachname*"
                    value={values.lastName}
                    disabled={isShopAccount}
                    error={Boolean(errors.lastName && touched.lastName && errors.lastName)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.lastName && touched.lastName && String(errors.lastName)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'street'}
                    label="Straße*"
                    value={values.street}
                    disabled={false}
                    error={Boolean(errors.street && touched.street && errors.street)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.street && touched.street && String(errors.street)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    name={'streetNumber'}
                    label="Nr.*"
                    value={values.streetNumber}
                    disabled={false}
                    error={Boolean(
                      errors.streetNumber && touched.streetNumber && errors.streetNumber
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.streetNumber && touched.streetNumber && String(errors.streetNumber)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'addressAddition'}
                    label="Adresszusatz"
                    value={values.addressAddition}
                    disabled={false}
                    error={Boolean(
                      errors.addressAddition && touched.addressAddition && errors.addressAddition
                    )}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      errors.addressAddition &&
                      touched.addressAddition &&
                      String(errors.addressAddition)
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'tel'}
                    name={'zipCode'}
                    inputProps={{ maxLength: 5 }}
                    label="PLZ*"
                    value={values.zipCode}
                    disabled={false}
                    error={Boolean(errors.zipCode && touched.zipCode && errors.zipCode)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.zipCode && touched.zipCode && String(errors.zipCode)}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'city'}
                    label="Ort*"
                    value={values.city}
                    disabled={false}
                    error={Boolean(errors.city && touched.city && errors.city)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.city && touched.city && String(errors.city)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'text'}
                    name={'birthdate'}
                    label="Geburtsdatum (tt.mm.jjjj)*"
                    value={values.birthdate}
                    disabled={isShopAccount}
                    error={Boolean(errors.birthdate && touched.birthdate && errors.birthdate)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.birthdate && touched.birthdate && String(errors.birthdate)}
                  />
                </Grid>
              </Grid>
              {error && (
                <Typography color={red.A400} mb={'8px'}>
                  {error.detail ? error.detail : error.message}
                </Typography>
              )}
              <SubmitButton
                sx={{ mt: '24px' }}
                fullWidth
                variant={'contained'}
                size={'large'}
                type={'submit'}
                disabled={isLoading || !isValid}
              >
                {!isLoading ? 'Weiter' : <LoadingSpinner />}
              </SubmitButton>
            </Grid>
          </form>
        )}
      </Formik>
      <Dialog open={dialogOpen}>
        {!isLoading && addressRecommendation ? (
          <>
            <DialogTitle>Empfohlene Adresse</DialogTitle>
            <DialogContent>
              <Typography variant={'body1'}>Könnte das die gewünschte Adresse sein?</Typography>
              <Typography variant={'body1'} fontWeight={'bold'}>
                {addressRecommendation.street} {addressRecommendation.streetNumber}
              </Typography>
              <Typography fontWeight={'bold'}>
                {addressRecommendation.zipCode} {addressRecommendation.city}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Link
                component="button"
                variant="body2"
                color={'#475261'}
                onClick={onHandleUpdateWithOriginal}
              >
                Nein, meine Eingabe verwenden.
              </Link>
              <Button
                sx={{ padding: '0.4rem 3rem' }}
                onClick={onHandleUpdateWithRecommendation}
                autoFocus
                variant={'contained'}
                disableElevation
              >
                Ja
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <CircularProgress data-testid={'loading-spinner'} />
          </DialogContent>
        )}
      </Dialog>
    </>
  );
}

export default AddressStep;
