import { HelpOutline, InfoOutlined, PolicyOutlined, PrivacyTipOutlined } from '@mui/icons-material';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { IconButton, Menu, MenuItem, Theme, useMediaQuery } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout, selectUserIsAuthenticated } from '../../../auth/state/authSlice';
import ProfileImageAvatar from '../../../components/ProfileImageAvatar/ProfileImageAvatar';
import Config from '../../../config/config';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import routePaths from '../../../routes/routePaths';
import { sendMessageToNativeApp } from '../../../storage/dataExchangerService';
import { buildClickMessageEvent, ClickEventElement } from '../../../storage/nativeMessages';
import {
  accountMenuItems,
  getNavigationItemsForUserRoles,
  selectActiveNavigationItem,
} from '../navigationItems';
import styles from './AccountMenu.module.scss';

const AccountMenu = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isApp = featureFlagValue(AvailableFlag.isInApp);
  const availableMenuItems = useSelector(getNavigationItemsForUserRoles(accountMenuItems));
  const activeMenuItemIndexOrFalse = useSelector(selectActiveNavigationItem(accountMenuItems));
  const isAuthenticated = useSelector(selectUserIsAuthenticated);

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const isPathAlreadyOpened = (selectedIndex: number) => {
    return activeMenuItemIndexOrFalse === selectedIndex;
  };

  const triggerLogout = () => {
    closeMenu();
    dispatch(logout());
  };

  const openConsentAndClose = () => {
    closeMenu();
    sendMessageToNativeApp(buildClickMessageEvent(ClickEventElement.OPEN_CONSENT));
  };

  const handleChange = (selectedIndex: number) => {
    closeMenu();
    const path = availableMenuItems[selectedIndex]?.path;
    path && history.location.pathname !== path && history.push(path);
  };

  return isAuthenticated ? (
    <>
      <IconButton
        className={styles.profile_image}
        aria-label="account of current user"
        data-testid="account"
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={openMenu}
        size="large"
      >
        <ProfileImageAvatar />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        className={styles.menu}
      >
        {!isMobile &&
          availableMenuItems.map((item, index) => (
            <MenuItem
              onClick={() => handleChange(index)}
              disabled={isPathAlreadyOpened(index)}
              className={styles.menuButton}
              data-testid={'nav-menu-item'}
              key={index}
            >
              {item.icon ? <span className={styles.menuIcon}>{item.icon}</span> : null}
              {item.name}
            </MenuItem>
          ))}
        <MenuItem
          component="a"
          href="https://hellolive.zendesk.com/hc/de/categories/8703569770013-Fragen-und-Antworten"
          target="_blank"
          className={styles.menuButton}
          data-testid={'nav-menu-item'}
        >
          <HelpOutline className={styles.menuIcon} />
          Hilfe
        </MenuItem>
        {isMobile && (
          <div>
            <MenuItem
              {...(isApp
                ? {
                    onClick: () => openConsentAndClose(),
                  }
                : {
                    component: 'a',
                    href: `${Config.env.streamersPortalUrl}${routePaths.privacyPolicy}`,
                    target: '_blank',
                  })}
              className={styles.menuButton}
              data-testid={'nav-menu-item'}
            >
              <PrivacyTipOutlined className={styles.menuIcon} />
              Datenschutz
            </MenuItem>
            <MenuItem
              component="a"
              href={`${Config.env.streamersPortalUrl}${routePaths.termsConditions}`}
              target="_blank"
              className={styles.menuButton}
              data-testid={'nav-menu-item'}
            >
              <PolicyOutlined className={styles.menuIcon} />
              Geschäftsbedingungen
            </MenuItem>
            <MenuItem
              component="a"
              href={`${Config.env.streamersPortalUrl}${routePaths.imprint}`}
              target="_blank"
              className={styles.menuButton}
              data-testid={'nav-menu-item'}
            >
              <InfoOutlined className={styles.menuIcon} />
              Impressum
            </MenuItem>
          </div>
        )}
        <MenuItem
          onClick={triggerLogout}
          className={styles.menuButton}
          data-testid={'nav-menu-item'}
        >
          <LogoutIcon className={styles.menuIcon} />
          Ausloggen
        </MenuItem>
      </Menu>
    </>
  ) : (
    <></>
  );
};

export default AccountMenu;
