import { Button, EButtonSize } from '@hse24/shared-components';
import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeErrorDialog, selectErrorDialogDetails } from '../../state/notificationsSlice';

const ErrorDialog = () => {
  const dispatch = useDispatch();
  const errorDialogDetails = useSelector(selectErrorDialogDetails);
  const [title, setTitle] = useState('');
  const [contentText, setContentText] = useState('');

  const onButtonClick = () => dispatch(closeErrorDialog());

  const onClose = (e: BaseSyntheticEvent) => e.preventDefault();

  const resolveTitleAndContentText = () => {
    switch (errorDialogDetails?.errorCode) {
      case 500: {
        setTitle('Fehler');
        setContentText(
          `Ein interner Fehler ist aufgetreten, bitte versuche es später noch einmal.`
        );
        break;
      }
      case 503: {
        setTitle('Nicht erreichbar');
        setContentText(
          `Diese Funktion ist vorübergehend nicht erreichbar, bitte versuche es später noch einmal.`
        );
        break;
      }
      case 400: {
        setTitle(errorDialogDetails.errorMessage || 'Fehlerhafte Daten');
        setContentText(
          `Die Daten wurden nicht akzeptiert. Bitte korrigiere deine Eingaben und versuche es erneut.`
        );
        break;
      }
      case 404: {
        setTitle('Daten nicht gefunden');
        setContentText(
          'Wir schließen gerade die Erstellung deines Accounts ab, damit Du mit deine Streams starten kannst. Falls dieser Fehler auch in einigen Stunden noch auftritt, wende Dich gerne an den HSE-Support.'
        );
        break;
      }
      // ToDo: This is a workaround for customer service client errors. See https://edutainment.atlassian.net/browse/SC-1151 for solving this!
      case 409: {
        setTitle('Kundenkonto kann nicht angelegt werden');
        setContentText(errorDialogDetails.errorMessage || `Bitte kontaktiere den HSE-Support.`);
        break;
      }
      default: {
        setTitle('Fehler');
        setContentText(
          errorDialogDetails?.errorMessage ||
            'Ein Fehler ist aufgetreten, bitte versuche es später noch einmal.'
        );
      }
    }
  };

  useEffect(() => {
    if (errorDialogDetails?.toggle) {
      resolveTitleAndContentText();
    }
  }, [errorDialogDetails]);

  // The navigation should not generally be back the the "übersicht". Also the navigation is done before displaying this
  // dialog. See https://edutainment.atlassian.net/wiki/spaces/TSC/pages/2101575728/Error+Messages for the actual
  // requirement. This will be implemented in a later story.
  return (
    <Dialog
      open={!!errorDialogDetails?.toggle}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size={EButtonSize.SMALL} onClick={onButtonClick}>
          Weiter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
