import { Currency } from '../shows/model/shows';

export enum Audience {
  CLASSIC = 'CLASSIC',
  HELLO = 'HELLO',
}

export enum Platform {
  APP = 'APP',
  WEB = 'WEB',
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

export interface PageableResponse {
  hits: number;
  pages: number;
}

export interface QueryParams {
  pageSize: number;
  pageNumber: number;
  sortField: string; // keyof CreatorOverview;
  sortOrder: 'asc' | 'desc';
  from: string;
  to: string;
  searchTerm?: string;
  audience?: Audience[];
}

export interface PageableRequest {
  pageSize: number;
  page: number;
}

export interface FloorSale {
  amount: number;
  currency: Currency;
}

export interface BudgetAmount extends FloorSale {
  initialAmount: number;
}

export interface PreSignedUrlResponse {
  preSignedUrl: string;
  key: string;
}
