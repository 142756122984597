import { useDispatch } from 'react-redux';
import PriceInfo from '../../../../components/PriceInfo/PriceInfo';
import ImagePreview from '../../../../products/components/ImagePreview/ImagePreview';
import { ProductTileInfo } from '../../../../products/model/productTileInfo';
import { onSelectProductForShowSchedule } from '../../../state/showsSlice';
import styles from './ShowProductSuggestionItem.module.scss';

interface ShowProductSuggestionItemProps {
  product: ProductTileInfo;
  onProductSelectedCallback: () => void;
}

const ShowProductSuggestionItem = ({
  product,
  onProductSelectedCallback,
}: ShowProductSuggestionItemProps) => {
  const dispatch = useDispatch();

  const selectProduct = () => {
    dispatch(onSelectProductForShowSchedule(product));
    onProductSelectedCallback();
  };

  return (
    <li className={styles.suggestion_item} onClick={selectProduct}>
      <ImagePreview
        alt={product.name}
        size={320}
        imageUrl={product.imageUri}
        height={125}
        width={100}
      />
      <div>
        <h3>{product.name}</h3>
        <PriceInfo price={product.price} />
      </div>
    </li>
  );
};

export default ShowProductSuggestionItem;
