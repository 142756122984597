import { IconCheck } from '@hse24/shared-components';
import { Popover } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import Icons from '../../../../../assets';
import Icon from '../../../../../components/Icon/Icon';
import t from '../../../../../constants/translation';
import { ShowStatus } from '../../../../model/shows';
import { changeShowVisibility } from '../../../../state/showsSlice';
import styles from './ShowStatusChangeModal.module.scss';

interface Props {
  showId: string;
  showStatus: ShowStatus;
  isOpen: boolean;
  isShowReadyToBePublished: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  testId?: string;
}

interface StatusConfig {
  label: string;
  icon?: React.ReactNode;
  className?: string;
  newStatusIdPublished: boolean;
}

const statusConfigs: Partial<Record<ShowStatus, StatusConfig>> = {
  [ShowStatus.CREATED]: {
    label: t.creators.show.status.Unpublished,
    icon: <Icon icon={Icons.eyesViewOffInvisibleIcon} dataTestId={'unpublished-icon'} />,
    newStatusIdPublished: false,
  },
  [ShowStatus.DRAFT]: {
    label: t.creators.show.status.Published,
    icon: <IconCheck className={styles.published} width="24px" height="24px" />,
    className: styles.published,
    newStatusIdPublished: true,
  },
  [ShowStatus.UNPUBLISHED]: {
    label: t.creators.show.status.Published,
    icon: <IconCheck className={styles.published} width="24px" height="24px" />,
    className: styles.published,
    newStatusIdPublished: true,
  },
};

const ShowStatusChangeModal = ({
  showId,
  showStatus,
  isShowReadyToBePublished,
  isOpen,
  anchorEl,
  handleClose,
  testId,
}: Props) => {
  const config = statusConfigs[showStatus];
  const dispatch = useDispatch();

  if (!config) {
    return null;
  }

  const handleClick = () => {
    dispatch(
      changeShowVisibility({
        showId,
        isPublished: config.newStatusIdPublished,
        updateUpcomingShowsOverview: true,
      })
    );
    handleClose();
  };

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          marginTop: '8px',
          boxShadow: '0 0 17px 0 rgba(0, 0, 0, 0.23)',
        },
      }}
      data-testid={testId}
    >
      <div className={styles.container}>
        <div className={styles.label}>
          <span>
            {isShowReadyToBePublished
              ? `${t.creators.show['Change status to']}:`
              : t.creators.show['Changing status not possible']}
          </span>
        </div>

        {isShowReadyToBePublished ? (
          <div className={styles.new_status} onClick={handleClick}>
            {config.icon}
            <span className={config.className}>{config.label}</span>
          </div>
        ) : (
          <div className={styles.new_status_disabled}>
            <Icon icon={Icons.sandGlassIcon} dataTestId={'sand-glass-icon'} />
            <span>{t.validation['Incomplete data']}</span>
          </div>
        )}
      </div>
    </Popover>
  );
};

export default ShowStatusChangeModal;
