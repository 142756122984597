import { createMiddleware, EventsMap } from 'redux-beacon';
import {
  onboardingCompleted,
  profileCompleted,
  samplesOrdered,
  samplesReceived,
} from '../../creators/state/creatorsSlice';
import { firstShowCreated } from '../../shows/state/showsSlice';
import {
  trackInvoiceDownload,
  trackLoginSuccess,
  trackMarketingConsent,
  trackPageImpression,
  trackProfileImageValidation,
  trackStreamEnded,
  trackStreamerAcademy,
} from './actions';
import { firebaseTrackingMiddleware } from './firebaseTrackingMiddleware';
import {
  trackInvoiceDownloadEvent,
  trackLoginSuccessEvent,
  trackMarketingConsentEvent,
  trackOnboardingCompletedEvent,
  trackPageImpressionEvent,
  trackProfileCompletedEvent,
  trackProfileImageValidationEvent,
  trackSamplesOrderedEvent,
  trackSamplesReceivedEvent,
  trackShowCreatedEvent,
  trackStreamEndedEvent,
  trackStreamerAcademyEvent,
} from './reduxBeaconEvents';

export const eventsMap: EventsMap = {
  [trackPageImpression.type]: trackPageImpressionEvent,
  [trackLoginSuccess.type]: trackLoginSuccessEvent,
  [trackInvoiceDownload.type]: trackInvoiceDownloadEvent,
  [trackMarketingConsent.type]: trackMarketingConsentEvent,
  [trackStreamerAcademy.type]: trackStreamerAcademyEvent,
  [trackStreamEnded.type]: trackStreamEndedEvent,
  [trackProfileImageValidation.type]: trackProfileImageValidationEvent,
  // Onboarding
  [profileCompleted.type]: trackProfileCompletedEvent,
  [samplesOrdered.type]: trackSamplesOrderedEvent,
  [samplesReceived.type]: trackSamplesReceivedEvent,
  [firstShowCreated.type]: trackShowCreatedEvent,
  [onboardingCompleted.type]: trackOnboardingCompletedEvent,
};

export const reduxBeaconMiddleware = () =>
  createMiddleware(eventsMap, firebaseTrackingMiddleware());

export default reduxBeaconMiddleware;
