import { Box, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';

function StreamerAcademySkeleton() {
  return (
    <>
      <Box mt={'3'} display={'flex'} flexDirection={'column'} gap={2} data-testid={'skeleton'}>
        <Typography component={'h2'} fontWeight={'bold'}>
          <Skeleton variant="rectangular" width={'100%'} height={40} />
        </Typography>
        <Paper elevation={0}>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <Skeleton variant="rectangular" width={'100%'} height={40} />
            </Box>
            <Box>
              <Skeleton variant="circular" width={25} height={25} />
            </Box>
          </Box>
        </Paper>
        <Paper elevation={0}>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <Skeleton variant="rectangular" width={'100%'} height={40} />
            </Box>
            <Box>
              <Skeleton variant="circular" width={25} height={25} />
            </Box>
          </Box>
        </Paper>
        <Paper elevation={0}>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <Skeleton variant="rectangular" width={'100%'} height={40} />
            </Box>
            <Box>
              <Skeleton variant="circular" width={25} height={25} />
            </Box>
          </Box>
        </Paper>
        <Paper elevation={0}>
          <Box p={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <Skeleton variant="rectangular" width={'100%'} height={40} />
            </Box>
            <Box>
              <Skeleton variant="circular" width={25} height={25} />
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}

export default StreamerAcademySkeleton;
