import { Button, EButtonType } from '@hse24/shared-components';
import { AddOutlined, ChevronLeft } from '@mui/icons-material';
import { Alert, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { Audience } from '../../../common/types';
import routePaths from '../../../routes/routePaths';
import { ShowDetails } from '../../model/shows';
import {
  addProductToShow,
  endShow,
  fetchShowDetails,
  selectShowDetailsData,
  selectShowDetailsLoading,
} from '../../state/showsSlice';
import HighlightProducts from '../HighlightProductsList/HighlightProducts';
import Loading from '../Loading/Loading';
import ShowProductSearch from '../ShowProductSearch/ShowProductSearch';
import styles from './ShowModeration.module.scss';
import { AddProductsSchema } from './ShowModeration.service';

const ShowModeration = () => {
  const { showId } = useParams<{ showId: string }>();
  const showDetails = useSelector(selectShowDetailsData);
  const loading = useSelector(selectShowDetailsLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { isError, message } = useSelector((state: RootState) => state.shows.highLightProductState);

  useEffect(() => {
    dispatch(fetchShowDetails(showId));
  }, []);

  useEffect(() => {
    if (showDetails?.endedAt) {
      const path = generatePath(routePaths.hseEmployee.showDetails, { showId: showDetails.id });
      history.push(path);
    }
  }, [showDetails?.endedAt, history]);

  if (loading) {
    return <Loading />;
  }

  const triggerEndShowRequest = (showDetails: ShowDetails) => dispatch(endShow(showDetails));

  const submit = (values: { baseProductsNo: string[]; productSearch: string }) => {
    dispatch(addProductToShow({ showId, baseProductsNo: values.baseProductsNo }));
    setIsDialogOpen(false);
  };

  return (
    <>
      <div>
        <Stack direction="row" justifyContent="space-between">
          <Button
            className={styles.link_back}
            onClick={() => history.goBack()}
            type={EButtonType.NONE}
          >
            <ChevronLeft />
            Zurück
          </Button>

          {showDetails && (
            <Button className={styles.link_back} onClick={() => triggerEndShowRequest(showDetails)}>
              <Typography fontWeight={700}> Stream beenden</Typography>
            </Button>
          )}
        </Stack>
      </div>
      {showDetails && (
        <div className={styles.show_details_container}>
          <Stack direction="row" justifyContent="space-between">
            <div>
              <h1 className={styles.header}>{showDetails.title}</h1>
            </div>
            <div>
              <Button
                type={EButtonType.LINK}
                icon={{ left: <AddOutlined /> }}
                onClick={() => setIsDialogOpen(true)}
              >
                Produkt hinzufügen
              </Button>
            </div>
          </Stack>
          <Stack my={4}>{isError && <Alert severity="error"> {message} </Alert>}</Stack>
          <Stack alignItems="center" justifyContent="space-around">
            <HighlightProducts showId={showId} />
          </Stack>
        </div>
      )}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth={true}>
        <DialogContent>
          <Formik
            initialValues={{
              baseProductsNo: [] as string[],
              productSearch: '',
            }}
            onSubmit={submit}
            validationSchema={AddProductsSchema}
          >
            <Form>
              <ShowProductSearch audience={showDetails?.audience ?? [Audience.HELLO]} />
              <Button className={styles.product_popup_btn}>Produkte hinzufügen</Button>
            </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShowModeration;
