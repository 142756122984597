import { Category } from '../model/category';
import { Facet, FacetElement } from '../model/facet';
import { Filter } from '../model/filter';
import { mapToFacetElementSelectedV2 } from './facetsMapper';
import { buildLinkTarget } from './targetLinkUtils';

export const mapToCategories = (facets: Array<Facet>) => {
  if (!facets || !facets.length || !facets.some(facet => facet.name === 'CategoryPath')) {
    return [];
  }

  const categoryFacet = facets.filter(facet => facet.name === 'CategoryPath')[0];

  const allCategories = [...categoryFacet.selectedElements, ...categoryFacet.elements]
    .map(category => mapToCategory(category))
    .sort((a, b) => a.name.localeCompare(b.name));
  const hashTable: Record<string, Category> = {};
  const categoryTree: Category[] = [];
  allCategories.forEach(
    category => (hashTable[category.categoryPath] = { ...category, categories: [] })
  );
  allCategories.forEach(category => {
    if (category.parentCategoryPath) {
      hashTable[category.parentCategoryPath] !== undefined &&
        hashTable[category.parentCategoryPath].categories.push(hashTable[category.categoryPath]);
    } else {
      categoryTree.push(hashTable[category.categoryPath]);
    }
  });

  return categoryTree;
};

const mapToCategory = (category: FacetElement) => {
  let categoryPath = '';
  let parentCategoryPath = '';

  const { filters } = category.searchParams;

  if (category.clusterLevel !== 0) {
    categoryPath = hasFilterCategoryPathValue(filters)
      ? filterCategoryPathValue(filters) + (category.selected ? `/${category.text}` : '')
      : category.text;

    parentCategoryPath = hasFilterCategoryPathValue(filters)
      ? filterCategoryPathValue(filters)?.replace(new RegExp(`\/${category.text}$`), '') || ''
      : category.text;
  } else {
    categoryPath = category.text;
    parentCategoryPath = '';
  }
  if (!hasFilterCategoryPathValue(filters)) {
    const createdFilters = {
      filters: [
        {
          name: 'CategoryPath',
          values: [
            {
              value: parentCategoryPath.length ? parentCategoryPath : categoryPath,
            },
          ],
        },
      ],
    };
    if (filters) {
      category.searchParams.filters?.push(createdFilters.filters[0]);
    } else {
      Object.assign(category.searchParams, createdFilters);
    }
  }

  return {
    categoryPath,
    parentCategoryPath,
    name: category.text,
    linkTarget: buildLinkTarget(category.searchParams),
    clusterLevel: category.clusterLevel,
    selected: mapToFacetElementSelectedV2(category.selected),
    categories: [],
    searchParams: category.searchParams,
  };
};

export const filterCategoryPathValue = (filters: Array<Filter> | undefined) =>
  filters && filters.find(filter => filter.name === 'CategoryPath')?.values[0].value;

export const hasFilterCategoryPathValue = (filters: Array<Filter> | undefined) =>
  filters && filters.some(filter => filter.name === 'CategoryPath');

export const hasChildCategories = (categories: Array<Category>): boolean =>
  categories.some(category => category.categories && category.categories.length !== 0);

export const hasSelectedChild = (categories: Array<Category>): boolean =>
  categories.some(category => category.selected);
