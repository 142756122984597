import { Box, IconButton } from '@mui/material';
import React from 'react';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import styles from './ProductSelectionDialogSearchInput.module.scss';
interface Props {
  value: string;
  onChange: (query: string) => void;
}

function ProductSelectionDialogSearchInput({ onChange, value }: Props) {
  const clearSearch = () => onChange('');
  return (
    <Box className={styles.search_input}>
      <input
        className={styles.input}
        name={'Suche nach Produkten'}
        placeholder="Suche nach Produkten"
        type={'text'}
        onChange={e => onChange(e.currentTarget.value)}
        value={value}
      ></input>

      <Box className={styles.action_icons}>
        <div className={styles.divider}></div>
        {value.length > 0 ? (
          <IconButton onClick={clearSearch} className={styles.clear_icon_btn}>
            <Icon className={styles.clear_icon} icon={Icons.closeIcon} dataTestId="CloseIcon" />
          </IconButton>
        ) : (
          <Icon
            className={styles.search_icon}
            icon={Icons.searchIcon}
            dataTestId="SearchSharpIcon"
          />
        )}
      </Box>
    </Box>
  );
}

export default ProductSelectionDialogSearchInput;
