import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Paper } from '@mui/material';
import { useDispatch } from 'react-redux';
import PriceInfo from '../../../../components/PriceInfo/PriceInfo';
import ImagePreview from '../../../../products/components/ImagePreview/ImagePreview';
import { ProductTileInfo } from '../../../../products/model/productTileInfo';
import { onRemoveProductForShowSchedule } from '../../../state/showsSlice';
import styles from './ShowProductSearchResultItem.module.scss';

interface ShowProductSearchResultItemProps {
  product: ProductTileInfo;
}

const ShowProductSearchResultItem = ({ product }: ShowProductSearchResultItemProps) => {
  const dispatch = useDispatch();
  const deleteProduct = () => dispatch(onRemoveProductForShowSchedule(product));

  return (
    <Paper elevation={4} className={styles.search_item_container}>
      <ImagePreview alt={product.name} size={320} imageUrl={product.imageUri} />
      <RemoveCircleIcon
        className={styles.delete_icon}
        onClick={deleteProduct}
        sx={{
          width: 32,
          height: 32,
        }}
      />
      <div className={styles.search_item_details}>
        <h3 className={styles.search_item_name}>{product.name}</h3>
        <PriceInfo price={product.price} className={styles.search_item_price} />
      </div>
    </Paper>
  );
};

export default ShowProductSearchResultItem;
