import { ArrowIconRight, Text } from '@hse24/shared-components';
import t from '../../../../constants/translation';
import styles from './ProfileDashboard.module.scss';

interface ProfileProps {
  profilePic: string;
  fullName: string;
  onEdit: () => void;
}

const Profile = ({ profilePic, fullName, onEdit }: ProfileProps) => {
  return (
    <div className={styles.profile} onClick={onEdit}>
      <img
        src={profilePic}
        alt={fullName}
        className={styles.profile_pic}
        data-testid="profile-pic"
      />
      <div className={styles.profile_info}>
        <Text.B2 className={styles.full_name}>{fullName}</Text.B2>
        <Text.BodySmall className={styles.edit_button}>{t.common['edit profil']}</Text.BodySmall>
      </div>
      <ArrowIconRight className={styles.arrow_icon} />
    </div>
  );
};

export default Profile;
