import { PayloadAction } from '@reduxjs/toolkit';

// Unfortunately there is a type mismatch in the redux-beacon library, and it's not possible to use it with PayloadAction<string>
// without compiler errors... ([key: string]: any should not have any problems with action: { type: string; payload: string; } but it has...
// That's why a custom type which extends the library action type which is { [key: string]: any }.
// See: EventDefinition from 'redux-beacon'.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventAction = PayloadAction<unknown> | { [key: string]: any };

export enum BeaconActions {
  TRACK_PAGE_IMPRESSION_EVENT = 'track/TRACK_PAGE_IMPRESSION_EVENT',
  TRACK_LOGIN_SUCCESS_EVENT = 'track/TRACK_LOGIN_SUCCESS_EVENT',
  TRACK_INVOICE_DOWNLOAD_EVENT = 'track/TRACK_INVOICE_DOWNLOAD_EVENT',
  TRACK_MARKETING_CONSENT_EVENT = 'track/TRACK_MARKETING_CONSENT_EVENT',
  TRACK_STREAMER_ACADEMY_EVENT = 'track/TRACK_STREAMER_ACADEMY_EVENT',
  TRACK_STREAM_ENDED_EVENT = 'track/TRACK_STREAM_ENDED_EVENT',
  TRACK_PROFILE_IMAGE_VALIDATION_EVENT = 'track/TRACK_PROFILE_IMAGE_VALIDATION_EVENT',
}

export enum BeaconEvent {
  PAGE_IMPRESSION = 'pageImpression',
  LOGIN_SUCCESS = 'loginSuccess',
  CREATORS_ONBOARDING = 'creatorsOnboarding',
  SHOW_CREATED = 'showCreated',
  INVOICE_DOWNLOAD = 'invoiceDownload',
  MARKETING_CONSENT = 'marketingConsent',
  STREAMER_ACADEMY = 'streamerAcademy',
  STREAM_ENDED = 'streamEnded',
  PROFILE_IMAGE_VALIDATION = 'profileImageValidation',
}

export enum BeaconEventCategory {
  STREAMERS_ONBOARDING = 'Streamers Onboarding',
  SHOW_CREATED = 'Show Created',
}

export enum BeaconEventAction {
  ONBOARDING_STEPS = 'onboarding steps',
}

export enum BeaconOnboardingEventLabel {
  PROFILE_COMPLETED = 'step 1 | profile completion',
  SHOP_SETUP_COMPLETED = 'step 2 | shop password created',
  SAMPLES_ORDERED = 'step 3 | sample products ordered',
  SAMPLES_RECEIVED = 'step 4 | sample products received',
  FIRST_SHOW_STREAMED = 'step 5 | first show streamed',
}

export interface FirebaseEventParams {
  portal_type?: string;
  pageName?: string;
  eventCategory?: BeaconEventCategory;
  eventAction?: BeaconEventAction | string;
  eventLabel?: BeaconOnboardingEventLabel;
  dimension6?: string;
  streamers_onboarding_steps?: BeaconOnboardingEventLabel;
}

export interface FirebaseEvent {
  name: BeaconEvent;
  params?: FirebaseEventParams;
  userProperties?: Record<string, string | number | boolean | undefined>;
  userId?: string;
  streamId?: string;
  consentFunctional?: boolean;
  data?: Record<string, string>;
}

export enum MarketingConsentResults {
  SKIP_MARKETING_CONSENT = 'SKIP_MARKETING_CONSENT',
  DENIED_MARKETING_CONSENT = 'DENIED_MARKETING_CONSENT',
  ACCEPT_MARKETING_CONSENT = 'ACCEPT_MARKETING_CONSENT',
}

export enum ProfileImageValidation {
  SMALL_DIMENSIONS = 'SMALL_DIMENSIONS',
  WRONG_FORMAT = 'WRONG_FORMAT',
  LARGE_SIZE = 'LARGE_SIZE',
}
