import { LoadingSpinner } from '@hse24/shared-components';
import Icons from '../../../../../src/assets';
import Icon from '../../../../components/Icon/Icon';
import { PostUploadStatus } from '../../../model/post';
import styles from './UploadStatusIcon.module.scss';

interface IconStatusProps {
  status: PostUploadStatus;
}
export const UploadStatusIcon = (props: IconStatusProps) => {
  switch (props.status) {
    case PostUploadStatus.IN_PROGRESS:
      return (
        <div data-testid="loading-spinner">
          <LoadingSpinner className={styles.icon} />
        </div>
      );
    case PostUploadStatus.SUCCESS:
      return <Icon icon={Icons.success} dataTestId="success-icon" className={styles.icon} />;
    case PostUploadStatus.ERROR:
      return <Icon icon={Icons.error} dataTestId="error-icon" className={styles.icon} />;
  }
};
