import authClient from '../../auth/authClient';
import Config from '../../config/config';
import { AddressRecommendation } from '../model/addressRecommendation';

export interface AddressValidateRequest {
  firstName?: string;
  lastName?: string;
  salutation?: string;
  street?: string;
  streetNumber?: string;
  zipCode?: string;
  city?: string;
  addressAddition?: string;
  dateOfBirth?: string;
}

const defaultBaseUrl = Config.env.addressCheckServiceBaseUrl;

const addressCheckClient = (baseUrl: string) => authClient.createClient(`${baseUrl}`);

export const validateAddress = async (
  address: AddressValidateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<AddressRecommendation[]> => {
  const response = await addressCheckClient(baseUrl).post<AddressRecommendation[]>(
    `/address-checks`,
    address
  );
  return response.data;
};

const validateAddressApi = {
  validateAddress,
};

export default validateAddressApi;
