import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import { clearProductsSelection } from '../../../../products/state/productsSlice';
import theme from '../../../../theme/theme.module.scss';
import AdminAddShowForm from '../../../component/AdminAddShowForm/AdminAddShowForm';
import Loading from '../../../component/Loading/Loading';
import { ScheduleShowData } from '../../../model/shows';
import {
  adminUpdateShow,
  clearShow,
  clearShowDetails,
  fetchShow,
  fetchShowDetails,
  selectShowIsBeingLoaded,
  selectShowState,
} from '../../../state/showsSlice';
import styles from './UpdateShowPage.module.scss';

export const UpdateShowPage = () => {
  const { showId } = useParams<{ showId: string }>();
  const dispatch = useDispatch();

  const show = useSelector(selectShowState);
  const loading = useSelector(selectShowIsBeingLoaded);

  const submitClicked = useCallback(
    (values: ScheduleShowData) => {
      dispatch(adminUpdateShow({ showId, request: values }));
    },
    [showId]
  );

  useEffect(() => {
    dispatch(fetchShow(showId));
    dispatch(fetchShowDetails(showId));
    return () => {
      dispatch(clearProductsSelection());
      dispatch(clearShow());
      dispatch(clearShowDetails());
    };
  }, [showId]);

  if (loading) {
    return <Loading />;
  }

  if (!show) {
    return (
      <ErrorPageDialog
        errorDialogType={ErrorPageDialogType.ENTITY_NOT_FOUND}
        entityLabel="Stream"
      />
    );
  }

  return (
    <PageContainer
      pageToRender={
        <div className={styles.container}>
          <AdminAddShowForm isCreate={false} showToUpdate={show} submitCallback={submitClicked} />
        </div>
      }
      backgroundColor={theme.whiteLilac}
    />
  );
};

export default UpdateShowPage;
