import { AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import authClient from '../../auth/authClient';
import { PageableRequest } from '../../common/types';
import Config from '../../config/config';
import { Pageable } from '../../creators/components/DataGridTable/ServerSideDataGrid';
import { Product, Variant } from '../../products/model/product';
import { ProductResponse } from '../../products/model/productResponse';
import { ProductTileInfo } from '../../products/model/productTileInfo';
import { SearchResponse } from '../../products/model/searchResponse';
import { Stock, StockResponse } from '../../products/model/stockResponse';
import isProductInStock from '../../products/utils/isProductInStock';
import { get } from '../../shared/axiosClient';
import {
  ImageType,
  PastShowsWithCreatorsResponse,
  ShortLinkResponse,
  StreamedBy,
  TagsResponse,
} from '../model/shows';
import { Voucher } from '../model/voucher';
import {
  ArchivedShow,
  AvailableShowTimeSlotsResponse,
  CreateShowResponse,
  CreatorPastShowsResponse,
  CreatorUpcomingShowsResponse,
  GeneratePreSignedShowUrlResponse,
  SetShowImageRequest,
  ShowDetailsResponse,
  ShowRequest,
  ShowResponse,
  ShowsQueryParams,
  ShowUpdateRequest,
  StreamOwnerUpdateResponse,
  UpcomingShowsWithCreatorsResponse,
} from './showsRequestResponse';

const defaultBaseUrl = `${Config.env.socialLifeCommerceServiceBaseUrl}`;

const showsClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/shows`);
const adminShowsClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/admin/shows`);
const archivedShowsClient = (baseUrl: string) =>
  authClient.createClient(`${baseUrl}/archived-shows`);
const tagsClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/tags`);

const searchClient = (baseUrl: string) => authClient.createClient(`${baseUrl}`);

const getCreatorUpcomingShows = async (
  creatorId: string,
  params: PageableRequest,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorUpcomingShowsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<CreatorUpcomingShowsResponse>(`/upcoming/creator/${creatorId}`, {
      params: decamelizeKeys(params),
    })
  );
  return camelizeKeys(response.data) as CreatorUpcomingShowsResponse;
};

const getCreatorPastShows = async (
  creatorId: string,
  params: Pageable,
  baseUrl: string = defaultBaseUrl
): Promise<CreatorPastShowsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<CreatorPastShowsResponse>(`/past/creator/${creatorId}`, {
      params: decamelizeKeys({ page: params.page, pageSize: params.pageSize }),
    })
  );
  return camelizeKeys(response.data) as CreatorPastShowsResponse;
};

const createNewShow = async (
  showToSchedule: ShowRequest,
  baseUrl: string = defaultBaseUrl
): Promise<CreateShowResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).post<CreateShowResponse>('', showToSchedule)
  );
  return camelizeKeys(response.data) as CreateShowResponse;
};

const getProductsSearchResult = async (
  query?: string,
  options: {
    baseUrl?: string;
    page?: number;
    size?: number;
    sort?: string;
    countryCode?: string;
    salesChannel?: string;
  } = {}
): Promise<SearchResponse> => {
  const {
    baseUrl = Config.env.baseUrl,
    page = 1,
    size = 50,
    sort = 'NEWEST',
    countryCode = 'DE',
    salesChannel = 'CLASSIC_APP',
  } = options;

  const xClientId = '';
  const endpoint = `/search/search/products?query=${query}&page=${page}&country_code=${countryCode}&size=${size}&sort=${sort}&sales_channel=${salesChannel}&x_client_id=${xClientId}`;

  const response = await RefreshTokenUtil.wrap(() =>
    searchClient(baseUrl).get<AxiosResponse>(endpoint)
  );
  return camelizeKeys(response.data) as SearchResponse;
};

const getProductsDetailsByIds = async (ids: string[]): Promise<ProductTileInfo[]> => {
  const variants = ids.filter(id => id.length === 9);
  function mapProductToProductTileInfo(product: Product, stocks: Stock[]): ProductTileInfo {
    const searchedVariant = variants.find(variant => variant.startsWith(product.baseProductNo));
    const defaultVariantSku = searchedVariant ?? product.defaultVariantSku;
    const variant: Variant =
      product.variants.find(variant => defaultVariantSku === variant.sku) || product.variants[0];

    const media = searchedVariant ? variant.images : product.media;

    const imageMedia = media?.find(media => media.mediaType === 'image');

    const imageUri = imageMedia?.uri;

    const price = variant.price;
    const categoryPath = product.categoryPath ? product.categoryPath.split('/') : undefined;

    const brand = product.brand || {};
    const { productLineName, brandNameShort, brandName } = brand;

    const productStock = stocks.filter((stock: Stock) =>
      stock.sku.startsWith(product.baseProductNo)
    );

    const isInStock = isProductInStock(productStock, product.status);

    return {
      imageUri,
      price,
      categoryPath,
      brandName: productLineName || brandNameShort || brandName,
      baseProductNo: product.baseProductNo,
      variantId: searchedVariant,
      name: product.name.short || product.name.long,
      trackingName: product.headline || product.name.long,
      freeShipping: product.shipping?.freeShipping || false,
      isNewInShop: product.isNew || false,
      salesDriverLabel: product.salesdrivers ? product.salesdrivers[0]?.name : undefined,
      created: product?.created,
      outOfStock: !isInStock,
    };
  }

  const baseProductNumbers = ids.map(baseProductNo => baseProductNo.slice(0, 6));

  const filter = `base_product_no=${baseProductNumbers.join(',')}`;
  const productResponse = await get<AxiosResponse>(`${Config.env.pdsUrl}/products?${filter}`);

  const products = (camelizeKeys(productResponse.data) as ProductResponse).products || [];

  const stocks = await getStocks(baseProductNumbers);

  return products.map(product => mapProductToProductTileInfo(product, stocks));
};

const getStocks = async (baseProductNos: string[]): Promise<Stock[]> => {
  const filter = `base_product_no=${baseProductNos.join(',')}`;
  const stockResponse = await get<AxiosResponse>(`${Config.env.pdsUrl}/stock?${filter}`);

  return (camelizeKeys(stockResponse.data) as StockResponse).stock || [];
};

const getShowById = async (
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<ShowResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<ShowResponse>(`/${showId}?disable_cache=true`)
  );
  return camelizeKeys(response.data) as ShowResponse;
};

const updateShow = async (
  showId: string,
  showToUpdate: ShowUpdateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<ShowResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).put<ShowResponse>(`/${showId}`, showToUpdate)
  );
  return camelizeKeys(response.data) as ShowResponse;
};

const queryAdminUpcomingShows = async (
  queryParams: ShowsQueryParams,
  baseUrl: string = defaultBaseUrl
): Promise<UpcomingShowsWithCreatorsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<UpcomingShowsWithCreatorsResponse>(
      `/overview/upcoming?${toQueryString(queryParams)}`
    )
  );
  return camelizeKeys(response.data) as UpcomingShowsWithCreatorsResponse;
};

const queryAdminPastShows = async (
  queryParams: ShowsQueryParams,
  baseUrl: string = defaultBaseUrl
): Promise<PastShowsWithCreatorsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<PastShowsWithCreatorsResponse>(
      `/overview/past?${toQueryString(queryParams)}`
    )
  );
  return camelizeKeys(response.data) as PastShowsWithCreatorsResponse;
};

const toQueryString = (queryParams: ShowsQueryParams) =>
  new URLSearchParams({
    sort_field: queryParams.sortField,
    sort_order: queryParams.sortOrder.toUpperCase(),
    page_size: queryParams.pageSize.toString(),
    page: queryParams.page.toString(),
    from: queryParams.dateFrom,
    to: queryParams.dateTo,
    ...(queryParams.searchTerm && { search_term: queryParams.searchTerm }),
    ...(queryParams.audience && {
      audience: queryParams.audience.map(e => e.toString()).join(','),
    }),
    ...(queryParams.streamedBy && {
      streamed_by: queryParams.streamedBy.map(e => e.toString()).join(','),
    }),
    ...(queryParams.excludeEarlyBirds && {
      exclude_early_birds: queryParams.excludeEarlyBirds.toString(),
    }),
  }).toString();

const getShortLink = async (
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<ShortLinkResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<ShortLinkResponse>(`/${showId}/short-link`)
  );
  return camelizeKeys(response.data) as ShortLinkResponse;
};

const generatePreSignedUrl = async (
  showId: string,
  imageType: ImageType,
  fileExtension: string,
  creatorId?: string,
  baseUrl: string = defaultBaseUrl
): Promise<GeneratePreSignedShowUrlResponse> => {
  const creatorInfo = creatorId ? `/creator/${creatorId}` : '';
  const client = creatorId ? adminShowsClient : showsClient;
  const response = await RefreshTokenUtil.wrap(() =>
    client(baseUrl).post<GeneratePreSignedShowUrlResponse>(
      `/${showId}${creatorInfo}/images/pre-signed-url`,
      {
        imageType,
        fileExtension,
      }
    )
  );
  return camelizeKeys(response.data) as GeneratePreSignedShowUrlResponse;
};

const getShowDetails = async (
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<ShowDetailsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).get<ShowDetailsResponse>(`/${showId}/details`)
  );
  return camelizeKeys(response.data) as ShowDetailsResponse;
};

const endShow = async (
  creatorId: string,
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<void> => {
  return await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).post(`/creator/${creatorId}/shows/${showId}/end-show`)
  );
};

const highlightProducts = async (
  showId: string,
  baseProductNo: string[],
  baseUrl: string = defaultBaseUrl
): Promise<void> => {
  return await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).put(
      `/${showId}/metadata/products?base_product_no=${baseProductNo.join(',')}`
    )
  );
};

const setShowImage = async (
  showId: string,
  imageKey: string,
  imageType: ImageType,
  creatorId?: string,
  baseUrl: string = defaultBaseUrl
): Promise<void> => {
  const creatorInfo = creatorId ? `/creator/${creatorId}` : '';
  const client = creatorId ? adminShowsClient : showsClient;

  await RefreshTokenUtil.wrap(() =>
    client(baseUrl).put<SetShowImageRequest>(`/${showId}${creatorInfo}/images`, {
      imageKey,
      imageType,
    })
  );
};

const postShowVisibility = async (
  showId: string,
  isPublished: boolean,
  baseUrl: string = defaultBaseUrl,
  skipRefreshingToken = false
): Promise<number> => {
  const call = showsClient(baseUrl).post(`/${showId}/visibility`, { isPublished });
  const response: AxiosResponse = await (skipRefreshingToken
    ? call
    : RefreshTokenUtil.wrap(() => call));
  return response.status;
};

const getApprovedTags = async (baseUrl: string = defaultBaseUrl): Promise<TagsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    tagsClient(baseUrl).get<TagsResponse>('/approved')
  );
  return camelizeKeys(response.data) as TagsResponse;
};

const addProductsToShow = async (
  showId: string,
  baseProductsNo: string[],
  baseUrl: string = defaultBaseUrl
): Promise<ShowDetailsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    showsClient(baseUrl).post(`/${showId}/add-products`, { baseProductsNo })
  );
  return response.data as ShowDetailsResponse;
};

const deleteShow = async (showId: string, baseUrl: string = defaultBaseUrl): Promise<void> => {
  await RefreshTokenUtil.wrap(() => showsClient(baseUrl).delete(`/${showId}`));
};

const deleteShowByAdmin = async (
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<void> => {
  await adminShowsClient(baseUrl).delete(showId);
};

const updateStreamedBy = async (
  showId: string,
  streamedBy: StreamedBy,
  baseUrl: string = defaultBaseUrl
): Promise<{ streamedBy: StreamedBy }> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminShowsClient(baseUrl).patch<StreamOwnerUpdateResponse>(`/${showId}/streamOwner`, {
      streamedBy,
    })
  );
  return camelizeKeys(response.data) as StreamOwnerUpdateResponse;
};

const attachVoucherToShow = async (
  showId: string,
  voucherCode: string,
  baseUrl: string = defaultBaseUrl
): Promise<Voucher> => {
  const response = await showsClient(baseUrl).put<Voucher>(`/${showId}/add-voucher/${voucherCode}`);
  return camelizeKeys(response.data) as Voucher;
};

const detachVoucherFromShow = async (
  showId: string,
  baseUrl: string = defaultBaseUrl
): Promise<void> => {
  return await showsClient(baseUrl).put(`/${showId}/remove-voucher`);
};

const fetchArchivedShows = async (baseUrl: string = defaultBaseUrl): Promise<ArchivedShow[]> => {
  const axiosResponse = await archivedShowsClient(baseUrl).get('');
  return camelizeKeys(axiosResponse.data) as ArchivedShow[];
};

const fetchAvailableShowTimeSlots = async (
  date: string,
  baseUrl: string = defaultBaseUrl
): Promise<AvailableShowTimeSlotsResponse> => {
  const axiosResponse = await showsClient(baseUrl).get('/available-time-slots', {
    params: {
      date: date,
    },
  });
  return camelizeKeys(axiosResponse.data) as unknown as AvailableShowTimeSlotsResponse;
};

const adminCreateNewShow = async (
  showToSchedule: ShowRequest,
  baseUrl: string = defaultBaseUrl
): Promise<CreateShowResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminShowsClient(baseUrl).post<CreateShowResponse>('', showToSchedule)
  );
  return camelizeKeys(response.data) as CreateShowResponse;
};

const adminUpdateShow = async (
  showId: string,
  showToUpdate: ShowUpdateRequest,
  baseUrl: string = defaultBaseUrl
): Promise<ShowResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    adminShowsClient(baseUrl).put<ShowResponse>(`/${showId}`, showToUpdate)
  );
  return camelizeKeys(response.data) as ShowResponse;
};
const showsApi = {
  showsClient,
  tagsClient,
  createNewShow,
  getProductsSearchResult,
  getProductsDetailsByIds,
  getShowById,
  updateShow,
  getShortLink,
  generatePreSignedUrl,
  queryAdminUpcomingShows,
  queryAdminPastShows,
  getShowDetails,
  endShow,
  highlightProducts,
  setShowImage,
  postShowVisibility,
  getApprovedTags,
  addProductsToShow,
  deleteShow,
  getCreatorUpcomingShows,
  getCreatorPastShows,
  attachVoucherToShow,
  detachVoucherFromShow,
  fetchArchivedShows,
  updateStreamedBy,
  fetchAvailableShowTimeSlots,
  getStocks,
  deleteShowByAdmin,
  adminCreateNewShow,
  adminUpdateShow,
};

export default showsApi;
