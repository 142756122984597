import { Button, Link, Typography } from '@mui/material';
import DialogDragLine from '../../components/DialogDragLine/DialogDragLine';
import { featureFlagValue } from '../../config/featureFlagConfig';
import { AvailableFlag } from '../../config/FeatureFlags';
import PageContainer from '../../layout/PageContainer/PageContainer';
import BackToTop from '../../products/components/BackToTop/BackToTop';
import styles from './PrivacyPolicyPage.module.scss';

const toTop = () =>
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

const PrivacyPolicyPage = () => {
  const isApp = featureFlagValue(AvailableFlag.isInApp);

  const cookieDashboard = {
    __html: `Ihre Cookie-Einstellungen können Sie <button onclick="UC_UI.showSecondLayer();">hier</button> vornehmen.`,
  };

  const PrivacyPolicy = () => (
    <div className={styles.wrapper} id="privacy-policy">
      <DialogDragLine />
      <div>
        <Typography variant="h1" component="h1">
          Datenschutzinformationen für Streamer und Agencies
        </Typography>
        <Typography paragraph={true}>
          Die Home Shopping Europe GmbH (nachfolgend: „HSE“) ist ein Homeshopping- und
          E-Commerce-Unternehmen, das seine Produkte im Fernsehen, in der von HSE entwickelten
          Shopping App, in Livestreams und in anderen Kanälen präsentiert und verkauft. HSE setzt
          beim Vertrieb ihrer Produkte unter anderem auf den Bereich Social Commerce, um ihren
          Kunden, insbesondere über Streams ein unterhaltsames Einkaufserlebnis zu bieten. Im
          Mittelpunkt dieser Streams stehen die sog. „Streamer“, die HSE-Produkte präsentieren und
          die Agenturen, mit denen HSE zusammenarbeitet (die „Agency“ oder „Agencies“). Mit den
          nachfolgenden Datenschutzinformationen für Streamer und Agencies geben wir Ihnen einen
          Überblick über unsere Verfahren hinsichtlich der Erhebung, Speicherung, Nutzung,
          Offenlegung oder Löschung (nachfolgend zusammen „Verarbeiten“ oder „Verarbeitung“) von
          Informationen jeglicher Art über Sie (z. B. Name, Kontaktdaten, Social Media Profile,
          etc., nachfolgend zusammen „personenbezogene Daten”) im Rahmen Ihrer Zusammenarbeit mit
          HSE als Streamer oder einer Agency bzw. deren Inhaber oder Mitarbeiter.
        </Typography>
      </div>
      <div className={styles.index}>
        <Typography variant="h2" component="h2">
          Inhaltsverzeichnis
        </Typography>
        <ul>
          <li>
            <Link href="#A">A. Verantwortlicher für die Datenverarbeitung</Link>
            <ul>
              <li>
                <Link href="#A-I">I. Name und Kontaktdaten des Verantwortlichen</Link>
              </li>
              <li>
                <Link href="#A-II">
                  II. Kontaktdaten des Datenschutzbeauftragten des Verantwortlichen
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link href="#B">B. Informationen über die Verarbeitung personenbezogener Daten</Link>
            <ul>
              <li>
                <Link href="#B-I">I. Verarbeitung im Rahmen des Onboardings der Streamer</Link>
              </li>
              <li>
                <Link href="#B-II">II. Zusammenarbeit mit Agenturen</Link>
              </li>
              <li>
                <Link href="#B-III">III. Nutzung der Streamer-Plattform</Link>
                <ul>
                  <li>
                    <Link href="#B-III-1">1. Server-Protokolldaten</Link>
                  </li>
                  <li>
                    <Link href="#B-III-2">
                      2. Datenübermittlung in Drittländer außerhalb der EU (z. B. USA)
                    </Link>
                  </li>
                  <li>
                    <Link href="#B-III-3">3. Nutzerprofil</Link>
                  </li>
                  <li>
                    <Link href="#B-III-4">4. Videos und Insights – Bambuser</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link href="#B-IV">IV. Cookies</Link>
                <ul>
                  <li>
                    <Link href="#B-IV-1">1. Technisch notwendige Cookies</Link>
                    <ul>
                      <li>
                        <Link href="#B-IV-1-a">a) Google Tag Manager 360</Link>
                      </li>
                      <li>
                        <Link href="#B-IV-1-b">b) Usercentrics Consent Manager</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link href="#B-IV-2">2. Statistik-Cookies</Link>
                    <ul>
                      <li>
                        <Link href="#B-IV-2-a">a) Google Analytics 360</Link>
                      </li>
                      <li>
                        <Link href="#B-IV-2-b">b) Hotjar - Feedbacktool</Link>
                      </li>
                      <li>
                        <Link href="#B-IV-2-c">c) Amplitude (Analyse und A/B-Testing)</Link>
                      </li>
                    </ul>
                  </li>
                  {!isApp && (
                    <li>
                      <Link href="#B-IV-3">3. Cookie-Dashboard</Link>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link href="#C">C. Informationen über Rechte der betroffenen Personen</Link>
            <ul>
              <li>
                <Link href="#C-I">I. Recht auf Auskunft</Link>
              </li>
              <li>
                <Link href="#C-II">II. Recht auf Berichtigung</Link>
              </li>
              <li>
                <Link href="#C-III">III. Recht auf Löschung („Recht auf Vergessenwerden“)</Link>
              </li>
              <li>
                <Link href="#C-IV">IV. Recht auf Einschränkung der Verarbeitung</Link>
              </li>
              <li>
                <Link href="#C-V">V. Recht auf Datenübertragbarkeit</Link>
              </li>
              <li>
                <Link href="#C-VI">VI. Recht auf Widerspruch</Link>
                <ul>
                  <li>
                    <Link href="#C-VI-1">
                      1. Recht auf Widerspruch aus Gründen, die sich aus der besonderen Situation
                      der betroffenen Person ergeben
                    </Link>
                  </li>
                  <li>
                    <Link href="#C-VI-2">2. Recht auf Widerspruch gegen Direktwerbung</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link href="#C-VII">VII. Recht auf Widerruf von Einwilligungen</Link>
              </li>
              <li>
                <Link href="#C-VIII">VIII. Recht auf Beschwerde bei einer Aufsichtsbehörde</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link href="#D">
              D. Informationen zu den in diesen Datenschutzinformationen verwendeten Fachbegriffen
              der der Datenschutz-Grundverordnung
            </Link>
          </li>
          <li>
            <Link href="#E">E. Stand und Änderung dieser Datenschutzinformationen</Link>
          </li>
        </ul>
      </div>
      <div className={styles.content}>
        <div id="A">
          <Typography variant="h1" component="h1">
            A. Verantwortlicher für die Datenverarbeitung
          </Typography>
          <div id="A-I">
            <Typography variant="h2" component="h2">
              I. Name und Kontaktdaten des Verantwortlichen
            </Typography>
            <Typography paragraph={true}>
              Home Shopping Europe GmbH
              <br />
              Münchener Straße 101 h<br />
              85737 Ismaning
              <br />
              Deutschland
              <br />
              E-Mail: <Link href="mailto:service@hse.de">service@hse.de</Link>
              <br />
              Telefon: +49 (0)800 27 888 88
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="A-II">
            <Typography variant="h2" component="h2">
              II. Kontaktdaten des Datenschutzbeauftragten des Verantwortlichen
            </Typography>
            <Typography paragraph={true}>
              Home Shopping Europe GmbH
              <br />
              - Datenschutzbeauftragter -<br />
              Münchener Straße 101 h<br />
              85737 Ismaning, Deutschland
              <br />
              E-Mail: <Link href="mailto:datenschutz@hse.de">datenschutz@hse.de</Link>
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
        </div>
        <div id="B">
          <Typography variant="h1" component="h1">
            B. Informationen über die Verarbeitung personenbezogener Daten
          </Typography>
          <div id="B-I">
            <Typography variant="h2" component="h2">
              I. Verarbeitung im Rahmen des Onboardings der Streamer
            </Typography>
            <Typography paragraph={true}>
              (i) Um neue Streamer für sich zu gewinnen, arbeitet HSE mit Agenturen zusammen, die
              eine Vorprüfung im Hinblick auf die Geeignetheit der Streamer anhand verschiedener
              Kriterien wie mediales Talent / gute Ausstrahlung vor der Kamera, gute Erklärungen zu
              den Produkten, fließende, freie Rede, positive Ausstrahlung/Charisma, überzeugend und
              motivierend, hilfreich und ehrlich, gutes Storytelling, Kreativität vornehmen. Die
              Agenturen bewerten in eigener Verantwortung, welcher Streamer diesen Kriterien
              entspricht.
              <br />
              (ii) Ist die Vorprüfung abgeschlossen, übermittelt die jeweilige Agentur ein Profil
              des Streamers (Namen, die E-Mail-Adresse, Social Media Profile, Beispielsvideo) an
              HSE. Diese Daten verarbeitet HSE, um den Streamer persönlich ansprechen, einen ersten
              Eindruck gewinnen und eine Einladung für eine Video-Audition übermitteln zu können.
              Die bei der Audition verarbeiteten Videodaten werden nicht über die Video-Audition
              hinausgehend gespeichert.
              <br />
              (iii) Die Verarbeitung der personenbezogenen Daten erfolgt als vorvertragliche
              Maßnahme auf Basis von Art. 6 Absatz 1 Buchstabe b DS-GVO.
              <br />
              (iv) Die Daten werden HSE von der Agentur des Streamers zur Verfügung gestellt.
              <br />
              (v) Für die Video-Audition setzt HSE eine Software der Microsoft Ireland Operations
              Limited , One Microsoft Place, South County Business Park, Leopardstown, Dublin 18,
              Irland („Microsoft“) im Wege der Auftragsverarbeitung ein. Über Microsoft können Daten
              ggfs. in Länder außerhalb der EWR (USA) übermittelt werden. Wir haben mit Microsoft
              Standarddatenschutzklauseln und zusätzliche vertragliche Garantien vereinbart, um ein
              bestmögliches Datenschutzniveau beim Transfer sicherzustellen. Für Näheres zu den
              Garantien und zum Erhalt einer Kopie, kontaktieren Sie uns bitte.
              <br />
              (vi) Sofern zwischen dem Streamer und HSE kein Vertrag zustande kommen sollten, werden
              die im Rahmen des Bewerbungsverfahrens verarbeiteten personenbezogenen Daten nach 6
              Monaten gelöscht.
              <br />
              (vii) Die Bereitstellung von Daten ist nicht gesetzlich, aber für den vorvertraglichen
              Prozess erforderlich. Im Falle einer Nichtbereitstellung der Daten können wir die
              Geeignetheit des Streamers nicht ausreichend feststellen, sodass in der Regel kein
              Vertragsschluss zustande kommt.
              <br />
              (viii) Es findet keine automatisierte Entscheidungsfindung statt.
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="B-II">
            <Typography variant="h2" component="h2">
              II. Zusammenarbeit mit Agenturen
            </Typography>
            <Typography paragraph={true}>
              (i) Im Rahmen der Zusammenarbeit zwischen HSE und den Agenturen ist es erforderlich,
              dass HSE personenbezogene Daten der Agenturen bzw. der von ihnen benannten
              Beschäftigten verarbeitet.
              <br />
              (ii) Die verarbeiteten Daten sind:
            </Typography>
            <ul>
              <li>
                ▪ Ansprechpartnerdaten (wie Name, Telefonnummer, E-Mail-Adresse), um die
                Kontaktaufnahme für Rückfragen im Rahmen der Geschäftsbeziehung effizient zu
                gestalten.
              </li>
              <li>
                ▪ Agenturdaten (soweit sie Rückschlüsse auf eine natürliche Person zulassen, werden
                diese als personenbezogene Daten betrachtet), dazu gehören Name, Anschrift,
                Telefonnummer, Telefax, E-Mail-Adresse, Umsatz-Steuer-ID, Umsatzdaten,
                Bankverbindung
              </li>
            </ul>
            <Typography paragraph={true}>
              (iii) Die Verarbeitung der Ansprechpartnerdaten erfolgt auf Basis von Art. 6 Absatz 1
              Buchstabe f DS-GVO zur vereinfachten Kontaktaufnahme im Rahmen der Zusammenarbeit. Die
              Verarbeitung der Agenturdaten erfolgt zur Erfüllung des Vertrages zwischen der Agentur
              und HSE gemäß Art. 6 Absatz 1 Buchstabe b DS-GVO.
              <br />
              (iv) Die Daten werden HSE von der Agentur zur Verfügung gestellt.
              <br />
              (v) HSE setzt Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855
              Luxembourg („AWS“), für die Speicherung der Daten im Wege der Auftragsverarbeitung
              ein. Über AWS können Daten in Einzelfällen in Länder außerhalb der EWR (USA)
              übermittelt werden. Wir haben mit AWS Standarddatenschutzklauseln abgeschlossen und
              zusätzliche technische Sicherheitsmaßnahmen getroffen, um ein bestmögliches
              Datenschutzniveau beim Transfer sicherzustellen. Für Näheres zu den Garantien und zum
              Erhalt einer Kopie, kontaktieren Sie uns bitte.
              <br />
              (vi) Die Daten werden nach Ablauf der gesetzlichen Aufbewahrungsfristen gemäß § 147
              AO, § 257 HGB und damit spätestens nach 10 Jahren nach Beendigung der
              Geschäftsbeziehung gelöscht.
              <br />
              (vii) Die Bereitstellung von Daten ist nicht gesetzlich, aber für die Erfüllung der
              gegenseitigen vertraglichen Verpflichtungen erforderlich. Im Falle einer
              Nichtbereitstellung der Daten können wir den Vertrag insbesondere auch
              Zahlungsverpflichtungen nicht ausreichend erfüllen.
              <br />
              (viii) Es findet keine automatisierte Entscheidungsfindung statt.
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="B-III">
            <Typography variant="h2" component="h2">
              III. Nutzung der Streamer-Plattform
            </Typography>
            <div id="B-III-1">
              <Typography variant="h3" component="h3">
                1. Server-Protokolldaten
              </Typography>
              <Typography paragraph={true}>
                Bei der Nutzung der Webseite werden durch den auf Ihrem Endgerät zum Einsatz
                kommenden Browser technisch bedingt bestimmte Informationen an den Server unserer
                Webseite gesendet. Diese Daten werden auf unserem Server gespeichert und
                verarbeitet.
                <br />
                (i) Wir verarbeiten die nachfolgend genannten Daten zum Zweck der Bereitstellung der
                von Ihnen aufgerufenen Inhalte der Webseite, zur Gewährleistung der Sicherheit der
                eingesetzten IT-Infrastruktur, zur Fehlerbehebung, zur Ermöglichung und
                Vereinfachung der Suche auf der Webseite und zur Verwaltung von Cookies.
                <br />
                (ii) Die verarbeiteten Daten sind:
              </Typography>
              <ul>
                <li>
                  ▪ HTTP-Daten
                  <br />
                  Bei HTTP-Daten handelt es sich um Protokolldaten, die beim Aufruf der Webseite
                  über das Hypertext Transfer Protocol (Secure) (HTTP(S)) technisch bedingt
                  anfallen: Hierzu zählen IP-Adresse, Typ und Version Ihres Internet-Browsers,
                  verwendetes Betriebssystem, die aufgerufene Seite, die zuvor besuchte Seite
                  (Referrer URL), Datum und Uhrzeit des Abrufs. HTTP(S)-Daten fallen auch auf
                  Servern von Dienstleistern an (z.B. bei Abruf von Drittinhalten).
                </li>
                <li>
                  ▪ Suchfunktion-Daten
                  <br />
                  Hierbei handelt es sich um Daten, die Sie in die Suchfunktionen unserer Webseite
                  eingeben: Hierzu zählen alle Informationen, die Sie als Suchbegriffe im jeweiligen
                  Suchformular der Webseite eingeben.
                </li>
                <li>
                  ▪ Cookie-Einstellungs-Daten
                  <br />
                  Hiervon umfasst sind die Daten, die Sie zur Verwaltung der Cookie-Einstellungen
                  für diese Webseite bereitstellen und Daten, die Ihrem Endgerät bei der Verwendung
                  der Funktion zur Verwaltung der Cookie-Einstellungen zugewiesen werden: Hierzu
                  zählen Ihre Einwilligungen, Ihre Widersprüche (Opt-Out) und gegebenenfalls Ihre
                  individuelle Auswahl für den Einsatz von Cookies auf Ihrem Endgerät.
                </li>
                <li>
                  ▪ Fehlerdaten
                  <br />
                  Hierbei handelt es sich um Fehlermeldungen des Servers und einzelner Anwendungen,
                  die gespeichert werden.
                </li>
              </ul>
              <Typography paragraph={true}>
                (iii) Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse gemäß
                Artikel 6 Absatz 1 Buchstabe f DS-GVO. Unser berechtigtes Interesse besteht in der
                Bereitstellung der durch den Nutzer aufgerufenen Inhalte und Such-Funktionen der
                Webseite und die Verwaltung der vom Nutzer vorgenommenen Cookie-Einstellungen und
                zudem in der Gewährleistung der Sicherheit der für die Bereitstellung der Webseite
                eingesetzten IT-Infrastruktur, insbesondere zur Feststellung, Beseitigung und
                beweissicheren Dokumentation von Störungen (z.B. DDoS-Angriffen).
                <br />
                (iv) Die Daten werden aktiv vom Nutzer selbst oder automatisch durch den Browser des
                Nutzers zur Verfügung gestellt.
                <br />
                (v) Empfänger der personenbezogenen Daten sind IT-Dienstleister (z.B. Hosting),
                welche wir im Rahmen einer Vereinbarung zur Auftragsverarbeitung einsetzen. Dazu
                zählen unter anderem auch Sentry (Fehleranalyse) und Akamai (Auslieferung von
                Inhalten). Beim Einsatz der Dienstleister können Daten in Drittländer außerhalb der
                EU übermittelt werden. Der Datentransfer erfolgt auf Basis von Art. 6 Abs. 1 Buchst.
                f, 49 Abs. 1 S. 1 Buchst. d DS-GVO ist zwingend zur Gewährleistung der Sicherheit
                unserer Webseite und Ihrer Nutzerdaten erforderlich.
                <br />
                (vi) Daten werden in Server-Log-Dateien in einer Form, die die Identifizierung der
                betroffenen Personen ermöglicht, maximal für einen Zeitraum von 14 Tagen
                gespeichert, es sei denn, dass ein sicherheitsrelevantes Ereignis auftritt (z.B. ein
                DDoS-Angriff). Im Fall eines sicherheitsrelevanten Ereignisses werden
                Server-Log-Dateien bis zur Beseitigung und vollständigen Aufklärung des
                sicherheitsrelevanten Ereignisses gespeichert. Daten der Suche werden automatisch
                nach spätestens 24 Stunden gelöscht. Angaben zur Cookie-Verwaltung werden nach 6
                Monaten gelöscht.
                <br />
                (vii) Ohne Preisgabe personenbeziehbarer Daten wie z.B. der IP-Adresse ist die
                Nutzung der Webseite nicht möglich. Eine Kommunikation über die Webseite ohne
                Angaben von Daten ist technisch nicht möglich.
                <br />
                (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                <br />
              </Typography>
              <Button onClick={() => toTop()} color="primary">
                Nach oben
              </Button>
            </div>
            <div id="B-III-2">
              <Typography variant="h3" component="h3">
                2. Datenübermittlung in Drittländer außerhalb der EU (z. B. USA)
              </Typography>
              <Typography paragraph={true}>
                (i) Wir übermitteln Ihre Daten im Rahmen bestimmter Analysetools in Drittländer
                außerhalb der EU (z. B. USA), wie in dieser Datenschutzerklärung beschrieben. Sie
                finden jeweils einen Hinweis bei der entsprechenden Erklärung eines Analysetools.
                <br />
                (ii) Der Datentransfer erfolgt auf Basis Ihrer ausdrücklichen Einwilligung gemäß
                Art. 6 Abs. 1 Buchst. a, 49 Abs. 1 S. 1 Buchst. a DS-GVO, soweit nicht andere
                Rechtsgrundlagen in den Beschreibungen der einzelnen Tools benannt werden.
                <br />
                (iii) Es liegt kein datenschutzrechtlicher Angemessenheitsbeschluss seitens der
                Europäischen Kommission für die USA und eine Vielzahl weiterer Länder außerhalb der
                EU vor. Zudem sind keine geeigneten Garantien für diese Länder nach den
                Anforderungen der DS-GVO gegeben. Eine Rechtsdurchsetzung ist möglicherweise nicht
                erfolgsversprechend. Es besteht für Sie das Risiko, dass staatliche Stellen auf
                personenbezogene Daten zugreifen, ohne dass wir und/oder Sie davon erfahren. Dies
                kann bereits bei der Übermittlung als solche geschehen.
                <br />
                (iv) Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen.
                Nutzen Sie hierfür bitte den Opt-Out-Link des jeweiligen Tools oder deaktivieren Sie
                die Schieberegler im Cookie-Dashboard.
                <br />
              </Typography>
              <Button onClick={() => toTop()} color="primary">
                Nach oben
              </Button>
            </div>
            <div id="B-III-3">
              <Typography variant="h3" component="h3">
                3. Nutzerprofil
              </Typography>
              <Typography paragraph={true}>
                (i) Die Streamer-Plattform ermöglicht es den Streamern, welche die Audition bei HSE
                erfolgreich durchlaufen haben, den Vertrag mit HSE zu schließen, ihre Vertragsdaten
                zu verwalten, ihre Shows zu steuern, sich ein Nutzerprofil anzulegen und
                Informationen über Zahlungen zu erhalten. Mit der Zustimmung des Streamers kann
                gleichermaßen dessen Agentur den Account managen. Zusätzlich kann die Agentur die
                eigene Geschäftsbeziehung mit HSE über die Plattform verwalten.
                <br />
                (ii) Die verarbeiteten Daten sind:
                <br />
                Streamer
              </Typography>
              <ul>
                <li>▪ Log-In-Daten: E-Mail-Adresse und Passwort</li>
                <li>
                  ▪ Name, Künstlername, Anschrift, Telefonnummer, Geburtsdatum, E-Mail-Adresse,
                  weitere Kontaktdaten, Agentur, Streamer ID, Umsatzsteuer-ID zur Vertragserfüllung,
                  Bankverbindungsdaten
                </li>
                <li>▪ Webseite des Streamers, Social-Media-Profile und Profilbild</li>
                <li>▪ Show-ID, Titel der Show, Datum, Uhrzeit, Likes, Showkategorie</li>
                <li>▪ Offene Zahlungen, erhaltene Zahlungen</li>
              </ul>
              <Typography paragraph={true}>Agentur</Typography>
              <ul>
                <li>▪ Log-In-Daten: E-Mail-Adresse und Passwort</li>
                <li>▪ Weitere unter B. II. genannte Daten</li>
              </ul>
              <Typography paragraph={true}>
                (iii) Die Verarbeitung der personenbezogenen Daten erfolgt zur Erfüllung des
                Vertrages zwischen dem Streamer, den Agenturen und HSE gemäß Art. 6 Absatz 1
                Buchstabe b DS-GVO. Soweit der Streamer der Agentur einen Zugriff auf das
                Nutzerprofil gewährt, erfolgt dies freiwillig auf Basis einer Einwilligung gemäß
                Art. 6 Abs. 1 Buchst. a DS-GVO. Die Einwilligung kann jederzeit widerrufen werden.
                Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit, der aufgrund der
                Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
                <br />
                (iv) Die Daten werden HSE vom Streamer bzw. von dessen Agentur zur Verfügung
                gestellt.
                <br />
                (v) HSE setzt Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855
                Luxembourg („AWS“), für die Speicherung der Daten im Wege der Auftragsverarbeitung
                ein. Über AWS können Daten in Einzelfällen in Länder außerhalb der EWR (USA)
                übermittelt werden. Wir haben mit AWS Standarddatenschutzklauseln abgeschlossen und
                zusätzliche technische Sicherheitsmaßnahmen getroffen, um ein bestmögliches
                Datenschutzniveau beim Transfer sicherzustellen. Für Näheres zu den Garantien und
                zum Erhalt einer Kopie, kontaktieren Sie uns bitte.
                <br />
                (vi) Das Nutzerprofil wird nach Ende der Geschäftsbeziehung gelöscht. Daten werden
                nach Ablauf der gesetzlichen Aufbewahrungsfristen gemäß § 147 AO, § 257 HGB und
                damit spätestens nach 10 Jahren nach Beendigung der Geschäftsbeziehung gelöscht.
                <br />
                (vii) Die Bereitstellung von Daten ist nicht gesetzlich, aber für die Erfüllung der
                gegenseitigen vertraglichen Verpflichtungen erforderlich. Im Falle einer
                Nichtbereitstellung der Daten können wir den Vertrag insbesondere auch
                Zahlungsverpflichtungen nicht ausreichend erfüllen.
                <br />
                (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                <br />
              </Typography>
              <Button onClick={() => toTop()} color="primary">
                Nach oben
              </Button>
            </div>
            <div id="B-III-4">
              <Typography variant="h3" component="h3">
                4. Videos und Insights – Bambuser
              </Typography>
              <Typography paragraph={true}>
                (i) Die Streamer präsentieren die HSE-Produkte im Wege von Livestreams und als
                Videos on demand auf verschiedenen Kanälen (z. B. HSE-App, -Webseite, Social Media
                Kanäle). Die Software hierfür stellt der Dienstleister Bambuser AB, Regeringsgatan
                29, 111 53 Stockholm, Schweden („Bambuser“) bereit. Zusätzlich analysiert Bambuser
                die Aktivitäten der Zuschauer der Videos, sodass den Streamern aggregierte
                Auswertungen in ihrem Nutzerprofil zur Verfügung gestellt werden.
                <br />
                (ii) Die verarbeiteten Daten sind:
              </Typography>
              <ul>
                <li>▪ Videodaten</li>
                <li>▪ User-ID um die Zuschauerdaten dem Streamer zuordnen zu können</li>
                <li>▪ Streamzeit der jeweiligen Show und Durchschnitt der letzten 30 Tage</li>
                <li>▪ Durchschnittliche Zuschaueranzahl der letzten 30 Tage</li>
                <li>▪ Umsatz der letzten 30 Tage, Umsatz gesamt</li>
                <li>▪ Anzahl der Bestellungen</li>
                <li>▪ Anzahl der Likes</li>
              </ul>
              <Typography paragraph={true}>
                (iii) Die Verarbeitung der Videodaten erfolgt zur Erfüllung des Vertrages gemäß Art.
                6 Absatz 1 Buchstabe b DS-GVO. Die Zuordnung der Zuschaueranalysen und Kaufanalysen
                zum jeweiligen Streamer erfolgt auf Basis des berechtigten Interesse gemäß Art. 6
                Absatz 1 Buchst. f DS-GVO von HSE an der Optimierung der Produktpräsentation durch
                die Streamer.
                <br />
                (iv) Die Daten werden durch den Browser des Nutzers zur Verfügung gestellt.
                <br />
                (v) HSE setzt Bambuser für die Bereitstellung der Videos im Wege der
                Auftragsverarbeitung ein. Über Bambuser können Daten ggfs. in Länder außerhalb der
                EWR (USA) übermittelt werden. Wir haben haben unseren Dienstleister beauftragt und
                bevollmächtigt mit den Subunternehmern die Standarddatenschutzklauseln zu
                vereinbaren und darüber hinaus ergänzenden vertragliche Sicherheiten vereinbart. Für
                Näheres zu den Garantien und zum Erhalt einer Kopie, kontaktieren Sie uns bitte.
                <br />
                (vi) Das Nutzerprofil wird nach Ende der Geschäftsbeziehung gelöscht. Daten werden
                nach Ablauf der gesetzlichen Aufbewahrungsfristen gemäß § 147 AO, § 257 HGB und
                damit spätestens nach 10 Jahren nach Beendigung der Geschäftsbeziehung gelöscht.
                <br />
                (v) Die Daten werden nach einem Zeitraum von einem Jahr gelöscht.
                <br />
                (vii) Die Bereitstellung der Videodaten ist nicht gesetzlich vorgeschrieben. Nach
                dem Vertrag erfolgt die Vergütung jedoch nur, wenn auch die Videodaten zur Verfügung
                gestellt werden. Im Falle einer Nichtbereitstellung der Daten kann keine Vergütung
                erfolgen.
                <br />
                (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                <br />
              </Typography>
              <Button onClick={() => toTop()} color="primary">
                Nach oben
              </Button>
            </div>
          </div>
          <div id="B-IV">
            <Typography variant="h2" component="h2">
              IV. Cookies
            </Typography>
            <div>
              <Typography paragraph={true}>
                Im Zusammenhang mit der Webseite und der auf der Webseite bereitgestellten Angebote
                setzen wir Cookies ein. Cookies sind kleine Textdateien mit Informationen, die bei
                dem Besuch einer Webseite über den Browser auf dem Endgerät des Nutzers gespeichert
                werden können. Bei einem erneuten Aufruf der Webseite mit demselben Endgerät können
                die in Cookies gespeicherten Informationen ausgelesen und verarbeitet werden. Dabei
                nutzen wir Verarbeitungs- und Speicherfunktionen des Browsers Ihres Endgeräts und
                erheben Informationen aus dem Speicher des Browsers Ihres Endgeräts.
                <br />
                Bei Cookies gibt es verschiedene Unterscheidungsmöglichkeiten:
              </Typography>
              <ul>
                <li>
                  ▪ Zum einen die Unterscheidung in First- und Third-Party-Cookies, je nachdem,
                  woher ein Cookie stammt:
                  <br />
                  First-Party-Cookies sind Cookies, die von dem Betreiber der Webseite als für die
                  Verarbeitung Verantwortlichem oder von einem von diesem beauftragten
                  Auftragsverarbeiter gesetzt und abgerufen werden. Third-Party-Cookies sind
                  Cookies, die von anderen für die Verarbeitung Verantwortlichen als dem Betreiber
                  der Webseite gesetzt und abgerufen werden, die nicht als Auftragsverarbeiter im
                  Auftrag des Betreibers der Webseite tätig sind
                </li>
                <li>
                  ▪ Zudem kann man zwischen transienten und persistenten Cookies, abhängig von der
                  Gültigkeitsdauer, unterscheiden:
                  <br />
                  Transiente Cookies (Sitzungs-Cookies) sind Cookies, die automatisch gelöscht
                  werden, wenn Sie Ihren Browser schließen. Persistente Cookies sind Cookies, die
                  nach dem Schließen des Browsers für einen bestimmten Zeitraum auf Ihrem Endgerät
                  gespeichert bleiben.
                </li>
                <li>
                  ▪ Außerdem kann zwischen einwilligungsfreien und einwilligungsbedürftigen Cookies
                  unterschieden werden:
                  <br />
                  Abhängig von ihrer Funktion und ihrem Einsatzzweck kann für den Einsatz bestimmter
                  Cookies eine Einwilligung des Nutzers erforderlich sein. Insoweit lassen sich
                  Cookies danach unterscheiden, ob für deren Einsatz eine Einwilligung des Nutzers
                  erforderlich ist.
                </li>
              </ul>
              <Typography paragraph={true}>
                Die Erteilung Ihrer Einwilligung erfolgt mittels eines sog. „Cookie Banners“:
                <br />
                Bei dem Aufruf unserer Webseite blenden wir ein sog. „Cookie-Banner“ ein. In unserem
                Cookie-Banner können Sie durch Betätigung der Schaltfläche „Akzeptieren“ Ihre
                Einwilligung für den Einsatz aller einwilligungsbedürftiger Cookies auf dieser
                Webseite erklären. Ohne eine solche Einwilligung werden die einwilligungsbedürftigen
                Cookies nicht aktiviert. Durch Betätigung der Schaltfläche „Ablehnen“ können Sie den
                Einsatz einwilligungsbedürftiger Cookie auch vollständig ablehnen. Diese
                Entscheidung wird in einem Cookie gespeichert. Alternativ haben Sie die Möglichkeit,
                durch Klick auf die Schaltfläche „Individuelle Einstellungen“ in unser
                „Cookie-Dashboard“ zu gelangen. Im Cookie-Dashboard können Sie eine individuelle
                Auswahl von Cookies vornehmen und zu einem späteren Zeitpunkt individuell
                anzupassen. Ihre Cookie-Einstellungen speichern wir in Form eines Cookies auf Ihrem
                Endgerät, um bei einem erneuten Aufruf der Webseite festzustellen, ob Sie bereits
                Cookie-Einstellungen vorgenommen haben.
                <br />
                Für die Funktion der Webseite erforderliche Cookies können über die
                Cookie-Verwaltungsfunktion dieser Webseite nicht deaktiviert werden. Sie können
                diese Cookies aber jederzeit allgemein in Ihrem Browser deaktivieren. Verschiedene
                Browser bieten unterschiedliche Wege, um die Cookie-Einstellungen im Browser zu
                konfigurieren. Weitere ausführliche Informationen hierzu finden Sie beispielsweise
                auf&nbsp;
                <Link href="http://www.allaboutcookies.org/ge/cookies-verwalten/" target="_blank">
                  http://www.allaboutcookies.org/ge/cookies-verwalten/
                </Link>
                . Wir möchten Sie jedoch darauf hinweisen, dass möglicherweise einige Funktionen der
                Webseite nicht oder nicht mehr ordnungsgemäß funktionieren, wenn Sie Cookies in
                Ihrem Browser allgemein deaktivieren.
                <br />
              </Typography>
              <Button onClick={() => toTop()} color="primary">
                Nach oben
              </Button>
            </div>
            <div id="B-IV-1">
              <Typography variant="h3" component="h3">
                1. Technisch notwendige Cookies
              </Typography>
              <div>
                <div id="B-IV-1-a">
                  <Typography variant="h4" component="h4">
                    a) Google Tag Manager 360
                  </Typography>
                  <Typography paragraph={true}>
                    Wir setzen auf unserer Webseite den Google Tag Manager 360 (im Folgenden:
                    „Google Tag Manager“) ein. Der Google Tag Manager ermöglicht es uns, Cookies zu
                    verwalten und deren Ausspielung zu steuern. Wir können dadurch z.B. Ihre
                    Einwilligung, einen Widerruf der Einwilligung oder ein Opt-Out umsetzen. Der
                    Google Tag Manager setzt keine eigenen Cookies und verarbeitet auch keine in
                    Cookies gespeicherten Daten.
                    <br />
                    (i) Zweck der Datenverarbeitung ist es, das Ausspielen von Cookies auf unserer
                    Webseite zu steuern, sowie die Gewährleistung der Sicherheit der Anwendung.
                    <br />
                    (ii) Die verarbeiteten Daten sind:
                  </Typography>
                  <ul>
                    <li>
                      ▪ HTTP-Daten
                      <br />
                      Bei HTTP-Daten handelt es sich um Protokolldaten, die beim Aufruf der Webseite
                      über das Hypertext Transfer Protocol (Secure) (HTTP(S)) technisch bedingt
                      anfallen. Hierzu zählen IP-Adresse, Typ und Version Ihres Internet-Browsers,
                      verwendetes Betriebssystem, die aufgerufene Seite, die zuvor besuchte Seite
                      (Referrer URL), Datum und Uhrzeit des Abrufs.
                    </li>
                  </ul>
                  <Typography paragraph={true}>
                    (iii) Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse
                    gemäß Artikel 6 Absatz 1 Buchstabe f DS-GVO an der einfachen und zuverlässigen
                    Steuerung von Cookies.
                    <br />
                    (iv) Die Daten werden automatisch durch den Browser des Nutzers zur Verfügung
                    gestellt.
                    <br />
                    (v) Empfänger der Daten ist Google Ireland Limited, Google Building Gordon
                    House, 4 Barrow St, Dublin, D04 E5W5, Irland als unser Auftragsverarbeiter.
                    Google Ireland Limited setzt die Google LLC in den USA (1600 Amphitheatre
                    Parkway, Mountain View, CA 94043, USA) als Dienstleister ein. Die Daten werden
                    nur übermittelt, sofern Sie uns hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1
                    Buchst. a, 49 Abs. 1 S. 1 Buchst. a DSGVO erteilt haben. Entsprechende Hinweise
                    zu Risiken derartiger Datenübermittlungen und Widerrufsmöglichkeiten finden Sie
                    unter B. III. 2. Wir haben den Tag Manager so auf unserer Webseite integriert,
                    dass ohne Ihre Einwilligung lediglich eine Referenz zu einem leeren Tag Manager
                    Container initiiert wird. Dabei werden keine personenbezogenen Daten an Google
                    übermittelt (lediglich Standard HTTP request logs, keine IP-Adresse oder andere
                    nutzerspezifische Daten, die eine Identifizierung Ihrer Person ermöglichen). Das
                    Auslesen des Containers und damit ein Austausch personenbezogener Daten mit
                    Google findet erst dann statt, wenn Sie Ihre Einwilligung erteilt haben.
                    <br />
                    (vi) Serverprotokolldaten werden gelöscht, wenn sie für die Zwecke der
                    Verarbeitung nicht mehr erforderlich sind.
                    <br />
                    (vii) Ohne Preisgabe personenbeziehbarer Daten wie z.B. der IP-Adresse ist die
                    Nutzung der Webseite nicht möglich. Eine Kommunikation über die Webseite ohne
                    Angaben von Daten ist technisch nicht möglich.
                    <br />
                    (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                    <br />
                  </Typography>
                  <Button onClick={() => toTop()} color="primary">
                    Nach oben
                  </Button>
                </div>
                <div id="B-IV-1-b">
                  <Typography variant="h4" component="h4">
                    b) Usercentrics Consent Manager
                  </Typography>
                  <Typography paragraph={true}>
                    Wir nutzen den Usercentrics Consent Manager, um Ihre Einwilligungen, mögliche
                    Widerrufe von Einwilligungen und Widersprüche gegen den Einsatz von Cookies zu
                    verwalten.
                    <br />
                    (i) Zweck der Datenverarbeitung ist die Verwaltung von Nutzerentscheidungen zu
                    Cookies (Einwilligung, Widerruf, Opt-out), sowie die Gewährleistung der
                    Sicherheit der Anwendung.
                    <br />
                    (ii) Die verarbeiteten Daten sind:
                  </Typography>
                  <ul>
                    <li>
                      ▪ HTTP-Daten
                      <br />
                      Bei HTTP-Daten handelt es sich um Protokolldaten, die beim Aufruf der Webseite
                      über das Hypertext Transfer Protocol (Secure) (HTTP(S)) technisch bedingt
                      anfallen: Hierzu zählen IP-Adresse, Typ und Version Ihres Internet-Browsers,
                      verwendetes Betriebssystem, die aufgerufene Seite, die zuvor besuchte Seite
                      (Referrer URL), Datum und Uhrzeit des Abrufs.
                    </li>
                    <li>
                      ▪ Einwilligungs-Cookies
                      <br />
                      Entscheidung des Nutzers zu einzelnen Cookies oder Gruppen von Cookies.
                      Zeitpunkt der Entscheidung und des letzten Besuchs.
                    </li>
                  </ul>
                  <Typography paragraph={true}>
                    (iii) Rechtsgrundlage für die Verarbeitung ist unser berechtigtes Interesse
                    gemäß Artikel 6 Absatz 1 Buchstabe f DS-GVO an der einfachen und zuverlässigen
                    Steuerung von Cookies.
                    <br />
                    (iv) Die Daten werden aktiv vom Nutzer (Entscheidung zu den Cookies) oder
                    automatisch durch den Browser des Nutzers (Protokolldaten, Zeitstempel) zur
                    Verfügung gestellt.
                    <br />
                    (v) Empfänger der Daten ist die Usercentrics GmbH, Rosental 4, 80331 München,
                    als unser Auftragsverarbeiter.
                    <br />
                    (vi) Der Widerruf einer zuvor erteilten Einwilligung wird drei Jahre lang
                    aufbewahrt (Rechenschaftspflicht). Das Verwaltungscookie wird 6 Monate nach dem
                    letzten Besuch gelöscht. Serverprotokolldaten werden vor der Speicherung
                    anonymisiert.
                    <br />
                    (vii) Ohne Preisgabe personenbeziehbarer Daten wie z.B. der IP-Adresse ist die
                    Nutzung der Webseite nicht möglich. Eine Kommunikation über die Webseite ohne
                    Angaben von Daten ist technisch nicht möglich.
                    <br />
                    (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                    <br />
                  </Typography>
                  <Button onClick={() => toTop()} color="primary">
                    Nach oben
                  </Button>
                </div>
              </div>
            </div>
            <div id="B-IV-2">
              <Typography variant="h3" component="h3">
                2. Statistik-Cookies
              </Typography>
              <div>
                <div id="B-IV-2-a">
                  <Typography variant="h4" component="h4">
                    a) Google Analytics 360
                  </Typography>
                  <Typography paragraph={true}>
                    Wenn Sie hierzu Ihre Einwilligung erteilt haben, setzen wir auf unserer Webseite
                    das Web-Analyse-Werkzeug Google Analytics 360 (im Folgenden: „Google Analytics“)
                    ein. Mithilfe von Google Analytics können wir das Nutzerverhalten der Besucher
                    unserer Webseite in pseudonymisierter und anonymisierter Form untersuchen.
                    <br />
                    Sie können die Datenverarbeitung durch Google Analytics jederzeit in unserem
                    „Cookie-Dashboard“ deaktivieren. Alternativ können Sie ein Browser-Plug-In von
                    Google installieren, welches die Datenerhebung durch Google Analytics
                    verhindert:&nbsp;
                    <Link href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
                      http://tools.google.com/dlpage/gaoptout?hl=de
                    </Link>
                    . Alternativ können Sie die Speicherung von Cookies in den Einstellungen Ihres
                    Browsers verbieten.
                    <br />
                    (i) Zweck der Datenverarbeitung ist die Steigerung der Effizienz unseres
                    Ressourceneinsatzes für unser Webangebot und unsere Werbemaßnahmen, die
                    Ausgestaltung der Plattform durch (nutzungsbasierte) Optimierung unseres
                    Webangebots durch Messung der Nutzung unseres Webangebots und (nutzungsbasierte)
                    Optimierung unserer Werbemaßnahmen durch Messung des Erfolgs der von uns
                    eingesetzten Werbemittel in unserem Webangebot (Werbemittelerfolgskontrolle).
                    Wir können keine Rückschlüsse auf das Verhalten einer konkreten Person ziehen.
                    Weitere Informationen zu Google Signals erhalten Sie unter:
                    <br />
                    <Link
                      href="https://support.google.com/analytics/answer/7532985?hl=de#signedin"
                      target="_blank"
                    >
                      https://support.google.com/analytics/answer/7532985?hl=de#signedin
                    </Link>
                    <br />
                    (ii) Die verarbeiteten Daten sind:
                  </Typography>
                  <ul>
                    <li>
                      ▪ Google Analytics HTTP-Daten
                      <br />
                      Hierbei handelt es sich um Protokolldaten, die beim Einsatz des auf der
                      Webseite verwendeten Web-Analyse-Werkzeugs Google Analytics über das Hypertext
                      Transfer Protocol (Secure) (HTTP(S)) technisch bedingt anfallen: Hierzu zählen
                      IP-Adresse, Typ und Version Ihres Internet-Browsers, verwendetes
                      Betriebssystem, die aufgerufene Seite, die zuvor besuchte Seite (Referrer
                      URL), Datum und Uhrzeit des Abrufs.
                    </li>
                    <li>
                      ▪ Google Analytics-Endgeräte-Daten
                      <br />
                      Daten, die durch das Web-Analyse-Werkzeug Google Analytics generiert und Ihrem
                      Endgerät zugewiesen werden: Hierzu zählt eine eindeutige ID zur (Wieder-)
                      Erkennung wiederkehrender Besucher (sog. „Client-ID“) sowie bestimmte
                      technische Parameter für die Steuerung der Datenerfassung für die Web-Analyse.
                      Zudem wird für eingeloggte Kunden eine sog. „User-ID“ gespeichert, damit wir
                      analysieren können, wie unsere Plattform geräteübergreifend genutzt wird.
                    </li>
                    <li>
                      ▪ Google Analytics-Mess-Daten
                      <br />
                      Gerätebezogene Rohdaten (sog. „Dimensionen“ und „Messwerte“), die durch das
                      Web-Analyse-Werkzeug Google Analytics bei der Nutzung unseres Webangebots
                      erfasst und analysiert werden: Hierzu zählen vor allem Informationen über die
                      Quellen, über die Besucher auf unser Webangebot gelangen, Informationen über
                      den Standort, den verwendeten Browser und das verwendete Endgerät,
                      Informationen über die Nutzung der Webseite (insbesondere Seitenaufrufe,
                      Aufrufhäufigkeit und Verweildauer auf aufgerufenen Seiten) sowie Informationen
                      zur Erfüllung bestimmter Ziele. Die Daten sind jeweils der Ihrem Endgerät
                      zugewiesenen Client-ID zugeordnet. Im Ergebnis entstehen dadurch also
                      gerätebezogene Nutzungsprofile, in denen alle gerätebezogenen Rohdaten zu
                      einer Client-ID zusammengefasst sind. Die Daten, die wir mittels Google
                      Analytics erfassen, ermöglichen es uns nicht, Sie direkt persönlich (also
                      anhand ihres bürgerlichen Namens) zu identifizieren. Wir führen die
                      gerätebezogenen Rohdaten und die entstehenden gerätebezogenen Nutzungsprofile
                      ohne Ihre Einwilligung auch nicht mit Daten zusammen, die Sie direkt
                      persönlich identifizieren.
                    </li>
                    <li>
                      ▪ Google Analytics-Bericht-Daten
                      <br />
                      Daten, die in aggregierten segment- und gerätebezogenen Berichten enthalten
                      sind, die durch das Web-Analyse-Werkzeug Google Analytics auf Grundlage der
                      Analyse der gerätbezogenen Rohdaten erstellt werden.
                      <br />
                      In Google Analytics stehen uns vier Berichtskategorien zur Verfügung:
                      Zielgruppe (Standort, Browser, genutzte Geräte sowie weitere gerätebezogene
                      Daten), Akquisition (Quellen, über die Besucher auf das Webangebot gelangen),
                      Verhalten (Informationen zum Aufruf von Inhalten des Webangebots, insbesondere
                      aufgerufenen Seiten der Webseite), Conversions (Informationen zu vorab
                      konfigurierten Zielen).
                    </li>
                  </ul>
                  <Typography paragraph={true}>
                    (iii) Rechtsgrundlage für die Verarbeitung ist Artikel 6 Absatz 1 Buchstabe a
                    DS-GVO (Einwilligung).
                    <br />
                    (iv) Die Daten werden automatisch durch den Browser des Nutzers zur Verfügung
                    gestellt.
                    <br />
                    (v) Empfänger der Daten sind im Rahmen der Auftragsverarbeitung Google Ireland
                    Limited (Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Irland)
                    und Analyse- und Supportdienstleister mit Sitz in der EU. Google Ireland Limited
                    setzt die Google LLC in den USA (1600 Amphitheatre Parkway, Mountain View, CA
                    94043, USA) als Dienstleister ein. Die Daten werden nur übermittelt, sofern Sie
                    uns hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1 Buchst. a, 49 Abs. 1 S. 1
                    Buchst. a DSGVO erteilt haben. Entsprechende Hinweise zu Risiken derartiger
                    Datenübermittlungen und Widerrufsmöglichkeiten finden Sie unter B. III. 2.
                    <br />
                    (vi) Auf dieser Webseite ist für den Einsatz des Web-Analyse-Werkzeugs Google
                    Analytics eine sog. IP-Anonymisierung aktiviert. Das bedeutet, dass die vom
                    Browser technisch bedingt übermittelte IP-Adresse durch Kürzung (Streichung des
                    letzten Oktetts der IPv4-Adresse bzw. der letzten 80 Bits der IPv6-Adresse) vor
                    der Speicherung anonymisiert wird. Die übrigen Protokolldaten speichern wir für
                    eine Dauer von 26 Monaten.
                    <br />
                    (vii) Die Bereitstellung von Daten ist nicht gesetzlich oder vertraglich
                    vorgeschrieben oder für einen Vertragsabschluss erforderlich. Es besteht keine
                    Verpflichtung der betroffenen Person zur Bereitstellung der Daten. Im Falle
                    einer Nichtbereitstellung der Daten können wir keine Web-Analyse mittels Google
                    Analytics vornehmen.
                    <br />
                    (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                    <br />
                  </Typography>
                  <Button onClick={() => toTop()} color="primary">
                    Nach oben
                  </Button>
                </div>
                <div id="B-IV-2-b">
                  <Typography variant="h4" component="h4">
                    b) Hotjar - Feedbacktool
                  </Typography>
                  <Typography paragraph={true}>
                    Wenn Sie hierzu Ihre Einwilligung erteilt haben, setzen wir auf unserer Webseite
                    das Feedback-Tool von Hotjar ein. Mithilfe von Hotjar können wir die
                    Nutzerzufriedenheit in Bezug auf unsere Webseite in pseudonymisierter und
                    anonymisierter Form untersuchen.
                    <br />
                    Sie können die Datenverarbeitung durch Hotjar jederzeit in unserem
                    „Cookie-Dashboard“ deaktivieren. Zudem können Sie folgenden Opt-Out Link
                    nutzen:&nbsp;
                    <Link href="https://www.hotjar.com/legal/compliance/opt-out" target="_blank">
                      https://www.hotjar.com/legal/compliance/opt-out
                    </Link>
                    .
                    <br />
                    Alternativ können Sie die Speicherung von Cookies in den Einstellungen Ihres
                    Browsers verbieten.
                    <br />
                    (i) Zweck der Datenverarbeitung ist die Steigerung der Effizienz unseres
                    Ressourceneinsatzes für unser Webangebot und der Zufriedenheit unserer
                    Webseitenbesucher durch Abfrage der Nutzerzufriedenheit. Hierbei betrachten wir
                    beispielsweise den Umgang mit besonderen Features unseres Webangebots.
                    <br />
                    (ii) Die verarbeiteten Daten sind:
                  </Typography>
                  <ul>
                    <li>
                      ▪ Hotjar-Endgeräte-Daten
                      <br />
                      Daten, die durch das Web-Analyse-Werkzeug Hotjar generiert und Ihrem Endgerät
                      zugewiesen werden: Hierzu zählt eine eindeutige ID zur (Wieder-) Erkennung
                      wiederkehrender Besucher (sog. „Hotjar User-ID“) sowie die IP-Adresse zur
                      Identifizierung der Region, in welcher sich der Nutzer befindet, anhand der
                      ersten drei Gruppen der IP-Adresse.
                    </li>
                    <li>
                      ▪ Feedbackdaten
                      <br />
                      Sämtliche Daten, welche Sie im Rahmen des Feedbacks eingeben. Dazu gehören
                      unter anderem ihre Zufriedenheit mit dem Angebot und Informationen, welche Sie
                      in das Freitextfeldes eingeben.
                    </li>
                  </ul>
                  <Typography paragraph={true}>
                    (iii) Rechtsgrundlage der Verarbeitung ist Artikel 6 Absatz 1 Buchstabe a DS-GVO
                    (Einwilligung).
                    <br />
                    (iv) Die Daten werden zum Teil automatisch durch den Browser des Nutzers
                    (Endgeräte-Daten) bzw. durch den Nutzer selbst (Feedbackdaten) zur Verfügung
                    gestellt.
                    <br />
                    (v) Empfänger der Daten ist Hotjar Ltd, Level 2, St Julians Business Centre, 3,
                    Elia Zammit Street St Julians STJ 3155, Malta, als unser Auftragsverarbeiter.
                    <br />
                    (vi) Feedbackdaten werden gelöscht, wenn Sie für die Zwecke der Verarbeitung
                    nicht mehr erforderlich sind. Die Speicherdauer der Cookies, in denen die Hotjar
                    User-ID gespeichert ist, beträgt 365 Tage.
                    <br />
                    (vii) Die Bereitstellung von Daten ist nicht gesetzlich oder vertraglich
                    vorgeschrieben oder für einen Vertragsabschluss erforderlich. Es besteht keine
                    Verpflichtung der betroffenen Person zur Bereitstellung der Daten. Im Falle
                    einer Nichtbereitstellung der Daten können wir über Hotjar keine Feedbacks
                    einholen.
                    <br />
                    (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                    <br />
                  </Typography>
                  <Button onClick={() => toTop()} color="primary">
                    Nach oben
                  </Button>
                </div>
                <div id="B-IV-2-c">
                  <Typography variant="h4" component="h4">
                    c) Amplitude (Analyse und A/B-Testing)
                  </Typography>
                  <Typography paragraph={true}>
                    Wenn Sie hierzu Ihre Einwilligung erteilt haben, setzen wir auf unserer Webseite
                    das Web-Analyse-Werkzeug Amplitude (im Folgenden: „Amplitude“) ein. Mithilfe von
                    Amplitude können wir das Nutzerverhalten der Besucher unserer Webseite in
                    pseudonymisierter und anonymisierter Form untersuchen. Zudem ermöglicht
                    Amplitude es uns, das Nutzerverhalten auf verschiedenen Gestaltungen unserer
                    Webseite miteinander zu vergleichen (sog. A/B-Testing).
                    <br />
                    Sie können die Datenverarbeitung durch Amplitude jederzeit in unserem
                    „Cookie-Dashboard“ deaktivieren. Alternativ können Sie die Speicherung von
                    Cookies in den Einstellungen Ihres Browsers verbieten.
                    <br />
                    (i) Zweck der Datenverarbeitung ist die Steigerung der Effizienz unseres
                    Ressourceneinsatzes für unser Webangebot und unsere Werbemaßnahmen, des Ertrags
                    unseres Online-Shops und der Zufriedenheit unserer Besucher und (potentiellen)
                    Kunden durch (nutzungsbasierte) Optimierung unseres Webangebots durch Messung
                    der Nutzung unseres Webangebots und (nutzungsbasierte) Optimierung unserer
                    Werbemaßnahmen durch Messung des Erfolgs der von uns eingesetzten Werbemittel in
                    unserem Webangebot (Werbemittelerfolgskontrolle). Durch Amplitude wird die
                    Durchführung unterschiedlicher Tests mit Möglichkeiten zur Ausrichtung auf URL,
                    auf bestimmte Zielgruppen, auf das Verhalten, auf bestimmte geografische
                    Gebiete, auf JavaScript-Variablen, auf eigene Cookies, auf benutzerdefiniertes
                    JavaScript, auf Suchparameter, auf Datenschichtvariablen und auf UTM-Parameter
                    ermöglicht.
                    <br />
                    (ii) Die verarbeiteten Daten sind:
                  </Typography>
                  <ul>
                    <li>
                      ▪ Amplitude HTTP-Daten
                      <br />
                      Hierbei handelt es sich um Protokolldaten, die beim Einsatz des auf der
                      Webseite verwendeten Web-Analyse-Werkzeugs Amplitude über das Hypertext
                      Transfer Protocol (Secure) (HTTP(S)) technisch bedingt anfallen: Hierzu zählen
                      IP-Adresse, Typ und Version Ihres Internet-Browsers, verwendetes
                      Betriebssystem, die aufgerufene Seite, die zuvor besuchte Seite (Referrer
                      URL), Datum und Uhrzeit des Abrufs.
                    </li>
                    <li>
                      ▪ Amplitude-Endgeräte-Daten
                      <br />
                      Daten, die durch das Web-Analyse-Werkzeug Amplitude generiert und Ihrem
                      Endgerät zugewiesen werden: Hierzu zählt eine eindeutige ID zur (Wieder-)
                      Erkennung wiederkehrender Besucher (sog. „Client-ID“) sowie bestimmte
                      technische Parameter für die Steuerung der Datenerfassung für die Web-Analyse.
                      Zudem wird für eingeloggte Kunden eine sog. „User-ID“ gespeichert, damit wir
                      analysieren können, wie unser Webshop geräteübergreifend genutzt wird
                      (Mobile-App, -Web, Desktop).
                    </li>
                    <li>
                      ▪ Amplitude-Mess-Daten
                      <br />
                      Gerätebezogene Rohdaten (sog. „Dimensionen“ und „Messwerte“), die durch das
                      Web-Analyse-Werkzeug Amplitude bei der Nutzung unseres Webangebots erfasst und
                      analysiert werden: Hierzu zählen vor allem Informationen über die Quellen,
                      über die Besucher auf unser Webangebot gelangen, Informationen über den
                      Standort, den verwendeten Browser und das verwendete Endgerät, Informationen
                      über die Nutzung der Webseite (insbesondere Seitenaufrufe, Aufrufhäufigkeit
                      und Verweildauer auf aufgerufenen Seiten) sowie Informationen zur Erfüllung
                      bestimmter Ziele (insbesondere Transaktionen im Online-Shop). Die Daten sind
                      jeweils der Ihrem Endgerät zugewiesenen Client-ID zugeordnet. Im Ergebnis
                      entstehen dadurch also gerätebezogene Nutzungsprofile, in denen alle
                      gerätebezogenen Rohdaten zu einer Client-ID zusammengefasst sind. Die Daten,
                      die wir mittels Amplitude erfassen, ermöglichen es uns nicht, Sie direkt
                      persönlich (also anhand ihres bürgerlichen Namens) zu identifizieren. Wir
                      führen die gerätebezogenen Rohdaten und die entstehenden gerätebezogenen
                      Nutzungsprofile ohne Ihre Einwilligung auch nicht mit Daten zusammen, die Sie
                      direkt persönlich identifizieren.
                    </li>
                    <li>
                      ▪ Amplitude-Berichtsdaten
                      <br />
                      Daten, die in aggregierten segment- und gerätebezogenen Berichten bzw. den
                      A/B-Testergebnissen enthalten sind, die durch das Web-Analyse-Werkzeug
                      Amplitude auf Grundlage der Analyse der gerätbezogenen Rohdaten erstellt
                      werden.
                      <br />
                      In Amplitude stehen uns vier Berichtskategorien zur Verfügung: Zielgruppe
                      (Standort, Browser, genutzte Geräte sowie weitere gerätebezogene Daten),
                      Akquisition (Quellen, über die Besucher auf das Webangebot gelangen),
                      Verhalten (Informationen zum Aufruf von Inhalten des Webangebots, insbesondere
                      aufgerufenen Seiten der Webseite und Artikel im Online-Shop, Besuchszeit,
                      Absprungrate), Conversions (Informationen zu vorab konfigurierten Zielen,
                      insbesondere Transaktionen im Online-Shop).
                    </li>
                  </ul>
                  <Typography paragraph={true}>
                    (iii) Rechtsgrundlage für die Verarbeitung ist Artikel 6 Absatz 1 Buchstabe a
                    DS-GVO (Einwilligung). Im Hinblick auf den Zugriff das Endgerät ist die
                    Rechtsgrundlage § 25 Abs. 1 S. 1 TTDSG.
                    <br />
                    (iv) Die Daten werden automatisch durch den Browser des Nutzers zur Verfügung
                    gestellt.
                    <br />
                    (v) Empfänger der Daten ist im Rahmen der Auftragsverarbeitung die Amplitude,
                    Inc201 3rd Street, Suite 200, San Francisco, CA 94103. Die Daten werden damit
                    auch in Länder außerhalb des EWR (USA) übermittelt. Dies erfolgt nur, sofern Sie
                    uns hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1 Buchst. a, 49 Abs. 1 S. 1
                    Buchst. a DSGVO erteilt haben. Entsprechende Hinweise zu Risiken derartiger
                    Datenübermittlungen und Widerrufsmöglichkeiten finden Sie unter B. I. 3.
                    <br />
                    (vi) Auf dieser Webseite ist für den Einsatz des Web-Analyse-Werkzeugs Amplitude
                    eine sog. IP-Anonymisierung aktiviert. Das bedeutet, dass die vom Browser
                    technisch bedingt übermittelte IP-Adresse durch Kürzung (Streichung des letzten
                    Oktetts der IPv4-Adresse bzw. der letzten 80 Bits der IPv6-Adresse) vor der
                    Speicherung anonymisiert wird. Die übrigen Protokolldaten speichern wir für eine
                    Dauer von 26 Monaten.
                    <br />
                    (vii) Die Bereitstellung von Daten ist nicht gesetzlich oder vertraglich
                    vorgeschrieben oder für einen Vertragsabschluss erforderlich. Es besteht keine
                    Verpflichtung der betroffenen Person zur Bereitstellung der Daten. Im Falle
                    einer Nichtbereitstellung der Daten können wir keine Web-Analyse und kein
                    A/B-Testing mittels Amplitude vornehmen.
                    <br />
                    (viii) Es findet keine automatisierte Entscheidungsfindung statt.
                    <br />
                  </Typography>
                  <Button onClick={() => toTop()} color="primary">
                    Nach oben
                  </Button>
                </div>
              </div>
            </div>
            {!isApp && (
              <div id="B-IV-3">
                <Typography variant="h3" component="h3">
                  3. Cookie-Dashboard
                </Typography>
                <div dangerouslySetInnerHTML={cookieDashboard} />
              </div>
            )}
          </div>
        </div>
        <div id="C">
          <Typography variant="h1" component="h1">
            C. Informationen über Rechte der betroffenen Personen
          </Typography>
          <div>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie in Bezug auf die Verarbeitung Ihrer personenbezogenen
              Daten folgende Rechte:
            </Typography>
            <ul>
              <li>▪ Recht auf Auskunft (Artikel 15 DS-GVO)</li>
              <li>▪ Recht auf Berichtigung (Artikel 16 DS-GVO)</li>
              <li>▪ Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DS-GVO)</li>
              <li>▪ Recht auf Datenübertragbarkeit (Artikel 20 DS-GVO)</li>
              <li>▪ Recht auf Widerspruch (Artikel 21 DS-GVO)</li>
              <li>▪ Recht auf Widerruf von Einwilligungen (Artikel 7 Absatz 3 DS-GVO)</li>
              <li>▪ Recht auf Beschwerde bei der Aufsichtsbehörde (Artikel 77 DS-GVO)</li>
            </ul>
            <Typography paragraph={true}>
              Zur Ausübung Ihrer Rechte können Sie sich unter den in Abschnitt A genannten
              <br />
              Kontaktinformationen an uns wenden. Den vollen Umfang Ihrer Rechte können Sie dem Text
              <br />
              der Datenschutz-Grundverordnung entnehmen, die Sie unter folgendem
              <br />
              Link abrufen können:
              <Link
                href="http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679"
                target="_blank"
              >
                http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679.
              </Link>
              <br />
              <br />
              Informationen über etwaige besondere Modalitäten und Mechanismen, die Ihnen die
              Ausübung Ihrer Rechte erleichtern, insbesondere zur Ausübung Ihrer Rechte auf
              Datenübertagbarkeit und Widerspruch, erhalten Sie gegebenenfalls in den Informationen
              zu der Verarbeitung personenbezogener Daten in Abschnitt B dieser
              Datenschutzinformationen.
              <br />
              Im Folgenden erhalten Sie detailliertere Informationen zu Ihren Rechten in Bezug auf
              die Verarbeitung Ihrer personenbezogenen Daten.
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-I">
            <Typography variant="h2" component="h2">
              I. Recht auf Auskunft
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 15 DS-GVO ein
              Recht auf Auskunft.
              <br />
              Das bedeutet insbesondere, dass Sie das Recht haben, von uns eine Bestätigung darüber
              zu verlangen, ob wir Sie betreffende personenbezogene Daten verarbeiten. Ist dies der
              Fall, haben Sie außerdem ein Recht auf Auskunft über diese personenbezogenen Daten und
              auf die in Artikel 15 Absatz 1 DS-GVO aufgeführten Informationen. Dazu gehören
              beispielsweise Informationen über die Verarbeitungszwecke, über die Kategorien
              personenbezogener Daten, die verarbeitet werden, sowie über die Empfänger oder
              Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt
              worden sind oder noch offengelegt werden (Artikel 15 Absatz 1 Buchstaben a, b und c
              DS-GVO).
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-II">
            <Typography variant="h2" component="h2">
              II. Recht auf Berichtigung
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 16 DS-GVO ein
              Recht auf Berichtigung.
              <br />
              Das bedeutet insbesondere, dass Sie das Recht haben, von uns unverzüglich die
              Berichtigung Sie betreffender unrichtiger personenbezogener Daten sowie die
              Vervollständigung unvollständiger personenbezogener Daten zu verlangen.
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-III">
            <Typography variant="h2" component="h2">
              III. Recht auf Löschung („Recht auf Vergessenwerden“)
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 17 DS-GVO ein
              Recht auf Löschung („Recht auf Vergessenwerden“).
              <br />
              Das bedeutet, dass Sie grundsätzlich das Recht haben, von uns zu verlangen, dass Sie
              betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir verpflichtet
              sind, personenbezogene Daten unverzüglich zu löschen, sofern einer der in Artikel 17
              Absatz 1 DS-GVO aufgeführten Gründe zutrifft. Das kann beispielsweise der Fall sein,
              wenn personenbezogenen Daten für die Zwecke, für die sie erhoben oder auf sonstige
              Weise verarbeitet wurden, nicht mehr notwendig sind (Artikel 17 Absatz 1 Buchstabe a
              DS-GVO).
              <br />
              Soweit wir die personenbezogenen Daten öffentlich gemacht haben und wir zu deren
              Löschung verpflichtet sind, sind wir außerdem verpflichtet unter Berücksichtigung der
              verfügbaren Technologie und der Implementierungskosten angemessene Maßnahmen, auch
              technischer Art, zu treffen, um andere für die Datenverarbeitung Verantwortliche, die
              die personenbezogenen Daten verarbeiten, darüber zu informieren, dass eine betroffene
              Person von ihnen die Löschung aller Links zu diesen personenbezogenen Daten oder von
              Kopien oder Replikationen dieser personenbezogenen Daten verlangt hat (Artikel 17
              Absatz 2 DS-GVO ).
              <br />
              Das Recht auf Löschung („Recht auf Vergessenwerden“) gilt ausnahmsweise nicht, soweit
              die Verarbeitung aus den in Artikel 17 Absatz 3 DS-GVO aufgeführten Gründen
              erforderlich ist. Das kann beispielsweise der Fall sein, soweit die Verarbeitung zur
              Erfüllung einer rechtlichen Verpflichtung oder zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen erforderlich ist (Artikel 17 Absatz 3 Buchstaben a
              und e DS-GVO).
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-IV">
            <Typography variant="h2" component="h2">
              IV. Recht auf Einschränkung der Verarbeitung
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 18 der
              Datenschutz-Grundverordnung ein Recht auf Einschränkung der Verarbeitung.
              <br />
              Das bedeutet, dass Sie das Recht haben, von uns die Einschränkung der Verarbeitung zu
              verlangen, wenn eine der in Artikel 18 Absatz 1 DS-GVO aufgeführten Voraussetzungen
              gegeben ist. Das kann beispielsweise der Fall sein, wenn Sie die Richtigkeit der
              personenbezogenen Daten bestreiten. Die Einschränkung der Verarbeitung erfolgt in
              diesem Fall für eine Dauer, die es uns ermöglicht, die Richtigkeit der
              personenbezogenen Daten zu überprüfen (Artikel 18 Absatz 1 Buchstabe a DS-GVO).
              <br />
              Einschränkung bedeutet die Markierung gespeicherter personenbezogener Daten mit dem
              Ziel, ihre künftige Verarbeitung einzuschränken (Artikel 4 Nummer 3 DS-GVO).
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-V">
            <Typography variant="h2" component="h2">
              V. Recht auf Datenübertragbarkeit
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 20 DS-GVO ein
              Recht auf Datenübertragbarkeit.
              <br />
              Das bedeutet, dass Sie grundsätzlich das Recht haben, die Sie betreffenden
              personenbezogenen Daten, die sie uns bereitgestellt haben, in einem strukturierten,
              gängigen und maschinenlesbaren Format zu erhalten, und Sie das Recht haben, diese
              Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern
              die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a oder
              Artikel 9 Absatz 2 Buchstabe a DS-GVO oder auf einem Vertrag gemäß Artikel 6 Absatz 1
              Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren
              erfolgt (Artikel 20 Absatz 1 DS-GVO ).
              <br />
              Informationen dazu, ob eine Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz
              1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a der Datenschutz-Grundverordnung oder
              auf einem Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b DS-GVO beruht, erhalten Sie in
              den Informationen zu den Rechtsgrundlagen der Verarbeitung in Abschnitt B dieser
              Datenschutzinformationen.
              <br />
              Bei der Ausübung ihres Rechts auf Datenübertragbarkeit haben Sie außerdem
              grundsätzlich das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns
              einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist
              (Artikel 20 Absatz 2 DS-GVO).
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-VI">
            <Typography variant="h2" component="h2">
              VI. Recht auf Widerspruch
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 21 DS-GVO ein
              Recht auf Widerspruch.
              <br />
              Auf Ihr Recht auf Widerspruch weisen wir Sie als betroffene Person spätestens zum
              Zeitpunkt der ersten Kommunikation mit Ihnen ausdrücklich hin.
              <br />
              Im Folgenden erhalten Sie detailliertere Informationen hierzu:
              <br />
            </Typography>
            <div id="C-VI-1">
              <Typography variant="h3" component="h3">
                1. Recht auf Widerspruch aus Gründen, die sich aus der besonderen Situation der
                betroffenen Person ergeben
              </Typography>
              <Typography paragraph={true}>
                Als betroffene Person haben Sie das Recht, aus Gründen, die sich aus Ihrer
                besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
                personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstaben e oder f
                DS-GVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese
                Bestimmungen gestütztes Profiling.
                <br />
                Informationen dazu, ob eine Verarbeitung aufgrund von Artikel 6 Absatz 1 Buchstaben
                e oder f DS-GVO erfolgt, erhalten Sie in den Informationen zu den Rechtsgrundlagen
                der Verarbeitung in Abschnitt B dieser Datenschutzinformationen.
                <br />
                Im Falle eines Widerspruchs aus Gründen, die sich aus Ihrer besonderen Situation
                ergeben, verarbeiten wir die betroffenen personenbezogenen Daten nicht mehr, es sei
                denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
                Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der
                Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
                <br />
              </Typography>
            </div>
            <div id="C-VI-2">
              <Typography variant="h3" component="h3">
                2. Recht auf Widerspruch gegen Direktwerbung
              </Typography>
              <Typography paragraph={true}>
                Werden personenbezogene Daten verarbeitet, um Direktwerbung zu betreiben, so haben
                Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender
                personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für
                das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.
                <br />
                Informationen dazu, ob und in welchem Umfang personenbezogene Daten verarbeitet
                werden, um Direktwerbung zu betreiben, erhalten Sie in den Informationen zu den
                Zwecken der Verarbeitung in Abschnitt B dieser Datenschutzinformationen.
                <br />
                Im Falle eines Widerspruchs gegen Verarbeitung für Zwecke der Direktwerbung,
                verarbeiten wir die betroffenen personenbezogenen Daten nicht mehr für diese Zwecke.
                <br />
              </Typography>
            </div>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-VII">
            <Typography variant="h2" component="h2">
              VII. Recht auf Widerruf von Einwilligungen
            </Typography>
            <Typography paragraph={true}>
              Wenn die Verarbeitung auf einer Einwilligung nach Artikel 6 Absatz 1 Buchstabe a oder
              Artikel 9 Absatz 2 Buchstabe a DS-GVO beruht, haben Sie als betroffene Person nach
              Artikel 7 Absatz 3 der Datenschutz-Grundverordnung das Recht, Ihre Einwilligung
              jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit,
              der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
              Hiervon setzen wir Sie vor Abgabe der Einwilligung in Kenntnis.
              <br />
              Informationen dazu, ob eine Verarbeitung auf einer Einwilligung nach Artikel 6 Absatz
              1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a DS-GVO beruht, erhalten Sie in den
              Informationen zu den Rechtsgrundlagen der Verarbeitung in Abschnitt B dieser
              Datenschutzinformationen.
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
          <div id="C-VIII">
            <Typography variant="h2" component="h2">
              VIII. Recht auf Beschwerde bei einer Aufsichtsbehörde
            </Typography>
            <Typography paragraph={true}>
              Als betroffene Person haben Sie unter den Voraussetzungen von Artikel 77 DS-GVO das
              Recht, Beschwerde bei einer Aufsichtsbehörde einzulegen.
              <br />
              Die für uns zuständige Aufsichtsbehörde ist:
              <br />
              Der Medienbeauftragte für den Datenschutz
              <br />
              Bayerische Landeszentrale für neue Medien (BLM)
              <br />
              Heinrich-Lübke-Straße 27
              <br />
              81737 München
              <br />
              Tel.: +49(0)89-63808-0
              <br />
              Fax: +49(0)89-63808-185
              <br />
              E-Mail:&nbsp;
              <Link href="mailto:datenschutzaufsicht@blm.de">datenschutzaufsicht@blm.de</Link>
              <br />
            </Typography>
            <Button onClick={() => toTop()} color="primary">
              Nach oben
            </Button>
          </div>
        </div>
        <div id="D">
          <Typography variant="h1" component="h1">
            D. Informationen zu den in diesen Datenschutzinformationen verwendeten Fachbegriffen der
            Datenschutz-Grundverordnung
          </Typography>
          <Typography paragraph={true}>
            Die in diesen Datenschutzinformationen verwendeten Fachbegriffe haben die in der DS-GVO
            zugrunde gelegte Bedeutung.
            <br />
            Den vollen Umfang der Begriffsbestimmungen der Datenschutz-Grundverordnung
            <br />
            können Sie Artikel 4 DS-GVO entnehmen, die Sie unter folgendem Link abrufen
            <br />
            können:&nbsp;
            <Link
              href="http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679"
              target="_blank"
            >
              http://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679.
            </Link>
            <br />
            <br />
            Detailliertere Informationen zu den wichtigsten in diesen Datenschutzinformationen
            zugrunde gelegten Fachbegriffen der Datenschutz-Grundverordnung erhalten Sie im
            Folgenden:
            <br />
            [Bei einer mehrschichtigen Darstellung der Informationen („Layered Information“) könnten
            die folgenden Informationen auf der ersten Schicht ausgeblendet werden.]
          </Typography>
          <ul>
            <li>
              ▪ „Personenbezogene Daten“ bezeichnet alle Informationen, die sich auf eine
              identifizierte oder identifizierbare natürliche Person („betroffene Person“) beziehen;
              als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt,
              insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer,
              zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen
              Merkmalen, die Ausdruck der physischen, physiologischen, genetischen, psychischen,
              wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind,
              identifiziert werden kann;
            </li>
            <li>
              ▪ „Betroffene Person“ bezeichnet die jeweils identifizierte oder identifizierbare
              natürliche Person, auf die sich personenbezogene Daten beziehen;
            </li>
            <li>
              ▪ „Verarbeitung“ bezeichnet jeden mit oder ohne Hilfe automatisierter Verfahren
              ausgeführten Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
              personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das Ordnen,
              die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
              Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der
              Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder
              die Vernichtung;
            </li>
            <li>
              ▪ „Profiling“ bezeichnet jede Art der automatisierten Verarbeitung personenbezogener
              Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um
              bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu
              bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
              Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten,
              Aufenthaltsort oder Ortswechsel dieser natürlichen Person zu analysieren oder
              vorherzusagen;
            </li>
            <li>
              ▪ „Verantwortlicher“ bezeichnet die natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke
              und Mittel der Verarbeitung von personenbezogenen Daten entscheidet; sind die Zwecke
              und Mittel dieser Verarbeitung durch das Unionsrecht oder das Recht der
              Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die
              bestimmten Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der
              Mitgliedstaaten vorgesehen werden;
            </li>
            <li>
              ▪ „Auftragsverarbeiter“ bezeichnet eine natürliche oder juristische Person, Behörde,
              Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des
              Verantwortlichen verarbeitet;
            </li>
            <li>
              ▪ „Empfänger“ bezeichnet eine natürliche oder juristische Person, Behörde, Einrichtung
              oder andere Stelle, der personenbezogene Daten offengelegt werden, unabhängig davon,
              ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im Rahmen eines
              bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
              Mitgliedstaaten möglicherweise personenbezogene Daten erhalten, gelten jedoch nicht
              als Empfänger; die Verarbeitung dieser Daten durch die genannten Behörden erfolgt im
              Einklang mit den geltenden Datenschutzvorschriften gemäß den Zwecken der Verarbeitung;
            </li>
            <li>
              ▪ „Dritter“ ist eine natürliche oder juristische Person, Behörde, Einrichtung oder
              andere Stelle, außer der betroffenen Person, dem Verantwortlichen, dem
              Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung des
              Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen
              Daten zu verarbeiten;
            </li>
            <li>
              ▪ „Internationale Organisation“ bezeichnet eine völkerrechtliche Organisation und ihre
              nachgeordneten Stellen oder jede sonstige Einrichtung, die durch eine zwischen zwei
              oder mehr Ländern geschlossene Übereinkunft oder auf der Grundlage einer solchen
              Übereinkunft geschaffen wurde;
            </li>
            <li>
              ▪ „Drittland“ bezeichnet ein Land, das nicht ein Mitgliedsstaat der Europäischen Union
              („EU“) oder des Europäischen Wirtschaftsraums („EWR“) ist;
            </li>
            <li>
              ▪ „Besondere Kategorien personenbezogener Daten“ bezeichnet Daten, aus denen die
              rassische und ethnische Herkunft, politische Meinungen, religiöse oder
              weltanschauliche Überzeugungen oder die Gewerkschaftszugehörigkeit hervorgehen, sowie
              genetische Daten, biometrische Daten zur eindeutigen Identifizierung einer natürlichen
              Person, Gesundheitsdaten oder Daten zum Sexualleben oder der sexuellen Orientierung
              einer natürlichen Person.
            </li>
          </ul>
          <Button onClick={() => toTop()} color="primary">
            Nach oben
          </Button>
        </div>
        <div id="E">
          <Typography variant="h1" component="h1">
            E. Stand und Änderung dieser Datenschutzinformationen
          </Typography>
          <Typography paragraph={true}>
            Diese Datenschutzinformation hat den Stand 17.11.2020.
            <br />
            Aufgrund technischer Weiterentwicklung, Änderung der Funktion der Webseite und/oder
            aufgrund geänderter gesetzlicher und/oder behördlicher Vorgaben kann es notwendig
            werden, diese Datenschutzinformationen anzupassen.
            <br />
          </Typography>
          <Button onClick={() => toTop()} color="primary">
            Nach oben
          </Button>
        </div>
      </div>
      <BackToTop />
    </div>
  );

  return <PageContainer pageToRender={PrivacyPolicy()} />;
};

export default PrivacyPolicyPage;
