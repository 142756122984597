import { camelizeKeys } from 'humps';
import authClient from '../../auth/authClient';
import Config from '../../config/config';
import { SurveyQueryParams, SurveysResponse } from '../types/types';

const defaultBaseUrl = `${Config.env.socialLifeCommerceServiceBaseUrl}`;

const surveysClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/ratings`);

export const getSurveys = async (
  queryParams: SurveyQueryParams,
  baseUrl: string = defaultBaseUrl
): Promise<SurveysResponse> => {
  const response = await surveysClient(baseUrl).get<SurveysResponse>(
    `/?${toQueryString(queryParams)}`
  );
  return camelizeKeys(response.data) as SurveysResponse;
};

const toQueryString = (queryParams: SurveyQueryParams) =>
  new URLSearchParams({
    sort_field: queryParams.sortField,
    sort_order: queryParams.sortOrder.toUpperCase(),
    page_size: queryParams.pageSize.toString(),
    page: queryParams.page.toString(),
    from: queryParams.dateFrom,
    to: queryParams.dateTo,
    ...(queryParams.searchTerm && { search_term: queryParams.searchTerm }),
    ...(queryParams.audience && {
      audience: queryParams.audience.map(e => e.toString()).join(','),
    }),
    item_type: queryParams.itemType,
    written_feedback_only: queryParams.writtenFeedbackOnly.toString(),
    ...(queryParams.platform && {
      platform: queryParams.platform,
    }),
  }).toString();

export const surveyApi = {
  surveysClient,
  toQueryString,
  getSurveys,
};
