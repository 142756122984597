import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { useState } from 'react';
import { isMobileBreakPoint } from '../../../../../../../common/mediaQueries';
import styles from './CustomTimer.module.scss';
import CustomTimerForm from './CustomTimerForm/CustomTimerForm';
import MobileCustomTimerForm from './MobileCustomForm/MobileCustomTimerForm';

export interface CustomTimerProps {
  showDate: Date;
  updateShowDate: (date: Date) => void;
  setHasSelectedValidTime: (hasSelectedValidTime: boolean) => void;
}

const getCustomTimer = (
  isMobile: boolean,
  setIsSelectingCustomTime: (isSelectingCustomTime: boolean) => void,
  props: CustomTimerProps
) => {
  return isMobile ? (
    <MobileCustomTimerForm setIsSelectingCustomTime={setIsSelectingCustomTime} {...props} />
  ) : (
    <CustomTimerForm shouldUpdateValueOnChange={true} {...props} />
  );
};

export const CustomTimer = (props: CustomTimerProps) => {
  const [isSelectingCustomTime, setIsSelectingCustomTime] = useState(false);
  const isMobile = isMobileBreakPoint();

  const handleSelectCustomTimeHandler = () => {
    setIsSelectingCustomTime(true);
    props.setHasSelectedValidTime(false);
  };

  return (
    <div data-testid="custom-timer" className={styles.custom_timer_container}>
      {isSelectingCustomTime ? (
        getCustomTimer(isMobile, setIsSelectingCustomTime, props)
      ) : (
        <Button
          type={EButtonType.BLANK}
          size={EButtonSize.SMALL}
          onClick={handleSelectCustomTimeHandler}
        >
          Benutzerdefinierte Zeit
        </Button>
      )}
    </div>
  );
};
export default CustomTimer;
