import { CheckCircleOutline } from '@mui/icons-material';
import { Alert, Snackbar as MaterialSnackbar } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar, selectSnackbarDetails } from '../../state/notificationsSlice';

const Snackbar = () => {
  const snackbarDetails = useSelector(selectSnackbarDetails);
  const isOpen = snackbarDetails?.toggle;
  const dispatch = useDispatch();
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (isOpen) {
      timeout = setTimeout(() => {
        dispatch(closeSnackbar());
      }, 6000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, dispatch]);

  return (
    <MaterialSnackbar
      open={isOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      sx={{ bottom: { xs: 60 } }} // this is needed so that the navbar appears above the bottom navigation (its height = 56px)
    >
      <Alert
        variant="filled"
        sx={{ width: '100%', borderRadius: '8px', backgroundColor: '#3E7F24' }}
        icon={<CheckCircleOutline />}
      >
        {snackbarDetails?.text}
      </Alert>
    </MaterialSnackbar>
  );
};

export default Snackbar;
