import { Button, EButtonType } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import SubmitBtn from '../../../../components/SubmitBtn/SubmitBtn';
import t from '../../../../constants/translation';
import { closeManagedDialog } from '../../../../notifications/state/notificationsSlice';
import styles from './DialogActionButtons.module.scss';

const DialogActionButtons = () => {
  const dispatch = useDispatch();

  return (
    <Box className={styles.actions}>
      <Button type={EButtonType.BLANK} onClick={() => dispatch(closeManagedDialog())}>
        <Typography fontWeight={700}>{t.common.Cancel}</Typography>
      </Button>
      <SubmitBtn>
        <Typography fontWeight={700}>{t.common.Save}</Typography>
      </SubmitBtn>
    </Box>
  );
};

export default DialogActionButtons;
