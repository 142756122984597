import { CircularProgress, Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import routePaths from '../../../../routes/routePaths';
import theme from '../../../../theme/theme.module.scss';
import { fetchAmasByShowIdAction, selectAmas, setAmas } from '../../state/amasSlice';

const AmaDashboardPage = () => {
  const { showId } = useParams<{ showId: string }>();
  if (!showId) return null;

  const dispatch = useDispatch();
  const history = useHistory();

  const amas = useSelector(selectAmas);

  useEffect(() => {
    dispatch(fetchAmasByShowIdAction(showId));
  }, []);

  useEffect(() => {
    if (amas !== undefined) {
      if (amas.length < 1) {
        history.replace(
          routePaths.creator.onDemandInteractions.ama.create.replace(':showId', showId)
        );
      } else {
        history.replace(
          routePaths.creator.onDemandInteractions.ama.display
            .replace(':showId', showId)
            .replace(':amaId', amas[0].id)
        );
      }
      dispatch(setAmas(undefined));
    }
  }, [amas, showId]);

  return (
    <PageContainer
      fullHeight
      backgroundColor={theme.whiteLilac}
      pageToRender={
        <Grid container justifyContent={'center'}>
          <CircularProgress data-testid={'circular-progress'} />
        </Grid>
      }
    />
  );
};

export default AmaDashboardPage;
