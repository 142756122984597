import { ArrowBack } from '@mui/icons-material';
import { CardMedia, Grid, IconButton } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import Config from '../../../config/config';
import DesktopFooter from '../../../layout/Footer/DesktopFooter/DesktopFooter';
import { StepInfo } from '../../authSteps';
import styles from '../../pages/AuthPage/AuthPage.module.scss';
import { AuthStep, selectAuthProgress, setAuthProcessStep } from '../../state/authSlice';
import HseAppLinks from '../HseAppLinks/HseAppLinks';
import RecaptchaInfo from '../RecaptchaInfo/RecaptchaInfo';

interface AuthLayoutProps {
  stepInfo?: StepInfo;
}

function AuthLayout({ stepInfo }: AuthLayoutProps) {
  const dispatch = useDispatch();
  const step = useSelector(selectAuthProgress);
  const allowBackButton = [AuthStep.FORGET_PASSWORD_STEP].includes(step.step);
  const isMobile = isMobileBreakPoint();

  const handleBack = () => {
    dispatch(setAuthProcessStep(AuthStep.LOGIN_PASSWORD_STEP));
  };
  return (
    <Grid className={styles.grid_container}>
      <Grid item className={styles.introduction_video_container}>
        <div className={styles.introduction_video} data-testid="introduction_video">
          {stepInfo && !isMobile && (
            <CardMedia
              component="video"
              image={`${process.env.PUBLIC_URL}/videos/HSE-intro.mp4`}
              autoPlay
              muted
              className={styles.video}
            />
          )}
        </div>
      </Grid>
      <Grid item className={classNames(styles.layout, !stepInfo?.recaptcha && styles.layout_start)}>
        <div className={styles.layout_content}>
          <div className={styles.layout_header}>
            {allowBackButton && (
              <div className={styles.arrow_back}>
                <IconButton onClick={handleBack}>
                  <ArrowBack />
                </IconButton>
              </div>
            )}
          </div>

          <div className={styles.container}>
            <div>
              {stepInfo &&
                (stepInfo.recaptcha ? (
                  <GoogleReCaptchaProvider reCaptchaKey={Config.env.recaptchaKey}>
                    <stepInfo.component />
                  </GoogleReCaptchaProvider>
                ) : (
                  <stepInfo.component />
                ))}
            </div>
          </div>
          <div className={styles.recaptcha}>
            {stepInfo && stepInfo.recaptcha && <RecaptchaInfo />}
          </div>
          <div className={styles.hse_apps}>
            <HseAppLinks />
          </div>
          <div className={styles.footer}>
            <DesktopFooter isFooterHidden={false} />
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default AuthLayout;
