import { matchPath } from 'react-router-dom';
import { RouteDetails } from '../routes/routeDetails';
import { routes } from '../routes/routes';

export const hiddenHeaderPath = (path: string) => !!findRouteDetails(path)?.hideHeader;
export const hiddenFooterPath = (path: string) => !!findRouteDetails(path)?.hideFooter;
export const isDesktopFooterHidden = (path: string) => !!findRouteDetails(path)?.hideDesktopFooter;

export const minimalHorizontalFooterPath = (path: string) =>
  !!findRouteDetails(path)?.minimalHorizontalFooter;

const findRouteDetails = (path: string): RouteDetails | undefined =>
  Object.values(routes).find(value =>
    matchPath(path, {
      path: value.path,
      exact: true,
    })
  );
