import { addMonths, startOfMonth } from 'date-fns';
import DatePicker from '../../DatePicker/DatePicker';
import DateRangePicker from '../../DateRangePicker/DateRangePicker';
import { DateFilterMode, DateFilterTypes } from '../dateFilterTypes';

interface DatePickerSelectorProps {
  selectedDateFilter: DateFilterTypes;
  selectedDateFrom: Date;
  selectedDateTo: Date;
  onChangeDateFromCallback: (date: Date | null) => void;
  onChangeDateToCallback: (date: Date | null) => void;
  dateFilterMode: DateFilterMode;
  isOpenInitially?: boolean;
}

const DatePickerSelector = ({
  selectedDateFilter,
  selectedDateFrom,
  selectedDateTo,
  onChangeDateFromCallback,
  onChangeDateToCallback,
  dateFilterMode,
  isOpenInitially = false,
}: DatePickerSelectorProps) => {
  const minDatePickerDateDateDependingOnFilterMode =
    dateFilterMode === DateFilterMode.PAST ? new Date('2021-01-01') : startOfMonth(new Date());

  const maxDatePickerDateDependingOnFilterMode =
    dateFilterMode === DateFilterMode.PAST ? new Date() : addMonths(new Date(), 6);

  return selectedDateFilter === DateFilterTypes.MONTH ? (
    <DatePicker
      value={selectedDateFrom}
      onChangeDateCallback={onChangeDateFromCallback}
      monthYearPicker
      minDate={minDatePickerDateDateDependingOnFilterMode}
      maxDate={maxDatePickerDateDependingOnFilterMode}
    />
  ) : (
    <DateRangePicker
      dateFrom={selectedDateFrom}
      dateTo={selectedDateTo}
      onChangeDateFromCallback={onChangeDateFromCallback}
      onChangeDateToCallback={onChangeDateToCallback}
      isOpenInitially={isOpenInitially}
      disabled={selectedDateFilter !== DateFilterTypes.CUSTOM}
      dateFilterMode={dateFilterMode}
    />
  );
};

export default DatePickerSelector;
