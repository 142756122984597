import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Role } from '../../auth/role';
import { selectUserRoles } from '../../auth/state/authSlice';
import { DateRange } from '../../components/DateFilter/dateFilterTypes';
import t from '../../constants/translation';
import { errorOccurred, showSnackbar } from '../../notifications/state/notificationsSlice';
import routePaths from '../../routes/routePaths';
import agencyApi, { AgencyRequest } from '../api/agencyApi';
import {
  AgenciesOverview,
  AgenciesResponse,
  Agency,
  AgencyDetails,
  AgencyDetailsRequest,
  AgencyPerformanceOverviewIdRequest,
} from '../model/agency';
import {
  getAgenciesOverview,
  getAgenciesSummary,
  getAgencyDetails,
  getAgencyDetailsById,
  navigateToAgencyDetailsPage,
  saveNewAgency,
  setAgenciesOverview,
  setAgenciesOverviewLoading,
  setAgenciesSummary,
  setAgency,
  setAgencyDetails,
  setAgencyDetailsLoading,
} from './agenciesSlice';

export function* saveNewAgencyHandler(action: PayloadAction<AgencyRequest>) {
  try {
    const agencyToSave = action.payload;
    const agency: Agency = yield call(agencyApi.saveAgency, agencyToSave);
    yield put(showSnackbar({ text: t.agencies['The creation of the Agency was initiated.'] }));
    yield put(setAgency(agency));
    yield put(push(routePaths.hseEmployee.agenciesOverview));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchAgencyDetailsByIdHandler(
  action: PayloadAction<AgencyPerformanceOverviewIdRequest>
) {
  try {
    yield put(setAgencyDetailsLoading(true));
    const agencyDetails: AgencyDetails = yield call(
      agencyApi.getAgencyDetailsById,
      action.payload.agencyId,
      action.payload.dateRange
    );
    yield put(setAgencyDetails(agencyDetails));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setAgencyDetailsLoading(false));
  }
}

export function* fetchAgencyDetailsHandler(action: PayloadAction<AgencyDetailsRequest>) {
  try {
    yield put(setAgencyDetailsLoading(true));
    const agencyDetails: AgencyDetails = yield call(
      agencyApi.getAgencyDetails,
      action.payload.dateRange
    );
    yield put(setAgencyDetails(agencyDetails));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setAgencyDetailsLoading(false));
  }
}

export function* getAgenciesOverviewHandler(action: PayloadAction<DateRange>) {
  try {
    yield put(setAgenciesOverviewLoading(true));
    const agenciesOverview: AgenciesOverview = yield call(
      agencyApi.getAgenciesOverview,
      action.payload
    );
    yield put(setAgenciesOverview(agenciesOverview));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setAgenciesOverviewLoading(false));
  }
}

export function* navigateToAgencyDetailsPageHandler(action: PayloadAction<{ agencyId: string }>) {
  const userRoles: Role[] = yield select(selectUserRoles);
  const route = userRoles.includes(Role.HSE_EMPLOYEE) ? routePaths.hseEmployee.agencyDetails : '';
  const path = generatePath(route, { agencyId: action.payload.agencyId });
  yield put(push(path));
}

export function* getAgenciesSummaryHandler() {
  try {
    const agenciesOverview: AgenciesResponse = yield call(agencyApi.getAgenciesSummary);
    yield put(setAgenciesSummary(agenciesOverview.agencies));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* watcherAgenciesSagas() {
  yield takeLatest(saveNewAgency.type, saveNewAgencyHandler);
  yield takeLatest(getAgencyDetailsById.type, fetchAgencyDetailsByIdHandler);
  yield takeLatest(getAgencyDetails.type, fetchAgencyDetailsHandler);
  yield takeLatest(getAgenciesOverview.type, getAgenciesOverviewHandler);
  yield takeLatest(navigateToAgencyDetailsPage.type, navigateToAgencyDetailsPageHandler);
  yield takeLatest(getAgenciesSummary.type, getAgenciesSummaryHandler);
}
