import { Button, EButtonType, Text } from '@hse24/shared-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openManagedDialog } from '../../../notifications/state/notificationsSlice';
import styles from './DeleteAccount.module.scss';
import DeleteAccountModal from './DeleteAccountModal/DeleteAccountModal';

const DeleteAccount = () => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();

  const openDeleteAccountModal = () =>
    dispatch(
      openManagedDialog({
        title: (
          <span className={styles.modal_title}>
            <DeleteOutlineIcon />
            Account löschen
          </span>
        ),
        component: DeleteAccountModal.name,
      })
    );

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setIsChecked(event.target.checked);

  return (
    <>
      <Text.B1 className={styles.header}>Account löschen</Text.B1>
      <Box mt={2} mb={3}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={isChecked} onChange={onChange} />}
            label="Ich will meinen Account löschen"
          />
        </FormGroup>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={openDeleteAccountModal}
            className={styles.button}
            type={EButtonType.BLANK}
            icon={{ right: <ArrowForwardIcon /> }}
            disabled={!isChecked}
          >
            <Typography fontWeight={700}>Weiter</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DeleteAccount;
