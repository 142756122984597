import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Audience, Platform } from '../../../common/types';
import AudienceFilter from '../../../components/AudienceFilter/AudienceFilter';
import DateFilterInPast, {
  PastDateFilters,
} from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import SwitchWithTooltip from '../../../components/SwitchWithTooltip/SwitchWithTooltip';
import { Pageable } from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import AdminPastShowsTable from '../../component/AdminPastShowsTable/AdminPastShowsTable';
import {
  QueryData,
  selectAdminPastShowsOnlyLivePerformance,
  selectAdminPastShowsQueryData,
  setAdminPastShowsOnlyLivePerformanceAction,
  setAdminPastShowsPlatform,
  setAdminPastShowsQueryData,
} from '../../state/showsSlice';
import styles from './PastShowsOverviewPage.module.scss';

const mapQueryDataToPageable = (queryData: QueryData<PastDateFilters>): Pageable => ({
  page: queryData.page,
  pageSize: queryData.pageSize,
  sortField: queryData.sortField,
  sortOrder: queryData.sortOrder,
  searchTerm: queryData.searchTerm,
});

const PastShowsOverviewPage = () => {
  const PastShowsOverview = () => {
    const dispatch = useDispatch();
    const queryData = useSelector(selectAdminPastShowsQueryData);
    const onlyLivePerformance = useSelector(selectAdminPastShowsOnlyLivePerformance);

    const onSwitchToggle = (checked: boolean) => {
      return dispatch(setAdminPastShowsOnlyLivePerformanceAction(checked));
    };

    const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) =>
      dispatch(
        setAdminPastShowsQueryData({
          dateFrom: dateRangeWithSelectedFilter.startDate,
          dateTo: dateRangeWithSelectedFilter.endDate,
          dateFilter: dateRangeWithSelectedFilter.dateFilter as PastDateFilters,
        })
      );

    const onPageableChange = (pageable: Pageable) =>
      dispatch(setAdminPastShowsQueryData({ ...pageable }));

    const resetSearchTermOnInit = () => {
      // We have to reset the searchTerm (which is used in the search field) on init,
      // because it's not possible to set its value and show in the search field (which belongs to the material ui data grid).
      // If we don't do that, there will be filtered out results (which contain the search term) but the search field will be empty, what leads to confusion.
      dispatch(setAdminPastShowsQueryData({ searchTerm: undefined }));
    };

    useEffect(() => {
      resetSearchTermOnInit();
    }, [dispatch]);

    const onFilterAudience = (audience: Audience[], platforms?: Platform[]) => {
      dispatch(setAdminPastShowsQueryData({ audience }));
      if (platforms) {
        dispatch(setAdminPastShowsPlatform(platforms));
      }
    };

    return (
      <div className={styles.container}>
        <Box className={styles.table_filter}>
          <Box>
            <DateFilterInPast
              onDateFiltersChange={onDateFiltersChange}
              selectedFilter={queryData.dateFilter}
              selectedDateFrom={queryData?.dateFrom}
              selectedDateTo={queryData?.dateTo}
            />
          </Box>
          <Box>
            <Typography variant={'h6'} className={styles.audience_filter_header}>
              Audience
            </Typography>
            <AudienceFilter
              audienceFilter={queryData.audience}
              onSelect={onFilterAudience}
              useBadges={false}
              shouldUseAllPlatforms={true}
            />
          </Box>
          <Box>
            <SwitchWithTooltip
              checked={onlyLivePerformance}
              onChange={event => onSwitchToggle(event.target.checked)}
              labelText="nur Live-Performance"
              tooltipText="Performance-Daten berücksichtigen nur Interaktionen während des Live-Streams."
            />
          </Box>
        </Box>
        <Box>
          <AdminPastShowsTable
            pageable={mapQueryDataToPageable(queryData)}
            pageableCallback={onPageableChange}
          />
        </Box>
      </div>
    );
  };
  return <PageContainer backgroundColor={theme.whiteLilac} pageToRender={PastShowsOverview()} />;
};

export default PastShowsOverviewPage;
