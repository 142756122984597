import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { GridColDef, GridFeatureModeConstant, GridRowParams } from '@mui/x-data-grid';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../../constants/translation';
import DataGridTable from '../../../creators/components/DataGridTable/DataGridTable';
import { TableFilter } from '../../../creators/state/creatorsSlice';
import styles from '../../../payments/components/InvoicesTable/InvoicesTable.module.scss';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { AgencyOverview } from '../../model/agency';
import {
  getAgenciesOverview,
  navigateToAgencyDetailsPage,
  selectAgenciesDateRange,
  selectAgenciesOverview,
  selectAgenciesOverviewIsLoading,
  selectAgenciesTableFilter,
  setAgenciesOverviewTableFilter,
} from '../../state/agenciesSlice';

function AgenciesTable() {
  const dispatch = useDispatch();
  const agencies = useSelector(selectAgenciesOverview) ?? [];
  const isLoading = useSelector(selectAgenciesOverviewIsLoading);
  const overviewTableFilter = useSelector(selectAgenciesTableFilter);
  const agenciesDateRange = useSelector(selectAgenciesDateRange);

  useEffect(() => {
    if (agenciesDateRange) {
      dispatch(getAgenciesOverview(agenciesDateRange));
    }
  }, [dispatch, agenciesDateRange]);

  const onRowClicked = (rowParams: GridRowParams<AgencyOverview>) => {
    dispatch(navigateToAgencyDetailsPage({ agencyId: rowParams.row.id }));
  };

  const onFilterStateChange = (state: TableFilter) => {
    const updatedObject = {
      pagination: state.pagination,
      sorting: state.sorting,
    };

    if (!_.isEqual(updatedObject, overviewTableFilter)) {
      dispatch(
        setAgenciesOverviewTableFilter({
          pagination: state.pagination,
          sorting: state.sorting,
        })
      );
    }
  };

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', minWidth: 300 },
    {
      field: 'streamersNumber',
      headerName: t.creators['Number of streamers'],
      width: 200,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'revenue',
      headerName: t.common.Revenue,
      width: 180,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      sortComparator: (v1, v2) => v1.amount - v2.amount,
    },
    {
      field: 'averageRevenue',
      headerName: t.creators['Revenue per streamer'],
      width: 200,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      sortComparator: (v1, v2) => v1.amount - v2.amount,
    },
    {
      field: 'icon',
      headerName: '',
      renderCell: () => (
        <div className={styles.nav_icon}>
          <ChevronRightIcon />
        </div>
      ),
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <DataGridTable
      mode={GridFeatureModeConstant.client}
      columns={columns}
      rows={agencies}
      onRowClick={onRowClicked}
      quickFilterPlaceholder={'Suchen...'}
      height={630}
      rowsPerPageOptions={[10, 20, 50]}
      loading={agencies.length == 0 && isLoading} // Show loading spinner only if there are no rows in the table
      initialState={overviewTableFilter}
      onStateChange={onFilterStateChange}
    />
  );
}

export default AgenciesTable;
