import { Dialog } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectIsFiltersModalOpen } from '../../state/productsSlice';
import Filter from '../Filter/Filter';

const FilterModal = () => {
  const isOpen = useSelector(selectIsFiltersModalOpen);

  const onClose = (e: BaseSyntheticEvent) => e.preventDefault();

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="responsive-dialog-title" maxWidth="lg">
      <Filter />
    </Dialog>
  );
};

export default FilterModal;
