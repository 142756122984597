import { Box, Breakpoint, Button, Typography } from '@mui/material';
import cx from 'classnames';
import { addHours, isPast } from 'date-fns';
import { useSelector } from 'react-redux';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import { selectLoggedInCreator } from '../../../../creators/state/creatorsSlice';
import { sendMessageToNativeApp } from '../../../../storage/dataExchangerService';
import { buildClickMessageEvent, ClickEventElement } from '../../../../storage/nativeMessages';
import { UpcomingShow } from '../../../model/shows';
import { notAllowedCreators, notAllowedToStreamAlert } from '../../../utils/notAllowedCreators';
import ShowCard from '../../ShowCard/ShowCard';
import ShowCardContent from '../../ShowCard/ShowCardContent/ShowCardContent';
import ShowScheduledAtOrCountdown from '../../ShowScheduledAtOrCountdown/ShowScheduledAtOrCountdown';
import UpcomingShowsTableMenu from '../../TableMenu/UpcomingShowsTableMenu';
import styles from './ShowCardWithUpcomingShow.module.scss';

interface ShowCardWithUpcomingShowProps {
  upcomingShow: UpcomingShow;
  size?: Breakpoint;
}

const ShowCardWithUpcomingShow = ({ upcomingShow, size }: ShowCardWithUpcomingShowProps) => {
  const creator = useSelector(selectLoggedInCreator);
  const expiredShow = isPast(addHours(new Date(upcomingShow.scheduledAt), 1));

  const handleStartStreaming = () => {
    if (creator && notAllowedCreators.includes(creator.id)) {
      alert(notAllowedToStreamAlert);
    } else {
      sendMessageToNativeApp(
        buildClickMessageEvent(ClickEventElement.START_STREAMING, upcomingShow.id)
      );
    }
  };

  return (
    <ShowCard
      size={size}
      previewImageUrl={upcomingShow.previewImageUrl}
      badge={expiredShow ? <>Nicht gestartet</> : null}
    >
      <ShowCardContent>
        <Typography variant="subtitle1" fontWeight="bold">
          <ShowScheduledAtOrCountdown startingAt={upcomingShow.scheduledAt} />
        </Typography>
        <Typography variant="subtitle1" mb={2} className={styles.multiLineEllipsis}>
          {upcomingShow.title}
        </Typography>
        <Box className={styles.buttons}>
          <Box className={styles.btn}>
            <Button
              variant="contained"
              className={cx({ [styles.disabled]: expiredShow })}
              endIcon={<Icon dataTestId="video-camera-icon" icon={Icons.videoCamera} />}
              onClick={handleStartStreaming}
              disableElevation
              fullWidth
              size="medium"
              disabled={expiredShow}
            >
              Starten
            </Button>
          </Box>
          <Box className={styles.card_menu}>
            <UpcomingShowsTableMenu
              iconClassname={styles.three_dots_icon}
              show={upcomingShow}
              hideStartStream
            />
          </Box>
        </Box>
      </ShowCardContent>
    </ShowCard>
  );
};

export default ShowCardWithUpcomingShow;
