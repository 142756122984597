import InfoIcon from '@mui/icons-material/Info';
import styles from './ShowProductSearchNoResults.module.scss';

const ShowProductSearchNoResults = () => {
  return (
    <li className={styles.no_results}>
      <InfoIcon className={styles.info_icon} />
      <div>
        <strong>Die Suche ergab kein Ergebnis.</strong>
        <p>Bitte prüfe die Rechtschreibung oder ändere den Suchbegriff.</p>
      </div>
    </li>
  );
};

export default ShowProductSearchNoResults;
