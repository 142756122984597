import { useDispatch, useSelector } from 'react-redux';
import { redirectToLogin } from '../../../auth/state/authSlice';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import {
  closeConfirmationDialog,
  ConfirmationDialogDetails,
  selectConfirmationDialogDetails,
} from '../../state/notificationsSlice';

export const redirectToLoginConfirmationDialogDetails = {
  title: 'Anmeldung',
  contentText: 'Deine Anmeldung ist abgelaufen, bitte melde Dich erneut an.',
  actionToDispatchOnClose: redirectToLogin(),
};

const ConfirmationDialog = () => {
  const dispatch = useDispatch();
  const confirmationDialogDetails: ConfirmationDialogDetails | undefined = useSelector(
    selectConfirmationDialogDetails
  );
  const isOpen = !!confirmationDialogDetails?.toggle;

  const onButtonClick = () => {
    if (confirmationDialogDetails?.actionToDispatchOnClose) {
      dispatch(confirmationDialogDetails?.actionToDispatchOnClose);
    }
    if (confirmationDialogDetails?.actionToTrigger) {
      confirmationDialogDetails?.actionToTrigger();
    }
    dispatch(closeConfirmationDialog());
  };

  const onClose = () => {
    dispatch(closeConfirmationDialog());
  };

  return (
    <StyledDialog
      dialogProps={{
        open: isOpen,
        ['aria-labelledby']: 'responsive-dialog-title',
      }}
      close={onClose}
      title={confirmationDialogDetails?.title}
      body={confirmationDialogDetails?.contentText}
      submit={{
        title: confirmationDialogDetails?.submit ?? 'Weiter',
        action: onButtonClick,
      }}
      cancel={{
        title: 'Weiter bearbeiten',
        action: onClose,
      }}
    />
  );
};

export default ConfirmationDialog;
