// HELLO default show images
export const DEFAULT_CURTAIN_BEFORE_IMAGE_URL =
  'https://www.hse.de/dpl/files/c/slc/hello/curtains/default_before_curtain.jpeg';

export const DEFAULT_CURTAIN_AFTER_IMAGE_URL =
  'https://www.hse.de/dpl/files/c/slc/hello/curtains/default_after_curtain.jpeg';

export const DEFAULT_PREVIEW_IMAGE_URL =
  'https://www.hse.de/dpl/files/c/slc/hello/default_show_cover.jpeg';

// CLASSIC default show images
export const DEFAULT_CURTAIN_BEFORE_IMAGE_URL_CLASSIC =
  'https://www.hse.de/dpl/files/c/slc/classic/curtains/default_before_curtain.png';

export const DEFAULT_CURTAIN_AFTER_IMAGE_URL_CLASSIC =
  'https://www.hse.de/dpl/files/c/slc/classic/curtains/default_after_curtain.png';

export const DEFAULT_PREVIEW_IMAGE_URL_CLASSIC =
  'https://www.hse.de/dpl/files/c/slc/classic/default_show_cover.png';

export const gracePeriodToStartStreamInHours = 3;
