import { ETag, IconTruck, Modal, Text } from '@hse24/shared-components';
import { Button, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AddressRecommendation } from '../../../model/addressRecommendation';
import {
  hideAddressRecommendation,
  selectAddressRecommendation,
  selectProfilePersonalData,
  selectProfilePersonalDataAddressRecommendation,
  selectShowAddressRecommendationModal,
  updateCreatorDetails,
} from '../../../state/creatorsSlice';
import styles from './AddressRecommendationModal.module.scss';

const AddressRecommendationModal = () => {
  const dispatch = useDispatch();

  const showRecommendationModal = useSelector(selectShowAddressRecommendationModal);
  const profilePersonalDataWithAddressRecommendation = useSelector(
    selectProfilePersonalDataAddressRecommendation
  );
  const profilePersonalData = useSelector(selectProfilePersonalData);
  const recommendation = useSelector(selectAddressRecommendation);
  const emptyRecommendation: AddressRecommendation = {
    street: '',
    streetNumber: '',
    zipCode: '',
    city: '',
  };

  const { street, streetNumber, city, zipCode } = recommendation
    ? recommendation
    : emptyRecommendation;

  function onRecommendedAddressModalAccept(): void {
    if (profilePersonalDataWithAddressRecommendation) {
      dispatch(updateCreatorDetails(profilePersonalDataWithAddressRecommendation));
    }
  }

  function onRecommendedAddressModalReject(): void {
    if (profilePersonalData) {
      dispatch(updateCreatorDetails(profilePersonalData));
    }
  }

  function onRecommendedAddressModalClose(): void {
    dispatch(hideAddressRecommendation());
  }

  return (
    <Modal
      showModal={showRecommendationModal}
      onRequestClose={onRecommendedAddressModalClose}
      className={styles.wrapper}
    >
      <Grid container direction="column" spacing={0} data-testid="address-recommendation-modal">
        <Grid container justifyContent="center">
          <Grid item>
            <IconTruck className={styles.truck_icon} width="64px" />
          </Grid>
        </Grid>
        <Grid item>
          <Text.B2
            tag={ETag.DIV}
            className={styles.title}
            data-testid="address-recommendation-modal-address-field"
          >
            Empfohlene Adresse
          </Text.B2>
        </Grid>
        <Grid item>
          <Text.Body tag={ETag.DIV} className={styles.question}>
            Könnte das die gewünschte Adresse sein?
          </Text.Body>
        </Grid>
        <Grid item>
          <Text.Body tag={ETag.DIV} className={styles.detail}>
            {`${street} ${streetNumber}`}
          </Text.Body>
        </Grid>
        <Grid item>
          <Text.Body tag={ETag.DIV} className={styles.detail}>
            {`${zipCode} ${city}`}
          </Text.Body>
        </Grid>
        <Grid
          container
          spacing={4}
          alignItems="center"
          justifyContent="flex-end"
          className={styles.action_buttons}
        >
          <Grid item onClick={onRecommendedAddressModalReject}>
            <Text.Body
              tag={ETag.DIV}
              className={styles.reject_link}
              data-testid="address-recommendation-modal-no-button"
            >
              Nein, von mir eingegebene Adresse verwenden
            </Text.Body>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={onRecommendedAddressModalAccept}
              className={styles.yes_button}
              data-testid="address-recommendation-modal-yes-button"
            >
              Ja
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddressRecommendationModal;
