export const loadScript = (
  srcUrl: string,
  isAsync: boolean,
  id: string | undefined,
  attributes: Record<string, string> = {}
) => {
  if (isScriptLoaded(srcUrl, id)) {
    return;
  }

  const script = document.createElement('script');

  script.async = isAsync;
  script.src = srcUrl;
  if (id) {
    script.id = id;
  }
  Object.entries(attributes).forEach(([key, value]) => {
    script.setAttribute(key, value);
  });

  document.body.appendChild(script);
};

const isScriptLoaded = (srcUrl: string, id?: string): boolean => {
  if (id) {
    return document.getElementById(id) != null;
  }

  return document.querySelector(`script[src="${srcUrl}"]`) != null;
};
