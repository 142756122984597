import { MediaType, PostPreSignedUrlRequest } from '../api/postsRequestResponse';
import { PostUploadFileType } from '../model/post';
import { FileExtension } from '../state/postsSlice';

type PostFile = {
  type: 'image' | 'video';
  extension: string;
};

export const extractMediaType = (dataUri: string): PostFile | null => {
  const mediaTypePattern = /^data:([^;]*);base64,.*/;
  const match = dataUri.match(mediaTypePattern);
  if (match) {
    const [type, extension] = match[1].split('/');
    return { type, extension } as PostFile;
  }
  return null;
};

export const getPostUploadFileType = (mimeType: string): PostUploadFileType => {
  if (mimeType.startsWith('image/')) {
    return PostUploadFileType.IMAGE;
  }

  if (mimeType.startsWith('video/')) {
    return PostUploadFileType.VIDEO;
  }

  return PostUploadFileType.FILE;
};

export const getReadableFileSize = (sizeInBytes: number): string => {
  const units = ['bytes', 'KB', 'MB', 'GB'];
  let index = 0;
  let size = sizeInBytes;

  while (size >= 1024 && index < units.length - 1) {
    size /= 1024;
    index++;
  }

  return `${size.toFixed(2)} ${units[index]}`;
};

export const getFileExtension = (fileName: string): string => {
  return fileName.split('.').pop() || '';
};

export const getFileName = (fileName: string): string => {
  const parts = fileName.split('.');
  return parts.slice(0, parts.length - 1).join('.') || fileName;
};

export const isFileExtensionSupported = (fileExtension: string) => {
  return Object.values(FileExtension).includes(fileExtension as FileExtension);
};

export const isImage = (fileType: string) => {
  return fileType.startsWith('image/') || fileType == MediaType.IMAGE;
};

export const isVideo = (fileType: string) => {
  return fileType.startsWith('video/') || fileType == MediaType.VIDEO;
};

export const filterSelectedFiles = (chosenFiles: FileList, indexingStart = 0) => {
  const files: {
    type: string;
    url: string | null;
    name: string;
    size: number;
    isSupported: boolean;
    index: number;
  }[] = [];
  const attachments: PostPreSignedUrlRequest[] = [];
  Array.from(chosenFiles).map(async (file, index) => {
    const fileExtension = getFileExtension(file.name);
    const isSupported = isFileExtensionSupported(fileExtension);
    if (isImage(file.type) && isSupported) {
      attachments.push({
        fileExtension: fileExtension,
        mediaType: MediaType.IMAGE,
        fileName: getFileName(file.name),
      });
    } else if (isVideo(file.type) && isSupported) {
      attachments.push({
        fileExtension: fileExtension,
        mediaType: MediaType.VIDEO,
        fileName: getFileName(file.name),
      });
    }
    const url = URL.createObjectURL(file);
    files.push({
      name: file.name,
      size: file.size,
      type: file.type,
      url: url,
      isSupported,
      index: indexingStart + index,
    });
  });
  return {
    files: files.sort((a, b) => a.index - b.index),
    attachments: attachments,
  };
};

export const replaceSpaceBy = (fileName: string, replaceValue: string) => {
  return fileName.trim().replace(/ /g, replaceValue);
};

export const moveItem = <FileMetaData>(
  array: FileMetaData[],
  index: number,
  direction: 1 | -1
): FileMetaData[] => {
  const newIndex = index + direction;

  if (newIndex < 0 || newIndex >= array.length) {
    return array;
  }

  const newArray = [...array];

  const itemToSwipe = array[index];
  const itemToSwipeWith = array[newIndex];
  newArray[newIndex] = { ...itemToSwipe, index: newIndex };
  newArray[index] = { ...itemToSwipeWith, index: index };

  return newArray;
};

export const getMediaType = (url: string): MediaType => {
  const fileExtension = getFileExtension(url).toLowerCase();
  if (Object.values(FileExtension).includes(fileExtension as FileExtension)) {
    switch (fileExtension as FileExtension) {
      case FileExtension.MOV:
      case FileExtension.MP4:
      case FileExtension.MKV:
      case FileExtension.WEBM:
      case FileExtension.MPEG:
      case FileExtension.QUICKTIME:
        return MediaType.VIDEO;
      case FileExtension.JPG:
      case FileExtension.JPEG:
      case FileExtension.PNG:
        return MediaType.IMAGE;
    }
  }
  throw new Error(`Unsupported file extension: ${fileExtension}`);
};
export const getSupportedFileExtension = (fileExtension: string): FileExtension => {
  if (Object.values(FileExtension).includes(fileExtension as FileExtension)) {
    return fileExtension as FileExtension;
  }
  throw new Error(`Unsupported file extension: ${fileExtension}`);
};

export const shortenFileName = (fileName: string, maxLength = 15): string => {
  if (fileName.length > maxLength) {
    return `${fileName.slice(0, 7)}...`;
  }
  return fileName;
};
