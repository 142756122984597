import cx from 'classnames';
import styles from './FeeCard.module.scss';

interface Props {
  title: string;
  value: string;
  dataTestId?: string;
  className?: string;
}
const FeeCard = ({ title, value, className, dataTestId }: Props) => {
  return (
    <div className={cx(className, styles.text_row)}>
      <div className={styles.text_content}>
        <div className={styles.text}>{title}</div>
      </div>
      <div className={styles.text_content}>
        <div className={styles.price} data-testid={dataTestId}>
          {value}
        </div>
      </div>
    </div>
  );
};

export default FeeCard;
