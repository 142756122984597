/**
 * Default {@link ChatLogger } implementation for {@link ChatRoom }. That passes log messages to the
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/console | `console` } object.
 */
export class ConsoleLogger {
    debug(message, ...args) {
        console.debug(message, ...args);
    }
    info(message, ...args) {
        console.info(message, ...args);
    }
    error(message, ...args) {
        console.error(message, ...args);
    }
}
