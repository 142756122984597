import { Input, LoadingSpinner } from '@hse24/shared-components';
import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import classes from './Dropdown.module.scss';

interface DropdownProps {
  options: SelectOption[];
  onSelect: (selectedOption: string) => void;
  defaultOption?: string;
  defaultLabel?: React.ReactNode;
  styles?: Styles;
  isLoadingOptions: boolean;
}

export interface SelectOption {
  label: React.ReactNode;
  value: string;
  key: string;
  searchValue: string;
}

export interface Styles {
  dropdown?: string;
  dropdownHeader?: string;
  dropdownOptions?: string;
  dropdownOption?: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  defaultOption,
  defaultLabel,
  styles,
  isLoadingOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultOption || '');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => setFilteredOptions(options), [options]);

  useEffect(() => {
    setSelectedValue(defaultOption ?? '');
  }, [defaultOption]);

  const handleToggle = () => {
    setIsOpen(prevState => !prevState);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setIsOpen(false);
    setFilteredOptions(options);
    onSelect(value);
  };

  const selectedLabel = options.find(option => option.value === selectedValue)?.label;

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!dropdownRef.current?.contains(event.relatedTarget as Node)) {
      setIsOpen(false);
    }
  };

  const onChangeHandler = (event: React.FormEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;
    setFilteredOptions(
      options.filter(option => option.searchValue.toLowerCase().includes(query.toLowerCase()))
    );
  };

  return (
    <div
      data-testid="dropdown"
      tabIndex={0}
      onBlur={handleBlur}
      ref={dropdownRef}
      className={cx(classes.dropdown, styles?.dropdown)}
    >
      <div
        data-testid="dropdown-header"
        className={cx(classes.dropdown_header, styles?.dropdownHeader)}
        onClick={handleToggle}
      >
        {isOpen ? (
          <Input
            name=""
            className={classes.input}
            type="text"
            autoFocus
            onChange={onChangeHandler}
          />
        ) : (
          <div>{selectedLabel || defaultLabel || 'Select an option'}</div>
        )}
      </div>
      {isOpen && (
        <>
          {isLoadingOptions ? (
            <div data-testid="loading-spinner" className={classes.loading_container}>
              <LoadingSpinner />
            </div>
          ) : (
            <div
              className={cx(classes.dropdown_options, styles?.dropdownOptions)}
              data-testid="options"
            >
              {filteredOptions.map(option => (
                <div
                  key={option.key}
                  className={cx(classes.dropdown_option, styles?.dropdownOption)}
                  onClick={() => handleOptionClick(option.value)}
                  data-testid={`${option.key}-option`}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Dropdown;
