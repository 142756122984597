import cx from 'classnames';
import { ReactNode } from 'react';
import styles from './DataCard.module.scss';

interface Props {
  label: string;
  value: string | number;
  subLabel?: string;
  className?: string;
  important?: boolean;
  toolTip?: ReactNode;
}

const DataCard = ({ label, value, subLabel, className, important, toolTip }: Props) => {
  return (
    <div
      className={cx(styles.container, className, {
        [styles.border]: important,
      })}
    >
      <div className={styles.top_wrapper}>
        <div className={styles.hero}>
          <p className={styles.label}>{label}</p>
          {toolTip}
        </div>
        {subLabel && <span className={styles.sub_label}>{subLabel}</span>}
      </div>
      <p className={styles.value}>{value}</p>
    </div>
  );
};

export default DataCard;
