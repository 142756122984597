import { createTheme } from '@mui/material';
import { latoBold, latoRegular } from './fonts';
import theme from './theme.module.scss';

export const baseTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 764,
      md: 992,
      lg: 1212,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: theme.primary,
    },
    secondary: {
      main: theme.secondary,
    },
    background: {
      default: theme.background,
    },
    error: {
      main: theme.error,
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          display: 'inherit',
          boxSizing: 'inherit',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&$focused:not($error)': {
            color: theme.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&$focused $notchedOutline': {
            borderColor: theme.secondary,
          },
        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #fff inset',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
        sizeSmall: {
          fontWeight: 600,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: theme.success,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [latoRegular, latoBold],
        body: {
          // Set MUI v4 defaults (see https://mui.com/guides/migration-v4/#cssbaseline)
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.paleOrange,
            opacity: 1,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: theme.paleOrange,
            opacity: 1,
            color: theme.grayDark,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            fill: theme.white,
            border: `1px solid ${theme.primary}`,
            borderRadius: '50%',
            text: {
              fill: theme.primary,
              fontWeight: 700,
            },
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h2: {
          fontSize: theme.fontSize24,
          fontWeight: theme.fontWeight9,
          lineHeight: theme.lineHeight40,
        },
        h3: {
          fontSize: theme.fontSize24,
          fontWeight: theme.fontWeight7,
          lineHeight: theme.lineHeight32,
        },
        body1: {
          fontSize: theme.fontSize16,
          fontWeight: theme.fontWeight4,
          lineHeight: theme.lineHeight50,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          marginTop: '4px !important',
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.nightBlueNightblue4Lightest,
        },
      },
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
  zIndex: {
    appBar: 99,
  },
});
