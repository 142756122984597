import { FunctionComponent } from 'react';
import womanBloggerWithRingLight from '../assets/woman-blogger-with-ring-light.png';
import womenStartingLiveStream from '../assets/women-starting-live-stream.png';
import ActivationError from './components/ActivationSteps/ActivationError/ActivationError';
import AddressStep from './components/ActivationSteps/AddressStep/AddressStep';
import ExistingAccountStep from './components/ActivationSteps/ExistingAccountStep/ExistingAccountStep';
import NewAccountStep from './components/ActivationSteps/NewAccountStep/NewAccountStep';
import WelcomeStep from './components/ActivationSteps/WelcomeStep/WelcomeStep';
import ForgetPasswordStep from './components/AuthSteps/ForgetPasswordStep/ForgetPasswordStep';
import LoginPasswordStep from './components/AuthSteps/LoginPasswordStep/LoginPasswordStep';
import ResetPasswordEmail from './components/AuthSteps/ResetPasswordEmail/ResetPasswordEmail';
import SetPasswordStep from './components/AuthSteps/SetPasswordStep/SetPasswordStep';
import { ActivationStep, AuthStep } from './state/authSlice';

interface StepImage {
  alt: string;
  src: string;
}

const womenStartingLiveStreamImage: StepImage = {
  alt: 'women starting live stream',
  src: womenStartingLiveStream,
};

const womanBloggerWithRingLightImage: StepImage = {
  alt: 'woman blogger with ring light',
  src: womanBloggerWithRingLight,
};

export interface StepInfo {
  component: FunctionComponent;
  image: StepImage;
  recaptcha: boolean;
}

export const authSteps: Map<AuthStep, StepInfo> = new Map([
  [
    AuthStep.LOGIN_PASSWORD_STEP,
    { component: LoginPasswordStep, image: womenStartingLiveStreamImage, recaptcha: true },
  ],
  [
    AuthStep.FORGET_PASSWORD_STEP,
    { component: ForgetPasswordStep, image: womenStartingLiveStreamImage, recaptcha: true },
  ],
  [
    AuthStep.RESET_PASSWORD_EMAIL,
    { component: ResetPasswordEmail, image: womanBloggerWithRingLightImage, recaptcha: false },
  ],
  [
    AuthStep.SET_PASSWORD_STEP,
    { component: SetPasswordStep, image: womanBloggerWithRingLightImage, recaptcha: false },
  ],
]);

export const activationSteps: Map<ActivationStep, StepInfo> = new Map([
  [
    ActivationStep.WELCOME_STEP,
    { component: WelcomeStep, image: womenStartingLiveStreamImage, recaptcha: false },
  ],
  [
    ActivationStep.NEW_ACCOUNT_STEP,
    { component: NewAccountStep, image: womenStartingLiveStreamImage, recaptcha: true },
  ],
  [
    ActivationStep.EXISTING_ACCOUNT_STEP,
    { component: ExistingAccountStep, image: womenStartingLiveStreamImage, recaptcha: true },
  ],
  [
    ActivationStep.ERROR_OCCURRED,
    { component: ActivationError, image: womenStartingLiveStreamImage, recaptcha: false },
  ],
  [
    ActivationStep.ADDRESS_STEP,
    { component: AddressStep, image: womenStartingLiveStreamImage, recaptcha: true },
  ],
]);
