import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAction, selectAuthProgress } from '../../../state/authSlice';
import AuthForm, { AuthFormValues } from '../../AuthForm/AuthForm';

function ForgetPasswordStep() {
  const dispatch = useDispatch();

  const { email } = useSelector(selectAuthProgress);

  const handleFromSubmit = (values?: AuthFormValues) => {
    if (values?.email) {
      dispatch(resetPasswordAction(values.email));
    }
  };

  return (
    <AuthForm
      header={
        <>
          <Typography variant={'h3'}>Passwort vergessen?</Typography>
          <Typography variant={'body1'}>Ist deine E-Mail-Adresse korrekt?</Typography>
          <Typography variant={'body1'}>
            Wir senden dir dann eine Nachricht mit einem Link, über den du dein Passwort
            zurücksetzen kannst.
          </Typography>
        </>
      }
      submitButton={{ title: 'Passwort zurücksetzen' }}
      submitCallback={handleFromSubmit}
      email={{ value: email ?? '' }}
    />
  );
}

export default ForgetPasswordStep;
