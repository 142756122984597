import { Curtains, ShowImages } from '../model/shows';

export const mapCurtainsToShowImages = (curtains: Curtains | undefined): ShowImages | undefined => {
  if (!curtains) return;

  return {
    curtainBeforeImageUrl: curtains.beforeImageUrl,
    curtainAfterImageUrl: curtains.afterImageUrl,
    previewImageUrl: curtains.previewImageUrl,
  };
};
