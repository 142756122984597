import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import CreatorStreamSettings from '../CreatorStreamSettings/CreatorStreamSettings';
const CreatorStreamSettingsPage = () => {
  return (
    <PageContainer pageToRender={<CreatorStreamSettings />} backgroundColor={theme.whiteLilac} />
  );
};

export default CreatorStreamSettingsPage;
