import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box } from '@mui/material';
import React from 'react';
import { AcademyVideoStatus } from '../../academy/model/academy';

interface Props {
  status: AcademyVideoStatus;
}

function VideoStatusBadge({ status }: Props) {
  return (
    <>
      {status !== AcademyVideoStatus.NOT_STARTED && (
        <Box
          color={
            status === AcademyVideoStatus.STARTED ? 'rgba(255, 180, 0, 1)' : 'rgba(46, 125, 50, 1)'
          }
        >
          {status === AcademyVideoStatus.STARTED ? (
            <AccessTimeOutlinedIcon color={'inherit'} />
          ) : (
            <CheckCircleOutlinedIcon color={'inherit'} />
          )}
        </Box>
      )}
    </>
  );
}

export default VideoStatusBadge;
