import t from '../../constants/translation';

export const notAllowedCreators = [
  'c4cfc9d2-62de-4177-8508-f36d23f0df0e',
  '37c2a414-42d0-405a-bfda-0a845e2831cb',
  'd726a068-c5aa-4e1e-80a0-c163677aae2a',
  '822f765a-51e1-4641-a9ae-a88c88d0047c',
  '424d8785-05e9-4b92-aa01-61434ed8d53c',
  'db31e517-4fb5-412e-9a29-3ee7318a7bd9',
  '98246da4-3804-46b6-ad1d-3d55036f196b',
  'c49638a5-f797-4323-80d5-4373bf466ae5',
  'ec4e4b3a-827d-4afc-92da-2444537862ba',
  '1f5c0ff5-5d7d-48f4-8860-e6f783a87614',
  'd08993e3-624d-41b8-836b-89c0734ecd7d',
  '67d3471d-571c-4a28-b81b-ab14b5dc0135',
  'd674173d-2e76-4ce3-b9df-c7f47e24371d',
  '299900f7-c4d7-4339-80f8-b9976306e5e2',
  'f33be04a-3e76-4ed1-b491-64cf4ed77aea',
  '56507d9f-1da6-41e2-b282-23f2c0f967c5',
  '43b4acda-abf0-40f6-a615-5e45dfe4326c',
  '70456494-c8eb-47dd-b814-d4721a9cf302',
  '98977ead-45bb-41f1-ba2f-743c86d3576c',
  'b06a40d1-f79a-4e8b-90f3-40f31275bf51',
  '66538d3e-bb40-4e0e-8cbd-9d55e51f9692',
  'ddd07629-8221-48e7-897a-956900eef62e',
  'fc559586-4037-4c0a-85a3-6282de937647',
  'b82c8cb7-ad47-4bea-96c6-6598dfda933c',
  '2f517e00-d972-4152-a9e6-fa6d9ee39185',
  'a1470e4f-dfa1-4515-8087-9354205dfa01',
  '4934c437-4a7a-43a1-ae34-5480c480ea9a',
  '4e453843-2326-4770-b7db-31a2a01c238a',
  '028ac7f5-8d8a-49c3-a21a-5ffebb6dd243',
  '8dc223cd-eb65-41ba-a0fb-3ff92e602403',
  'd0e2388a-eb4e-491b-9a32-7545aa8138cd',
  'b309466b-5ed9-4353-8dfe-734cd52e4a09',
  '0e089875-d7e7-4cc6-a66c-9c121b20a5c2',
  'ce3b6945-2ea5-40d3-988e-74cb701b0c54',
  '4f7e6cdd-7563-4efc-99f6-826f7545ebee',
  '39a327d2-c839-4b47-8322-0c4a9c50fa25',
  '7c588324-1f3f-48eb-a534-226dec1477d9',
  '745b15b3-ce0a-458e-b0e0-f7d066fd1341',
  '1bc15b2c-0c93-4f78-b44a-b59902081367',
  '7dfef429-deac-4873-a5e4-1d52faaac658',
  '2f351422-2147-46b1-9dea-b040f79a4f6f',
  '76afeaa4-6a9b-4827-a800-75a9515b53b9',
  '7c3e10f2-1019-47dc-8107-d34d7b80774a',

  // TV-Creators:
  'be4087df-b0ff-4cd8-9fc1-216540373f23',
  '45a9851e-0171-4ab7-9f6e-ada5f90d5b36',
  '5526af4b-dbdd-4289-930b-10faf61e47bf',
  '795cad9a-5201-45ac-9f8a-f1cde663e3fd',
  '20f45838-a562-4e0c-9c0d-115b10e6b6b7',
  'e1ec1059-04ba-4cb5-aa4e-1356f1661ce7',
  'ff8f6aeb-df17-4d3c-9f4d-aec335a0277b',
  'cb2f83d9-becb-4100-a642-a123b8f9d743',
  'f8f5b8a3-1d0d-4d63-9724-3fc4dd14d784',
  '78d2940c-e968-4dbc-b274-cd71564bd6b3',
  'd9858a1d-517c-4be7-a3c6-29b700a392bb',
  '731de066-228b-4cc2-aa5d-4c5b6978db8d',
  'e6b0de2a-6545-41e8-998d-97fc089c3aa3',
  '569a0bc9-d3cc-4f1e-90a3-9b0627e75c18',
  'c3c1f4ac-560d-4279-8b76-c495b3a16dd5',
  '285eda33-8be2-4210-a197-0994209666b5',
  '0772272f-cb05-49ec-9cf1-b38e53a47c45',
  '18fd70a1-f395-460b-bb50-3b95906be7e5',
  'a99cdae6-be59-4ea4-bd50-567db600509d',
  'a78f8363-7e64-4d03-99a9-684bcb71f959',
  'ba9d1693-ab45-42d7-b799-6e66c401ae9c',
  '811f93d7-e89b-405a-a49c-ad31bad611dd',
  '5824f095-0f06-43f1-a628-814dde35f9f8',
  '814293c3-e4d3-43d1-9cd2-9dd8f2211066',
  'd2ea3259-4fd9-428f-9926-2aba0851cd8b',
  'bdbdbe7a-a07f-4757-b43e-70b88b652e17',
  'f4b5d467-1c11-41f6-b073-d73321162841',
];

export const notAllowedToStreamAlert =
  t.creators["You can't start streams at the moment. Please contact your agency."];

export const notAllowedToCreateStreamAlert =
  t.creators["You can't create shows at the moment. Please contact your agency."];
