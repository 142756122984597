import {
  DefaultPlaceholder,
  ETag,
  InfoFlag,
  LabelFlag,
  LazyLoad,
  PriceInfo,
  ResponsiveImage,
  Text,
  TProductTileProduct,
} from '@hse24/shared-components';
import classNames from 'classnames';
import { FC, ReactNode, SyntheticEvent } from 'react';
import { buildAssetUrl, fallbackImageUrl, staticMediaUrlDefault } from '../../utils/UrlUtils';
import styles from './ProductTile.module.scss';

interface TProductTileProps {
  product: TProductTileProduct;
  isProdEnv: boolean;
  lazyLoadOffset?: number;
  starRatingsSlot?: ReactNode;
  staticMediaUrl?: string;
  onClick?: (e: SyntheticEvent) => void;
  classNames?: string;
}

const VERY_HIGH_OFFSET = 10000;
const IMAGE_SIZES = [320, 480, 640];

export const ProductTile: FC<TProductTileProps> = props => {
  const { isProdEnv } = props;
  const {
    product,
    lazyLoadOffset = isProdEnv ? 300 : VERY_HIGH_OFFSET,
    staticMediaUrl = staticMediaUrlDefault,
    starRatingsSlot,
    onClick = () => null,
  } = props;

  return (
    <div className="no-underline cursor-pointer w-full block" onClick={onClick}>
      <div className="flex-1 flex-col">
        <div className="mb-12 relative flex-1">
          <div className={styles['responsive-image-wrapper']}>
            <LazyLoad
              offset={lazyLoadOffset}
              placeholder={<DefaultPlaceholder style={{ height: '0px', paddingBottom: '125%' }} />}
            >
              {product.imageUri ? (
                <ResponsiveImage
                  sources={IMAGE_SIZES.map(width => ({
                    width,
                    url: buildAssetUrl(staticMediaUrl, product.imageUri!, width),
                  }))}
                  className={classNames(
                    'absolute block left-0 top-0 w-full h-full',
                    styles.rounded
                  )}
                  altText={product.name}
                />
              ) : (
                <img
                  src={fallbackImageUrl}
                  className={styles['responsive-image']}
                  alt={product.name}
                />
              )}
            </LazyLoad>
          </div>
          <InfoFlag
            isNewInShop={product.isNewInShop}
            saving={product?.price?.reference?.savingPercent}
            className={styles.discount}
          />

          {product.salesDriverLabel && (
            <LabelFlag
              className={styles['sales-driver']}
              text={LabelFlag.splitLabel(product.salesDriverLabel)}
            />
          )}
        </div>

        {product.brandName && (
          <Text.Body className={styles.brand} tag={ETag.DIV}>
            {product.brandName}
          </Text.Body>
        )}

        <Text.Body tag={ETag.DIV} className={styles.title}>
          {product.name}
        </Text.Body>

        <PriceInfo {...product.price} referenceClassName={styles.priceReference} />

        <div className="flex items-center mt-4">{starRatingsSlot}</div>

        {product.freeShipping && <p className={styles['free-shipping']}>Versand Gratis</p>}
      </div>
    </div>
  );
};
