import { Box, Button } from '@mui/material';
import Menu, { MenuProps } from '@mui/material/Menu';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../../../layout/Navbar/DesktopNavbar/DesktopNavbar.module.scss';
import { NavigationItem } from '../../../layout/Navbar/navigationItems';
import {
  hideCreateContentDialog,
  selectCreateContentDialog,
  showCreateContentDialog,
} from '../../../notifications/state/notificationsSlice';
import CreateContentCards from '../CreateContentCards/CreateContentCards';
import CreateContentUpcomingShows from '../CreateContentUpcomingShows/CreateContentUpcomingShows';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '0.75rem',
    marginTop: theme.spacing(1),
    width: '32rem',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
}));

interface Props {
  item: NavigationItem;
}

function DesktopCreateContent({ item }: Props) {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectCreateContentDialog);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl) && isOpen;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    dispatch(showCreateContentDialog());
  };

  const handleClose = () => {
    setAnchorEl(null);
    dispatch(hideCreateContentDialog());
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={item.icon}
        data-testid={'nav-tab-item'}
        onClick={handleClick}
        disableElevation
        className={styles.button_link}
        size={'small'}
      >
        {item.name}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box p={2} pt={5}>
          <CreateContentUpcomingShows />
          <CreateContentCards />
        </Box>
      </StyledMenu>
    </>
  );
}

export default DesktopCreateContent;
