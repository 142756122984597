import { Box } from '@mui/material';
import classNames from 'classnames';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import { StreamChatMessage } from '../../state/streamChatSlice';
import styles from './ChatListItem.module.scss';

interface ChatItemProps {
  chatMessage: StreamChatMessage;
  fontSize: number;
  isFollow?: boolean;
}

const ChatListItem = ({ chatMessage, fontSize, isFollow }: ChatItemProps) => {
  return (
    <Box
      className={classNames(styles.item, {
        [styles.follow]: Boolean(isFollow),
      })}
      style={{ fontSize: fontSize, padding: `${fontSize - 8}px` }}
    >
      {chatMessage.isStreamerFollower && (
        <Box className={styles.icon}>
          <Icon dataTestId={'streamer-follower-icon'} icon={Icons.verifiedUser} />
        </Box>
      )}
      <Box>
        <p className={styles.message}>
          <span className={styles.username}>{chatMessage.username}</span>
          <span className={styles.content}>{chatMessage.message}</span>
        </p>
      </Box>
    </Box>
  );
};
export default ChatListItem;
