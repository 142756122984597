import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SubmitButton from '../../../../components/Button/SubmitButton';
import { deleteAmaAction } from '../../state/amasSlice';

interface DeleteAmaButtonProps {
  id: string;
}

const DeleteAmaButton = ({ id }: DeleteAmaButtonProps) => {
  const dispatch = useDispatch();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);

  const handleConfirmationClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  const deleteAma = () => {
    dispatch(deleteAmaAction(id));
    handleConfirmationClose();
  };

  return (
    <>
      <SubmitButton
        fullWidth
        variant="outlined"
        color="error"
        onClick={() => setIsConfirmationDialogOpen(true)}
        data-testid="delete-button"
        style={{ backgroundColor: 'inherit', borderColor: 'inherit' }}
      >
        Delete
      </SubmitButton>
      <Dialog
        open={isConfirmationDialogOpen}
        onClose={handleConfirmationClose}
        data-testid="deletion-confirmation-dialog"
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to permanently delete this AMA?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteAma} data-testid="delete-ama-button">
            Delete
          </Button>
          <Button onClick={handleConfirmationClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAmaButton;
