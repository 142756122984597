import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectProductListProps, toggleFilterModal } from '../../../state/productsSlice';
import { buildResetLinkTarget } from '../../../utils/targetLinkUtils';
import styles from './ResetAllFilters.module.scss';

const ResetAllFilters = () => {
  const {
    searchParams = {
      query: '*',
    },
  } = useSelector(selectProductListProps);

  const history = useHistory();
  const dispatch = useDispatch();

  const resetFilters = () => {
    history.push(buildResetLinkTarget(searchParams));
    dispatch(toggleFilterModal());
  };

  return (
    <>
      {searchParams?.filters?.length && (
        <div
          className={classNames('flex items-center justify-center', styles.container)}
          onClick={resetFilters}
        >
          <CancelOutlinedIcon />
          <div className={styles.text}>Alle Filter zurücksetzen</div>
        </div>
      )}
    </>
  );
};

export default ResetAllFilters;
