import React from 'react';
import GobackHeader from '../../../components/GoBackHeader/GobackHeader';
import MobilePageHeader from '../../../components/MobilePageHeader/MobilePageHeader';
import StreamingSettings from '../../../shows/component/StreamingSettings/StreamingSettings';
import CreatorAccountPage from '../CreatorAccountPage/CreatorAccountPage';
import styles from './CreatorStreamSettings.module.scss';
const CreatorStreamSettings = () => {
  return (
    <div className={styles.container}>
      <MobilePageHeader />
      <GobackHeader className={styles.back_button} />
      <CreatorAccountPage />
      <StreamingSettings />
    </div>
  );
};

export default CreatorStreamSettings;
