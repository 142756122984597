import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectProps,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface RadioItem {
  value: string;
  label: string;
}

interface FormikRadioGroupProps {
  items: Array<RadioItem>;
  fieldName: string;
  value: string;
  formLabel?: string;
  muiProps?: SelectProps;
}

const FormikRadioGroup = (props: FormikRadioGroupProps) => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  const { values, handleChange, setFieldValue } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormikContext<any>();

  useEffect(() => {
    setFieldValue(props.fieldName, props.value);
  }, [props.value]);

  return (
    <FormControl
      variant={'outlined'}
      fullWidth
      component="fieldset"
      disabled={props.muiProps ? props.muiProps.disabled : false}
    >
      {props.formLabel && <FormLabel component="legend">{props.formLabel}</FormLabel>}
      <RadioGroup name={props.fieldName} onChange={handleChange} value={values[props.fieldName]}>
        {props.items.map(item => (
          <FormControlLabel
            key={item.value}
            value={item.value}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default FormikRadioGroup;
