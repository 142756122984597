import { Select, Text } from '@hse24/shared-components';
import { TSelection } from '@hse24/shared-components/lib/components/Select/Select';
import { Divider, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '../../../../components/TextField/TextField';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import BirthdatePicker from '../BirthdatePicker/BirthdatePicker';
import {
  profilePersonalDataKeys,
  profilePersonalDataLabels,
  Salutation,
  translate,
} from '../ProfilePersonalData';
import styles from './ContactDataForm.module.scss';

const ContactDataForm = () => {
  const profileData = useSelector(selectLoggedInCreator);
  const [salutation, setSalutation] = useState<Salutation>(
    profileData?.salutation ?? Salutation.MR
  );
  const options: TSelection[] = Object.values(Salutation).map((value, index) => {
    return {
      value,
      label: translate(value),
      index,
    };
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text.B1 className={styles.heading}>Kontaktdaten</Text.B1>
        <Typography>
          Die hier eingetragenen Daten müssen mit denen aus der umsatzsteuerlichen Registrierung
          übereinstimmen, insofern du kein Kleinunternehmer bist.
        </Typography>
      </Grid>
      <Grid item xs={4} sm={2}>
        <div className={styles.select}>
          <Select
            label={translate(salutation)}
            selectedSelector={option => {
              return option === profileData?.salutation;
            }}
            options={options}
            style={{ zIndex: 40 }}
            onChange={option => {
              setSalutation(option.value as Salutation);
            }}
            selectBodyClassName={styles.select}
          ></Select>
        </div>
      </Grid>
      <Grid item xs={8} sm={5}>
        <TextField
          fieldName={profilePersonalDataKeys.firstName}
          label={`${profilePersonalDataLabels.firstName}*`}
          value={profileData?.firstName}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          fieldName={profilePersonalDataKeys.lastName}
          label={`${profilePersonalDataLabels.lastName}*`}
          value={profileData?.lastName}
        />
      </Grid>
      <Grid item xs={8} sm={8} md={4}>
        <TextField
          fieldName={profilePersonalDataKeys.street}
          label={`${profilePersonalDataLabels.street}*`}
          value={profileData?.street}
        />
      </Grid>
      <Grid item xs={4} sm={4} md={2}>
        <TextField
          fieldName={profilePersonalDataKeys.streetNumber}
          label={`${profilePersonalDataLabels.streetNumber}*`}
          value={profileData?.streetNumber}
        />
      </Grid>
      <Grid item xs={5} sm={4} md={2}>
        <TextField
          fieldName={profilePersonalDataKeys.zipCode}
          label={`${profilePersonalDataLabels.zipCode}*`}
          value={profileData?.zipCode}
        />
      </Grid>
      <Grid item xs={7} sm={8} md={4}>
        <TextField
          fieldName={profilePersonalDataKeys.city}
          label={`${profilePersonalDataLabels.city}*`}
          value={profileData?.city}
        />
      </Grid>
      <Grid item xs={12} sm={7} md={3}>
        <TextField
          fieldName={profilePersonalDataKeys.phoneNumber}
          label={profilePersonalDataLabels.phoneNumber}
          value={profileData?.phoneNumber}
        />
      </Grid>
      <Grid item xs={12} sm={5} md={3}>
        <BirthdatePicker />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fieldName={profilePersonalDataKeys.email}
          label={profilePersonalDataLabels.email}
          value={profileData?.email}
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Divider className={styles.divider} />
      </Grid>
    </Grid>
  );
};

export default ContactDataForm;
