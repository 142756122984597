import { Error } from '@hse24/shared-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { styled } from '@mui/styles';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import TextField from '../../../components/TextField/TextField';
import t from '../../../constants/translation';
import ShowCoverSelection from '../../../shows/component/ShowCoverSelection/ShowCoverSelection';
import { ImageType } from '../../../shows/model/shows';
import theme from '../../../theme/theme.module.scss';
import { Aspect } from '../../../utils/images/cropImageFns';
import { CreatorShopLink, CreatorShopLinkData, ShopLinkType } from '../../model/creator';
import { selectCreatorShopLinksIsLoading } from '../../state/creatorsSlice';
import { getUrlValidationSchemaFromShopLinkType } from '../../utils/formikUtils';
import styles from './AdminCreateStreamerShopLinkDialog.module.scss';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow placement="right" {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: '#eaf0f3',
    color: 'black',
    width: '241px',
    fontSize: '14px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#eaf0f3',
  },
}));

const creatorShopLinkRadioOptions: Record<
  ShopLinkType,
  { translation: string; hint: string; label: string; tooltip?: string }
> = {
  [ShopLinkType.BRAND_OR_CATEGORY]: {
    translation: t.admins.creators.creatorDetails['Brand/Category'],
    hint: t.admins.creators.creatorDetails['Brand/Category hint'],
    label: t.admins.creators.creatorDetails['Brand/Category label'],
  },
  [ShopLinkType.CAMPAIGN]: {
    translation: t.admins.creators.creatorDetails.Campaign,
    hint: t.admins.creators.creatorDetails['Campaign hint'],
    label: t.admins.creators.creatorDetails['Campaign label'],
    tooltip: t.admins.creators.creatorDetails['Campaign toolTip'],
  },
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  shopLinkToEdit?: CreatorShopLink;
  handleSubmit: (shopLink: CreatorShopLinkData) => void;
}

const AdminCreateStreamerShopLinkDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  shopLinkToEdit,
}: Props) => {
  const isLoading = useSelector(selectCreatorShopLinksIsLoading);
  const [selectedType, setSelectedType] = useState<ShopLinkType>(
    shopLinkToEdit?.type ?? ShopLinkType.BRAND_OR_CATEGORY
  );
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const onSubmit = (values: Omit<CreatorShopLinkData, 'type'>) => {
    const shopLinkData: CreatorShopLinkData = {
      ...values,
      type: selectedType,
    };
    handleSubmit(shopLinkData);
    handleClose();
  };

  return (
    <Formik
      initialValues={{
        url: shopLinkToEdit?.url ?? '',
        label: shopLinkToEdit?.label ?? '',
        imageUrl: shopLinkToEdit?.imageUrl,
        preview: {
          imageUrl: '',
          fileExtension: '',
          changed: false,
        },
      }}
      onSubmit={onSubmit}
      validationSchema={yup.object({
        url: getUrlValidationSchemaFromShopLinkType(selectedType),
        label: yup
          .string()
          .required(t.validation['This field is mandatory'])
          .max(20, t.common['Maximum X characters'].replace('X', '20')),
        preview: yup
          .object()
          .test(
            'invalid',
            'Kein Bild ausgewählt',
            value => !!value.changed || !!shopLinkToEdit?.imageUrl
          ),
      })}
    >
      {({ submitForm, touched, submitCount, errors, initialValues }) => (
        <Form>
          <StyledDialog
            close={handleClose}
            dialogProps={{
              open: isOpen,
              maxWidth: 'md',
            }}
            fullWidth={true}
            title={t.admins.creators.creatorDetails['Allowed assortment']}
            body={
              <div className={styles.body}>
                <FormControl>
                  <RadioGroup
                    value={selectedType}
                    onChange={event => {
                      setSelectedType(
                        ShopLinkType[event.target.value as keyof typeof ShopLinkType]
                      );
                    }}
                    className={styles.radio_group}
                  >
                    {Object.entries(creatorShopLinkRadioOptions).map(
                      ([shopLinkType, { translation, tooltip }]) => {
                        return (
                          <FormControlLabel
                            key={shopLinkType}
                            value={shopLinkType}
                            label={
                              <>
                                {translation}{' '}
                                {!!tooltip && (
                                  <StyledTooltip
                                    title={
                                      <span dangerouslySetInnerHTML={{ __html: tooltip }}></span>
                                    }
                                  >
                                    <InfoOutlinedIcon />
                                  </StyledTooltip>
                                )}
                              </>
                            }
                            control={
                              <Radio
                                className={styles.radio_option}
                                sx={{
                                  '& .MuiSvgIcon-root:nth-of-type(2)': {
                                    color: theme.primary,
                                  },
                                  '& .MuiSvgIcon-root:nth-of-type(1)': {
                                    color: 'black !important',
                                  },
                                }}
                              />
                            }
                          />
                        );
                      }
                    )}
                  </RadioGroup>
                </FormControl>
                <TextField
                  fieldName="url"
                  label={creatorShopLinkRadioOptions[selectedType].label}
                  value={initialValues.url}
                  className={styles.input}
                />
                <div
                  className={styles.hint}
                  dangerouslySetInnerHTML={{
                    __html: creatorShopLinkRadioOptions[selectedType].hint,
                  }}
                />
                <div className={styles.picture}>
                  <ShowCoverSelection
                    fieldName="preview"
                    imageType={ImageType.PREVIEW}
                    defaultImage={shopLinkToEdit?.imageUrl}
                    ratio={Aspect.RATIO_26_10}
                    hasError={
                      (touched.preview || isSubmitClicked || submitCount > 0) && !!errors.preview
                    }
                  />
                  {(touched.preview || isSubmitClicked || submitCount > 0) && errors.preview && (
                    <Error error={errors.preview as string} className={styles.error_message} />
                  )}
                </div>
                <TextField
                  fieldName="label"
                  label={t.common['Displayed name']}
                  value={initialValues.label}
                  className={styles.input}
                />
                <div className={styles.hint}>
                  {t.common['Maximum X characters'].replace('X', '20')}
                </div>
              </div>
            }
            cancel={{
              title: t.common.Cancel,
              action: handleClose,
            }}
            submit={{
              title: t.common['Save'],
              loading: isLoading,
              action: () => {
                setIsSubmitClicked(true);
                submitForm();
              },
              buttonProps: {
                color: 'primary',
              },
            }}
            testId={'admin-create-streamer-shop-link-dialog'}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AdminCreateStreamerShopLinkDialog;
