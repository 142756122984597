import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import { selectLoggedInCreator } from '../../../creators/state/creatorsSlice';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import {
  clearProductsSelection,
  selectProductSelection,
} from '../../../products/state/productsSlice';
import Loading from '../../../shows/component/Loading/Loading';
import theme from '../../../theme/theme.module.scss';
import CreateEditPostForm from '../../components/CreateEditPostForm/CreateEditPostForm';
import CreatePostHeader from '../../components/CreatePostHeader/CreatePostHeader';
import { StreamPostData } from '../../model/post';
import {
  fetchStreamerPost,
  selectPostIsBeingLoaded,
  selectStreamerPost,
  updatePost,
} from '../../state/postsSlice';

const UpdatePostView = () => {
  const dispatch = useDispatch();
  const { postId } = useParams<{ postId: string }>();
  const post = useSelector(selectStreamerPost);
  const loading = useSelector(selectPostIsBeingLoaded);
  const loggedInStreamer = useSelector(selectLoggedInCreator);
  const { selectedProducts } = useSelector(selectProductSelection);
  const isAdmin: boolean = useSelector(selectUserIsHSEEmployee);

  const onUpdatePost = ({ caption, scheduleAt }: StreamPostData) => {
    if (caption?.trim()) {
      dispatch(
        updatePost({
          postId,
          request: {
            caption,
            baseProductsNo: selectedProducts.map(p => p.baseProductNo),
            scheduleAt: scheduleAt,
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(clearProductsSelection());
    dispatch(fetchStreamerPost(postId));
  }, [postId]);

  if (loading) {
    return <Loading />;
  }

  if (!post) {
    return (
      <ErrorPageDialog errorDialogType={ErrorPageDialogType.ENTITY_NOT_FOUND} entityLabel="Post" />
    );
  }

  return (
    <>
      <CreatePostHeader title="Post ändern" />
      <CreateEditPostForm
        postToUpdate={post}
        isOwnPost={post.creator?.id === loggedInStreamer?.id || isAdmin}
        submitCallback={onUpdatePost}
      />
    </>
  );
};

function UpdatePostPage() {
  return (
    <PageContainer
      backgroundColor={theme.lightgreyLightest}
      pageToRender={<UpdatePostView />}
      fullHeight
    />
  );
}

export default UpdatePostPage;
