import { useFormikContext } from 'formik';
import { FocusEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputTextField from '../../../../components/InputTextField/InputTextField';
import { ibanFormat } from '../../../../utils/formatters/ibanFormat/ibanFormat';
import { selectLoggedInCreator, validateCreatorIban } from '../../../state/creatorsSlice';
import { profilePersonalDataKeys } from '../ProfilePersonalData';

/**
 * This TextField validates the entered IBAN with the external payment service.
 */
const ValidatedIbanTextField = () => {
  const profileData = useSelector(selectLoggedInCreator);
  const dispatch = useDispatch();

  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  const { errors, values, touched, handleBlur, handleChange, setFieldValue } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormikContext<any>();
  const ibanKey = profilePersonalDataKeys.iban;

  useEffect(() => {
    if (profileData?.iban) {
      setFieldValue(ibanKey, profileData?.iban, false);
    }
  }, [profileData?.iban]);

  function triggerIbanValidation(event: FocusEvent<HTMLInputElement>) {
    handleBlur(event);
    dispatch(validateCreatorIban({ iban: event.target.value.replace(/\s+/gi, '') }));
  }

  return (
    <InputTextField
      fieldName={ibanKey}
      label={'IBAN*'}
      error={touched[ibanKey] && Boolean(errors[ibanKey])}
      onChange={handleChange}
      onBlur={triggerIbanValidation}
      // This is a workaround to prevent "jumping" of UI elements if errors are shown / hidden.
      // (see https://github.com/mui-org/material-ui/issues/7747#issuecomment-322029315)
      value={ibanFormat(values[ibanKey])}
    />
  );
};

export default ValidatedIbanTextField;
