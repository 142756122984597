import { Divider } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import StreamerCommissionInfoTooltip from '../../../components/StreamerCommissionInfoTooltip/StreamerCommissionInfoTooltip';
import { NUMBER_OF_LEVELS } from '../../../constants/streamerNumberOfLevels';
import t from '../../../constants/translation';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import {
  germanMoneyFormat,
  germanPercentFormat,
} from '../../../utils/formatters/germanFormat/germanFormats';
import { selectCreatorPerformanceOverview } from '../../state/creatorsSlice';
import InsightData from '../InsightData/InsightData';
import ProgressSpinner from '../ProgressSpinner/ProgressSpinner';
import styles from './StreamerLevel.module.scss';

const StreamerCurrentLevel: FC = () => {
  const {
    ordersCount: orders,
    streamedSeconds,
    generatedSales: { amount: sales, currency },
    earnings: { amount: earning },
    currentLevel: { commission, number: currentLevelNumber },
    nextLevel,
    levelProgress,
  } = useSelector(selectCreatorPerformanceOverview);

  const streamedTime = formatTime(streamedSeconds);
  const restToNextCommission = germanPercentFormat(nextLevel?.commission ?? 0);
  const restToNextLevel = germanMoneyFormat((nextLevel?.min ?? 0) - sales, currency);

  return (
    <div className={classNames(styles.hero_insights, styles.current)}>
      <div className={styles.hero_title}>
        <h1>Stream-Level {currentLevelNumber} </h1> <span>von {NUMBER_OF_LEVELS}</span>
      </div>
      <div className={styles.overview}>
        <ProgressSpinner
          className={styles.spinner_container}
          progress={levelProgress}
          level={nextLevel?.number}
          restToNextLevel={restToNextLevel}
        />
        <div className={styles.insights_data}>
          <InsightData
            title={`Stream-${t.creators['Net sales']}`}
            value={germanMoneyFormat(sales, currency)}
          />
          <InsightData
            title={`Stream-${t.creators.Revenue}`}
            value={germanMoneyFormat(earning, currency)}
          />
          <InsightData title={`Stream-${t.creators.Orders}`} value={orders.toString()} />
          <InsightData
            title={`Stream-${t.creators.Commission}`}
            value={germanPercentFormat(commission)}
          />
          <InsightData title={t.creators['Streaming time']} value={streamedTime} />
        </div>
        <Divider className={styles.divider} />
        <div className={styles.hints}>
          <h1>
            <span className={styles.hint_title}>Tipp</span> 💡
          </h1>
          <ul className={styles.hint_list}>
            <li>
              Du brauchst noch <b>{restToNextLevel}</b> um das nächste Level freizuschalten.
            </li>
            <li>
              Schalte Level {nextLevel?.number ?? 0} frei und erhalte{' '}
              <span>{restToNextCommission}</span> {t.creators.Commission}.
            </li>
          </ul>
        </div>
      </div>
      <StreamerCommissionInfoTooltip disabledTitle>
        <u className={styles.commission_link}>{t.creators['Remuneration overview']}</u>
      </StreamerCommissionInfoTooltip>
    </div>
  );
};

export default StreamerCurrentLevel;
