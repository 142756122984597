import {
  CountryCode,
  getCountryCodeFromStringOrDeDefault,
  TProductTileProduct,
} from '@hse24/shared-components';
import { ProductTileInfo } from '../model/productTileInfo';
import { ProductSwatch } from './productTileInfoUtils';

export const mapProductTileInfoToProductTileProduct = (
  productTileInfo: ProductTileInfo
): TProductTileProduct => {
  return {
    ...productTileInfo,
    price: {
      ...productTileInfo.price,
      countryCode: getCountryCodeFromStringOrDeDefault(
        productTileInfo.price.countryCode,
        CountryCode.DE
      ),
    },
  };
};

export const mapProductSwatchToProductTitleInfo = (
  productSwatch: ProductSwatch,
  productTitleInfo: ProductTileInfo
): ProductTileInfo => {
  return {
    ...productTitleInfo,
    baseProductNo: productSwatch.sku,
    imageUri: productSwatch.image.uri,
  };
};
