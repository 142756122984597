import axios, { AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import { Audience } from '../../common/types';
import Config from '../../config/config';
import { featureFlagValue } from '../../config/featureFlagConfig';
import { AvailableFlag } from '../../config/FeatureFlags';
import authClient from '../authClient';
import {
  ActivateUserRequest,
  ActivateUserResponse,
  LoggedInCustomerPersonalDetailsResponse,
  LoginUserCredentials,
  LoginUserResponse,
  SetPasswordRequest,
  StreamerRegistrationRequest,
  StreamerRegistrationResponse,
} from '../types';

export enum UserRole {
  STREAMER = 'STREAMER',
  INFLUENCER = 'INFLUENCER',
  AGENCY = 'AGENCY',
  HSE_EMPLOYEE = 'HSE_EMPLOYEE',
}

export interface UserLoginResponse {
  role: UserRole;
  profileImageUrl?: string;
  id: string;
  displayName: string;
  firstName?: string;
  lastName?: string;
  audiences?: Audience[];
}

const slcsBaseUrl = Config.env.socialLifeCommerceServiceBaseUrl;
const csBaseUrl = Config.env.customerServiceBaseUrl;

const usersClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/users`);

const getUserLoginState = async (
  baseUrl: string = slcsBaseUrl,
  skipRefreshingToken = false,
  isUnauthorized = false
): Promise<UserLoginResponse> => {
  try {
    const call = usersClient(baseUrl).get<UserLoginResponse>(`/login-state`);
    const response: AxiosResponse = await (skipRefreshingToken
      ? call
      : RefreshTokenUtil.wrap(() => call));
    return response.data as UserLoginResponse;
  } catch (e) {
    if (!isUnauthorized) {
      return await getUserLoginState(baseUrl, skipRefreshingToken, true);
    } else {
      throw e;
    }
  }
};

const logout = async (): Promise<AxiosResponse> => {
  return await axios.get(`${csBaseUrl}/user/auth/logout`);
};

export const isAccountExisting = async (
  email: string,
  recaptchaToken: string,
  baseUrl: string = csBaseUrl
): Promise<AxiosResponse> => {
  return await axios.get(`${baseUrl}/user/account/check?username=${encodeURIComponent(email)}`, {
    headers: {
      'X-Recaptcha-Token': recaptchaToken,
    },
  });
};

const getCustomerPersonalDetails = async (
  baseUrl: string = csBaseUrl
): Promise<LoggedInCustomerPersonalDetailsResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    axios.get(`${baseUrl}/user/personal-details?t=${Date.now()}`, {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    })
  );
  const { date_of_birth, ...personalDetails } = response.data;
  return camelizeKeys({
    ...personalDetails,
    birthdate: date_of_birth,
  }) as unknown as LoggedInCustomerPersonalDetailsResponse;
};

export const loginUserApi = async (
  userCredentials: LoginUserCredentials,
  recaptchaToken: string,
  baseUrl: string = csBaseUrl
): Promise<LoginUserResponse> => {
  const isInApp = featureFlagValue(AvailableFlag.isInApp);
  const body = decamelizeKeys({
    username: userCredentials.email,
    password: userCredentials.password,
    stayLoggedIn: userCredentials.stayLoggedIn,
    loginSource: isInApp ? 'STREAMERS_APP' : 'STREAMERS_PORTAL',
  });
  const response = await axios.post<LoginUserResponse>(`${baseUrl}/user/login`, body, {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-Recaptcha-Token': recaptchaToken,
    },
  });
  return camelizeKeys(response.data) as LoginUserResponse;
};

export const resetPassword = (username: string, baseUrl: string = csBaseUrl) => {
  return axios.post(`${baseUrl}/user/reset-password`, { username, origin: 'STREAMER_PORTAL' });
};

export const activateUser = async (
  activateUserRequest: ActivateUserRequest,
  baseUrl: string = slcsBaseUrl
): Promise<ActivateUserResponse> => {
  const response = await usersClient(baseUrl).post<ActivateUserResponse>(
    '/activate',
    decamelizeKeys(activateUserRequest)
  );
  return response.data as ActivateUserResponse;
};

const streamersRegistration = async (
  request: StreamerRegistrationRequest,
  baseUrl: string = slcsBaseUrl
) => {
  return await usersClient(baseUrl).post<StreamerRegistrationResponse>(
    `/streamers-registration`,
    decamelizeKeys(request)
  );
};

const setPassword = async (request: SetPasswordRequest, baseUrl: string = csBaseUrl) => {
  return await axios.post<SetPasswordRequest>(
    `${baseUrl}/user/set-password`,
    decamelizeKeys({
      ...request,
      loginSource: featureFlagValue(AvailableFlag.isInApp) ? 'STREAMERS_APP' : 'STREAMERS_PORTAL',
    })
  );
};

const authApi = {
  getUserLoginState,
  usersClient,
  logout,
  isAccountExisting,
  loginUserApi,
  resetPassword,
  getCustomerPersonalDetails,
  activateUser,
  streamersRegistration,
  setPassword,
};

export default authApi;
