import { store } from '../app/store';
import { logout } from '../auth/state/authSlice';
import { LocalStorageKeys } from '../constants/localStorageKeys';
import { isAppBuildInfo } from '../utils/appBuildInfo';
import { isMobileDevice } from '../utils/isMobileDevice';
import {
  buildLocalStorageMessageEvent,
  NativeMessageEvent,
  NativeMessageType,
} from './nativeMessages';
import { sharedStorageService } from './sharedStorageService';

export function sendMessageToNativeApp(message: NativeMessageEvent<unknown>) {
  if (window?.webkit !== undefined) {
    // IOS
    window?.webkit?.messageHandlers?.observer?.postMessage(message);
  } else {
    // Android
    window?.observer?.postMessage(JSON.stringify(message));
  }
}

export const dataExchangerService = {
  init: (): void => {
    const isMobile = isMobileDevice();

    window.nativeApp = {
      saveStorageItemsHandler: (items: Record<string, string>) => {
        Object.entries(items).forEach(([key, value]) => {
          sharedStorageService.setItem(key, value, false);
        });
      },
      removeStorageItemsHandler: (keys: string[]) => {
        keys.forEach(key => {
          sharedStorageService.removeItem(key, false);
        });
      },
      getStorageItemHandler: (item: string): string | null => {
        return sharedStorageService.getItem(item);
      },
      firebase: {
        logEvent: (name: string, params: Record<string, unknown>) => {
          if (!name) {
            return;
          }

          if (window.AnalyticsWebInterface) {
            window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
          } else if (window.webkit?.messageHandlers?.firebase !== undefined) {
            const message = {
              command: 'logEvent',
              name: name,
              parameters: params,
            };
            window.webkit.messageHandlers.firebase.postMessage(message);
          } else if (isMobile) {
            console.error('No native APIs found.');
          }
        },
        setUserProperty: (name: string, value: string) => {
          if (!name || !value) {
            return;
          }

          if (window.AnalyticsWebInterface) {
            window.AnalyticsWebInterface.setUserProperty(name, value);
          } else if (window?.webkit?.messageHandlers?.firebase !== undefined) {
            const message = {
              command: 'setUserProperty',
              name: name,
              value: value,
            };
            window.webkit.messageHandlers.firebase.postMessage(message);
          } else if (isMobile) {
            console.error('No native APIs found.');
          }
        },
        setUserId: (value: string) => {
          if (!value) {
            console.error('User ID is null');
            return;
          }

          if (window.AnalyticsWebInterface) {
            window.AnalyticsWebInterface.setUserId(value);
          } else if (window?.webkit?.messageHandlers?.firebase !== undefined) {
            const message = {
              command: 'setUserId',
              value: value,
            };
            window.webkit.messageHandlers.firebase.postMessage(message);
          } else if (isMobile) {
            console.error('No native APIs found.');
          }
        },
      },
      setAppBuildInfo: (buildInfo: Record<string, string>) => {
        if (!isAppBuildInfo(buildInfo)) return;

        sharedStorageService.setItem(
          LocalStorageKeys.AppBuildInfo,
          JSON.stringify(buildInfo),
          false
        );
      },
      setStreamEnded: (showId: string) => {
        sharedStorageService.setItem(LocalStorageKeys.streamEnded, showId, false);
      },
      loginEvent: (body: Record<string, string>) => {
        return sendMessageToNativeApp({
          type: NativeMessageType.LOGIN_EVENT,
          body,
        });
      },
      logoutEvent: () => {
        return sendMessageToNativeApp({
          type: NativeMessageType.LOGOUT_EVENT,
          body: null,
        });
      },
      forceLogout: () => {
        store.dispatch(logout());
      },
    };

    window.onstorage = (event: StorageEvent) => {
      if (event.key) {
        sendMessageToNativeApp(buildLocalStorageMessageEvent(event.key, event.newValue));
      }
    };
  },
};
