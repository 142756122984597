import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import BorderLinearProgress from '../../../components/BorderLinearProgress/BorderLinearProgress';
import MobileAppBar from '../../../components/MobileAppBar/MobileAppBar';
import routePaths from '../../../routes/routePaths';
import { AcademySection } from '../../model/academy';
import {
  fetchAcademyProgress,
  fetchAcademySections,
  selectAcademyProgress,
  selectAcademySections,
} from '../../state/academySlice';
import SectionCard from '../SectionCard/SectionCard';
import StreamerAcademySkeleton from './StreamerAcademySkeleton';

function StreamerAcademy() {
  const dispatch = useDispatch();
  const history = useHistory();
  const sections = useSelector(selectAcademySections);
  const { watchedVideosCount, totalVideosCount, currentProgressPercentage } =
    useSelector(selectAcademyProgress);

  const isMobile = isMobileBreakPoint();

  const goBack = () => {
    history.push(routePaths.creator.dashboard);
  };

  useEffect(() => {
    dispatch(fetchAcademySections());
    dispatch(fetchAcademyProgress());
  }, []);

  const numOfEp = sections.data.reduce((total, section) => {
    return total + section.videos.length;
  }, 0);

  return (
    <>
      {isMobile && <MobileAppBar title={'Streamer Academy'} landing={goBack} />}
      {sections.loading ? (
        <StreamerAcademySkeleton />
      ) : (
        <Box mt={'3'} display={'flex'} flexDirection={'column'} gap={2}>
          <Paper elevation={0}>
            <Box paddingY={3} paddingX={2} display={'flex'} flexDirection={'column'} gap={2}>
              <Typography component={'h2'} fontWeight={'bold'}>
                <RocketLaunchOutlinedIcon /> Mein Fortschritt
              </Typography>
              <Box>
                <BorderLinearProgress
                  variant="determinate"
                  value={currentProgressPercentage}
                  bgColor={'#b8dcb4'}
                />
              </Box>
              <Typography variant={'body1'}>
                {watchedVideosCount}/{totalVideosCount} angeschaut
              </Typography>
            </Box>
          </Paper>
          <Box mt={2}>
            <Typography component={'h2'} fontWeight={'bold'}>
              Alle Folgen ({numOfEp})
            </Typography>
          </Box>
          {sections.data.map((section: AcademySection) => (
            <SectionCard key={section.id} section={section} />
          ))}
        </Box>
      )}
    </>
  );
}

export default StreamerAcademy;
