import { Visibility, VisibilityOff } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, MenuItem } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import StyledMenu from '../../../components/StyledMenu/StyledMenu';
import StyledMenuList from '../../../components/StyledMenu/StyledMenuList';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import { Post, PostStatus } from '../../model/post';
import { changePostStatus, selectPostStatusLoading } from '../../state/postsSlice';
import styles from './PostsMenuOptions.module.scss';

interface Props {
  post: Post;
  isPublished: boolean;
  isAdmin: boolean;
}

const PostsMenuOptions = ({ post, isPublished, isAdmin }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isUnPublishDialogLoading = useSelector(selectPostStatusLoading);
  const [dropDownAnchor, setDropDownAnchor] = useState<null | HTMLElement>(null);
  const [showUnPublishConfirmationDialog, setShowUnPublishConfirmationDialog] = useState(false);
  const readyToOpen = Boolean(dropDownAnchor);
  const isMobile = isMobileBreakPoint();

  useEffect(() => {
    if (!isUnPublishDialogLoading) handleOnClose();
  }, [isUnPublishDialogLoading]);

  const handleOnClose = () => {
    setDropDownAnchor(null);
    setShowUnPublishConfirmationDialog(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) =>
    setDropDownAnchor(event.currentTarget);

  const handleOnUnPublish = () => setShowUnPublishConfirmationDialog(true);

  const handleUnPublish = () => {
    const nextStatus = isAdmin
      ? isPublished
        ? PostStatus.UNPUBLISHED_HSE_EMPLOYEE
        : PostStatus.PUBLISHED
      : PostStatus.UNPUBLISHED_CREATOR;
    dispatch(changePostStatus({ postId: post.id, postStatus: nextStatus }));
  };

  const handleShowSelected = useCallback(() => {
    const path = isAdmin
      ? routePaths.hseEmployee.updateStreamerPost
      : routePaths.creator.updatePost;
    history.push(path.replace(':postId', post.id));
  }, [history]);

  const MenuOptions = () => (
    <>
      <MenuItem
        onClick={handleOnUnPublish}
        disableRipple
        disabled={!isAdmin && post.status !== PostStatus.PUBLISHED}
      >
        <Box color={isPublished ? '#d21e4a' : isAdmin ? '#ff3c28' : '#838b95'}>
          {isPublished ? (
            <>
              <VisibilityOff color={'inherit'} />
              Verbergen
            </>
          ) : (
            <>
              <Visibility color={'inherit'} />
              Veröffentlichen
            </>
          )}
        </Box>
      </MenuItem>
      <MenuItem data-testid="edit-button" onClick={handleShowSelected}>
        <EditIcon className={styles.item_icon} fontSize={'small'} />
        Bearbeiten
      </MenuItem>
    </>
  );

  const renderDialogBody = () => {
    if (showUnPublishConfirmationDialog) {
      if (isPublished) {
        if (isAdmin) {
          return 'Der Post wird nicht mehr in der HELLO App verfügbar sein. Der Post wird dem Streamer weiterhin in seiner Übersicht angezeigt und ausstehende Provision ausgezahlt. Du kannst diese Aktion jederzeit rückgängig machen.';
        }
        return 'Der Post wird nicht mehr in der HELLO App verfügbar sein. Du kannst diese Aktion nicht rückgängig machen. Der Post wird dir weiterhin in deiner Übersicht angezeigt und ausstehende Provision ausgezahlt.';
      }
      return 'Der Post wird umgehend wieder in der HELLO App verfügbar sein.';
    }
    return (
      isMobile && (
        <StyledMenuList>
          <MenuOptions />
        </StyledMenuList>
      )
    );
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        open={!isMobile && readyToOpen && !showUnPublishConfirmationDialog}
        anchorEl={dropDownAnchor}
        data-testid={'styled-menu'}
        onClose={handleOnClose}
      >
        <MenuOptions />
      </StyledMenu>
      <StyledDialog
        close={handleOnClose}
        dialogProps={{
          open: (isMobile && readyToOpen) || (!isMobile && showUnPublishConfirmationDialog),
          maxWidth: isMobile ? 'md' : 'xs',
        }}
        title={
          isPublished
            ? 'Willst du diesen Post wirklich verbergen?'
            : 'Willst du diesen Post wirklich wieder veröffentlichen?'
        }
        body={renderDialogBody()}
        cancel={
          !(isMobile && readyToOpen)
            ? {
                title: t.common.Cancel,
                action: handleOnClose,
              }
            : undefined
        }
        submit={
          !(isMobile && readyToOpen)
            ? {
                title: isPublished ? 'Verbergen' : 'Veröffentlichen',
                loading: isUnPublishDialogLoading,
                action: handleUnPublish,
                buttonProps: {
                  color: isPublished ? 'error' : 'primary',
                },
              }
            : undefined
        }
      />
    </>
  );
};

export default PostsMenuOptions;
