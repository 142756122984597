import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import t from '../../../../constants/translation';
import { EUROPE_BERLIN_TIMEZONE } from '../../../../shows/constants';
import { DeletedExpiredShowHintProps } from '../DeletedExpiredShowAlert/DeletedExpiredShowAlert';
import style from './DeletedExpiredShowDialog.module.scss';

const DeletedExpiredShowDialog = ({ archivedShow, onClose }: DeletedExpiredShowHintProps) => {
  return (
    <Dialog onClose={onClose} open={true}>
      <Box className={style.container}>
        <Box display="flex" justifyContent="flex-start" alignContent="space-between">
          <Typography className={style.title}>
            {t.creators.notifications['We have deleted your missed stream after 48 hours:']}
          </Typography>
          <IconButton
            onClick={onClose}
            className={style.close_icon}
            data-testid="deleted-expired-show-dialog-close-icon"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography>
          {'DO : ' +
            dayjs(archivedShow.scheduledStartAt)
              .tz(EUROPE_BERLIN_TIMEZONE)
              .format('DD. MMM, HH:mm')}
        </Typography>
        <Typography className={style.content}>{archivedShow.title}</Typography>
        <Button
          variant="contained"
          className={style.button}
          onClick={onClose}
          data-testid="deleted-expired-show-dialog-okay-button"
        >
          {t.common.Okay}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DeletedExpiredShowDialog;
