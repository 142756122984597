import AppBadge from '../components/AppBadge/AppBadge';
import { OnboardingHintDialogueProps } from '../components/OnboardingHintDialogue/OnboardingHintDialogue';
import Config from '../config/config';
import {
  onboardingCompleted,
  samplesOrdered,
  samplesReceived,
} from '../creators/state/creatorsSlice';

export const URL_APP_STORE = 'https://apps.apple.com/de/app/hello-streamer/id1601974573';
export const URL_GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=de.hse.streamer';

export const MAX_ONBOARDING_STEPS = 4;

export const renderOnboardingStep = (
  dispatchOnboardingCallback: (action: { type: string }) => void,
  step: number,
  isInApp: boolean
): OnboardingHintDialogueProps =>
  [
    {
      title:
        `First things first:<br/>` +
        `Um weiterzumachen benötigen wir jetzt noch weitere Informationen über dich.`,
      steps: { current: 1, total: MAX_ONBOARDING_STEPS },
      subtitle: 'Vervollständige dein Profil',
    },
    {
      title: 'Wie willst du deine Zuschauer inspirieren? Bestelle deine Must-Haves! 📦',
      steps: { current: 2, total: MAX_ONBOARDING_STEPS },
      subtitle: 'Bestelle Produkte für deinen Stream',
      message:
        `Im Reiter <a href="${Config.env.streamersPortalUrl}/creator/products">Produkte</a> findest du die ` +
        `Highlights für deinen Stream. Wähle ein Produkt, lege es in den Warenkorb und schließe die Bestellung ab.`,
      button: {
        title: 'Bestellung abgeschickt. Nächster Schritt!',
        action: () => dispatchOnboardingCallback(samplesOrdered()),
      },
    },
    {
      title:
        `Dein Paket ist unterwegs! 📦<br />` +
        `Lass dich doch in der Zwischenzeit von der HELLO-Community inspirieren ✨`,
      steps: { current: 2, total: MAX_ONBOARDING_STEPS },
      subtitle: 'Bestelle Produkte für deinen Stream',
      message:
        'Klar, Warten ist langweilig. Aber du brauchst die Produkte für deinen ersten Stream, ' +
        'richtig? Sag uns Bescheid, sobald du sie erhalten hast.',
      button: {
        title: 'Bestellung erhalten!',
        action: () => dispatchOnboardingCallback(samplesReceived()),
      },
    },
    {
      title: `Du bist ready 🎉<br />Jetzt kannst du mit deinem ersten Stream durchstarten.`,
      steps: { current: 3, total: MAX_ONBOARDING_STEPS },
      subtitle: 'Plane deinen ersten Stream',
      message: 'Wähle dabei die Produkte aus, die du bestellt hast.',
    },
    isInApp
      ? {
          title: 'Gehe jetzt mit deinem ersten Stream live! 📱',
          steps: { current: 4, total: MAX_ONBOARDING_STEPS },
          subtitle: 'Starte deinen ersten Stream',
          message: `Du findest diesen in der <a href="${Config.env.streamersPortalUrl}/creator/dashboard">Streamübersicht</a>.`,
        }
      : {
          title: `Du hast deinen ersten Stream!<b/>👉 Jetzt fehlt dir nur noch unsere App`,
          subtitle: 'Downloade die HELLO for Streamers App und starte deinen ersten Stream',
          message: AppBadge(),
        },
    {
      title: `You did it! Glückwunsch zu deinem ersten Stream! ✨`,
      subtitle: 'Schau dir jetzt deine Statistiken an',
      message:
        `Im Reiter <a href="${Config.env.streamersPortalUrl}/creator/shows">Streams</a> ` +
        `unter Vergangene Streams kannst du dir die Performance anschauen. Aktiviere den Schalter, ` +
        `um nur die Daten zu sehen, die auf den Zeitraum deines Livestreams zutreffen.`,
      button: {
        title: 'Einführung abschließen',
        action: () => dispatchOnboardingCallback(onboardingCompleted()),
      },
    },
  ][step];
