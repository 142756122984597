import AppBuildInfo from '../../../components/AppBuildInfo/AppBuildInfo';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import ClassicDashboardContainer from '../../components/ClassicDashboardContainer/ClassicDashboardContainer';

const CreatorClassicDashboardPage = () => {
  const dashboard = () => (
    <div>
      <ClassicDashboardContainer />
      <AppBuildInfo />
    </div>
  );

  return <PageContainer pageToRender={dashboard()} backgroundColor={theme.whiteLilac} fullHeight />;
};

export default CreatorClassicDashboardPage;
