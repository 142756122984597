import LockIcon from '@mui/icons-material/Lock';
import { Divider } from '@mui/material';
import classNames from 'classnames';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { NUMBER_OF_LEVELS } from '../../../constants/streamerNumberOfLevels';
import t from '../../../constants/translation';
import { selectCreatorPerformanceOverview } from '../../state/creatorsSlice';
import InsightData from '../InsightData/InsightData';
import styles from './StreamerLevel.module.scss';

const StreamerNextLevel: FC = () => {
  const { nextLevel } = useSelector(selectCreatorPerformanceOverview);

  return (
    <div className={classNames(styles.hero_insights, styles.unlocked)}>
      <div className={styles.hero_title}>
        <h1>Level {nextLevel?.number} </h1> <span>von {NUMBER_OF_LEVELS}</span>
      </div>
      <div className={styles.overview}>
        <div className={styles.unlocked_progress}>
          <div className={styles.half_circle} />
          <div className={styles.circle_icon}>
            <LockIcon />
          </div>
        </div>
        <div className={styles.insights_data}>
          <InsightData title={t.creators['Net sales']} value={'- €'} />
          <InsightData title={t.creators.Revenue} value={'- €'} />
          <InsightData title={t.creators.Orders} value={'-'} />
          <InsightData title={t.creators.Commission} value={`- %`} />
          <InsightData title={t.creators['Streaming time']} value={'-'} />
        </div>
        <Divider className={styles.divider} />
        <div className={styles.hints}>
          <h1>
            <span className={styles.hint_title}>Tipp</span> 💡
          </h1>
          <ul className={styles.hint_list}>
            <li>Schalte Level {nextLevel?.number} frei für mehr Tipps.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default StreamerNextLevel;
