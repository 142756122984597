import React from 'react';
import PageContainer from '../../layout/PageContainer/PageContainer';
import theme from '../../theme/theme.module.scss';
import SurveysTable from '../components/SurveysTable/SurveysTable';

function SurveysPage() {
  return (
    <PageContainer backgroundColor={theme.whiteLilac} pageToRender={<SurveysTable />} fullHeight />
  );
}

export default SurveysPage;
