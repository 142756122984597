import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import classNames from 'classnames';
import styles from './SearchNotFound.module.scss';

const SearchNotFound = () => {
  return (
    <div>
      <div className="flex justify-center">
        <div className={`bg-white py-24 px-20 ${styles.box}`}>
          <SentimentDissatisfiedIcon className={styles.icon_face} />
          <p className={classNames('leading-16 mb-16', styles.info)}>
            Die Suche ergab leider kein Ergebnis. Bitte prüfe die Rechtschreibung oder ändere den
            Suchbegriff.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SearchNotFound;
