import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import Icons from '../../assets';
import Icon from '../Icon/Icon';
import styles from './ModalTittle.module.scss';
interface ModalProps {
  title: string;
  handleModalClose: () => void;
  leftElement?: ReactNode;
}

const ModalTitle = (props: ModalProps) => {
  return (
    <Box data-testid="modal-title" className={styles.title_container}>
      {props.leftElement ?? <Box></Box>}
      <Typography fontWeight={400} className={styles.title} fontSize={20}>
        {props.title}
      </Typography>
      <Button
        type={EButtonType.BLANK}
        className={styles.close_btn}
        size={EButtonSize.TINY}
        onClick={props.handleModalClose}
      >
        <Icon className={styles.close_icon} icon={Icons.closeIcon} dataTestId="icon" />
      </Button>
    </Box>
  );
};

export default ModalTitle;
