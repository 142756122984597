import { Input } from '@hse24/shared-components';
import cx from 'classnames';
import React, { FocusEvent } from 'react';
import styles from './InputTextField.module.scss';

export interface InputTextFieldProps {
  fieldName: string;
  label: string;
  value: string | undefined;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
  className?: string;
  disabled?: boolean;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
}

const InputTextField = ({
  fieldName,
  label,
  value,
  disabled,
  onChange,
  className,
  onBlur,
}: InputTextFieldProps) => {
  return (
    <div className={cx(className, styles.text_field, disabled && styles.disabled_text_field)}>
      <Input
        className={styles.input}
        name={fieldName}
        type="text"
        label={label}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default InputTextField;
