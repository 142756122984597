import { Box, Divider } from '@mui/material';
import AccessData from '../../../auth/components/AccessData/AccessData';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import theme from '../../../theme/theme.module.scss';
import DeleteAccount from '../../components/DeleteAccount/DeleteAccount';

const CreatorAccountPage = () => {
  const isApp = featureFlagValue(AvailableFlag.isInApp);
  return (
    <div data-testId="access-data">
      <AccessData />
      <Box mt={3} mb={3}>
        <Divider color={theme.lightGrey} />
      </Box>
      {isApp && <DeleteAccount />}
    </div>
  );
};

export default CreatorAccountPage;
