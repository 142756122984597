import { alpha, MenuList } from '@mui/material';
import { styled } from '@mui/styles';
import React, { ReactNode } from 'react';

const MenuListStyled = styled(MenuList)(({ theme }) => ({
  borderRadius: 6,
  padding: '4px 0',
  '& .MuiMenuItem-root': {
    padding: theme.spacing(1.5),
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      marginRight: theme.spacing(1.5),
    },
    '&:active': {
      backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    },
  },
}));

interface Props {
  children: ReactNode;
}

function StyledMenuList({ children }: Props) {
  return <MenuListStyled>{children}</MenuListStyled>;
}

export default StyledMenuList;
