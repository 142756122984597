import { LoadingSpinner } from '@hse24/shared-components';
import { ArrowForward } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import t from '../../../../constants/translation';
import { useQuery } from '../../../../hooks/useQuery';
import routePaths from '../../../../routes/routePaths';
import { Role } from '../../../role';
import {
  activateUserAction,
  activateUserRedirectAction,
  selectAuthActivationProgressData,
  selectAuthLoading,
} from '../../../state/authSlice';
import AuthForm from '../../AuthForm/AuthForm';

function WelcomeStep() {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  const activationTokenId = query.get('token');
  if (!activationTokenId) history.push(routePaths.landing);

  const loading = useSelector(selectAuthLoading);
  const role = useSelector(selectAuthActivationProgressData)?.role;
  const isLoading = loading || !role;

  useEffect(() => {
    if (activationTokenId) dispatch(activateUserAction(activationTokenId));
  }, [activationTokenId]);

  const handleSubmit = () => {
    dispatch(activateUserRedirectAction());
  };

  return isLoading ? (
    <div data-testid={'loading-spinner'}>
      <LoadingSpinner />
    </div>
  ) : (
    <AuthForm
      header={
        role && (
          <>
            <Typography variant={'h3'}>Willkommen bei HELLO!</Typography>
            {role === Role.STREAMER_HELLO && (
              <Typography variant={'body1'}>
                Wir freuen uns, dass du als Streamer ein Teil von HELLO sein willst. In den nächsten
                Schritten schließen wir die Einrichtung deines Streamer-Kontos ab. Falls du bereits
                einen Account bei HELLO hast, bitten wir dich im nächsten Schritt, dich einzuloggen.
              </Typography>
            )}
            {role === Role.AGENCY && (
              <Typography variant={'body1'}>
                Wir freuen uns, dass du als {t.agencies.Agency} ein Teil von HELLO sein willst.
                Falls du bereits unter dieser E-Mail-Adresse einen Account bei HELLO hast, bitten
                wir dich im nächsten Schritt, dich einzuloggen.
              </Typography>
            )}
          </>
        )
      }
      submitButton={{ title: 'Weiter', icon: <ArrowForward /> }}
      submitCallback={handleSubmit}
    />
  );
}

export default WelcomeStep;
