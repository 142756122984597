import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import React from 'react';

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    '&:hover': {
      color: '#ff3c28',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#ff3c28',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  })
);

export default StyledTab;
