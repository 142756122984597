import { Grid } from '@mui/material';
import React, { ReactNode } from 'react';
import theme from '../../theme/theme.module.scss';
import { COMMISSION_TAB, COMMISSION_TAB_PANEL } from '../StreamerCommissionInfoTooltip/utils';

interface Props {
  index: number;
  value: number;
  children?: ReactNode;
}

const TabPanel = ({ value, index, children }: Props) => (
  <div
    role={'tab-panel'}
    hidden={value !== index}
    id={`${COMMISSION_TAB_PANEL}-${index}`}
    aria-labelledby={`${COMMISSION_TAB}-${index}`}
  >
    {value === index && (
      <Grid bgcolor={theme.whiteLilac} py={theme.space24}>
        {children}
      </Grid>
    )}
  </div>
);

export default TabPanel;
