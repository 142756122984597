import '@hse24/shared-components/dist/shared-components.css';
import '@hse24/shared-components/index.scss';
import { StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ConnectedRouter } from 'connected-react-router';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/App/App';
import { browserHistory, store } from './app/store';
import { FeatureFlags } from './config/FeatureFlags';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { dataExchangerService } from './storage/dataExchangerService';
import { baseTheme } from './theme/theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

dataExchangerService.init();

ReactDOM.render(
  <StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={baseTheme}>
        <CssBaseline />
        <Provider store={store}>
          <ConnectedRouter history={browserHistory}>
            <FeatureFlags>
              <App />
            </FeatureFlags>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
