import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { generatePath } from 'react-router-dom';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Role } from '../../auth/role';
import { selectUserRoles } from '../../auth/state/authSlice';
import { selectLoggedInCreator } from '../../creators/state/creatorsSlice';
import { errorOccurred } from '../../notifications/state/notificationsSlice';
import routePaths from '../../routes/routePaths';
import paymentsApi from '../api/paymentsApi';
import {
  CreatorInvoices,
  InvoiceDetails,
  PaymentDetailsParams,
  PaymentsDetails,
  PaymentsMonthRange,
} from '../model/Payments';
import {
  getCreatorInvoices,
  getCreatorPaymentsDetails,
  getInvoiceDetails,
  getPaymentsDetails,
  navigateToInvoiceDetails,
  setCreatorInvoices,
  setInvoiceDetails,
  setPaymentsDetails,
} from './paymentsSlice';

export function* fetchPaymentsDetails(dateRange: PaymentsMonthRange) {
  try {
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    const creatorId = creator?.id;
    if (creatorId) {
      const paymentsDetails: PaymentsDetails = yield call(
        paymentsApi.getPaymentsDetails,
        dateRange,
        creatorId
      );
      yield put(setPaymentsDetails(paymentsDetails));
    } else {
      yield put(
        errorOccurred(
          new Error(
            'Daten konnten nicht geladen werden. Bitte wende dich an den Support (Hinweis: Creator-ID fehlt).'
          )
        )
      );
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchPaymentsDetailsHandler(action: PayloadAction<PaymentsMonthRange>) {
  try {
    const dateRange = action.payload;
    yield fetchPaymentsDetails(dateRange);
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchCreatorDetailsPaymentsDetailsHandler(
  action: PayloadAction<PaymentDetailsParams>
) {
  try {
    const dateRange = action.payload.dateRange;
    const creatorId: string = action.payload.creatorId;
    const paymentsDetails: PaymentsDetails = yield call(
      paymentsApi.getPaymentsDetails,
      dateRange,
      creatorId
    );
    yield put(setPaymentsDetails(paymentsDetails));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

function* fetchCreatorInvoices(creatorId: string | undefined) {
  try {
    if (creatorId) {
      const creatorInvoices: CreatorInvoices = yield call(
        paymentsApi.getCreatorInvoices,
        creatorId
      );
      yield put(setCreatorInvoices(creatorInvoices));
    } else {
      yield put(
        errorOccurred(
          new Error(
            'Daten konnten nicht geladen werden. Bitte wende dich an den Support (Hinweis: Creator-ID fehlt).'
          )
        )
      );
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchCreatorInvoicesHandler() {
  try {
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    yield call(fetchCreatorInvoices, creator?.id);
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* fetchInvoiceDetailsHandler(action: PayloadAction<string>) {
  try {
    const invoiceDetails: InvoiceDetails = yield call(
      paymentsApi.getInvoiceDetails,
      action.payload
    );
    yield put(setInvoiceDetails(invoiceDetails));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* navigateToInvoiceDetailsHandler(action: PayloadAction<string>) {
  const userRoles: Role[] = yield select(selectUserRoles);
  if (userRoles.includes(Role.STREAMER_HELLO)) {
    const path = generatePath(routePaths.creator.invoiceDetails, { invoiceId: action.payload });
    yield put(push(path));
  }
}

export function* watcherPaymentsSagas() {
  yield takeLatest(getPaymentsDetails.type, fetchPaymentsDetailsHandler);
  yield takeLatest(getCreatorInvoices.type, fetchCreatorInvoicesHandler);
  yield takeLatest(getCreatorPaymentsDetails.type, fetchCreatorDetailsPaymentsDetailsHandler);
  yield takeLatest(getInvoiceDetails.type, fetchInvoiceDetailsHandler);
  yield takeLatest(navigateToInvoiceDetails.type, navigateToInvoiceDetailsHandler);
}
