import { CircularProgress, Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { BaseSyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import { selectProgressDialogDetails } from '../../state/notificationsSlice';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '10px',
    },
    message: {
      marginLeft: '20px',
    },
  })
);

const ProgressDialog = () => {
  const dialogData = useSelector(selectProgressDialogDetails);
  const classes = useStyles();

  const onClose = (e: BaseSyntheticEvent) => e.preventDefault();

  return (
    <Dialog
      open={!!dialogData?.toggle}
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth
      aria-labelledby="progress-dialog"
    >
      <div className={classes.content}>
        <CircularProgress />
        <span className={classes.message}>
          {dialogData?.message ? dialogData?.message : 'Bitte warten...'}
        </span>
      </div>
    </Dialog>
  );
};

export default ProgressDialog;
