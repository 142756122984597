import { IconInfo, Text } from '@hse24/shared-components';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ResponsiveDetailsRow from '../../../../components/DetailsRow/ResponsiveDetailsRow';
import formatTime from '../../../../utils/formatters/formatTime/formatTime';
import { EUROPE_BERLIN_TIMEZONE } from '../../../constants';
import { ShowDetails } from '../../../model/shows';
import StreamedByInfo from '../StreamedBy/StreamedByInfo';
import styles from './ShowInfo.module.scss';

interface ShowInfoProps {
  showDetails: ShowDetails;
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);

const SHOW_DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const formatDateIfProvided = (dateAsString: string | undefined): string =>
  dateAsString ? dayjs(dateAsString).tz(EUROPE_BERLIN_TIMEZONE).format(SHOW_DATE_FORMAT) : '-';

const ShowInfo = ({ showDetails }: ShowInfoProps) => {
  return (
    <div data-testid={'show-info'}>
      <Box className={styles.show_info_header}>
        <IconInfo width="24px" height="24px" />
        <Text.B1>Allgmeine Informationen</Text.B1>
      </Box>
      <StreamedByInfo showId={showDetails.id} streamedBy={showDetails.streamedBy} />
      <ResponsiveDetailsRow label="ID" value={showDetails.id} withCopyIcon />
      {!!showDetails.title && <ResponsiveDetailsRow label="Titel" value={showDetails.title} />}
      {!!showDetails.scheduledAt && (
        <ResponsiveDetailsRow
          label="Geplant"
          value={formatDateIfProvided(showDetails.scheduledAt)}
        />
      )}
      {!!showDetails.startedAt && (
        <ResponsiveDetailsRow
          label="Startzeit"
          value={formatDateIfProvided(showDetails.startedAt)}
        />
      )}
      {!!showDetails.endedAt && (
        <ResponsiveDetailsRow label="Endzeit" value={formatDateIfProvided(showDetails.endedAt)} />
      )}
      {!!showDetails.durationInSeconds && (
        <ResponsiveDetailsRow label="Dauer" value={formatTime(showDetails.durationInSeconds)} />
      )}
    </div>
  );
};

export default ShowInfo;
