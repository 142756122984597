import Config from '../config/config';

export const latoRegular = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 400,
  src: `local('Lato Regular'),
        local('Lato-Regular'),
        url(${Config.env.assetsBaseUrl}/files/c/fonts/lato-v16-latin-regular.woff) format('truetype')`,
};

export const latoBold = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap' as const,
  fontWeight: 700,
  src: `local('Lato Bold '),
        local('Lato-Bold'),
        url('${Config.env.assetsBaseUrl}/files/c/fonts/lato-v16-latin-700.woff') format('truetype')`,
};
