import { Box, Chip, ChipProps } from '@mui/material';
import React from 'react';
import { ReactComponent as HseClassicLogo } from '../../assets/HSE_CLASSIC_LOGO.svg';
import { ReactComponent as HseHelloLogo } from '../../assets/HSE_HELLO_LOGO.svg';
import { Audience } from '../../common/types';

interface Props {
  audience?: Audience[];
  chipProps?: ChipProps;
}

function AudienceBadge({ audience, chipProps }: Props) {
  const audienceLabel = () => {
    if (audience?.includes(Audience.HELLO) && audience?.includes(Audience.CLASSIC)) {
      return (
        <>
          <Chip data-testid={'hello-audience-logo'} label={<HseHelloLogo />} variant="outlined" />
          <Chip data-testid={'hse-audience-logo'} label={<HseClassicLogo />} variant="outlined" />
        </>
      );
    }
    if (audience?.includes(Audience.CLASSIC) && audience?.length === 1) {
      return (
        <Chip
          data-testid={'hse-audience-logo'}
          label={<HseClassicLogo />}
          {...chipProps}
          variant="outlined"
        />
      );
    }
    if (audience?.includes(Audience.HELLO) && audience?.length === 1) {
      return (
        <Chip
          data-testid={'hello-audience-logo'}
          label={<HseHelloLogo />}
          {...chipProps}
          variant="outlined"
        />
      );
    }
    return <Chip data-testid={'none-audience'} label={'KEINE'} />;
  };
  return (
    <Box display={'flex'} gap={1}>
      {audienceLabel()}
    </Box>
  );
}

export default AudienceBadge;
