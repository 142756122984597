import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Chip, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MouseEvent, useState } from 'react';
import OnboardingDetailsStatusModal from '../../../../components/OnboardingDetailsStatusModal/OnboardingDetailsStatusModal';
import {
  getNoOfOnboardingStepsDone,
  mapCreatorOverviewToOnboardingStatus,
} from '../../../../utils/onboardingMapper';
import { CreatorOverview } from '../../../model/creator';

interface CreatorsTableStatusCellProps {
  creatorOverview: CreatorOverview;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      minWidth: '110px',
      backgroundColor: theme.palette.error.main,
    },
    checkIcon: {
      opacity: 0.5,
    },
  })
);

const CreatorsTableStatusCell = ({ creatorOverview }: CreatorsTableStatusCellProps) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openOnboardingDetailsStatusModal = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const renderCell = (): JSX.Element => {
    if (creatorOverview.onboarding.isOnboardingCompleted) {
      return <CheckCircleIcon color="primary" className={classes.checkIcon} />;
    } else {
      return (
        <Chip
          data-testid="creators-table-status-chip"
          label={getNoOfOnboardingStepsDone(creatorOverview)}
          onClick={openOnboardingDetailsStatusModal}
          color="primary"
        />
      );
    }
  };

  return (
    <>
      {renderCell()}
      <OnboardingDetailsStatusModal
        isOpen={isModalOpen}
        creatorName={creatorOverview.name}
        creatorId={creatorOverview.id}
        onboardingStatus={mapCreatorOverviewToOnboardingStatus(creatorOverview)}
        onCloseCallback={isOpen => setIsModalOpen(isOpen)}
      />
    </>
  );
};

export default CreatorsTableStatusCell;
