import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined';
import { Alert, AlertTitle, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UpcomingShowsLoading from '../../../components/CreateContent/UpcomingShowsLoading/UpcomingShowsLoading';
import MobileAppBar from '../../../components/MobileAppBar/MobileAppBar';
import { AvailableFlag, Flag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import {
  getCreatorUpcomingShows,
  selectCreatorUpcomingShows,
  selectShowsAreBeingLoaded,
} from '../../state/showsSlice';
import ShowCardWithUpcomingShow from '../StartingSoonShowsSlider/ShowCardWithUpcomingShow/ShowCardWithUpcomingShow';
import styles from './UpcomingStreams.module.scss';

function UpcomingStreams() {
  const history = useHistory();
  const dispatch = useDispatch();

  const upcomingShows = useSelector(selectCreatorUpcomingShows);
  const loading = useSelector(selectShowsAreBeingLoaded);
  const [showAlert, setShowAlert] = useState(true);

  const handleArrowBack = () => {
    history.push(routePaths.creator.dashboard);
  };

  useEffect(() => {
    dispatch(
      getCreatorUpcomingShows({
        pageSize: 10,
        page: 0,
      })
    );
  }, [dispatch]);

  return (
    <>
      <MobileAppBar landing={handleArrowBack} title={'Startet bald'} />
      <Flag flag={AvailableFlag.enableStreamDeleting}>
        {showAlert ? (
          <Box>
            <Alert
              className={styles.alert}
              severity="warning"
              variant="outlined"
              icon={<WarningAmberOutlined />}
              onClose={() => setShowAlert(false)}
            >
              <AlertTitle className={styles.alert_title}>
                {t.creators.show['Expired stream']}
              </AlertTitle>
              {t.creators.show['delete expired streams notification']}
            </Alert>
          </Box>
        ) : null}
      </Flag>
      <Box className={styles.shows}>
        {loading ? (
          <>
            <UpcomingShowsLoading size={'lg'} />
            <UpcomingShowsLoading size={'lg'} />
            <UpcomingShowsLoading size={'lg'} />
            <UpcomingShowsLoading size={'lg'} />
          </>
        ) : (
          upcomingShows.map(show => <ShowCardWithUpcomingShow key={show.id} upcomingShow={show} />)
        )}
      </Box>
    </>
  );
}

export default UpcomingStreams;
