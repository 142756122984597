import { CSSProperties, ReactElement } from 'react';
import styles from './IconCard.module.scss';

interface Props {
  text: string | number | undefined;
  textStyle?: CSSProperties | undefined;
  icon: ReactElement;
}

const IconCard = ({ text, textStyle, icon }: Props) => {
  return (
    <div className={styles.icon_wrapper}>
      {icon}
      <span style={textStyle}>{text}</span>
    </div>
  );
};

export default IconCard;
