import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isMobileBreakPoint } from '../../../common/mediaQueries';
import { addPathQuery } from '../../../common/utils';
import MobileAppBar from '../../../components/MobileAppBar/MobileAppBar';
import { useQuery } from '../../../hooks/useQuery';
import {
  fetchAmasByShowIdAction,
  selectAmaLoading,
  selectAmas,
} from '../../../onDemandInteractions/amas/state/amasSlice';
import routePaths from '../../../routes/routePaths';
import AmaMessageContent from './AmaMessageContent';

function AmaMessages() {
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useQuery();

  const showId = search.get('showId');

  const isMobile = isMobileBreakPoint();

  const amas = useSelector(selectAmas);
  const loading = useSelector(selectAmaLoading);

  useEffect(() => {
    if (showId) {
      dispatch(fetchAmasByShowIdAction(showId));
    }
  }, [showId]);

  const goBack = () => {
    history.push(
      addPathQuery(routePaths.creator.analytics, {
        tab: 'Streams',
      })
    );
  };

  return (
    <Box>
      {isMobile && <MobileAppBar landing={goBack} title={'AMA'} />}
      <Box
        display={'flex'}
        alignItems={'center'}
        style={{ cursor: 'pointer' }}
        gap={1}
        onClick={goBack}
        mb={4}
      >
        <IconButton>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography variant={'body1'}>Zurück zur Vergangene Streams</Typography>
      </Box>
      {!loading ? (
        <>
          {amas && amas.length && amas[0].responses.length ? (
            <Grid container spacing={5} rowSpacing={2}>
              {amas?.map(ama => (
                <Grid key={ama.id} item xs={12} md={6} lg={6}>
                  <AmaMessageContent ama={ama} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant={'body1'} textAlign={'center'}>
              Keine AMA-Nachricht gefunden
            </Typography>
          )}
        </>
      ) : (
        <Grid container spacing={1} data-testid={'skeleton'}>
          <Grid item xs={6} lg={3}>
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={100} />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={100} />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={100} />
          </Grid>
          <Grid item xs={6} lg={3}>
            <Skeleton variant="rectangular" animation={'wave'} width={'100%'} height={100} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default AmaMessages;
