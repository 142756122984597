import { ArrowIconLeft, Text } from '@hse24/shared-components';
import { Box } from '@mui/material';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import t from '../../constants/translation';
import styles from './GobackHeader.module.scss';
const GobackHeader = ({ className }: { className?: string }) => {
  const history = useHistory();
  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div>
      <Box className={cx(styles.exit, className)} onClick={handleGoBack} data-testid="back-button">
        <ArrowIconLeft width="16px" height="16px" className={styles.icon} />
        <Text.Body className={styles.text}>{t.creators.show.exitShowCreation}</Text.Body>
      </Box>
    </div>
  );
};
export default GobackHeader;
