import { FormControl, InputLabel, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { useFormikContext } from 'formik';
import { ReactNodeArray, useEffect } from 'react';

export interface FormikSelectFieldProps {
  fieldName: string;
  label: string;
  value: string;
  menuItems: ReactNodeArray;
  muiProps?: SelectProps;
  onChange?: (e: SelectChangeEvent) => void;
}

/**
 * This component is based on a FormikContext in the current path.
 * It renders a MUI Select and links it to props.fieldName in
 * the FormikContext.
 */
const FormikSelect = (props: FormikSelectFieldProps) => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  const { values, errors, handleChange, setFieldValue, handleBlur, touched } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormikContext<any>();

  useEffect(() => {
    setFieldValue(props.fieldName, props.value);
  }, [props.value]);

  const onChange = (event: SelectChangeEvent) => {
    handleChange(event);
    props.onChange && props.onChange(event);
  };

  return (
    <FormControl
      variant={'outlined'}
      fullWidth
      disabled={props.muiProps ? props.muiProps.disabled : false}
    >
      <InputLabel id={'id_' + props.fieldName}>{props.label}</InputLabel>
      <Select
        id={props.fieldName}
        labelId={'id_' + props.fieldName}
        name={props.fieldName}
        label={props.label}
        error={touched[props.fieldName] && Boolean(errors[props.fieldName])}
        onChange={onChange}
        onBlur={handleBlur}
        value={values[props.fieldName]}
        variant="outlined"
        inputProps={{ id: props.fieldName, 'data-testid': 'formik-select-input' }}
        {...props.muiProps}
      >
        {props.menuItems}
      </Select>
    </FormControl>
  );
};

export default FormikSelect;
