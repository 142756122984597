import { isFuture, isValid, parse } from 'date-fns';
import * as yup from 'yup';

export const defaultDateFormat = 'dd.MM.yyyy HH:mm';

const createShowSchema = () =>
  yup.object({
    header: yup.string().min(3, 'Mindestens 3 Zeichen').max(40, 'Maximal 40 Zeichen'),
    title: yup
      .string()
      .required('Bitte gib einen Titel ein')
      .min(3, 'Mindestens 3 Zeichen')
      .max(60, 'Bitte verwende einen kürzen Stream-Titel mit maximal 60 Zeichen')
      .test('is valid title', 'Bitte geb mindestens 3 Zeichen ein', value =>
        value ? value.trim().length >= 3 : false
      ),
    scheduledStartAt: yup
      .string()
      .required('Startzeit ist erforderlich')
      .test(
        'invalid',
        'Ungültiges Datum',
        value => !!value && isValid(parse(value, defaultDateFormat, new Date()))
      )
      .test('invalid', 'Startzeit muss in der Zukunft liegen', value => {
        return !!value && isFuture(parse(value, defaultDateFormat, new Date()));
      }),
    baseProductsNo: yup
      .array()
      .required('Keine Produkte ausgewählt')
      .test('invalid', 'Keine Produkte ausgewählt', value => !!value && value.length > 0),
    preview: yup
      .object()
      .required('Kein Bild ausgewählt')
      .test('invalid', 'Kein Bild ausgewählt', value => (value?.changed ? value?.imageUrl : true)),
    tags: yup.array().optional().max(15, 'Maximum 15 Tags'),
    voucherId: yup.string().optional(),
    salesTopic: yup.string().optional(),
  });

export default createShowSchema;
