import { AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import Config from '../../config/config';
import axiosClient, { get } from '../../shared/axiosClient';
import { SearchResponse } from '../model/searchResponse';
import { TSuggestElement } from '../model/suggestElement';

const getFactFinderResult = async (query?: string): Promise<SearchResponse> => {
  const response = await get<SearchResponse>(
    `${Config.env.factFinderSearchUrl}dede?query=${query}`
  );
  return camelizeKeys(response.data) as SearchResponse;
};

const getSuggestions = async (
  searchTerm: string
): Promise<AxiosResponse<{ data: TSuggestElement[] }>> => {
  const query = encodeURIComponent(searchTerm);
  return await axiosClient.get(`${Config.env.factFinderSuggestUrl}dede?query=${query}`);
};

const getFactFinderResults = async (query?: string): Promise<SearchResponse> => {
  const q = `${
    query || '*'
  }&hitsPerPage=10&filter=Status:SELLABLE&filter=ProductSocialLiveChannel:true`;
  const response = await get<SearchResponse>(`${Config.env.factFinderSearchUrl}dede?query=${q}`);
  return camelizeKeys(response.data) as SearchResponse;
};

const factFinderApi = { getFactFinderResult, getSuggestions, getFactFinderResults };
export default factFinderApi;
