import axios from 'axios';
import { camelizeKeys } from 'humps';
import RefreshTokenUtil from '../../agencies/api/RefreshTokenUtil';
import Config from '../../config/config';
import { OrdersOverviewResponse } from './requestResponse';

const ohsBaseUrl = Config.env.orderHistoryServiceUrl;

const getOrdersOverview = async (baseUrl: string = ohsBaseUrl): Promise<OrdersOverviewResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    axios.get<OrdersOverviewResponse>(`${baseUrl}/orders`, {
      withCredentials: true,
    })
  );
  return camelizeKeys(response.data) as OrdersOverviewResponse;
};

const orderApi = {
  getOrdersOverview,
};

export default orderApi;
