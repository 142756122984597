import { CloseOutlined } from '@mui/icons-material';
import { Box, Dialog, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectUserIsAgency,
  selectUserIsCreator,
  selectUserIsHSEEmployee,
} from '../../auth/state/authSlice';
import t from '../../constants/translation';
import {
  selectCreatorPerformanceOverview,
  selectStreamerCurrentLevel,
} from '../../creators/state/creatorsSlice';
import theme from '../../theme/theme.module.scss';
import CommissionInfoTabPanel from '../CommissionInfoTabPanel/CommissionInfoTabPanel';
import InfoTable from '../InfoTable/InfoTable';
import {
  streamerCommissionTables,
  StreamerCommissionType,
} from '../StreamerCommissionInfoTooltip/streamerCommissionTables';
import { COMMISSION_TABS, getTapProps } from '../StreamerCommissionInfoTooltip/utils';
import styles from './CommissionModal.module.scss';

interface Props {
  handleCloseTable: () => void;
  isOpen: boolean;
}

function CommissionModal({ isOpen, handleCloseTable }: Props) {
  const currentLevel = useSelector(selectStreamerCurrentLevel);
  const isCreator = useSelector(selectUserIsCreator);
  const isAgency = useSelector(selectUserIsAgency);
  const isHSEEmployee = useSelector(selectUserIsHSEEmployee);

  const [value, setValue] = useState(0);

  const sampleOrderFeeMonth = useSelector(selectCreatorPerformanceOverview).sampleOrderFeeMonth;
  const creatorIsWithinThreeMonthPeriod = !!sampleOrderFeeMonth;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseTable}
      PaperProps={{
        className: styles.modal,
      }}
    >
      <Box className={styles.modal_content}>
        <Grid
          container
          className={styles.grid}
          spacing={0.5}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10.5}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label={COMMISSION_TABS}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                className={styles.tab}
                label={
                  creatorIsWithinThreeMonthPeriod
                    ? 'Getting Started'
                    : t.creators['Sales commission']
                }
                {...getTapProps(0)}
                disableRipple
              />
              {creatorIsWithinThreeMonthPeriod && (
                <Tab
                  disableRipple
                  className={styles.tab}
                  label={t.creators.Interactions}
                  {...getTapProps(1)}
                />
              )}
              <Tab disableRipple className={styles.tab} label="Social Media" {...getTapProps(2)} />
            </Tabs>
          </Grid>
          <Grid item xs={1.5}>
            <Grid container justifyContent={'flex-end'}>
              <IconButton onClick={handleCloseTable}>
                <CloseOutlined />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <CommissionInfoTabPanel value={value} index={0}>
          {creatorIsWithinThreeMonthPeriod && (
            <>
              <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.CONTENT_HOURS)} />
              <InfoTable
                {...streamerCommissionTables.get(StreamerCommissionType.CONTENT_MONTHS)}
                selectedRow={sampleOrderFeeMonth ?? 0}
              />
            </>
          )}
          {isCreator && (
            <InfoTable
              {...streamerCommissionTables.get(StreamerCommissionType.STREAMER_SALES_PROVISION)}
              selectedRow={currentLevel}
            />
          )}
          {(isAgency || isHSEEmployee) && (
            <InfoTable
              {...streamerCommissionTables.get(StreamerCommissionType.AGENCY_SALES_PROVISION)}
              selectedRow={currentLevel}
            />
          )}
        </CommissionInfoTabPanel>
        {creatorIsWithinThreeMonthPeriod && (
          <CommissionInfoTabPanel value={value} index={1}>
            <Typography
              fontWeight={theme.fontWeight7}
              fontSize={theme.fontSize14}
              m={theme.space24}
              maxWidth={657}
            >
              Prämien für Interaktionen in deinen ersten drei Monaten
            </Typography>
            <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.VIEWS)} />
            <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.LIKES)} />
            <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.COMMENTS)} />
            <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.ORDERS)} />
          </CommissionInfoTabPanel>
        )}
        <CommissionInfoTabPanel value={value} index={creatorIsWithinThreeMonthPeriod ? 2 : 1}>
          {isCreator ? (
            <>
              <InfoTable
                {...streamerCommissionTables.get(StreamerCommissionType.CREATOR_INSTAGRAM)}
              />
              <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.CREATOR_TIKTOK)} />
            </>
          ) : (
            <>
              <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.INSTAGRAM)} />
              <InfoTable {...streamerCommissionTables.get(StreamerCommissionType.TIKTOK)} />
            </>
          )}
        </CommissionInfoTabPanel>
      </Box>
    </Dialog>
  );
}

export default CommissionModal;
