import React from 'react';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import StreamerAcademyVideo from '../../components/StreamerAcademyVideo/StreamerAcademyVideo';

function StreamerAcademyVideoPage() {
  return (
    <PageContainer
      pageToRender={<StreamerAcademyVideo />}
      backgroundColor={theme.whiteLilac}
      fullHeight
    />
  );
}

export default StreamerAcademyVideoPage;
