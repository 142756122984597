import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { ChangeEvent, useEffect } from 'react';
import styles from './FormikTextField.module.scss';
export interface FormikTextFieldProps {
  fieldName: string;
  label: string;
  value: string | undefined;
  muiProps?: TextFieldProps;
  inputRef?: React.Ref<HTMLElement>;
  onChange?: (value?: string) => void;
  className?: string;
}

/**
 * This component is based on a FormikContext in the current path.
 * It renders a MUI TextField and links it to props.fieldName in
 * the FormikContext.
 */
const FormikTextField = ({
  fieldName,
  label,
  muiProps,
  value,
  inputRef,
  onChange,
  className,
}: FormikTextFieldProps) => {
  const { values, setFieldValue, errors, handleChange, handleBlur, touched } =
    // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
    // eslint-disable-next-line
    useFormikContext<any>();

  useEffect(() => {
    setFieldValue(fieldName, value?.trim(), false);
  }, [value]);

  const onChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    onChange && onChange(event.target.value);
  };

  const maxCharacters = muiProps?.inputProps?.maxLength;
  return (
    <div className={cx(className, styles.text_field)}>
      <TextField
        fullWidth
        id={fieldName}
        type="text"
        label={label}
        variant="outlined"
        error={touched[fieldName] && Boolean(errors[fieldName])}
        onChange={onChangeEvent}
        onBlur={e => {
          setFieldValue(fieldName, e.target.value.trim());
          handleBlur(e);
        }}
        value={values[fieldName] ?? ''}
        // This is a workaround to prevent "jumping" of UI elements if errors are shown / hidden.
        // (see https://github.com/mui-org/material-ui/issues/7747#issuecomment-322029315)
        helperText={touched[fieldName] && errors[fieldName] ? errors[fieldName] : ' '}
        {...muiProps}
        inputRef={inputRef}
      />
      {maxCharacters && (
        <span className={styles.char_count}>
          {(values[fieldName] ?? '').length}/{maxCharacters}
        </span>
      )}
    </div>
  );
};

export default FormikTextField;
