import { Language } from '@mui/icons-material';
import { Typography } from '@mui/material';
import t from '../../../../../../../src/constants/translation';
import styles from './TimeZone.module.scss';

const TimeZone = () => {
  return (
    <div data-testid="time-zone" className={styles.time_zone}>
      <Language data-testid="web-icon" width="24px" height="24px" />
      <Typography>{t.creators.show.timeZone}</Typography>
    </div>
  );
};

export default TimeZone;
