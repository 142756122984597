import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  DateRange,
  DateRangeWithSelectedFilter,
} from '../../components/DateFilter/dateFilterTypes';
import { CreatorOverview } from '../../creators/model/creator';
import { TableFilter } from '../../creators/state/creatorsSlice';
import { Currency } from '../../payments/model/Payments';
import { AgencyRequest } from '../api/agencyApi';
import {
  AgenciesOverview,
  Agency,
  AgencyDetails,
  AgencyDetailsRequest,
  AgencyPerformanceOverviewIdRequest,
} from '../model/agency';

export interface AgenciesState {
  agency: Agency | undefined;
  agencyDetails: {
    data: AgencyDetails;
    isLoading: boolean;
    tableFilter?: TableFilter;
    dateFilter?: DateRangeWithSelectedFilter;
  };
  agenciesOverview: {
    data: AgenciesOverview | undefined;
    isLoading: boolean;
    tableFilter?: TableFilter;
    dateFilter?: DateRangeWithSelectedFilter;
  };
  agenciesSummary: Agency[];
}

export const initialState: AgenciesState = {
  agency: undefined,
  agencyDetails: {
    data: {
      totals: {
        streamedSeconds: 0,
        floorSales: { amount: 0, currency: Currency.EUR },
        agencyCommission: { amount: 0, currency: Currency.EUR },
      },
      streamers: [],
    },
    isLoading: false,
    tableFilter: {
      pagination: {
        pageSize: 10,
      },
    },
  },
  agenciesOverview: {
    isLoading: false,
    data: undefined,
    tableFilter: {
      pagination: {
        pageSize: 10,
      },
    },
  },
  agenciesSummary: [],
};

export const saveNewAgency = createAction<AgencyRequest>('agencies/saveNewAgency');
export const getAgencyDetailsById = createAction<AgencyPerformanceOverviewIdRequest>(
  'agencies/getAgencyDetailsById'
);
export const getAgencyDetails = createAction<AgencyDetailsRequest>('agencies/getAgencyDetails');
export const getAgenciesOverview = createAction<DateRange>('agencies/AgenciesOverview');

export const navigateToAgencyDetailsPage = createAction<{ agencyId: string }>(
  'agencies/navigateToAgencyDetailsPage'
);

export const getAgenciesSummary = createAction('agencies/getAgenciesSummary');

export const agenciesSlice = createSlice({
  name: 'agencies',
  initialState,
  reducers: {
    setAgency: (state, action: PayloadAction<Agency>) => {
      state.agency = action.payload;
    },
    setAgencyDetails: (state, action: PayloadAction<AgencyDetails>) => {
      state.agencyDetails.data = action.payload;
      state.agencyDetails.isLoading = false;
    },
    setAgenciesOverview: (state, action: PayloadAction<AgenciesOverview>) => {
      state.agenciesOverview.data = action.payload;
    },
    setAgenciesOverviewLoading: (state, action: PayloadAction<boolean>) => {
      state.agenciesOverview.isLoading = action.payload;
    },
    setAgencyDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.agencyDetails.isLoading = action.payload;
    },
    addToAgencyStreamers: (state, action: PayloadAction<CreatorOverview>) => {
      state.agencyDetails.data.streamers = [action.payload].concat(
        state.agencyDetails.data.streamers
      );
    },
    setAgenciesOverviewTableFilter: (state, action: PayloadAction<TableFilter>) => {
      state.agenciesOverview.tableFilter = action.payload;
    },
    setAgenciesOverviewDateRange: (state, action: PayloadAction<DateRangeWithSelectedFilter>) => {
      state.agenciesOverview.dateFilter = action.payload;
    },
    setAgencyStreamersOverviewTableFilter: (state, action: PayloadAction<TableFilter>) => {
      state.agencyDetails.tableFilter = action.payload;
    },
    setAgencyStreamersOverviewDateFilter: (
      state,
      action: PayloadAction<DateRangeWithSelectedFilter>
    ) => {
      state.agencyDetails.dateFilter = action.payload;
    },
    setAgenciesSummary: (state, action: PayloadAction<Agency[]>) => {
      state.agenciesSummary = action.payload;
    },
  },
});

export const {
  setAgency,
  setAgencyDetails,
  setAgenciesOverview,
  setAgenciesOverviewLoading,
  setAgencyDetailsLoading,
  addToAgencyStreamers,
  setAgenciesOverviewTableFilter,
  setAgenciesOverviewDateRange,
  setAgencyStreamersOverviewTableFilter,
  setAgencyStreamersOverviewDateFilter,
  setAgenciesSummary,
} = agenciesSlice.actions;

const selectAgenciesState = (state: RootState) => state[agenciesSlice.name];

export const selectAgencyStreamedSeconds = createSelector(
  selectAgenciesState,
  state => state.agencyDetails.data.totals.streamedSeconds
);

export const selectAgencyCommission = createSelector(
  selectAgenciesState,
  state => state.agencyDetails.data.totals.agencyCommission
);

export const selectAgencyGeneratedSales = createSelector(
  selectAgenciesState,
  state => state.agencyDetails.data.totals.floorSales
);

export const selectAgencyStreamers = createSelector(
  selectAgenciesState,
  state => state.agencyDetails.data?.streamers
);

export const selectAgencyStreamersOverviewTableFilter = createSelector(
  selectAgenciesState,
  state => state.agencyDetails.tableFilter
);

export const selectAgencyStreamersOverviewDateFilter = createSelector(
  selectAgenciesState,
  state => state.agencyDetails.dateFilter
);

export const selectAgenciesOverview = (state: RootState) =>
  state.agencies.agenciesOverview.data?.agencies;

export const selectAgenciesOverviewIsLoading = (state: RootState) =>
  state.agencies.agenciesOverview.isLoading;

export const selectAgencyDetailsLoading = (state: RootState) =>
  state.agencies.agencyDetails.isLoading;

export const selectAgenciesTableFilter = createSelector(
  selectAgenciesState,
  state => state.agenciesOverview.tableFilter
);

export const selectAgenciesDateRange = createSelector(
  selectAgenciesState,
  state => state.agenciesOverview.dateFilter
);

export const selectAgenciesSummary = createSelector(
  selectAgenciesState,
  state => state.agenciesSummary
);

export default agenciesSlice.reducer;
