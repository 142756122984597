import { POSSIBLE_IMAGE_MANAGER_WIDTHS } from '../constants/media';

export const getUrlWithImageManagerParams = (
  baseUrl: string,
  screenWidth: number = window.innerWidth
): string => {
  const url = new URL(baseUrl);

  url.searchParams.append('impolicy', 'content-view-policy');
  url.searchParams.append(
    'imwidth',
    String(
      POSSIBLE_IMAGE_MANAGER_WIDTHS.find(possibleWidth => possibleWidth >= screenWidth) || 1024
    )
  );

  return url.toString();
};
