/* eslint-disable @typescript-eslint/explicit-function-return-type */
/** @internal */
class EventEmitter {
    constructor() {
        this.listeners = new Map();
    }
    addListener(eventName, listener) {
        const eventListeners = this.listeners.get(eventName) || [];
        if (!eventListeners.includes(listener)) {
            this.listeners.set(eventName, [...eventListeners, listener]);
        }
        return () => this.removeListener(eventName, listener);
    }
    removeListener(eventName, listener) {
        const eventListeners = this.listeners.get(eventName);
        if (eventListeners) {
            this.listeners.set(eventName, eventListeners.filter((cb) => cb !== listener));
        }
    }
    emit(eventName, ...args) {
        var _a;
        (_a = this.listeners.get(eventName)) === null || _a === void 0 ? void 0 : _a.forEach((callback) => callback(...args));
    }
}
export { EventEmitter };
