import { SearchParams } from './searchParams';

export interface Facet {
  name: string;
  displayName?: string;
  elements: FacetElement[];
  selectedElements: FacetElement[];
  unit: string;
  type: FacetType;
}

export interface FFFacet extends Facet {
  detailedLinks: number;
  showPreviewImages: boolean;
  filterStyle: string;
  selectionType: string;
}

// TODO: cleanup, Brand and Default are never returned by FactFinder
export enum FacetType {
  'CategoryPath' = 'CATEGORY_PATH',
  'Brand' = 'BRAND',
  'Default' = 'DEFAULT',
  'Text' = 'TEXT',
  'Float' = 'FLOAT',
}

export enum FacetName {
  'CategoryPath' = 'CategoryPath',
  'Brand' = 'Brand',
  'Price' = 'Price',
  'Size' = 'SIZE',
  'ColorFamilies' = 'ColorFamilies',
  'Alloy' = 'ALLOY',
}

export interface FacetElement {
  text: string;
  displayName?: string;
  associatedFieldName: string;
  searchParams: SearchParams;
  selected: boolean | string;
  clusterLevel: number;
  totalHits?: number;
}
