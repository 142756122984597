import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsHSEEmployee } from '../../../auth/state/authSlice';
import { openManagedDialog } from '../../../notifications/state/notificationsSlice';
import styles from '../../pages/AdminCreatorsOverviewPage/AdminCreatorsOverview.module.scss';
import AdminAddCreatorForm from '../AddCreatorsForm/AdminAddCreatorForm/AdminAddCreatorForm';
import AgencyAddCreatorForm from '../AddCreatorsForm/AgencyAddCreatorForm/AgencyAddCreatorForm';

const AddCreatorDialogWithButton = () => {
  const dispatch = useDispatch();
  const isHseEmployee = useSelector(selectUserIsHSEEmployee);
  const dialogComponent = isHseEmployee ? AdminAddCreatorForm.name : AgencyAddCreatorForm.name;

  const openDialog = () =>
    dispatch(openManagedDialog({ title: 'Neuen Streamer erstellen', component: dialogComponent }));

  return (
    <>
      <Button
        onClick={openDialog}
        className={styles.button}
        type={EButtonType.PRIMARY}
        size={EButtonSize.MEDIUM}
        data-testid="create-streamer-btn"
      >
        <Typography fontWeight={700}>Streamer anlegen</Typography>
      </Button>
    </>
  );
};

export default AddCreatorDialogWithButton;
