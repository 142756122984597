import cx from 'classnames';
import { ReactElement } from 'react';
import styles from './PostMediaRendererMenuItem.module.scss';

interface PostMediaRendererMenuItemProps {
  Icon: ReactElement;
  onClickHandler: () => void;
  dataTestId: string;
  isActive: boolean;
}

export const PostMediaRendererMenuItem = (props: PostMediaRendererMenuItemProps) => {
  const clickHandler = () => {
    if (props.isActive) {
      props.onClickHandler();
    }
  };
  return (
    <div
      data-testid={props.dataTestId}
      className={cx(styles.menu_item, { [styles.disabled]: !props.isActive })}
      onClick={clickHandler}
    >
      {props.Icon}
    </div>
  );
};
