import { Radio, Text } from '@hse24/shared-components';
import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import Icons from '../../../../assets';
import SubmitButton from '../../../../components/Button/SubmitButton';
import Icon from '../../../../components/Icon/Icon';
import { StreamedBy } from '../../../model/shows';
import { updateStreamedBy } from '../../../state/showsSlice';
import styles from './StreamedByInfo.module.scss';

interface Props {
  showId: string;
  streamedBy: StreamedBy;
}

export interface Option {
  value: string;
  icon: React.ReactElement;
  primaryText: string;
  secondaryText: string;
}

const defaultOption: Option = {
  value: StreamedBy.CREATOR,
  icon: <Icon dataTestId="video-camera-icon" icon={Icons.starWithCircle} />,
  primaryText: 'Creator',
  secondaryText: 'Von Creator geplant und durchgeführt',
};

const options: Option[] = [
  {
    value: StreamedBy.HSE,
    icon: <Icon dataTestId="video-camera-icon" icon={Icons.videoCameraWithCircle} />,
    primaryText: 'HSE',
    secondaryText: 'Von HSE geplant und bei Umsetzung unterstützt',
  },
  defaultOption,
];

const StreamedByInfo = ({ showId, streamedBy }: Props) => {
  const dispatch = useDispatch();

  const [pageEditable, setPageEditable] = useState(false);
  const [selectedValue, setSelectedValue] = useState<StreamedBy>(streamedBy);

  const togglePageEditable = () => {
    setPageEditable(!pageEditable);
  };

  const handleRadioOptionClick = (value: string) => {
    setSelectedValue(value as StreamedBy);
  };

  const handleSubmitAfterEditing = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      updateStreamedBy({
        showId,
        streamedBy: selectedValue,
      })
    );
    togglePageEditable();
  };

  return (
    <div className={styles.streamed_by_container}>
      <form onSubmit={handleSubmitAfterEditing}>
        <div className={styles.form}>
          <div className={styles.radio_buttons}>
            {options.map(option => (
              <Radio
                key={option.value}
                selected={selectedValue === option.value}
                onSelect={() => handleRadioOptionClick(option.value)}
              >
                {option.primaryText}
              </Radio>
            ))}
          </div>
          {streamedBy !== selectedValue && (
            <SubmitButton type="submit" className={styles.submit_btn}>
              Speichern
            </SubmitButton>
          )}
        </div>
      </form>
      <Text.BodyMicro className={styles.hint}>
        {options.find(option => selectedValue === option.value)?.secondaryText}
      </Text.BodyMicro>
    </div>
  );
};

export default StreamedByInfo;
