function dispatchStorageEvent(key: string, newValue: string | null) {
  window.dispatchEvent(
    new StorageEvent('storage', {
      key,
      newValue,
    })
  );
}

export const sharedStorageService = {
  setItem: (key: string, value: string, notifyNativeApp = true): void => {
    window.localStorage.setItem(key, value);

    if (notifyNativeApp) {
      dispatchStorageEvent(key, value);
    }
  },
  getItem: (key: string): string | null => {
    return window.localStorage.getItem(key);
  },
  removeItem: (key: string, notifyNativeApp = true): void => {
    window.localStorage.removeItem(key);

    if (notifyNativeApp) {
      dispatchStorageEvent(key, null);
    }
  },
  clear: (): void => {
    window.localStorage.clear();
  },
};
