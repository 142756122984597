import { HelpOutline } from '@mui/icons-material';
import { Container, Grid, Link } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Config from '../../../config/config';
import routePaths from '../../../routes/routePaths';
import {
  isDesktopFooterHidden,
  minimalHorizontalFooterPath,
} from '../../../utils/routeConfiguration';
import styles from './DesktopFooter.module.scss';

interface DesktopFooterProps {
  isFooterHidden?: boolean;
}

export default function DesktopFooter(props: DesktopFooterProps) {
  const imprintLink = (
    <Link
      className={styles.footerLink}
      underline="hover"
      target={'_blank'}
      rel={'opener'}
      href={`${Config.env.streamersPortalUrl}${routePaths.imprint}`}
    >
      Impressum
    </Link>
  );

  const privacyPolicyLink = (
    <Link
      className={styles.footerLink}
      underline="hover"
      target={'_blank'}
      rel={'opener'}
      href={`${Config.env.streamersPortalUrl}${routePaths.privacyPolicy}`}
    >
      Datenschutz
    </Link>
  );

  const termsConditionsLink = (
    <Link
      className={styles.footerLink}
      underline="hover"
      target={'_blank'}
      rel={'opener'}
      href={`${Config.env.streamersPortalUrl}${routePaths.termsConditions}`}
    >
      Geschäftsbedingungen
    </Link>
  );

  const helpdeskLink = (
    <Link
      className={styles.footerLink}
      underline="hover"
      target="_blank"
      rel={'opener'}
      href="https://hellolive.zendesk.com/hc/de/categories/8703569770013-Fragen-und-Antworten"
    >
      <HelpOutline className={styles.footerIcon} />
      Hilfe
    </Link>
  );

  const horizontalMinimalFooter = minimalHorizontalFooterPath(useLocation().pathname);
  const shouldRenderDesktopFooter =
    props.isFooterHidden === false || !isDesktopFooterHidden(useLocation().pathname);
  let footerItemSize = 12;
  if (horizontalMinimalFooter) {
    footerItemSize = 6;
  }

  return (
    <>
      {shouldRenderDesktopFooter ? (
        <footer data-testid="desktop-footer">
          <Container className={styles.footer} maxWidth={'lg'}>
            <Grid container direction="row" spacing={4} justifyContent={'center'}>
              <Grid container item xs={footerItemSize} sm={2} justifyContent={'center'}>
                {imprintLink}
              </Grid>
              <Grid container item xs={footerItemSize} sm={2} justifyContent={'center'}>
                {privacyPolicyLink}
              </Grid>
              {!horizontalMinimalFooter && (
                <>
                  <Grid container item xs={footerItemSize} sm={2} justifyContent={'center'}>
                    {termsConditionsLink}
                  </Grid>
                  <Grid container item xs={footerItemSize} sm={2} justifyContent={'center'}>
                    {helpdeskLink}
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </footer>
      ) : (
        <></>
      )}
    </>
  );
}
