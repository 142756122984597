import { Location } from 'history';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Prompt, useHistory } from 'react-router-dom';
import { openConfirmationDialog } from '../../notifications/state/notificationsSlice';

interface RouteLeavingGuardProps {
  blockNavigation: boolean;
  navigationPermitted?: boolean;
  onAbort?: () => void;
}
export const RouteLeavingGuard = ({
  blockNavigation,
  navigationPermitted,
  onAbort,
}: RouteLeavingGuardProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lastLocation, updateLastLocation] = useState<Location | undefined>(undefined);
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (location: Location) => {
    updateLastLocation(location);
    dispatch(
      openConfirmationDialog({
        title: 'Möchtest du deine Eingabe wirklich verwerfen?',
        contentText: 'Deine Änderungen werden nicht gespeichert.',
        submit: 'Abbrechen ohne speichern',
        actionToTrigger: () => {
          closeModal(location)();
          onAbort?.();
        },
      })
    );
  };

  const closeModal = (location: Location) => {
    return () => {
      if (location) {
        updateConfirmedNavigation(true);
      }
    };
  };

  const handleBlockedNavigation = (nextLocation: Location) => {
    if (!confirmedNavigation && shouldBlockNavigation()) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  const shouldBlockNavigation = () => {
    return blockNavigation && !navigationPermitted;
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(`${lastLocation.pathname}${lastLocation.search}`);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  return <Prompt when={blockNavigation} message={handleBlockedNavigation} />;
};

export default RouteLeavingGuard;
