import {
  Box,
  Chip,
  ChipProps,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import _, { capitalize } from 'lodash';
import React from 'react';
import { Audience, Platform } from '../../common/types';
import t from '../../constants/translation';
import AudienceBadge from '../AudienceBadge/AudienceBadge';
import styles from './AudienceFilter.module.scss';

interface Props {
  onSelect: (audience: Audience[], platform?: Platform[]) => void;
  audienceFilter?: Audience[];
  useBadges?: boolean;
  shouldUseAllPlatforms?: boolean;
}

function AudienceFilter({ audienceFilter, onSelect, useBadges, shouldUseAllPlatforms }: Props) {
  const [audiences, setAudiences] = React.useState<Audience[]>(
    audienceFilter && audienceFilter.length > 0 ? [audienceFilter[0]] : []
  );
  const [sources, setSources] = React.useState<Platform[]>([]);
  const checkIfSelected = (value: Audience[]): ChipProps => {
    return {
      onClick: () => onSelect(value),
      ...(_.isEqual(audienceFilter, value)
        ? {
            className: styles.chip,
          }
        : {
            variant: 'outlined',
          }),
    };
  };

  const radioButtonOnChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const newAudiences = value == '' ? [] : [value as Audience];
    setAudiences(newAudiences);
    setSources([]);
    onSelect(newAudiences, []);
  };

  const selectOnChange = (event: SelectChangeEvent) => {
    const value = event.target.value;
    const newSources = value == 'ALL' ? [] : [value as Platform];
    setSources(newSources);
    onSelect(audiences, newSources);
  };
  const getInitialFilter = () => {
    return audienceFilter != undefined
      ? audienceFilter.length == 0
        ? ''
        : audienceFilter[0]
      : audiences.length == 0
      ? ''
      : audiences[0];
  };
  const platformSelectItems: Platform[] = [
    Platform.WEB,
    Platform.APP,
    ...(shouldUseAllPlatforms ? [Platform.IOS, Platform.ANDROID] : []),
  ];

  return useBadges === false ? (
    <Box display={'flex'} gap={1} alignItems="center">
      <RadioGroup value={getInitialFilter()} onChange={radioButtonOnChange}>
        <FormControlLabel control={<Radio />} label={t.common['All']} value={''} />
        <FormControlLabel control={<Radio />} label="HSE Classic" value={Audience.CLASSIC} />
        <FormControlLabel control={<Radio />} label={Audience.HELLO} value={Audience.HELLO} />
      </RadioGroup>
      <Select
        data-testid="select"
        value={sources.length == 0 ? 'ALL' : sources[0]}
        onChange={selectOnChange}
        input={<OutlinedInput />}
        inputProps={{ 'aria-label': 'Without label' }}
        size={'small'}
        sx={{
          visibility: audiences.includes(Audience.CLASSIC) ? 'visible' : 'hidden',
          minWidth: '128px',
          maxHeight: '32px',
        }}
      >
        <MenuItem value={'ALL'}>{t.common.Total}</MenuItem>
        {platformSelectItems.map(e => {
          return (
            <MenuItem key={e} value={e}>
              {e === Platform.IOS ? 'iOS' : capitalize(e)}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  ) : (
    <Box display={'flex'} gap={1}>
      <Box>
        <Chip {...checkIfSelected([])} label={t.common['All']} />
      </Box>
      <AudienceBadge
        chipProps={{ ...checkIfSelected([Audience.HELLO]) }}
        audience={[Audience.HELLO]}
      />
      <AudienceBadge
        chipProps={{ ...checkIfSelected([Audience.CLASSIC]) }}
        audience={[Audience.CLASSIC]}
      />
    </Box>
  );
}

export default AudienceFilter;
