import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import { Box, Button, IconButton, Slider, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import {
  clearChat,
  getChatMessagesLive,
  initializeChat,
  StreamChatMessage,
  streamChatSelectors,
} from '../../state/streamChatSlice';
import ChatList from '../ChatList/ChatList';
import ChatListItem from '../ChatListItem/ChatListItem';
import { ReactComponent as HandIcon } from './../../../assets/hand_icon.svg';
import styles from './StreamChat.module.scss';

const useStyles = makeStyles({
  root: {
    color: '#fff',
  },
});

const defaultStreamChat: StreamChatMessage = {
  message:
    'Das ist eine Testnachricht, damit du die Schriftgröße optimal einstellen kannst. Benutze für das Verändern der Textgröße den Regler rechts.',
  receivedTime: '',
  count: 1,
  username: 'Benutzername',
  type: 'MESSAGE',
};

const animationVariants = {
  enter: { x: -100, opacity: 0 },
  center: { zIndex: 1, x: 0, opacity: 1 },
  exit: { zIndex: 0, y: -50, opacity: 0 },
};

function StreamChat() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showHint, setShowHint] = useState(true);

  const history = useHistory();

  const { streamId } = useParams<{ streamId: string }>();

  const [fontSize, setFontSize] = useState(16);

  const streamChat = useSelector(streamChatSelectors.messages);
  const messages = streamChat.length ? streamChat : [defaultStreamChat];

  const joinerUsername = useSelector(streamChatSelectors.joinerUsername);
  const followerUsername = useSelector(streamChatSelectors.followerUsername);

  const isInApp = featureFlagValue(AvailableFlag.isInApp);

  const [isMessagesFetched, setIsMessagesFetched] = useState(false);
  const [atBottom, setAtBottom] = useState(true);
  const [numberOfUnseenMessages, setNumberOfUnseenMessages] = useState(0);
  const [oldMessagesCount, setOldMessagesCount] = useState(0);

  const chatListRef = useRef<HTMLDivElement | null>(null);
  const chatBottomRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (chatListRef.current) {
        if (chatListRef.current.scrollTop <= -100) {
          setAtBottom(false);
        } else {
          setAtBottom(true);
        }
      }
    };

    const chatListElement = chatListRef.current;
    chatListElement?.addEventListener('scroll', handleScroll);

    return () => {
      chatListElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (messages.length && atBottom) {
      scrollToBottom();
      setNumberOfUnseenMessages(0);
    }
    if (messages.length > oldMessagesCount && !atBottom) {
      setNumberOfUnseenMessages(
        numberOfUnseenMessages => numberOfUnseenMessages + messages.length - oldMessagesCount
      );
    }
    setOldMessagesCount(messages.length);
  }, [messages, atBottom]);

  useEffect(() => {
    if (streamId && !isMessagesFetched) {
      dispatch(clearChat());
      dispatch(getChatMessagesLive(streamId));
      dispatch(initializeChat(streamId));
      setIsMessagesFetched(true);
    }
  }, [dispatch, streamId, isMessagesFetched]);

  const handleHideHint = () => {
    setShowHint(false);
  };

  const scrollToBottom = () => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop =
        chatListRef.current.scrollHeight - chatListRef.current.clientHeight;
      setAtBottom(true);
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      <Box className={styles.appbar}>
        <Toolbar className={styles.toolbar}>
          <Box className={styles.header}>
            <Box>
              {isInApp && (
                <IconButton onClick={goBack}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
            </Box>
            <Box className={styles.title}>
              <Typography sx={{ textAlign: 'center' }} variant={'h6'} fontWeight={'bold'}>
                Stream Chat
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </Box>
      <Box className={styles.chat_box}>
        <AnimatePresence>
          {joinerUsername && (
            <Box className={styles.sticky_navbar}>
              <motion.div
                key={joinerUsername}
                initial="enter"
                animate="center"
                variants={animationVariants}
                transition={{ type: 'tween', duration: 0.5 }}
              >
                <ChatListItem
                  chatMessage={{
                    username: joinerUsername,
                    message: 'ist beigetreten',
                    receivedTime: '',
                    count: 1,
                  }}
                  fontSize={fontSize}
                />
              </motion.div>
            </Box>
          )}
          {followerUsername && (
            <Box
              className={styles.sticky_navbar}
              sx={{
                marginTop: `${fontSize * 3}px`,
              }}
            >
              <motion.div
                key={followerUsername}
                initial="enter"
                animate="center"
                variants={animationVariants}
                transition={{ type: 'tween', duration: 0.5 }}
              >
                <ChatListItem
                  chatMessage={{
                    username: followerUsername,
                    message: 'folgt dir jetzt',
                    receivedTime: '',
                    count: 1,
                  }}
                  fontSize={fontSize}
                  isFollow
                />
              </motion.div>
            </Box>
          )}
        </AnimatePresence>
        {!atBottom && (
          <Button
            sx={{
              position: 'absolute',
              bottom: '20px',
              left: '50%',
              transform: 'translateX(-50%)',
              borderRadius: '20px',
              backgroundColor: 'white',
              borderWidth: 0,
              ':hover': {
                backgroundColor: '#dce6eb',
                borderWidth: 0,
              },
            }}
            variant="outlined"
            onClick={scrollToBottom}
            endIcon={<ArrowDownwardRoundedIcon fontSize={'small'} />}
            startIcon={
              numberOfUnseenMessages ? (
                <Box className={styles.number_of_messages}>{numberOfUnseenMessages}</Box>
              ) : null
            }
          >
            Gehen Sie zu den neuesten Nachrichten
          </Button>
        )}
        <Box className={styles.chat} ref={chatListRef}>
          <ChatList messages={messages} fontSize={fontSize} />
          <Box ref={chatBottomRef} />
        </Box>
        <Box className={styles.chat_font_adapter} onClick={handleHideHint}>
          {showHint && (
            <Box className={styles.hint_text}>
              <Typography variant="body1">Ziehen um Schriftgröße zu ändern.</Typography>
              <Box>
                <HandIcon />
              </Box>
            </Box>
          )}
          <Box fontSize={'1.5rem'}>
            <ChatOutlinedIcon fontSize={'inherit'} />
          </Box>
          <Box fontSize={'1.5rem'}>
            <TextIncreaseIcon fontSize={'inherit'} />
          </Box>
          <Box>
            <Slider
              className={classes.root}
              orientation="vertical"
              valueLabelDisplay="auto"
              defaultValue={30}
              onChange={(_e: Event, newValue: number | number[]) => {
                setFontSize(newValue as number);
                if (showHint) {
                  handleHideHint();
                }
              }}
              max={50}
              min={16}
              value={fontSize}
              sx={{
                '& .MuiSlider-thumb': {
                  width: '16px',
                  height: '16px',
                  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                    boxShadow: 'none',
                  },
                },
              }}
            />
          </Box>
          <Box fontSize={'1rem'}>
            <TextDecreaseIcon fontSize={'inherit'} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default StreamChat;
