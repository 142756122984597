import { Currency } from '../../../payments/model/Payments';

export const germanMoneyFormat = (value: number, currency?: Currency | string) => {
  return value.toLocaleString('de-DE', {
    style: 'currency',
    currency: currency ?? Currency.EUR,
    minimumFractionDigits: 2,
  });
};

export const germanFormat = (value: number) => {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
  });
};

export const germanFormatWithoutDecimal = (value: number) => {
  return value.toLocaleString('de-DE', {
    minimumFractionDigits: 0,
  });
};

export const germanPercentFormat = (value: number) => {
  return (value / 100).toLocaleString('de-DE', {
    style: 'percent',
    minimumFractionDigits: 2,
  });
};
