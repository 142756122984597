import { Box } from '@mui/material';
import React from 'react';
import StreamChat from '../../components/StreamChat/StreamChat';
import styles from './StreamChatPage.module.scss';

function StreamChatPage() {
  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <StreamChat />
      </Box>
    </Box>
  );
}

export default StreamChatPage;
