import { Grid } from '@mui/material';
import classNames from 'classnames';
import styles from '../ResponsiveDetailsRow.module.scss';

interface ClickableDetailsRowProps {
  label: string;
  value: string;
  className?: string;
  onClick?: () => void;
  last?: boolean;
}

const ClickableDetailsRow = ({
  label,
  value,
  className,
  onClick,
  last,
}: ClickableDetailsRowProps) => {
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      className={`${styles.row} ${last ? '' : styles.rowSeparator}`}
    >
      <Grid container item xs={12} direction="column" gap={1}>
        <Grid item className={styles.label}>
          {label}
        </Grid>
        <Grid item className={classNames(styles.withLineBreaks, className)} onClick={onClick}>
          {value}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClickableDetailsRow;
