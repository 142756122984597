import { IPage } from '../model/page';
import { ProductTileInfo } from '../model/productTileInfo';

export const updatePages = (statePages: IPage[], requestedPage: number): IPage[] => {
  const currentPage = statePages.find(p => p.current);
  const newCurrentPage = statePages.find(p => p.count === requestedPage);
  if (newCurrentPage && currentPage) {
    currentPage.current = false;
    newCurrentPage.current = true;
  }
  return statePages;
};

export const addPages = (
  statePages: IPage[],
  isNewSearch: boolean,
  requestedPage: number,
  newProducts: ProductTileInfo[]
): IPage[] => {
  if (!isNewSearch) {
    const currentPage = statePages.find(p => p.current);
    const currentPageCount = currentPage?.count ?? null;

    if (!currentPage) {
      return [{ count: requestedPage, products: newProducts, current: true }];
    }

    if (!currentPageCount) {
      return [{ count: requestedPage, products: newProducts, current: true }];
    }

    if (requestedPage > currentPageCount) {
      currentPage.current = false;
      statePages.push({ count: requestedPage, products: newProducts, current: true });
      return statePages.sort((a, b) => a.count - b.count);
    }

    if (requestedPage < currentPageCount) {
      currentPage.current = false;
      statePages.push({ count: requestedPage, products: newProducts, current: true });
      return statePages.sort((a, b) => a.count - b.count);
    }
  }

  return [{ count: requestedPage, products: newProducts, current: true }];
};

export const isRequestRequired = (
  maxVisitedPage: number,
  minVisitedPage: number,
  requestedPage: number
) => !(minVisitedPage <= requestedPage && requestedPage <= maxVisitedPage);
