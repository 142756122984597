import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import ClientSideDataGrid from '../../../creators/components/DataGridTable/ClientSideDataGrid';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { InvoiceOverview } from '../../model/Payments';
import { navigateToInvoiceDetails } from '../../state/paymentsSlice';
import styles from './InvoicesTable.module.scss';

interface Props {
  invoices: InvoiceOverview[];
}

const InvoicesTable = ({ invoices }: Props) => {
  const dispatch = useDispatch();

  const onRowClick = (rowParams: GridRowParams) => {
    dispatch(navigateToInvoiceDetails(rowParams.row.invoiceId));
  };

  const columns: GridColDef[] = [
    { field: 'invoiceNumber', headerName: 'Rechnung Nr.', minWidth: 150 },
    {
      field: 'generationDate',
      headerName: 'Datum',
      minWidth: 150,
      valueGetter: params => new Date(params.row.generationDate),
      valueFormatter: ({ value }) => dayjs(value).format('DD.MM.YYYY'),
    },
    {
      field: 'periodStart',
      headerName: 'Monat',
      minWidth: 150,
      valueGetter: params => new Date(params.row.periodStart),
      valueFormatter: ({ value }) => dayjs(value).format('MMM YYYY'),
    },
    {
      field: 'gross',
      headerName: 'Betrag',
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => ({
        gross: params.row.gross,
        currency: params.row.currency,
      }),
      valueFormatter: cellValue => {
        return germanMoneyFormat(cellValue.value.gross, cellValue.value.currency);
      },
      sortComparator: (v1, v2) => v1.gross - v2.gross,
    },
    {
      field: 'icon',
      headerName: '',
      renderCell: () => (
        <div className={styles.nav_icon}>
          <ChevronRightIcon />
        </div>
      ),
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <div className={styles.payment_list}>
      <h1>Rechnungen</h1>
      <ClientSideDataGrid
        rows={invoices}
        columns={columns}
        loading={false}
        initialPageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        onRowClick={onRowClick}
        getRowId={row => row.invoiceId}
      />
    </div>
  );
};

export default InvoicesTable;
