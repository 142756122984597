export type ScriptDetails = {
  srcUrl: string;
  id: string | undefined;
  attributes: Record<string, string>;
};

export const userCentricsConsentScriptDetails: ScriptDetails = {
  srcUrl: 'https://web.cmp.usercentrics.eu/ui/loader.js',
  id: 'usercentrics-cmp',
  attributes: { 'data-settings-id': 'uVkVWS92E' },
};
