import { Box, Typography } from '@mui/material';
import React from 'react';
import t from '../../../constants/translation';
import StreamerOverallPerformance from '../StreamerOverallPerformance/StreamerOverallPerformance';
import StreamerPostsTable from '../StreamerPostsTable/StreamerPostsTable';

function CreatorPostsOverview() {
  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} mt={2}>
      <Typography variant="h3" fontWeight={'bold'}>
        {t.creators.post['Posts overall performance']}
      </Typography>
      <StreamerOverallPerformance />
      <StreamerPostsTable />
    </Box>
  );
}

export default CreatorPostsOverview;
