import { Button, Text } from '@hse24/shared-components';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { lazy } from 'react';
import { useDispatch } from 'react-redux';
import { CreatorImageType } from '../../../creators/model/creator';
import { updateCreatorShowImages } from '../../../creators/state/creatorsSlice';
import { useShowImages } from '../../../shared/useShowImages';
import { ShowCoverSelectionMetadata, ShowImagesData } from '../../model/shows';
import styles from './StreamingSettings.module.scss';

const showCoverSelectionMetadata: ShowCoverSelectionMetadata = { displayTitles: false };

const StreamingSettings = () => {
  const dispatch = useDispatch();
  const [streamerShowImages] = useShowImages();
  const initialValues: ShowImagesData = {
    preview: {
      imageType: CreatorImageType.PREVIEW,
      fileExtension: 'png',
      changed: false,
    },
    curtainAfter: {
      imageType: CreatorImageType.CURTAIN_AFTER,
      fileExtension: 'png',
      changed: false,
    },
    curtainBefore: {
      imageType: CreatorImageType.CURTAIN_BEFORE,
      fileExtension: 'png',
      changed: false,
    },
  };

  const onSubmit = (values: ShowImagesData) => {
    dispatch(updateCreatorShowImages(values));
  };

  const getAccordionSummary = (accordionTitle: string) => {
    const elementId = `${accordionTitle.replace(/\s/g, '-')}-accordion`;
    return (
      <AccordionSummary
        expandIcon={<ExpandMoreRoundedIcon />}
        aria-controls={elementId}
        id={elementId}
      >
        <Typography style={{ fontWeight: 700 }}>{accordionTitle}</Typography>
      </AccordionSummary>
    );
  };

  const ShowCoverSelection = lazy(() => import('../ShowCoverSelection/ShowCoverSelection'));
  return (
    <div data-testId="streaming-settings">
      <Text.B1>Vorschaubild</Text.B1>
      <Typography className={styles.secondary_text} component="div" gutterBottom>
        Lade hier ein Vorschaubild für deinen Stream hoch. Mit diesem Bild kündigen wir deinen
        Stream in der HELLO App an.
      </Typography>
      <br />
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className={styles.streamer_images_form}>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            {getAccordionSummary('Vorschaubild')}
            <AccordionDetails className={styles.accordion_details}>
              <ShowCoverSelection
                fieldName={'preview'}
                imageType={CreatorImageType.PREVIEW}
                defaultImage={streamerShowImages.previewImageUrl}
                metadata={showCoverSelectionMetadata}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion TransitionProps={{ unmountOnExit: true }}>
            {getAccordionSummary('Vor live')}
            <AccordionDetails className={styles.accordion_details}>
              <ShowCoverSelection
                fieldName={'curtainBefore'}
                imageType={CreatorImageType.CURTAIN_BEFORE}
                defaultImage={streamerShowImages.curtainBeforeImageUrl}
                metadata={showCoverSelectionMetadata}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion TransitionProps={{ unmountOnExit: true }}>
            {getAccordionSummary('Nach live')}
            <AccordionDetails className={styles.accordion_details}>
              <ShowCoverSelection
                fieldName={'curtainAfter'}
                imageType={CreatorImageType.CURTAIN_AFTER}
                defaultImage={streamerShowImages.curtainAfterImageUrl}
                metadata={showCoverSelectionMetadata}
              />
            </AccordionDetails>
          </Accordion>

          <div className={styles.submit_button_wrapper}>
            <Button data-testid="streaming-settings-submit">
              <Typography fontWeight={700}>Änderungen speichern</Typography>
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default StreamingSettings;
