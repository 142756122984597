import React from 'react';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import theme from '../../../theme/theme.module.scss';
import AdminPostsOverview from '../../components/AdminPostsOverview/AdminPostsOverview';

function AdminPostPage() {
  return (
    <PageContainer
      backgroundColor={theme.whiteLilac}
      pageToRender={<AdminPostsOverview />}
      fullHeight
    />
  );
}

export default AdminPostPage;
