import { Dialog, Theme, useMediaQuery } from '@mui/material';
import { BaseSyntheticEvent, FC } from 'react';
import { useSelector } from 'react-redux';
import AdminAddCreatorForm from '../../../creators/components/AddCreatorsForm/AdminAddCreatorForm/AdminAddCreatorForm';
import AgencyAddCreatorForm from '../../../creators/components/AddCreatorsForm/AgencyAddCreatorForm/AgencyAddCreatorForm';
import DeleteAccountConfirmationModal from '../../../creators/components/DeleteAccount/DeleteAccountConfirmationModal/DeleteAccountConfirmationModal';
import DeleteAccountModal from '../../../creators/components/DeleteAccount/DeleteAccountModal/DeleteAccountModal';
import { ManagedDialog, selectManagedDialog } from '../../state/notificationsSlice';

const mapNameToComponent = (name: string): FC | undefined => {
  switch (name) {
    case AdminAddCreatorForm.name:
      return AdminAddCreatorForm;
    case AgencyAddCreatorForm.name:
      return AgencyAddCreatorForm;
    case DeleteAccountModal.name:
      return DeleteAccountModal;
    case DeleteAccountConfirmationModal.name:
      return DeleteAccountConfirmationModal;
  }
};

const DialogManager = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const managedDialog: ManagedDialog | undefined = useSelector(selectManagedDialog);

  if (!managedDialog) {
    return <></>;
  }

  const onClose = (e: BaseSyntheticEvent) => e.preventDefault();
  const Content: FC | undefined = mapNameToComponent(managedDialog.component);
  return (
    <>
      {Content && (
        <Dialog
          open={managedDialog.isOpen}
          fullScreen={isMobile}
          fullWidth={isMobile}
          onClose={onClose}
          maxWidth={'sm'}
        >
          <Content />
        </Dialog>
      )}
    </>
  );
};

export default DialogManager;
