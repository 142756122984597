import { Button, Col, EButtonSize, EButtonType, Row, TSelection } from '@hse24/shared-components';
import TuneIcon from '@mui/icons-material/Tune';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { TSortItem } from '../../model/sortItem';
import { selectProductListProps, toggleFilterModal } from '../../state/productsSlice';
import FilterModal from '../FilterModal/FilterModal';
import { ETriggerSize, ETriggerType } from '../SortingSelect';
import SortingSelect from '../SortingSelect/SortingSelect';
import styles from './SearchSettings.module.scss';

const SearchSettings = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sortResetUrl = `${history.location.pathname}${history.location.search.split('&sort=')[0]}`;

  const { loading, sortItems } = useSelector(selectProductListProps);

  const [extendedSortItems, setExtendedSortItems] = useState<TSortItem[]>(sortItems);

  const resetSortItem = (selected: boolean): TSortItem => ({
    description: 'Reset',
    name: 'Reset',
    order: 'default',
    href: sortResetUrl,
    selected,
    linkTarget: '',
  });

  useEffect(() => {
    const containsSelectedElement = Boolean(sortItems.find(elem => elem.selected));
    const resetSort = resetSortItem(!containsSelectedElement);
    setExtendedSortItems([...sortItems, resetSort]);
  }, [sortItems]);

  const handleSortSelect = (selection?: TSelection) => {
    if (!selection) {
      history.push(sortResetUrl);
    } else {
      const link = extendedSortItems[Number(selection.value)]?.href;
      link ? history.push(link) : history.push(sortResetUrl);
    }
  };

  const toggleModal = () => dispatch(toggleFilterModal());

  return (
    <>
      {!loading && extendedSortItems && extendedSortItems.length > 0 && (
        <>
          <Row className="mb-16">
            <Col s={6} ms={3} md={3} className="pr-8">
              <SortingSelect
                onChange={handleSortSelect}
                defaultLabel="Sortieren"
                options={extendedSortItems}
                className={`z-10 ${styles.sortingBtn}`}
                type={ETriggerType.BLANK}
                size={ETriggerSize.SMALL}
              />
            </Col>
            <Col s={6} ms={2} md={2} className="pl-8">
              <Button
                type={EButtonType.BLANK}
                size={EButtonSize.SMALL}
                onClick={toggleModal}
                className={styles.filterBtn}
              >
                <TuneIcon className={styles.iconFilter} />
                Filtern
              </Button>
            </Col>
          </Row>
          <FilterModal />
        </>
      )}
    </>
  );
};

export default SearchSettings;
