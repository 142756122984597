import { useSelector } from 'react-redux';
import { isMobileBreakPoint } from '../../common/mediaQueries';
import { selectCreateContentDialog } from '../../notifications/state/notificationsSlice';
import MobileCreateContent from './MobileCreateContent/MobileCreateContent';

function CreateContent() {
  const isMobile = isMobileBreakPoint();
  const isOpen = useSelector(selectCreateContentDialog);

  return <>{isMobile && isOpen && <MobileCreateContent />}</>;
}

export default CreateContent;
