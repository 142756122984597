import CircleIcon from '@mui/icons-material/Circle';
import LocalFireDepartmentTwoToneIcon from '@mui/icons-material/LocalFireDepartmentTwoTone';
import cx from 'classnames';
import styles from './ProgressSpinner.module.scss';
interface Props {
  progress: number;
  level: number | undefined;
  restToNextLevel: string;
  className?: string;
}

const ProgressSpinner = ({ progress, level, restToNextLevel, className }: Props) => {
  const progressColor = {
    background: ` 
     linear-gradient(0deg, rgba(255, 143, 31, ${
       progress / 100 + 0.3
     }) 50%,  rgba(255, 182, 54, 0.1) 50%) 
     `,
  };

  const halfCircleLeftEndProgressColor = {
    background: ` 
    linear-gradient(0deg, rgba(255, 143, 31, ${
      progress / 100 + 0.3
    }) 100%,  rgba(255, 182, 54, 0.1) 50%) `,
  };

  const halfCircleRightEndProgressColor = {
    background: 'rgba(255, 143, 31, 0.1)',
  };

  const flameDimensionStyle = {
    width: `${progress / 1.9}px`,
    height: `${progress / 1.9}px`,
  };

  return (
    <div className={cx(className, styles.container)}>
      <div
        className={styles.progress_spinner}
        style={{ ...progressColor, transform: `rotate(${progress * 1.8}deg)` }}
      >
        <div className={styles.progress_icon}>
          {progress > 2 && progress < 50 && <CircleIcon className={styles.ellipse_icon} />}
          {progress < 95 && progress >= 50 && (
            <LocalFireDepartmentTwoToneIcon
              className={styles.flame}
              style={{
                ...flameDimensionStyle,
                transform:
                  progress > 80
                    ? `translate(-${progress / 1.8}%, 0%) rotate(-${(progress * 90) / 50}deg)`
                    : `translate(-${progress / 1.5}%, 0%) rotate(-${(progress * 90) / 50}deg)`,
              }}
            />
          )}
        </div>
      </div>

      {progress <= 2 && <CircleIcon className={styles.start_ellipse} />}

      {progress >= 95 && (
        <LocalFireDepartmentTwoToneIcon
          className={styles.end_flame}
          style={{
            ...flameDimensionStyle,
          }}
        />
      )}

      <div className={styles.inside_circle}>
        <h1 data-testid={'percent-of-progress'}>{progress.toFixed(0)}%</h1>
      </div>

      <hr className={styles.divider} />

      <div className={styles.hide_half_circle}>
        <h2 data-testid={'restToNextLevel'}>{restToNextLevel}</h2>
        {level && <span>um Level {level} freizuschalten</span>}
        <div
          className={styles.right_half_circle_end}
          style={{ ...halfCircleRightEndProgressColor }}
        />
        <div
          className={styles.left_half_circle_end}
          style={{ ...halfCircleLeftEndProgressColor }}
        />
      </div>
    </div>
  );
};

export default ProgressSpinner;
