import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  GridColDef,
  GridFeatureModeConstant,
  GridRowIdGetter,
  GridRowParams,
  GridSortItem,
} from '@mui/x-data-grid';
import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridRowClassNameParams } from '@mui/x-data-grid/models/params';
import DataGridTable from './DataGridTable';

interface ClientSideDataGridProps {
  columns: GridColDef[];
  rows: GridRowsProp;
  height?: number;
  initialPageSize?: number;
  rowsPerPageOptions?: number[];
  disableColumnMenu?: boolean;
  onRowClick?: (rowParams: GridRowParams) => void;
  getRowId?: GridRowIdGetter;
  loading: boolean;
  initialSorting?: GridSortItem[];
  quickFilterPlaceholder?: string;
  autoHeight?: boolean;
  getRowClassName?: (params: GridRowClassNameParams) => string;
  sx?: SxProps<Theme>;
  columnVisibilityModel?: GridColumnVisibilityModel;
}

const ClientSideDataGrid = (props: ClientSideDataGridProps) => {
  return <DataGridTable {...props} mode={GridFeatureModeConstant.client} />;
};

export default ClientSideDataGrid;
