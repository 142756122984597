import { IPage } from '../../model/page';

interface ProductsCountProps {
  pages: IPage[];
  foundProducts: number;
}

const ProductsCount = ({ pages, foundProducts }: ProductsCountProps) => {
  return (
    <>
      {pages && pages.length > 0 && (
        <div className="flex justify-center mb-16">
          {pages
            .map(page => page.products.length)
            .reduce((countFirst, countSecond) => countFirst + countSecond)}{' '}
          von {foundProducts} Produkten geladen
        </div>
      )}
    </>
  );
};

export default ProductsCount;
