import { ChatRoom, type ChatToken } from 'amazon-ivs-chat-messaging';
import streamChatApi from '../api/streamChatApi';

const tokenProvider = async (roomArn: string, userId: string): Promise<ChatToken> => {
  const { token, sessionExpirationTime, tokenExpirationTime } = await streamChatApi.getChatToken(
    roomArn,
    userId
  );
  return {
    token,
    sessionExpirationTime,
    tokenExpirationTime,
  };
};

const create = (roomArn: string, userId: string) =>
  new ChatRoom({
    regionOrUrl: 'eu-west-1',
    tokenProvider: () => tokenProvider(roomArn, userId),
  });

const connect = (chatRoom: ChatRoom) => chatRoom.connect();
const disconnect = (chatRoom: ChatRoom) => chatRoom.disconnect();

const ivsChatUtils = {
  create,
  connect,
  disconnect,
};

export default ivsChatUtils;
