import { VideoPlayer } from '@hse24/video-player';
import '@hse24/video-player/style.css';
import VideoPlayerWrapper from '@hse24/video-player/VideoPlayerWrapper';
import { Box } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { saveAcademyVideoStatus } from '../../state/academySlice';
import styles from './AcademyVideoPlayer.module.scss';

const videoPlayerModule = import(
  /* webpackChunkName: "videoPlayer", webpackPreload: true */ '@hse24/video-player/videoPlayer'
);

interface Props {
  url: string;
  previewImageUrl: string;
  id: string;
}

function AcademyVideoPlayer({ url, previewImageUrl, id }: Props) {
  const dispatch = useDispatch();
  const hasStarted = useRef(false);
  const onReady = useCallback((player: VideoPlayer) => {
    player._getVideoJsPlayer().on('play', () => {
      if (!hasStarted.current) {
        hasStarted.current = true;
        saveTheVideoStatus(false);
      }
    });
    player._getVideoJsPlayer().on('ended', () => {
      saveTheVideoStatus(true);
    });
    player.setContent({
      sources: [
        {
          mimetype: 'video/mp4',
          url,
        },
      ],
      poster: previewImageUrl,
    });
  }, []);

  const saveTheVideoStatus = (finished: boolean) => {
    dispatch(saveAcademyVideoStatus({ id, finished }));
  };

  return (
    <Box className={styles.video_player}>
      <VideoPlayerWrapper module={videoPlayerModule} onReady={onReady} />
    </Box>
  );
}

export default AcademyVideoPlayer;
