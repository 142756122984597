import { useEffect, useState } from 'react';
import {
  AppBuildInfo as AppInfoType,
  getAppBuildInfo,
  productionEnvironmentValues,
} from '../../utils/appBuildInfo';
import styles from './AppBuildInfo.module.scss';

const AppBuildInfo = () => {
  const [appBuildInfo, setAppBuildInfo] = useState<AppInfoType | undefined>();

  useEffect(() => {
    setAppBuildInfo(getAppBuildInfo());
  }, []);

  if (!appBuildInfo) return null;

  return (
    <div className={styles.app_build_info_wrapper}>
      <span className={styles.app_build_info} data-testid={'app-build-info-span'}>
        v{appBuildInfo.version} ({appBuildInfo.buildNumber})
        {!productionEnvironmentValues.includes(appBuildInfo.environment) && (
          <>&nbsp;-&nbsp;{appBuildInfo.environment}</>
        )}
      </span>
    </div>
  );
};

export default AppBuildInfo;
