import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapQueryParamsToPageable } from '../../../common/queryParams';
import { FloorSale } from '../../../common/types';
import AudienceBadge from '../../../components/AudienceBadge/AudienceBadge';
import t from '../../../constants/translation';
import styles from '../../../payments/components/InvoicesTable/InvoicesTable.module.scss';
import IconCard from '../../../shows/component/IconCard/IconCard';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { calculateOnboardingStepsStatus } from '../../../utils/onboardingMapper';
import { displayName } from '../../model/creator';
import {
  getCreatorsOverview,
  navigateToCreatorDetailsPage,
  selectCreatorsOverview,
  setCreatorsOverview,
  TableFilter,
} from '../../state/creatorsSlice';
import ServerSideDataGrid, { Pageable } from '../DataGridTable/ServerSideDataGrid';
import ApproveBtnCell from './ApproveBtnCell/ApproveBtnCell';
import CreatorsTableStatusCell from './StatusCell/CreatorsTableStatusCell';

const mapSortFieldValue = (sortField: string): string => {
  switch (sortField) {
    case 'approve':
      return 'status';
    case 'status':
      return 'onboarding';
    default:
      return sortField;
  }
};

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 200 },
  {
    field: 'role',
    headerName: 'Rolle',
    width: 120,
    valueFormatter: ({ value }) => displayName(value),
  },
  {
    field: 'marketingConsent',
    headerName: 'Marktforschung',
    width: 140,
    align: 'center',
    renderCell: ({ value }) =>
      value?.hasAccepted ? (
        <CheckCircleOutlineIcon color={'success'} />
      ) : (
        <HighlightOffIcon color={'error'} />
      ),
    hide: true,
  },
  { field: 'email', headerName: 'Email', width: 320 },
  {
    field: 'audience',
    headerName: 'Audience',
    width: 145,
    renderCell: value => <AudienceBadge audience={value.row.audience} />,
  },
  { field: 'agencyName', headerName: t.agencies.Agency, width: 150 },
  {
    field: 'streamedSeconds',
    headerName: t.creators['Streaming time'],
    align: 'right',
    headerAlign: 'right',
    width: 190,
    renderHeader: () => (
      <IconCard
        text={t.creators['Streaming time']}
        textStyle={{ fontWeight: 'bold' }}
        icon={<VideocamOutlinedIcon />}
      />
    ),
    valueFormatter: params => formatTime(params.value),
  },
  {
    field: 'showsFloorSales',
    headerName: t.creators['Streams Revenue'],
    width: 160,
    align: 'right',
    headerAlign: 'right',
    renderHeader: () => (
      <IconCard
        text={t.creators['Streams Revenue']}
        textStyle={{ fontWeight: 'bold' }}
        icon={<CreditCardOutlinedIcon />}
      />
    ),
    valueFormatter: params => germanMoneyFormat(params.value.amount, params.value.currency),
    sortable: false,
    hide: true,
  },
  {
    field: 'postsFloorSales',
    headerName: t.creators.post['Posts Revenue'],
    width: 140,
    align: 'right',
    headerAlign: 'right',
    renderHeader: () => (
      <IconCard
        text={t.creators.post['Posts Revenue']}
        textStyle={{ fontWeight: 'bold' }}
        icon={<CreditCardOutlinedIcon />}
      />
    ),
    valueFormatter: params => germanMoneyFormat(params.value.amount, params.value.currency),
    sortable: false,
    hide: true,
  },
  {
    field: 'floorSales',
    headerName: t.common.Revenue,
    width: 140,
    align: 'right',
    headerAlign: 'right',
    renderHeader: () => (
      <IconCard
        text={t.common.Revenue}
        textStyle={{ fontWeight: 'bold' }}
        icon={<CreditCardOutlinedIcon />}
      />
    ),
    valueFormatter: params => germanMoneyFormat(params.value.amount, params.value.currency),
    sortComparator: (v1: FloorSale, v2: FloorSale) => v1.amount - v2.amount,
  },
  {
    field: 'approve',
    headerName: 'Freigabe',
    width: 150,
    renderCell: params => <ApproveBtnCell creatorId={params.row.id} status={params.row.status} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 110,
    align: 'center',
    headerAlign: 'center',
    valueGetter: params => params.row,
    renderCell: ({ value }) => <CreatorsTableStatusCell creatorOverview={value} />,
    valueFormatter: params => {
      const stepsStatus = calculateOnboardingStepsStatus(params.value);
      return `${stepsStatus.stepsDone}/${stepsStatus.stepsTotal}`;
    },
  },
  {
    field: 'icon',
    headerName: '',
    renderCell: () => (
      <div className={styles.nav_icon}>
        <ChevronRightIcon />
      </div>
    ),
    flex: 1,
    sortable: false,
    disableExport: true,
  },
  {
    field: 'approvedAt',
    headerName: t.creators['Approved at'],
    align: 'right',
    headerAlign: 'right',
    width: 110,
    valueFormatter: params => (params.value ? dayjs(params.value).format('DD.MM.YYYY') : ''),
    hide: true,
  },
];

const AdminCreatorsTable = () => {
  const dispatch = useDispatch();

  const creatorsOverview = useSelector(selectCreatorsOverview);

  useEffect(() => {
    if (creatorsOverview.dateFilter) {
      const { from, to } = creatorsOverview.queryParams;
      const { startDate, endDate } = creatorsOverview.dateFilter;
      if (from != startDate || to != endDate)
        dispatch(
          getCreatorsOverview({
            ...creatorsOverview.queryParams,
            from: startDate,
            to: endDate,
          })
        );
    }
  }, [creatorsOverview.dateFilter]);

  useEffect(() => {
    if (creatorsOverview.audienceFilter) {
      dispatch(
        getCreatorsOverview({
          ...creatorsOverview.queryParams,
          audience: creatorsOverview.audienceFilter,
        })
      );
    }
  }, [creatorsOverview.audienceFilter]);

  const updatePageableCallback = (pageable: Pageable) => {
    const updatedQueryParams = {
      from: creatorsOverview.queryParams.from,
      to: creatorsOverview.queryParams.to,
      pageSize: pageable.pageSize,
      pageNumber: pageable.page,
      sortField: mapSortFieldValue(pageable.sortField),
      sortOrder: pageable.sortOrder,
      searchTerm: pageable.searchTerm,
      audience: creatorsOverview.queryParams.audience,
    };
    if (!_.isEqual(updatedQueryParams, creatorsOverview.queryParams))
      dispatch(getCreatorsOverview(updatedQueryParams));
  };

  const onRowClick = (params: GridRowParams) =>
    dispatch(navigateToCreatorDetailsPage({ creatorId: params.row.id }));

  const onFilterStateChange = (state: TableFilter) => {
    const updatedObject = {
      pagination: state.pagination,
      sorting: state.sorting,
    };

    if (!_.isEqual(updatedObject, creatorsOverview.tableFilter)) {
      dispatch(
        setCreatorsOverview({
          tableFilter: {
            pagination: state.pagination,
            sorting: state.sorting,
          },
        })
      );
    }
  };

  return (
    <ServerSideDataGrid
      columns={columns}
      rows={creatorsOverview.creators}
      rowCount={creatorsOverview.hits}
      loading={creatorsOverview.loading}
      rowsPerPageOptions={[10, 50, 100, 1000]}
      height={630}
      quickFilterPlaceholder={'Suchen...'}
      updatePageableCallback={updatePageableCallback}
      initialPageable={mapQueryParamsToPageable(creatorsOverview.queryParams)}
      onRowClick={onRowClick}
      enableColumnSelector
      isExportEnabled
      initialState={creatorsOverview.tableFilter}
      onStateChange={onFilterStateChange}
    />
  );
};

export default AdminCreatorsTable;
