import { Typography } from '@mui/material';
import { format, isFuture, setHours, setMinutes } from 'date-fns';
import { useFormik } from 'formik';
import React, { ChangeEvent } from 'react';
import t from '../../../../../../../../../src/constants/translation';
import { isValidHour, isValidMinutes } from '../../../../../../../../utils/dates';
import { CustomTimerProps } from '../CustomTimer';
import styles from './CustomTimerForm.module.scss';

interface FormFields {
  hours: string;
  minutes: string;
}

interface CustomTimerFormProps extends CustomTimerProps {
  shouldUpdateValueOnChange: boolean;
  onMinutesChange?: (minutes: string) => void;
  onHourChange?: (hours: string) => void;
  setIsFormValid?: (isFormValid: boolean) => void;
}

const CustomTimerForm = (props: CustomTimerFormProps) => {
  const now = new Date();
  const setFormikValue = (field: string, value: string) => {
    setTimeout(() => formik.setFieldTouched(field, true), 1);
    formik.setFieldValue(field, value);
  };
  const concatWithZeroIfNecessary = (value: string): string => {
    const regex = /^\d$/;
    return regex.test(value) ? '0'.concat(value) : value;
  };

  const hoursHandler = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const hour = e?.currentTarget.value.slice(0, 2);
    setFormikValue('hours', hour);
    updateHours(hour);
  };

  function updateHours(hour: string) {
    props.onHourChange?.(hour);
    if (props.shouldUpdateValueOnChange && isValidHour(hour)) {
      props.updateShowDate(setHours(props.showDate, +hour));
    }
  }

  const minutesHandler = (e: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
    const minutes = e?.currentTarget.value.slice(0, 2);
    setFormikValue('minutes', minutes);
    updateMinutes(minutes);
  };
  function updateMinutes(minutes: string) {
    props.onMinutesChange?.(minutes);
    if (props.shouldUpdateValueOnChange && isValidMinutes(minutes)) {
      props.updateShowDate(setMinutes(props.showDate, +minutes));
    }
  }

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const inputName = event.target.name;
    setFormikValue(inputName, concatWithZeroIfNecessary(inputValue));
  };

  const formik = useFormik<FormFields>({
    initialValues: {
      hours: '',
      minutes: '',
    },
    onSubmit: values => {
      console.log(values);
    },
    validate: (values): FormFields => {
      if (
        formik.touched.hours &&
        formik.touched.minutes &&
        isValidHour(values.hours) &&
        isValidMinutes(values.minutes) &&
        isFuture(setMinutes(setHours(props.showDate, +values.hours), +values.minutes))
      ) {
        props.setHasSelectedValidTime(true);
        props.setIsFormValid?.(true);
        return { hours: '', minutes: '' };
      } else {
        props.setIsFormValid?.(false);
        props.setHasSelectedValidTime(false);
        return { hours: 'error', minutes: 'error' };
      }
    },
    validateOnChange: true,
  });

  const isErrorState =
    formik.values.minutes.length >= 2 &&
    formik.values.hours.length >= 2 &&
    formik.touched.hours &&
    formik.touched.minutes &&
    (formik.errors.hours || formik.errors.minutes);

  return (
    <>
      <div className={styles.custom_selector}>
        <div>
          <input
            data-testid="hours-input"
            className={styles.input}
            pattern="[0-9]{2}"
            type="text"
            id="hours"
            name="hours"
            value={formik.values.hours}
            onChange={hoursHandler}
            onBlur={e => {
              onBlurHandler(e);
              updateHours(concatWithZeroIfNecessary(formik.values.hours));
            }}
            placeholder={format(now, 'HH')}
            inputMode="numeric"
          />
          <Typography className={styles.label}> Stunde </Typography>
        </div>
        <div>
          <input
            data-testid="minutes-input"
            className={styles.input}
            type="text"
            id="minutes"
            name="minutes"
            pattern="[0-9]{2}"
            value={formik.values.minutes}
            onChange={minutesHandler}
            onBlur={e => {
              onBlurHandler(e);
              updateMinutes(concatWithZeroIfNecessary(formik.values.minutes));
            }}
            placeholder={format(now, 'mm')}
            inputMode="numeric"
          />
          <p className={styles.label}>Minuten</p>
        </div>
      </div>
      {isErrorState && (
        <Typography data-testid="error" className={styles.error}>
          {t.creators.show.invalidTime}
        </Typography>
      )}
    </>
  );
};

export default CustomTimerForm;
