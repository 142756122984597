import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectUserIsClassicStreamer } from '../../../auth/state/authSlice';
import AppBuildInfo from '../../../components/AppBuildInfo/AppBuildInfo';
import PageContainer from '../../../layout/PageContainer/PageContainer';
import routePaths from '../../../routes/routePaths';
import Loading from '../../../shows/component/Loading/Loading';
import { checkForShowEndedTracking } from '../../../shows/state/showsSlice';
import theme from '../../../theme/theme.module.scss';
import DashboardContainer from '../../components/DashboardContainer/DashboardContainer';
import {
  getCreatorPerformanceOverview,
  selectPerformanceOverviewLoading,
} from '../../state/creatorsSlice';

const CreatorDashboardPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(selectPerformanceOverviewLoading);
  const IsClassicStreamer = useSelector(selectUserIsClassicStreamer);

  if (IsClassicStreamer) {
    // for streaming app since they always open the app with /dashboard
    history.push(routePaths.creator.classicDashboard);
    return <Loading />;
  }

  useEffect(() => {
    dispatch(getCreatorPerformanceOverview());
    dispatch(checkForShowEndedTracking());
  }, [dispatch]);

  const dashboard = () => (
    <div>
      {!isLoading ? <DashboardContainer /> : <Loading />}
      <AppBuildInfo />
    </div>
  );

  return <PageContainer pageToRender={dashboard()} backgroundColor={theme.whiteLilac} />;
};

export default CreatorDashboardPage;
