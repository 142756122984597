import { Button, EButtonSize, EButtonType, IconEdit } from '@hse24/shared-components';
import { Box, CardMedia, Theme, Typography, useMediaQuery } from '@mui/material';
import cx from 'classnames';
import { addHours, isPast } from 'date-fns';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Icons from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import t from '../../../../constants/translation';
import ProductSlider from '../../../../products/components/ProductSlider/ProductSlider';
import routePaths from '../../../../routes/routePaths';
import ShowScheduledAtOrCountdown from '../../../../shows/component/ShowScheduledAtOrCountdown/ShowScheduledAtOrCountdown';
import UpcomingShowsTableMenu from '../../../../shows/component/TableMenu/UpcomingShowsTableMenu';
import { UpcomingShow } from '../../../../shows/model/shows';
import {
  notAllowedCreators,
  notAllowedToStreamAlert,
} from '../../../../shows/utils/notAllowedCreators';
import { sendMessageToNativeApp } from '../../../../storage/dataExchangerService';
import { buildClickMessageEvent, ClickEventElement } from '../../../../storage/nativeMessages';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import TimeLine from './TimeLine';
import styles from './UpcomingShowCard.module.scss';

interface UpcomingShowCardProps {
  upcomingShow: UpcomingShow;
  isLarge?: boolean;
}

const UpcomingShowCard = ({ upcomingShow, isLarge }: UpcomingShowCardProps) => {
  const history = useHistory();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const creator = useSelector(selectLoggedInCreator);
  const isExpiredShow = isPast(addHours(new Date(upcomingShow.scheduledAt), 1));
  const isDelayedStream = isPast(new Date(upcomingShow.scheduledAt)) && !isExpiredShow;
  const isLiveStream = upcomingShow.isLive;
  const showStartDate = dayjs(upcomingShow.scheduledAt).locale('de').format('dddd, HH:mm [Uhr]');

  const handleStartStreaming = () => {
    if (creator && notAllowedCreators.includes(creator.id)) {
      alert(notAllowedToStreamAlert);
    } else {
      sendMessageToNativeApp(
        buildClickMessageEvent(ClickEventElement.START_STREAMING, upcomingShow.id)
      );
    }
  };

  const handleEditStreaming = () => {
    history.push(routePaths.creator.updateShow.replace(':showId', upcomingShow.id));
  };

  return (
    <div className={styles.container}>
      <TimeLine />
      <Box className={styles.show_card}>
        <Typography variant="h5" className={styles.date_container}>
          {!isExpiredShow ? (
            <ShowScheduledAtOrCountdown startingAt={upcomingShow.scheduledAt} />
          ) : (
            <>{t.creators.show.showNotStarted}</>
          )}
        </Typography>
        <Box className={cx(styles.card, { [styles.large]: isLarge })}>
          <Box className={styles.image_container}>
            <CardMedia
              component="img"
              className={styles.poster}
              image={upcomingShow.previewImageUrl}
              data-testid="show-image"
            />
            <div className={cx(styles.image_overlay, { [styles.dark_layer]: isDelayedStream })} />
            <div className={styles.stream_state}>
              {isLiveStream && (
                <>
                  <Icon dataTestId="live-icon" icon={Icons.liveIcon} />
                  <Typography variant="body1">{t.creators.show.liveNow}</Typography>
                </>
              )}
              {isDelayedStream && !isLiveStream && (
                <Typography variant="body1">{t.creators.show.startStreamNow}</Typography>
              )}
            </div>
            <span className={styles.date_badge}>{showStartDate}</span>
            <ProductSlider
              className={styles.product_slider}
              productsPreview={upcomingShow.products}
            />
          </Box>
          <Box className={styles.content}>
            <span className={styles.date_badge_desktop}>{showStartDate}</span>
            <Typography variant="h6" className={styles.multiLineEllipsis}>
              {upcomingShow.title}
            </Typography>
            <Box className={styles.buttons}>
              {isExpiredShow ? (
                <Button
                  className={styles.btn}
                  type={EButtonType.BLANK}
                  icon={{
                    right: !isMobile || isLarge ? <IconEdit width="20px" height="20px" /> : null,
                  }}
                  onClick={handleEditStreaming}
                  size={EButtonSize.SMALL}
                >
                  {t.common.editStream}
                </Button>
              ) : (
                <Button
                  className={styles.btn}
                  icon={{
                    right:
                      !isMobile || isLarge ? (
                        <Icon dataTestId="video-camera-icon" icon={Icons.videoCamera} />
                      ) : null,
                  }}
                  onClick={handleStartStreaming}
                  size={EButtonSize.SMALL}
                >
                  {t.common.startStream}
                </Button>
              )}
              <UpcomingShowsTableMenu
                className={styles.card_menu}
                iconClassname={styles.three_dots_icon}
                show={upcomingShow}
                hideStartStream
              />
            </Box>
            <ProductSlider
              className={styles.product_slider_desktop}
              productsPreview={upcomingShow.products}
              isLarge={isLarge}
            />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default UpcomingShowCard;
