import { Audience, PageableResponse, Platform } from '../../common/types';

export interface Surveys {
  id: string;
  source: SurveysSource;
  contentType: SurveyContentType;
  contentId: string;
  score: number;
  feedback: string;
  createdAt: string;
  contentCreatorName: string;
  contentDescription: string;
  showStatus?: SurveyShowStatus;
  platform?: Platform;
  deviceType?: DeviceType;
}

export interface SurveysResponse extends PageableResponse {
  ratings: Surveys[];
}

export enum SurveyContentType {
  SHOW = 'SHOW',
  POST = 'POST',
}
export enum SurveyShowStatus {
  LIVE = 'LIVE',
  RECORDED = 'RECORDED',
}

export enum SurveysSource {
  CLASSIC = 'CLASSIC',
  HELLO = 'HELLO',
}

export enum DeviceType {
  iOS_phone = 'iOS_phone',
  Android_phone = 'Android_phone',
  iOS_tablet = 'iOS_tablet',
  Android_tablet = 'Android_tablet',
}

export enum SurveyRequestType {
  POST = 'POST',
  LIVE_SHOW = 'LIVE_SHOW',
  RECORDED_SHOW = 'RECORDED_SHOW',
  ALL = 'ALL',
}

// TODO: create a unified QueryParams for all endpoints and extend it if needed
export type SurveyQueryParams = {
  sortField: string;
  sortOrder: 'asc' | 'desc';
  searchTerm?: string;
  pageSize: number;
  page: number;
  dateFrom: string;
  dateTo: string;
  audience?: Audience[];
  itemType: SurveyRequestType;
  writtenFeedbackOnly: boolean;
  platform?: Platform;
};
