import { FormControlLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../../../products/components/Checkbox';
import { useIsMountedRef } from '../../../../shared/useIsMountedRef';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import { profilePersonalDataKeys } from '../ProfilePersonalData';
import styles from './NotLiableToVatCheckbox.module.scss';

const NotLiableToVatCheckbox = () => {
  const profileData = useSelector(selectLoggedInCreator);
  const isLiableToVat = profileData?.isLiableToVat != null ? profileData?.isLiableToVat : true;
  const [isChecked, setChecked] = useState(false);

  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isLiableToVat !== undefined) {
      setChecked(!isLiableToVat);
      setFieldValue(profilePersonalDataKeys.isLiableToVat, isLiableToVat, true);
    }
  }, [isLiableToVat]);

  async function updateCheckedValue(event: ChangeEvent<HTMLInputElement>) {
    const isCheckboxChecked = event.target.checked;
    setFieldValue(profilePersonalDataKeys.isLiableToVat, !isCheckboxChecked, true);
    setChecked(isCheckboxChecked);
    setTimeout(() => {
      if (isMountedRef.current) {
        // The 'shouldValidate' flag is broken currently (see https://github.com/formium/formik/issues/2059)
        // Workaround: trigger the validation manually by simulating the 'field touched' event afterwards.
        setFieldTouched(profilePersonalDataKeys.vatId, true);
      }
    });
  }

  return (
    <FormControlLabel
      control={
        <Checkbox checked={isChecked} onChange={updateCheckedValue} classNames={styles.checkbox} />
      }
      label="Ich bin Kleinunternehmer"
      className={styles.container}
    />
  );
};

export default NotLiableToVatCheckbox;
