import { IconTrash, Text } from '@hse24/shared-components';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import PriceInfo from '../../../../components/PriceInfo/PriceInfo';
import { ProductTileInfo } from '../../../model/productTileInfo';
import ImagePreview from '../../ImagePreview/ImagePreview';
import { ProductColorPicker } from '../ProductColorPicker/ProductColorPicker';
import t from './../../../../constants/translation';
import styles from './ProductSelectionItem.module.scss';

interface Props {
  direction: 'row' | 'column';
  onRemoveHandler?: () => void;
  product: ProductTileInfo;
  isColorPickerShown?: boolean;
  setSelectedProduct?: (product: ProductTileInfo) => void;
}

function ProductSelectionItem({
  product,
  onRemoveHandler,
  direction,
  isColorPickerShown,
  setSelectedProduct,
}: Props) {
  const [imagePreview, setImagePreview] = useState<string | undefined>(product.imageUri);

  const isColumn = direction === 'column';
  const isDisabled = product.outOfStock;
  const opacity = isDisabled ? 0.5 : 1;
  return (
    <Box
      className={styles.product_item}
      display="flex"
      flexDirection={direction}
      alignItems="center"
    >
      <Box position="relative">
        <Box className={styles.image} sx={{ width: 138, opacity }}>
          {onRemoveHandler && (
            <Box className={styles.remove}>
              <IconButton onClick={onRemoveHandler} data-testid="remove-button">
                <IconTrash width="24px" height="24px" />
              </IconButton>
            </Box>
          )}
          <ImagePreview alt={product.name} imageUrl={imagePreview} size={320} />
          {!!product.price.reference && (
            <Text.BodySmall className={styles.saving_percent}>
              -{product.price.reference.savingPercent}%
            </Text.BodySmall>
          )}
        </Box>
        {isDisabled && (
          <Chip
            label={
              <Typography fontSize={10} fontWeight={500} color="white">
                {t.common['Sold out']}
              </Typography>
            }
            className={styles.chip}
          />
        )}
      </Box>
      <Box
        sx={
          isColumn
            ? { width: 138, marginLeft: 0, opacity }
            : { marginLeft: 1, width: 'object-fit', opacity }
        }
      >
        <Typography className={styles.substring} variant="caption">
          {product.brandName}
        </Typography>
        <Typography className={styles.product_name} variant="body2">
          {product.name}
        </Typography>
        <Box>
          <PriceInfo price={product.price} isColumn={isColumn} hideSavingPercentage />
        </Box>
        <ProductColorPicker
          setSelectedProduct={setSelectedProduct}
          isShown={isColorPickerShown}
          setImagePreview={setImagePreview}
          product={product}
        />
      </Box>
    </Box>
  );
}

export default ProductSelectionItem;
