import { Typography } from '@mui/material';
import React from 'react';
import t from '../../../../constants/translation';
import AuthForm from '../../AuthForm/AuthForm';

function ResetPasswordEmail() {
  const handleFromSubmit = () => window.location.reload();

  return (
    <AuthForm
      header={
        <>
          <Typography variant={'h3'}>Check deine E-Mails</Typography>
          <br />
          <Typography variant={'body1'}>
            {t.creators['we have sent you a password reset mail']}
          </Typography>
          <Typography variant={'body1'}>{t.creators['Hint: check spam folder']}</Typography>
        </>
      }
      submitButton={{ title: 'Zurück zum Login' }}
      submitCallback={handleFromSubmit}
    />
  );
}

export default ResetPasswordEmail;
