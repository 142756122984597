import {
  Chat,
  FavoriteBorder,
  PeopleOutline,
  PermIdentity,
  Share,
  VisibilityOffOutlined,
} from '@mui/icons-material';
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded';
import { Button } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FloorSale } from '../../../common/types';
import { addPathQuery } from '../../../common/utils';
import AmaMessageDialog from '../../../components/AmaMessageDialog/AmaMessageDialog';
import SwitchWithTooltip from '../../../components/SwitchWithTooltip/SwitchWithTooltip';
import ServerSideDataGrid, {
  Pageable,
} from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import routePaths from '../../../routes/routePaths';
import IconCard from '../../../shows/component/IconCard/IconCard';
import PastShowsTableMenu from '../../../shows/component/PastShowsTableMenu/PastShowsTableMenu';
import { EUROPE_BERLIN_TIMEZONE, START_SHOW_FORMAT } from '../../../shows/constants';
import { PastShowData, ShowTotalAndLivePerformance, ShowType } from '../../../shows/model/shows';
import {
  getCreatorPastShows,
  selectCreatorPastShows,
  selectCreatorPastShowsPageable,
  selectShowsOnlyLivePerformanceFlag,
  setCreatorPastShowsPageable,
  setShowsOnlyLivePerformanceAction,
} from '../../../shows/state/showsSlice';
import theme from '../../../theme/theme.module.scss';
import formatTime from '../../../utils/formatters/formatTime/formatTime';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import styles from './PastStreamsOverview.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TableData extends ShowTotalAndLivePerformance {
  id: string;
  title: string;
  startedAt: string;
  durationInSeconds: number;
  type: ShowType;
}

const customizeData = (onlyLivePerformance: boolean, shows: PastShowData[]): TableData[] => {
  return shows.map(show => {
    return {
      ...show,
      ...(onlyLivePerformance ? show.livePerformance : show.totalPerformance),
    };
  });
};

const PastStreamsOverview = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const creatorPastShows: ReturnType<typeof selectCreatorPastShows> =
    useSelector(selectCreatorPastShows);
  const creatorPastShowsPageable: ReturnType<typeof selectCreatorPastShowsPageable> = useSelector(
    selectCreatorPastShowsPageable
  );
  const livePerformanceOnly = useSelector(selectShowsOnlyLivePerformanceFlag);

  useEffect(() => {
    dispatch(getCreatorPastShows());
  }, [creatorPastShowsPageable]);

  const updatePageableCallback = (pageable: Pageable) => {
    dispatch(setCreatorPastShowsPageable(pageable));
  };

  const onSwitchToggle = (checked: boolean) => {
    return dispatch(setShowsOnlyLivePerformanceAction(checked));
  };

  const amaNavigate = (showId: string) => {
    history.push(addPathQuery(routePaths.creator.analytics, { tab: 'Streams', showId }));
  };

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Stream',
      minWidth: 250,
      renderCell: ({ value, row }) =>
        row.isPublished ? (
          <>{value}</>
        ) : (
          <>
            <VisibilityOffOutlined sx={{ mr: theme.space12 }} />
            {value}
          </>
        ),
    },
    {
      field: 'startedAt',
      headerName: 'Datum',
      minWidth: 145,
      valueFormatter: ({ value }) =>
        dayjs(value).tz(EUROPE_BERLIN_TIMEZONE).format(START_SHOW_FORMAT),
    },
    {
      field: 'durationInSeconds',
      headerName: 'Dauer',
      width: 100,
      align: 'right',
      headerAlign: 'right',
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: 'total',
      headerName: 'Umsatz',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.sales.total,
      valueFormatter: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      sortComparator: (v1: FloorSale, v2: FloorSale) => v1.amount - v2.amount,
    },
    {
      field: 'perVisitor',
      headerName: 'Umsatz/Zuschauer',
      width: 185,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.sales.perVisitor,
      valueFormatter: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      sortComparator: (v1: FloorSale, v2: FloorSale) => v1.amount - v2.amount,
    },
    {
      field: 'perMinute',
      headerName: 'Umsatz/Minute',
      width: 170,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.sales.perMinute,
      valueFormatter: ({ value }) => germanMoneyFormat(value.amount, value.currency),
      sortComparator: (v1: FloorSale, v2: FloorSale) => v1.amount - v2.amount,
    },
    {
      field: 'views',
      width: 155,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.viewsOverview.views,
      renderHeader: () => (
        <IconCard
          text="Aufrufe"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PeopleOutline fontSize="small" />}
        />
      ),
    },
    {
      field: 'viewers',
      width: 155,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.viewersOverview.viewers,
      renderHeader: () => (
        <IconCard
          text="Zuschauer"
          textStyle={{ fontWeight: 'bold' }}
          icon={<PermIdentity fontSize="small" />}
        />
      ),
    },
    {
      field: 'watchTime',
      headerName: 'Watch Time',
      width: 170,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.timeWatched?.seconds,
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: 'watchTimePerView',
      headerName: 'Watch Time/Aufruf',
      width: 170,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.timeWatched?.perView,
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: 'watchTimePerViewer',
      headerName: 'Watch Time/Zuschauer',
      width: 170,
      align: 'right',
      headerAlign: 'right',
      valueGetter: params => params.row.timeWatched?.perViewer,
      valueFormatter: ({ value }) => formatTime(value),
    },
    {
      field: 'shares',
      width: 130,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Shares"
          textStyle={{ fontWeight: 'bold' }}
          icon={<Share fontSize="small" />}
        />
      ),
    },
    {
      field: 'amaResponsesCount',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      headerName: 'AMA',
      renderCell: ({ value, row }) => (
        <Button
          variant={'outlined'}
          onClick={() => amaNavigate(row.id)}
          disableElevation
          endIcon={<LaunchRoundedIcon />}
        >
          {value}
        </Button>
      ),
    },
    {
      field: 'likes',
      width: 120,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Likes"
          textStyle={{ fontWeight: 'bold' }}
          icon={<FavoriteBorder fontSize="small" />}
        />
      ),
    },
    {
      field: 'chatMessages',
      width: 125,
      align: 'right',
      headerAlign: 'right',
      renderHeader: () => (
        <IconCard
          text="Chats"
          textStyle={{ fontWeight: 'bold' }}
          icon={<Chat fontSize="small" />}
        />
      ),
    },
    {
      field: 'show',
      headerName: '',
      renderCell: params => (
        <div className={styles.last_column}>
          <PastShowsTableMenu show={params.row} />
        </div>
      ),
      flex: 1,
      sortable: false,
    },
  ];

  return (
    <>
      <AmaMessageDialog />
      <div className={styles.table_wrapper}>
        <h1>Vergangene Streams</h1>
        <div className={styles.toggle}>
          <SwitchWithTooltip
            checked={livePerformanceOnly}
            onChange={event => onSwitchToggle(event.target.checked)}
            labelText="nur Live-Performance"
            tooltipText="Performance-Daten berücksichtigen nur Interaktionen während des Live-Streams."
          />
        </div>
        <ServerSideDataGrid
          columns={columns}
          rows={customizeData(livePerformanceOnly, creatorPastShows.shows)}
          rowCount={creatorPastShows.hits}
          loading={creatorPastShows.loading}
          updatePageableCallback={updatePageableCallback}
          rowsPerPageOptions={[5, 10, 50, 100]}
          initialPageable={creatorPastShows.pageable}
          getRowClassName={params => `published-show-row-theme--${params.row.isPublished}`}
          sx={{
            '& .published-show-row-theme--false': {
              color: theme.grayDark,
            },
          }}
        />
      </div>
    </>
  );
};
export default PastStreamsOverview;
