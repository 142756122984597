import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useInterval = (callBack: (...params: unknown[]) => unknown, delay: number) => {
  const persistedCallback = useRef<(...params: unknown[]) => unknown>();

  useEffect(() => {
    persistedCallback.current = callBack;
  }, [callBack]);

  useEffect(() => {
    if (delay) {
      const intervalId = setInterval(
        () => persistedCallback.current && persistedCallback.current(),
        delay
      );
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};
