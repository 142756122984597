import { Tooltip } from '@mui/material';
import Fade from '@mui/material/Fade';
import { MouseEventHandler } from 'react';

interface CopyToClipboardProps {
  isCopied: boolean;
  children: JSX.Element;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const CopyToClipboard = ({
  isCopied,
  children,
  placement = 'left-start',
  onClick,
}: CopyToClipboardProps) => {
  return (
    <Tooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      title="Kopiert"
      arrow
      placement={placement}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={isCopied}
      onClick={onClick}
    >
      {children}
    </Tooltip>
  );
};

export default CopyToClipboard;
