import { EButtonSize, EButtonType } from '@hse24/shared-components';
import cx from 'classnames';
import { CSSProperties, FC, MouseEvent, ReactNode } from 'react';
import styles from './AnchorButton.module.scss';

type TButtonProps = {
  // textual content of the button
  children?: ReactNode;

  // click handler which is invoked when clicking the button
  onClick?: (ev: MouseEvent<HTMLElement>) => void;

  // disables the button from a functional and visual point of view
  disabled?: boolean;

  // icons which are placed left or right in a save zone
  icon?: {
    left?: ReactNode | null | string;
    right?: ReactNode | null | string;
  };

  // the overall size of the button
  size?: EButtonSize;

  // the overall visual representation of the button. mostly colors
  type?: EButtonType;

  // determines if the buttons content is responsible for determining the width of the button
  inline?: boolean;

  // custom class which is applied to the button
  className?: string;

  // custom styles which are applied to the button
  style?: CSSProperties;

  // optional url. If set renders an anchor tag. Prevent default if url and onClick props are provided.
  url?: string;
};

const AnchorButton: FC<TButtonProps> = props => {
  const {
    icon: { left: iconLeft = null, right: iconRight = null } = {},
    children = null,
    onClick = () => null,
    disabled = false,
    size = EButtonSize.MEDIUM,
    type = EButtonType.PRIMARY,
    inline = false,
    className = '',
    style = {},
    url,
  } = props;

  const stylesButton = cx(styles.button, styles[size], className, {
    [styles[type]]: type !== EButtonType.NONE,
    [styles.inline]: inline,
    [styles.denseLeft]: !iconLeft,
    [styles.denseRight]: !iconRight,
    [styles.textLeft]: !iconLeft && iconRight,
  });
  const stylesAside = cx(styles.element, styles.aside);
  const stylesCenter = cx(styles.element, styles.center);

  const sharedProps = { style, className: stylesButton, 'aria-disabled': disabled };
  const content = (
    <>
      {iconLeft && <span className={stylesAside}>{iconLeft}</span>}
      <span className={stylesCenter}>{children}</span>
      {iconRight && <span className={stylesAside}>{iconRight}</span>}
    </>
  );

  return (
    <>
      {url ? (
        <a
          href={url}
          onClick={wrapOnClickForAnchor(onClick, disabled)}
          {...sharedProps}
          data-testid="link-element"
        >
          {content}
        </a>
      ) : (
        <button {...sharedProps} disabled={disabled} onClick={onClick} data-testid="button-element">
          {content}
        </button>
      )}
    </>
  );
};

function wrapOnClickForAnchor(onClick: (ev: MouseEvent<HTMLElement>) => void, disabled: boolean) {
  return function (ev: MouseEvent<HTMLElement>) {
    ev.preventDefault();
    if (!disabled) {
      onClick(ev);
    }
  };
}

export default AnchorButton;
