import { Box } from '@mui/material';
import { getWeekDetails } from '../../../../../../utils/dates';
import DayOption from './DayOption/DayOption';
import styles from './DaySelector.module.scss';
interface DaySelectorProps {
  showDate: Date;
  updateShowDate: (date: Date) => void;
}

const DaySelector = ({ showDate, updateShowDate }: DaySelectorProps) => {
  const daysDetails = getWeekDetails(showDate);

  return (
    <Box data-testid="day-selector" className={styles.container}>
      {daysDetails.map(dayDetail => {
        return (
          <DayOption
            key={dayDetail.dayName}
            showDate={showDate}
            dayDetails={dayDetail}
            updateShowDate={updateShowDate}
          />
        );
      })}
    </Box>
  );
};

export default DaySelector;
