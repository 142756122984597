import { EAllowedSort, TSortItem } from '../model/sortItem';
import { buildLinkTarget } from './targetLinkUtils';

export const mapSortItems = (ffSortItems: TSortItem[] | undefined): TSortItem[] => {
  if (!ffSortItems) {
    return [];
  }

  return ffSortItems.filter(removeNotAllowedSortItems).map(ffSortItem => ({
    description: ffSortItem.description,
    name: ffSortItem.name,
    order: ffSortItem.order,
    href: ffSortItem.searchParams ? buildLinkTarget(ffSortItem.searchParams) : '',
    selected: ffSortItem.selected,
    linkTarget: ffSortItem.searchParams ? buildLinkTarget(ffSortItem.searchParams) : '',
  }));
};

const removeNotAllowedSortItems = (ffSortItem: TSortItem) =>
  Object.keys(EAllowedSort).some(item => {
    return (
      EAllowedSort[item].key === ffSortItem.name && EAllowedSort[item].order === ffSortItem.order
    );
  });

const SORTING_PRICE_ASC_LABEL = 'Preis aufsteigend';
const SORTING_PRICE_DESC_LABEL = 'Preis absteigend';
const SORTING_NEW_IN_SHOP_DESC_LABEL = 'Neuheiten';
const SORTING_RESET_LABEL = 'Empfohlen';
const SORTING_DEFAULT_LABEL = '_';

export const mapSortingLabel = (item: TSortItem) => {
  const { order, name } = item;
  if (name === 'Price' && order === 'asc') {
    return SORTING_PRICE_ASC_LABEL;
  }
  if (name === 'Price' && order === 'desc') {
    return SORTING_PRICE_DESC_LABEL;
  }
  if (name === 'NewInShop' && order === 'desc') {
    return SORTING_NEW_IN_SHOP_DESC_LABEL;
  }
  if (name === 'Reset') {
    return SORTING_RESET_LABEL;
  }
  return SORTING_DEFAULT_LABEL;
};
