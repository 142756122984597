import { Check } from '@mui/icons-material';
import { Button, Menu, MenuItem, MenuList } from '@mui/material';
import { BaseSyntheticEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreatorRole, CreatorStatus } from '../../../model/creator';
import { approveCreator } from '../../../state/creatorsSlice';
import styles from './ApproveBtnCell.module.scss';
export interface ApproveBtnProps {
  creatorId: string;
  status: CreatorStatus;
}

const ApproveBtnCell = (props: ApproveBtnProps) => {
  const [isApproved, setApproved] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();

  const options = [
    { value: CreatorRole.STREAMER, label: 'als Streamer' },
    { value: CreatorRole.INFLUENCER, label: 'als Influencer' },
  ];

  const triggerApproval = (role: CreatorRole, event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setApproved(true);
    dispatch(approveCreator({ creatorId: props.creatorId, role }));
  };

  const openMenu = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: BaseSyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const renderBtn = () =>
    isApproved ? (
      <Button
        variant="contained"
        color="primary"
        disabled
        data-testid="check-btn"
        className={styles.button}
      >
        <Check />
      </Button>
    ) : (
      <>
        <Button onClick={openMenu} variant="contained" className={styles.button}>
          Freigeben
        </Button>
        <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
          <MenuList>
            {options.map(option => (
              <MenuItem key={option.value} onClick={e => triggerApproval(option.value, e)}>
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </>
    );

  return props.status === CreatorStatus.PENDING ? renderBtn() : null;
};

export default ApproveBtnCell;
