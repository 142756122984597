import { LoadingSpinner } from '@hse24/shared-components';
import { ContentCopyOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Grid, Typography } from '@mui/material';
import CopyToClipboard from '../../CopyToClipboard/CopyToClipboard';
import useCopyToClipboard from '../../CopyToClipboard/useCopyToClipboard';
import { DetailsRowProps } from '../ResponsiveDetailsRow';
import styles from '../ResponsiveDetailsRow.module.scss';

const DesktopDetailsRow = (props: DetailsRowProps) => {
  const [isCopied, copyToClipboard] = useCopyToClipboard();
  const value = props.value;
  const showMarketingConsent = () =>
    props.label === 'E-Mail' && (
      <Box display={'flex'} gap={0.5} alignItems={'center'}>
        {props.marketingConsent?.hasAccepted ? (
          <>
            <CheckCircleOutlineIcon color={'success'} />
            <Typography variant="body2" color={'green'}>
              Marktforschung
            </Typography>
          </>
        ) : (
          <>
            <HighlightOffIcon color={'error'} />
            <Typography variant="body2" color={'error'}>
              keine Marktforschung
            </Typography>
          </>
        )}
      </Box>
    );
  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      className={`${styles.row} ${props.last ? '' : styles.rowSeparator}`}
    >
      <Grid container item xs={12} direction="column" gap={1}>
        <Grid item className={styles.label}>
          <Box
            sx={props.updateStyle}
            display={'flex'}
            flexWrap={'wrap'}
            gap={1}
            alignItems={'center'}
          >
            <Box>{props.label}</Box> {showMarketingConsent()}
          </Box>
        </Grid>
        <Grid item className={styles.withLineBreaks} display="flex" justifyContent="space-between">
          {props.component ? (
            props.component
          ) : (
            <div>
              {props.isLoading ? (
                <LoadingSpinner className={styles.loader} />
              ) : (
                <Box sx={props.updateStyle}>{value}</Box>
              )}
            </div>
          )}
          {!!props.withCopyIcon && value && (
            <div onClick={() => copyToClipboard(value)} className={styles.icon}>
              <CopyToClipboard isCopied={isCopied}>
                <ContentCopyOutlined fontSize="small" />
              </CopyToClipboard>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DesktopDetailsRow;
