import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, IconButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useRef, useState } from 'react';
import t from '../../../constants/translation';
import StreamerCurrentLevel from './StreamerCurrentLevel';
import styles from './StreamerLevel.module.scss';
import StreamerNextLevel from './StreamerNextLevel';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function StreamerLevel() {
  const [expanded, setExpanded] = useState(false);
  const bottom = useRef<HTMLDivElement | null>(null);
  const handleExpandClick = () => {
    setExpanded(!expanded);
    if (!expanded) {
      executeScroll();
    }
  };

  const executeScroll = () => {
    setTimeout(() => {
      bottom.current?.scrollIntoView({ behavior: 'smooth' });
    }, 400);
  };

  return (
    <Box>
      <Box>
        <StreamerCurrentLevel />
      </Box>
      <Box className={styles.collapse_container}>
        <Box
          data-testid={'level-collapse'}
          className={styles.collapse_header}
          onClick={handleExpandClick}
        >
          <Box>
            <Typography variant={'h2'}>{t.creators['Next level']}</Typography>
          </Box>
          <Box>
            <ExpandMore expand={expanded} aria-expanded={expanded}>
              <ExpandMoreIcon />
            </ExpandMore>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <StreamerNextLevel />
        </Collapse>
      </Box>
      <div ref={bottom}></div>
    </Box>
  );
}

export default StreamerLevel;
