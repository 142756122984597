import { addHours, isPast } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { LocalStorageKeys } from '../../constants/localStorageKeys';
import { sharedStorageService } from '../../storage/sharedStorageService';
import { UpcomingShow } from '../model/shows';

interface ExpiredStreamManagerResult {
  expiredStreamToShow?: UpcomingShow;
  expiredStreams: UpcomingShow[];
  handleDismissExpiredStream: (expiredStreamId: string) => void;
}

const useExpiredStreamsManager = (initialStreams: UpcomingShow[]): ExpiredStreamManagerResult => {
  const [dismissedExpiredStreams, setDismissedExpiredStreams] = useState<string[]>([]);
  const [visibleExpiredStreams, setVisibleExpiredStreams] = useState<UpcomingShow[]>([]);

  // Filter expired streams
  const expiredStreams = useMemo(
    () => initialStreams.filter(stream => isPast(addHours(new Date(stream.scheduledAt), 1))),
    [initialStreams]
  );

  useEffect(() => {
    // Load dismissed streams from local storage
    const storedDismissedExpiredStreams = JSON.parse(
      sharedStorageService.getItem(LocalStorageKeys.dismissedExpiredStreams) || '[]'
    );
    setDismissedExpiredStreams(storedDismissedExpiredStreams);
  }, []);

  useEffect(() => {
    // Filter out streams that have been dismissed
    const streams = expiredStreams.filter(
      expiredStream => !dismissedExpiredStreams.includes(expiredStream.id)
    );
    setVisibleExpiredStreams(streams);
  }, [expiredStreams, dismissedExpiredStreams]);

  const handleDismissExpiredStream = (expiredStreamId: string) => {
    // Add the dismissed streams to the state and local storage
    setDismissedExpiredStreams(prevDismissedExpiredStreams => [
      ...prevDismissedExpiredStreams,
      expiredStreamId,
    ]);
    sharedStorageService.setItem(
      LocalStorageKeys.dismissedExpiredStreams,
      JSON.stringify([...dismissedExpiredStreams, expiredStreamId])
    );
  };

  return {
    expiredStreamToShow: visibleExpiredStreams.length ? visibleExpiredStreams[0] : undefined,
    expiredStreams: visibleExpiredStreams,
    handleDismissExpiredStream,
  };
};

export default useExpiredStreamsManager;
