import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { CalendarPickerView, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deLocale from 'date-fns/locale/de';
import { useEffect, useState } from 'react';
import Icons from '../../assets';
import Icon from '../Icon/Icon';
import styles from './DatePicker.module.scss';

interface DatePickerProps {
  value: Date | null;
  onChangeDateCallback: (date: Date | null) => void;
  isOpenInitially?: boolean;
  monthYearPicker?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  isLarge?: boolean;
}

const DatePicker = ({
  value,
  onChangeDateCallback,
  isOpenInitially = false,
  monthYearPicker = false,
  minDate,
  maxDate,
  disabled = false,
  isLarge = true,
}: DatePickerProps) => {
  const [isOpen, setOpen] = useState(isOpenInitially);

  const onDateChange = (date: Date | null) => onChangeDateCallback(date);
  const calendarViews: CalendarPickerView[] = monthYearPicker
    ? ['year', 'month']
    : ['year', 'month', 'day'];
  const mask = monthYearPicker ? '__.____' : '__.__.____';
  const openTo = monthYearPicker ? 'month' : 'day';

  useEffect(() => setOpen(!disabled && isOpenInitially), [isOpenInitially, disabled]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
      <DesktopDatePicker
        value={value}
        mask={mask}
        label=""
        open={isOpen}
        openTo={openTo}
        minDate={minDate}
        maxDate={maxDate}
        onClose={() => setOpen(false)}
        onChange={onDateChange}
        renderInput={(props: TextFieldProps) => (
          <OutlinedInput
            {...(props as OutlinedInputProps)}
            size="small"
            className={styles.input}
            sx={{
              width: isLarge ? '180px' : '140px',
            }}
            onClick={() => !disabled && setOpen(true)}
            endAdornment={<Icon icon={Icons.calendarIcon} dataTestId="calendar-icon" />}
            data-testid="date-picker"
          />
        )}
        views={calendarViews}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
