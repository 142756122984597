import React from 'react';
import Icons from '../../../assets';
import { LightCreator } from '../../../creators/api/requestResponse';
import Icon from '../../Icon/Icon';
import styles from './CreatorsSearchOption.module.scss';

export interface CreatorSearchOptionProps {
  creator: LightCreator;
}

const CreatorsSearchOption = ({ creator }: CreatorSearchOptionProps) => {
  return (
    <div data-testid="creator-option" className={styles.creator_option}>
      <div data-testid="creator-image" className={styles.creator_image}>
        {creator.profileImageUrl ? (
          <img src={creator.profileImageUrl} alt={creator.name} />
        ) : (
          <Icon dataTestId="person-icon" height={40} width={40} icon={Icons.personIcon} />
        )}
      </div>
      <div data-testid="creator-name" className={styles.name}>
        {creator.name}
      </div>
    </div>
  );
};

export default CreatorsSearchOption;
