import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    borderBottom: '1px solid #dde1e7',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#ff3c28',
  },
}));

export default StyledTabs;
