import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { errorOccurred } from '../../notifications/state/notificationsSlice';
import academyApi from '../api/academyApi';
import {
  AcademyProgressResponse,
  AcademySectionResponse,
  AcademyVideo,
  AcademyVideoAction,
  AcademyVideoStatus,
} from '../model/academy';
import {
  fetchAcademyProgress,
  fetchAcademySections,
  fetchAcademyVideo,
  saveAcademyVideoStatus,
  setAcademyProgress,
  setAcademySections,
  setAcademySectionsLoading,
  setAcademyVideo,
  setAcademyVideoLoading,
  updateAcademyVideoStatus,
} from './academySlice';

export function* fetchAcademySectionsHandler() {
  try {
    yield put(setAcademySectionsLoading(true));
    const data: AcademySectionResponse = yield call(academyApi.fetchAcademySections);
    yield put(setAcademySections(data.sections));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setAcademySectionsLoading(false));
  }
}

export function* fetchAcademyVideoHandler(action: PayloadAction<string>) {
  try {
    yield put(setAcademyVideoLoading(true));
    const data: AcademyVideo = yield call(academyApi.fetchAcademyVideo, action.payload);
    yield put(setAcademyVideo(data));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setAcademyVideoLoading(false));
  }
}

export function* fetchAcademyProgressHandler() {
  try {
    const data: AcademyProgressResponse = yield call(academyApi.fetchAcademyProgress);
    yield put(setAcademyProgress(data));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* saveAcademyVideoStatusHandler(action: PayloadAction<AcademyVideoAction>) {
  const { id, finished } = action.payload;
  try {
    yield call(academyApi.saveAcademyVideoStatus, id, finished);
    if (finished) {
      yield put(updateAcademyVideoStatus(AcademyVideoStatus.FINISHED));
    }
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  }
}

export function* watcherAcademySagas() {
  yield takeLatest(fetchAcademySections.type, fetchAcademySectionsHandler);
  yield takeLatest(fetchAcademyVideo.type, fetchAcademyVideoHandler);
  yield takeLatest(fetchAcademyProgress.type, fetchAcademyProgressHandler);
  yield takeLatest(saveAcademyVideoStatus.type, saveAcademyVideoStatusHandler);
}
