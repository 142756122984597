import { FacetElement, FacetName } from '../model/facet';

const formatPriceText = (text: string) => {
  const textParts = text.split(' ');
  if (textParts.length < 2) return '*';

  const signedValue = parseFloat(textParts[1]).toFixed(2);
  if (textParts[0].includes('>=')) return `[${signedValue}, 100000000.0)`;
  if (textParts[0].includes('<')) return `[0.0, ${signedValue})`;

  if (textParts[1].includes('-')) {
    const startValue = parseFloat(textParts[0]).toFixed(2);
    return `[${startValue}, ${(!textParts[0].includes('.0')
      ? parseFloat(textParts[2])
      : parseFloat(textParts[2]) + 0.01
    ).toFixed(2)})`;
  }

  return '*';
};

const mapSelectedOptionsToFilterValues = (
  selectedOptions: Array<FacetElement>
): Array<{ value: string; type?: string; exclude?: boolean }> => {
  if (!selectedOptions.length) return [];

  const values = selectedOptions.map(so => {
    if (so.associatedFieldName === FacetName.Price) return formatPriceText(so.text);
    return so.text;
  });

  return Array.from(new Set(values)).map(v => ({
    value: v,
    type: 'or',
    exclude: false,
  }));
};

export default mapSelectedOptionsToFilterValues;
