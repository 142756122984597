import { LoadingSpinner } from '@hse24/shared-components';
import { Typography } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from '../../../../hooks/useQuery';
import routePaths from '../../../../routes/routePaths';
import { createUserAccountAction, selectAuthLoading } from '../../../state/authSlice';
import AuthForm, { AuthFormValues } from '../../AuthForm/AuthForm';

function NewAccountStep() {
  const query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();

  const activationTokenId = query.get('token');
  if (!activationTokenId) history.push(routePaths.landing);

  const loading = useSelector(selectAuthLoading);

  const onCreateAccount = (values?: AuthFormValues) => {
    const password = values?.password;
    if (activationTokenId && password)
      dispatch(
        createUserAccountAction({
          activationTokenId,
          password,
        })
      );
  };

  return loading ? (
    <div data-testid={'loading-spinner'}>
      <LoadingSpinner />
    </div>
  ) : (
    <AuthForm
      header={
        <>
          <Typography variant={'h3'}>Willkommen bei HELLO!</Typography>
          <Typography variant={'body1'}>
            Lass uns im ersten Schritt dein Passwort erstellen.
          </Typography>
        </>
      }
      submitButton={{ title: 'Passwort erstellen' }}
      password={''}
      passwordLabel={'Passwort erstellen'}
      submitCallback={onCreateAccount}
      passwordStrengthBar={true}
      passwordHelperText={'Mind. 8 Zeichen'}
    />
  );
}

export default NewAccountStep;
