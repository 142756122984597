import cx from 'classnames';
import { setHours, setMinutes } from 'date-fns';
import { TimeDetails } from '../../../../../../../../utils/dates';
import styles from './TimeOption.module.scss';

export interface TimeOptionProps {
  timeOption: TimeDetails;
  showDate: Date;
  updateShowDate: (date: Date) => void;
  setHasSelectedValidTime: (hasSelectedValidTime: boolean) => void;
}

const TimeOption = ({
  timeOption,
  updateShowDate,
  showDate,
  setHasSelectedValidTime,
}: TimeOptionProps) => {
  const isSelectedTimeOption =
    +timeOption.hours === showDate.getHours() && +timeOption.minutes === showDate.getMinutes();
  const handleTimeOptionClicked = () => {
    setHasSelectedValidTime(true);
    updateShowDate(setMinutes(setHours(showDate, +timeOption.hours), +timeOption.minutes));
  };
  return (
    <p
      onClick={handleTimeOptionClicked}
      className={cx(styles.time_option, { [styles.selected]: isSelectedTimeOption })}
    >
      {`${timeOption.hours}:${timeOption.minutes}`}
    </p>
  );
};

export default TimeOption;
