import DateFilter from '../DateFilter';
import { DateFilterMode, DateFilterTypes, DateRangeWithSelectedFilter } from '../dateFilterTypes';

export type FutureDateFilters = Extract<
  DateFilterTypes,
  | DateFilterTypes.TODAY
  | DateFilterTypes.TOMORROW
  | DateFilterTypes.MONTH
  | DateFilterTypes.CUSTOM
  | DateFilterTypes.NEXT_7_DAYS
  | DateFilterTypes.NEXT_14_DAYS
  | DateFilterTypes.NEXT_28_DAYS
>;

export interface DateFilterInFutureProps {
  onDateFiltersChange: (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => void;
  selectedFilter?: FutureDateFilters;
}

const DateFilterInFuture = ({
  onDateFiltersChange,
  selectedFilter = DateFilterTypes.MONTH,
}: DateFilterInFutureProps) => {
  return (
    <DateFilter
      selectedFilter={selectedFilter}
      onDateFiltersChange={onDateFiltersChange}
      dateFilterMode={DateFilterMode.FUTURE}
    />
  );
};

export default DateFilterInFuture;
