import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import t from '../../constants/translation';
import CommissionModal from '../CommissionModal/CommissionModal';
import styles from './StreamerCommissionInfoTooltip.module.scss';

const HtmlTooltip = withStyles({
  tooltip: {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '20px',
    maxWidth: '230px',
  },
  arrow: {
    color: 'white',
  },
})(Tooltip);

interface Props {
  children: JSX.Element;
  disabledTitle?: boolean;
  className?: string;
}

const StreamerCommissionInfoTooltip = ({ disabledTitle, children, className }: Props) => {
  const [isCommissionTableShown, setIsCommissionTableShown] = useState(false);
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const handleOpenTable = () => {
    if (disabledTitle) {
      setIsCommissionTableShown(true);
      setIsTooltipShown(false);
    }
  };

  const handleCloseTable = () => {
    setIsCommissionTableShown(false);
  };

  return (
    <>
      <CommissionModal isOpen={isCommissionTableShown} handleCloseTable={handleCloseTable} />
      <HtmlTooltip
        data-testid={'tool-tip'}
        arrow
        open={isTooltipShown}
        onOpen={() => setIsTooltipShown(true)}
        placement={'top'}
        className={className}
        onClick={handleOpenTable}
        title={
          !disabledTitle ? (
            <div className={styles.toolTip_wrapper}>
              <CancelOutlinedIcon
                className={styles.icon}
                onClick={() => setIsTooltipShown(false)}
              />
              <p>
                Sobald Streamer das Umsatzlevel 3 erreichen, werden Streamer-Umsätze für die{' '}
                {t.agencies['Agency commission']} relevant.
              </p>
              <u
                onClick={() => {
                  setIsCommissionTableShown(true);
                  setIsTooltipShown(false);
                }}
              >
                Provisionstabelle öffnen
              </u>
            </div>
          ) : (
            ''
          )
        }
      >
        <div className={styles.container}>{children}</div>
      </HtmlTooltip>
    </>
  );
};

export default StreamerCommissionInfoTooltip;
