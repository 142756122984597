import ShowScheduledAtOrCountdown from '../../ShowScheduledAtOrCountdown/ShowScheduledAtOrCountdown';

interface UpcomingShowLabelProps {
  scheduledAt: string;
}

const UpcomingShowLabel = ({ scheduledAt }: UpcomingShowLabelProps) => {
  return (
    <div data-testid="upcoming-show-label">
      <ShowScheduledAtOrCountdown startingAt={scheduledAt} startCountdownInMinutes={12 * 60} />
    </div>
  );
};

export default UpcomingShowLabel;
