import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { errorOccurred } from '../../notifications/state/notificationsSlice';
import { getSurveys } from '../api/surveysApi';
import { SurveyQueryParams, SurveysResponse } from '../types/types';
import { fetchSurveys, setSurveys, setSurveysLoading } from './surveysSlice';

export function* fetchSurveysHandler(action: PayloadAction<SurveyQueryParams>) {
  try {
    yield put(setSurveysLoading(true));
    const surveysResponse: SurveysResponse = yield call(getSurveys, action.payload);
    yield put(
      setSurveys({
        pages: surveysResponse.pages,
        hits: surveysResponse.hits,
        surveys: surveysResponse.ratings,
      })
    );
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setSurveysLoading(false));
  }
}

export function* watcherSurveysSagas() {
  yield takeLatest(fetchSurveys.type, fetchSurveysHandler);
}
