import MobilePageHeader from '../../../components/MobilePageHeader/MobilePageHeader';
import { ShowResponse } from '../../api/showsRequestResponse';
import { ScheduleShowData } from '../../model/shows';
import ClassicScheduleShowForm from './ClassicScheduleShowForm';

export interface ShowFormProps {
  isCreate: boolean;
  isOwnShow?: boolean;
  showToUpdate?: ShowResponse;
  submitCallback: (values: ScheduleShowData) => void;
}

const ScheduleShowForm = (props: ShowFormProps) => {
  return (
    <>
      <MobilePageHeader />
      <ClassicScheduleShowForm {...props} />
    </>
  );
};

export default ScheduleShowForm;
