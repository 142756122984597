import { Theme, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { MarketingConsent } from '../../creators/model/creator';
import DesktopDetailsRow from './DesktopDetailsRow/DesktopDetailsRow';
import MobileDetailsRow from './MobileDetailsRow/MobileDetailsRow';

export interface DetailsRowProps {
  label: string;
  value?: string;
  component?: ReactNode;
  last?: boolean;
  withCopyIcon?: boolean;
  marketingConsent?: MarketingConsent;
  isLoading?: boolean;
  updateStyle?: SxProps<Theme>;
}

const ResponsiveDetailsRow = (props: DetailsRowProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return isMobile ? <MobileDetailsRow {...props} /> : <DesktopDetailsRow {...props} />;
};

export default ResponsiveDetailsRow;
