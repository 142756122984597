import { format, isSameYear } from 'date-fns';
import { FloorSale } from '../common/types';
import { BarChartData } from '../components/Charts/BarChart/BarChart';
import t from '../constants/translation';
import { DailyGeneratedSales, DailyStreamedSeconds } from '../payments/model/Payments';
import formatTime from './formatters/formatTime/formatTime';
import { getCurrency } from './getCurrency';

function resolveDateFormat(date: Date): string {
  const isYearTheSame = isSameYear(date, new Date());
  return isYearTheSame ? 'dd.MM' : 'dd.MM.yyyy';
}

export const mapDailyStreamedSecondsToBarChartData = (
  dailyStreamedSeconds: DailyStreamedSeconds[]
): BarChartData[] =>
  dailyStreamedSeconds.map(({ day, streamedSeconds }) => {
    const date = new Date(day);
    const dateFormat = resolveDateFormat(date);
    return {
      x: format(date, dateFormat),
      y: streamedSeconds,
    };
  });

export const mapDailyGeneratedSalesToBarChartData = (
  dailyGeneratedSales: DailyGeneratedSales[]
): BarChartData[] =>
  dailyGeneratedSales.map(({ day, generatedSales }) => {
    const date = new Date(day);
    const dateFormat = resolveDateFormat(date);
    return {
      x: format(date, dateFormat),
      y: generatedSales.amount,
    };
  });

export const mapPaymentsGeneratedSalesToSummary = (paymentsGeneratedSales: FloorSale | undefined) =>
  `${t.creators['Total net stream revenue in the selected period']}: ${
    paymentsGeneratedSales !== undefined
      ? `${paymentsGeneratedSales.amount} ${getCurrency(paymentsGeneratedSales.currency)}`
      : 'N/A'
  }`;

export const mapStreamedSecondsToSummary = (streamedSeconds: number | undefined) =>
  `${t.creators['Total streaming time in the selected period']}: ${
    streamedSeconds !== undefined ? formatTime(streamedSeconds) : 'N/A'
  }`;
