import {
  ArrowIconLeft,
  ArrowIconRight,
  Button,
  EButtonSize,
  EButtonType,
  Input,
  Select,
} from '@hse24/shared-components';
import { TSelection } from '@hse24/shared-components/lib/components/Select/Select';
import { Theme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../../assets';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import { selectAdminUpcomingShows, setAdminUpcomingShowsQueryData } from '../../state/showsSlice';
import AdminPlannedShowTile from '../AdminPlannedShowTile/AdminPlannedShowTile';
import AdminPlannedShowTileSkeleton from '../AdminPlannedShowTile/AdminPlannedShowTileSkeleton/AdminPlannedShowTileSkeleton';
import styles from './AdminPlannedShowsList.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const sortingOptions: TSelection[] = [
  t.admins.upcomingShowsOverview.sortOptions['Next stream first'],
  t.admins.upcomingShowsOverview.sortOptions['Latest stream first'],
  t.admins.upcomingShowsOverview.sortOptions['Streamer name A-Z'],
  t.admins.upcomingShowsOverview.sortOptions['Streamer name Z-A'],
  t.admins.upcomingShowsOverview.sortOptions['Stream name A-Z'],
  t.admins.upcomingShowsOverview.sortOptions['Stream name Z-A'],
].map((value, index) => {
  return {
    value,
    label: value,
    index,
  };
});

const AdminPlannedShowsList = () => {
  const dispatch = useDispatch();
  const showsQuery = useSelector(selectAdminUpcomingShows);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [search, setSearch] = useState(showsQuery.queryData.searchTerm);
  const [debouncedSearch, setDebouncedSearch] = useState(search); // State for debounced search
  const [selectedSort, setSelectedSort] = useState<string>(sortingOptions[0].label);

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  // Debounce logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500); // Adjust debounce time (in ms) as needed

    // Cleanup timeout if user types again within the debounce time
    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  // Dispatch action when debounced search term changes
  useEffect(() => {
    dispatch(setAdminUpcomingShowsQueryData({ searchTerm: debouncedSearch }));
  }, [debouncedSearch, dispatch]);

  const onPageSizeChange = (selection: TSelection) => {
    dispatch(setAdminUpcomingShowsQueryData({ pageSize: +selection.value, page: 0 }));
  };

  const getPaginationDescription = () => {
    if (showsQuery.loading) {
      return t.common['Loading...'];
    }
    const total = showsQuery.hits;
    const from =
      showsQuery.queryData.page * showsQuery.queryData.pageSize + (showsQuery.hits == 0 ? 0 : 1);
    const to = Math.min((showsQuery.queryData.page + 1) * showsQuery.queryData.pageSize, total);

    return `${from}-${to} von ${total}`;
  };

  const hasNextPage = () => {
    return showsQuery.queryData.page + 1 < showsQuery.pages;
  };

  const nextPage = () => {
    dispatch(setAdminUpcomingShowsQueryData({ page: showsQuery.queryData.page + 1 }));
  };

  const previousPage = () => {
    dispatch(setAdminUpcomingShowsQueryData({ page: showsQuery.queryData.page - 1 }));
  };

  const hasPreviousPage = () => {
    return showsQuery.queryData.page > 0;
  };

  function getSortFieldAndOrderFromLabel(selection: TSelection): {
    sortField: string;
    sortOrder: 'asc' | 'desc';
  } {
    switch (selection.value) {
      case t.admins.upcomingShowsOverview.sortOptions['Latest stream first']:
        return { sortField: 'scheduledAt', sortOrder: 'desc' };

      case t.admins.upcomingShowsOverview.sortOptions['Streamer name A-Z']:
        return { sortField: 'creatorName', sortOrder: 'asc' };

      case t.admins.upcomingShowsOverview.sortOptions['Streamer name Z-A']:
        return { sortField: 'creatorName', sortOrder: 'desc' };

      case t.admins.upcomingShowsOverview.sortOptions['Stream name A-Z']:
        return { sortField: 'title', sortOrder: 'asc' };

      case t.admins.upcomingShowsOverview.sortOptions['Stream name Z-A']:
        return { sortField: 'title', sortOrder: 'desc' };

      default:
        return { sortField: 'scheduledAt', sortOrder: 'asc' };
    }
  }

  const onSortChange = (selection: TSelection) => {
    const { sortField, sortOrder } = getSortFieldAndOrderFromLabel(selection);
    setSelectedSort(selection.value);
    dispatch(
      setAdminUpcomingShowsQueryData({
        sortField,
        sortOrder,
      })
    );
  };

  return (
    <>
      <div className={styles.header}>
        <span className={styles.hits}>
          {showsQuery.loading
            ? t.common['Loading...']
            : `${showsQuery.hits} Stream${showsQuery.hits > 1 ? 's' : ''}`}
        </span>
        <div className={styles.sort_and_search_wrapper}>
          <div className={styles.search_wrapper}>
            <Input
              name={t.admins.upcomingShowsOverview['Search streams']}
              className={styles.search}
              value={search}
              onChange={onSearchChange}
            />
            <div className={styles.divider} />
            <Icon icon={Icons.searchIcon} className={styles.search_icon} dataTestId="seach-icon" />
          </div>

          <Select
            selectTriggerSize={EButtonSize.SMALL}
            label={isMobile ? '' : selectedSort}
            style={{ zIndex: 40 }}
            className={styles.sort}
            onChange={onSortChange}
            selectBodyClassName={styles.sort_option}
            options={sortingOptions}
            selectedSelector={option => {
              return selectedSort === option.label;
            }}
          />
        </div>
      </div>
      <div className={styles.shows_wrapper}>
        {showsQuery.loading
          ? [1, 2].map((_, i) => <AdminPlannedShowTileSkeleton key={i} />)
          : showsQuery.shows.map(show => <AdminPlannedShowTile key={show.id} show={show} />)}
      </div>
      <div className={styles.pagination}>
        <span>{t.common['Entries per page']}</span>
        <Select
          label={showsQuery.queryData.pageSize.toString()}
          className={styles.pagination_select}
          onChange={onPageSizeChange}
          options={[
            { value: '10', label: '10' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
          ]}
        ></Select>
        <Button
          type={EButtonType.ICON_ONLY}
          size={EButtonSize.TINY}
          className={styles.pagination_button}
          disabled={showsQuery.loading || !hasPreviousPage()}
          onClick={previousPage}
        >
          <ArrowIconLeft width={'24px'} height={'24px'} />
        </Button>

        <div className={styles.pagination_info} data-testid="pagination-description">
          {getPaginationDescription()}
        </div>

        <Button
          type={EButtonType.ICON_ONLY}
          size={EButtonSize.TINY}
          className={styles.pagination_button}
          disabled={showsQuery.loading || !hasNextPage()}
          onClick={nextPage}
        >
          <ArrowIconRight width={'24px'} height={'24px'} />
        </Button>
      </div>
    </>
  );
};

export default AdminPlannedShowsList;
