import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Audience } from '../common/types';
import {
  DEFAULT_CURTAIN_AFTER_IMAGE_URL,
  DEFAULT_CURTAIN_AFTER_IMAGE_URL_CLASSIC,
  DEFAULT_CURTAIN_BEFORE_IMAGE_URL,
  DEFAULT_CURTAIN_BEFORE_IMAGE_URL_CLASSIC,
} from '../constants/show';
import {
  selectLoggedInCreatorAudience,
  selectLoggedInCreatorShowImages,
} from '../creators/state/creatorsSlice';
import { ShowImages } from '../shows/model/shows';

export const useShowImages = (showImages?: ShowImages): ShowImages[] => {
  const creatorShowImages = useSelector(selectLoggedInCreatorShowImages);
  const creatorAudience = useSelector(selectLoggedInCreatorAudience);
  const isHelloAudience = creatorAudience === Audience.HELLO;

  const getDefaultImageUrl = (helloUrl: string, classicUrl: string) =>
    isHelloAudience ? helloUrl : classicUrl;

  const localShowImages = useMemo(
    () => ({
      curtainBeforeImageUrl:
        showImages?.curtainBeforeImageUrl ??
        creatorShowImages?.curtainBeforeImageUrl ??
        getDefaultImageUrl(
          DEFAULT_CURTAIN_BEFORE_IMAGE_URL,
          DEFAULT_CURTAIN_BEFORE_IMAGE_URL_CLASSIC
        ),
      curtainAfterImageUrl:
        showImages?.curtainAfterImageUrl ??
        creatorShowImages?.curtainAfterImageUrl ??
        getDefaultImageUrl(
          DEFAULT_CURTAIN_AFTER_IMAGE_URL,
          DEFAULT_CURTAIN_AFTER_IMAGE_URL_CLASSIC
        ),
      previewImageUrl: showImages?.previewImageUrl ?? creatorShowImages?.previewImageUrl,
    }),
    [showImages, creatorShowImages, isHelloAudience]
  );

  return [localShowImages];
};
