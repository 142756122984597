import React from 'react';
import { buildAssetUrl, fallbackImageUrl, staticMediaUrlDefault } from '../../utils/UrlUtils';

interface ImagePreviewProps {
  imageUrl?: string;
  size: number;
  alt: string;
  className?: string;
  height?: number;
  width?: number;
}

const ImagePreview = (props: ImagePreviewProps) => {
  return (
    <img
      data-testid="image-preview"
      className={props.className}
      src={
        props.imageUrl
          ? buildAssetUrl(staticMediaUrlDefault, props.imageUrl, props.size)
          : fallbackImageUrl
      }
      alt={props.alt}
      height={props.height}
      width={props.width}
    />
  );
};

export default ImagePreview;
