import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import Config from '../../config/config';
import { BankDetails } from './BankDetails';

const addDecamelizeConfig = (config: AxiosRequestConfig) => {
  return {
    ...config,
    data: decamelizeKeys(config.data),
  };
};
const addCamelizeConfig = (config: AxiosRequestConfig) => {
  return {
    ...config,
    data: camelizeKeys(config.data),
  };
};

const paymentServiceClient = (): AxiosInstance => {
  const client = axios.create({
    baseURL: Config.env.paymentServiceUrl,
    headers: { 'Content-Type': 'application/json' },
  });

  client.interceptors.request.use(
    config => addDecamelizeConfig(config),
    err => Promise.reject(err)
  );

  client.interceptors.response.use(
    config => addCamelizeConfig(config),
    err => Promise.reject(err)
  );

  return client;
};

/**
 * Validates the passed IBAN and returns a valid BIC or an empty string, if the IBAN is invalid.
 */
const validateIban = async (iban: string): Promise<string | undefined> => {
  try {
    const response: AxiosResponse<BankDetails> = await paymentServiceClient().post(
      `/api/iban/validation`,
      {
        iban: iban,
      }
    );
    return response.data.bankDetails.bic;
  } catch (error: unknown) {
    const axiosError: AxiosError = error as AxiosError;
    if (axiosError.isAxiosError && axiosError.response?.status == 400) {
      // HTTP 400 is returned, if the IBAN is invalid
      return undefined;
    }
    throw error;
  }
};

const paymentServiceApi = { validateIban, paymentServiceClient };
export default paymentServiceApi;
