import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  CreatorInvoices,
  InvoiceDetails,
  PaymentDetailsParams,
  PaymentPreview,
  PaymentsDetails,
  PaymentsMonthRange,
} from '../model/Payments';

export interface PaymentsState {
  paymentsDetails: PaymentsDetails | undefined;
  paymentPreview: PaymentPreview | undefined;
  creatorInvoices: CreatorInvoices | undefined;
  pdfData: string | undefined;
  invoiceDetails: InvoiceDetails | undefined;
}

export const paymentsInitialState: PaymentsState = {
  paymentsDetails: undefined,
  paymentPreview: undefined,
  creatorInvoices: undefined,
  pdfData: undefined,
  invoiceDetails: undefined,
};

export const getPaymentsDetails = createAction<PaymentsMonthRange>('payment/getPaymentDetails');
export const getCreatorPaymentsDetails = createAction<PaymentDetailsParams>(
  'payment/getCreatorPaymentDetails'
);
export const getCreatorInvoices = createAction('payment/getCreatorInvoices');

export const navigateToInvoiceDetails = createAction<string>('payment/navigateToInvoiceDetails');

export const getInvoiceDetails = createAction<string>('payment/getInvoiceDetails');

export const paymentsSlice = createSlice({
  name: 'payment',
  initialState: paymentsInitialState,
  reducers: {
    setPaymentsDetails: (state, action: PayloadAction<PaymentsDetails>) => {
      state.paymentsDetails = action.payload;
    },
    setPaymentPreview: (state, action: PayloadAction<PaymentPreview>) => {
      state.paymentPreview = action.payload;
    },
    setCreatorInvoices: (state, action: PayloadAction<CreatorInvoices>) => {
      state.creatorInvoices = action.payload;
    },
    setInvoiceDetails: (state, action: PayloadAction<InvoiceDetails>) => {
      state.invoiceDetails = action.payload;
    },
  },
});

export const { setPaymentsDetails, setPaymentPreview, setCreatorInvoices, setInvoiceDetails } =
  paymentsSlice.actions;

export const selectPaymentsDetails = (state: RootState) => state.payments.paymentsDetails;

export const selectPaymentPreview = (state: RootState) => state.payments.paymentPreview;

export const selectInvoiceDetailsCreator = (state: RootState) =>
  state.payments.invoiceDetails?.invoiceDetails?.creator;

export const selectPaymentsDailyGeneratedSales = (state: RootState) =>
  state.payments.paymentsDetails?.dailyGeneratedSales;

export const selectPaymentsGeneratedSales = (state: RootState) =>
  state.payments.paymentsDetails?.generatedSales.floorSales;

export const selectDailyStreamedSeconds = (state: RootState) =>
  state.payments.paymentsDetails?.dailyStreamedSeconds;

export const selectStreamedSeconds = (state: RootState) =>
  state.payments.paymentsDetails?.streamedSeconds;

export const selectTax = (state: RootState) => state.payments.paymentPreview?.tax;

export const selectTotals = (state: RootState) => state.payments.paymentPreview?.totals;

export const selectPaymentsFeeCurrency = (state: RootState) =>
  state.payments.paymentPreview?.currency;

export const selectPaymentPreviewFee = (state: RootState) => state.payments.paymentPreview?.fees;

export const selectPaymentPreviewPDFData = (state: RootState) => state.payments.pdfData;

export const selectCreatorInvoices = (state: RootState) => state.payments.creatorInvoices?.invoices;

export const selectInvoiceDetails = (state: RootState) => state.payments.invoiceDetails;

export default paymentsSlice.reducer;
