import { CreatorOverview, CreatorStatus, OnboardingStatus } from '../creators/model/creator';

interface OnboardingStepsStatus {
  stepsDone: number;
  stepsTotal: number;
}

export const mapCreatorOverviewToOnboardingStatus = (
  creatorOverview: CreatorOverview
): OnboardingStatus => {
  const onboarding = creatorOverview.onboarding;
  return {
    hasHSEApproved:
      creatorOverview.status === CreatorStatus.APPROVED ||
      creatorOverview.status === CreatorStatus.CREATED,
    hasAcceptedTermsAndConditions: onboarding.hasAcceptedTermsAndConditions,
    hasCompletedProfile: onboarding.hasCompletedProfile,
    isSamplesOrdered: onboarding.isSamplesOrdered,
    isFirstShowCreated: onboarding.isFirstShowCreated,
    isFirstShowStreamed: onboarding.isFirstShowStreamed,
  };
};

export const getNoOfOnboardingStepsDone = (creatorOverview: CreatorOverview): string => {
  const onboardingStatus = calculateOnboardingStepsStatus(creatorOverview);
  return `${onboardingStatus.stepsDone}/${onboardingStatus.stepsTotal}`;
};

export const mapProgressToSortOrder = (creatorOverview: CreatorOverview): number => {
  const onboardingStatus = calculateOnboardingStepsStatus(creatorOverview);
  if (creatorOverview.onboarding.isOnboardingCompleted) {
    // If the onboarding is completed, the items should be sorted to the end of the list
    return onboardingStatus.stepsTotal + 1;
  } else {
    return onboardingStatus.stepsDone;
  }
};

export const calculateOnboardingStepsStatus = (
  creatorOverview: CreatorOverview
): OnboardingStepsStatus => {
  const onboardingStatus = mapCreatorOverviewToOnboardingStatus(creatorOverview);
  const stepsDone = Object.values(onboardingStatus).filter(val => val).length;
  const stepsTotal = Object.keys(onboardingStatus).length;
  return { stepsTotal, stepsDone };
};
