import cx from 'classnames';
import { isEqual, startOfDay } from 'date-fns';
import { DayDetails, isBeforeToday } from '../../../../../../../utils/dates';
import styles from '../DaySelector.module.scss';

export interface DayOptionProps {
  showDate: Date;
  updateShowDate: (date: Date) => void;
  dayDetails: DayDetails;
}

const DayOption = ({ showDate, updateShowDate, dayDetails }: DayOptionProps) => {
  const isBeforeCurrentDay = isBeforeToday(dayDetails.date);
  const handleDaySelection = () => {
    if (!isBeforeCurrentDay) {
      const selectedDate = new Date(
        dayDetails.date.getFullYear(),
        dayDetails.date.getMonth(),
        dayDetails.date.getDate(),
        showDate.getHours(),
        showDate.getMinutes()
      );
      updateShowDate(selectedDate);
    }
  };

  return (
    <div
      onClick={handleDaySelection}
      className={cx(styles.day_container, {
        [styles.selected]: isEqual(startOfDay(showDate), startOfDay(dayDetails.date)),
        [styles.passed]: isBeforeCurrentDay,
      })}
      key={dayDetails.dayName}
    >
      <p>{dayDetails.dayName}</p>
      <p>{dayDetails.formattedDayNumber}</p>
    </div>
  );
};

export default DayOption;
