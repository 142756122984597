import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box } from '@mui/material';
import React from 'react';
import styles from '../StreamerPostsTable/StreamerPostsTable.module.scss';

interface Props {
  caption: string;
  value: string;
  isInVisible: boolean;
}

function PostImage({ caption, value, isInVisible }: Props) {
  return (
    <Box className={styles.post_image}>
      <img style={{ borderRadius: '8px' }} src={value} alt={caption} />
      {isInVisible && (
        <Box className={styles.invisible}>
          <VisibilityOffIcon fontSize={'small'} color={'inherit'} />
        </Box>
      )}
    </Box>
  );
}

export default PostImage;
