import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { default as classNames, default as cx } from 'classnames';
import { useEmblaCarousel } from 'embla-carousel/react';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import routePaths from '../../../routes/routePaths';
import { Category } from '../../model/category';
import { NavigationTypes } from '../../model/navigation';
import { fetchFactFinderResults } from '../../state/productsSlice';
import { generateNavigationItemID } from '../../utils/navigationUtils';
import { generateQueryForFactFinder } from '../../utils/queryUtils';
import styles from './CategoriesSlider.module.scss';
import { getLastActiveParentCategory } from './CategoriesSlider.service';

export const displayLabelLink = (
  category: Category | null,
  query: string | undefined,
  onClick: () => void
) => {
  if (category && category.selected) {
    if (category.clusterLevel !== 0) {
      return (
        <Link
          data-testid="categories-slider-label-link"
          className="flex leading-none outline-none focus:outline-none"
          to={category.linkTarget || ''}
        >
          <KeyboardBackspaceIcon width="24px" className="mr-16" />
          {category.name}
        </Link>
      );
    }
    return (
      <span
        data-testid="categories-slider-label-span"
        className="flex items-center"
        onClick={onClick}
      >
        <KeyboardBackspaceIcon width="24px" className="mr-16" />
        {category.name}
      </span>
    );
  }

  return (
    <>
      Deine Suche: <span className={styles.searchText}>&quot;{query}&quot;</span>
    </>
  );
};

export const getSlider = (categories: Category[]) => {
  if (!categories || categories.length === 0) {
    return null;
  }

  const [sliderRef, sliderApi] = useEmblaCarousel({
    align: 'start',
    dragFree: true,
    containScroll: 'trimSnaps',
    selectedClass: '',
    draggableClass: '',
    draggingClass: '',
  });

  useEffect(() => {
    sliderApi?.reInit();
    sliderApi?.scrollTo(0);
  }, [categories]);

  return (
    <div
      data-testid="categories-slider-container"
      className={`mb-24 ${styles.categoriesSlider} overflow-hidden`}
      ref={sliderRef}
    >
      <div className="flex">
        {[...categories.filter(c => c.selected), ...categories.filter(c => !c.selected)].map(
          category => (
            <div key={category.name} className="mr-8">
              <Link
                id={generateNavigationItemID([NavigationTypes.CATEGORY_TREE, category.name])}
                to={category.linkTarget || ''}
                className={classNames(
                  styles.category,
                  cx('inline-block border-2 px-16 py-8 leading-20 whitespace-no-wrap', {
                    'border-lightgrey': !category.selected,
                    [styles.categorySelected]: category.selected,
                  })
                )}
              >
                {category.name}
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
};

const CategoriesSlider: FC<{
  children?: JSX.Element;
  query?: string;
  categories: Category[];
}> = props => {
  if (!props.categories?.length) {
    return null;
  }
  const dispatch = useDispatch();
  const history = useHistory();

  const goToProductListWithFilters = () => {
    history.push(routePaths.creator.products);
  };

  const lastActiveCategory = getLastActiveParentCategory(props.categories);

  useEffect(() => {
    const cookingCategory = props.categories.find(
      (category: Category) => category.name === 'Gesund%20%26%20Vital'
    );

    if (cookingCategory?.linkTarget) {
      history.push(cookingCategory.linkTarget);
      dispatch(
        fetchFactFinderResults({
          query: generateQueryForFactFinder(history.location.search),
        })
      );
    }
  }, []);

  return (
    <>
      {lastActiveCategory || (props.query && props.query !== '*') ? (
        <div className="mb-16 text-24">
          {displayLabelLink(lastActiveCategory, props.query, goToProductListWithFilters)}
        </div>
      ) : null}
      {props.children}
      {getSlider(lastActiveCategory ? lastActiveCategory.categories : props.categories)}
    </>
  );
};

export default CategoriesSlider;
