import { Role } from '../../auth/role';

const hasNumber = (stringEl: string): boolean => /\d/.test(stringEl);

const isLandingPage = (url: string): boolean => url.length === 1 && url.includes('/');

export const getPageName = (url?: string) => {
  if (!url) {
    return 'Page unknown - Not tracked';
  } else if (isLandingPage(url)) {
    return 'Landing page';
  } else {
    const urlWithoutId = url.split('/').filter(el => !hasNumber(el)); // remove user id if url contains it
    return urlWithoutId[urlWithoutId.length - 1]; // last url segment
  }
};

export const getPortalType = (portalType?: Role) =>
  portalType ? portalType.split('-')[2] : undefined;
