import { Text } from '@hse24/shared-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import noShowImage from '../../../assets/no-show.png';
import { selectUserIsHelloStreamer } from '../../../auth/state/authSlice';
import UpcomingShowsLoading from '../../../components/CreateContent/UpcomingShowsLoading/UpcomingShowsLoading';
import MobilePageHeader from '../../../components/MobilePageHeader/MobilePageHeader';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import t from '../../../constants/translation';
import routePaths from '../../../routes/routePaths';
import {
  getCreatorUpcomingShows,
  selectCreatorUpcomingShows,
  selectShowsAreBeingLoaded,
} from '../../../shows/state/showsSlice';
import { selectLoggedInCreator } from '../../state/creatorsSlice';
import sortUpcomingShows from '../../utils/mappers/sortUpcomingShows';
import styles from './StartingSoonSlider.module.scss';
import UpcomingShowCard from './UpcomingShowCard/UpcomingShowCard';

const StartingSoonSlider = () => {
  const history = useHistory();
  const upcomingShows = useSelector(selectCreatorUpcomingShows);
  const loading = useSelector(selectShowsAreBeingLoaded);
  const profileData = useSelector(selectLoggedInCreator);
  const dispatch = useDispatch();

  const isHelloStreamer = useSelector(selectUserIsHelloStreamer);
  const isGoodbyeMessageForHelloStreamerEnabled = featureFlagValue(
    AvailableFlag.goodbyeMessageForHelloStreamer
  );

  const createShowButtonHandler = () => {
    history.push(routePaths.creator.addShow);
  };

  const hasShows = upcomingShows.length > 0;

  useEffect(() => {
    dispatch(
      getCreatorUpcomingShows({
        pageSize: 12,
        page: 0,
      })
    );
  }, [dispatch]);

  if (!loading && !hasShows && isHelloStreamer && isGoodbyeMessageForHelloStreamerEnabled) {
    return null;
  }

  const { mainShow, otherShows } = useMemo(() => sortUpcomingShows(upcomingShows), [upcomingShows]);

  return (
    <div data-testid="planned-shows-container" className={styles.planned_shows_container}>
      <MobilePageHeader />
      <Text.J3 className={styles.header}>{t.creators.show.Overview}</Text.J3>
      {loading && (
        <Box className={styles.shows_slider} data-testid="shows-slider-loader">
          <UpcomingShowsLoading />
          <br />
          <UpcomingShowsLoading />
        </Box>
      )}
      {hasShows && (
        <Box className={styles.shows_slider} data-testid="shows-slider">
          <UpcomingShowCard upcomingShow={mainShow} isLarge />
          {otherShows.map(show => (
            <UpcomingShowCard key={show.id} upcomingShow={show} />
          ))}
        </Box>
      )}
      <div className={styles.no_upcoming_show_container}>
        {!hasShows && (
          <>
            <Typography data-testid="welcome-text" className={styles.welcome_text} variant="body1">
              {t.common.welcomeBack}, {profileData?.firstName}
            </Typography>
            <Typography className={styles.no_show_text} variant="body2">
              {t.creators['You currently have no streams scheduled']}
            </Typography>
          </>
        )}
        <img
          className={styles.no_show_image}
          alt="no-show"
          data-testid="no-show-image"
          src={noShowImage}
        />
        {hasShows && (
          <Typography
            data-testid="create-show-reminded"
            className={styles.add_stream_question}
            variant="body1"
          >
            {t.creators['Do you want to prepare more streams']}
          </Typography>
        )}
        <Button
          onClick={createShowButtonHandler}
          variant="outlined"
          className={styles.create_show_button}
          endIcon={<ArrowForwardIcon />}
        >
          <Typography variant="body1" fontWeight="bold">
            {t.creators['Schedule a stream']}
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default StartingSoonSlider;
