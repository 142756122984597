import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { BudgetAmount } from '../../common/types';

export interface BasketState {
  isLoading: boolean;
  itemsCount: number;
  isBudgetLoading: boolean;
  budgetAmount?: BudgetAmount;
}

const basketInitialState: BasketState = {
  isLoading: false,
  itemsCount: 0,
  isBudgetLoading: false,
};

export const fetchBasketCount = createAction('basket/fetchBasketCount');
export const fetchBudgetAmount = createAction('basket/fetchBudgetAmount');

export const basketSlice = createSlice({
  name: 'basket',
  initialState: basketInitialState,
  reducers: {
    setBasketIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setBasketItemCount: (state, action: PayloadAction<number>) => {
      state.itemsCount = action.payload;
    },
    setBudgetAmountIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isBudgetLoading = action.payload;
    },

    setBudgetAmount: (state, action: PayloadAction<BudgetAmount>) => {
      state.budgetAmount = action.payload;
    },
  },
});

const selectShowsState = (state: RootState) => state[basketSlice.name];

export const selectBasketItemsCount = createSelector(selectShowsState, state => state.itemsCount);
export const selectBasketIsLoading = createSelector(selectShowsState, state => state.isLoading);

export const selectBudgetAmount = createSelector(selectShowsState, state => state.budgetAmount);
export const selectBudgetAmountIsLoading = createSelector(
  selectShowsState,
  state => state.isBudgetLoading
);

export const { setBasketIsLoading, setBasketItemCount, setBudgetAmountIsLoading, setBudgetAmount } =
  basketSlice.actions;

export default basketSlice.reducer;
