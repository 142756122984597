import { Text } from '@hse24/shared-components';
import { Facet } from '../../../model/facet';
import FilterMultiSelect from '../FilterMultiSelect/FilterMultiSelect';
import styles from './FilterItem.module.scss';

interface FacetItemProps {
  facet: Facet;
}

const FilterItem = ({ facet }: FacetItemProps) => {
  return (
    <li className={styles.mobileFilterBarListItem}>
      <Text.B3 className={styles.mobileFilterBarHeadline}>{facet.displayName}</Text.B3>
      <FilterMultiSelect
        options={[...facet.selectedElements, ...facet.elements]}
        facetName={facet.name}
      />
    </li>
  );
};

export default FilterItem;
