import { AxiosResponse } from 'axios';
import { camelizeKeys } from 'humps';
import authClient from '../../auth/authClient';
import { DateRange } from '../../components/DateFilter/dateFilterTypes';
import Config from '../../config/config';
import {
  AgenciesOverview,
  AgenciesResponse,
  Agency,
  AgencyDetails,
  AgencyMonthRange,
} from '../model/agency';
import RefreshTokenUtil from './RefreshTokenUtil';

export interface AgencyRequest {
  name: string;
  email: string;
}

const defaultBaseUrl = `${Config.env.socialLifeCommerceServiceBaseUrl}`;

const agencyClient = (baseUrl: string) => authClient.createClient(`${baseUrl}/agencies`);

const saveAgency = async (
  agencyToSave: AgencyRequest,
  baseUrl: string = defaultBaseUrl
): Promise<Agency> => {
  const response = await RefreshTokenUtil.wrap(() =>
    agencyClient(baseUrl).post<Agency>('', agencyToSave)
  );
  return camelizeKeys(response.data) as Agency;
};

const getAgenciesOverview = async (
  dateRange: DateRange,
  baseUrl: string = defaultBaseUrl
): Promise<AgenciesOverview> => {
  const response = await RefreshTokenUtil.wrap(() =>
    agencyClient(baseUrl).get<AgenciesOverview>('', {
      params: {
        from: dateRange.startDate,
        to: dateRange.endDate,
      },
    })
  );
  return response.data as AgenciesOverview;
};

const getAgencyDetailsById = async (
  agencyId: string,
  dateRange: AgencyMonthRange,
  baseUrl: string = defaultBaseUrl
): Promise<AgencyDetails> => {
  const response = await RefreshTokenUtil.wrap(() =>
    agencyClient(baseUrl).get<AgencyDetails>(`/${agencyId}/details`, {
      params: {
        from: dateRange.startDate,
        to: dateRange.endDate,
      },
    })
  );

  return camelizeKeys(response.data) as AgencyDetails;
};

const getAgencyDetails = async (
  dateRange: AgencyMonthRange,
  baseUrl: string = defaultBaseUrl,
  skipRefreshingToken = false
): Promise<AgencyDetails> => {
  const call = agencyClient(baseUrl).get<AgencyDetails>(`/details`, {
    params: {
      from: dateRange.startDate,
      to: dateRange.endDate,
    },
  });
  const response: AxiosResponse = await (skipRefreshingToken
    ? call
    : RefreshTokenUtil.wrap(() => call));
  return response.data as AgencyDetails;
};

const getAgenciesSummary = async (baseUrl: string = defaultBaseUrl): Promise<AgenciesResponse> => {
  const response = await RefreshTokenUtil.wrap(() =>
    agencyClient(baseUrl).get<AgenciesResponse>('/summary')
  );
  return camelizeKeys(response.data) as AgenciesResponse;
};

const agencyApi = {
  saveAgency,
  getAgencyDetailsById,
  getAgencyDetails,
  agencyClient,
  getAgenciesOverview,
  getAgenciesSummary,
};

export default agencyApi;
