import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../../app/hooks';
import { fetchFactFinderResults, selectProductListProps } from '../../state/productsSlice';
import { generateQueryForFactFinder } from '../../utils/queryUtils';
import BackToTop from '../BackToTop/BackToTop';
import CategoriesSlider from '../CategoriesSlider/CategoriesSlider';
import LoadMoreArticles from '../LoadMoreArticles/LoadMoreArticles';
import LoadPreviousArticles from '../LoadPreviousArticles/LoadPreviousArticles';
import PagingBar from '../PagingBar/PagingBar';
import ProductsCount from '../ProductsCount/ProductsCount';
import SearchLoading from '../SearchLoading/SearchLoading';
import SearchNotFound from '../SearchNotFound/SearchNotFound';
import SearchResults from '../SearchResults/SearchResults';
import SearchSettings from '../SearchSettings/SearchSettings';

const Result = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { loading, foundProducts, categories, paging, pages, searchParams } =
    useSelector(selectProductListProps);

  useEffect(() => {
    dispatch(
      fetchFactFinderResults({
        query: generateQueryForFactFinder(history.location.search),
      })
    );
  }, [history.location.search]);

  if (!loading && foundProducts === 0) {
    return <SearchNotFound />;
  }

  return (
    <>
      <CategoriesSlider categories={categories} />
      <SearchSettings />
      <LoadPreviousArticles paging={paging} loading={loading} />
      {loading && <SearchLoading />}
      <SearchResults pages={pages} />

      {pages &&
        pages.length > 0 &&
        paging.minVisitedPage &&
        paging.maxVisitedPage &&
        searchParams?.hitsPerPage && (
          <div className="flex justify-center mb-16 mt-48">
            <PagingBar
              hitsPerPage={searchParams.hitsPerPage}
              totalHits={foundProducts}
              minVisitedPage={paging.minVisitedPage}
              maxVisitedPage={paging.maxVisitedPage}
            />
          </div>
        )}

      <ProductsCount pages={pages} foundProducts={foundProducts} />
      <LoadMoreArticles paging={paging} loading={loading} />
      <BackToTop />
    </>
  );
};

export default Result;
