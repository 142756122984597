import axios from 'axios';
import { decamelizeKeys } from 'humps';
import { store } from '../../app/store';
import Config from '../../config/config';

export enum LogLevel {
  WARN = 'WARN',
  ERROR = 'ERROR',
  INFO = 'INFO',
  DEBUG = 'DEBUG',
}

export const client = axios.create({
  baseURL: Config.env.loggingLambdaUrl,
  headers: { 'Content-Type': 'application/json' },
});

export const log = (payload: Record<string, unknown>) => {
  const { getState } = store;
  const state = getState();
  const body = decamelizeKeys({
    projectId: 'creators-portal-view',
    payload: {
      ...payload,
      userLogin: state?.auth?.userLogin,
      location: state?.router?.location,
    },
  });
  try {
    // Returned promise can be ignored
    client.post(Config.env.loggingLambdaUrl, body);
  } catch (e) {
    console.log(`Error while sending log to logging lambda: ${e}`);
  }
};
