export const supportedImageTypes = [
  'image/png',
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/heic',
  'image/heif',
];

export const humanReadableSupportedImageTypes = supportedImageTypes
  .map(el => el.split('image/')[1].toUpperCase())
  .join(', ');
