import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Grid, IconButton } from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import SocialMediaAccountSelection from '../SocialMediaAccountSelection/SocialMediaAccountSelection';

const SOCIAL_MEDIA_USERNAME = 'socialMediaUsername';
const SOCIAL_MEDIA_PLATFORM = 'socialMediaPlatform';

const OptionalSocialMediaAccountSelection = () => {
  const [showSocialMediaAccountSelection, setShowSocialMediaAccountSelection] = useState(false);

  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  const { setFieldTouched, setFieldValue } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useFormikContext<any>();

  function removeSocialMediaAccountSelection() {
    setFieldValue(SOCIAL_MEDIA_USERNAME, '');
    setFieldValue(SOCIAL_MEDIA_PLATFORM, '');
    setFieldTouched(SOCIAL_MEDIA_USERNAME, false);
    setFieldTouched(SOCIAL_MEDIA_PLATFORM, false);

    // After the field values have been updated we need to trigger the validation again.
    // Formik is doing that asynchronously, so we need trigger after the other actions are executed.
    setTimeout(() => setFieldTouched('name'), 1);
    setShowSocialMediaAccountSelection(false);
  }

  return (
    <>
      {!showSocialMediaAccountSelection && (
        <Button
          data-testid={'optional-social-media-account-selection-submit'}
          startIcon={<AddIcon />}
          onClick={() => setShowSocialMediaAccountSelection(true)}
          size="large"
        >
          Füge einen Social Media Account hinzu
        </Button>
      )}
      {showSocialMediaAccountSelection && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={11}>
            <SocialMediaAccountSelection />
          </Grid>
          <Grid item xs={1} mb={3}>
            <IconButton
              color="primary"
              onClick={() => removeSocialMediaAccountSelection()}
              data-testid="clear-icon"
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default OptionalSocialMediaAccountSelection;
