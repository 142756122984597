import { FFPaging, Paging } from '../model/paging';
import { buildLinkTarget } from './targetLinkUtils';

export const mapToPaging = (ffPaging: FFPaging, paging: Paging, isNewSearch: boolean): Paging => {
  if (!ffPaging) {
    return {
      prevLink: null,
      nextLink: null,
      maxVisitedPage: null,
      minVisitedPage: null,
      currentPage: 1,
      pageCount: 1,
    };
  }

  // range of visited pages
  let maxVisitedPage = paging.maxVisitedPage;
  let minVisitedPage = paging.minVisitedPage;

  if (!maxVisitedPage || isNewSearch) {
    maxVisitedPage = ffPaging.currentPage;
  } else if (maxVisitedPage && maxVisitedPage < ffPaging.currentPage) {
    maxVisitedPage = ffPaging.currentPage;
  }

  if (!minVisitedPage || isNewSearch) {
    minVisitedPage = ffPaging.currentPage;
  } else if (minVisitedPage && minVisitedPage >= 1 && minVisitedPage > ffPaging.currentPage) {
    minVisitedPage = ffPaging.currentPage;
  }

  // previous and next pages
  let prevLink = paging.prevLink;
  let nextLink = paging.nextLink;

  if (ffPaging.previousLink && ffPaging.previousLink.number < minVisitedPage) {
    prevLink = buildLinkTarget(ffPaging.previousLink.searchParams);
  } else if (minVisitedPage === 1) {
    prevLink = null;
  }

  if (ffPaging.nextLink && ffPaging.nextLink.number > maxVisitedPage) {
    nextLink = buildLinkTarget(ffPaging.nextLink.searchParams);
  } else if (maxVisitedPage === ffPaging.pageCount) {
    nextLink = null;
  }

  return {
    minVisitedPage,
    maxVisitedPage,
    prevLink,
    nextLink,
    currentPage: ffPaging.currentPage,
    pageCount: ffPaging.pageCount,
  };
};

export const updatePaging = (paging: Paging, requestedPage: number): Paging => {
  return {
    ...paging,
    currentPage: requestedPage,
  };
};
