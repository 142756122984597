import { BoxProps } from '@mui/material';
import React from 'react';
import { getPasswordStrengthLevel } from './PasswordStrengthBar.service';
import { Bar, Container, FilledBar } from './PasswordStrengthBar.style';

interface Props extends BoxProps {
  password: string;
}

const MIN_LENGTH = 8;

const PasswordStrengthBar = (props: Props) => {
  const password = props.password;
  const strengthLevel = getPasswordStrengthLevel(password);

  return (
    <Container data-testid="password-strength-bar">
      <Bar>
        <FilledBar
          data-testid="password-strength-filled-bar"
          color={strengthLevel.color}
          percentage={strengthLevel.percentage}
        />
      </Bar>
      <span data-testid="password-strength-label">
        {password?.length < MIN_LENGTH ? 'Gib mehr Zeichen an' : strengthLevel.label}
      </span>
    </Container>
  );
};

export default PasswordStrengthBar;
