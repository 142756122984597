import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, ClickAwayListener, IconButton, Tooltip, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { ChangeEvent, useState } from 'react';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import t from '../../../../constants/translation';
import { CreatorVoucher } from '../../../../creators/model/creator';
import style from './ApplyVouchers.module.scss';

interface ApplyVouchersProps {
  vouchers: CreatorVoucher[];
  onChange?: (voucherId?: string) => void;
}

const ApplyVouchers = ({ vouchers, onChange }: ApplyVouchersProps) => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldValue } = useFormikContext<any>();
  const [isChecked, setChecked] = useState(false);
  const [voucher] = useState<CreatorVoucher | undefined>(vouchers[0]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  if (!voucher) {
    return null;
  }

  const updateCheckedValue = (event: ChangeEvent<HTMLInputElement>) => {
    const isSwitchChecked = event.target.checked;
    setFieldValue('voucherId', isSwitchChecked ? voucher.id : undefined);
    onChange && onChange(isSwitchChecked ? voucher.id : undefined);
    setChecked(isSwitchChecked);
  };

  return (
    <Box className={style.container}>
      <Box>
        <Box display={'flex'}>
          <Typography
            variant={'h5'}
            fontWeight={700}
            fontSize={16}
          >{`${voucher.percentage}${t.creators.voucher['%-voucher']}`}</Typography>
          <Typography variant={'subtitle2'} fontSize={12} fontWeight={600} className={style.badge}>
            {t.creators.voucher.updated}
          </Typography>
        </Box>

        <Typography component="div" className={style.secondary_text}>
          {t.creators.voucher['Voucher for your viewers']}
          <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
            <Tooltip
              PopperProps={{ disablePortal: true }}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={t.creators.voucher['tooltip text']}
            >
              <IconButton className={style.icon}>
                <InfoOutlinedIcon
                  fontSize={'small'}
                  onClick={() => setTooltipOpen(true)}
                  sx={{ padding: '1px' }}
                />
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </Typography>
      </Box>
      <ToggleSwitch
        onChange={updateCheckedValue}
        checked={isChecked}
        data-testid="apply-vouchers-switch"
      />
    </Box>
  );
};

export default ApplyVouchers;
