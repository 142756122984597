import { EButtonSize, EButtonType } from '@hse24/shared-components';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useHistory } from 'react-router';
import Config from '../../../config/config';
import { Paging } from '../../model/paging';
import AnchorButton from '../AnchorButton/AnchorButton';
import styles from './LoadMoreArticles.module.scss';

interface LoadMoreArticlesProps {
  paging: Paging;
  loading: boolean;
}

const LoadMoreArticles = ({ paging, loading }: LoadMoreArticlesProps) => {
  const history = useHistory();
  const loadMore = (linkTarget: string) => history.push(linkTarget);

  return (
    <>
      {paging.nextLink && (
        <div className={styles.buttonMoreCol}>
          <AnchorButton
            size={EButtonSize.MEDIUM}
            type={EButtonType.BLANK}
            icon={{
              left: <div className="aside" />,
              right: loading ? (
                <RefreshIcon className="spinning" data-testid="loading-icon" />
              ) : (
                <ArrowDownwardIcon width="1.5rem" data-testid="arrow-down-icon" />
              ),
            }}
            onClick={() => loadMore(paging.nextLink || '')}
            className="outline-none"
            url={Config.env.baseUrl.concat(paging.nextLink)}
          >
            Weitere Artikel laden
          </AnchorButton>
        </div>
      )}
    </>
  );
};

export default LoadMoreArticles;
