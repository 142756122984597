import { LoadingSpinner } from '@hse24/shared-components';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import PasswordStrengthBar from '../../../components/Bars/PasswordStrengthBar/PasswordStrengthBar';
import SubmitButton from '../../../components/Button/SubmitButton';
import t from '../../../constants/translation';
import { useExecuteReCaptcha } from '../../../hooks/useExecuteReCaptcha';
import {
  confirmEmailExistence,
  EmailExistenceStatus,
  selectAuthLoading,
  selectAuthProgress,
  selectEmailExistenceVerification,
} from '../../state/authSlice';
import styles from '../AuthSteps/AuthSteps.module.scss';

export interface AuthFormValues {
  email?: string;
  password?: string;
  stayLoggedIn?: boolean;
}

interface RegisterFormProps {
  header?: ReactNode;
  submitButton: { title: string; icon?: ReactNode };
  submitCallback: (values?: AuthFormValues) => void;
  resetPasswordCallback?: () => void;
  email?: { value?: string; disabled?: boolean };
  password?: string;
  passwordLabel?: string;
  passwordHelperText?: string;
  passwordStrengthBar?: boolean;
  stayLoggedIn?: boolean;
}

const AuthForm = ({
  header,
  submitButton,
  submitCallback,
  resetPasswordCallback,
  email,
  password,
  passwordLabel,
  passwordHelperText,
  passwordStrengthBar,
  stayLoggedIn,
}: RegisterFormProps) => {
  const dispatch = useDispatch();
  const executeRecaptcha = useExecuteReCaptcha();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPasswordShown, setIsPasswordShown] = useState<boolean>(false);

  const authLoading = useSelector(selectAuthLoading);
  const { loading, error } = useSelector(selectAuthProgress);
  const emailExistenceVerification = useSelector(selectEmailExistenceVerification);

  useEffect(() => {
    setIsLoading(authLoading || loading);
  }, [authLoading, loading]);

  const isResetPasswordProvided = resetPasswordCallback !== undefined;
  const isEmailProvided = email?.value !== undefined;
  const isPasswordProvided = password !== undefined;
  const isStayLoggedInProvided = stayLoggedIn !== undefined;

  const initialValues: AuthFormValues = {
    email: email?.value,
    password,
    stayLoggedIn,
  };

  const validationSchema = yup.object({
    email: isEmailProvided
      ? yup
          .string()
          .email(
            t.validation[
              "Oops, that doesn't look like an email address. Please enter a valid address, such as name@beispiel.com."
            ]
          )
          .required('Pflichtfeld')
      : yup.string(),
    password: isPasswordProvided
      ? passwordStrengthBar
        ? yup.string().required('Pflichtfeld').min(8, 'Mind. 8 Zeichen')
        : yup.string().required('Pflichtfeld')
      : yup.string(),
    stayLoggedIn: isStayLoggedInProvided ? yup.boolean().required('Pflichtfeld') : yup.boolean(),
  });

  const handleShowHidePassword = () => setIsPasswordShown(isPasswordShown => !isPasswordShown);

  const handleEmailBlur = async (value: string) => {
    const isValid = await yup
      .string()
      .required()
      .email()
      .validate(value)
      .then(() => true)
      .catch(() => false);
    if (isValid) {
      dispatch(
        confirmEmailExistence({
          email: value,
          executeRecaptcha,
        })
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => submitCallback(values)}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isValid,
        getFieldProps,
      }) => (
        <form className={styles.auth_form} onSubmit={handleSubmit}>
          <Grid container direction={'column'} alignItems={'center'}>
            <Box className={styles.auth_form_header}>{header}</Box>
            <Box className={styles.auth_form_fields}>
              <Box className={styles.fields}>
                {isEmailProvided && (
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={'email'}
                    name={'email'}
                    label={'E-Mail'}
                    value={values.email}
                    disabled={email?.disabled}
                    error={Boolean(
                      (errors.email && touched.email && errors.email) ||
                        emailExistenceVerification?.status === EmailExistenceStatus.EMAIL_NOT_EXISTS
                    )}
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e);
                      handleEmailBlur?.(e.target.value);
                    }}
                    helperText={
                      (errors.email && touched.email && String(errors.email)) ||
                      (emailExistenceVerification?.status ===
                        EmailExistenceStatus.EMAIL_NOT_EXISTS &&
                        t.validation[
                          'Oh no, we have never seen this email address before. Please check your input again.'
                        ])
                    }
                    InputProps={{
                      endAdornment: !errors.email && (
                        <InputAdornment position="end">
                          {emailExistenceVerification?.loading && (
                            <span data-testid={'loading-spinner'}>
                              <LoadingSpinner className={styles.input_loading} />
                            </span>
                          )}
                          {!emailExistenceVerification?.loading &&
                            emailExistenceVerification?.status ===
                              EmailExistenceStatus.EMAIL_EXIST && <CheckIcon color={'success'} />}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {isPasswordProvided && (
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    size={'medium'}
                    type={isPasswordShown ? 'text' : 'password'}
                    name={'password'}
                    label={passwordLabel ?? 'Passwort'}
                    value={values.password}
                    error={Boolean(errors.password && touched.password && errors.password)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={
                      (errors.password && touched.password && String(errors.password)) ||
                      passwordHelperText
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            data-testid="password-visibility"
                            onClick={handleShowHidePassword}
                            edge="end"
                          >
                            {isPasswordShown ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {isPasswordProvided && passwordStrengthBar && (
                  <PasswordStrengthBar password={getFieldProps('password').value} />
                )}
              </Box>
              {isResetPasswordProvided && (
                <Link
                  className={styles.field}
                  sx={{ cursor: 'pointer' }}
                  onClick={resetPasswordCallback}
                  underline={'none'}
                  color={'#000'}
                  justifyContent={'right'}
                >
                  {'Passwort vergessen?'}
                </Link>
              )}
            </Box>
            <Box className={styles.auth_form_actions}>
              {isStayLoggedInProvided && (
                <FormGroup>
                  <FormControlLabel
                    className={styles.logged_in}
                    control={
                      <Checkbox
                        name={'stayLoggedIn'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.stayLoggedIn}
                        checked={values.stayLoggedIn}
                        disabled={isLoading}
                      />
                    }
                    label={'Login merken'}
                  />
                  <FormHelperText>
                    {errors.stayLoggedIn && touched.stayLoggedIn && String(errors.stayLoggedIn)}
                  </FormHelperText>
                </FormGroup>
              )}
              {error && (
                <Typography color={red.A400} mb={'8px'}>
                  {error}
                </Typography>
              )}
              <SubmitButton
                fullWidth
                variant={'contained'}
                size={'large'}
                type={'submit'}
                disabled={isLoading || !isValid}
                endIcon={!isLoading && submitButton.icon}
              >
                {!isLoading ? (
                  <Typography fontWeight={700}>{submitButton.title}</Typography>
                ) : (
                  <LoadingSpinner />
                )}
              </SubmitButton>
            </Box>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default AuthForm;
