import { useEffect, useState } from 'react';

const useCopyToClipboard = (timeout = 1000): [boolean, (textToCopy: string) => void] => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (textToCopy: string) => {
    setIsCopied(true);
    navigator.clipboard.writeText(textToCopy);
  };

  useEffect(() => {
    if (!isCopied) return;

    const timer = setTimeout(() => setIsCopied(false), timeout);
    return () => clearTimeout(timer);
  }, [isCopied]);

  return [isCopied, copyToClipboard];
};

export default useCopyToClipboard;
