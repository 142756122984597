import { v4 as uuid } from 'uuid';
import { ChatConnection } from './ChatConnection.js';
import { chatErrorCodes } from './errors.js';
import { EventEmitter } from './EventEmitter.js';
import { ConsoleLogger } from './logging.js';
import { parseChatError, parseChatEvent, parseChatMessage, parseDeleteMessageEvent, parseDisconnectUserEvent, parseObject, parseString, } from './utils/deserialize.js';
import { serializeRequest } from './utils/serialize.js';
import { makeWebSocketUrl } from './utils/url.js';
/**
 * Represents IVS chat room connection.
 *
 * This is a high-level object representing connection to the IVS Chat room. It is responsible for managing chat
 * connection, sending chat requests and receiving chat events.
 */
export class ChatRoom {
    /**
     * Constructs a new {@link ChatRoom} instance.
     *
     * @param config - {@link ChatRoomConfig | Constructor configuration object}
     */
    constructor({ regionOrUrl, tokenProvider, maxReconnectAttempts = 3, id = uuid() }) {
        this._state = 'disconnected';
        this.currentConnection = null;
        this.nextConnection = null;
        this.eventEmitter = new EventEmitter();
        this.disconnectReason = 'serverDisconnect';
        this.pendingRequests = new Map();
        this.nextConnectionScheduleHandle = null;
        // Short-lived set of received entity ids used for deduplicating socket messages when switching connections.
        this.receivedEntityIds = new Set();
        /** {@link ChatLogger | Logger } used by the {@link ChatRoom | room}. Defaults to {@link ConsoleLogger }. */
        this.logger = new ConsoleLogger();
        /**
         * Minimal log severity {@link ChatLogLevel | level } for messages to be logged to {@link ChatRoom#logger | logger}.
         *
         * Defaults to {@link ChatLogLevel | `debug` }.
         */
        this.logLevel = 'debug';
        this.clearConnection = (connection) => {
            connection === null || connection === void 0 ? void 0 : connection.removeListener('open', this.onOpen);
            connection === null || connection === void 0 ? void 0 : connection.removeListener('close', this.onClose);
            connection === null || connection === void 0 ? void 0 : connection.removeListener('message', this.onMessage);
            connection === null || connection === void 0 ? void 0 : connection.close();
        };
        this.disposeAllConnections = () => {
            this.clearConnection(this.currentConnection);
            this.clearConnection(this.nextConnection);
            this.currentConnection = null;
            this.nextConnection = null;
            if (this.nextConnectionScheduleHandle != null) {
                clearTimeout(this.nextConnectionScheduleHandle);
            }
        };
        this.onOpen = (tokenExpirationTime) => {
            var _a;
            this.state = 'connected';
            if (((_a = this.nextConnection) === null || _a === void 0 ? void 0 : _a.state) === 'open') {
                this.clearConnection(this.currentConnection);
                this.currentConnection = this.nextConnection;
                this.nextConnection = null;
                if (this.nextConnectionScheduleHandle != null) {
                    clearTimeout(this.nextConnectionScheduleHandle);
                }
                // clear cached messages after disconnecting the old connection and clear events completely.
                setTimeout(() => this.receivedEntityIds.clear(), 500);
            }
            if (tokenExpirationTime != null) {
                this.scheduleReconnect(tokenExpirationTime);
            }
            this.logDebug('did receive connection "open" event');
        };
        this.onClose = (reason) => {
            // Connection object will not try reconnecting after connection has been established, so room object has to create a new connection in such case.
            if (reason === 'socketConnectionBroken') {
                this.reconnect();
            }
            else {
                this.disconnectReason = reason;
                this.state = 'disconnected';
                this.disposeAllConnections();
            }
        };
        this.onMessage = (data) => {
            let entity;
            try {
                entity = parseObject(JSON.parse(data), 'data');
            }
            catch (error) {
                this.logError('failed to parse received JSON', data, error);
                return;
            }
            let id;
            try {
                id = parseString(entity.Id, 'Id');
                if (this.receivedEntityIds.has(id)) {
                    this.logDebug(`ignoring already received socket message with the same id: ${id}`);
                    return;
                }
                else {
                    this.receivedEntityIds.add(id);
                }
            }
            catch (error) {
                this.logError('failed to parse received entity Id', entity, error);
            }
            switch (entity.Type) {
                case 'MESSAGE':
                    this.receiveMessageJson(entity);
                    break;
                case 'EVENT':
                    this.receiveEventJson(entity);
                    break;
                case 'ERROR':
                    this.receiveErrorJson(entity);
                    break;
                default:
                    this.logError(`received unknown entity type ${entity.Type}`, data);
            }
        };
        this.id = id;
        this.tokenProvider = tokenProvider;
        this.socketUrl = makeWebSocketUrl(regionOrUrl);
        this.maxReconnectAttempts = maxReconnectAttempts;
    }
    /** Returns the {@link ConnectionState | connection state} of the {@link ChatRoom | room}. */
    get state() {
        return this._state;
    }
    set state(newState) {
        if (newState === this._state) {
            return;
        }
        const oldState = this._state;
        this._state = newState;
        this.logInfo(`changed state: ${oldState} -> ${newState}`);
        switch (newState) {
            case 'connecting':
                this.dispatchEvent('connecting');
                break;
            case 'connected':
                this.dispatchEvent('connect');
                break;
            case 'disconnected':
                this.dispatchEvent('disconnect', this.disconnectReason);
                break;
        }
    }
    /**
     * Sets up a function that will be called whenever the {@link ChatRoomListenerMap | specified event} is delivered.
     *
     * @typeParam EventName - One of supported {@link ChatRoomListenerMap | event types}
     * @param eventName - Name of the {@link ChatRoomListenerMap | event}
     * @param listener - Function called whenever the {@link ChatRoomListenerMap | specified event} is delivered
     * @returns A function that removes the event listener
     */
    addListener(eventName, listener) {
        return this.eventEmitter.addListener(eventName, listener);
    }
    /**
     * Removes the event listener for the {@link ChatRoomListenerMap | specified event}.
     *
     * @typeParam Event - One of supported {@link ChatRoomListenerMap | event types}.
     */
    removeListener(eventName, listener) {
        return this.eventEmitter.removeListener(eventName, listener);
    }
    dispatchEvent(name, ...payload) {
        this.logDebug(`dispatching event "${name}"`, ...payload);
        this.eventEmitter.emit(name, ...payload);
    }
    /**
     * Initiates the process of connecting to the socket, using {@link ChatTokenProvider | token provider} passed to
     * constructor. In case of failure, it will try to reconnect the number of times specified in
     * {@link ChatRoom#maxReconnectAttempts }, defaulting to 3 attempts.
     */
    connect() {
        if (this.state !== 'disconnected') {
            this.logError(`state must be "disconnected" but is ${this.state}`);
            throw new Error(`State must be "disconnected" but is ${this.state}`);
        }
        // Reset values to defaults
        this.disconnectReason = 'serverDisconnect';
        this.state = 'connecting';
        this.currentConnection = this.createConnection();
    }
    /**
     * Initiates a graceful disconnection of the Web Socket. Results in calling the
     * {@link ChatRoomListenerMap | `disconnect` event} with `disconnectReason` parameter set to
     * {@link DisconnectReason | `'clientDisconnect'` }.
     */
    disconnect() {
        this.disconnectReason = 'clientDisconnect';
        this.state = 'disconnected';
        this.disposeAllConnections();
    }
    /**
     * Sends a {@link SendMessageRequest | message request } to the {@link ChatRoom | room} to the room.
     *
     * @param request - Contains a message and related metadata to be sent
     * @returns Promise that resolves to a {@link ChatMessage} or rejects with a {@link ChatError}
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if the request cannot be serialized.
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if the {@link ChatRoom | room} is not connected.
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if a request with the same {@link SendMessageRequest#requestId} is already submitted.
     */
    sendMessage(request) {
        var _a;
        let json;
        try {
            json = serializeRequest(request);
        }
        catch (error) {
            this.logError(`failed to serialize send message request to JSON`, request, error);
            throw new Error(`Request serialization failed`);
        }
        if (this.state !== 'connected') {
            this.logError('is not connected');
            throw new Error('Room is not connected');
        }
        if (this.pendingRequests.has(request.requestId)) {
            this.logError(`already has pending request with the same requestId`, request);
            throw new Error('Duplicate request id');
        }
        (_a = this.currentConnection) === null || _a === void 0 ? void 0 : _a.send(json);
        this.logInfo('did submit send message request', json);
        return new Promise((resolve, reject) => {
            this.pendingRequests.set(request.requestId, {
                type: 'sendMessage',
                resolve,
                reject,
            });
        });
    }
    /**
     * Sends a {@link DeleteMessageRequest | delete message request } to the room.
     *
     * @param request - Identifies a message to be deleted along with an optional reason for deletion.
     * @returns Promise that resolves to a {@link DeleteMessageEvent} or rejects with a {@link ChatError}
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if the request cannot be serialized.
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if the {@link ChatRoom | room} is not connected.
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if a request with the same {@link DeleteMessageRequest#requestId} is already submitted.
     */
    deleteMessage(request) {
        var _a;
        let json;
        try {
            json = serializeRequest(request);
        }
        catch (error) {
            this.logError(`failed to serialize delete message request to JSON`, request, error);
            throw new Error(`Request serialization failed`);
        }
        if (this.state !== 'connected') {
            this.logError('is not connected');
            throw new Error('Room is not connected');
        }
        if (this.pendingRequests.has(request.requestId)) {
            this.logError(`already has pending request with the same request id ${request.requestId}`, request);
            throw new Error('Duplicate request id');
        }
        (_a = this.currentConnection) === null || _a === void 0 ? void 0 : _a.send(json);
        this.logInfo('did submit send delete message request', json);
        return new Promise((resolve, reject) => {
            this.pendingRequests.set(request.requestId, {
                type: 'deleteMessage',
                resolve,
                reject,
            });
        });
    }
    /**
     * Sends a {@link DisconnectUserRequest | disconnect user request } to the room.
     *
     * @param request - Identifies the user to be disconnected along with an optional reason for disconnection.
     * @returns Promise that resolves to a {@link DisconnectUserEvent | disconnect user event} or rejects with a
     *   {@link ChatError | chat error}
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Throws if the request cannot be serialized.
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Throws if the {@link ChatRoom | room} is not connected.
     * @throws {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error | Error}
     *   Thrown if a request with the same {@link DisconnectUserRequest#requestId} is already submitted.
     */
    disconnectUser(request) {
        var _a;
        let json;
        try {
            json = serializeRequest(request);
        }
        catch (error) {
            this.logError(`failed to serialize disconnect user request to JSON`, request, error);
            throw new Error(`Request serialization failed`);
        }
        if (this.state !== 'connected') {
            this.logError('is not connected');
            throw new Error('Room is not connected');
        }
        if (this.pendingRequests.has(request.requestId)) {
            this.logError(`already has pending request with the same request id ${request.requestId}`, request);
            throw new Error('Duplicate request id');
        }
        (_a = this.currentConnection) === null || _a === void 0 ? void 0 : _a.send(json);
        this.logInfo('did submit disconnect user request', request);
        return new Promise((resolve, reject) => {
            this.pendingRequests.set(request.requestId, {
                type: 'disconnectUser',
                resolve,
                reject,
            });
        });
    }
    scheduleReconnect(tokenExpirationTime) {
        const remainingTokenLife = tokenExpirationTime.getTime() - Date.now();
        // Create next connection 60 secs before expiry, unless token is short lived (60 secs or less),
        // in such case create next connection 30 secs before expiry.
        const nextConnectionAfter = remainingTokenLife <= 60000 ? Math.max(remainingTokenLife - 30000, 0) : remainingTokenLife - 60000;
        this.logDebug(`scheduled next connection in ${new Date(Date.now() - nextConnectionAfter).toISOString()}`);
        this.nextConnectionScheduleHandle = setTimeout(() => {
            this.logInfo('Refreshing connection...');
            this.nextConnection = this.createConnection();
        }, nextConnectionAfter);
    }
    createConnection() {
        const currentConnection = new ChatConnection({
            socketUrl: this.socketUrl,
            tokenProvider: this.tokenProvider,
            maxReconnectAttempts: this.maxReconnectAttempts,
            roomId: this.id,
            logger: this.logger,
            logLevel: this.logLevel,
        });
        currentConnection.addListener('open', this.onOpen);
        currentConnection.addListener('close', this.onClose);
        currentConnection.addListener('message', this.onMessage);
        return currentConnection;
    }
    reconnect() {
        // Reset values to defaults
        this.disconnectReason = 'serverDisconnect';
        this.state = 'connecting';
        this.clearConnection(this.currentConnection);
        this.currentConnection = this.createConnection();
    }
    receiveMessageJson(entity) {
        let message;
        try {
            message = parseChatMessage(entity);
            this.logInfo('did receive message', message);
        }
        catch (error) {
            this.logError('did fail to deserialize received message', entity, error);
            return;
        }
        this.dispatchEvent('message', message);
        if (message.requestId == null) {
            return;
        }
        const pendingRequest = this.pendingRequests.get(message.requestId);
        this.pendingRequests.delete(message.requestId);
        if ((pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type) === 'sendMessage') {
            this.logDebug('resolving sendMessage promise', message);
            pendingRequest.resolve(message);
        }
        else if (pendingRequest != null) {
            this.logError(`found pending request of type "${pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type}" for request id ${message.requestId} but expected request of type "sendMessage"`, message);
        }
    }
    receiveEventJson(entity) {
        let event;
        try {
            event = parseChatEvent(entity);
        }
        catch (error) {
            this.logError('did fail to deserialize received event', entity, error);
            return;
        }
        switch (event.eventName) {
            case 'aws:DELETE_MESSAGE': {
                this.handleDeleteMessageEvent(event);
                break;
            }
            case 'aws:DISCONNECT_USER': {
                this.handleDisconnectUserEvent(event);
                break;
            }
            default:
                this.logInfo('did receive event', event);
                this.dispatchEvent('event', event);
                break;
        }
    }
    handleDeleteMessageEvent(event) {
        let deleteMessageEvent;
        try {
            deleteMessageEvent = parseDeleteMessageEvent(event);
            this.logInfo('did receive delete message event', event);
        }
        catch (error) {
            this.logError('did fail to deserialize received delete message event', event, error);
            return;
        }
        this.logDebug('dispatching "messageDelete" event', deleteMessageEvent);
        this.dispatchEvent('messageDelete', deleteMessageEvent);
        if (deleteMessageEvent.requestId == null) {
            return;
        }
        const pendingRequest = this.pendingRequests.get(deleteMessageEvent.requestId);
        this.pendingRequests.delete(deleteMessageEvent.requestId);
        if ((pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type) === 'deleteMessage') {
            this.logDebug(`resolving deleteMessage promise`, deleteMessageEvent);
            pendingRequest.resolve(deleteMessageEvent);
        }
        else if ((pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type) != null) {
            this.logError(`found pending request of type "${pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type}" for request id ${deleteMessageEvent.requestId} but expected request of type "deleteMessage"`, deleteMessageEvent);
        }
    }
    handleDisconnectUserEvent(event) {
        let disconnectUserEvent;
        try {
            disconnectUserEvent = parseDisconnectUserEvent(event);
            this.logInfo('did receive disconnect user event', event);
        }
        catch (error) {
            this.logError('did fail to deserialize received disconnect user event', event, error);
            return;
        }
        this.dispatchEvent('userDisconnect', disconnectUserEvent);
        if (disconnectUserEvent.requestId == null) {
            return;
        }
        const pendingRequest = this.pendingRequests.get(disconnectUserEvent.requestId);
        this.pendingRequests.delete(disconnectUserEvent.requestId);
        if ((pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type) === 'disconnectUser') {
            this.logDebug(`resolving disconnectUser promise`, disconnectUserEvent);
            pendingRequest.resolve(disconnectUserEvent);
        }
        else if ((pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type) != null) {
            this.logError(`found pending request of type "${pendingRequest === null || pendingRequest === void 0 ? void 0 : pendingRequest.type}" for request id ${disconnectUserEvent.requestId} but expected request of type "disconnectUser"`, disconnectUserEvent);
        }
    }
    receiveErrorJson(entity) {
        let error;
        try {
            error = parseChatError(entity);
            this.logError('received error', entity);
        }
        catch (e) {
            this.logError('did fail to deserialize received error', entity, e);
            return;
        }
        const isTokenExpired = error.errorCode === chatErrorCodes.unauthorized;
        if (error.requestId) {
            const pendingRequest = this.pendingRequests.get(error.requestId);
            this.pendingRequests.delete(error.requestId);
            if (pendingRequest) {
                this.logDebug(`rejecting ${pendingRequest.type} promise`, error);
                pendingRequest.reject(error);
            }
            else if (!isTokenExpired) {
                this.logError('received error without matching pending request', error);
            }
        }
        if (isTokenExpired) {
            this.logDebug('received token expiration error, reconnecting', error);
            // fix: reject pending request before new connection is initialized.
            // eslint-disable-next-line promise/catch-or-return, promise/prefer-await-to-then
            Promise.resolve().then(() => this.reconnect());
        }
        if (error.requestId == null && !isTokenExpired) {
            this.logError('received error without matching pending request', error);
        }
    }
    logDebug(message, ...args) {
        if (this.logLevel === 'debug') {
            this.logger.debug(`Room ${this.id} ${message}`, ...args);
        }
    }
    logInfo(message, ...args) {
        if (this.logLevel === 'debug' || this.logLevel === 'info') {
            this.logger.info(`Room ${this.id} ${message}`, ...args);
        }
    }
    logError(message, ...args) {
        this.logger.error(`Room ${this.id} ${message}`, ...args);
    }
}
