import { Facet, FacetElement, FacetName, FFFacet } from '../model/facet';
import { Locale } from '../model/locale';
import { CustomParameters, SearchParams } from '../model/searchParams';

const facetNames: string[] = Object.values(FacetName);

export const mapToFacets = (
  ffFacets: Array<FFFacet>,
  customParams?: CustomParameters[]
): Facet[] => {
  if (!ffFacets) {
    return [];
  }

  return ffFacets
    .filter(ffFacet => facetNames.includes(ffFacet.name))
    .map(facet => mapToFacet(facet, customParams))
    .filter(Boolean);
};

export const mapToFacetElementSelectedV2 = (selected: boolean | string): boolean => {
  if (typeof selected === 'boolean') {
    return selected;
  }
  return selected === 'TRUE';
};

const mapToFacet = (ffFacet: FFFacet, customParams?: CustomParameters[]): Facet => {
  return {
    name: ffFacet.name,
    displayName: mapToDisplayName(ffFacet.name),
    elements: ffFacet.elements.map(element =>
      mapToFacetElement(element, ffFacet.unit, customParams)
    ),
    selectedElements: ffFacet.selectedElements.map(element =>
      mapToFacetElement(element, ffFacet.unit, customParams)
    ),
    unit: ffFacet.unit,
    type: ffFacet.type,
  };
};

const mapToDisplayName = (name: string): string => {
  const facetName = Object.values(FacetName).find(facetName => facetName === name);
  const facetNameToDisplayName = {
    [FacetName.Brand]: 'Marke',
    [FacetName.Price]: 'Preis',
    [FacetName.Size]: 'Größe',
    [FacetName.ColorFamilies]: 'Farbe',
    [FacetName.Alloy]: 'Legierung',
    [FacetName.CategoryPath]: 'CategoryPath',
  };
  return (facetName && facetNameToDisplayName[facetName]) || '';
};

const mapToFacetElement = (
  element: FacetElement,
  unit: string,
  customParams?: CustomParameters[]
): FacetElement => {
  return {
    text: element.text,
    displayName: mapFactFinderFacetElementToDisplayName(element.associatedFieldName, element.text),
    associatedFieldName: element.associatedFieldName,
    searchParams: element.searchParams && mapToSearchParams(element.searchParams, customParams),
    selected: mapToFacetElementSelectedV2(element.selected),
    clusterLevel: element.clusterLevel,
  };
};

const mapToSearchParams = (
  searchParams: SearchParams,
  customParams?: CustomParameters[]
): SearchParams => {
  return {
    query: searchParams.query,
    filters: searchParams.filters,
    customParameters: customParams,
  };
};

const mapFactFinderFacetElementToDisplayName = (
  associatedFieldName: string,
  text: string
): string => {
  if (associatedFieldName === FacetName.Price) {
    return toPriceDisplayName(text);
  }
  return text;
};

const toPriceDisplayName = (text: string) => {
  const matches = text.match(/\d+(\.\d+)?/g);
  let result = text;
  matches &&
    matches.forEach(match => {
      result = result.replace(match, formatPrice(Locale.deDE, parseFloat(match)));
    });
  return result;
};

const formatPrice = (locale: Locale, price: number) => {
  return price.toLocaleString(locale, {
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
    style: 'currency',
  });
};
