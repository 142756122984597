import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import OnboardingHintDialogue from '../../../components/OnboardingHintDialogue/OnboardingHintDialogue';
import { featureFlagValue } from '../../../config/featureFlagConfig';
import { AvailableFlag } from '../../../config/FeatureFlags';
import { renderOnboardingStep } from '../../../constants/Onboarding';
import { selectCurrentOnboardingStepIndex } from '../../../creators/state/creatorsSlice';

const StreamerOnboardingDialog = () => {
  const dispatch = useAppDispatch();
  const isInApp = featureFlagValue(AvailableFlag.isInApp);
  const currentOnboardingStepIndex = useAppSelector(selectCurrentOnboardingStepIndex);

  const dispatchOnboardingCallback = (action: { type: string }) => {
    dispatch(action);
  };

  const onboardingStep = renderOnboardingStep(
    dispatchOnboardingCallback,
    currentOnboardingStepIndex,
    isInApp
  );

  return (
    <>
      {onboardingStep && (
        <OnboardingHintDialogue
          title={onboardingStep.title}
          steps={onboardingStep.steps}
          subtitle={onboardingStep.subtitle}
          message={onboardingStep.message}
          button={onboardingStep.button}
        />
      )}
    </>
  );
};

export default StreamerOnboardingDialog;
