import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import Icons from '../../../../assets';
import CropModal from '../../../../components/CropModal/CropModal';
import Icon from '../../../../components/Icon/Icon';
import { Aspect, CropSource } from '../../../../utils/images/cropImageFns';
import {
  cancelFileUploadToS3,
  imageCropped,
  imageCroppedInUpdate,
  moveSelectedFileDown,
  moveSelectedFileUp,
  removeUnsupportedFiles,
  selectExhibitedFile,
} from '../../../state/postsSlice';
import { getFileExtension, isImage } from '../../../utils/MediaUtils';
import styles from './PostMediaRender.module.scss';
import { PostMediaRendererMenuItem } from './PostMediaRendererMenuItem/PostMediaRendererMenuItem';

interface PostMediaRendererProps {
  isUpdate: boolean;
}

const PostMediaRenderer = ({ isUpdate }: PostMediaRendererProps) => {
  const file = useSelector(selectExhibitedFile);
  const [isCropModalOpen, setIsCropModalOpen] = useState<boolean>(false);
  const selectFilesSize = useSelector(
    (state: RootState) => state.posts.createPost.upload.selectedFiles.length
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeUnsupportedFiles());
  }, []);

  const handleDelete = () => {
    if (file) {
      dispatch(cancelFileUploadToS3({ key: file.key, preSignedUrl: file.preSignedUrl }));
    }
  };

  const handleMoveUp = () => {
    if (file) {
      dispatch(moveSelectedFileUp(file));
    }
  };

  const handleMoveDown = () => {
    if (file) {
      dispatch(moveSelectedFileDown(file));
    }
  };
  const onMediaCropSaveHandler = (image: Blob) => {
    if (file) {
      dispatch(
        isUpdate
          ? imageCroppedInUpdate({
              croppedFile: file,
              newCroppedImageUrl: URL.createObjectURL(image),
              s3File: { key: file.key, preSignedUrl: file.preSignedUrl },
            })
          : imageCropped({
              croppedFile: file,
              newCroppedImageUrl: URL.createObjectURL(image),
              preSignedUrl: file.preSignedUrl,
            })
      );
      setIsCropModalOpen(false);
    }
  };

  return (
    <>
      {file && file.url ? (
        <div data-testid="media-renderer" className={styles.container}>
          {isCropModalOpen && (
            <CropModal
              imageUrl={file.url}
              onSaveHandler={onMediaCropSaveHandler}
              handleCancelCrop={() => {
                setIsCropModalOpen(false);
              }}
              cropSource={CropSource.POST}
              aspect={Aspect.RATIO_1_1}
              title="Vorschaubild hochladen"
              showRatioVariants
            />
          )}
          <div className={styles.menu}>
            <PostMediaRendererMenuItem
              dataTestId="arrow-down-icon"
              onClickHandler={handleMoveDown}
              Icon={<Icon icon={Icons.arrowDown} height={24} width={24} />}
              isActive={
                selectFilesSize > 1 && (file.index === 0 || file.index < selectFilesSize - 1)
              }
            />

            <PostMediaRendererMenuItem
              onClickHandler={handleMoveUp}
              dataTestId="arrow-up-icon"
              Icon={<Icon icon={Icons.arrowUpv24} height={24} width={24} />}
              isActive={
                selectFilesSize > 1 && (file.index === selectFilesSize - 1 || file.index > 0)
              }
            />
            {isImage(file.type) && (
              <PostMediaRendererMenuItem
                dataTestId="crop-icon"
                onClickHandler={() => {
                  setIsCropModalOpen(true);
                }}
                Icon={<Icon icon={Icons.cropV24} height={24} width={24} />}
                isActive={true}
              />
            )}
            <PostMediaRendererMenuItem
              dataTestId="trash-icon"
              onClickHandler={handleDelete}
              Icon={<Icon icon={Icons.trashBin} height={24} width={24} />}
              isActive={true}
            />
          </div>
          {isImage(file.type) ? (
            <img className={styles.media} src={file.url} alt="Media" />
          ) : (
            <video controls className={styles.media}>
              <source src={file.url} type={`video/${getFileExtension(file.key)}`} />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PostMediaRenderer;
