import { Text } from '@hse24/shared-components';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Box, Card, CardMedia, IconButton, Paper, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import Icons from '../../../assets';
import CropModal from '../../../components/CropModal/CropModal';
import Icon from '../../../components/Icon/Icon';
import t from '../../../constants/translation';
import { CreatorImageType } from '../../../creators/model/creator';
import useValidateAndCropImage from '../../../shared/images/useValidateAndCropImage';
import { Aspect, CropSource } from '../../../utils/images/cropImageFns';
import { getUrlWithImageManagerParams } from '../../../utils/media';
import { ImageType, ShowCoverSelectionMetadata } from '../../model/shows';
import styles from './ShowCoverSelection.module.scss';

export interface ShowCoverSelectionProps {
  fieldName: string;
  imageType: ImageType | CreatorImageType;
  defaultImage?: string;
  metadata?: ShowCoverSelectionMetadata;
  onChange?: (value?: string) => void;
  hasError?: boolean;
  ratio?: Aspect;
  showRatioVariants?: boolean;
}

const ShowCoverSelection = (props: ShowCoverSelectionProps) => {
  const { ratio = Aspect.RATIO_4_5, showRatioVariants } = props;
  const validateAndCrop = useValidateAndCropImage();
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldValue, setFieldTouched } = useFormikContext<any>();
  const [coverImg, setCoverImg] = useState<string | null>(null);
  const [croppedImg, setCroppedImg] = useState<string | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCroppedImg(props.defaultImage ? getUrlWithImageManagerParams(props.defaultImage) : null);
  }, [props.defaultImage]);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const image: string | null = await validateAndCrop(event);
    if (image) {
      setCoverImg(image);
    }
  };

  const onSaveHandler = (image: Blob) => {
    const imageURL = URL.createObjectURL(image);
    setCroppedImg(imageURL);
    handleFileChange(image.type.substring(6), true, imageURL);
    setCoverImg(null);
  };

  const handleCancelCrop = () => {
    setCoverImg(null);
    handleFileChange('', false, undefined);
  };

  const handleFileChange = (imageExtension: string, imageChanged: boolean, imageUrl?: string) => {
    setFieldValue(
      props.fieldName,
      {
        imageType: props.imageType,
        fileExtension: imageExtension,
        imageUrl: imageUrl,
        changed: imageChanged,
      },
      true
    );
    setFieldTouched(props.fieldName, true);
    props.onChange && props.onChange(imageUrl);
  };

  const cardClassName = cx({
    [styles.large_cover_card]: ratio !== Aspect.RATIO_26_10,
    [styles.ratio_26_10_cover_card]: ratio === Aspect.RATIO_26_10,
    [styles.cover_card]: !props.hasError,
    [styles.error_cover_card]: props.hasError,
  });

  const textClassName = cx({
    [styles.error_secondary_text]: props.hasError,
  });

  const renderCoverImageCard = (hasError?: boolean) => (
    <Card variant="outlined" className={cardClassName}>
      {croppedImg ? (
        <CardMedia
          component="img"
          className={styles.cover_selected_img}
          image={croppedImg}
          data-testid="displayed-cropped-image"
        />
      ) : (
        <Paper className={styles.card} elevation={0} data-testid="default-image">
          <IconButton>
            <CalendarTodayIcon className={textClassName} />
          </IconButton>
          <Typography variant="body1" className={textClassName}>
            {t.common['Upload image']}
          </Typography>
          <Typography
            variant="body1"
            className={hasError ? styles.error_secondary_text : styles.secondary_text}
          >
            {t.creators.show.imageFormat}
          </Typography>
        </Paper>
      )}
      <IconButton
        onClick={handleClick}
        className={cx(styles.floating_button, { [styles.edit]: !!croppedImg })}
        size="small"
      >
        {croppedImg ? (
          <Icon dataTestId="edit-icon" icon={Icons.editIcon} />
        ) : (
          <Icon dataTestId="upload-icon" icon={Icons.uploadIcon} />
        )}
      </IconButton>
      <input
        className={styles.img_input}
        type="file"
        onChange={event => handleChange(event)}
        ref={hiddenFileInput}
        onClick={event => {
          const element = event.target as HTMLInputElement;
          element.value = '';
        }}
        data-testid="select-cover-input"
      />
    </Card>
  );

  const render26_10_CoverImageCard = () => (
    <div className={styles.image_container}>
      <Card variant="outlined" className={cardClassName}>
        {croppedImg ? (
          <CardMedia
            component="img"
            className={styles.cover_selected_img}
            image={croppedImg}
            data-testid="displayed-cropped-image"
          />
        ) : (
          <Paper className={styles.card} elevation={0} data-testid="default-image">
            <Text.BodySmall className={styles.image_text}>
              {t.common['Upload image']}
            </Text.BodySmall>
          </Paper>
        )}
        <IconButton
          onClick={handleClick}
          className={cx(styles.floating_button, { [styles.edit]: !!croppedImg })}
          size="small"
        >
          {croppedImg ? (
            <Icon dataTestId="edit-icon" icon={Icons.editIcon} />
          ) : (
            <Icon dataTestId="upload-icon" icon={Icons.uploadIcon} />
          )}
        </IconButton>
      </Card>
      <input
        className={styles.img_input}
        type="file"
        onChange={event => handleChange(event)}
        ref={hiddenFileInput}
        onClick={event => {
          const element = event.target as HTMLInputElement;
          element.value = '';
        }}
        data-testid="select-cover-input"
      />
      {ratio === Aspect.RATIO_26_10 && (
        <Text.BodySmall className={styles.ratio_text}>mindestens 416 × 160px</Text.BodySmall>
      )}
    </div>
  );

  const renderTitles = () => (
    <>
      {props.metadata?.title && (
        <MenuItem className={styles.menu_item}>
          <ListItemIcon>
            <Icon dataTestId="video-camera-icon" icon={Icons.videoCamera} />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="h6" fontWeight="bold">
              {props.metadata?.title}
            </Typography>
          </ListItemText>
        </MenuItem>
      )}
      {props.metadata?.subTitle && (
        <Typography component="div" className={styles.secondary_text}>
          {props.metadata?.subTitle}
        </Typography>
      )}
    </>
  );

  const renderCroppedImage = (coverImg: string) => {
    return (
      <CropModal
        handleCancelCrop={handleCancelCrop}
        onSaveHandler={onSaveHandler}
        imageUrl={coverImg}
        aspect={ratio}
        cropSource={CropSource.STREAM}
        title={t.common['Edit Image section']}
        showRatioVariants={showRatioVariants}
      />
    );
  };

  return (
    <>
      {props.metadata?.displayTitles && renderTitles()}
      <Box className={cx(styles.show_cover, { [styles.space]: !!props.metadata?.displayTitles })}>
        {coverImg && renderCroppedImage(coverImg)}
        {ratio === Aspect.RATIO_26_10
          ? render26_10_CoverImageCard()
          : renderCoverImageCard(props.hasError)}
      </Box>
    </>
  );
};

export default ShowCoverSelection;
