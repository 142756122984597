import { Grid } from '@mui/material';
import React from 'react';
import { SectionVideo } from '../../model/academy';
import styles from '../StreamerAcademyVideo/StreamerAcademyVideo.module.scss';
import VideoCard from '../VideoCard/VideoCard';

interface Props {
  video: SectionVideo;
}

function EpisodeCard({ video }: Props) {
  return (
    <Grid item xs={6} className={styles.episode}>
      <VideoCard video={video} column />
    </Grid>
  );
}

export default EpisodeCard;
