import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserIsHSEEmployee } from '../../auth/state/authSlice';
import { OnboardingStatus } from '../../creators/model/creator';
import styles from '../../creators/pages/CreatorDetailsPage/CreatorDetailsProfile/CreatorDetailsProfile.module.scss';
import { resendWelcomeEmail } from '../../creators/state/creatorsSlice';

function getSteps() {
  return [
    'Freigabe durch HSE',
    'Login erfolgreich',
    'Profil vollständig',
    'Showprodukte bestellt',
    'Erste Show geplant',
    'Erfolgreich gestreamt',
  ];
}

const getActiveStep = (onboardingStatus: OnboardingStatus): number =>
  Object.values(onboardingStatus).filter(val => val).length;

interface OnboardingDetailsStatusModalProps {
  isOpen: boolean;
  creatorName: string;
  creatorId: string;
  onboardingStatus: OnboardingStatus;
  onCloseCallback: (isOpen: boolean) => void;
}

const OnboardingDetailsStatusModal = ({
  isOpen,
  creatorName,
  creatorId,
  onboardingStatus,
  onCloseCallback,
}: OnboardingDetailsStatusModalProps) => {
  const [open, setOpen] = useState(isOpen);
  const isHseEmployee = useSelector(selectUserIsHSEEmployee);
  const dispatch = useDispatch();
  const steps = getSteps();

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const onClose = () => {
    setOpen(false);
    onCloseCallback(false);
  };

  const getStepContent = (step: number): ReactNode | undefined => {
    if (step === 1 && isHseEmployee) {
      return (
        <Button
          className={styles.resendWelcomeMailBtn}
          variant="contained"
          color="primary"
          endIcon={<SendIcon />}
          fullWidth
          onClick={() => dispatch(resendWelcomeEmail(creatorId))}
        >
          Einladung erneut senden
        </Button>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {creatorName}
          <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ minWidth: 250 }}>
          <Stepper activeStep={getActiveStep(onboardingStatus)} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>{getStepContent(index)}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OnboardingDetailsStatusModal;
