import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { RootState } from '../../app/store';
import { Audience, PageableResponse } from '../../common/types';
import { DateRangeWithSelectedFilter } from '../../components/DateFilter/dateFilterTypes';
import { TableFilter } from '../../creators/state/creatorsSlice';
import { SurveyQueryParams, SurveyRequestType, Surveys } from '../types/types';

export const fetchSurveys = createAction<SurveyQueryParams>('posts/fetchSurveys');

export interface SurveysState extends PageableResponse {
  surveys: Surveys[];
  loading: boolean;
  queryParams: SurveyQueryParams;
  tableFilter?: TableFilter;
  dateFilter?: DateRangeWithSelectedFilter;
  audienceFilter?: Audience[];
}

export const surveysInitialState: SurveysState = {
  surveys: [],
  hits: 0,
  pages: 0,
  loading: false,
  queryParams: {
    pageSize: 10,
    page: 0,
    sortField: 'createdAt',
    sortOrder: 'desc',
    dateFrom: dayjs().set('year', 2022).format('YYYY-MM-DD'),
    dateTo: dayjs().format('YYYY-MM-DD'),
    audience: [],
    itemType: SurveyRequestType.ALL,
    writtenFeedbackOnly: false,
  },
  tableFilter: {
    pagination: {
      pageSize: 10,
    },
  },
};

export const surveysSlice = createSlice({
  name: 'surveys',
  initialState: surveysInitialState,
  reducers: {
    setSurveysLoading: (state: SurveysState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSurveys: (state: SurveysState, action: PayloadAction<Partial<SurveysState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setSurveysLoading, setSurveys } = surveysSlice.actions;
export const selectSurveys = (state: RootState) => state.surveys;

export default surveysSlice.reducer;
