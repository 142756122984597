import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { germanMoneyFormat } from '../../../utils/formatters/germanFormat/germanFormats';
import { selectInvoiceDetails, selectInvoiceDetailsCreator } from '../../state/paymentsSlice';
import { formatIBAN } from '../../utils/formatIBAN';
import styles from './InvoiceStreamerDetails.module.scss';

function InvoiceStreamerDetails() {
  const streamer = useSelector(selectInvoiceDetailsCreator);
  const invoice = useSelector(selectInvoiceDetails);

  return (
    <div>
      <div className={styles.title}>
        <strong className={styles.text_title}>Details zur Rechnung</strong>
      </div>
      <div className={styles.text_row}>
        <div className={styles.text_info}>
          <p className={styles.text}>Empfänger</p>
        </div>
        <div className={styles.text_info}>
          {streamer && (
            <strong className={styles.text}>
              {streamer.firstName} {streamer.lastName}
            </strong>
          )}
        </div>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.text_row}>
        <div className={styles.text_info}>
          <p className={styles.text}>Bankverbindung des Empfängers (IBAN)</p>
        </div>
        <div className={styles.text_info}>
          {streamer && streamer.iban && (
            <strong className={styles.text}>{formatIBAN(streamer.iban)}</strong>
          )}
        </div>
      </div>
      <Divider className={styles.divider} />
      {invoice && (
        <div className={styles.text_row}>
          <div className={styles.text_content}>
            <strong>Gesamtbetrag</strong>
          </div>
          <div className={styles.text_content}>
            <strong className={styles.price}>{germanMoneyFormat(invoice.gross)}</strong>
          </div>
        </div>
      )}
    </div>
  );
}

export default InvoiceStreamerDetails;
