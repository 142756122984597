import { IconWithText } from '@hse24/shared-components';
import { ReactElement } from 'react';
import styles from './Instruction.module.scss';

interface instructionProps {
  icon: ReactElement;
  iconText: string;
  instructionDescription: string;
  importantNote?: string;
  dataTestId?: string;
}

export const Instruction = (props: instructionProps) => {
  return (
    <div className={styles.instruction} data-testid={props.dataTestId}>
      <IconWithText textClassName={styles.icon_text} icon={props.icon}>
        <p>{props.iconText}</p>
      </IconWithText>
      <div className={styles.instruction_text}>
        {props.instructionDescription}
        <b>{props.importantNote}</b>
      </div>
    </div>
  );
};
