import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectActivationProcessError } from '../../../state/authSlice';

function ActivationError() {
  const error = useSelector(selectActivationProcessError);
  return (
    <Typography variant={'h3'}>
      {error?.detail || error?.message || 'Ups! Irgendwas ist schiefgelaufen!'}
    </Typography>
  );
}

export default ActivationError;
