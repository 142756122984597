import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Role } from '../../auth/role';
import { selectUserRoles } from '../../auth/state/authSlice';
import routePaths from '../../routes/routePaths';

const LandingPage = () => {
  const userRoles = useSelector(selectUserRoles);
  const history = useHistory();

  useEffect(() => {
    if (userRoles?.some(userRole => userRole === Role.STREAMER_CLASSIC)) {
      history.push(routePaths.creator.classicDashboard);
    }

    if (
      userRoles?.some(userRole => userRole === Role.STREAMER_HELLO || userRole === Role.INFLUENCER)
    ) {
      history.push(routePaths.creator.dashboard);
    }

    if (userRoles?.some(userRole => userRole === Role.AGENCY)) {
      history.push(routePaths.agency.creatorsOverview);
    }

    if (userRoles?.some(userRole => userRole === Role.HSE_EMPLOYEE)) {
      history.push(routePaths.hseEmployee.agenciesOverview);
    }
  }, [userRoles]);

  return <span>Landing page</span>;
};

export default LandingPage;
