import zxcvbn from 'zxcvbn';

interface StrengthLevelProps {
  color: string;
  label: string;
  percentage: number;
}

const strengthLevelProps: StrengthLevelProps[] = [
  {
    color: '#ff2600',
    label: 'Ihr Passwort enthält bekannte Schlüsselworte ⛔️',
    percentage: 25,
  },
  {
    color: '#ff2600',
    label: 'Ihr Passwort enthält bekannte Schlüsselworte ⛔️',
    percentage: 25,
  },
  {
    color: '#F57C00',
    label: 'Weiter so ⛔️',
    percentage: 50,
  },
  {
    color: '#aebe0b',
    label: 'Schon besser 🙂',
    percentage: 75,
  },
  {
    color: '#7fdc0b',
    label: 'Sehr sicher 💪',
    percentage: 100,
  },
];

export const getPasswordStrengthLevel = (password: string) => {
  return strengthLevelProps[zxcvbn(password).score];
};
