import { LocalStorageKeys } from '../constants/localStorageKeys';
import { sharedStorageService } from '../storage/sharedStorageService';
import Config from './config';
import { AvailableFlag } from './FeatureFlags';

export const featureFlagValue = (flag: AvailableFlag) => Config.env.flags[flag];

export const markInApp = (): void => {
  Config.env.flags[AvailableFlag.isInApp] = true;
  sharedStorageService.setItem(LocalStorageKeys.InApp, 'true');
};
