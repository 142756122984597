import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InputTextField from '../../../../components/InputTextField/InputTextField';
import { useIsMountedRef } from '../../../../shared/useIsMountedRef';
import { selectLoggedInCreator } from '../../../state/creatorsSlice';
import { profilePersonalDataKeys } from '../ProfilePersonalData';

const BicTextField = () => {
  const bic = useSelector(selectLoggedInCreator)?.bic;

  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { setFieldTouched, values, setFieldValue } = useFormikContext<any>();
  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (bic) {
      setFieldValue(profilePersonalDataKeys.bic, bic, false);
    }

    const timeout = setTimeout(() => {
      // Trigger validation of IBAN textfield only if IBAN has already been entered
      if (isMountedRef.current && values[profilePersonalDataKeys.iban] != '') {
        setFieldTouched(profilePersonalDataKeys.iban, true);
      }
    });

    return () => {
      clearTimeout(timeout);
    };
  }, [bic]);

  return (
    <InputTextField
      fieldName={'BIC'}
      label={'BIC'}
      value={bic || 'automatisch ausgefüllt'}
      disabled
    />
  );
};

export default BicTextField;
