import Icons, { SvgIcon } from '../../../../assets';
import Icon from '../../../../components/Icon/Icon';
import { PostUploadFileType } from '../../../model/post';

interface UploadStatusFileIconProps {
  fileType: PostUploadFileType;
}

export const UploadStatusFileIcon = (props: UploadStatusFileIconProps) => {
  let icon: SvgIcon;
  switch (props.fileType) {
    case PostUploadFileType.IMAGE:
      icon = Icons.photo;
      break;
    case PostUploadFileType.VIDEO:
      icon = Icons.play;
      break;
    case PostUploadFileType.FILE:
      icon = Icons.file;
      break;
  }

  return <Icon icon={icon} dataTestId={`${props.fileType}-icon`} height={14} width={14} />;
};
