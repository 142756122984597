import { Error } from '@hse24/shared-components';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import React, { BaseSyntheticEvent, useEffect } from 'react';
import styles from './TextAreaField.module.scss';

export interface TextFieldProps {
  fieldName: string;
  label: string;
  value: string | undefined;
  onChange?: (value?: string) => void;
  className?: string;
  disabled?: boolean;
  maxChars?: number;
  isSubmitClicked?: boolean;
  rows?: number;
}

const TextAreaField = ({
  fieldName,
  label,
  value,
  disabled,
  onChange,
  className,
  maxChars,
  isSubmitClicked,
  rows = 5,
}: TextFieldProps) => {
  const { values, setFieldValue, errors, handleChange, handleBlur, touched } =
    // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
    // eslint-disable-next-line
    useFormikContext<any>();

  useEffect(() => {
    setFieldValue(fieldName, value?.trim(), false);
  }, [value]);

  const onChangeEvent = (event: BaseSyntheticEvent) => {
    if (maxChars && event.currentTarget.value.length > maxChars) {
      return;
    }

    handleChange(event);
    onChange && onChange(event.currentTarget.value);
  };

  return (
    <div className={cx(className, styles.text_field, disabled && styles.disabled_text_field)}>
      <textarea
        className={styles.input}
        name={fieldName}
        placeholder={label}
        role="textbox"
        value={values[fieldName] ?? ''}
        onChange={onChangeEvent}
        onBlur={(e: BaseSyntheticEvent) => {
          setFieldValue(fieldName, e.currentTarget.value.trim());
          if (handleBlur) {
            handleBlur(e);
          }
        }}
        disabled={disabled}
        rows={rows}
      />
      <div className={styles.input_info}>
        {(touched[fieldName] || isSubmitClicked) && errors[fieldName] && (
          <Error error={errors[fieldName] as string} className={styles.error_message} />
        )}
        {maxChars && (
          <span className={styles.char_count}>
            {(values[fieldName] ?? '').length}/{maxChars}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextAreaField;
