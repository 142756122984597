import { Dialog } from '@mui/material';
import { setHours, setMinutes } from 'date-fns';
import { useState } from 'react';
import ModalTitle from '../../../../../../../../components/ModalTitle/ModalTitle';
import DayActions from '../../../DayActions/DayActions';
import DaySelectionPreview from '../../../DaySelectionPreview/DaySelectionPreview';
import { CustomTimerProps } from '../CustomTimer';
import CustomTimerForm from '../CustomTimerForm/CustomTimerForm';
import styles from './MobileCustomTimerForm.module.scss';

interface MobileCustomTimer extends CustomTimerProps {
  setIsSelectingCustomTime: (isSelecting: boolean) => void;
}

const MobileCustomTimerForm = (props: MobileCustomTimer) => {
  const [isDialogOpen, setIsDialogOpen] = useState(true);
  const [isFormValid, setFormValid] = useState(false);
  const [selectedMinutes, setSelectedMinutes] = useState('');
  const [selectedHours, setSelectedHours] = useState('');
  const [selectedDateShow, setSelectedDateShow] = useState(props.showDate);

  const handleModalClosed = () => {
    setIsDialogOpen(false);
    props.setIsSelectingCustomTime(false);
  };
  const handleValid = () => {
    if (isFormValid) {
      props.updateShowDate(setHours(setMinutes(props.showDate, +selectedMinutes), +selectedHours));
      props.setHasSelectedValidTime(true);
      handleModalClosed();
    }
  };
  const handleCancel = () => {
    handleModalClosed();
  };
  const handleHoursChange = (hours: string) => {
    setSelectedHours(hours);
    setSelectedDateShow(setHours(selectedDateShow, +hours));
  };

  const handleMinutesChange = (minutes: string) => {
    setSelectedMinutes(minutes);
    setSelectedDateShow(setMinutes(selectedDateShow, +minutes));
  };

  return (
    <Dialog
      fullWidth
      fullScreen={true}
      open={isDialogOpen}
      maxWidth="sm"
      data-testid={'time-selector-modal'}
    >
      <ModalTitle title="Manuelle Zeitauswahl" handleModalClose={handleModalClosed} />
      <div className={styles.content}>
        <div className={styles.current_day}>
          <DaySelectionPreview day={props.showDate} shouldDisplayFullDate={false} />
        </div>
        <CustomTimerForm
          shouldUpdateValueOnChange={false}
          setIsFormValid={setFormValid}
          onHourChange={handleHoursChange}
          onMinutesChange={handleMinutesChange}
          {...props}
        />
        <DayActions
          className={styles.actions}
          isValid={isFormValid}
          showDate={selectedDateShow}
          handleValid={handleValid}
          handleCancel={handleCancel}
          shouldDisplayCancelButton={true}
        ></DayActions>
      </div>
    </Dialog>
  );
};

export default MobileCustomTimerForm;
