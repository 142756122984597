import { Box, Typography } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Audience, Platform } from '../../../common/types';
import AudienceBadge from '../../../components/AudienceBadge/AudienceBadge';
import AudienceFilter from '../../../components/AudienceFilter/AudienceFilter';
import DateFilterInPast from '../../../components/DateFilter/DateFilterInPast/DateFilterInPast';
import { DateRangeWithSelectedFilter } from '../../../components/DateFilter/dateFilterTypes';
import StyledDialog from '../../../components/StyledDialog/StyledDialog';
import SwitchWithTooltip from '../../../components/SwitchWithTooltip/SwitchWithTooltip';
import Config from '../../../config/config';
import t from '../../../constants/translation';
import ServerSideDataGrid, {
  Pageable,
} from '../../../creators/components/DataGridTable/ServerSideDataGrid';
import { EUROPE_BERLIN_TIMEZONE } from '../../../shows/constants';
import { fetchSurveys, selectSurveys, setSurveys } from '../../state/surveysSlice';
import {
  SurveyContentType,
  SurveyQueryParams,
  SurveyRequestType,
  SurveyShowStatus,
  SurveysSource,
} from '../../types/types';
import FeedbackText from '../FeedbackText/FeedbackText';
import PlatformBadge from '../PlatformBadge/PlatformBadge';
import SurveyItemTypeFilter from '../SurveyItemTypeFilter/SurveyItemTypeFilter';
import styles from './SurveysTable.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

export const mapQueryParamsToPageable = (queryData: SurveyQueryParams): Pageable => ({
  page: queryData.page,
  pageSize: queryData.pageSize,
  sortField: queryData.sortField,
  sortOrder: queryData.sortOrder,
  searchTerm: queryData.searchTerm,
});
function SurveysTable() {
  const dispatch = useDispatch();

  const { surveys, loading, queryParams, dateFilter, hits, audienceFilter } =
    useSelector(selectSurveys);

  const [surveyView, setSurveyView] = useState<GridRowParams | null>();
  useEffect(() => {
    dispatch(fetchSurveys(queryParams));
  }, [queryParams]);
  const columns: GridColDef[] = [
    {
      field: 'show_status',
      headerName: 'Typ',
      renderCell: ({ row }) => (
        <Typography sx={{ fontSize: 14 }}>
          {row.contentType == SurveyContentType.POST
            ? t.admins.survey.Post
            : row.showStatus == SurveyShowStatus.LIVE
            ? t.admins.survey.Livestream
            : t.admins.survey.Recording}
        </Typography>
      ),
      minWidth: 124,
      valueGetter: ({ row }) =>
        row.contentType == SurveyContentType.POST
          ? t.admins.survey.Post
          : row.showStatus == SurveyShowStatus.LIVE
          ? t.admins.survey.Livestream
          : t.admins.survey.Recording,
    },
    {
      field: 'contentCreatorName',
      headerName: 'Streamer',
      sortable: false,
      flex: 1,
    },
    {
      field: 'contentDescription',
      headerName: 'Stream',
      flex: 2,
      sortable: false,
    },
    {
      field: 'createdAt',
      flex: 1,
      headerName: 'Datum',
      valueFormatter: ({ value }) =>
        dayjs(value).tz(EUROPE_BERLIN_TIMEZONE).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: 'source',
      headerName: 'Audience',
      renderCell: ({ value }) => <AudienceBadge audience={[value]} />,
    },
    {
      field: 'platform',
      headerName: 'Plattform',
      renderCell: ({ row }) => <PlatformBadge survey={row}></PlatformBadge>,
      valueGetter: ({ row }) =>
        row.source == SurveysSource.HELLO && row.deviceType != undefined
          ? row.deviceType.toString().split('_').join(' ')
          : row.platform,
    },
    {
      field: 'score',
      headerName: t.admins.survey.Score,
      flex: 1,
      align: 'center',
    },
    {
      field: 'feedback',
      headerName: 'Feedback',
      flex: 2,
      renderCell: ({ value }) => <FeedbackText text={value} />,
    },
  ];

  const onRowClick = (rowParams: GridRowParams) => {
    setSurveyView(rowParams);
  };

  const pageableCallback = (pageable: Pageable) => {
    const newQueryParams: SurveyQueryParams = {
      dateFrom: queryParams.dateFrom,
      dateTo: queryParams.dateTo,
      pageSize: pageable.pageSize,
      page: pageable.page,
      sortField: pageable.sortField,
      sortOrder: pageable.sortOrder,
      searchTerm: pageable.searchTerm,
      audience: queryParams.audience,
      itemType: queryParams.itemType,
      writtenFeedbackOnly: queryParams.writtenFeedbackOnly,
      platform: queryParams.platform,
    };
    if (!_.isEqual(queryParams, newQueryParams))
      dispatch(setSurveys({ queryParams: newQueryParams }));
  };

  const onDateFiltersChange = (dateRangeWithSelectedFilter: DateRangeWithSelectedFilter) => {
    dispatch(
      setSurveys({
        queryParams: {
          ...queryParams,
          dateTo: dateRangeWithSelectedFilter.endDate,
          dateFrom: dateRangeWithSelectedFilter.startDate,
        },
      })
    );
  };

  const onSwitchWrittenFeedbackOnlyToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    return dispatch(
      setSurveys({
        queryParams: {
          ...queryParams,
          writtenFeedbackOnly: event.target.checked,
        },
      })
    );
  };

  const onAudienceFilterChange = (audience: Audience[], classicSource?: Platform[]) => {
    dispatch(
      setSurveys({
        queryParams: {
          ...queryParams,
          audience: audience,
          platform: classicSource && classicSource?.length == 1 ? classicSource[0] : undefined,
        },
      })
    );
  };

  const onItemTypeFilterChange = (itemType: SurveyRequestType) => {
    dispatch(
      setSurveys({
        queryParams: {
          ...queryParams,
          itemType,
        },
      })
    );
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={3} mt={2}>
      <Box className={styles.table_filter}>
        <Box>
          <DateFilterInPast
            onDateFiltersChange={onDateFiltersChange}
            selectedDateFrom={dateFilter?.startDate}
            selectedDateTo={dateFilter?.endDate}
            selectedFilter={dateFilter?.dateFilter}
          />
        </Box>

        <Box>
          <Typography className={styles.filter_heading} variant={'h4'} mb={2}>
            {t.admins.survey.Type}
          </Typography>
          <SurveyItemTypeFilter
            initialValue={queryParams.itemType}
            onItemTypeFilterChange={onItemTypeFilterChange}
          />
        </Box>
        <Box>
          <Typography className={styles.filter_heading} variant={'h4'} mb={2}>
            {t.common.Audience}
          </Typography>
          <AudienceFilter
            onSelect={onAudienceFilterChange}
            audienceFilter={audienceFilter}
            useBadges={false}
          />
        </Box>
      </Box>

      <div>
        <Box display="flex" justifyContent="flex-end" data-testid="written-feedback-only" mb={5}>
          <div className={styles.toggle}>
            <SwitchWithTooltip
              checked={queryParams.writtenFeedbackOnly}
              onChange={onSwitchWrittenFeedbackOnlyToggle}
              labelText={t.admins.survey['Only display text feedback']}
            />
          </div>
        </Box>

        <ServerSideDataGrid
          columns={columns}
          rows={surveys}
          loading={loading}
          initialPageSize={10}
          rowsPerPageOptions={[10, 50, 100, 1000]}
          onRowClick={onRowClick}
          sx={{
            border: '1px solid #dde1e7',
            borderRadius: '8px',
          }}
          autoHeight
          quickFilterPlaceholder={'Suchen...'}
          updatePageableCallback={pageableCallback}
          initialPageable={mapQueryParamsToPageable(queryParams)}
          enableColumnSelector
          isExportEnabled
          rowCount={hits}
        />
        {surveyView && (
          <StyledDialog
            dialogProps={{
              open: !!surveyView,
            }}
            close={() => setSurveyView(null)}
            title={'Survey'}
            body={
              <Box>
                <Box>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    {t.admins.survey.Streamer}
                  </Typography>
                  <Typography variant={'subtitle2'} gutterBottom>
                    {surveyView.row.contentCreatorName}
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    {surveyView.row.contentType == SurveyContentType.POST
                      ? t.admins.survey.Post
                      : surveyView.row.showStatus == SurveyShowStatus.LIVE
                      ? t.admins.survey.Livestream
                      : t.admins.survey.Recording}
                  </Typography>
                  <Typography variant={'subtitle2'} gutterBottom>
                    {surveyView.row.contentDescription}
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    Datum
                  </Typography>
                  <Typography variant={'subtitle2'}>
                    {dayjs(surveyView.row.createdAt).format('DD.MM.YYYY  hh:mm')}
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    Source
                  </Typography>
                  <Typography variant={'subtitle2'} gutterBottom>
                    <AudienceBadge audience={[surveyView.row.source]} />
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    {t.admins.survey.Score}
                  </Typography>
                  <Typography variant={'subtitle2'} gutterBottom>
                    {surveyView.row.score}
                  </Typography>
                  <Typography variant={'body1'} fontWeight={'bold'}>
                    Feedback
                  </Typography>
                  <Typography variant={'subtitle2'} gutterBottom>
                    {surveyView.row.feedback}
                  </Typography>

                  <Typography variant={'body1'} fontWeight={'bold'}>
                    {t.common['Recording link']}
                  </Typography>
                  <Typography variant={'subtitle2'} gutterBottom>
                    <a
                      className={styles.link}
                      href={`${Config.env.baseUrl}/wsv/shows/${surveyView.row.itemId}`}
                    >
                      {surveyView.row.itemId}
                    </a>
                  </Typography>
                </Box>
              </Box>
            }
          />
        )}
      </div>
    </Box>
  );
}

export default SurveysTable;
