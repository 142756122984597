import { useParams } from 'react-router-dom';
import PageContainer from '../../../../layout/PageContainer/PageContainer';
import theme from '../../../../theme/theme.module.scss';
import AmaDisplay from '../../components/AmaDisplay/AmaDisplay';

const AmaDisplayPage = () => {
  const { amaId: id, showId } = useParams<{ amaId: string; showId: string }>();
  if (!id || !showId) return null;

  return (
    <PageContainer
      fullHeight
      backgroundColor={theme.whiteLilac}
      pageToRender={<AmaDisplay id={id} showId={showId} />}
    />
  );
};

export default AmaDisplayPage;
