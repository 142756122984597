import { addDays, endOfMonth, startOfMonth, subDays } from 'date-fns';
import { DateFilterMode, DateFilterTypes } from './dateFilterTypes';

export const resolveDateRange = (
  selectedFilter: DateFilterTypes,
  dateFilterMode: DateFilterMode,
  dateFrom?: string,
  dateTo?: string
): { dateFrom: Date; dateTo: Date } => {
  switch (selectedFilter) {
    case DateFilterTypes.CUSTOM:
      return !dateFrom || !dateTo
        ? {
            dateFrom:
              dateFilterMode === DateFilterMode.PAST ? startOfMonth(Date.now()) : new Date(),
            dateTo: dateFilterMode === DateFilterMode.PAST ? new Date() : endOfMonth(Date.now()),
          }
        : {
            dateFrom: new Date(dateFrom),
            dateTo: new Date(dateTo),
          };
    case DateFilterTypes.MONTH:
      return {
        dateFrom: dateFilterMode === DateFilterMode.PAST ? startOfMonth(Date.now()) : new Date(),
        dateTo: dateFilterMode === DateFilterMode.PAST ? new Date() : endOfMonth(Date.now()),
      };
    case DateFilterTypes.TODAY:
      return { dateFrom: new Date(), dateTo: new Date() };
    case DateFilterTypes.YESTERDAY:
      return { dateFrom: subDays(Date.now(), 1), dateTo: subDays(Date.now(), 1) };
    case DateFilterTypes.TOMORROW:
      return { dateFrom: addDays(Date.now(), 1), dateTo: addDays(Date.now(), 1) };
    case DateFilterTypes.LAST_7_DAYS:
      return { dateFrom: subDays(Date.now(), 7), dateTo: subDays(Date.now(), 1) };
    case DateFilterTypes.LAST_14_DAYS:
      return { dateFrom: subDays(Date.now(), 14), dateTo: subDays(Date.now(), 1) };
    case DateFilterTypes.LAST_28_DAYS:
      return { dateFrom: subDays(Date.now(), 28), dateTo: subDays(Date.now(), 1) };
    case DateFilterTypes.NEXT_7_DAYS:
      return { dateFrom: new Date(), dateTo: addDays(Date.now(), 7) };
    case DateFilterTypes.NEXT_14_DAYS:
      return { dateFrom: new Date(), dateTo: addDays(Date.now(), 14) };
    case DateFilterTypes.NEXT_28_DAYS:
      return { dateFrom: new Date(), dateTo: addDays(Date.now(), 28) };
  }
};
