import { call, put, takeLatest } from 'redux-saga/effects';
import { BasketItemsResponse } from '../../shows/api/showsRequestResponse';
import { basketApi } from '../api/basketApi';
import { CustomerCreditAmountResponse } from '../api/CustomerCreditAmountResponse';
import { InvoiceResponse } from '../api/InvoiceResponse';
import {
  fetchBasketCount,
  fetchBudgetAmount,
  setBasketIsLoading,
  setBasketItemCount,
  setBudgetAmount,
  setBudgetAmountIsLoading,
} from './basketSlice';

export function* fetchBasketItemsCountHandler() {
  try {
    yield put(setBasketIsLoading(true));
    const basketItemsResponse: BasketItemsResponse = yield call(basketApi.fetchBasketItems);
    yield put(setBasketItemCount(basketItemsResponse.items.length));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setBasketIsLoading(false));
  }
}

export function* getBudgetHandler() {
  try {
    yield put(setBudgetAmountIsLoading(true));
    const invoiceResponse: InvoiceResponse = yield call(basketApi.getOpenInvoices);
    const creditAmountResponse: CustomerCreditAmountResponse = yield call(
      basketApi.getCreditAmount
    );

    if (invoiceResponse.balance && creditAmountResponse.creditAmount) {
      yield put(
        setBudgetAmount({
          amount: creditAmountResponse.creditAmount.value,
          currency: creditAmountResponse.creditAmount.currency,
          initialAmount: creditAmountResponse.creditAmount.value + invoiceResponse.balance.amount,
        })
      );
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(setBudgetAmountIsLoading(false));
  }
}

export function* watcherBasketSagas() {
  yield takeLatest(fetchBasketCount.type, fetchBasketItemsCountHandler);
  yield takeLatest(fetchBudgetAmount.type, getBudgetHandler);
}
