import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  collapseOnboarding,
  selectOnboardingDialogCollapsed,
} from '../../notifications/state/notificationsSlice';
import themeVariables from '../../theme/theme.module.scss';

const useStyles = () => {
  const madeStyles = makeStyles((theme: Theme) =>
    createStyles({
      container: {
        background: themeVariables.brightBlue,
        border: '1px solid ' + themeVariables.skyBlue,
        borderRadius: themeVariables.buttonBorderRadius,
        padding: '1.3rem 1.6rem 1rem 1rem',
        marginBottom: '1rem',
      },
      title: {
        margin: '0 0.3rem 2rem 0.3rem',
        fontSize: '1.125rem',
        fontWeight: 700,
      },
      step: {
        textTransform: 'uppercase',
        marginLeft: '0.3rem',
        marginBottom: '1rem',
      },
      subtitle: {
        marginTop: '0.1rem',
        marginBottom: '0.5rem',
        fontWeight: 700,
        fontStyle: 'normal',
      },
      message: {
        width: 'auto',
        margin: '0.25rem 0 1rem 0',
        '& a': {
          color: themeVariables.skyBlue,
          fontWeight: 700,
        },
        '& span': {
          color: themeVariables.skyBlue,
          fontWeight: 700,
        },
        '& b': {
          fontWeight: 600,
          fontStyle: 'italic',
        },
      },
      icon: {
        display: 'inline-block',
        color: themeVariables.softBlue,
        fontSize: '1.75rem',
        marginRight: '0.5rem',
      },
      button: {
        width: '100%',
        background: themeVariables.skyBlue,
        color: 'white',
        boxShadow: 'none',
        textTransform: 'none',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 700,
        fontStyle: 'normal',
        padding: '0.5rem 3.75rem',
        margin: '1rem 0 0.75rem 0.3rem',
        '&:hover': {
          background: themeVariables.skyBlue,
        },
      },
      arrowOpen: {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      arrowClose: {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
      },
      [theme.breakpoints.up('sm')]: {
        button: {
          width: 'auto',
        },
      },
    })
  );
  return madeStyles();
};

interface Steps {
  current: number;
  total: number;
}

interface Button {
  title: string;
  action: () => void;
}

export interface OnboardingHintDialogueProps {
  title: string;
  steps?: Steps;
  subtitle?: string;
  message?: string | JSX.Element;
  button?: Button;
}

const OnboardingHintDialogue = ({
  title,
  steps,
  subtitle,
  message,
  button,
}: OnboardingHintDialogueProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const expanded = !useAppSelector(selectOnboardingDialogCollapsed);

  const handleToggle = () => {
    dispatch(collapseOnboarding(expanded));
  };

  const renderCollapsedHeader = (steps?: Steps) => (
    <Grid className={classes.arrowClose} item container xs={12} md={12}>
      {steps && (
        <Typography className={classes.step}>
          Schritt <b>{steps.current}</b>/{steps.total}
        </Typography>
      )}
      {expanded ? (
        <KeyboardArrowUpIcon data-testid="arrow-open" onClick={handleToggle} />
      ) : (
        <KeyboardArrowDownIcon data-testid="arrow-close" onClick={handleToggle} />
      )}
    </Grid>
  );

  const renderExpandedHeader = () => (
    <Grid className={classes.arrowOpen} item container xs={12} md={12}>
      {expanded ? (
        <KeyboardArrowUpIcon data-testid="arrow-open" onClick={handleToggle} />
      ) : (
        <KeyboardArrowDownIcon data-testid="arrow-close" onClick={handleToggle} />
      )}
    </Grid>
  );

  const renderExpandedTitle = (title: string) => (
    <Grid item xs={12} md={4}>
      <Typography
        data-testid={'expended-title'}
        className={classes.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    </Grid>
  );

  const renderExpandedSteps = (steps: Steps) => (
    <Grid item xs={12} md={12}>
      <Typography className={classes.step}>
        Schritt <b>{steps.current}</b>/{steps.total}
      </Typography>
    </Grid>
  );

  const renderIcon = () => (
    <Grid item>
      <FiberManualRecordIcon className={classes.icon} />
    </Grid>
  );

  const renderSubTitle = (subtitle: string) => (
    <Typography data-testid="subtitle" className={classes.subtitle}>
      {subtitle}
    </Typography>
  );

  const renderMessage = (message: string | JSX.Element) =>
    typeof message === 'string' ? (
      <Typography
        data-testid={'message'}
        className={classes.message}
        dangerouslySetInnerHTML={{ __html: message }}
      />
    ) : (
      message
    );

  const renderExpandedButton = (button: Button) => (
    <Grid item xs={12} md={12}>
      <Button
        data-testid="onboarding-button"
        className={classes.button}
        onClick={button.action}
        variant="contained"
      >
        {button.title}
      </Button>
    </Grid>
  );

  return (
    <Grid container className={classes.container}>
      {expanded ? renderExpandedHeader() : renderCollapsedHeader(steps)}
      <Grid item container xs={12} md={12}>
        {expanded && renderExpandedTitle(title)}
        <Grid item container xs={12} md={8}>
          <Grid item container xs={12} md={12}>
            {expanded && steps && renderExpandedSteps(steps)}
            <Grid item container xs={12} md={12} direction={'row'} wrap={'nowrap'}>
              {renderIcon()}
              <Grid item>
                {subtitle && renderSubTitle(subtitle)}
                {expanded && message && renderMessage(message)}
              </Grid>
            </Grid>
          </Grid>
          {expanded && button && renderExpandedButton(button)}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OnboardingHintDialogue;
