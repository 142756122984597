import { CloseOutlined } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../config/config';
import t from '../../../constants/translation';
import {
  acceptMarketingConsent,
  selectLoggedInCreator,
} from '../../../creators/state/creatorsSlice';
import { trackMarketingConsent } from '../../../middlewares/reduxBeaconMiddleware/actions';
import { MarketingConsentResults } from '../../../middlewares/reduxBeaconMiddleware/types';
import { selectConsentDialogOpen } from '../../state/notificationsSlice';
import styles from './ConsentDialog.module.scss';

const ConsentDialog = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const showDialog = useSelector(selectConsentDialogOpen);
  const creator = useSelector(selectLoggedInCreator);
  const onCloseHandler = () => {
    dispatch(trackMarketingConsent(MarketingConsentResults.SKIP_MARKETING_CONSENT));
    dispatch(acceptMarketingConsent(false));
  };

  const onDennieHandler = () => {
    dispatch(trackMarketingConsent(MarketingConsentResults.DENIED_MARKETING_CONSENT));
    dispatch(acceptMarketingConsent(false));
  };

  const onAcceptMarketingConsent = () => {
    dispatch(trackMarketingConsent(MarketingConsentResults.ACCEPT_MARKETING_CONSENT));
    dispatch(acceptMarketingConsent(true));
  };

  return (
    <Dialog open={showDialog} fullScreen={isMobile} fullWidth={isMobile} data-testid={'dialog'}>
      <Box className={styles.dialog_content}>
        <Box className={styles.dialog_header}>
          <IconButton onClick={onCloseHandler}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <Box className={styles.dialog_body}>
          <Box mb={'2rem'}>
            <Typography variant={'h3'} component={'div'}>
              {t.creators['We need your help!💪']}
            </Typography>
          </Box>
          <Box mb={'1rem'}>
            <Typography variant={'h3'}>
              {t.creators['Hello']} {creator?.name}
            </Typography>
          </Box>
          <Box mb={'2rem'}>
            <Typography variant={'body1'}>
              Wir wollen dich bei deiner Reise als Streamer unterstützen und das Nutzererlebnis
              stetig verbessern. Dafür benötigen wir deine Mithilfe und würden dich gerne
              gelegentlich für Umfragen per E-Mail kontaktieren.
            </Typography>
          </Box>
          <Box mb={'2rem'}>
            <Typography variant={'body1'}>
              Höre als erstes von neuen Features und gestalte HELLO aktiv mit.
            </Typography>
            <Typography variant={'body1'}>Let&apos;s rock this together! 🚀</Typography>
          </Box>
          <Box mb={'2rem'}>
            <Typography variant={'body1'}>Ist das für dich in Ordnung?</Typography>
          </Box>
          <Box>
            <Typography className={styles.small_text} variant={'caption'}>
              Deine Einwilligung gilt auch für Änderungen der Kontaktdaten. Du kannst deine
              Einwilligung jederzeit mit Wirkung für die Zukunft z.B. per Mail widerrufen.{' '}
              <a
                className={styles.anchor}
                href={`${Config.env.streamersPortalUrl}/privacy-policy`}
                rel="noreferrer"
                target="_blank"
              >
                {t.common['Privacy Policy']}
              </a>
            </Typography>
          </Box>
        </Box>
        <Box className={styles.dialog_actions}>
          <Box className={styles.button}>
            <Button onClick={onDennieHandler} variant={'outlined'}>
              {t.common['No']}
            </Button>
          </Box>
          <Box className={styles.button}>
            <Button onClick={onAcceptMarketingConsent} variant={'contained'} disableElevation>
              {t.common['Yes']}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConsentDialog;
