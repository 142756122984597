import React from 'react';
/*eslint-disable-next-line @typescript-eslint/no-explicit-any*/
export const useClickOutside = (ref: any, callback: () => void) => {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any*/
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};
