import PersonIcon from '@mui/icons-material/Person';
import { Avatar } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectLoggedInCreator } from '../../creators/state/creatorsSlice';
import { getUrlWithImageManagerParams } from '../../utils/media';

interface Props {
  size?: number;
  forBottomNavbar?: boolean;
}

const ProfileImageAvatar = ({ size, forBottomNavbar }: Props) => {
  const loggedInStreamer = useSelector(selectLoggedInCreator);

  return (
    <Avatar
      src={
        loggedInStreamer?.profileImageUrl &&
        getUrlWithImageManagerParams(loggedInStreamer?.profileImageUrl, 170)
      }
      alt={loggedInStreamer?.name}
      sx={{
        width: size,
        height: size,
        border: forBottomNavbar ? '1.25px solid #081F3E' : undefined,
        boxSizing: 'content-box',
        margin: '3px',
      }}
      data-testid="profile-image-avatar"
    >
      <PersonIcon fontSize={forBottomNavbar ? 'small' : 'large'} />
    </Avatar>
  );
};

export default ProfileImageAvatar;
