import { Link } from '@mui/material';
import styles from './RecaptchaInfo.module.scss';

export const URL_PRIVACY = 'https://policies.google.com/privacy';
export const URL_TERMS = 'https://policies.google.com/terms';

const renderLink = (text: string, url: string) => (
  <Link color={'inherit'} variant={'inherit'} href={url} target={'_blank'} rel={'noreferrer'}>
    {text}
  </Link>
);

const RecaptchaInfo = () => (
  <p className={styles.recaptcha}>
    Diese Website ist durch reCAPTCHA geschützt und es gelten insoweit die{' '}
    {renderLink('Datenschutzbestimmungen', URL_PRIVACY)} und{' '}
    {renderLink('Nutzungsbedingungen', URL_TERMS)} von Google.
  </p>
);

export default RecaptchaInfo;
