import { useMemo } from 'react';
import { BudgetAmount } from '../../common/types';

const useBudgetLabel = (budgetAmount?: BudgetAmount) => {
  const labelStyle = useMemo(() => {
    if (!budgetAmount) {
      return {};
    }

    const percentage = (budgetAmount.amount / budgetAmount.initialAmount) * 100;
    let color = '';

    if (percentage > 25) {
      color = '#3E7F24';
    } else if (percentage > 0) {
      color = '#ED6C02';
    } else {
      color = '#BE0A14';
    }

    return { color };
  }, [budgetAmount]);

  return labelStyle;
};

export default useBudgetLabel;
