import { Theme, useMediaQuery } from '@mui/material';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  TooltipItem,
} from 'chart.js';
import { useEffect, useState } from 'react';
import Chart from '../Chart/Chart';

export type BarChartData = {
  x: string;
  y: number;
};

interface NewBarChartProps {
  chartData: Array<BarChartData>;
  label: string;
  /**
   * To change the text that displays in a tooltip for a given data point
   * @see https://www.chartjs.org/docs/latest/configuration/tooltip.html#label-callback
   */
  tooltipLabelFormatter: (numberToFormat: number) => string | string[];
  /**
   * To change the number representation of a tick value in the y axis and convert it to a string format
   * @see https://www.chartjs.org/docs/latest/axes/labelling.html#creating-custom-tick-formats
   */
  yTickValueFormatter: (numberToFormat: number) => string;
  summary?: string;
}

type BarChartType = 'bar';

ChartJS.register(BarElement, BarController, Legend, Title, Tooltip, CategoryScale, LinearScale);

const BarChart = ({
  chartData,
  label,
  tooltipLabelFormatter,
  yTickValueFormatter,
  summary,
}: NewBarChartProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const options: ChartOptions<BarChartType> = {
    responsive: true,
    aspectRatio: isMobile ? 1 : 2,
    scales: {
      y: {
        ticks: {
          callback: function (value: string | number) {
            return yTickValueFormatter(Number(value));
          },
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: TooltipItem<BarChartType>) {
            return tooltipLabelFormatter(Number(context.parsed.y));
          },
        },
      },
    },
  };

  const labels = chartData.map(data => data.x);

  const data: ChartData<BarChartType, number[], string> = {
    labels,
    datasets: [
      {
        label,
        data: chartData.map(data => data.y),
        backgroundColor: '#ff3c28', // this color is defined in the scss variables: $brand-orange
      },
    ],
  };

  const [chartOptions, setChartOptions] = useState<ChartOptions<BarChartType>>(options);

  useEffect(() => {
    // Initially mui hook useMediaQuery returns false even if mobile view is being active from the beginning
    setChartOptions({ ...options, aspectRatio: isMobile ? 1 : 2 });
  }, [isMobile]);

  return <Chart data={data} type={'bar'} chartOptions={chartOptions} summary={summary}></Chart>;
};

export default BarChart;
