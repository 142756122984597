import TvOffIcon from '@mui/icons-material/TvOff';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShowCardWithUpcomingShow from '../../../shows/component/StartingSoonShowsSlider/ShowCardWithUpcomingShow/ShowCardWithUpcomingShow';
import {
  getCreatorUpcomingShows,
  selectCreatorUpcomingShows,
  selectShowsAreBeingLoaded,
} from '../../../shows/state/showsSlice';
import UpcomingShowsLoading from '../UpcomingShowsLoading/UpcomingShowsLoading';
import styles from './CreateContentUpcomingShows.module.scss';

function CreateContentUpcomingShows() {
  const dispatch = useDispatch();
  const upcomingShows = useSelector(selectCreatorUpcomingShows);
  const loading = useSelector(selectShowsAreBeingLoaded);

  useEffect(() => {
    dispatch(
      getCreatorUpcomingShows({
        pageSize: 10,
        page: 0,
      })
    );
  }, [dispatch]);

  return (
    <>
      <div className={styles.container}>
        {loading ? (
          <div className={styles.shows_slider}>
            <UpcomingShowsLoading />
            <UpcomingShowsLoading />
            <UpcomingShowsLoading />
          </div>
        ) : upcomingShows.length > 0 ? (
          <div className={styles.shows_slider}>
            {upcomingShows.map(show => (
              <ShowCardWithUpcomingShow key={show.id} upcomingShow={show} />
            ))}
          </div>
        ) : (
          <Box className={styles.no_shows_card}>
            <Box>
              <TvOffIcon />
            </Box>
            <Box>
              <Typography variant={'body1'}>Noch keine geplanten Streams</Typography>
            </Box>
          </Box>
        )}
      </div>
    </>
  );
}

export default CreateContentUpcomingShows;
