import { ArrowIconLeft, ArrowIconRight } from '@hse24/shared-components';
import { Box } from '@mui/material';
import cx from 'classnames';
import { endOfWeek, startOfWeek } from 'date-fns';
import {
  formatDay,
  formatYear,
  germanMonth,
  isAfterToday,
  isWeekSpanningTwoMonths,
} from '../../../../../../utils/dates';
import styles from './WeekSelector.module.scss';

interface WeekSelectorProps {
  showDate: Date;
  addWeekFn: () => void;
  subtractWeekFn: () => void;
}

const WeekSelector = (props: WeekSelectorProps) => {
  const startOfTheWeek = startOfWeek(props.showDate, {
    weekStartsOn: 1,
  });
  const endOfTheWeek = endOfWeek(props.showDate, {
    weekStartsOn: 1,
  });
  const canGoToPreviousWeek = isAfterToday(startOfTheWeek);

  const previousWeekHandler = () => {
    if (canGoToPreviousWeek) {
      props.subtractWeekFn();
    }
  };

  return (
    <Box data-testid="week-selector" className={styles.week_container}>
      <div data-testid="previous-week-arrow" onClick={previousWeekHandler}>
        <ArrowIconLeft
          className={cx(styles.icon, { [styles.disabled_icon]: !canGoToPreviousWeek })}
          width="32px"
          height="32px"
        />
      </div>
      <p data-testid="current-week" className={styles.week}>
        {isWeekSpanningTwoMonths(startOfTheWeek, endOfTheWeek) ? (
          <>
            {`${formatDay(startOfTheWeek)}. ${germanMonth(startOfTheWeek)} - ${formatDay(
              endOfTheWeek
            )}. ${germanMonth(endOfTheWeek)} ${formatYear(props.showDate)}`}
          </>
        ) : (
          <>{`${formatDay(startOfTheWeek)}. - ${formatDay(endOfTheWeek)}. ${germanMonth(
            props.showDate
          )} ${formatYear(props.showDate)}`}</>
        )}
      </p>
      <div data-testid="next-week-arrow" className={styles.icon} onClick={props.addWeekFn}>
        <ArrowIconRight width="32px" height="32px" />
      </div>
    </Box>
  );
};

export default WeekSelector;
