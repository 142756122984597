import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HelpIcon from '@mui/icons-material/Help';
import { Box, IconButton, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CreatePostHeader.module.scss';

function CreatePostHeader({ title }: { title: string }) {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Box className={styles.appbar}>
      <Toolbar className={styles.toolbar}>
        <Box className={styles.header}>
          <Box onClick={handleGoBack}>
            <Box className={styles.title}>
              <IconButton>
                <ArrowBackIosIcon />
              </IconButton>
              <Box className={styles.text_left}>
                <Typography variant={'h6'} fontWeight={'bold'}>
                  {title}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.text_center}>
            <Typography variant={'h6'} fontWeight={'bold'}>
              {title}
            </Typography>
          </Box>
          <Box>
            <IconButton
              target="_blank"
              href="https://hellolive.zendesk.com/hc/de/categories/8703569770013-Fragen-und-Antworten"
            >
              <HelpIcon />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </Box>
  );
}

export default CreatePostHeader;
