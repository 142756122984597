import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import { Alert, AlertTitle, Slide } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeSuccessDialog, selectSucessDialogDetails } from '../../state/notificationsSlice';
import styles from './SuccessDialog.module.scss';

const SuccessDialog = () => {
  const successDialogDetails = useSelector(selectSucessDialogDetails);
  const isOpen = successDialogDetails?.toggle;
  const dispatch = useDispatch();
  let timeout: NodeJS.Timeout;

  useEffect(() => {
    if (isOpen) {
      timeout = setTimeout(() => {
        dispatch(closeSuccessDialog());
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen]);

  return (
    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
      <Alert
        className={styles.alert}
        severity="success"
        icon={<CheckCircleOutlined />}
        onClose={() => dispatch(closeSuccessDialog())}
        sx={{ bottom: { xs: 60 } }}
      >
        <AlertTitle className={styles.alert_title}>{successDialogDetails?.title}</AlertTitle>
        {successDialogDetails?.text}
      </Alert>
    </Slide>
  );
};

export default SuccessDialog;
