interface IProps {
  title: string;
  value: string;
}
function InsightData(props: IProps) {
  return (
    <div>
      <strong>{props.value}</strong>
      <h2>{props.title}</h2>
    </div>
  );
}

export default InsightData;
