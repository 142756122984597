import React from 'react';
import { featureFlagValue } from '../../config/featureFlagConfig';
import { AvailableFlag } from '../../config/FeatureFlags';
import styles from './DialogDragLine.module.scss';

const isIOS = () => /iPad|iPhone|iPod/.test(window.navigator.userAgent);

function DialogDragLine() {
  const isApp = featureFlagValue(AvailableFlag.isInApp);
  return isIOS() && isApp ? (
    <div className={styles.line} data-testid={'line'}>
      <div />
    </div>
  ) : null;
}

export default DialogDragLine;
