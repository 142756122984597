import { LoadingSpinner } from '@hse24/shared-components';
import { ChevronLeft } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { useAppSelector } from '../../../../app/hooks';
import SubmitButton from '../../../../components/Button/SubmitButton';
import routePaths from '../../../../routes/routePaths';
import { AmaUpdateRequest } from '../../api/amasRequestResponse';
import { selectAmaLoading } from '../../state/amasSlice';
import DeleteAmaButton from '../DeleteAmaButton/DeleteAmaButton';
import styles from './AmaManagementForm.module.scss';

type AmaManagementFormValues = AmaUpdateRequest;

const validationSchema = yup.object({
  request: yup.string().required(),
});

interface AmaManagementFormProps {
  id?: string;
  title: string;
  initialValues: AmaManagementFormValues;
  handleFormSubmit: (values: AmaManagementFormValues) => void;
  submitButton: {
    title: string;
    icon: ReactNode;
  };
  isUpdate: boolean;
}

const AmaManagementForm = ({
  id,
  title,
  initialValues,
  handleFormSubmit,
  submitButton,
  isUpdate,
}: AmaManagementFormProps) => {
  const history = useHistory();
  const isLoading = useAppSelector(selectAmaLoading);

  return (
    <div className={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={values => handleFormSubmit(values)}
        enableReinitialize={true}
      >
        {formik => (
          <Form className={styles.form}>
            <div className={styles.back}>
              <IconButton
                color={'secondary'}
                onClick={() => history.push(routePaths.creator.shows)}
              >
                <ChevronLeft />
              </IconButton>
              <h1>{title}</h1>
            </div>
            <TextField
              fullWidth
              multiline
              id={'request'}
              type={'text'}
              label={'Request'}
              variant={'outlined'}
              size={'small'}
              error={Boolean(formik.errors.request)}
              onChange={formik.handleChange}
              helperText={formik.errors.request}
              value={formik.values.request}
            />
            <SubmitButton
              fullWidth
              variant={'contained'}
              size={'small'}
              type={'submit'}
              disabled={isLoading || !formik.isValid}
              endIcon={!isLoading && submitButton.icon}
            >
              {!isLoading ? submitButton.title : <LoadingSpinner />}
            </SubmitButton>
            {isUpdate && id && <DeleteAmaButton id={id} />}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AmaManagementForm;
