const batchGenerator = function* <T>(items: T[], batchSize: number): Generator<T[], void> {
  let index = 0;

  while (index < items.length) {
    yield items.slice(index, index + batchSize);
    index += batchSize;
  }
};

export default batchGenerator;
