import { Box } from '@mui/material';
import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from '../ShowCard.module.scss';

interface ShowCardContentProps {
  children: ReactNode;
  className?: string;
}

const ShowCardContent = ({ children, className }: ShowCardContentProps) => {
  return (
    <Box className={styles.show_details_container}>
      <Box className={classNames(styles.show_content, className)}>
        <Box className={styles.content_wrapper}>{children}</Box>
      </Box>
    </Box>
  );
};

export default ShowCardContent;
