import { Dialog } from '@mui/material';
import { addWeeks, subWeeks } from 'date-fns';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isMobileBreakPoint } from '../../../../../common/mediaQueries';
import ModalTitle from '../../../../../components/ModalTitle/ModalTitle';
import { openConfirmationDialog } from '../../../../../notifications/state/notificationsSlice';
import { fetchAvailableShowTimeSlot } from '../../../../state/showsSlice';
import useFormChanged from '../../../../utils/useFormChanged';
import styles from './DateSelector.module.scss';
import DayActions from './DayActions/DayActions';
import DaySelectionPreview from './DaySelectionPreview/DaySelectionPreview';
import DaySelector from './DaySelector/DaySelector';
import TimeSelector from './TimeSelecor/TimeSelector';
import TimeZone from './TimeZone/TimeZone';
import WeekSelector from './WeekSelector/WeekSelector';

interface DataSelectorProps {
  plannedAt: Date;
  updatePlannedAt: (date: Date) => void;
  isDialogOpen: boolean;
  closeDialog: () => void;
  title: string;
}

const DateSelector = ({
  plannedAt,
  updatePlannedAt,
  isDialogOpen,
  closeDialog,
  title,
}: DataSelectorProps) => {
  const dispatch = useDispatch();
  const [showDate, setShowDate] = useState(plannedAt);
  const isMobileDevice = isMobileBreakPoint();
  const [hasSelectedValidTime, setHasSelectedValidTime] = useState(false);

  const isDateChanged = useFormChanged<string>(plannedAt.toISOString(), showDate.toISOString());

  const handleValid = () => {
    updatePlannedAt(showDate);
    closeDialog();
  };
  const dayChangeHandler = (date: Date) => {
    setShowDate(date);
    dispatch(fetchAvailableShowTimeSlot(date));
  };
  const addWeekHandler = (): Date => {
    const nextWeek = addWeeks(showDate, 1);
    setShowDate(nextWeek);
    dispatch(fetchAvailableShowTimeSlot(nextWeek));
    return nextWeek;
  };
  const subtractWeekHandler = (): Date => {
    const previousWeek = subWeeks(showDate, 1);
    setShowDate(previousWeek);
    dispatch(fetchAvailableShowTimeSlot(previousWeek));
    return previousWeek;
  };

  const handleCancel = () => {
    if (isDateChanged) {
      dispatch(
        openConfirmationDialog({
          title: 'Möchtest du deine Eingabe wirklich verwerfen?',
          contentText: 'Deine Änderungen werden nicht gespeichert.',
          submit: 'Abbrechen ohne speichern',
          actionToTrigger: closeDialog,
        })
      );
    } else {
      closeDialog();
    }
  };

  return (
    <Dialog
      PaperProps={{
        className: styles.modal,
      }}
      fullWidth
      fullScreen={isMobileDevice}
      open={isDialogOpen}
      maxWidth="sm"
      data-testid="time-selector-modal"
    >
      <ModalTitle title={title} handleModalClose={handleCancel} />
      <div className={styles.content}>
        <div className={styles.date_data}>
          <WeekSelector
            showDate={showDate}
            addWeekFn={addWeekHandler}
            subtractWeekFn={subtractWeekHandler}
          />
          <DaySelector showDate={showDate} updateShowDate={dayChangeHandler} />
          <DaySelectionPreview day={showDate} shouldDisplayFullDate={false} />
          <TimeSelector
            setHasSelectedValidTime={setHasSelectedValidTime}
            updateShowDate={setShowDate}
            showDate={showDate}
          />
          <TimeZone />
        </div>
        <DayActions
          className={styles.actions}
          showDate={showDate}
          handleValid={handleValid}
          handleCancel={handleCancel}
          isValid={hasSelectedValidTime}
          shouldDisplayCancelButton={!isMobileDevice}
        />
      </div>
    </Dialog>
  );
};

export default DateSelector;
