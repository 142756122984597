import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import routePaths from '../../../routes/routePaths';
import { authSteps } from '../../authSteps';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { selectAuthProgress, selectIsUserLogin } from '../../state/authSlice';

const AuthPage = () => {
  const history = useHistory();

  const step = useSelector(selectAuthProgress)?.step;
  const isLoggedIn = useSelector(selectIsUserLogin);
  const redirect = new URLSearchParams(history.location.search).get('redirect');

  useEffect(() => {
    if (isLoggedIn) {
      history.push(redirect ?? routePaths.landing);
    }
  }, [isLoggedIn]);

  return <AuthLayout stepInfo={authSteps.get(step)} />;
};

export default AuthPage;
