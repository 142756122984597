import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import ProtectedRoute from '../../auth/components/ProtectedRoute/ProtectedRoute';
import { selectUserIsAuthenticated, userLoginState } from '../../auth/state/authSlice';
import AmaOnboardingDialog from '../../components/AmaOnboardingDialog/AmaOnboardingDialog';
import CreateContent from '../../components/CreateContent/CreateContent';
import UnsupportedBrowserHint from '../../components/UnsupportedBrowserHint/UnsupportedBrowserHint';
import Config from '../../config/config';
import { markInApp } from '../../config/featureFlagConfig';
import { SessionStorageKeys } from '../../constants/sessionStorageKeys';
import { userCentricsConsentScriptDetails } from '../../constants/usercentricsScripts';
import Footer from '../../layout/Footer/Footer';
import Navbar from '../../layout/Navbar/Navbar';
import ConfirmationDialog from '../../notifications/components/ConfirmationDialog/ConfirmationDialog';
import ConsentDialog from '../../notifications/components/ConsentDialog/ConsentDialog';
import ErrorDialog from '../../notifications/components/ErrorDialog/ErrorDialog';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import DialogManager from '../../notifications/components/ManagedDialogs/DialogManager';
import ProgressDialog from '../../notifications/components/ProgressDialog/ProgressDialog';
import Snackbar from '../../notifications/components/Snackbar/Snackbar';
import SuccessDialog from '../../notifications/components/SuccessAlert/SuccessDialog';
import { RouteDetails } from '../../routes/routeDetails';
import { routes } from '../../routes/routes';
import { getCreatorArchivedShows } from '../../shows/state/showsSlice';
import { loadScript } from '../../utils/loadScript';
import { hiddenFooterPath, hiddenHeaderPath } from '../../utils/routeConfiguration';
import styles from './App.module.scss';

const TermsAndConditionsDialog = lazy(
  () => import('../../notifications/components/TermsAndConditionsDialog/TermsAndConditionsDialog')
);

const renderRoute = (route: RouteDetails) =>
  route.protected ? (
    <ProtectedRoute
      component={route.component}
      exact={route.exact}
      path={route.path}
      roles={route.roles}
      key={route.path}
    />
  ) : (
    <Route path={route.path} exact={route.exact} component={route.component} key={route.path} />
  );

const HIDE_NAVBAR_URL_PARAM = 'hideNavbar';
const HIDE_HEADER_URL_PARAM = 'hideHeader';
const IS_IN_APP = 'inApp';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const isHeaderHidden =
    hiddenHeaderPath(location.pathname) || queryParams.has(HIDE_HEADER_URL_PARAM);
  const isNavbarHidden = queryParams.has(HIDE_NAVBAR_URL_PARAM);
  const isFooterHidden = hiddenFooterPath(location.pathname);
  const isAuthenticated = useSelector(selectUserIsAuthenticated);

  useEffect(() => {
    dispatch(userLoginState());
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has(IS_IN_APP)) {
      markInApp();
      return;
    }

    if (Config.env.flags.isInApp) {
      return;
    }

    loadScript(
      userCentricsConsentScriptDetails.srcUrl,
      true,
      userCentricsConsentScriptDetails.id,
      userCentricsConsentScriptDetails.attributes
    );
  }, [location.search]);

  useEffect(() => {
    if (isAuthenticated) {
      const hasDisplayedArchivedShowsPopup = sessionStorage.getItem(
        SessionStorageKeys.HasDisplayedArchivedShowsPopup
      );

      if (!hasDisplayedArchivedShowsPopup) {
        dispatch(getCreatorArchivedShows());
        sessionStorage.setItem(SessionStorageKeys.HasDisplayedArchivedShowsPopup, 'true');
      }
    }
  }, [isAuthenticated]);

  return (
    <Suspense
      fallback={
        <div className={styles.loading_progress}>
          <CircularProgress />
        </div>
      }
    >
      <ConfirmationDialog />
      <ProgressDialog />
      <ErrorDialog />
      <DialogManager />
      <TermsAndConditionsDialog />
      <ConsentDialog />
      <Snackbar />
      <UnsupportedBrowserHint />
      <CreateContent />
      <AmaOnboardingDialog />
      <SuccessDialog />

      {!isHeaderHidden && <Navbar />}

      <main className={classNames({ [styles.main]: true, [styles.hideHeader]: isHeaderHidden })}>
        <Switch>
          {Object.values(routes).map(route => renderRoute(route))}
          <Route
            render={props => (
              <ErrorPageDialog {...props} errorDialogType={ErrorPageDialogType.PAGE_NOT_FOUND} />
            )}
          />
        </Switch>
      </main>

      {!isNavbarHidden && !isFooterHidden && (
        <Footer hideBottomHeader={hiddenHeaderPath(location.pathname)} />
      )}
    </Suspense>
  );
};
export default App;
