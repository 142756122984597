import { Info } from '@mui/icons-material';
import { ClickAwayListener, FormControlLabel, IconButton, Switch, Tooltip } from '@mui/material';
import { useState } from 'react';
import styles from './SwitchWithTooltip.module.scss';

export interface SwitchWithTooltipProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelText: string;
  tooltipText?: string;
}

const SwitchWithTooltip = ({
  checked,
  onChange,
  labelText,
  tooltipText,
}: SwitchWithTooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            color="primary"
            checked={checked}
            onChange={onChange}
            inputProps={{ 'aria-label': labelText }}
          />
        }
        label={labelText}
        className={styles.switchLabel}
      />
      {!!tooltipText && (
        <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setTooltipOpen(false)}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={tooltipText}
          >
            <IconButton
              onClick={() => setTooltipOpen(true)}
              aria-label="close-tooltip"
              size="large"
            >
              <Info fontSize={'small'} />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>
  );
};

export default SwitchWithTooltip;
