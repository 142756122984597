export function validateString(value, field) {
    if (typeof value !== 'string') {
        throw createValidationError(`${field}: expected string but got ${typeof value}: ${value}`);
    }
}
export function validateOptionalString(value, field) {
    const isValidOptionalString = typeof value === 'string' || value == null;
    if (!isValidOptionalString) {
        throw createValidationError(`${field}: expected string or null/undefined but got ${typeof value}: ${value}`);
    }
}
export function validateObject(value, field) {
    const isObject = value != null && typeof value === 'object';
    if (!isObject) {
        throw createValidationError(`${field}: expected object but got ${typeof value}: ${value}`);
    }
}
export function validateOptionalObject(value, field) {
    const isValidOptionalObject = typeof value === 'object' || value == null;
    if (!isValidOptionalObject) {
        throw createValidationError(`${field}: expected object but got ${typeof value}: ${value}`);
    }
}
export function validateOptionalDate(value, field) {
    const isValidOptionalDate = (value instanceof Date && value.toString() !== 'Invalid Date') || value == null;
    if (!isValidOptionalDate) {
        throw createValidationError(`${field}: expected Date but got ${typeof value}: ${value}`);
    }
}
export function createValidationError(message) {
    const error = new Error(message);
    error.name = 'ValidationError';
    return error;
}
