import { useEffect, useState } from 'react';

const useCountdown = (scheduledAt: string): string => {
  const [time, setTime] = useState(0);

  const formatTime = (elapsedTime: number): string => {
    const remainingTime = Math.max(0, 48 * 60 * 60 - Math.floor(elapsedTime / 1000));

    const hours = Math.floor(remainingTime / 3600);
    const minutes = Math.floor((remainingTime % 3600) / 60);
    const seconds = remainingTime % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    const initialElapsedTime = Date.now() - new Date(scheduledAt).getTime();
    setTime(initialElapsedTime);

    const intervalId = setInterval(() => {
      setTime(prevElapsedTime => prevElapsedTime + 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [scheduledAt]);

  return formatTime(time);
};

export default useCountdown;
