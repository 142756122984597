import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const addDecamelizeConfig = (config: AxiosRequestConfig) => {
  return {
    ...config,
    data: decamelizeKeys(config.data),
  };
};
const addCamelizeConfig = (config: AxiosRequestConfig) => {
  return {
    ...config,
    data: camelizeKeys(config.data),
  };
};

const createClient = (baseURL: string): AxiosInstance => {
  const client = axios.create({
    baseURL,
    headers: { 'Content-Type': 'application/json' },
  });

  client.interceptors.request.use(
    config => addDecamelizeConfig(config),
    err => {
      return Promise.reject(err);
    }
  );

  client.interceptors.response.use(
    config => addCamelizeConfig(config),
    err => {
      return Promise.reject(err);
    }
  );

  return client;
};

const authClient = { createClient };

export default authClient;
