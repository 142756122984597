var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { EventEmitter } from './EventEmitter.js';
import { parseToken } from './utils/deserialize.js';
/** @internal */
export class ChatConnection {
    constructor({ socketUrl, tokenProvider, maxReconnectAttempts = 3, roomId, logger, logLevel, }) {
        this.reconnectAttemptCount = 0;
        this.closeReason = 'serverDisconnect';
        this.eventEmitter = new EventEmitter();
        this._state = 'connecting';
        this.socket = null;
        this.logLevel = 'debug';
        this.onOpen = () => {
            this.state = 'open';
        };
        this.onClose = (event) => {
            // Abnormal socket termination is reported by `close` event in tests
            // Workaround: Firefox incorrectly reports `wasClean === false` for clean close with close code 1000
            const isCleanClose = event.wasClean || event.code === 1000;
            if (!isCleanClose) {
                this.onError(event);
                return;
            }
            this.logDebug(`did receive WebSocket "close" event: code ${event.code}, reason ${event.reason}, was clean: ${event.wasClean}`);
            this.state = 'close';
            this.clearSocket();
        };
        this.onError = (event) => {
            this.clearSocket();
            this.logError('did receive WebSocket "error" event', event);
            if (this.state === 'open') {
                this.closeReason = 'socketConnectionBroken';
            }
            else {
                this.closeReason = 'socketError';
            }
            this.reconnectIfNeeded();
        };
        this.onMessage = ({ data }) => {
            this.dispatchEvent('message', data);
        };
        this.socketUrl = socketUrl;
        this.tokenProvider = tokenProvider;
        this.maxReconnectAttempts = maxReconnectAttempts;
        this.roomId = roomId;
        this.logger = logger;
        this.logLevel = logLevel;
        this.requestToken();
    }
    get state() {
        return this._state;
    }
    set state(newState) {
        if (newState === this._state) {
            return;
        }
        const oldState = this._state;
        this._state = newState;
        this.logInfo(`changed state: ${oldState} -> ${newState}`);
        switch (newState) {
            case 'open':
                this.dispatchEvent('open', this.tokenExpirationTime);
                break;
            case 'close':
                this.dispatchEvent('close', this.closeReason);
                break;
        }
    }
    close() {
        var _a;
        this.state = 'close';
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.close(1000);
        this.clearSocket();
    }
    send(json) {
        var _a;
        if (this.state !== 'open') {
            throw new Error(`Trying to send while in state ${this.state}`);
        }
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.send(json);
    }
    addListener(eventName, listener) {
        return this.eventEmitter.addListener(eventName, listener);
    }
    removeListener(eventName, listener) {
        return this.eventEmitter.removeListener(eventName, listener);
    }
    dispatchEvent(name, ...payload) {
        this.logDebug(`dispatching event "${name}"`, ...payload);
        this.eventEmitter.emit(name, ...payload);
    }
    reconnectIfNeeded() {
        if (this.closeReason === 'serverDisconnect' || this.closeReason === 'socketConnectionBroken') {
            this.logInfo(`closed due to reason "${this.closeReason}". This connection will not attempt to reconnect.`);
            this.state = 'close';
            return;
        }
        if (this.reconnectAttemptCount >= this.maxReconnectAttempts) {
            this.logInfo('max reconnect limit has been reached');
            this.state = 'close';
            return;
        }
        this.reconnectAttemptCount += 1;
        this.logInfo(`attempting re-connection ${this.reconnectAttemptCount}`);
        this.requestToken();
    }
    requestToken() {
        return __awaiter(this, void 0, void 0, function* () {
            this.logDebug('requesting token');
            let chatToken = null;
            try {
                chatToken = parseToken(yield this.tokenProvider());
                this.tokenExpirationTime = chatToken.tokenExpirationTime;
            }
            catch (error) {
                this.logError('did receive error while fetching token', error);
                this.closeReason = 'fetchTokenError';
                this.reconnectIfNeeded();
                return;
            }
            if (this.state === 'close') {
                this.logDebug('did receive token after being close by the room');
                return;
            }
            this.logDebug('did receive token', chatToken.token);
            this.createSocket(chatToken.token);
        });
    }
    createSocket(token) {
        try {
            this.logDebug(`connecting to WebSocket ${this.socketUrl}`);
            this.socket = new WebSocket(this.socketUrl, token);
            this.socket.addEventListener('open', this.onOpen);
            this.socket.addEventListener('close', this.onClose);
            this.socket.addEventListener('message', this.onMessage);
            this.socket.addEventListener('error', this.onError);
        }
        catch (error) {
            // this error happens when socket configuration is invalid when e.g. URL is invalid.
            // this error does not occur when the connection is terminated.
            this.logError(`failed to create WebSocket for url: ${this.socketUrl}`, error);
            this.closeReason = 'socketError';
            this.state = 'close';
        }
    }
    clearSocket() {
        var _a, _b, _c, _d, _e;
        (_a = this.socket) === null || _a === void 0 ? void 0 : _a.removeEventListener('open', this.onOpen);
        (_b = this.socket) === null || _b === void 0 ? void 0 : _b.removeEventListener('close', this.onClose);
        (_c = this.socket) === null || _c === void 0 ? void 0 : _c.removeEventListener('message', this.onMessage);
        (_d = this.socket) === null || _d === void 0 ? void 0 : _d.removeEventListener('error', this.onError);
        // In some envs (e.g. tests) closing socket that is connecting will trigger error event. In order to prevent issues
        // we assign a dummy event handler that will consume this error.
        (_e = this.socket) === null || _e === void 0 ? void 0 : _e.addEventListener('error', () => { });
        this.socket = null;
    }
    logDebug(message, ...args) {
        if (this.logLevel === 'debug') {
            this.logger.debug(`Room ${this.roomId} connection ${message}`, ...args);
        }
    }
    logInfo(message, ...args) {
        if (this.logLevel === 'debug' || this.logLevel === 'info') {
            this.logger.info(`Room ${this.roomId} connection ${message}`, ...args);
        }
    }
    logError(message, ...args) {
        this.logger.error(`Room ${this.roomId} connection ${message}`, ...args);
    }
}
