import { PayloadAction } from '@reduxjs/toolkit';
import { compareDesc, parseISO } from 'date-fns';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { RootState } from '../../app/store';
import { errorOccurred } from '../../notifications/state/notificationsSlice';
import showsApi from '../../shows/api/showsApi';
import factFinderApi from '../api/factFinderApi';
import orderApi from '../api/ordersApi';
import { OrdersOverviewResponse } from '../api/requestResponse';
import { Locale } from '../model/locale';
import { IPage } from '../model/page';
import { ProductTileInfo } from '../model/productTileInfo';
import { SearchResponse } from '../model/searchResponse';
import { isRequestRequired } from '../utils/pagesUtils';
import { mapSearchResponseToProductTileInfos } from '../utils/productTileInfoUtils';
import {
  fetchFactFinderResults,
  fetchFactFinderResultsError,
  fetchFactFinderResultsSuccess,
  fetchOrdersProductsSelectionSearch,
  fetchProductsSelectionSearch,
  setFactFinderLoading,
  setProductsSelectionLoading,
  setProductsSelectionResponse,
  setTotalOrders,
  updateFactFinderResultsSuccess,
} from './productsSlice';

export const getQuery = (state: RootState) => state.products.query;
export const getStatePages = (state: RootState) => state.products.pages;

const isNewSearch = (query: string, queryFromState: string) =>
  queryFromState.length
    ? query.replace(new RegExp('&page=([^&]*)'), '') !==
      queryFromState.replace(new RegExp('&page=([^&]*)'), '')
    : true;

export function* productsHandlers(action: PayloadAction<{ query: string }>) {
  try {
    const queryFromState: string = yield select(getQuery);
    const newSearch = isNewSearch(action.payload.query, queryFromState);

    // if no page query, requested page is 1
    const requestedPage = Number(new URLSearchParams(action.payload.query).get('page') ?? 1);
    const statePages: IPage[] = yield select(getStatePages);
    const pageCounts = statePages.map((p: IPage) => p.count);
    const maxVisitedPage = Math.max(...pageCounts); // -Infinity for []
    const minVisitedPage = Math.min(...pageCounts); // Infinity for []
    const requestRequired = isRequestRequired(maxVisitedPage, minVisitedPage, requestedPage);

    yield put(setFactFinderLoading({ isNewSearch: newSearch }));

    if (!newSearch && !requestRequired) {
      // no need to perform additional request here
      yield put(updateFactFinderResultsSuccess({ requestedPage, query: action.payload.query }));
    } else {
      const searchResponse: SearchResponse = yield call(
        factFinderApi.getFactFinderResult,
        action.payload.query
      );

      yield put(
        fetchFactFinderResultsSuccess({
          searchResponse,
          locale: Locale.deDE,
          isNewSearch: newSearch,
          query: action.payload.query,
        })
      );
    }
  } catch {
    yield put(fetchFactFinderResultsError());
  }
}

export function* searchForProductsHandler(action: PayloadAction<{ query: string }>) {
  try {
    yield put(setProductsSelectionLoading(true));
    const searchResponse: SearchResponse = yield call(
      showsApi.getProductsSearchResult,
      action.payload.query
    );

    const products = mapSearchResponseToProductTileInfos(searchResponse);

    // Fetch products sold-out info
    const updatedProducts: ProductTileInfo[] = yield call(fetchSoldOutInfoHandler, products);

    yield put(setProductsSelectionResponse(updatedProducts));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setProductsSelectionLoading(false));
  }
}

function* fetchSoldOutInfoHandler(products: ProductTileInfo[]) {
  try {
    const ids = products.map(product => product.variantId ?? product.baseProductNo);

    if (ids.length) {
      const productsInfo: ProductTileInfo[] = yield call(showsApi.getProductsDetailsByIds, ids);

      return products.map(product => ({
        ...product,
        soldOut:
          productsInfo.find(info => info.baseProductNo === product.baseProductNo)?.outOfStock ??
          false,
      }));
    }
    return products;
  } catch (err) {
    console.error('Failed to fetch products info:', err);
    return products;
  }
}

export function* getOrdersProductsHandler() {
  try {
    yield put(setProductsSelectionLoading(true));
    const response: OrdersOverviewResponse = yield call(orderApi.getOrdersOverview);
    if (response.orders.length) {
      const baseProductNos = response.orders
        .sort((a, b) => {
          const dateA = parseISO(a.placedAt ?? new Date().toISOString());
          const dateB = parseISO(b.placedAt ?? new Date().toISOString());
          return compareDesc(dateA, dateB);
        })
        .flatMap(o => o.items)
        .map(i => i.sku ?? i.baseProductNo ?? '')
        .filter(b => b.length);
      const products: ProductTileInfo[] = yield call(
        showsApi.getProductsDetailsByIds,
        // Search API doesn't accept more than 60 items
        baseProductNos.slice(0, 60)
      );
      yield put(setTotalOrders(baseProductNos.length));
      yield put(setProductsSelectionResponse(products));
    } else {
      yield put(setTotalOrders(0));
      yield put(setProductsSelectionResponse([]));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setProductsSelectionLoading(false));
  }
}

export function* watcherFactFinderSagas() {
  yield takeLatest(fetchFactFinderResults, productsHandlers);
  yield takeLatest(fetchProductsSelectionSearch, searchForProductsHandler);
  yield takeLatest(fetchOrdersProductsSelectionSearch, getOrdersProductsHandler);
}
