import dayjs from 'dayjs';
import { RootState } from '../../app/store';
import { getPageName, getPortalType } from './reduxBeaconGetters';
import {
  BeaconEvent,
  BeaconEventAction,
  BeaconEventCategory,
  BeaconOnboardingEventLabel,
  EventAction,
  FirebaseEvent,
  FirebaseEventParams,
} from './types';

export const trackPageImpressionEvent = (
  _action: EventAction,
  state: RootState
): FirebaseEvent => ({
  name: BeaconEvent.PAGE_IMPRESSION,
  userId: state.auth?.userLogin?.id,
  params: {
    pageName: getPageName(state.router?.location?.pathname),
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
});

export const trackLoginSuccessEvent = (_action: EventAction, state: RootState): FirebaseEvent => ({
  name: BeaconEvent.LOGIN_SUCCESS,
  userId: state.auth?.userLogin?.id,
  params: {
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
});

export const trackInvoiceDownloadEvent = (
  _action: EventAction,
  state: RootState
): FirebaseEvent => ({
  name: BeaconEvent.INVOICE_DOWNLOAD,
  userId: state.auth?.userLogin?.id,
  params: {
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
  userProperties: {
    invoice_id: state.payments.invoiceDetails?.invoiceId,
    invoice_number: state.payments.invoiceDetails?.invoiceNumber,
  },
});

export const trackMarketingConsentEvent = (
  _action: EventAction,
  state: RootState
): FirebaseEvent => ({
  name: BeaconEvent.MARKETING_CONSENT,
  userId: state.auth?.userLogin?.id,
  params: {
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
  userProperties: {
    result: _action.payload,
  },
});

export const trackStreamerAcademyEvent = (
  _action: EventAction,
  state: RootState
): FirebaseEvent => ({
  name: BeaconEvent.STREAMER_ACADEMY,
  userId: state.auth?.userLogin?.id,
  params: {
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
});

export const trackStreamEndedEvent = (_action: EventAction, state: RootState): FirebaseEvent => ({
  name: BeaconEvent.STREAM_ENDED,
  userId: state.auth?.userLogin?.id,
  streamId: _action.payload.showId,
  consentFunctional: _action.payload.consent,
  params: {
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
  userProperties: {
    consentFunctional: _action.payload.consent,
    consentEssential: _action.payload.consent,
  },
});

export const trackProfileImageValidationEvent = (
  _action: EventAction,
  state: RootState
): FirebaseEvent => ({
  name: BeaconEvent.PROFILE_IMAGE_VALIDATION,
  userId: state.auth?.userLogin?.id,
  params: {
    portal_type: getPortalType(state.auth?.userLogin?.role),
  },
  data: {
    validation: _action.payload,
  },
});

const createOnboardingEvent = (
  state: RootState,
  eventLabel: BeaconOnboardingEventLabel,
  params?: FirebaseEventParams
): FirebaseEvent => {
  const userId = state.auth?.userLogin?.id;
  const portalType = getPortalType(state.auth?.userLogin?.role);

  return {
    userId,
    name: BeaconEvent.CREATORS_ONBOARDING,
    params: {
      eventCategory: BeaconEventCategory.STREAMERS_ONBOARDING,
      eventAction: BeaconEventAction.ONBOARDING_STEPS,
      eventLabel,
      portal_type: portalType,
      streamers_onboarding_steps: eventLabel,
      ...params,
    },
    userProperties: {
      onboarding_steps: eventLabel,
      customer_id: userId,
    },
  };
};

export const trackProfileCompletedEvent = (_action: EventAction, state: RootState) =>
  createOnboardingEvent(state, BeaconOnboardingEventLabel.PROFILE_COMPLETED, {
    dimension6: dayjs(Date.now()).format('DD.MM.YYYY'),
  });

export const trackShopSetupCompletedEvent = (_action: EventAction, state: RootState) =>
  createOnboardingEvent(state, BeaconOnboardingEventLabel.SHOP_SETUP_COMPLETED);

export const trackSamplesOrderedEvent = (_action: EventAction, state: RootState) =>
  createOnboardingEvent(state, BeaconOnboardingEventLabel.SAMPLES_ORDERED);

export const trackSamplesReceivedEvent = (_action: EventAction, state: RootState) =>
  createOnboardingEvent(state, BeaconOnboardingEventLabel.SAMPLES_RECEIVED);

export const trackOnboardingCompletedEvent = (_action: EventAction, state: RootState) =>
  createOnboardingEvent(state, BeaconOnboardingEventLabel.FIRST_SHOW_STREAMED);

export const trackShowCreatedEvent = (action: EventAction): FirebaseEvent => ({
  name: BeaconEvent.SHOW_CREATED,
  params: {
    eventCategory: BeaconEventCategory.SHOW_CREATED,
    eventAction: `${action.payload.title} | ${action.payload.id}`,
  },
});
