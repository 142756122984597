import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { selectUserIsHSEEmployee } from '../../auth/state/authSlice';
import { QueryParams } from '../../common/types';
import { addPathQuery } from '../../common/utils';
import Config from '../../config/config';
import t from '../../constants/translation';
import { selectLoggedInCreator } from '../../creators/state/creatorsSlice';
import { LogLevel } from '../../integration/logginglambda/LoggingLambdaApi';
import { logToLambda } from '../../integration/logginglambda/loggingSlice';
import { errorOccurred, showSnackbar } from '../../notifications/state/notificationsSlice';
import { ProductTileInfo } from '../../products/model/productTileInfo';
import {
  addToProductsSelectionSelectedProducts,
  clearProductsSelection,
} from '../../products/state/productsSlice';
import routePaths from '../../routes/routePaths';
import { put as sendPutRequest } from '../../shared/axiosClient';
import showsApi from '../../shows/api/showsApi';
import getBlobFromUrl from '../../utils/images/getBlobFromUrl';
import { parseError } from '../../utils/parseError/parseError';
import postsApi from '../api/postsApi';
import {
  AdminDeleteS3ObjectsRequest,
  AdminPostCreationRequest,
  AdminPostUpdatePayload,
  MediaType,
  PostCreationRequest,
  PostPreSignedUrlRequest,
  PostPreSignedUrlResponse as S3File,
  PostPreSignedUrlsResponse,
  PostStatusRequestResponse,
  PostUpdatePayload,
  StreamerPostResponse,
  StreamerPostsResponse,
  UploadPostMediaToS3Request,
} from '../api/postsRequestResponse';
import { AdminPostData, PostData, PostStatus, PostUploadStatus } from '../model/post';
import { extractPostIdFromPreSignedUrl } from '../utils/extractPostIdFromPreSignedUrl';
import { getFileName, getMediaType, moveItem, replaceSpaceBy } from '../utils/MediaUtils';
import {
  addSelectedFiles,
  cancelFileUploadToS3,
  cancelPostMediaUploadToS3,
  changePostStatus,
  clearPostCreationData,
  createNewPost,
  createNewPostByAdmin,
  createPreSignedUrls,
  fetchAdminPostsOverview,
  fetchStreamerPost,
  fetchStreamerPosts,
  fetchStreamerPostsStats,
  FileMetaData,
  imageCropped,
  imageCroppedInUpdate,
  moveSelectedFileDown,
  moveSelectedFileUp,
  PostsStatsData,
  removeS3UploadStatus,
  selectCreatorId,
  selectPostId,
  selectS3loadingStatuses,
  selectSelectedFiles,
  selectStreamerPostsPageable,
  setAdminPostsOverview,
  setAdminPostStatus,
  setCreatingPostLoading,
  setCreatorId,
  setExhibitedFile,
  setPost,
  setPostId,
  setPostStatusLoading,
  setPostUploadIsLoading,
  setS3UploadStatus,
  setSelectedFiles,
  setStreamerPosts,
  setStreamerPostsLoading,
  setStreamerPostsStats,
  setStreamerPostsStatsLoading,
  setStreamerPostStatus,
  updatePost,
  updatePostByAdmin,
  uploadPostMediaToS3,
} from './postsSlice';

export function* createNewPostHandler(action: PayloadAction<PostData>) {
  try {
    yield put(setCreatingPostLoading(true));
    const postPreSignedUrlRequest: PostPreSignedUrlRequest = {
      fileExtension: action.payload.preview.fileExtension,
      mediaType: action.payload.preview.mediaType,
    };
    const uploadImageResponse: S3File = yield call(
      uploadPostImage,
      action.payload.preview.imageUrl,
      postPreSignedUrlRequest
    );

    const postId = extractPostIdFromPreSignedUrl(uploadImageResponse.key);

    if (postId == null) {
      throw new Error('Pre signed URL format invalid');
    }

    const postCreationRequest: PostCreationRequest = {
      postId,
      fileExtension: action.payload.preview.fileExtension,
      baseProductsNo: action.payload.baseProductsNo,
      caption: action.payload.caption,
      s3Key: uploadImageResponse.key,
      mediaType: action.payload.preview.mediaType,
      scheduleAt: action.payload.scheduleAt,
    };
    yield call(postsApi.createNewPost, postCreationRequest);
    yield put(push(routePaths.creator.dashboard));
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatingPostLoading(false));
  }
}
export function* createNewPostByAdminHandler(action: PayloadAction<AdminPostData>) {
  try {
    yield put(setCreatingPostLoading(true));

    const postId: string = yield* getPostId();
    const creatorId: string = yield* getCreatorId();

    const adminPostCreationRequest: AdminPostCreationRequest = {
      postId,
      baseProductsNo: action.payload.baseProductsNo,
      caption: action.payload.caption,
      scheduleAt: action.payload.scheduleAt,
      medias: action.payload.medias,
      creatorId,
      salesTheme: action.payload.salesTheme,
    };
    yield call(postsApi.createNewPostByAdmin, adminPostCreationRequest);
    yield put(push(routePaths.hseEmployee.postsOverview));
    yield put(clearPostCreationData());
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setCreatingPostLoading(false));
  }
}

export function* uploadPostImage(imageUrl: string, request: PostPreSignedUrlRequest) {
  const preSignedUrlResponse: S3File = yield call(postsApi.generatePreSignedUrlForPost, request);
  const previewImageData: Blob = yield call(getBlobFromUrl, imageUrl);
  yield call(sendPutRequest, preSignedUrlResponse.preSignedUrl, previewImageData);

  return preSignedUrlResponse;
}

export function* fetchStreamerPostsHandler() {
  try {
    yield put(setStreamerPostsLoading(true));
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    const pageable: ReturnType<typeof selectStreamerPostsPageable> = yield select(
      selectStreamerPostsPageable
    );
    if (creator) {
      const response: StreamerPostsResponse = yield call(
        postsApi.fetchStreamerPosts,
        creator.id,
        pageable
      );
      yield put(setStreamerPosts(response));
    }
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setStreamerPostsLoading(false));
  }
}

export function* changePostStatusHandler(action: PayloadAction<PostStatusRequestResponse>) {
  const { postId, postStatus } = action.payload;
  try {
    yield put(setPostStatusLoading(true));
    let response: PostStatusRequestResponse;
    switch (postStatus) {
      case PostStatus.PUBLISHED:
        response = yield call(postsApi.republishPost, postId);
        break;
      default:
        response = yield call(postsApi.unpublishPost, postId);
        break;
    }
    const isAdmin: boolean = yield select(selectUserIsHSEEmployee);
    if (isAdmin) {
      yield put(setAdminPostStatus(response));
    } else {
      yield put(setStreamerPostStatus(response));
    }
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setPostStatusLoading(false));
  }
}

export function* fetchStreamerPostsStatsHandler() {
  try {
    yield put(setStreamerPostsStatsLoading(true));
    const creator: ReturnType<typeof selectLoggedInCreator> = yield select(selectLoggedInCreator);
    if (creator) {
      const response: PostsStatsData = yield call(postsApi.fetchStreamerPostsStats, creator.id);
      yield put(setStreamerPostsStats(response));
    }
  } catch (unknownError) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setStreamerPostsStatsLoading(false));
  }
}

export function* fetchAdminPostsOverviewHandler(action: PayloadAction<QueryParams>) {
  try {
    yield put(setAdminPostsOverview({ loading: true }));
    const postsResponse: StreamerPostsResponse = yield call(
      postsApi.fetchAdminPostsOverview,
      action.payload
    );
    yield put(setAdminPostsOverview({ ...postsResponse, queryParams: action.payload }));
  } catch (unknownError: unknown) {
    yield put(errorOccurred(unknownError as Error));
  } finally {
    yield put(setAdminPostsOverview({ loading: false }));
  }
}

export function* fetchStreamerPostHandler(action: PayloadAction<string>) {
  try {
    const postId = action.payload;
    const post: StreamerPostResponse = yield call(postsApi.getStreamerPostById, postId);
    const ids = post.products?.map(p => p.variantId ?? p.baseProductNo);
    if (ids?.length) {
      const products: ProductTileInfo[] = yield call(showsApi.getProductsDetailsByIds, ids);
      yield put(addToProductsSelectionSelectedProducts(products));
    } else {
      yield put(addToProductsSelectionSelectedProducts([]));
    }
    if (post.medias) {
      const selectedFiles: FileMetaData[] = [];
      post.medias?.forEach((media, index) => {
        const regex = new RegExp(`${Config.env.baseUrl}/files/streamer/`, 'g');
        const s3Key = media.url.replace(regex, '');
        selectedFiles.push({
          type: getMediaType(media.url),
          url: media.url,
          name: getFileName(media.url.split('/').pop()!),
          size: 0,
          isSupported: true,
          index,
          preSignedUrl: media.url,
          key: s3Key,
          uploadStatus: PostUploadStatus.SUCCESS,
        });
      });

      yield put(setSelectedFiles(selectedFiles));
      yield put(setExhibitedFile(selectedFiles[0]));
      yield put(setCreatorId(post.creator?.id ? post.creator.id : ''));
    }
    yield put(setPostId(post.id));
    yield put(setPost(post));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  }
}

export function* updatePostHandler(action: PayloadAction<PostUpdatePayload>) {
  try {
    const isAdmin: boolean = yield select(selectUserIsHSEEmployee);

    yield put(setCreatingPostLoading(true));

    const { postId, request } = action.payload;
    yield call(postsApi.updatePost, postId, request);
    yield put(showSnackbar({ text: 'Post erfolgreich geändert 🎉' }));
    yield put(clearProductsSelection());
    const path = isAdmin
      ? routePaths.hseEmployee.postsOverview
      : addPathQuery(routePaths.creator.analytics, {
          tab: 'Posts',
        });
    yield put(push(path));
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: `Error occurred when trying to update post ${action.payload.postId}`,
        error: parseError(err),
      })
    );
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setCreatingPostLoading(false));
  }
}
export function* updatePostByAdminHandler(action: PayloadAction<AdminPostUpdatePayload>) {
  try {
    yield put(setCreatingPostLoading(true));

    const { postId, request } = action.payload;
    yield call(postsApi.updatePostByAdmin, postId, request);
    yield put(showSnackbar({ text: 'Post erfolgreich geändert 🎉' }));
    yield put(clearProductsSelection());
    yield put(push(routePaths.hseEmployee.postsOverview));
    yield put(clearPostCreationData());
  } catch (err) {
    yield put(
      logToLambda({
        level: LogLevel.ERROR,
        message: `Error occurred when trying to update post ${action.payload.postId}`,
        error: parseError(err),
      })
    );
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setCreatingPostLoading(false));
  }
}

export function* createPreSignedUrlsHandler(
  action: PayloadAction<{
    files: {
      type: string;
      url: string | null;
      name: string;
      size: number;
      isSupported: boolean;
      index: number;
    }[];
    attachments: PostPreSignedUrlRequest[];
    isUploading: boolean;
  }>
) {
  yield put(setPostUploadIsLoading(true));
  try {
    const selectedFiles: FileMetaData[] = [];
    const creatorId = yield* getCreatorId();
    const postId: string = yield select(selectPostId);
    const postPreSignedUrlsResponse: PostPreSignedUrlsResponse = yield call(
      postsApi.createPreSignedUrls,
      {
        attachments: action.payload.attachments,
        creatorId,
        postId: postId.length > 0 ? postId : undefined,
      }
    );
    yield put(setPostId(postPreSignedUrlsResponse.postId));
    postPreSignedUrlsResponse.keys.forEach(s3File => {
      const searchedFile = action.payload.files.find(file =>
        s3File.key.includes(replaceSpaceBy(getFileName(file.name), '_').toLowerCase())
      );
      if (searchedFile) {
        selectedFiles.push({
          name: getFileName(searchedFile.name),
          size: searchedFile.size,
          type: searchedFile.type,
          url: searchedFile.url,
          isSupported: searchedFile.isSupported,
          index: searchedFile.index,
          preSignedUrl: s3File.preSignedUrl,
          key: s3File.key,
          uploadStatus: action.payload.isUploading ? PostUploadStatus.IN_PROGRESS : undefined,
        });
      }
    });
    const unsupportedFiles: FileMetaData[] = action.payload.files
      .filter(file => !file.isSupported)
      .map(file => {
        return { ...file, uploadStatus: undefined, key: '', preSignedUrl: '' };
      });
    yield put(addSelectedFiles([...selectedFiles, ...unsupportedFiles]));
  } catch (err) {
    yield put(errorOccurred(err as Error));
  } finally {
    yield put(setPostUploadIsLoading(false));
  }
}
export function* uploadPostMediaToS3Handler(action: PayloadAction<UploadPostMediaToS3Request>) {
  if (action.payload.preSignedUrl && action.payload.fileUrl) {
    const uploadStatuses: Record<string, PostUploadStatus> = yield select(selectS3loadingStatuses);
    if (!uploadStatuses.hasOwnProperty(action.payload.preSignedUrl)) {
      yield put(
        setS3UploadStatus({
          preSignedUrl: action.payload.preSignedUrl,
          status: PostUploadStatus.IN_PROGRESS,
        })
      );
      try {
        const imageData: Blob = yield call(getBlobFromUrl, action.payload.fileUrl);
        yield call(sendPutRequest, action.payload.preSignedUrl, imageData);
        yield put(
          setS3UploadStatus({
            preSignedUrl: action.payload.preSignedUrl,
            status: PostUploadStatus.SUCCESS,
          })
        );
      } catch (err) {
        yield put(errorOccurred(err as Error));
        yield put(
          setS3UploadStatus({
            preSignedUrl: action.payload.preSignedUrl,
            status: PostUploadStatus.ERROR,
          })
        );
      }
    }
  }
}
export function* cancelPostMediaUploadToS3Handler() {
  try {
    const keys: FileMetaData[] = yield select(selectSelectedFiles);
    const mappedKeys = keys.map(key => key.key);
    const creatorId = yield* getCreatorId();
    const deleteS3ObjectsRequest: AdminDeleteS3ObjectsRequest = { keys: mappedKeys, creatorId };

    yield call(postsApi.deleteS3Objects, deleteS3ObjectsRequest);
    yield put(setSelectedFiles([]));
  } catch (e) {
    yield put(errorOccurred(e as Error));
  }
}
export function* cancelFileUploadToS3Handler(action: PayloadAction<S3File>) {
  try {
    const selectedFiles: FileMetaData[] = yield select(selectSelectedFiles);
    const creatorId = yield* getCreatorId();
    const deleteS3ObjectsRequest: AdminDeleteS3ObjectsRequest = {
      keys: [action.payload.key],
      creatorId,
    };

    yield call(postsApi.deleteS3Objects, deleteS3ObjectsRequest);
    yield put(
      setSelectedFiles(
        selectedFiles
          .filter(file => !action.payload.key.includes(file.key))
          .map((file, index) => {
            return { ...file, index };
          })
      )
    );
    yield put(setExhibitedFile(undefined));
  } catch (e) {
    yield put(errorOccurred(e as Error));
  }
}

export function* moveSelectedFileUpHandler(action: PayloadAction<FileMetaData>) {
  try {
    const selectedFiles: FileMetaData[] = yield select(selectSelectedFiles);
    const swipedFileList = moveItem(selectedFiles, action.payload.index, -1);
    yield put(setSelectedFiles(swipedFileList));
    if (selectedFiles != swipedFileList) {
      yield put(setExhibitedFile(swipedFileList[action.payload.index - 1]));
    }
  } catch (e) {
    yield put(errorOccurred(e as Error));
  }
}

export function* moveSelectedFileDownHandler(action: PayloadAction<FileMetaData>) {
  try {
    const selectedFiles: FileMetaData[] = yield select(selectSelectedFiles);
    const swipedFileList = moveItem(selectedFiles, action.payload.index, +1);
    yield put(setSelectedFiles(swipedFileList));
    if (selectedFiles != swipedFileList) {
      yield put(setExhibitedFile(swipedFileList[action.payload.index + 1]));
    }
  } catch (e) {
    yield put(errorOccurred(e as Error));
  }
}
export function* imageCroppedHandler(
  action: PayloadAction<{
    croppedFile: FileMetaData;
    newCroppedImageUrl: string;
    preSignedUrl: string;
  }>
) {
  try {
    yield put(removeS3UploadStatus(action.payload.preSignedUrl));
    yield put(
      uploadPostMediaToS3({
        fileUrl: action.payload.newCroppedImageUrl,
        preSignedUrl: action.payload.preSignedUrl,
      })
    );
    yield put(
      setExhibitedFile({ ...action.payload.croppedFile, url: action.payload.newCroppedImageUrl })
    );
    const selectedFiles: FileMetaData[] = yield select(selectSelectedFiles);

    yield put(
      setSelectedFiles(
        selectedFiles
          .map(file =>
            file.url !== action.payload.croppedFile.url
              ? file
              : { ...file, url: action.payload.newCroppedImageUrl }
          )
          .sort((a, b) => a.index - b.index)
      )
    );
  } catch (e) {
    yield put(errorOccurred(e as Error));
  }
}

export function* imageCroppedInUpdateHandler(
  action: PayloadAction<{
    croppedFile: FileMetaData;
    newCroppedImageUrl: string;
    s3File: S3File;
  }>
) {
  try {
    const attachments: PostPreSignedUrlRequest[] = [
      {
        fileExtension: 'jpeg',
        mediaType: MediaType.IMAGE,
        fileName: action.payload.croppedFile.name,
      },
    ];
    const postId: string = yield* getPostId();
    const creatorId: string = yield* getCreatorId();

    const preSignedUrlResponse: PostPreSignedUrlsResponse = yield call(
      postsApi.createPreSignedUrls,
      { attachments, creatorId, postId }
    );
    yield call(postsApi.deleteS3Objects, { creatorId, keys: [action.payload.s3File.key] });
    yield put(
      uploadPostMediaToS3({
        fileUrl: action.payload.newCroppedImageUrl,
        preSignedUrl: preSignedUrlResponse.keys[0].preSignedUrl,
      })
    );
    yield put(
      setExhibitedFile({ ...action.payload.croppedFile, url: action.payload.newCroppedImageUrl })
    );
    const selectedFiles: FileMetaData[] = yield select(selectSelectedFiles);

    yield put(
      setSelectedFiles(
        selectedFiles
          .map(file =>
            file.url !== action.payload.croppedFile.url
              ? file
              : {
                  ...file,
                  url: action.payload.newCroppedImageUrl,
                  key: preSignedUrlResponse.keys[0].key,
                }
          )
          .sort((a, b) => a.index - b.index)
      )
    );
  } catch (e) {
    yield put(errorOccurred(e as Error));
  }
}

function* getCreatorId() {
  const creatorId: string = yield select(selectCreatorId);
  if (creatorId.length === 0) {
    throw new Error(t.creators.post.creatorIdIsMandatory);
  }
  return creatorId;
}
function* getPostId() {
  const postId: string = yield select(selectPostId);

  if (postId.length === 0) {
    throw new Error('The post id must be set');
  }
  return postId;
}

export function* watcherPostsSagas() {
  yield takeLatest(createNewPost.type, createNewPostHandler);
  yield takeLatest(createNewPostByAdmin.type, createNewPostByAdminHandler);
  yield takeLatest(fetchStreamerPosts.type, fetchStreamerPostsHandler);
  yield takeLatest(fetchStreamerPostsStats.type, fetchStreamerPostsStatsHandler);
  yield takeLatest(changePostStatus.type, changePostStatusHandler);
  yield takeLatest(fetchAdminPostsOverview.type, fetchAdminPostsOverviewHandler);
  yield takeLatest(fetchStreamerPost.type, fetchStreamerPostHandler);
  yield takeLatest(updatePost.type, updatePostHandler);
  yield takeLatest(updatePostByAdmin.type, updatePostByAdminHandler);
  yield takeLatest(createPreSignedUrls.type, createPreSignedUrlsHandler);
  yield takeEvery(uploadPostMediaToS3.type, uploadPostMediaToS3Handler);
  yield takeLatest(cancelPostMediaUploadToS3.type, cancelPostMediaUploadToS3Handler);
  yield takeLatest(moveSelectedFileUp.type, moveSelectedFileUpHandler);
  yield takeLatest(moveSelectedFileDown.type, moveSelectedFileDownHandler);
  yield takeLatest(cancelFileUploadToS3.type, cancelFileUploadToS3Handler);
  yield takeLatest(imageCropped.type, imageCroppedHandler);
  yield takeLatest(imageCroppedInUpdate.type, imageCroppedInUpdateHandler);
}
