import cx from 'classnames';
import { ChangeEvent, CSSProperties } from 'react';
import styles from './Checkbox.module.scss';

type CheckboxProps = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  checked: boolean;
  inline?: boolean;
  classNames?: string;
  style?: CSSProperties;
};

const Checkbox = (props: CheckboxProps) => {
  const { disabled, label, checked, onChange, inline, classNames, style } = props;
  const className = cx(classNames, [styles.checkbox], {
    [styles.inline]: inline,
    [styles.checked]: checked,
    [styles.disabled]: disabled,
    [styles.standAlone]: !label,
  });
  return (
    <label className={className} style={style}>
      <input type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
      <i
        className={cx({
          [styles.checkboxChecked]: checked,
        })}
      />
      {label}
    </label>
  );
};

export default Checkbox;
