import { useSelector } from 'react-redux';
import { FacetName } from '../../model/facet';
import { selectProductListProps } from '../../state/productsSlice';
import styles from './Filter.module.scss';
import FilterHeader from './FilterHeader/FilterHeader';
import FilterItem from './FilterItem/FilterItem';
import ResetAllFilters from './ResetAllFilters/ResetAllFilters';

const Filter = () => {
  const { facets = [] } = useSelector(selectProductListProps);
  return (
    <div className={styles.mobileFilterBar}>
      <FilterHeader />
      <ul className={styles.mobileFilterBarList}>
        {facets
          .filter(facet => facet.name !== FacetName.CategoryPath)
          .map(facet => (
            <FilterItem facet={facet} key={facet.name} />
          ))}
      </ul>
      <ResetAllFilters />
    </div>
  );
};

export default Filter;
