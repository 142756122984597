import { addMinutes, isBefore, isFuture } from 'date-fns';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useEffect, useState } from 'react';
import { formatDateRelative } from '../../../utils/formatters/dateFormatter/dateFormatter';

dayjs.extend(duration);

interface ShowScheduledTimeOrCountdownProps {
  startingAt: string;
  startCountdownInMinutes?: number;
}

const ShowScheduledTimeOrCountdown = ({
  startingAt,
  startCountdownInMinutes = 30,
}: ShowScheduledTimeOrCountdownProps) => {
  const [remainingTime, setRemainingTime] = useState(() =>
    dayjs(startingAt).diff(Date.now(), 'milliseconds')
  );
  const isShowStartingInLessThan30Min = isBefore(
    new Date(startingAt),
    addMinutes(Date.now(), startCountdownInMinutes)
  );

  const isShowInFutureAndIsStartingInLessThan30Min =
    isFuture(new Date(startingAt)) && isShowStartingInLessThan30Min;

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = dayjs(startingAt).diff(Date.now(), 'milliseconds');
      if (remainingTime <= 0) {
        clearInterval(interval);
      }
      setRemainingTime(remainingTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [startingAt]);

  const renderTimeOrCountdown = () => {
    if (remainingTime > 0 && isShowInFutureAndIsStartingInLessThan30Min) {
      const formattedTime = dayjs.duration(remainingTime).format('mm:ss');
      return (
        <span data-testid="time-countdown">
          Heute, in <b>{formattedTime} min</b>
        </span>
      );
    } else {
      return formatDateRelative(startingAt);
    }
  };

  return <>{renderTimeOrCountdown()}</>;
};

export default ShowScheduledTimeOrCountdown;
