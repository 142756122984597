import { Button, EButtonSize, EButtonType } from '@hse24/shared-components';
import { DateFilterTypes } from '../dateFilterTypes';
import styles from './FilterButton.module.scss';

interface FilterChipProps {
  label: string;
  filterType: DateFilterTypes;
  currentFilter: DateFilterTypes;
  filterSelectedCallback: (selectedFilter: DateFilterTypes) => void;
}

const FilterButton = ({
  label,
  filterType,
  currentFilter,
  filterSelectedCallback,
}: FilterChipProps) => {
  const resolvesType = () =>
    filterType === currentFilter ? EButtonType.PRIMARY : EButtonType.BLANK;

  return (
    <Button
      type={resolvesType()}
      className={styles.button}
      onClick={() => filterSelectedCallback(filterType)}
      size={EButtonSize.SMALL}
      exposeButtonType={true}
    >
      {label}
    </Button>
  );
};

export default FilterButton;
