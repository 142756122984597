import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import {
  fetchLoggedInCreator,
  selectCreatorHasCompletedProfile,
  selectLoggedInCreator,
  selectLoggedInCreatorNeedsToBeFetched,
  selectUserMightNeedToAcceptTermsAndConditions,
} from '../../../creators/state/creatorsSlice';
import ErrorPageDialog, {
  ErrorPageDialogType,
} from '../../../notifications/components/ErrorPageDialog/ErrorPageDialog';
import { RouteDetails } from '../../../routes/routeDetails';
import routePaths from '../../../routes/routePaths';
import Loading from '../../../shows/component/Loading/Loading';
import { Role } from '../../role';
import {
  logout,
  redirectToAddressStepAction,
  selectAuthLoading,
  selectUserIsAuthenticated,
  selectUserIsCreator,
  selectUserRoles,
} from '../../state/authSlice';

const ProtectedRoute = ({
  roles,
  path,
  exact,
  component,
}: Pick<RouteDetails, 'roles' | 'exact' | 'path' | 'component'>) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const authLoading = useSelector(selectAuthLoading);
  const isAuthenticated = useSelector(selectUserIsAuthenticated);
  const userRoles = useSelector(selectUserRoles);
  const loggedInStreamerNeedsToBeFetched = useSelector(selectLoggedInCreatorNeedsToBeFetched);
  const userMightNeedToAcceptTermsAndConditions = useSelector(
    selectUserMightNeedToAcceptTermsAndConditions
  );
  const userIsCreator = useSelector(selectUserIsCreator);
  const streamerHasCompletedProfile = useSelector(selectCreatorHasCompletedProfile);
  const loggedInCreator = useSelector(selectLoggedInCreator);

  useEffect(() => {
    if (
      !authLoading &&
      isAuthenticated &&
      !userRoles?.some(role => Object.values(Role).includes(role))
    ) {
      dispatch(logout());
    }
  }, [authLoading, isAuthenticated, userRoles, dispatch]);

  if (authLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    const redirect = history.location.pathname;
    if (routePaths.auth !== redirect) {
      history.push(routePaths.auth + '?redirect=' + redirect);
    }
    return <div>Redirecting to Login</div>;
  }

  if (loggedInStreamerNeedsToBeFetched) {
    dispatch(fetchLoggedInCreator());
  }

  if (userMightNeedToAcceptTermsAndConditions) {
    if (
      !!loggedInCreator &&
      !(
        loggedInCreator.street &&
        loggedInCreator.streetNumber &&
        loggedInCreator.city &&
        loggedInCreator.zipCode
      )
    ) {
      dispatch(redirectToAddressStepAction());
    }

    return null;
  }

  const userHasRequiredRole = roles?.some(routeRole => userRoles?.includes(routeRole));

  if (!userHasRequiredRole) {
    return <ErrorPageDialog errorDialogType={ErrorPageDialogType.UNAUTHORIZED} />;
  }

  if (
    userIsCreator &&
    !streamerHasCompletedProfile &&
    history.location.pathname !== routePaths.creator.editProfile
  ) {
    history.push(routePaths.creator.editProfile);
    return null;
  }

  return <Route exact={exact} path={path} component={component} />;
};

export default ProtectedRoute;
