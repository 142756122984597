import { Box, Card, CardContent } from '@mui/material';
import { Chart as ChartJS, ChartData, ChartOptions, ChartType, DefaultDataPoint } from 'chart.js';
import { useEffect, useRef } from 'react';

interface ChartProps<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown
> {
  type: TType;
  data: ChartData<TType, TData, TLabel>;
  chartOptions?: ChartOptions<TType>;
  /**
   * Description shown below the chart
   */
  summary?: string;
}

const Chart = ({ data, type, chartOptions, summary }: ChartProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<ChartJS | null>(null);

  const renderChart = () => {
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d')?.canvas;

    if (ctx) {
      chartRef.current = new ChartJS(ctx, {
        type,
        data,
        options: chartOptions,
      });
    }
  };

  const destroyChart = () => {
    if (chartRef.current) {
      chartRef.current.destroy();
      chartRef.current = null;
    }
  };

  useEffect(() => {
    renderChart();

    return () => destroyChart();
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data;
      if (chartOptions) {
        chartRef.current.options = chartOptions;
      }
      chartRef.current.update();
    }
  }, [data, chartOptions]);

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <canvas ref={canvasRef} role="img" height="400" width="400"></canvas>
        <Box mt={3}>
          <p>{summary}</p>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Chart;
