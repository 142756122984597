import dayjs from 'dayjs';
import { gracePeriodToStartStreamInHours } from '../../constants/show';
import { UpcomingShow } from '../model/shows';

const isAllowedToStartStream = (upcomingShow: UpcomingShow) => {
  const now = dayjs(Date.now());
  const showScheduledAt = dayjs(upcomingShow.scheduledAt);

  return (
    now.isBefore(showScheduledAt.add(gracePeriodToStartStreamInHours, 'hours')) ||
    upcomingShow.isLive
  );
};

export default isAllowedToStartStream;
