import { format, formatRelative } from 'date-fns';
import de from 'date-fns/locale/de';

export const formatDateRelative = (dateToFormatISO: string) => {
  const formatRelativeLocale = {
    lastWeek: "EEEE, dd. MMM, 'um' HH:mm 'Uhr'",
    yesterday: "EEEE, dd. MMM, 'um' HH:mm 'Uhr'",
    today: "'Heute, um' HH:mm 'Uhr'",
    tomorrow: "EEEE, 'um' HH:mm 'Uhr'",
    nextWeek: "EEEE, 'um' HH:mm 'Uhr'",
    other: "EEEE, dd. MMM, 'um' HH:mm 'Uhr'",
  };

  const locale: Locale = {
    ...de,
    // @ts-ignore date-fns does not have exported type FormatRelativeToken...
    formatRelative: token => formatRelativeLocale[token],
  };

  return formatRelative(new Date(dateToFormatISO), new Date(), { locale })
    .replace('.:', ':')
    .replace('.,', ',');
};

export const getDayOfTheWeek = (dateISO: string) => {
  return format(new Date(dateISO), 'EEEE', { locale: de });
};

export const formatDate = (dateISO: string) => {
  return format(new Date(dateISO), 'dd.MM.yyyy', { locale: de });
};

export const formatTime = (dateISO: string) => {
  return format(new Date(dateISO), "HH:mm 'Uhr'", { locale: de });
};
