import { ContentCopyOutlined } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useState } from 'react';
import Icons from '../../../assets';
import CopyToClipboard from '../../CopyToClipboard/CopyToClipboard';
import useCopyToClipboard from '../../CopyToClipboard/useCopyToClipboard';
import Icon from '../../Icon/Icon';
import styles from '../ResponsiveDetailsRow.module.scss';

interface SecureDetailsRowProps {
  label: string;
  value: string;
  last?: boolean;
}

function hideValue(val: string): string {
  return (
    val
      .split('')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(_ => '*')
      .join('')
  );
}

const SecureDetailsRow = ({ label, value, last }: SecureDetailsRowProps) => {
  const [isValueVisible, setIsValueVisible] = useState(false);
  const [secureValue, setSecureValue] = useState(hideValue(value));
  const [isCopied, copyToClipboard] = useCopyToClipboard();

  const toggleVisibility = () => {
    const toggledValueVisibility = !isValueVisible;
    setIsValueVisible(toggledValueVisibility);

    if (toggledValueVisibility) {
      setSecureValue(value);
    } else {
      setSecureValue(hideValue(value));
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      className={`${styles.row} ${last ? '' : styles.rowSeparator}`}
      flexWrap={'nowrap'}
    >
      <Grid container item flexGrow={1} direction="column" gap={1}>
        <Grid item className={styles.label}>
          {label}
        </Grid>
        <Grid
          item
          className={styles.withLineBreaks}
          onClick={isValueVisible ? () => copyToClipboard(secureValue) : undefined}
        >
          {secureValue}
        </Grid>
      </Grid>
      {
        <Grid item className={styles.eye_icon}>
          {isValueVisible ? (
            <Grid
              item
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              className={styles.icon}
            >
              <CopyToClipboard isCopied={isCopied} onClick={() => copyToClipboard(secureValue)}>
                <ContentCopyOutlined fontSize={'small'} />
              </CopyToClipboard>
              <span onClick={toggleVisibility}>
                <Icon icon={Icons.eyesViewOffInvisibleIcon} dataTestId="visibility-off-icon" />
              </span>
            </Grid>
          ) : (
            <span className={styles.icon} onClick={toggleVisibility}>
              <Icon icon={Icons.eyesViewOnVisibleIcon} dataTestId="visibility-icon" />
            </span>
          )}
        </Grid>
      }
    </Grid>
  );
};

export default SecureDetailsRow;
