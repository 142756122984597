import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Badge, InputAdornment } from '@mui/material';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Audience } from '../../../common/types';
import FormikTextField from '../../../components/FormikTextField/FormikTextField';
import { searchForShowScheduleProducts, selectSelectedProducts } from '../../state/showsSlice';
import styles from './ShowProductSearch.module.scss';
import ShowProductSearchResults from './ShowProductSearchResults/ShowProductSearchResults';
import ShowProductSuggestions from './ShowProductSuggestions/ShowProductSuggestions';

const searchTermEmpty = (searchTerm: string) => searchTerm?.length === 0;

const searchTermFieldName = 'productSearch';

interface Props {
  audience: Audience[];
}

const ShowProductSearch = ({ audience }: Props) => {
  // Formik is currently not supporting typed interfaces => we use any (see https://github.com/formium/formik/issues/1334)
  // eslint-disable-next-line
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();
  const dispatch = useDispatch();
  const selectedProducts = useSelector(selectSelectedProducts);

  const clearSearchField = () => setFieldValue(searchTermFieldName, '');

  const searchInputRef = useRef<HTMLElement | null>(null);

  const onSearchBlur = () => {
    if (searchInputRef.current) {
      searchInputRef.current?.focus();
    }
  };

  const adornments = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          {searchTermEmpty(values.productSearch) ? (
            <SearchIcon onClick={onSearchBlur} className={styles.search_icon} />
          ) : (
            <CloseIcon className={styles.search_icon} onClick={clearSearchField} />
          )}
        </InputAdornment>
      ),
    },
  };

  // useRef is used to create a reference that would be persisted across every render: mutating the .current property doesn’t cause a re-render
  // see: https://reactjs.org/docs/hooks-reference.html#useref
  const debouncedSearch = useRef(
    debounce((query: string) => {
      dispatch(searchForShowScheduleProducts({ query, audience }));
    }, 300)
  ).current;

  useEffect(() => {
    const query = values.productSearch;
    if (query) {
      debouncedSearch(query);
    }
  }, [values.productSearch, dispatch]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    // We have to update the form values with added products from the search. Those values build a request createNewShow which will be sent to the backend.
    const productsIDs: string[] = selectedProducts.map(product => product.baseProductNo);
    setFieldValue('baseProductsNo', productsIDs);
    // We have to trigger a validation here...
    // The 'shouldValidate' flag is broken (see: https://github.com/formium/formik/issues/2059)
    const validateSelectedProductsTimer = setTimeout(
      () => setFieldTouched('baseProductsNo', true),
      1
    );

    return () => clearTimeout(validateSelectedProductsTimer);
  }, [selectedProducts]);

  return (
    <>
      <span className={styles.badge_header_container}>
        <h2 className={styles.headline}>Produkte hinzufügen</h2>
        <Badge color="primary" badgeContent={selectedProducts.length} showZero>
          {/* The badge is designed to work with icons and not text like header... a workaround to use a span as anchor element*/}
          <span className={styles.badge_container} />
        </Badge>
      </span>

      <FormikTextField
        fieldName={searchTermFieldName}
        label="Produktsuche"
        value=""
        inputRef={searchInputRef}
        muiProps={adornments}
      />
      {values.productSearch && <ShowProductSuggestions onSuggestionsClose={clearSearchField} />}
      <ShowProductSearchResults />
    </>
  );
};

export default ShowProductSearch;
