import { Text } from '@hse24/shared-components';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useDispatch } from 'react-redux';
import { toggleFilterModal } from '../../../state/productsSlice';
import styles from './FilterHeader.module.scss';

const FilterHeader = () => {
  const dispatch = useDispatch();
  const closeFilter = () => dispatch(toggleFilterModal());

  return (
    <div className={styles.mobileFilterBarHeader}>
      <Text.B3 className={styles.label}>Filter</Text.B3>
      <Text.BodySmall className={styles.subLabel}>Bitte wählen</Text.BodySmall>
      <div onClick={closeFilter} className={styles.close} data-testid="close-drawer">
        <CancelOutlinedIcon />
      </div>
    </div>
  );
};

export default FilterHeader;
