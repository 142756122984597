import { CloseSharp, WarningAmberOutlined } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { browserName, browserVersion, isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { featureFlagValue } from '../../config/featureFlagConfig';
import { AvailableFlag } from '../../config/FeatureFlags';
import { LogLevel } from '../../integration/logginglambda/LoggingLambdaApi';
import { logToLambda } from '../../integration/logginglambda/loggingSlice';
import styles from './UnsupportedBrowserHint.module.scss';

// The supported versions are based on MUI (https://github.com/mui/material-ui/blob/master/.browserslistrc)
export const supportedMinimalDesktopBrowserVersions = new Map<string, number>([
  ['Firefox', 78],
  ['Chrome', 90],
  ['Edge', 91],
  ['Safari', 14],
  ['Opera', 76],
]);
export const supportedMinimalMobileBrowserVersions = new Map<string, number>([
  ['Firefox', 89],
  ['Chrome', 91],
  ['Chrome WebView', 91],
  ['Safari', 12.2],
  ['Mobile Safari', 12.2],
  ['WebKit', 604], // 604 is the corresponding the safari version for safari 12.2 , this is not the WebKit version. Find corresponding version here: https://en.wikipedia.org/wiki/Safari_version_history#Safari_12 for
  ['Android Browser', 91],
  ['Opera', 76],
]);

export enum BrowserSupportedResult {
  SUPPORTED,
  UNSUPPORTED,
  UNKNOWN,
}

const determineBrowserSupport = (): BrowserSupportedResult => {
  const supportedBrowserVersion: number | undefined = isMobile
    ? supportedMinimalMobileBrowserVersions.get(browserName)
    : supportedMinimalDesktopBrowserVersions.get(browserName);
  if (supportedBrowserVersion) {
    return Number(browserVersion) >= supportedBrowserVersion
      ? BrowserSupportedResult.SUPPORTED
      : BrowserSupportedResult.UNSUPPORTED;
  } else {
    return BrowserSupportedResult.UNKNOWN;
  }
};

const UnsupportedBrowserHint = () => {
  const dispatch = useDispatch();
  const browserSupportedResult = determineBrowserSupport();
  const isBrowserNotSupported = browserSupportedResult === BrowserSupportedResult.UNSUPPORTED;
  const [isVisible, setIsVisible] = useState<boolean>(isBrowserNotSupported);
  const isInApp = featureFlagValue(AvailableFlag.isInApp);

  useEffect(() => {
    dispatch(
      logToLambda({
        level: LogLevel.INFO,
        message: 'Determined current browser',
        browserVersion: browserVersion,
        browserName: browserName,
        isMobile: isMobile,
        isBrowserSupported: !isBrowserNotSupported,
        browserSupportedResult: browserSupportedResult,
      })
    );
  }, [dispatch, isBrowserNotSupported, browserSupportedResult]);

  return (
    <>
      {isVisible && !isInApp && (
        <Grid container justifyContent="center">
          <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.hint}
          >
            <Grid item>
              <WarningAmberOutlined className={styles.icon} />
            </Grid>
            <Grid item>
              Du verwendest eine veraltete Browserversion. Um das Portal fehlerfrei zu verwenden,
              aktualisiere bitte deinen Browser.
            </Grid>
            <Grid item>
              <Button className={styles.button} onClick={() => setIsVisible(false)}>
                <CloseSharp />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UnsupportedBrowserHint;
